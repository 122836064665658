<div class="py-6" *ngIf="currentOrientation === 'portrait' || (currentOrientation === 'landscape' && isTablet)">
    <div class="flex flex-col items-center justify-center overflow-hidden">
        <div class="font-bebas-neue font-normal leading-none text-black text-[9rem]">
            <app-remaining-score [team]="team" [busted]="currentTeam.busted"></app-remaining-score>
        </div>
        <div
            class="rounded-sm px-2 py-0.5 font-bold uppercase leading-none !text-white sm:text-base text-md max-w-full"
            [class.bg-orange]="team.subtext || (team.tempSubtext && team.allowTempScores)"
            *ngIf="preferenceService.showCheckoutHint && checkoutPossible">
            <app-checkout-suggestion [team]="team"></app-checkout-suggestion>
        </div>
    </div>
    @if (isLargeTablet) {
        <div class="mx-auto mt-4 grid grid-cols-2 gap-x-1.5 gap-y-6">
            @for (i of [0, 1, 2, 3, 4, 5]; track $index) {
                @if (i > 0 || (i === 0 && preferenceService.showAvg)) {
                    <div class="col-span-1 space-y-1.5">
                        <div class="truncate text-center font-semibold uppercase leading-none text-orange text-2xl">
                            <div *ngIf="i === 0" [translate]="'AVERAGE'" i18n="@@AVERAGE">3-dart avg.</div>
                            <div *ngIf="i === 1" [translate]="'FIRST_9_AVERAGE'" i18n="@@FIRST_9_AVERAGE">
                                First 9 avg.
                            </div>
                            <div *ngIf="i === 2" [translate]="'CHECKOUT_RATE'" i18n="@@CHECKOUT_RATE">
                                Checkout rate
                            </div>
                            <div *ngIf="i === 3" [translate]="'BEST_LEG'" i18n="@@BEST_LEG">Best leg</div>
                            <div *ngIf="i === 4" [translate]="'LAST_SCORE'" i18n="@@LAST_SCORE">Last score</div>
                            <div *ngIf="i === 5" [translate]="'DARTS_THROWN'" i18n="@@DARTS_THROWN">Darts thrown</div>
                        </div>
                        <div
                            class="text-md truncate text-center font-bebas-neue font-normal leading-none text-black text-[1.75rem]">
                            <div *ngIf="i === 0">
                                {{ team.three_dart_average || 0 | number: '1.2-2' }}
                            </div>
                            <div *ngIf="i === 1">
                                {{ team.first_nine_average || 0 | number: '1.2-2' }}
                            </div>
                            <div *ngIf="i === 2">
                                {{ (team.total_score_legs / team.darts_on_double) * 100 || 0 | number: '1.2-2' }}% ({{
                                    team.total_score_legs || 0
                                }}
                                / {{ team.darts_on_double || 0 }})
                            </div>
                            <div *ngIf="i === 3">
                                {{ team.best_leg || '-' }}
                                <span
                                    class="!font-bebas-neue"
                                    *ngIf="team.best_leg"
                                    [translate]="'DARTS'"
                                    i18n="@@DARTS">
                                    Darts
                                </span>
                            </div>
                            <div *ngIf="i === 4">
                                {{ team.lastScore || '-' }}
                            </div>
                            <div *ngIf="i === 5">
                                {{ team.darts_this_leg || 0 }}
                            </div>
                        </div>
                    </div>
                }
            }
        </div>
    } @else {
        <div
            class="mx-auto mt-4 flex flex-col landscape:md:space-y-2"
            [ngClass]="{ 'portrait:w-[70%] landscape:w-full landscape:lg:w-[70%]': halfWidth, 'w-full': !halfWidth }">
            @for (i of keyboard === 'none' ? [0, 1, 2, 3, 4, 5] : [0, 1, 2]; track $index) {
                @if (!(i === 0 && keyboard === 'none' && !preferenceService.showAvg)) {
                    <div class="grid grid-cols-10 items-center gap-x-3 gap-y-4">
                        <div
                            class="col-span-7 truncate text-left text-[0.825rem] font-semibold uppercase leading-none text-orange text:2xl"
                            [ngClass]="{
                                'landscape:md:col-span-10 landscape:md:text-center': keyboard !== 'none'
                            }">
                            @if (keyboard === 'none') {
                                <div *ngIf="i === 0" [translate]="'AVERAGE'" i18n="@@AVERAGE">3-dart avg.</div>
                                <div *ngIf="i === 1" [translate]="'FIRST_9_AVERAGE'" i18n="@@FIRST_9_AVERAGE">
                                    First 9 avg.
                                </div>
                                <div *ngIf="i === 2" [translate]="'CHECKOUT_RATE'" i18n="@@CHECKOUT_RATE">
                                    Checkout rate
                                </div>
                                <div *ngIf="i === 3" [translate]="'LAST_SCORE'" i18n="@@LAST_SCORE">Last score</div>
                                <div *ngIf="i === 4" [translate]="'DARTS_THROWN'" i18n="@@DARTS_THROWN">
                                    Darts thrown
                                </div>
                                <div *ngIf="i === 5" [translate]="'BEST_LEG'" i18n="@@BEST_LEG">Best leg</div>
                            } @else {
                                <div
                                    *ngIf="i === 0 && preferenceService.showAvg"
                                    [translate]="'AVERAGE'"
                                    i18n="@@AVERAGE">
                                    3-dart avg.
                                </div>
                                <div
                                    *ngIf="i === 0 && !preferenceService.showAvg"
                                    [translate]="'BEST_LEG'"
                                    i18n="@@BEST_LEG">
                                    Best leg
                                </div>
                                <div *ngIf="i === 1" [translate]="'LAST_SCORE'" i18n="@@LAST_SCORE">Last score</div>
                                <div *ngIf="i === 2" [translate]="'DARTS_THROWN'" i18n="@@DARTS_THROWN">
                                    Darts thrown
                                </div>
                            }
                        </div>
                        <div
                            class="col-span-3 truncate text-left font-bebas-neue font-normal leading-none text-black text-[1.75rem]"
                            [ngClass]="{
                                'landscape:md:col-span-10 landscape:md:text-center': keyboard !== 'none'
                            }">
                            @if (keyboard === 'none') {
                                <div *ngIf="i === 0">
                                    {{ team.three_dart_average || 0 | number: '1.2-2' }}
                                </div>
                                <div *ngIf="i === 1">
                                    {{ team.first_nine_average || 0 | number: '1.2-2' }}
                                </div>
                                <div *ngIf="i === 2">
                                    {{ (team.total_score_legs / team.darts_on_double) * 100 || 0 | number: '1.2-2' }}%
                                </div>
                                <div *ngIf="i === 3">
                                    {{ team.lastScore || '-' }}
                                </div>
                                <div *ngIf="i === 4">
                                    {{ team.darts_this_leg || 0 }}
                                </div>
                                <div *ngIf="i === 5">
                                    {{ team.best_leg || '-' }}
                                    <span
                                        class="!font-bebas-neue"
                                        *ngIf="team.best_leg"
                                        [translate]="'DARTS'"
                                        i18n="@@DARTS"
                                        >Darts</span
                                    >
                                </div>
                            } @else {
                                <div *ngIf="i === 0 && preferenceService.showAvg">
                                    {{ team.three_dart_average || 0 | number: '1.2-2' }}
                                </div>
                                <div *ngIf="i === 0 && !preferenceService.showAvg">
                                    {{ team.best_leg || '-' }}
                                    <span
                                        class="!font-bebas-neue"
                                        *ngIf="team.best_leg"
                                        [translate]="'DARTS'"
                                        i18n="@@DARTS"
                                        >Darts</span
                                    >
                                </div>
                                <div *ngIf="i === 1">{{ team.lastScore || '-' }}</div>
                                <div *ngIf="i === 2">{{ team.darts_this_leg || 0 }}</div>
                            }
                        </div>
                    </div>
                }
            }
        </div>
    }
</div>

<ng-template #stat1>
    <div
        class="stat"
        [class.big]="!(currentOrientation === 'landscape' && isLargeTablet)"
        [class.xl]="currentOrientation === 'landscape' && isLargeTablet">
        <app-remaining-score [team]="team" [busted]="currentTeam.busted"></app-remaining-score>
    </div>
    <div
        class="stat-name"
        [class.big]="!(currentOrientation === 'landscape' && isLargeTablet)"
        [class.xl]="currentOrientation === 'landscape' && isLargeTablet"
        *ngIf="preferenceService.showCheckoutHint">
        <app-checkout-suggestion [team]="team"></app-checkout-suggestion>
    </div>
</ng-template>

<ng-template #threeDartAvg>
    <div class="stat" [class.small]="!(currentOrientation === 'landscape' && isLargeTablet)">
        {{ team.three_dart_average || 0 | number: '1.2-2' }}
    </div>
    <div class="stat-name" [class.small]="!(currentOrientation === 'landscape' && isLargeTablet)">
        <div class="pb-1" [translate]="'AVERAGE'" i18n="@@AVERAGE">3-dart avg.</div>
    </div>
</ng-template>

<ng-template #lastScore>
    <div class="stat" [class.small]="!(currentOrientation === 'landscape' && isLargeTablet)">
        {{ team.lastScore || '-' }}
    </div>
    <div class="stat-name" [class.small]="!(currentOrientation === 'landscape' && isLargeTablet)">
        <div class="pb-1" [translate]="'LAST_SCORE'" i18n="@@LAST_SCORE">Last score</div>
    </div>
</ng-template>

<ng-template #dartsThrown>
    <div class="stat" [class.small]="!(currentOrientation === 'landscape' && isLargeTablet)">
        {{ team.darts_this_leg || 0 }}
    </div>
    <div class="stat-name" [class.small]="!(currentOrientation === 'landscape' && isLargeTablet)">
        <div class="pb-1" [translate]="'DARTS_THROWN'" i18n="@@DARTS_THROWN">Darts thrown</div>
    </div>
</ng-template>
