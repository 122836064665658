export enum PreferenceLocalStorageKey {
    allowCaller = 'allowCaller',
    showHeadToHead = 'showHeadToHead',
    callYouRequire = 'callYouRequire',
    callDartbot = 'callDartbot',
    dartbotTimeout = 'dartbotTimeout',
    showAdvancedBar = 'showAdvancedBar',
    showAvg = 'showAvg',
    showCheckoutHint = 'showCheckoutHint',
    showInGameChat = 'showInGameChat',
    enableSpeechToScore = 'enableSpeechToScore',
    enableActionReplays = 'enableActionReplays',
    promptActionReplay = 'promptActionReplay',

    transition = 'transition',
    lang = 'lang',
}
