import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { FriendApiService } from '@dc-api/friend.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { ModalController } from '@ionic/angular';
import { debounceTime } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-search-friend-dialog',
    templateUrl: 'search-friend.component.html',
})
export class SearchFriendDialogComponent implements OnInit {
    searchString: string;
    users: User[] = [];
    public searchLoading = false;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private _friendApiService: FriendApiService
    ) {}

    public ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(debounceTime(600), takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                if (value.length) {
                    this.searchUsers(value);
                }
            });
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    searchUsers(searchString: string) {
        if (searchString.length > 0) {
            this.searchLoading = true;
            this._friendApiService.getFriends({ search: searchString }).then((res) => {
                this.searchLoading = false;
                this.users = res.data.data;
            });
        }
    }
}
