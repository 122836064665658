/* eslint-disable max-len */
import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor, PermissionState } from '@capacitor/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { ConnectionStatus, Network } from '@capacitor/network';
import { ScreenOrientation as CapScreenOrientation } from '@capacitor/screen-orientation';
import { Wifi } from '@capacitor-community/wifi';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { SmartDevice, SmartDeviceNetwork } from '@dc-core/dc-backend/dc-classes';
import { SmartDeviceType } from '@dc-core/dc-backend/dc-enums';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { CAMERA_TYPE } from '@dc-core/dc-services/camera/camera.models';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from '@services/auth.service';
import { MediaWatcherService } from '@services/media-watcher.service';
import { SmartDeviceService } from '@services/smart-device.service';
import { UDPService } from '@services/udp.service';
import { VirtServerService } from '@services/virt-server.service';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import {
    CapacitorSmartDeviceClient,
    VirtCamCommandRequest,
    VirtCamCommands,
    VirtCamConfigureWiFiStationRequest,
    VirtCamHelper,
    VirtCamResponses,
} from 'capacitor-smart-device-client';
import _ from 'lodash';
import { PowerMode } from 'power-mode';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { Device_Images, GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { SmartDeviceLightIndicatorsDialogComponent } from 'src/app/modules/smart-devices/dialogs/smart-device-light-indicators/smart-device-light-indicators.dialog';
import { UserService } from 'src/app/modules/user/services/user.service';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { environment } from 'src/environments/environment';

import { AskForLocationDialogComponent } from '../ask-for-location/ask-for-location.dialog';

type SlideType =
    | 'select_device'
    | 'power_on'
    | 'wifi_connection'
    | 'device_hotspot'
    | 'configuration'
    | 'device_identity'
    | 'end';
type SubSlide = 'begin' | 'info' | 'waiting' | 'success' | 'streams';

type Slide = {
    index: number;
    type: SlideType;
    subSides?: SubSlide[];
    name: string;
    nextText: string;
};

type WiFiNetwork = { ssid: string; quality: string };

type SmartDeviceSetupImages = {
    device: string;
    power: string;
    hotspotStartup: string;
    hotspotMode: string;
    wifi: string;
    success: string;
    green: string;
};

@Component({
    selector: 'app-add-smart-device-dialog',
    templateUrl: 'add-smart-device.dialog.html',
    animations: [
        trigger('slideAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('300ms ease-out', style({ transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)' }),
                animate('150ms ease-out', style({ transform: 'translateX(-100%)' })),
            ]),
        ]),
    ],
})
export class AddSmartDeviceDialogComponent implements OnInit, OnDestroy {
    public slides: Slide[] = [
        {
            index: 0,
            type: 'select_device',
            name: $localize`:@@SELECT_DEVICE:Select device`,
            nextText: $localize`:@@CONTINUE:Continue`,
        },
        {
            index: 1,
            type: 'power_on',
            name: $localize`:@@POWER_ON:Power on`,
            nextText: $localize`:@@NEXT:Next`,
            subSides: ['begin', 'info'],
        },
        {
            index: 2,
            type: 'device_hotspot',
            name: $localize`:@@DEVICE_HOTSPOT:Device hotspot`,
            nextText: $localize`:@@CONTINUE:Continue`,
            subSides: ['waiting', 'success'],
        },
        {
            index: 3,
            type: 'wifi_connection',
            name: $localize`:@@WIFI_CONNECTION:Wi-Fi connection`,
            nextText: $localize`:@@CONTINUE:Continue`,
        },
        {
            index: 4,
            type: 'configuration',
            name: $localize`:@@CONFIGURATION:Configuration`,
            nextText: $localize`:@@CONTINUE:Continue`,
            subSides: ['waiting', 'success'],
        },
        {
            index: 5,
            type: 'device_identity',
            name: $localize`:@@DEVICE_IDENTITY:Device identity`,
            nextText: $localize`:@@SAVE_DETAILS:Save details`,
        },
        {
            index: 6,
            type: 'end',
            name: null,
            nextText: $localize`:@@SAVE_DETAILS:Save details`,
            subSides: ['success', 'streams'],
        },
    ];
    public currentSlide: Slide = this.slides[0];
    public animationState = 'right';
    public currentSubSlide: SubSlide = null;
    public hideNextButton = false;

    @Input() networks: SmartDeviceNetwork[] = [];
    public existingDevices: SmartDevice[] = [];
    public networkStatus: ConnectionStatus;
    public deviceSetup: 'new' | 'existing' = 'new';
    public autoHotspotConnectionFailed = false;

    public availableSmartDevices: { title: string; type: SmartDeviceType }[] = [
        {
            title: 'Target VirtCam',
            type: 'virt_cam',
        },
        {
            title: 'Target Omni',
            type: 'omni_scoring',
        },
    ];
    public smartDevice: SmartDevice = {
        name: null,
        type: 'virt_cam',
        guid: null,
        ip_address: null,
    };
    public smartDeviceNetwork: SmartDeviceNetwork = {
        name: $localize`:@@MY_NETWORK:My Network`,
        ssid: null,
        passphrase: null,
        country_code: 'NL',
    };
    public hasNetwork = false;

    public coordinates: Position;
    public matchingAliases: string[] = [];
    public deviceImages = Device_Images;

    public images: SmartDeviceSetupImages = {
        device: null,
        power: null,
        hotspotStartup: null,
        hotspotMode: null,
        wifi: null,
        success: null,
        green: null,
    };

    public deviceType: string;
    public deviceSsid: string;
    public devicePassphrase: string;
    public deviceIpAddress: string;
    public devicePort: number;

    public wiFiFound: boolean = null;
    public wiFiNetworks: WiFiNetwork[] = null;
    public passwordVisible: boolean = false;

    public connectedToHotspot = false;
    public sendingWiFiCredentials = false;
    public foundDevice = false;
    public completedSetup = false;
    public checkedStreams = false;
    public locationPermission: PermissionState = null;

    private _backButtonSubscription: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public udpService: UDPService = inject(UDPService);

    private userService: UserService = inject(UserService);

    constructor(
        public videoRoomService: JanusVideoRoomService,
        public smartDeviceService: SmartDeviceService,
        private _platform: Platform,
        private _auth: AuthService,
        private _view: ModalController,
        private _modal: ModalController,
        private _ngZone: NgZone,
        private _iab: InAppBrowser,
        private _navParams: NavParams,
        private _alertService: DartCounterAlertService,
        private _translateService: TranslateService,
        private _virtServerService: VirtServerService,
        private _smartDeviceApiService: SmartDeviceApiService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _mediaWatcherService: MediaWatcherService,
        private ga: DartCounterAnalyticsService
    ) {}

    async ngOnInit() {
        if (!this._platform.is('tablet') && this._platform.is('capacitor')) {
            CapScreenOrientation.lock({ orientation: 'portrait' });
        }

        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.dismissModal();
        });

        this._alertService.showNetworkAlert = false;

        this.networks.forEach((network) => {
            network.devices.forEach((device) => this.existingDevices.push(device));
        });

        this.smartDeviceNetwork.id = this._navParams.get('networkId');
        if (this.smartDeviceNetwork.id) {
            this.hasNetwork = true;
        }
        this.smartDeviceNetwork.ssid = this._navParams.get('networkSsid');
        this.smartDeviceNetwork.passphrase = this._navParams.get('networkPassphrase');

        this.networkStatus = await Network.getStatus();

        try {
            if (this._platform.is('ios') && this._platform.is('capacitor')) {
                PowerMode.lowPowerModeEnabled().then((res) => {
                    if (res.lowPowerModeEnabled) {
                        this._alertService.createAlert({
                            title: $localize`:@@LOW_POWER_MODE:Low power mode`,
                            text: $localize`:@@SWITCH_OFF_LOWER_POWER_MODE_FOR_CAM_STREAMS:Switch off ‘Low power mode’ in your device settings to prevent camera streams from pausing.`,
                            icon: 'warning',
                            timer: null,
                            confirmButtonText: $localize`:@@OK:OK`,
                        });
                    }
                });
            }
        } catch (_) {}

        this._mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.matchingAliases = matchingAliases;
                this.setImages();
            });

        this.smartDeviceService.isSettingUpSmartDevice = true;

        this.smartDeviceService.refreshSetup$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            if (
                this.currentSlide.type === 'device_hotspot' &&
                this.currentSubSlide === 'waiting' &&
                this.autoHotspotConnectionFailed &&
                this.locationPermission === 'denied'
            ) {
                this.requestLocationPermissions();
                this.connectToDeviceHotspot();
            }
        });
    }

    ionViewWillEnter() {
        this.ga.trackView('Smart devices | Setup');
    }

    setImages() {
        let folderName = 'virt-cam';
        let fileName = 'Virt';
        if (this.smartDevice.type === 'omni_scoring') {
            folderName = 'omni-scoring';
            fileName = 'Omni';
        }

        const images: SmartDeviceSetupImages = {
            device: `assets/images/ftr/${folderName}-setup/${fileName}-setup-device`,
            power: `assets/images/ftr/${folderName}-setup/${fileName}-setup-power`,
            hotspotStartup: `assets/images/ftr/${folderName}-setup/${fileName}-setup-hotspot-startup`,
            hotspotMode: `assets/images/ftr/${folderName}-setup/${fileName}-setup-hotspot-mode`,
            wifi: `assets/images/ftr/${folderName}-setup/${fileName}-setup-wifi`,
            success: `assets/images/ftr/${folderName}-setup/${fileName}-setup-success`,
            green: `assets/images/ftr/${folderName}-setup/${fileName}-setup-green`,
        };

        const isLargeTablet =
            this.matchingAliases?.includes('lg') &&
            (this._platform.is('capacitor') ? this._platform.is('tablet') : true);
        if (this.matchingAliases && !isLargeTablet) {
            images.device += '-sm';
            images.power += '-sm';
            // images.hotspotStartup += '-sm';
            images.hotspotMode += '-sm';
            images.wifi += '-sm';
            images.success += '-sm';
            // images.green += '-sm';
        }

        images.device += '.jpg';
        images.power += '.jpg';
        images.hotspotStartup += '.gif';
        images.hotspotMode += '.jpg';
        images.wifi += '.jpg';
        images.success += '.jpg';
        images.green += '.jpg';

        this.images = images;
    }

    translated(tag: string): string {
        switch (tag) {
            case 'POWER_UP_YOUR_DEVICE_TYPE':
                $localize`:@@POWER_UP_YOUR_DEVICE_TYPE:Power up your ${this.deviceType}:device:`;
                return this._translateService.instant('POWER_UP_YOUR_DEVICE_TYPE', {
                    device: this.deviceType,
                });
            case 'CHECK_HOTSPOT_LIGHT_IS_FLASHING':
                $localize`:@@CHECK_HOTSPOT_LIGHT_IS_FLASHING:Check ${this.deviceType}:device: hotspot light is flashing blue`;
                return this._translateService.instant('CHECK_HOTSPOT_LIGHT_IS_FLASHING', {
                    device: this.deviceType,
                });
            case 'DEVICE_WILL_CONNECT_TO_WIFI_CREDENTIALS':
                $localize`:@@DEVICE_WILL_CONNECT_TO_WIFI_CREDENTIALS:${this.deviceType}:device: will connect to the Wi-Fi credentials below`;
                return this._translateService.instant('DEVICE_WILL_CONNECT_TO_WIFI_CREDENTIALS', {
                    device: this.deviceType,
                });
            case 'CHOOSE_A_WIFI_NETWORK_FOR_YOUR_DEVICE':
                $localize`:@@CHOOSE_A_WIFI_NETWORK_FOR_YOUR_DEVICE:Choose a Wi-Fi network you would like to use for your ${this.deviceType}:device:`;
                return this._translateService.instant('CHOOSE_A_WIFI_NETWORK_FOR_YOUR_DEVICE', {
                    device: this.deviceType,
                });
            case 'LOOKING_FOR_DEVICE_HOTSPOT':
                $localize`:@@LOOKING_FOR_DEVICE_HOTSPOT:Looking for ${this.deviceType}:device: hotspot`;
                return this._translateService.instant('LOOKING_FOR_DEVICE_HOTSPOT', {
                    device: this.deviceType,
                });
            case 'CONNECTING_TO_DEVICE_HOTSPOT':
                $localize`:@@CONNECTING_TO_DEVICE_HOTSPOT:Connecting to ${this.deviceType}:device: hotspot`;
                return this._translateService.instant('CONNECTING_TO_DEVICE_HOTSPOT', {
                    device: this.deviceType,
                });
            case 'CONNECT_TO_DEVICE_HOTSPOT':
                $localize`:@@CONNECT_TO_DEVICE_HOTSPOT:Connect to ${this.deviceType}:device: hotspot`;
                return this._translateService.instant('CONNECT_TO_DEVICE_HOTSPOT', {
                    device: this.deviceType,
                });
            case 'FIND_WIFI_NETWORK_AND_CONNECT_USING_PASSWORD':
                $localize`:@@FIND_WIFI_NETWORK_AND_CONNECT_USING_PASSWORD:Find '${this.deviceSsid}:ssid:' Wi-Fi network and connect to it using the password below`;
                return this._translateService.instant('FIND_WIFI_NETWORK_AND_CONNECT_USING_PASSWORD', {
                    ssid: this.deviceSsid,
                });
            case 'CONNECTING_TO_DEVICE':
                $localize`:@@CONNECTING_TO_DEVICE:Connecting to ${this.deviceType}:device:`;
                return this._translateService.instant('CONNECTING_TO_DEVICE', {
                    device: this.deviceType,
                });
            case 'CONNECTED_TO_DEVICE_HOTSPOT_AND_CONTINUE_SETUP':
                $localize`:@@CONNECTED_TO_DEVICE_HOTSPOT_AND_CONTINUE_SETUP:You are connected to ${this.deviceType}:device: hotspot and can continue to setup`;
                return this._translateService.instant('CONNECTED_TO_DEVICE_HOTSPOT_AND_CONTINUE_SETUP', {
                    device: this.deviceType,
                });
            case 'CONNECTING_DEVICE_TO_YOUR_NETWORK':
                $localize`:@@CONNECTING_DEVICE_TO_YOUR_NETWORK:Connecting ${this.deviceType}:device: to your network`;
                return this._translateService.instant('CONNECTING_DEVICE_TO_YOUR_NETWORK', {
                    device: this.deviceType,
                });
            case 'SEARCHING_FOR_DEVICE_IN_YOUR_NETWORK':
                $localize`:@@SEARCHING_FOR_DEVICE_IN_YOUR_NETWORK:Searching for ${this.deviceType}:device: in your network`;
                return this._translateService.instant('SEARCHING_FOR_DEVICE_IN_YOUR_NETWORK', {
                    device: this.deviceType,
                });
            case 'WAIT_UNTIL_LIGHT_TURNS_GREEN_FOR_CONNECTED_DEVICE':
                $localize`:@@WAIT_UNTIL_LIGHT_TURNS_GREEN_FOR_CONNECTED_DEVICE:Wait until the light changes through to solid green. Your ${this.deviceType}:device: is then connected to your Wi-Fi network`;
                return this._translateService.instant('WAIT_UNTIL_LIGHT_TURNS_GREEN_FOR_CONNECTED_DEVICE', {
                    device: this.deviceType,
                });
            case 'DEVICE_CONNECTED_AND_READY_FOR_ITS_IDENTITY':
                $localize`:@@DEVICE_CONNECTED_AND_READY_FOR_ITS_IDENTITY:${this.deviceType}:device: is now connected and ready!`;
                return this._translateService.instant('DEVICE_CONNECTED_AND_READY_FOR_ITS_IDENTITY', {
                    device: this.deviceType,
                });
            case 'CONFIGURED_DEVICE_AND_READY_FOR_USE':
                $localize`:@@CONFIGURED_DEVICE_AND_READY_FOR_USE:You've configured your ${this.deviceType}:device:. It is connected to your network and ready!`;
                return this._translateService.instant('CONFIGURED_DEVICE_AND_READY_FOR_USE', {
                    device: this.deviceType,
                });
            case 'YOUR_DEVICE_VIEW':
                $localize`:@@YOUR_DEVICE_VIEW:Your ${this.deviceType}:device: view`;
                return this._translateService.instant('YOUR_DEVICE_VIEW', {
                    device: this.deviceType,
                });
            case 'YOUR_DEVICE_VIEW_VS_OPPONENTS_INFO':
                $localize`:@@YOUR_DEVICE_VIEW_VS_OPPONENTS_INFO:This is your ${this.deviceType}:device: view, your opponents will see this when playing against you!`;
                return this._translateService.instant('YOUR_DEVICE_VIEW_VS_OPPONENTS_INFO', {
                    device: this.deviceType,
                });
        }
    }

    requestLocationPermissions(): void {
        Geolocation.checkPermissions()
            .then((checkPerms) => {
                this.locationPermission = checkPerms.location;

                switch (this.locationPermission) {
                    case 'granted':
                        this.setCoordinates();
                        break;
                    case 'denied':
                        break;
                    case 'prompt':
                        this._modal
                            .create({
                                component: AskForLocationDialogComponent,
                                cssClass: 'auto-height',
                                backdropDismiss: false,
                                showBackdrop: true,
                            })
                            .then((elem) => {
                                elem.present();
                                elem.onDidDismiss().then(() => {
                                    Geolocation.requestPermissions({ permissions: ['coarseLocation', 'location'] })
                                        .then((requestPerms) => {
                                            this.locationPermission = requestPerms.location;

                                            if (requestPerms.location === 'granted') {
                                                this.setCoordinates();
                                            }
                                        })
                                        .catch(() => {
                                            this.locationPermission = 'denied';
                                        });
                                });
                            });
                        break;
                }
            })
            .catch(() => {
                this.locationPermission = 'denied';
            });
    }

    openAppSettings(): void {
        NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
        });
    }

    async setCoordinates(): Promise<void> {
        this.coordinates = await Geolocation.getCurrentPosition();
        this._auth.fetcher
            .path('/location/lat-long')
            .method('post')
            .create()({ latitude: this.coordinates.coords.latitude, longitude: this.coordinates.coords.longitude })
            .then((res) => {
                this.smartDeviceNetwork.country_code = res.data.country_code;
            });
    }

    previousSlide(): void {
        if (this.currentSlide.index === 0) {
            return;
        }

        this.currentSlide = this.slides.find((slide) => slide.index === this.currentSlide.index + 1);
        this.animationState = 'right';

        if (this.currentSlide.subSides) {
            this.currentSubSlide = this.currentSlide.subSides[0];
        }
    }

    nextSlide(): void {
        if (this.currentSlide.index === this.slides[this.slides.length - 1].index) {
            return;
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.SEARCHFRIENDS, GA_EVENTACTIONS.SMART_DEVICES_NEXT_SLIDE);

        this.currentSlide = this.slides.find((slide) => slide.index === this.currentSlide.index + 1);
        this.animationState = 'left';

        if (this.currentSlide.subSides) {
            this.currentSubSlide = this.currentSlide.subSides[0];
        }
    }

    goToSlide(type: SlideType): void {
        const previousSlide = this.currentSlide;
        this.currentSlide = this.slides.find((slide) => slide.type === type);
        if (this.currentSlide.index > previousSlide.index) {
            this.animationState = 'left';
        } else {
            this.animationState = 'right';
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.SEARCHFRIENDS, GA_EVENTACTIONS.SMART_DEVICES_PREVIOUS_SLIDE);

        if (this.currentSlide.subSides) {
            this.currentSubSlide = this.currentSlide.subSides[0];
        }
    }

    // To Do: Add some trouble-shooting
    setWiFiStatus(wiFiFound: boolean = null): void {
        if (!wiFiFound) {
            this.smartDeviceNetwork.ssid = null;
        }
        this.wiFiFound = wiFiFound;
    }

    alreadyOnWiFi(): void {
        $localize`:@@SAME_WIFI_NETWORK_AS_DEVICE:Make sure you are connected to the same Wi-Fi network as the ${this.deviceType}:device:`;
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@CONNECTED_TO_SAME_WIFI_NETWORK:Connected to same Wi-Fi network`,
                    text: this._translateService.instant('SAME_WIFI_NETWORK_AS_DEVICE', {
                        device: this.deviceType,
                    }),
                    confirmText: $localize`:@@OK:OK`,
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.deviceSetup = 'existing';
                        if (this.smartDeviceNetwork.ssid) {
                            this.searchForDeviceViaUDP();
                            this.goToSlide('configuration');
                        } else {
                            this.hideNextButton = false;
                            Wifi.getSSID()
                                .then((res) => {
                                    if (res.ssid) {
                                        this.wiFiFound = true;
                                        this.smartDeviceNetwork.ssid = res.ssid;

                                        this.searchForDeviceViaUDP();
                                        this.goToSlide('configuration');
                                    } else {
                                        this.wiFiFound = false;
                                        this.goToSlide('wifi_connection');
                                    }
                                })
                                .catch(() => {
                                    this.wiFiFound = false;
                                    this.goToSlide('wifi_connection');
                                });
                        }
                    }
                });
            });
    }

    public showDeviceLightIndicators() {
        this._modal
            .create({
                component: SmartDeviceLightIndicatorsDialogComponent,
                componentProps: {},
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                showBackdrop: true,
                backdropDismiss: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        console.log(dialogRes.data);
                    }
                });
            });
    }

    selectType(type: SmartDeviceType): void {
        this.smartDevice.type = type;

        const slide = _.find(this.slides, (listSlide) => listSlide.type === 'end');
        if (slide) {
            if (type === 'virt_cam') {
                slide.subSides = ['success', 'streams'];
            } else if (type === 'omni_scoring') {
                slide.subSides = ['success'];
            }
        }

        this.setImages();
    }

    public togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    public cantFindNetwork() {
        //Todo show troubleshooting
    }

    noWiFiSSIDFound(): void {
        this.hideNextButton = false;
        this.smartDeviceNetwork.ssid = null;
        this.smartDeviceNetwork.passphrase = null;
        this.wiFiFound = false;
    }

    chooseWiFiNetwork(ssid: string): void {
        this.smartDeviceNetwork.ssid = ssid;
        this.wiFiFound = true;
    }

    searchForWiFiNetworks(scanOnly = true): void {
        if (!scanOnly) {
            this.hideNextButton = true;
            this.wiFiFound = null;
            this.smartDeviceNetwork.ssid = null;
        }

        this.wiFiNetworks = null;

        this.getWiFiNetworks()
            .then((networks) => {
                this.wiFiNetworks = networks;
                this._changeDetectorRef.detectChanges();
            })
            .catch(() => {
                this.wiFiFound = false;
            });
    }

    getWiFiNetworks(): Promise<WiFiNetwork[]> {
        return new Promise((resolve, reject) => {
            this._virtServerService
                .sendToVirt({}, this.deviceIpAddress + ':' + this.smartDeviceService.virtServerPort + '/scan-wifi', 20)
                .pipe(take(1))
                .subscribe({
                    next: (res: any) => {
                        resolve(res.networks);
                    },
                    error: () => {
                        reject();
                    },
                });
        });
    }

    onNextButtonTouched() {
        if (this.currentSlide.type === 'select_device') {
            switch (this.smartDevice.type) {
                case 'virt_cam':
                    this.deviceType = 'Target VirtCam';
                    this.deviceSsid = 'Virt';
                    this.devicePassphrase = 'Targetvirt72';
                    this.deviceIpAddress = '192.168.4.1';
                    this.devicePort = 443;
                    break;
                case 'omni_scoring':
                    this.deviceType = 'Target Omni';
                    this.deviceSsid = 'Omni';
                    this.devicePassphrase = 'Targetomni72';
                    this.deviceIpAddress = '192.168.4.1';
                    this.devicePort = 443;
                    break;
            }

            this.smartDevice.name = this.deviceType;

            this.nextSlide();
            this.requestLocationPermissions();
        } else if (this.currentSlide.type === 'power_on') {
            if (this.currentSubSlide === 'begin') {
                this.currentSubSlide = 'info';
            } else {
                if (this.smartDeviceNetwork.ssid) {
                    if (this.deviceSetup == 'new') {
                        this.connectToDeviceHotspot();
                        this.nextSlide();
                    } else if (this.deviceSetup == 'existing') {
                        this.hideNextButton = true;
                        this.searchForDeviceViaUDP();

                        this.goToSlide('configuration');
                    }
                } else {
                    this.connectToDeviceHotspot();
                    this.nextSlide();
                }
            }
        } else if (this.currentSlide.type === 'device_hotspot') {
            if (this.currentSubSlide === 'waiting') {
                this.connectedToHotspot = false;
                if (this.udpService.status !== 'searching') {
                    Wifi.getSSID()
                        .then((res) => {
                            if (res.ssid === this.deviceSsid) {
                                this.currentSubSlide = 'success';
                            } else {
                                this.askForCorrectHotspot();
                            }
                        })
                        .catch(() => {
                            this.askForCorrectHotspot();
                        });
                }
            } else if (this.currentSubSlide === 'success') {
                if (this.smartDeviceNetwork.ssid) {
                    if (this.isNetworkPassphraseValid()) {
                        this.configureWiFiStation();
                        this.goToSlide('configuration');
                    }
                } else {
                    this.hideNextButton = true;
                    this.wiFiFound = null;
                    this.smartDeviceNetwork.ssid = null;
                    this.nextSlide();

                    this.searchForWiFiNetworks();
                }
            }
        } else if (this.currentSlide.type === 'wifi_connection') {
            if (!this.smartDeviceNetwork.ssid) {
                this._alertService.showPrompt(
                    $localize`:@@FILL_IN_ALL_WIFI_DETAILS:Please fill in all Wi-Fi details`,
                    'error'
                );
                return;
            }

            if (this.deviceSetup == 'new') {
                if (this.isNetworkPassphraseValid()) {
                    this.hideNextButton = true;

                    this.configureWiFiStation();
                    this.nextSlide();
                }
            } else if (this.deviceSetup == 'existing') {
                this.searchForDeviceViaUDP();
                this.nextSlide();
            }
        } else if (this.currentSlide.type === 'configuration') {
            if (this.currentSubSlide === 'waiting') {
                this.setupCompleted();
                this.currentSubSlide = 'success';
                this.hideNextButton = false;
            } else {
                this.checkStreams();
                this.goToSlide('end');
            }
        } else if (this.currentSlide.type === 'device_identity') {
            if (this.smartDevice.id) {
                this._smartDeviceApiService
                    .updateSmartDeviceById({
                        smartDeviceId: this.smartDevice.id,
                        name: this.smartDevice.name,
                    })
                    .catch(console.error);
            }

            if (!this.hasNetwork && this.smartDeviceNetwork.id) {
                this._smartDeviceApiService
                    .updateSmartDeviceNetworkById({
                        smartDeviceNetworkId: this.smartDeviceNetwork.id,
                        name: this.smartDeviceNetwork.name,
                    })
                    .catch(console.error);
            }

            this.nextSlide();
        } else if (this.currentSlide.type === 'end') {
            if (this.currentSubSlide === 'success') {
                this.currentSubSlide = 'streams';
            }
        }
    }

    async setupCompleted(): Promise<void> {
        this.completedSetup = true;

        if (this.smartDeviceNetwork.id) {
            await this._smartDeviceApiService
                .storeSmartDevice({
                    name: this.smartDevice.name,
                    network_id: this.smartDeviceNetwork.id,
                    type: this.smartDevice.type,
                    guid: this.smartDevice.guid,
                    mac_address: this.smartDevice.mac_address,
                    ip_address: this.smartDevice.ip_address,
                })
                .then((res) => {
                    this.smartDevice = res.data;
                })
                .catch((err) => this._alertService.errorFromApi(err));
        } else {
            this.smartDeviceNetwork.name = this.smartDeviceNetwork.ssid;

            await this._smartDeviceApiService
                .storeSmartDeviceNetwork({
                    name: this.smartDeviceNetwork.ssid,
                    ssid: this.smartDeviceNetwork.ssid,
                    passphrase: this.smartDeviceNetwork.passphrase || null,
                    country_code: this.smartDeviceNetwork.country_code,
                })
                .then(async (res) => {
                    this.smartDeviceNetwork = res.data;

                    await this._smartDeviceApiService
                        .storeSmartDevice({
                            name: this.smartDevice.name,
                            network_id: res.data.id,
                            type: this.smartDevice.type,
                            guid: this.smartDevice.guid,
                            mac_address: this.smartDevice.mac_address,
                            ip_address: this.smartDevice.ip_address,
                        })
                        .then((deviceRes) => {
                            this.smartDevice = deviceRes.data;
                        })
                        .catch((err) => this._alertService.errorFromApi(err));
                })
                .catch((err) => this._alertService.errorFromApi(err));
        }
    }

    checkStreams(): void {
        this.checkedStreams = true;

        if (this.smartDevice.type === 'virt_cam') {
            try {
                this.videoRoomService.stopOwnStream().finally(() => {
                    this.videoRoomService.ownCamera.camType = CAMERA_TYPE.SMART_DEVICE;
                    this.smartDeviceService.initSmartDevice(this.smartDevice);
                    this.smartDeviceService.startStreaming(this.smartDevice, true).then((janusRoom) => {
                        this._ngZone.run(() => {
                            this.videoRoomService.ownUserMedia.startRemoteEvents();
                            this.videoRoomService.ownUserMedia.remoteEvents$
                                .pipe(takeUntil(this._unsubscribeAll))
                                .subscribe(() => {
                                    this._changeDetectorRef.detectChanges();
                                });

                            this.videoRoomService
                                .useExternalRoom(janusRoom, CAMERA_TYPE.SMART_DEVICE)
                                .catch(console.error);
                        });
                    });
                });
            } catch (err) {
                console.error('Add smart error', err);
            }
        }
    }

    connectToDeviceHotspot(): void {
        this.hideNextButton = true;

        if (this.udpService.status === 'searching') {
            this.udpService.stopUDPSocket();
        }

        setTimeout(() => {
            Wifi.connect({
                ssid: this.deviceSsid,
                password: this.devicePassphrase,
            })
                .then((res) => {
                    this.hideNextButton = false;

                    if (res.ssid == this.deviceSsid) {
                        this.onNextButtonTouched();
                    } else {
                        this.deviceHotspotFailed();
                    }
                })
                .catch((err) => {
                    if (err instanceof Capacitor.Exception) {
                        // Check for specific error messages or types
                        switch (err.message) {
                            case 'User denied permission':
                                this.locationPermission = 'denied';
                                break;
                            case 'ERROR_CONNECTION_FAILED':
                                // Connection issue, no hotspot?
                                break;
                        }
                    }

                    this.hideNextButton = false;
                    this.deviceHotspotFailed();
                });
        }, 1000);
    }

    async copyPasswordToClipboard(showAlert: boolean): Promise<void> {
        await Clipboard.write({
            string: this.devicePassphrase,
        });

        if (showAlert) {
            this._alertService.createAlert({
                title: $localize`:@@PASSWORD_COPIED_TO_CLIPBOARD:Password copied to clipboard`,
                icon: 'success',
            });
        }
    }

    async deviceHotspotFailed(): Promise<void> {
        await this.copyPasswordToClipboard(false);
        this.autoHotspotConnectionFailed = true;
    }

    askForCorrectHotspot(): void {
        $localize`:@@CONNECTED_TO_HOTSPOT_SSID_Q:Are you connected to the hotspot ${this.deviceSsid}:ssid:?`;
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@CORRECT_HOTSPOT:Correct hotspot`,
                    text: this._translateService.instant('CONNECTED_TO_HOTSPOT_SSID_Q', {
                        ssid: this.deviceSsid,
                    }),
                    cancelText: $localize`:@@NO:No`,
                    confirmText: $localize`:@@YES:Yes`,
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.currentSubSlide = 'success';
                    }
                });
            });
    }

    askForCorrectWiFi(): void {
        $localize`:@@CONNECTED_TO_WIFI_NETWORK_Q:Are you connected to the Wi-Fi network ${this.smartDeviceNetwork.ssid}:ssid:?`;
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@CORRECT_WIFI_NETWORK:Correct Wi-Fi network`,
                    text: this._translateService.instant('CONNECTED_TO_WIFI_NETWORK_Q', {
                        ssid: this.smartDeviceNetwork.ssid,
                    }),
                    cancelText: $localize`:@@NO:No`,
                    confirmText: $localize`:@@YES:Yes`,
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
            });
    }

    public dismissModal(returnValue = null): void {
        this._view.dismiss(returnValue);
    }

    public async configureWiFiStation() {
        if (this.smartDeviceNetwork.ssid) {
            if (!this.isNetworkPassphraseValid()) {
                return;
            }

            this.connectedToHotspot = true;
            this.sendingWiFiCredentials = true;

            await CapacitorSmartDeviceClient.sendTLSRequest({
                host: this.smartDevice.ip_address ?? this.deviceIpAddress,
                port: this.devicePort,
                data: JSON.stringify({
                    command: VirtCamCommands.ReadGUID,
                } as VirtCamCommandRequest),
            })
                .then((res) => {
                    const response = VirtCamHelper.getResponse(res);
                    if (response.status == VirtCamResponses.Success) {
                        this.smartDevice.guid = response.guid;
                    }
                })
                .catch((err) => {
                    console.error('Error: ' + JSON.stringify(err));
                });

            setTimeout(() => {
                CapacitorSmartDeviceClient.sendTLSRequest({
                    host: this.smartDevice.ip_address ?? this.deviceIpAddress,
                    port: this.devicePort,
                    data: JSON.stringify({
                        command: VirtCamCommands.ConfigureWiFiStation,
                        ssid: this.smartDeviceNetwork.ssid,
                        passphrase: this.smartDeviceNetwork.passphrase || '',
                        country: this.smartDeviceNetwork.country_code,
                    } as VirtCamConfigureWiFiStationRequest),
                })
                    .then((res) => {
                        this.sendingWiFiCredentials = false;

                        const status = VirtCamHelper.getResponse(res).status;
                        if (status == VirtCamResponses.Success) {
                            this.searchForDeviceViaUDP();
                        } else {
                            this._alertService.showPrompt(
                                $localize`:@@THE_DEVICE_CANNOT_REACH_THE_WIFI_NETWORK:The device cannot reach the Wi-Fi network`,
                                'error'
                            );
                        }
                    })
                    .catch((err) => {
                        if (err.toString().includes('Software caused connection abort')) {
                            this.searchForDeviceViaUDP();
                        } else {
                            this.sendingWiFiCredentials = false;

                            $localize`:@@NO_DEVICE_FOUND:No ${this.deviceType}:device: found!`;
                            this._alertService.showPrompt(
                                this._translateService.instant('NO_DEVICE_FOUND', {
                                    device: this.deviceType,
                                }),
                                'error'
                            );
                        }
                    });
            }, 2000);
        }
    }

    searchForDeviceViaUDP() {
        this.sendingWiFiCredentials = false;

        const udpSubscription = this.udpService.watchUDPSocket(30000).subscribe((response: any) => {
            if (!response) {
                this.foundDevice = false;
                if (udpSubscription) {
                    udpSubscription.unsubscribe();
                }
            } else if (response.data) {
                const parsedResponse = this.udpService.parseUDPResponse(response.data);
                if (!parsedResponse) return;

                const { guid, type } = parsedResponse;

                // Check if the device matches the current smart device or if the smart device has no GUID set
                if (!this.smartDevice.guid || guid === this.smartDevice.guid) {
                    const existingDevice = _.find(
                        this.existingDevices,
                        (device) => device.guid === guid && device.type === this.smartDevice.type
                    );

                    if (existingDevice) {
                        const existingNetwork = _.find(
                            this.networks,
                            (network) => network.id === existingDevice.network_id
                        );

                        if (existingNetwork && guid === this.smartDevice.guid) {
                            $localize`:@@SMART_DEVICE_ALREADY_EXISTS_IN_NETWORK:The device ${existingDevice.name}:device: already exists in your network ${existingNetwork.name}:network:`;
                            this._alertService.showPrompt(
                                this._translateService.instant('SMART_DEVICE_ALREADY_EXISTS_IN_NETWORK', {
                                    device: existingDevice.name,
                                    network: existingNetwork.name,
                                }),
                                'warning'
                            );
                            return;
                        }
                    }

                    const remoteAddress = response.remoteAddress;
                    const correctType = this.udpService.isCorrectSmartDeviceType(this.smartDevice.type, type);

                    if (this.udpService.isValidRemoteAddress(remoteAddress) && correctType) {
                        this.smartDevice.guid = guid;
                        this.smartDevice.ip_address = remoteAddress;
                        this.smartDevice.mac_address = parsedResponse.mac;

                        this.foundDevice = true;
                        this.udpService.status = null;
                        this.udpService.stopUDPSocket();

                        this._ngZone.run(() => {
                            setTimeout(() => {
                                this.onNextButtonTouched();
                            }, 1000);
                        });
                    }
                }
            }
        });

        // Set a timeout to verify if the device is connected to the correct Wi-Fi network after 17 seconds
        setTimeout(() => {
            Wifi.getSSID()
                .then((res) => {
                    if (res.ssid !== this.smartDeviceNetwork.ssid) {
                        this.askForCorrectWiFi();
                    }
                })
                .catch(() => {
                    this.askForCorrectWiFi();
                });
        }, 17000);
    }

    goToTroubleshoot(): void {
        switch (this.smartDevice.type) {
            case 'omni_scoring':
                this._iab.create('https://www.target-darts.co.uk/omni-instructions ', '_system', {
                    location: 'yes',
                } as InAppBrowserOptions);
                break;
            case 'virt_cam':
                this._iab.create('https://www.target-darts.co.uk/virt-camera-instructions', '_system', {
                    location: 'yes',
                } as InAppBrowserOptions);
                break;
        }
    }

    close(): void {
        this.dismissModal(this.smartDevice);
    }

    ngOnDestroy(): void {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }

        this.smartDeviceService.isSettingUpSmartDevice = false;
        this._alertService.showNetworkAlert = true;

        if (this.udpService.status === 'searching') {
            this.udpService.status = null;
            this.udpService.stopUDPSocket();
        }

        this.videoRoomService.ownUserMedia.stopRemoteEvents();
        this.smartDeviceService.clean();

        if (this._platform.is('capacitor')) {
            CapScreenOrientation.unlock();
        }

        if (this.checkedStreams) {
            this.userService
                .canUseSocialFeatures(false, false)
                .pipe(take(1))
                .subscribe((valid: boolean) => {
                    if (!valid) {
                        this.videoRoomService.stopOwnStream();
                    }
                });
        }
    }

    private isNetworkPassphraseValid(): boolean {
        if (this.smartDeviceNetwork.passphrase?.length < 8) {
            this._alertService.showPrompt(
                $localize`:@@CONNECTING_TO_A_NETWORK_WITH_A_PASSWORD_WITH_LESS_CHARACTERS_IS_NOT_SAFE:Connecting to a network with a password less than 8 characters is not safe and therefore not supported`,
                'error'
            );
            return false;
        }
        return true;
    }

    private promptForLocation(permission: PermissionState): void {
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@NEED_LOCATION_ACCESS:You need location access for this feature`,
                    text: $localize`:@@LOCATION_IS_NEEDED_FOR_THE_SMART_DEVICE_ENABLE_THE_PERMISSION:The location is needed to set up the smart device, please enable this permission in the device settings`,
                    icon: 'error',
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data && permission !== 'denied') {
                        this.requestLocationPermissions();
                    } else {
                        this.dismissModal();
                    }
                });
            });
    }
}
