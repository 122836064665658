import { Component, OnInit } from '@angular/core';
import { MediaStreamRecorder, RecordRTCPromisesHandler } from 'recordrtc';

@Component({
    selector: 'app-dev',
    templateUrl: 'dev.component.html',
    styleUrls: ['dev.scss'],
})
export class DevelopmentComponent implements OnInit {
    recorder: RecordRTCPromisesHandler;

    public async ngOnInit(): Promise<void> {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false,
        });

        setTimeout(() => {
            this.recorder = new RecordRTCPromisesHandler(stream, {
                type: 'video',
                recorderType: MediaStreamRecorder,
                mimeType: 'video/webm;codecs=vp8',
                disableLogs: false,
                // timeSlice: 1000,
                frameInterval: 24,
                // ondataavailable: (blob) => {
                //     console.log('blob', blob);
                // },
            });
        }, 1000);
    }

    public startRecording(): void {
        this.recorder.startRecording();
    }

    public stopRecording(): void {
        this.recorder.stopRecording().then(async () => {
            const blob = await this.recorder.getBlob();
            const data = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = data;
            a.download = 'test';
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
    }
}
