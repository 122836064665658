import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class FirebaseApiService extends ApiService {
    public createFirebaseToken = super.getFetcher().path('/firebase/token').method('get').create();
    public firebaseGameInvite = super.getFetcher().path('/firebase/game/invite').method('post').create();
    public firebaseJoinGame = super.getFetcher().path('/firebase/game/join').method('post').create();
    public firebaseContinueGame = super.getFetcher().path('/firebase/game/continue').method('post').create();
}
