<div fullscreen>
    <div class="auto-height-dialog">
        <div class="title-container">
            <div class="title" i18n="@@INACTIVITY_WARNING" [translate]="'INACTIVITY_WARNING'">Inactivity warning</div>
            @if (autoStopStreaming) {
                <div
                    class="subtitle"
                    [translate]="'INACTIVE_VIRT_CAM_WARNING_AUTO_STOP'"
                    i18n="@@INACTIVE_VIRT_CAM_WARNING_AUTO_STOP">
                    You seem to be inactive, but your Target VirtCam is still running. The Target VirtCam will
                    automatically stop the streams after:
                </div>
            } @else {
                <div class="subtitle" [translate]="'INACTIVE_VIRT_CAM_WARNING'" i18n="@@INACTIVE_VIRT_CAM_WARNING">
                    Your Target VirtCam is still running, do you wish to keep it streaming?
                </div>
            }
        </div>

        @if (autoStopStreaming) {
            <div class="mt-4 font-bebas-neue text-3xl text-center text-black">
                {{ seconds }}
            </div>
        }

        <div class="button-container flex justify-between">
            <app-primary-button
                type="submit"
                size="normal"
                color="red"
                [translate]="'STOP_STREAMING'"
                i18n="@@STOP_STREAMING"
                (click)="stopStreaming()">
                Stop streaming
            </app-primary-button>
            <app-primary-button
                type="submit"
                size="normal"
                color="green"
                [translate]="'KEEP_ON'"
                i18n="@@KEEP_ON"
                (click)="close()">
                Keep on
            </app-primary-button>
        </div>
    </div>
</div>
