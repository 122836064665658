import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RangeMode } from '@dc-core/dc-backend/dc-enums';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-require-triple-dialog',
    templateUrl: 'require-triple.dialog.html',
})
export class RequireTripleDialogComponent implements OnInit {
    @Input() goalTriple: any;
    @Input() range: RangeMode;

    private modalController: ModalController = inject(ModalController);
    private formBuilder: FormBuilder = inject(FormBuilder);

    public amountForm: FormGroup;

    public ngOnInit(): void {
        this.amountForm = this.formBuilder.group({
            goalTriple: [
                this.goalTriple,
                [Validators.required, Validators.min(1), Validators.max(this.range === 'one_to_seven' ? 7 : 20)],
            ],
        });
    }

    public save(): void {
        const goalTriple = parseInt(this.amountForm.get('goalTriple').value, 10);
        this.modalController.dismiss(isNaN(goalTriple) ? null : goalTriple);
    }

    public dismiss(returnValue: any = null): void {
        if (returnValue !== null) {
            returnValue = Math.floor(returnValue);
        }
        this.modalController.dismiss(returnValue);
    }
}
