import { Route } from '@angular/router';

export const actionReplaysRoutes: Route[] = [
    {
        path: 'action-replays',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('./pages/action-replays/action-replays.component').then((c) => c.ActionReplaysComponent),
            },
            {
                path: 'view',
                loadComponent: () =>
                    import('./pages/action-replay-view/action-replay-view.component').then(
                        (c) => c.ActionReplayViewComponent
                    ),
            },
        ],
    },
];
