import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';

@Component({
    selector: 'app-validation-label',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './validation-label.component.html',
    styleUrl: './validation-label.component.css',
})
export class ValidationLabelComponent {
    public textColor: InputSignal<'black' | 'white'> = input('black');
    public noAbsolute: InputSignal<boolean> = input(false);
}
