import { SinglesTraining, SinglesTrainingUser } from 'dc-core/dc-backend/dc-classes';
import { SinglesTrainingSettings, TrainingMode } from 'dc-core/dc-backend/dc-enums';
import { LOCAL_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import { DartCounterGameSettings } from '../settings-classes';
import {
    dateTimeFormat,
    THROW_HIGH_TO_LOW,
    THROW_LOW_TO_HIGH,
    TRAINING_MODES,
    TRHOW_RANDOM,
} from '../../settings.globals';
import * as moment from 'moment';

export class DartCounterSinglesTrainingSettings extends DartCounterGameSettings {
    public throwOrder: SinglesTrainingSettings;
    public throwOrders: { label: string; tag: string; type: SinglesTrainingSettings }[];

    public trainingMode: TrainingMode;
    public trainingModes = TRAINING_MODES;

    constructor() {
        //Set the super-class settings
        super();
        this.storageKey = LOCAL_GAME_SETTINGS_STORAGE_KEYS.SINGLES_TRAINING;

        this.throwOrders = [THROW_LOW_TO_HIGH, THROW_HIGH_TO_LOW, TRHOW_RANDOM];

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        } else {
            this.setDefaults();
        }
    }

    private setDefaults() {
        this.throwOrder = 'low_to_high';
        this.playerType = 'players';
        this.trainingMode = 'training';

        this.users = [];
        this.teams = [];
    }

    private setStoredSettings(storedSettings: DartCounterSinglesTrainingSettings) {
        this.throwOrder = storedSettings.throwOrder;
        this.trainingMode = storedSettings.trainingMode ?? 'training';

        this.users = storedSettings.users;
        this.teams = storedSettings.teams;
    }

    changeThrowOrder(setting: SinglesTrainingSettings): void {
        this.throwOrder = setting;
    }

    changeTrainingMode(trainingMode: TrainingMode): void {
        this.trainingMode = trainingMode;
    }

    saveGameSettings() {
        let storageObject = {
            throwOrder: this.throwOrder,
            playerType: this.playerType,
            users: this.users,
            teams: this.teams,
        };
        localStorage.setItem(this.storageKey, JSON.stringify(storageObject));
    }

    mapToSinglesTrainingObject(): SinglesTraining {
        const users: SinglesTrainingUser[] = [];
        this.users.forEach((user, index) => {
            users.push({
                id: index + 1,
                singles_training_id: 0,
                name: user.id === null ? user.full_name : user.first_name,
                user_id: user.id,
                user,
                ghost_name: user.id === null ? user.full_name : null,
                is_verified: this.user.id === user.id ? true : false,
            });
        });

        return <SinglesTraining>{
            id: 1,
            settings: this.throwOrder,
            training_mode: this.users.length > 1 ? this.trainingMode : 'training',
            started_at: moment().utc().format(dateTimeFormat),
            users: users,
            turns: [],
        };
    }
}
