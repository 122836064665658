import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/guards/auth.guard';
import { DevelopmentGuard } from 'src/guards/development.guard';
import { DowntimeGuard } from 'src/guards/downtime.guard';
import { NoDowntimeGuard } from 'src/guards/no-downtime.guard';
import { NoRedirectAuthGuard } from 'src/guards/no-redirect-auth.guard';
import { NotBannedGuard } from 'src/guards/not-banned.guard';
import { OnlineRestrictionGuard } from 'src/guards/online-restriction.guard';

import { authenticationRoutes } from './modules/authentication/authentication.routes';
import { gameRoutes } from './modules/games/games.routes';
import { profileRoutes } from './modules/profile/profile.routes';
import { smartDeviceRoutes } from './modules/smart-devices/smart-devices.routes';
import { testingRoutes } from './modules/testing/testing.routes';

const routes: Routes = [
    ...authenticationRoutes,

    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },

    {
        path: '',
        canActivate: [DowntimeGuard, NoRedirectAuthGuard],
        loadComponent: () => import('./core/shell/shell.component').then((c) => c.ShellComponent),
        children: [
            {
                path: 'connect',
                loadChildren: () => import('../pages/connect/connect.module').then((m) => m.ConnectModule),
            },
            {
                path: 'redeem-coupon',
                loadComponent: () =>
                    import('../pages/redeem-coupon/redeem-coupon.component').then((m) => m.RedeemCouponComponent),
            },
            {
                path: 'virt',
                loadComponent: () =>
                    import('../pages/redeem-coupon/virt-redeem-coupon/virt-redeem-coupon.component').then(
                        (m) => m.VirtRedeemCouponComponent
                    ),
            },
            {
                path: 'omni',
                loadComponent: () =>
                    import('../pages/redeem-coupon/omni-redeem-coupon/omni-redeem-coupon.component').then(
                        (m) => m.OmniRedeemCouponComponent
                    ),
            },
            {
                path: 'contact',
                loadChildren: () => import('../pages/contact/contact.module').then((m) => m.ContactModule),
            },

            ...testingRoutes,
        ],
    },

    // Main paths
    {
        path: '',
        canActivate: [DowntimeGuard, AuthGuard],
        loadComponent: () => import('./core/shell/shell.component').then((c) => c.ShellComponent),
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('./modules/account-settings/pages/settings-menu/settings-menu.module').then(
                        (m) => m.SettingsMenuModule
                    ),
            },
            {
                path: 'app-settings',
                loadChildren: () =>
                    import('./modules/account-settings/pages/app-settings/app-settings.module').then(
                        (m) => m.AppSettingsModule
                    ),
            },
            {
                path: 'subscriptions',
                loadChildren: () =>
                    import('./modules/account-settings/pages/subscriptions/subscriptions.module').then(
                        (m) => m.SubscriptionsModule
                    ),
            },
            {
                path: 'game-settings',
                loadChildren: () =>
                    import('../pages/global-game-settings/global-game-settings.module').then(
                        (m) => m.GlobalGameSettingsModule
                    ),
            },
            {
                path: 'profile',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () => import('../pages/profile/profile.module').then((m) => m.ProfileModule),
            },
            {
                path: 'profile/:id',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () => import('../pages/profile/profile.module').then((m) => m.ProfileModule),
            },
            ...profileRoutes,
            {
                path: 'search-users',
                loadChildren: () =>
                    import('../pages/search-users/search-users.module').then((m) => m.SearchUsersModule),
            },
            {
                path: 'friends',
                loadChildren: () => import('../pages/my-friends/my-friends.module').then((m) => m.MyFriendsModule),
            },
            {
                path: 'account-settings',
                data: {
                    hideTournamentNotification: true,
                },
                loadComponent: () =>
                    import('./modules/account-settings/pages/account-settings/account-settings.component').then(
                        (c) => c.AccountSettingsComponent
                    ),
            },
            {
                path: 'edit-password',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('./modules/account-settings/components/edit-password/edit-password.module').then(
                        (m) => m.EditPasswordModule
                    ),
            },
            {
                path: 'edit-email',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('./modules/account-settings/components/edit-email/edit-email.module').then(
                        (m) => m.EditEmailModule
                    ),
            },
            {
                path: 'blocked-players',
                loadChildren: () =>
                    import('./modules/account-settings/pages/blockedplayers/blockedplayers.module').then(
                        (m) => m.BlockedPlayersModule
                    ),
            },

            // Game paths
            {
                path: 'local-games',
                loadChildren: () => import('../pages/local-games/local-games.module').then((m) => m.LocalGamesModule),
            },

            // //Statistic paths
            {
                path: 'statistics',
                loadChildren: () => import('../pages/statistics/statistics.module').then((m) => m.StatisticsModule),
            },
            {
                path: 'statistics/checkout-121/segment',
                loadComponent: () =>
                    import(
                        './modules/statistics/checkout-121/pages/checkout-121-statistics-details/checkout-121-statistics-details.component'
                    ).then((c) => c.Checkout121StatisticsDetailsComponent),
            },
            {
                path: 'statistics/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/stat-details-list/stat-details-list.module').then(
                        (m) => m.StatDetailsListModule
                    ),
            },
            {
                path: 'tournament-statistics/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadComponent: () =>
                    import(
                        '../pages/statistics/tournament-stat-details-list/tournament-stat-details-list.component'
                    ).then((c) => c.TournamentStatDetailsListComponent),
            },

            // Game list
            {
                path: 'statistics/match/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/match/match-list.module').then((m) => m.MatchlistModule),
            },
            {
                path: 'statistics/cricket-tactics/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/cricket-tactics/cricket-tactics-list.module').then(
                        (m) => m.CricketTacticsListModule
                    ),
            },
            {
                path: 'statistics/around-the-clock/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/around-the-clock/around-the-clock-list.module').then(
                        (m) => m.AroundTheClockListModule
                    ),
            },
            {
                path: 'statistics/bobs-27/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/bobs-27/bobs-27-list.module').then((m) => m.Bobs27ListModule),
            },
            {
                path: 'statistics/shanghai/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/shanghai/shanghai-list.module').then(
                        (m) => m.ShanghaiListModule
                    ),
            },
            {
                path: 'statistics/singles-training/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/singles-training/singles-training-list.module').then(
                        (m) => m.SinglesTrainingListModule
                    ),
            },
            {
                path: 'statistics/doubles-training/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/doubles-training/doubles-training-list.module').then(
                        (m) => m.DoublesTrainingListModule
                    ),
            },
            {
                path: 'statistics/score-training/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/score-training/score-training-list.module').then(
                        (m) => m.ScoreTrainingListModule
                    ),
            },
            {
                path: 'statistics/checkout-121/list',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/game-list/checkout-121/checkout-121-list.module').then(
                        (m) => m.Checkout121ListModule
                    ),
            },

            // Game details
            {
                path: 'statistics/match/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/match/match-details.module').then((m) => m.MatchDetailsModule),
            },
            {
                path: 'statistics/cricket-tactics/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/cricket-tactics/cricket-tactics-details.module').then(
                        (m) => m.CricketTacticsDetailsModule
                    ),
            },
            {
                path: 'statistics/around-the-clock/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/around-the-clock/around-the-clock-details.module').then(
                        (m) => m.AroundTheClockDetailsModule
                    ),
            },
            {
                path: 'statistics/bobs-27/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/bobs-27/bobs-27-details.module').then(
                        (m) => m.Bobs27DetailsModule
                    ),
            },
            {
                path: 'statistics/shanghai/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/shanghai/shanghai-details.module').then(
                        (m) => m.ShanghaiDetailsModule
                    ),
            },
            {
                path: 'statistics/singles-training/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/singles-training/singles-training-details.module').then(
                        (m) => m.SinglesTrainingDetailsModule
                    ),
            },
            {
                path: 'statistics/doubles-training/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/doubles-training/doubles-training-details.module').then(
                        (m) => m.DoublesTrainingDetailsModule
                    ),
            },
            {
                path: 'statistics/score-training/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/score-training/score-training-details.module').then(
                        (m) => m.ScoreTrainingDetailsModule
                    ),
            },
            {
                path: 'statistics/checkout-121/details',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/statistics/details/checkout-121/checkout-121-details.module').then(
                        (m) => m.Checkout121DetailsModule
                    ),
            },

            // Online paths
            {
                path: 'online-games',
                loadChildren: () =>
                    import('../pages/online/online-games/online-games.module').then((m) => m.OnlineGamesModule),
            },
            {
                path: 'online-setup',
                data: {
                    hideTournamentNotification: true,
                },
                loadChildren: () =>
                    import('../pages/online-setup/online-setup.module').then((m) => m.OnlineSetupModule),
            },
            {
                path: 'friends-lobby',
                canActivate: [OnlineRestrictionGuard],
                loadChildren: () =>
                    import('../pages/online/friendLobby/friendLobby.module').then((m) => m.FriendLobbyModule),
            },
            {
                path: 'pre-game',
                data: {
                    hideTournamentNotification: true,
                },
                loadComponent: () =>
                    import('./modules/games/pages/pre-game/pre-game.component').then((c) => c.PreGameComponent),
            },
            {
                path: 'online/unfinished-games',
                loadChildren: () =>
                    import('../pages/online/unfinished-games/unfinished-games.module').then(
                        (m) => m.UnfinishedGamesModule
                    ),
            },
            ...gameRoutes,

            // Tournaments
            {
                path: 'tournaments',
                loadComponent: () =>
                    import('./modules/tournaments/pages/tournaments/tournaments.component').then(
                        (c) => c.TournamentsComponent
                    ),
            },
            {
                path: 'tournaments/history',
                loadComponent: () =>
                    import('./modules/tournaments/pages/tournaments/history/tournament-history.component').then(
                        (c) => c.TournamentHistoryComponent
                    ),
            },
            {
                path: 'tournaments/:id',
                data: {
                    hideTournamentNotification: true,
                },
                loadComponent: () =>
                    import('./modules/tournaments/pages/tournaments/view/tournament-view.component').then(
                        (c) => c.TournamentViewComponent
                    ),
            },

            // Other
            {
                path: 'action-replays',
                loadChildren: () =>
                    import('../pages/action-replays/action-replays.module').then((m) => m.ActionReplaysModule),
            },
            ...smartDeviceRoutes,
            {
                path: 'chats',
                loadChildren: () =>
                    import('../pages/private-chats/private-chats.module').then((m) => m.PrivateChatsModule),
            },
        ],
    },

    {
        path: '',
        canActivate: [NoDowntimeGuard],
        children: [
            {
                path: 'maintenance',
                loadChildren: () => import('../pages/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
            },
        ],
    },

    {
        path: '',
        canActivate: [NotBannedGuard],
        children: [
            {
                path: 'banned',
                loadChildren: () => import('../pages/banned/banned.module').then((m) => m.BannedModule),
            },
        ],
    },

    {
        path: '',
        canActivate: [DevelopmentGuard, AuthGuard],
        children: [
            {
                path: 'dev',
                loadChildren: () => import('../pages/dev/dev.module').then((m) => m.DevelopmentModule),
            },
        ],
    },

    { path: '**', redirectTo: '/dashboard' },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
