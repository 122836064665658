<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern">
    <app-page-header mainNavigation="back" defaultBack="statistics">
        <div title>{{ title }}</div>
    </app-page-header>
    <div swipe (next)="goToWorst()" (previous)="goToBest()" appWebContainer>
        <div class="tab-toggle-container">
            <div
                class="tab-toggle"
                [class.inner-border-b-2-orange]="segment === 'best'"
                [class.opacity-60]="segment !== 'best'"
                (click)="changeSegment('best')">
                <div [translate]="'BEST'" i18n="@@BEST">Best</div>
            </div>
            <div
                class="tab-toggle"
                [class.inner-border-b-2-orange]="segment === 'worst'"
                [class.opacity-60]="segment !== 'worst'"
                (click)="changeSegment('worst')">
                <div [translate]="'WORST'" i18n="@@WORST">Worst</div>
            </div>
        </div>
        <div class="m-3">
            <div class="statistics-card mt-4">
                <div class="title-container">
                    <div class="icon p-1">
                        <app-icon icon="trending_up" size="medium" class="text-black"></app-icon>
                    </div>
                    @if (segment === 'best') {
                        <div class="title">
                            <span [translate]="'BEST'" i18n="@@BEST">Best</span>
                            <span class="ml-1.5 lowercase">{{ title }}</span>
                        </div>
                    }
                    @if (segment === 'worst') {
                        <div class="title">
                            <span [translate]="'WORST'" i18n="@@WORST">Worst</span>
                            <span class="ml-1.5 lowercase">{{ title }}</span>
                        </div>
                    }
                </div>

                <div class="stats-list-container">
                    @for (game of items[segment]; track game; let i = $index, isOdd = $odd, isEven = $even) {
                        <div
                            class="grid grid-cols-12 p-2 text-black cursor-pointer"
                            (click)="ShowDetail(game)"
                            [ngClass]="{ 'bg-neutral-50': isOdd, '': isEven }">
                            <div class="col-span-2">#{{ i + 1 }}</div>
                            <div class="col-span-4 text-right">
                                {{
                                    GetDate(game.date_played) | date: 'dd MMM yyyy' : '' : translateService.currentLang
                                }}
                            </div>
                            @if (numberFormat === 'number') {
                                <div class="col-span-4 text-right">
                                    {{ game.value || 0 }}
                                </div>
                            }
                            @if (numberFormat === 'decimal') {
                                <div class="col-span-4 text-right">
                                    {{ game.value || 0 | number: '1.2-2' }}
                                </div>
                            }
                            @if (numberFormat === 'percentage') {
                                <div class="col-span-4 text-right">{{ game.value || 0 | number: '1.2-2' }}%</div>
                            }
                            <div class="col-span-2 flex items-center justify-end">
                                <ion-icon name="chevron-forward-outline"></ion-icon>
                            </div>
                        </div>
                    }
                    @if (!auth.user.is_ultimate) {
                        <app-primary-button
                            size="normal"
                            addedClasses="w-full mt-3"
                            color="blue"
                            (click)="OpenSubscriptionDialog()"
                            [translate]="'UPGRADE_BTN'"
                            i18n="@@UPGRADE_BTN">
                            Upgrade
                        </app-primary-button>
                        <div
                            [translate]="'TOP10_UPGRADE'"
                            i18n="@@TOP10_UPGRADE"
                            class="text-left text-sm text-neutral-600">
                            Do you want to see the top 25 of all your statistics? Upgrade your account to Ultimate!
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
