import { Pipe, PipeTransform } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';

@Pipe({
    name: 'liveGamesFilter',
    pure: false,
})
export class LiveGamesFilterPipe implements PipeTransform {
    transform(items: OnlineGameplay[], filter: any): any {
        if (!items || !filter) {
            return items;
        }

        if (filter.search) {
            const searchparts = filter.search.split(' ');
            let resultarray = JSON.parse(JSON.stringify(items));
            searchparts.forEach((searchPart) => {
                resultarray = this.filterRecursive(resultarray, searchPart.toLowerCase());
            });
            return resultarray;
        }

        return items;
    }

    filterRecursive(items: OnlineGameplay[], search) {
        return items.filter(
            (item) =>
                (item.players[0].full_name && item.players[0].full_name.toLowerCase().indexOf(search) !== -1) ||
                (item.players[1].full_name && item.players[1].full_name.toLowerCase().indexOf(search) !== -1)
        );
    }
}
