import { Component, inject, OnInit } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { PreferenceLocalStorageKey } from '@dc-core/dc-services/preference/preference.models';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKey } from 'dc-core/dc-localstorage';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { take } from 'rxjs';
import { DC_LANGUAGES } from 'src/app/app.globals';

@Component({
    selector: 'app-change-language-dialog',
    templateUrl: 'change-language.dialog.html',
    styleUrls: ['change-language.dialog.scss'],
})
export class ChangeLanguageDialogComponent implements OnInit {
    private modalController: ModalController = inject(ModalController);
    private translateService: TranslateService = inject(TranslateService);
    private preferenceService: DartCounterPreferenceService = inject(DartCounterPreferenceService);
    private userApiService: UserApiService = inject(UserApiService);

    public lang: string = null;
    public languages = [];

    public ngOnInit(): void {
        this.languages = DC_LANGUAGES;
        if (this.translateService.currentLang) {
            this.lang = this.translateService.currentLang;
        } else {
            this.lang = 'en';
        }
    }

    public changeLanguage(language: string) {
        this.lang = language;
        this.translateService.resetLang(this.translateService.currentLang);
        this.translateService
            .use(this.lang)
            .pipe(take(1))
            .subscribe(() => {
                localStorage.setItem(LocalStorageKey.language, this.lang);
                this.preferenceService.changePreference(PreferenceLocalStorageKey.lang, this.lang);
                this.preferenceService.langSignal.set(this.lang);
            });
    }

    public dismiss(returnValue: any = null): void {
        this.userApiService.updateUser({
            locale: this.lang,
        });

        this.modalController.dismiss(returnValue);
    }
}
