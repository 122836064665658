import { Component, Input, OnInit } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { Unsubscribe } from 'firebase/auth';
import { DocumentSnapshot } from 'firebase/firestore';

import { JoyrideService } from '../../../providers/ngx-joyride/src/lib/services/joyride.service';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-page-global-match-info',
    templateUrl: 'globalMatchInfo.html',
})
export class GlobalMatchInfoComponent implements OnInit {
    @Input() onlineGameplay: OnlineGameplay;
    @Input() user: DCFireStoreUser;

    onlineFunctions = new OnlineFunctions();

    private _unsubscribeLobbyGame: Unsubscribe;

    constructor(
        private ga: DartCounterAnalyticsService,
        public menu: MenuController,
        public platform: Platform,
        public auth: AuthService,
        private readonly joyrideService: JoyrideService,
        private modal: ModalController,
        private _lobbySettingsService: LobbySettingsCollectionService,
        private _alertService: DartCounterAlertService
    ) {}

    ngOnInit() {
        this.ga.trackView('Match | Lobby game preview');

        this._unsubscribeLobbyGame = this._lobbySettingsService.watchDoc(
            this.onlineGameplay.doc_id,
            (docSnapshot: DocumentSnapshot<OnlineGameplay>) => {
                const gameplay = docSnapshot.data();

                if (!gameplay) {
                    this._alertService.createAlert({
                        icon: 'warning',
                        title: $localize`:@@LOBBY_MATCH_REMOVED:The game is removed from the lobby`,
                    });
                    this.DismissModal();
                }
            }
        );

        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog)) ?? true;
        if (showTour) {
            this.showTour();
            localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
        }
    }

    public DismissModal() {
        this._unsubscribeLobbyGame();
        this.modal.dismiss(false);
    }

    public join() {
        this._unsubscribeLobbyGame();
        this.modal.dismiss(true);
    }

    public showTour(index = null) {
        let steps = ['firstStep'];
        if (index !== null) {
            steps = [steps[index]];
        }

        setTimeout(() => {
            this.joyrideService.startTour({
                steps: steps,
                showPrevButton: false,
                themeColor: '#444',
            });
        }, 500);
    }
}
