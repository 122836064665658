<div class="flex flex-col items-center font-bebas-neue {{ classes }}">
    <div
        class="text-xxs leading-none !text-white"
        *ngIf="showLabel && type === 'legs'"
        [translate]="'LEGS'"
        i18n="@@LEGS">
        Legs
    </div>
    <div
        class="text-xxs leading-none !text-white"
        *ngIf="showLabel && type === 'sets'"
        [translate]="'SETS'"
        i18n="@@SETS">
        Sets
    </div>
    <ng-content></ng-content>
</div>
