@if (timeFromDate !== undefined && (!hideTimerWhenExpired() || (hideTimerWhenExpired() && hasTimer))) {
    <div
        class="w-max flex items-center space-x-0.5 rounded-sm bg-white px-2 py-1.5 pr-4 text-black border-2 border-orange">
        <app-icon icon="timer"></app-icon>
        <div class="pt-0.5 font-bebas-neue text-xl leading-none">
            @if (timeFromDate !== null) {
                {{ timeFromDate ?? 0 }}
            } @else {
                <div [translate]="'SOON'" i18n="@@SOON">Soon</div>
            }
        </div>
    </div>
}
