import { inject, Injectable } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Subscription } from 'rxjs';

@Injectable()
export class PopoverService {
    private screenOrientation: ScreenOrientation = inject(ScreenOrientation);

    currentPopover: HTMLIonPopoverElement;
    orientationSubscription: Subscription;

    init(popover: HTMLIonPopoverElement): void {
        this.destroy();

        this.currentPopover = popover;
        this.orientationSubscription = this.screenOrientation.onChange().subscribe(() => {
            this.destroy();
        });
    }

    destroy(): void {
        if (this.orientationSubscription) {
            this.orientationSubscription.unsubscribe();
            this.orientationSubscription = null;
        }

        if (this.currentPopover) {
            this.currentPopover.dismiss();
            this.currentPopover = null;
        }
    }
}
