import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export type YourVirtCamViewDialogPayload = {
    userId: number;
};

@Component({
    selector: 'app-your-virt-cam-view-dialog',
    templateUrl: 'your-virt-cam-view.dialog.html',
})
export class YourVirtCamViewDialogComponent implements OnInit, OnDestroy {
    @Input() userId: number;
    title: string = null;

    private _backButtonSubscription: Subscription;

    constructor(
        public videoRoomService: JanusVideoRoomService,
        public ingameCameraService: InGameCameraService,
        public view: ModalController,
        private _platform: Platform,
        private _translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.title = this._translate.instant('YOUR_DEVICE_VIEW', {
            device: 'Target VirtCam',
        });

        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    ngOnDestroy(): void {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }
    }
}
