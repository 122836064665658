import { Component, NgZone } from '@angular/core';
import { AuthApiService } from '@dc-api/auth.api.service';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import OneSignal from 'onesignal-cordova-plugin';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AuthService } from 'src/services/auth.service';

/**
 * Generated class for the Settings page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-page-settings-menu',
    templateUrl: 'settings-menu.html',
})
export class SettingsMenuViewComponent {
    constructor(
        public nav: NavController,
        public auth: AuthService,
        public preferenceService: DartCounterPreferenceService,
        private ga: DartCounterAnalyticsService,
        private platform: Platform,
        public translate: TranslateService,
        private _alertService: DartCounterAlertService,
        private authApiService: AuthApiService,
        public modal: ModalController,
        private _ngZone: NgZone
    ) {}

    appSettings() {
        this.nav.navigateForward('app-settings');
    }

    showGlobalGameSettings() {
        this.nav.navigateForward('game-settings');
    }

    showAccount() {
        this.nav.navigateForward('account-settings');
    }

    Logout() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.MENU, GA_EVENTACTIONS.LOGOUT);

        if (this.platform.is('capacitor') && !this.platform.is('desktop')) {
            try {
                // When the user logs out from a device, we want to remove this token from the database
                // We no longer need to send a push notification to this device for this user.
                OneSignal.getDeviceState((response) => {
                    if (response?.userId && response?.pushToken) {
                        this.authApiService
                            .unlinkPushToken({ one_signal_id: response.userId, token: response.pushToken })
                            .then(() => {
                                this.logoutAndRedirect();
                            })
                            .catch((err) => {
                                this._alertService.errorFromApi(err);
                            });
                    } else {
                        this.logoutAndRedirect();
                    }
                });
            } catch (e) {
                this.logoutAndRedirect();
            }
        } else {
            this.logoutAndRedirect();
        }
    }

    logoutAndRedirect(): void {
        this.auth.signOut();
        setTimeout(() => {
            this._ngZone.run(() => {
                this.nav.navigateRoot('/login');
            });
        }, 200);
    }
}
