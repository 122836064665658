/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { ProfilePhotoComponent } from '@components/profile-photo/profile-photo.component';
import { FriendApiService } from '@dc-api/friend.api.service';
import { PrivateChatApiService } from '@dc-api/private-chat.api.service';
import { ChatConversation, User } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { IonicModule, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DCLoadingService } from '@providers/DCLoadingService';
import { debounceTime, Subscription } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { CropProfilePhotoDialogComponent } from 'src/dialogs/crop-profile-photo/crop-profile-photo.dialog';
import { WebContainerDirective } from 'src/directives/web-container.directive';
import { environment } from 'src/environments/environment';

export type ChatType = 'direct' | 'group';
export type GroupView = 'select' | 'overview';

@Component({
    selector: 'app-chats-start-chat-dialog',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrimaryButtonComponent,
        ProfilePhotoComponent,
        TranslateModule,
        IonicModule,
        IconComponent,
        WebContainerDirective,
    ],
    templateUrl: 'chats-start-chat-dialog.component.html',
})
export class ChatsStartChatDialogComponent implements OnInit, OnDestroy {
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private platform: Platform = inject(Platform);
    private friendApiService: FriendApiService = inject(FriendApiService);
    private modalController: ModalController = inject(ModalController);
    private modal2Controller: ModalController = inject(ModalController);
    private loadingService: DCLoadingService = inject(DCLoadingService);
    private privateChatApiService: PrivateChatApiService = inject(PrivateChatApiService);
    private userService: UserService = inject(UserService);
    private navController: NavController = inject(NavController);

    public friends: User[] = null;
    public chatType: ChatType = 'direct';
    public groupView: GroupView = 'select';
    public groupName: string = '';
    public groupIcon: string = null;
    public selected: User[] = [];
    public selectedIds: number[] = [];
    public searchControl: UntypedFormControl = new UntypedFormControl();

    public errors: { groupName: boolean } = { groupName: false };

    private destroyRef: DestroyRef = inject(DestroyRef);

    private backButtonSubscription: Subscription;

    constructor() {}

    public ngOnInit(): void {
        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });

        this.searchFriends(null);

        this.searchControl.valueChanges
            .pipe(debounceTime(600), takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                if (value.length) {
                    this.searchFriends(value);
                } else {
                    this.searchFriends(null);
                }
            });
    }

    public ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }
    }

    public close(returnValue: ChatConversation = null): void {
        this.modalController.dismiss(returnValue);
    }

    public toggleChatType(): void {
        if (this.chatType === 'direct') {
            this.chatType = 'group';
        } else {
            this.chatType = 'direct';
        }

        this.groupView = 'select';
        this.groupName = '';
        this.groupIcon = null;
        this.selected = [];
        this.selectedIds = [];

        this.errors = { groupName: false };
    }

    public toggleGroupView(): void {
        if (this.groupView === 'select') {
            if (!this.selectedIds.length) {
                this.alertService.createAlert({
                    title: $localize`:@@PLEASE_SELECT_TWO_OR_MORE_MEMBERS:Please select two or more members`,
                    icon: 'error',
                });
                return;
            }

            this.groupView = 'overview';
        } else {
            this.groupView = 'select';
        }

        this.groupName = '';
        this.groupIcon = null;

        this.errors = { groupName: false };
    }

    public searchFriends(searchString: string): void {
        if (searchString) {
            this.friendApiService.getFriends({ search: searchString, limit: 9999 }).then((res) => {
                this.friends = res.data.data;
            });
        } else {
            this.friendApiService.getFriends({ limit: 100 }).then((res) => {
                this.friends = res.data.data;
            });
        }
    }

    public toggleFriend(friend: User) {
        if (this.chatType === 'direct') {
            this.privateChatApiService
                .createPrivateChat({ direct_message: true, user_ids: [friend.id] })
                .then((res) => {
                    if (res.data) {
                        this.close(res.data);
                    }
                })
                .catch((err) => {
                    this.alertService.errorFromApi(err);
                });
        } else if (this.chatType === 'group') {
            if (!this.selectedIds.includes(friend.id)) {
                this.selected.push(friend);
                this.selectedIds.push(friend.id);
            } else {
                const index = this.selectedIds.indexOf(friend.id);
                this.selected.splice(index, 1);
                this.selectedIds.splice(index, 1);
            }
        }
    }

    public uploadGroupIcon(): void {
        this.userService.checkSuspension('profile_editing', true).then(async (suspension) => {
            if (!suspension.suspended) {
                if (this.platform.is('capacitor')) {
                    try {
                        const checkCamPermissions = await Camera.checkPermissions();
                        if (checkCamPermissions.camera !== 'granted' || checkCamPermissions.photos !== 'granted') {
                            const requestCamResult = await Camera.requestPermissions();
                            if (requestCamResult.camera === 'granted' && requestCamResult.photos === 'granted') {
                                setTimeout(() => {
                                    this.uploadGroupIcon();
                                }, 500);
                                return;
                            } else {
                                throw 'No permission';
                            }
                        }
                    } catch (_) {
                        this.alertService.createAlert({
                            title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                            icon: 'error',
                            timer: 2000,
                            timerProgressBar: true,
                            showCloseButton: true,
                        });
                        return;
                    }
                }

                await Camera.getPhoto({
                    height: 500,
                    width: 500,
                    allowEditing: false,
                    correctOrientation: true,
                    quality: 90,
                    resultType: CameraResultType.Base64,
                    webUseInput: environment.isWeb,
                }).then((imageData: Photo) => {
                    this.modal2Controller
                        .create({
                            component: CropProfilePhotoDialogComponent,
                            componentProps: {
                                image: imageData.base64String,
                                format: imageData.format,
                            },
                            cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then(async (dialogRes) => {
                                if (dialogRes.data) {
                                    this.groupIcon = dialogRes.data;
                                }
                            });
                        });
                });
            }
        });
    }

    public createGroupChat(): void {
        if (!this.groupName) {
            this.alertService.createAlert({
                title: $localize`:@@FILL_IN_REQUIRED_FIELDS:Please fill in the required fields`,
                icon: 'error',
            });

            this.errors.groupName = true;
            return;
        }

        this.loadingService.ShowDefaultLoader();

        this.privateChatApiService
            .createPrivateChat({
                name: this.groupName,
                encoded_group_icon: this.groupIcon,
                direct_message: false,
                user_ids: this.selectedIds,
            })
            .then((res) => {
                if (res.data) {
                    this.close(res.data);
                }
            })
            .catch((err) => {
                this.alertService.errorFromApi(err, null, true);
            })
            .finally(() => {
                this.loadingService.DismissLoader();
            });
    }

    public searchUsers() {
        this.navController.navigateForward('search-users');
        this.close();
    }
}
