import { inject, Injectable } from '@angular/core';
import { OnlineGameplay, PLAYERSTATUS } from '../dc-backend/dc-interfaces';
import { PausesCollectionService } from '../dc-firestore/collection-helpers/pauses.collection.service';
import { DCFireStorePause } from '../dc-firestore/globals/firestore.tables';
import { IntervalManager } from '../dc-logging/interval.manager';
import { INTERVAL_KEY } from '../dc-logging/subscription_enums';
import { DartCounterAlertService } from '../dc-services/alert.service';
import { Unsubscribe } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { ONLINE_LOCAL_ACTIONS } from './online-game/online.ingame.globals';
import { TournamentService } from 'src/app/modules/tournaments/services/tournament.service';
import { CricketTacticsUser, MatchUser, ReportTurn } from '@dc-core/dc-backend/dc-classes';
import { DartCounterPlayingMatch } from './in-game/match/match.in-game.classes';
import {
    DartCounterCricketTacticsGame,
    DartCounterCricketTacticsSet,
} from './in-game/cricket-tactics/cricket-tactics.in-game.classes';

@Injectable()
export class DCOnlineCore {
    private tournamentService: TournamentService = inject(TournamentService);

    public isSpectating: boolean = false;
    public preferOmniOverCam: boolean = false;

    public currentUserID: number = null;

    // Pause functionality
    public pause: DCFireStorePause;
    public pauseSeconds: number;
    public isReadyToContinue = false;
    public pausePlayersReady = 0;
    public unsubscribeActivePause: Unsubscribe;

    //Subjects
    public localActions$: Subject<ONLINE_LOCAL_ACTIONS> = new Subject();

    public timeLeft: number = null;
    public onlineGameplay: OnlineGameplay;

    public oponnentLeftTimeout = null;
    public waitingForOpponentTimeout = null;
    public waitingForOpponentAlert: string = null;

    constructor(
        private intervalManager: IntervalManager,
        public pausesCollectionService: PausesCollectionService,
        public alertService: DartCounterAlertService
    ) {}

    readyToContinue(userUid) {
        if (this.isReadyToContinue) {
            return false;
        }

        let myIndex: number = null;
        this.pause.players.forEach((player, index) => {
            if (player.uid == userUid) {
                myIndex = index;
            }
        });

        const firestoreReady = <DCFireStorePause>{};
        if (myIndex == 0) {
            firestoreReady.playerOneStatus = PLAYERSTATUS.READY;
        } else {
            firestoreReady.playerTwoStatus = PLAYERSTATUS.READY;
        }

        this.pausePlayersReady++;
        this.pausesCollectionService.setReady(firestoreReady, this.pause.doc_id);

        if (this.pausePlayersReady == this.pause.players.length) {
            this.completePause();
        } else {
            this.isReadyToContinue = true;
        }
    }

    completePause(): void {
        this.pause.completed = true;
        this.pausesCollectionService.updatePause(this.pause);
    }

    getTimerValue(): number {
        return this.timeLeft;
    }

    getPauseTimerValue(): string {
        return moment.utc(this.pauseSeconds * 1000).format('m:ss');
    }

    resetPause(completedAt?: Timestamp): void {
        if (this.unsubscribeActivePause) {
            this.unsubscribeActivePause();
        }

        this.intervalManager.clear(INTERVAL_KEY.PAUSE_TIMER);
        this.pause = null;
        this.isReadyToContinue = false;
        this.pausePlayersReady = 0;

        if (completedAt) {
            this.updateTimerEndsAt(this.onlineGameplay, completedAt);
        }
    }

    initPauseTimer(): void {
        this.intervalManager.clear(INTERVAL_KEY.PAUSE_TIMER);

        let pauseSeconds = this.pause.break_ends_at.seconds - Timestamp.now().seconds;
        // If 3 mins *60 = 180 the max seconds = 180 (so we take the lowest number)
        pauseSeconds = Math.min(pauseSeconds, this.pause.minutes * 60);
        // Seconds below zero is prevented
        this.pauseSeconds = Math.max(0, pauseSeconds);

        let pauseDurationInterval = setInterval(() => {
            if (this.pauseSeconds > 0) {
                this.pauseSeconds--;
            } else {
                this.completePause();
            }
        }, 1000);

        this.intervalManager.track('online gameplay', INTERVAL_KEY.PAUSE_TIMER, pauseDurationInterval);
    }

    pauseTimer() {
        this.intervalManager.clear(INTERVAL_KEY.INGAME_TIMER);

        if (this.waitingForOpponentTimeout) {
            clearTimeout(this.waitingForOpponentTimeout);
            this.waitingForOpponentTimeout = null;
        }
        if (this.oponnentLeftTimeout) {
            clearTimeout(this.oponnentLeftTimeout);
            this.oponnentLeftTimeout = null;
        }
        if (this.waitingForOpponentAlert) {
            this.alertService.closeAlertById(this.waitingForOpponentAlert);
            this.waitingForOpponentAlert = null;
        }
    }

    checkPauseReadyPlayers() {
        let playersReady = 0;
        if (this.pause.playerOneStatus == PLAYERSTATUS.READY) {
            playersReady++;
        }
        if (this.pause.playerTwoStatus == PLAYERSTATUS.READY) {
            playersReady++;
        }

        this.pausePlayersReady = playersReady;

        if (this.pausePlayersReady == this.pause.players.length) {
            this.completePause();
        }
    }

    updateTimerEndsAt(onlineGameplay: OnlineGameplay, forcedTimerEndsAt: Timestamp): void {
        onlineGameplay.timer_ends_at = Timestamp.fromDate(
            moment(forcedTimerEndsAt.toDate()).add(this.onlineGameplay.inGameTimer, 'seconds').toDate()
        );
    }

    setTimeLeft(useTimerEndsAt = false): void {
        let timeLeft: number;
        if (this.onlineGameplay.timer_ends_at && (this.timeLeft == null || useTimerEndsAt)) {
            const now = Timestamp.now();
            const endTime = this.onlineGameplay.timer_ends_at;
            // Calculate time left based on the difference between end time and current time
            timeLeft = endTime.seconds - now.seconds;
        } else {
            // Default to inGameTimer if timer_ends_at is not set
            timeLeft = _.cloneDeep(this.onlineGameplay.inGameTimer);
        }

        // If inGameTimer = 40 the max timeLeft = 40 (so we take 40 or any number lower)
        timeLeft = Math.min(this.onlineGameplay.inGameTimer, timeLeft);
        // Prevent timer value below zero
        this.timeLeft = Math.max(0, timeLeft);
    }

    startTimer(isOwnTimer: boolean): void {
        this.pauseTimer();

        let timerInterval = setInterval(() => {
            this.timeLeft--;
            if (this.timeLeft <= 0) {
                this.intervalManager.clear(INTERVAL_KEY.INGAME_TIMER);

                if (isOwnTimer) {
                    this.localActions$.next(ONLINE_LOCAL_ACTIONS.OWN_TIMER_RAN_OUT);
                } else {
                    this.waitingForOpponentTimeout = setTimeout(() => {
                        this.waitingForOpponentAlert = 'waiting-for-opponent';
                        this.alertService.createAlert({
                            id: this.waitingForOpponentAlert,
                            icon: 'warning',
                            title: $localize`:@@WAITING_FOR_OPPONENT:Waiting for opponent`,
                            timer: 10000,
                            timerProgressBar: true,
                            showCloseButton: true,
                        });

                        this.oponnentLeftTimeout = setTimeout(() => {
                            this.tournamentService.removeNotRespondingUser();
                            this.localActions$.next(ONLINE_LOCAL_ACTIONS.OPPONENT_NOT_RESPONDING);
                        }, 10000);
                    }, 5000);
                }
            }
        }, 1000);

        this.intervalManager.track('online gameplay', INTERVAL_KEY.INGAME_TIMER, timerInterval);
    }

    clearSubjects() {
        this.localActions$ = new Subject();

        this.pauseTimer();
        this.resetPause();
        this.intervalManager.clear(INTERVAL_KEY.PAUSE_TIMER);

        this.onlineGameplay = null;
    }
}
