import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class GameLabelApiService extends ApiService {
    public getGameLabels = super.getFetcher().path('/game-labels').method('get').create();
    public storeGameLabel = super.getFetcher().path('/game-labels').method('post').create();
    public showGameLabelById = super.getFetcher().path('/game-labels/{gameLabelId}').method('get').create();
    public updateGameLabelById = super.getFetcher().path('/game-labels/{gameLabelId}').method('put').create();
    public deleteGameLabelById = super.getFetcher().path('/game-labels/{gameLabelId}').method('delete').create();
    public labelGame = super.getFetcher().path('/game-labels/label').method('post').create();
    public unlabelGame = super.getFetcher().path('/game-labels/unlabel').method('post').create();

    public getGameLabelCategories = super.getFetcher().path('/game-label-categories').method('get').create();
    public storeGameLabelCategory = super.getFetcher().path('/game-label-categories').method('post').create();
    public showGameLabelCategoryById = super
        .getFetcher()
        .path('/game-label-categories/{gameLabelCategoryId}')
        .method('get')
        .create();
    public updateGameLabelCategoryById = super
        .getFetcher()
        .path('/game-label-categories/{gameLabelCategoryId}')
        .method('put')
        .create();
    public deleteGameLabelCategoryById = super
        .getFetcher()
        .path('/game-label-categories/{gameLabelCategoryId}')
        .method('delete')
        .create();
}
