import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class TournamentApiService extends ApiService {
    public getTournaments = super.getFetcher().path('/tournaments').method('get').create();
    public getParticipatingTournaments = super.getFetcher().path('/tournaments/participating').method('get').create();
    public checkTournamentRestriction = super
        .getFetcher()
        .path('/tournaments/check-restriction')
        .method('post')
        .create();
    public storeTournament = super.getFetcher().path('/tournaments').method('post').create();
    public showTournamentByIdOrSlug = super.getFetcher().path('/tournaments/{tournamentId}').method('get').create();
    public updateTournamentById = super.getFetcher().path('/tournaments/{tournamentId}').method('put').create();
    public deleteTournamentById = super.getFetcher().path('/tournaments/{tournamentId}').method('delete').create();
    public joinTournament = super.getFetcher().path('/tournaments/{tournamentId}/join').method('post').create();
    public leaveTournament = super.getFetcher().path('/tournaments/{tournamentId}/leave').method('post').create();
    public kickParticipantFromTournament = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/kick/{participantId}')
        .method('post')
        .create();
    public inviteModeratorToTournament = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/invite-moderator')
        .method('post')
        .create();
    public kickModeratorFromTournament = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/kick-moderator/{participantId}')
        .method('post')
        .create();
    public acceptInviteToTournament = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/accept-invite/{participantId}')
        .method('post')
        .create();
    public checkInForTournament = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/check-in')
        .method('post')
        .create();
    public checkOutFromTournament = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/check-out')
        .method('post')
        .create();
    public withdrawFromTournament = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/withdraw')
        .method('post')
        .create();
    public approveTournamentParticipant = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/approve/{participantId}')
        .method('post')
        .create();
    public declineTournamentParticipant = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/decline/{participantId}')
        .method('post')
        .create();
    public disqualifyTournamentParticipant = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/disqualify/{userId}')
        .method('post')
        .create();
    public showTournamentGame = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/game/{gameId}')
        .method('get')
        .create();
    public startTournamentGame = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/game/{gameId}/start')
        .method('post')
        .create();
    public updateTournamentGame = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/game/{gameId}')
        .method('put')
        .create();
    public finishTournamentGame = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/game/{gameId}/finish')
        .method('post')
        .create();
    public leaveTournamentGame = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/game/{gameId}/leave')
        .method('post')
        .create();
    public quitTournamentGame = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/game/{gameId}/quit')
        .method('post')
        .create();
    public createNewTournamentAnnouncement = super
        .getFetcher()
        .path('/tournaments/{tournamentId}/announcements')
        .method('post')
        .create();
}
