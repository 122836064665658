<button
    [type]="type"
    class="rounded-sm justify-center items-center inline-flex cursor-pointer {{ classes }}"
    [class.opacity-60]="disabled"
    [ngClass]="{ '!cursor-default': disabled }">
    <div
        class="text-center flex items-center font-open-sans {{ slotClasses }}"
        [class.font-bold]="bold"
        [class.underline]="underline">
        <ng-content></ng-content>
    </div>
</button>
