import { Injectable } from '@angular/core';

interface ActiveInterval {
  intervalId: number;
  view: string;
}
@Injectable()
export class IntervalManager {
  private activeIntervals: { [key: string]: ActiveInterval } = {};

  track(view: string, key: string, intervalId: any): void {
    if (this.activeIntervals[key]) {
      // If an interval with the same key is already active, clear it before tracking again
      this.clear(key);
    }

    this.activeIntervals[key] = {
      intervalId,
      view,
    };
  }

  clear(key: string): void {
    if (this.activeIntervals[key]) {
      clearInterval(this.activeIntervals[key].intervalId);
      delete this.activeIntervals[key];
    }
  }

  clearMultiple(keys: string[]): void {
    keys.forEach((key) => {
      this.clear(key);
    });
  }

  clearAll(): void {
    Object.keys(this.activeIntervals).forEach((key) => {
      this.clear(key);
    });
  }

  getActiveIntervals(): { key: string; view: string }[] {
    return Object.keys(this.activeIntervals).map((key) => {
      const activeInterval = this.activeIntervals[key];
      return { key, view: activeInterval.view };
    });
  }
}
