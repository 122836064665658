/* eslint-disable max-len */
import { Component, EventEmitter, Output } from '@angular/core';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { Bobs27OverallStats, Bobs27StatDetails } from '@dc-core/dc-statistics/bobs-27/bobs-27-overall-stats';
import {
    FilterChangedFilter,
    OverallStatDetails,
    StatDetailSegment,
    StatDetailType,
} from '@dc-core/dc-statistics/game-statistics.classes';
import { DropdownSelectItem, StatisticsRange } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { UltimateFeatureID } from 'src/app/core/app-features/services/app-features.service';
import { UltimateDialogPayload } from 'src/dialogs/ultimate-subscription/ultimate-subscription';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-bobs-27-table',
    templateUrl: 'bobs-27-table.component.html',
})
export class Bobs27TableComponent extends Bobs27OverallStats {
    @Output() dateChangeEvent = new EventEmitter<void>();

    public periodOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL_TIME:All-time`,
            value: 'all_time',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@THIS_MONTH:This month`,
            value: 'this_month',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@THIS_YEAR:This year`,
            value: 'this_year',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CUSTOM:Custom`,
            value: 'custom',
            ultimateOnly: true,
        },
    ];

    public gameSettingOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL:All`,
            value: 'all',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@EASY:Easy`,
            value: 'easy',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@HARD:Hard`,
            value: 'hard',
            ultimateOnly: true,
        },
    ];

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public gameStatisticsApiService: GameStatisticsApiService,
        public modal: ModalController,
        private upgrade: UpgradeService
    ) {
        super(auth.user, gameStatisticsApiService);
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.OPENBOBSSTATSTABLE);
        this.setFilters(); // Set previous filters if any
        this.loadOverallStats();
    }

    changeFilter(filter: FilterChangedFilter, value: any): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.CHANGEBOBSFILTER);
        switch (filter) {
            // Overall
            case 'statisticsRange':
                this.updateStatisticsRange(value);
                this.dateChangeEvent.emit();
                break;

            // Game specific
            case 'gameSetting':
                this.gameSetting = value;
                break;
        }

        this.loadOverallStats();
    }

    changeStatisticsRange(statisticsRange: StatisticsRange): void {
        console.log(statisticsRange);
        super.updateStatisticsRange(statisticsRange, false);
        this.loadOverallStats();
    }

    goToDetails(statsType: StatDetailType, segment: StatDetailSegment) {
        let title = '';
        switch (statsType) {
            case 'totalScore':
                title = $localize`:@@TOTAL_SCORE:Total score`;
                break;
            case 'avgHitsPerTurn':
                title = $localize`:@@AVG_HITS_PER_TURN:Avg. hits per turn`;
                break;
            case 'avgTurnsTaken':
                title = $localize`:@@AVG_TURNS_TAKEN:Avg. turns taken`;
                break;
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, 'View top 25 ' + segment + ' ' + statsType);

        this.nav.navigateForward('statistics/details', {
            queryParams: {
                // Overall
                title,
                statsType: statsType,
                segment: segment,
                fromDate: this.fromDate,
                untilDate: this.untilDate,
                // Game mode specific
                gameMode: 'bobs_27',
                gameSpecific: {
                    gameSetting: this.gameSetting,
                } as Bobs27StatDetails,
            } as OverallStatDetails,
        } as NavigationOptions);
    }

    async OpenUltimateDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        this.modal
            .create({
                component: dialogComponent,
                componentProps: {
                    highlightedFeatureID: UltimateFeatureID.ALLTIME,
                } as UltimateDialogPayload,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }
}
