import { User } from 'dc-core/dc-backend/dc-classes';
import { GameStatistics } from '../game-statistics.classes';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';

export interface ScoreTrainingOverallStatsData {
    threeDartAvg: { average?: number; best?: number; worst?: number };
    oneDartAvg: { average?: number; best?: number; worst?: number };
    totalScore: { average?: number; best?: number; worst?: number };
}

export interface ScoreTrainingStatDetails {}

export class ScoreTrainingOverallStats extends GameStatistics {
    public overallStats: ScoreTrainingOverallStatsData = {
        threeDartAvg: {},
        oneDartAvg: {},
        totalScore: {},
    } as any;

    public loadedOverallStats: boolean = false;
    public gameStatisticsApiService: GameStatisticsApiService;

    private _localStorageKey = 'scoreTrainingOverallStats';

    constructor(user: User, gameStatisticsApiService: GameStatisticsApiService) {
        super(user);
        this.gameStatisticsApiService = gameStatisticsApiService;
    }

    setFilters(): void {
        let savedFilters = JSON.parse(localStorage.getItem(this._localStorageKey));
        super.setFilters();
    }

    saveFilters() {
        localStorage.setItem(
            this._localStorageKey,
            JSON.stringify({
                statisticsGameType: this.statisticsGameType,
                statisticsRange: this.statisticsRange,
                year: this.year,
                month: this.month,
            })
        );
        super.saveFilters();
    }

    async loadOverallStats(): Promise<void> {
        this.loadedOverallStats = false;
        await this.initThreeDartAvg();
        if (this.user.is_ultimate) {
            await this.initOneDartAvg();
            await this.initTotalScore();
        }
        this.loadedOverallStats = true;
        this.saveFilters();
    }

    async initThreeDartAvg(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getScoreTrainingThreeDartAverageStatistics({
                        type,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.threeDartAvg[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initOneDartAvg(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getScoreTrainingOneDartAverageStatistics({
                        type,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.oneDartAvg[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initTotalScore(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getScoreTrainingTotalScoreStatistics({
                        type,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.totalScore[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }
}
