import { User } from 'dc-core/dc-backend/dc-classes';
import { GameStatistics } from '../game-statistics.classes';
import { Bobs27Difficulty } from '@dc-core/dc-backend/dc-enums';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';

export interface Bobs27OverallStatsData {
    totalScore: { average?: number; best?: number; worst?: number };
    avgHitsPerTurn: { average?: number; best?: number; worst?: number };
    avgTurnsTaken: { average?: number; best?: number; worst?: number };
    doubleHitRate: { average?: number; best?: number; worst?: number };
}

export interface Bobs27StatDetails {
    gameSetting: Bobs27Difficulty;
}

export class Bobs27OverallStats extends GameStatistics {
    public gameSetting: Bobs27Difficulty = 'all';

    public overallStats: Bobs27OverallStatsData = {
        totalScore: {},
        avgHitsPerTurn: {},
        avgTurnsTaken: {},
        doubleHitRate: {},
    } as any;

    public loadedOverallStats: boolean = false;
    public gameStatisticsApiService: GameStatisticsApiService;

    private _localStorageKey = 'bobs27OverallStats';

    constructor(user: User, gameStatisticsApiService: GameStatisticsApiService) {
        super(user);
        this.gameStatisticsApiService = gameStatisticsApiService;
    }

    setFilters(): void {
        let savedFilters = JSON.parse(localStorage.getItem(this._localStorageKey));
        if (savedFilters) {
            this.gameSetting = savedFilters.gameSetting ?? this.gameSetting;
        }
        super.setFilters();
    }

    saveFilters() {
        localStorage.setItem(
            this._localStorageKey,
            JSON.stringify({
                gameSetting: this.gameSetting,
                statisticsGameType: this.statisticsGameType,
                statisticsRange: this.statisticsRange,
                year: this.year,
                month: this.month,
            })
        );
        super.saveFilters();
    }

    async loadOverallStats(): Promise<void> {
        this.loadedOverallStats = false;
        await this.initTotalScore();
        if (this.user.is_ultimate) {
            await this.initAvgHitsPerTurn();
            await this.initAvgTurnsTaken();
            await this.initDoubleHitRate();
        }
        this.loadedOverallStats = true;
        this.saveFilters();
    }

    async initTotalScore(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getBobs27TotalScoreStatistics({
                        type,
                        game_setting: this.gameSetting,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.totalScore[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initAvgHitsPerTurn(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getBobs27AvgHitsPerTurnStatistics({
                        type,
                        game_setting: this.gameSetting,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.avgHitsPerTurn[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initAvgTurnsTaken(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getBobs27AvgTurnsTakenStatistics({
                        type,
                        game_setting: this.gameSetting,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.avgTurnsTaken[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initDoubleHitRate(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getBobs27DoubleHitRateStatistics({
                        type,
                        game_setting: this.gameSetting,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.doubleHitRate[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }
}
