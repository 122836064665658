<modal-content fullscreen="true" class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="white">
        <div title [translate]="'ASSIGN_GAME_LABELS'" i18n="@@ASSIGN_GAME_LABELS">Assign game labels</div>
    </app-page-header>

    <div class="game-label-container with-border" *ngIf="assignedGameLabelCategories">
        <div class="head">
            <span [translate]="'ASSIGNED'" i18n="@@ASSIGNED">Assigned</span>
        </div>
        <p
            class="text-center"
            style="margin: 5px; margin-top: 8px; font-size: 14px"
            [translate]="'NO_LABELS_ASSIGNED'"
            i18n="@@NO_LABELS_ASSIGNED"
            *ngIf="!assignedGameLabelCategories.length">
            No labels assigned
        </p>
        <div
            style="border: 3px solid {{ category.color }}; border-radius: 0.5rem; padding: 0.5rem"
            *ngFor="let category of assignedGameLabelCategories; let categoryIndex = index">
            <ion-label style="padding: 0 0.5rem">{{ category.final_title }}</ion-label>
            <ion-row
                class="game-label-large"
                *ngFor="let gameLabel of category.labels; let labelIndex = index"
                (click)="unlabel(gameLabel, categoryIndex, labelIndex)">
                <ion-col
                    class="label-title"
                    style="background-color: {{ category.color }}; color: {{ category.text_color }}">
                    <ion-label>{{ gameLabel.final_title }}</ion-label>
                </ion-col>
            </ion-row>
        </div>
    </div>

    <div class="game-label-container with-border" *ngIf="availableGameLabelCategories">
        <div class="head">
            <span [translate]="'AVAILABLE'" i18n="@@AVAILABLE">Available</span>
        </div>
        <p
            class="text-center"
            style="margin: 5px; margin-top: 8px; font-size: 14px"
            [translate]="'NO_LABELS_AVAILABLE'"
            i18n="@@NO_LABELS_AVAILABLE"
            *ngIf="!availableGameLabelCategories.length">
            No labels available
        </p>
        <div
            style="border: 3px solid {{ category.color }}; border-radius: 0.5rem; padding: 0.5rem"
            *ngFor="let category of availableGameLabelCategories; let categoryIndex = index">
            <ion-label style="padding: 0 0.5rem">{{ category.final_title }}</ion-label>
            <ion-row
                class="game-label-large"
                (click)="label(gameLabel, categoryIndex, labelIndex)"
                *ngFor="let gameLabel of category.labels; let labelIndex = index">
                <ion-col
                    class="label-title"
                    style="background-color: {{ category.color }}; color: {{ category.text_color }}">
                    <ion-label>{{ gameLabel.final_title }}</ion-label>
                </ion-col>
            </ion-row>
        </div>
    </div>

    <ion-button
        style="width: 99%; padding: 0 10px; margin-bottom: 8px"
        fill="outline"
        color="primary"
        (click)="openGameLabels()"
        [translate]="'MANAGE_GAME_LABELS'"
        i18n="@@MANAGE_GAME_LABELS">
        Manage game labels
    </ion-button>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA" style="position: relative" (click)="close()">
            <p class="text-center" style="height: 72px" [translate]="'CLOSE'" i18n="@@CLOSE">Close</p>
        </div>
    </ion-toolbar>
</ion-footer>
