import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatThousand',
})
export class FormatThousandPipe implements PipeTransform {
    transform(num: number): string {
        if (num >= 100000) {
            return (num / 1000).toFixed(0) + 'K';
        } else if (num >= 10000) {
            return (num / 1000).toFixed(0) + 'K';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            return num.toString();
        }
    }
}
