<div
    class="flex relative bottom-0 w-full flex-col overflow-y-hidden overscroll-none rounded-t-lg bg-white-pattern text-black">
    <div class="relative flex items-center rounded-t-lg py-4 px-safe-offset-4">
        <ion-buttons slot="start">
            <div
                class="ml-1 text-center text-sm font-bold text-black cursor-pointer"
                [translate]="'CLOSE'"
                i18n="@@CLOSE"
                (click)="dismiss()">
                Close
            </div>
        </ion-buttons>
        <div
            class="absolute left-0 right-0 my-auto w-full text-center font-bebas-neue text-2xl text-black"
            [translate]="'SMART_DEVICE_LIGHT_INDICATORS'"
            i18n="@@SMART_DEVICE_LIGHT_INDICATORS">
            Device Light Indicators
        </div>
    </div>
    <div class="flex flex-1 flex-col mx-4 gap-1 overflow-y-auto pb-safe-offset-4 text-black">
        <div class="flex flex-row w-full mx-3 gap-1 bg-neutral-50 rounded-md">
            <img src="assets/images/ftr/magenta_light.jpg" />
            <div class="flex flex-col w-3/4">
                <div class="font-bebas-neue" [translate]="'SOLID_MAGENTA_LIGHT'" i18n="@@SOLID_MAGENTA_LIGHT">
                    Solid magenta light
                </div>
                <div [translate]="'SOLID_MAGENTA_LIGHTT_DESCRIPTION'" i18n="@@SOLID_MAGENTA_LIGHT_DESCRIPTION">
                    The device is booting the firmware, please wait.
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full mx-3 gap-1 bg-neutral-50 rounded-md">
            <img src="assets/images/ftr/green_light.jpg" />
            <div class="flex flex-col w-3/4">
                <div class="font-bebas-neue" [translate]="'SOLID_GREEN_LIGHT'" i18n="@@SOLID_GREEN_LIGHT">
                    Solid green light
                </div>
                <div [translate]="'SOLID_GREEN_LIGHT_DESCRIPTION'" i18n="@@SOLID_GREEN_LIGHT_DESCRIPTION">
                    The device is connected to the WiFi and has access to internet.
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full mx-3 gap-1 bg-neutral-50 rounded-md">
            <img src="assets/images/ftr/green_light_blinking.gif" />
            <div class="flex flex-col w-3/4">
                <div class="font-bebas-neue" [translate]="'BLINKING_GREEN_LIGHT'" i18n="@@BLINKING_GREEN_LIGHT">
                    Blinking green light
                </div>
                <div [translate]="'BLINKING_GREEN_LIGHT_DESCRIPTION'" i18n="@@BLINKING_GREEN_LIGHT_DESCRIPTION">
                    The device is connected to the WiFi but has no active internet connection.
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full mx-3 gap-1 bg-neutral-50 rounded-md">
            <img src="assets/images/ftr/blue_light_blinking.gif" />
            <div class="flex flex-col w-3/4">
                <div class="font-bebas-neue" [translate]="'BLINKING_BLUE_LIGHT'" i18n="@@BLINKING_BLUE_LIGHT">
                    Blinkig blue light
                </div>
                <div [translate]="'BLINKING_GREEN_LIGHT_DESCRIPTION'" i18n="@@BLINKING_GREEN_LIGHT_DESCRIPTION">
                    The device is in hotspot mode, connect to the hotspot and link it to your WiFi.
                </div>
            </div>
        </div>
    </div>
</div>
