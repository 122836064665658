<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="DismissModal()"></app-icon>
        <div class="title-container">
            <div class="title" [translate]="'QUIT_GAME'" i18n="@@QUIT_GAME">Quit game</div>
            <div class="subtitle" [translate]="'QUIT_OR_SAVE_GAME'" i18n="@@QUIT_OR_SAVE_GAME">
                Do you want to quit or save this game?
            </div>
        </div>
        <div class="button-container flex justify-between space-x-2">
            <app-primary-button
                size="normal"
                color="red"
                addedClasses="px-4"
                (click)="DismissModal('quit')"
                [translate]="'QUIT'"
                i18n="@@QUIT">
                Quit
            </app-primary-button>
            <app-primary-button
                size="normal"
                color="blue"
                addedClasses="px-4"
                (click)="DismissModal('save')"
                [translate]="'SAVE'"
                i18n="@@SAVE">
                Save
            </app-primary-button>
        </div>
    </div>
</modal-content>
