import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class TournamentStatisticsApiService extends ApiService {
    public getTournamentStatistics = super.getFetcher().path('/tournaments/statistics').method('get').create();

    // Match
    public getTournamentMatchStatistics = super
        .getFetcher()
        .path('/tournament-statistics/match')
        .method('get')
        .create();
    public getSpecificTournamentMatchStatistics = super
        .getFetcher()
        .path('/tournament-statistics/match/specific')
        .method('get')
        .create();
    public getTournamentMatchThreeDartAvgStatistics = super
        .getFetcher()
        .path('/tournament-statistics/match/three-dart-avg')
        .method('get')
        .create();
    public getTournamentMatchFirstNineAvgStatistics = super
        .getFetcher()
        .path('/tournament-statistics/match/first-nine-avg')
        .method('get')
        .create();
    public getTournamentMatchCheckoutRateStatistics = super
        .getFetcher()
        .path('/tournament-statistics/match/checkout-rate')
        .method('get')
        .create();
    public getTournamentMatchCheckoutsStatistics = super
        .getFetcher()
        .path('/tournament-statistics/match/checkouts')
        .method('get')
        .create();
}
