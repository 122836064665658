<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" i18n="@@TRANSITION_TO_USER" [translate]="'TRANSITION_TO_USER'">
            Transition to user
        </div>
    </div>
    <div class="slide-modal-content">
        <div i18n="@@TRANSITION_TO_COMPLETE_YOUR_ACCOUNT" [translate]="'TRANSITION_TO_COMPLETE_YOUR_ACCOUNT'">
            Transition your guest account to complete your account
        </div>
        <div class="mt-5 space-y-4 mb-3">
            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'EMAIL'" i18n="@@EMAIL">Email</div>
                <input type="text" class="dialog-input" [(ngModel)]="email" />
            </div>

            <app-checkbox
                class="block"
                [model]="newsletterOptIn"
                color="white"
                (itemChange)="newsletterOptIn = !newsletterOptIn">
                <div class="flex text-black" [translate]="'NEWSLETTER_OPT_IN_INFO'" i18n="@@NEWSLETTER_OPT_IN_INFO">
                    Yes, I want to hear about exclusive offers, announcements and the newest products from Target
                    DartCounter, as well as those tailored to my account and activity.
                </div>
            </app-checkbox>

            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'PASSWORD'" i18n="@@PASSWORD">Password</div>
                <input type="password" class="dialog-input" [(ngModel)]="password" />
            </div>
        </div>
        <app-primary-button
            size="normal"
            addedClasses="w-full"
            color="orange"
            (click)="submit()"
            [translate]="'TRANSITION'"
            i18n="@@TRANSITION">
            Transition
        </app-primary-button>
    </div>
</div>
