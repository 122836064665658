import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoggingService, LogViewerLog } from '@services/logging.service';

@Component({
    selector: 'app-log-viewer-dialog',
    templateUrl: 'log-viewer.dialog.html',
    styleUrls: ['log-viewer.dialog.scss'],
})
export class LogViewerDialogComponent {
    logs: LogViewerLog[];
    type: '' | 'log' | 'warn' | 'error' = '';

    constructor(public view: ModalController, private _loggingService: LoggingService) {
        this.setLogs();
    }

    setLogs(): void {
        this.logs = this._loggingService.getLogs().filter((log) => {
            return !this.type || log.type == this.type;
        });
    }
    toggleLog(log: LogViewerLog) {
        log.expanded = !log.expanded;
    }

    setType(type: any): void {
        this.type = type;
        this.setLogs();
    }

    clearLog(): void {
        this._loggingService.clear();
        this.logs = [];
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
