import { inject, Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { CookieConsentService } from '@dc-core/dc-services/cookie-consent.service';

@Injectable()
export class DartCounterAnalyticsService {
    private cookieConsentService: CookieConsentService = inject(CookieConsentService);

    public async trackView(screenName: string): Promise<void> {
        if (this.cookieConsentService.getConsent('analytics')) {
            await FirebaseAnalytics.setCurrentScreen({
                screenName,
            });
        }
    }

    public async trackEvent(name: string, event: string, action?: string): Promise<void> {
        if (this.cookieConsentService.getConsent('analytics')) {
            await FirebaseAnalytics.logEvent({
                name,
                params: { event, action },
            });
        }
    }
}
