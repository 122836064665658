<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@CANCEL">Cancel</ng-container>
}
<!-- End of translation containers-->

<app-page-header mainNavigation="back" defaultBack="dashboard">
    <div title [translate]="'PAGE_SETTINGS'" i18n="@@PAGE_SETTINGS">Settings</div>
</app-page-header>

<div class="h-full bg-white-pattern text-black overflow-y-auto" id="main-content">
    <div appWebContainer class="h-full">
        <div class="flex max-w-xl mx-auto px-8 py-4 w-full flex-col text-black">
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="appSettings()">
                <div class="font-normal" [translate]="'APP_SETTINGS'" i18n="@@APP_SETTINGS">App settings</div>
                <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="showAccount()">
                <div class="font-normal" [translate]="'ACCOUNT'" i18n="@@ACCOUNT">Account</div>
                <div class="flex items-center">
                    @if (!auth.user?.username || !auth.user?.profile?.birthdate || !auth.user?.email_verified_at) {
                        <div class="h-2.5 w-2.5 rounded-full bg-yellow-icon-alert mr-2"></div>
                    }
                    <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
                </div>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="showGlobalGameSettings()">
                <div class="font-normal" [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</div>
                <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 ion-text-orange-accent dark:ion-text-yellow cursor-pointer"
                (click)="Logout()">
                <div class="font-normal" [translate]="'LOGOUT'" i18n="@@LOGOUT">Logout</div>
                <app-icon icon="logout" size="small"></app-icon>
            </div>
        </div>
    </div>
</div>
