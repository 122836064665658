<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@CANCEL">Cancel</ng-container>
}
<!-- End of translation containers-->

<app-page-header mainNavigation="back" defaultBack="settings">
    <div title [translate]="'PAGE_SETTINGS'" i18n="@@PAGE_SETTINGS">Settings</div>
</app-page-header>

<div class="h-full flex flex-col bg-white-pattern overflow-y-auto text-black pb-safe" id="main-content">
    <div class="flex-1" appWebContainer>
        <div class="flex flex-col justify-between px-8 py-4 h-full">
            <div class="flex max-w-xl mx-auto w-full flex-col text-black">
                <div
                    class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                    (click)="showLanguages()">
                    <div class="font-normal" [translate]="'LANGUAGE'" i18n="@@LANGUAGE">Language</div>
                    <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
                </div>
                <div class="flex items-center justify-between w-full py-3 cursor-pointer" (click)="changeTheme()">
                    <div class="font-normal" [translate]="'THEME'" i18n="@@THEME">Theme</div>
                    <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
                </div>
            </div>
            <div class="flex justify-between text-sm">
                <div class="cursor-pointer" (click)="OpenAgreementDialog()">
                    <div class="font-normal" [translate]="'TERMS_OF_SERVICE'" i18n="@@TERMS_OF_SERVICE">
                        Terms of Service
                    </div>
                </div>
                <div class="cursor-pointer text-right" (click)="OpenPolicyDialog()">
                    <div class="font-normal" [translate]="'PRIVACY_POLICY'" i18n="@@PRIVACY_POLICY">Privacy policy</div>
                </div>
            </div>
        </div>
    </div>
</div>
