import { Route } from '@angular/router';

export const spectatingOnlineGameRoutes: Route[] = [
    {
        path: 'online-game/match/spectate/:id',
        data: {
            hideTournamentNotification: true,
        },
        loadComponent: () =>
            import('./match/pages/match-spectator/match-spectator.component').then((c) => c.MatchSpecatorComponent),
    },
    {
        path: 'online-game/cricket-tactics/spectate/:id',
        data: {
            hideTournamentNotification: true,
        },
        loadComponent: () =>
            import('./cricket-tactics/pages/cricket-tactics-spectator/cricket-tactics-spectator.component').then(
                (c) => c.CricketTacticsSpecatorComponent
            ),
    },
];
