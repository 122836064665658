import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { FriendApiService } from '@dc-api/friend.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { ActionSheetController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { LocalStorageKey } from 'dc-core/dc-localstorage';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { debounceTime } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-search-users',
    templateUrl: 'search-users.component.html',
})
export class SearchUsersComponent implements OnInit {
    public profile: any;
    public searchString = '';
    public recentSearches: any[] = [];
    public people: Array<any> = [];
    loadingFriends: boolean;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        public platform: Platform,
        public nav: NavController,
        public auth: AuthService,
        public sheet: ActionSheetController,
        private ga: DartCounterAnalyticsService,
        private loading: DCLoadingService,
        public userApiService: UserApiService,
        public friendApiService: FriendApiService,
        private _alertService: DartCounterAlertService,
        public modal: ModalController,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.recentSearches = JSON.parse(localStorage.getItem(LocalStorageKey.recentFriendSearches)) ?? [];

        this.searchControl.valueChanges
            .pipe(debounceTime(600), takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                if (value.length) {
                    this.searchUsers(value);
                } else {
                    this.searchUsers(null);
                }
            });
    }

    ionViewWillEnter() {
        this.ga.trackView('Search users');
    }

    ionViewWillLeave() {
        this.loading.DismissLoader();
    }

    searchUsers(searchString) {
        if (searchString?.length > 0) {
            this.searchString = searchString;
            this.loadingFriends = true;

            return new Promise((resolve, reject) => {
                this.ga.trackEvent(GA_EVENTCATEGORIES.SEARCHFRIENDS, GA_EVENTACTIONS.SEARCHFRIENDS);
                this.userApiService
                    .getAllUsers({ search: searchString })
                    .then((res) => {
                        this.loadingFriends = false;
                        this.people = res.data.data;
                        resolve(res.data);
                    })
                    .catch((e) => {
                        if (e instanceof this.userApiService.getAllUsers.Error) {
                            this.loadingFriends = false;
                            reject(e.message);
                        }
                    });
            });
        } else {
            this.searchString = '';
            this.loadingFriends = false;
        }
    }

    OpenProfile(person) {
        // Add this to recent profile-searches
        const recentSearches = JSON.parse(localStorage.getItem(LocalStorageKey.recentFriendSearches));
        if (recentSearches) {
            const found = recentSearches.find((x) => x.ID === person.ID);
            if (found) {
                recentSearches.splice(recentSearches.indexOf(found), 1);
            }
            // Add the person and remove anything which is more than 8
            recentSearches.unshift(person);
            if (recentSearches.length > 8) {
                recentSearches.pop();
            }

            localStorage;
            localStorage.setItem(LocalStorageKey.recentFriendSearches, JSON.stringify(recentSearches));
        } else {
            localStorage.setItem(LocalStorageKey.recentFriendSearches, JSON.stringify([person]));
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.SEARCHFRIENDS, GA_EVENTACTIONS.OPENPROFILEUSER);
        this.nav.navigateForward('profile/' + person.id);
    }

    AddFriend(account) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .addFriendByUserId({ userId: account.id })
            .then(() => {
                account.friend_status = 'pending';
                this.loading.DismissLoader();
            })
            .catch((e) => {
                this._alertService.errorFromApi(e);
                this.loading.DismissLoader();
            });
    }

    ShowPendingOptions(account) {
        $localize`:@@CANCEL_REQUEST:Cancel`;
        const actionSheet = this.sheet.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: this.translate.instant('CANCEL_REQUEST'),
                    icon: 'close',
                    handler: () => {
                        this.DenyFriend(account);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    DenyFriend(account) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .denyFriendByUserId({ userId: account.id })
            .then(() => {
                account.friend_status = 'no_friends';
                this.loading.DismissLoader();
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }
}
