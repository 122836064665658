import { DartCounterMatchSpeechActions } from '../actions/match.speech-actions';
import { RecognitionLang, SpeechCommand, SpeechCommandInfo } from '../speech-to-score.service';
import {
    CommandGame,
    CommandOption,
    CommandType,
    DartCounterGlobalSpeechCommands,
    DartCounterSpeechCommands,
    SpeechCommandHelper,
} from '../speech-commands';

export class DartCounterMatchSpeechCommands extends SpeechCommandHelper implements DartCounterGlobalSpeechCommands {
    constructor(langNumbers: {}) {
        super(langNumbers);
    }

    commands(actions: DartCounterMatchSpeechActions): SpeechCommand[] {
        return [
            {
                type: CommandType.REMAINING_SCORE,
                commands: DartCounterSpeechCommands.remainingScore,
                action: (lang: RecognitionLang, attributes: string[]) => {
                    const finalScore = this.getFinalScore(lang, attributes[0]);
                    if (finalScore) {
                        const addToTimer = this.getAddToTimer(finalScore);
                        if (actions.remainingScore(finalScore)) {
                            return addToTimer;
                        }
                    }
                    return null;
                },
            },
            {
                type: CommandType.SCORE,
                option: CommandOption.SCORE,
                commands: DartCounterSpeechCommands.score,
                action: (lang: RecognitionLang, attributes: string[]) => {
                    const finalScore = this.getFinalScore(lang, attributes[0]);
                    if (finalScore) {
                        const addToTimer = this.getAddToTimer(finalScore);
                        if (actions.score(finalScore)) {
                            return addToTimer;
                        }
                    }
                    return null;
                },
            },
            {
                type: CommandType.NO_SCORE,
                commands: DartCounterSpeechCommands.noScore,
                action: (lang: RecognitionLang, attributes: string[]) => {
                    actions.noScore();
                    return 0;
                },
            },
            {
                type: CommandType.UNDO_SCORE,
                game: CommandGame.MATCH,
                commands: DartCounterSpeechCommands.undoScore,
                action: (lang: RecognitionLang, attributes: string[]) => {
                    actions.undoScore();
                    return 0;
                },
            },
            {
                type: CommandType.EDIT_SCORE,
                game: CommandGame.ONLINE_MATCH,
                commands: DartCounterSpeechCommands.editScore,
                action: (lang: RecognitionLang, attributes: string[]) => {
                    actions.editScore();
                    return 0;
                },
            },
            {
                type: CommandType.CHECKOUT,
                commands: DartCounterSpeechCommands.checkout,
                action: (lang: RecognitionLang, attributes: string[]) => {
                    const first = this.getFinalScore(lang, attributes[0]);
                    let second = null;
                    if (attributes[1]) {
                        second = this.getFinalScore(lang, attributes[1]);
                    }

                    if (first) {
                        actions.checkout(parseInt(first), second ? parseInt(second) : null);
                    }
                    return 0;
                },
            },
            {
                type: CommandType.CHANGE_KEYBOARD,
                commands: DartCounterSpeechCommands.changeKeyboard,
                action: (lang: RecognitionLang, attributes: string[]) => {
                    const keyboard = attributes[0];
                    switch (keyboard) {
                        case 'numbers':
                        case 'number':
                            actions.changeKeyboard('numbers');
                            return 0;
                        case 'single':
                            actions.changeKeyboard('single');
                            return 0;
                        case 'hotkeys':
                        case 'hot keys':
                            actions.changeKeyboard('hotkeys');
                            return 0;
                    }
                    return null;
                },
            },
            {
                type: CommandType.SCORE,
                option: CommandOption.SCORE_ONLY,
                commands: DartCounterSpeechCommands.scoreOnly,
                action: (lang: RecognitionLang, attributes: string[]) => {
                    const finalScore = this.getFinalScore(lang, attributes[0]);
                    if (finalScore) {
                        const addToTimer = this.getAddToTimer(finalScore);
                        if (actions.scoreOnly(finalScore)) {
                            return addToTimer;
                        }
                    }
                    return null;
                },
            },
        ];
    }

    info(): SpeechCommandInfo[] {
        return [
            {
                title: $localize`:@@ENTER_A_SCORE:Enter a score`,
                type: CommandType.SCORE,
                hasOptions: true,
                list: [
                    {
                        option: CommandOption.SCORE,
                        parts: [
                            {
                                languages: ['en-US', 'nl-NL'],
                                texts: [
                                    { text: 'score', type: 'command' },
                                    { text: '+', type: 'text' },
                                    { text: $localize`:@@YOUR_SCORE:Your score`, type: 'input' },
                                ],
                            },
                            {
                                languages: ['de-DE'],
                                texts: [
                                    { text: 'Punktzahl', type: 'command' },
                                    { text: '+', type: 'text' },
                                    { text: $localize`:@@YOUR_SCORE:Your score`, type: 'input' },
                                ],
                            },
                        ],
                    },
                    {
                        option: CommandOption.SCORE_ONLY,
                        parts: [
                            {
                                languages: ['en-US', 'nl-NL', 'de-DE'],
                                texts: [{ text: $localize`:@@YOUR_SCORE:Your score`, type: 'input' }],
                            },
                        ],
                    },
                ],
            },
            {
                title: $localize`:@@USE_REMAINING_SCORE:Use remaining score`,
                type: CommandType.REMAINING_SCORE,
                hasOptions: false,
                list: [
                    {
                        parts: [
                            {
                                languages: ['en-US'],
                                texts: [
                                    { text: 'remaining score', type: 'command' },
                                    { text: '+', type: 'text' },
                                    { text: $localize`:@@YOUR_SCORE:Your score`, type: 'input' },
                                ],
                            },
                            {
                                languages: ['nl-NL'],
                                texts: [
                                    { text: 'resterende score', type: 'command' },
                                    { text: '+', type: 'text' },
                                    { text: $localize`:@@YOUR_SCORE:Your score`, type: 'input' },
                                ],
                            },
                            {
                                languages: ['de-DE'],
                                texts: [
                                    { text: 'verbleibende Punktzahl', type: 'command' },
                                    { text: '+', type: 'text' },
                                    { text: $localize`:@@YOUR_SCORE:Your score`, type: 'input' },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                title: $localize`:@@ENTER_NO_SCORE:Enter no score`,
                type: CommandType.NO_SCORE,
                hasOptions: false,
                list: [
                    {
                        parts: [
                            {
                                languages: ['en-US'],
                                texts: [{ text: 'no score', type: 'command' }],
                            },
                            {
                                languages: ['nl-NL'],
                                texts: [{ text: 'geen score', type: 'command' }],
                            },
                            {
                                languages: ['de-DE'],
                                texts: [{ text: 'keine Punktzahl', type: 'command' }],
                            },
                        ],
                    },
                ],
            },
            {
                title: $localize`:@@UNDO_YOUR_PREVIOUS_SCORE:Undo your previous score`,
                type: CommandType.UNDO_SCORE,
                game: CommandGame.MATCH,
                hasOptions: false,
                list: [
                    {
                        parts: [
                            {
                                languages: ['en-US'],
                                texts: [{ text: 'undo score', type: 'command' }],
                            },
                            {
                                languages: ['nl-NL'],
                                texts: [{ text: 'score ongedaan maken', type: 'command' }],
                            },
                            {
                                languages: ['de-DE'],
                                texts: [{ text: 'Punktzahl rückgängig machen', type: 'command' }],
                            },
                        ],
                    },
                ],
            },
            {
                title: $localize`:@@EDIT_YOUR_PREVIOUS_SCORE:Edit your previous score`,
                type: CommandType.EDIT_SCORE,
                game: CommandGame.ONLINE_MATCH,
                hasOptions: false,
                list: [
                    {
                        parts: [
                            {
                                languages: ['en-US'],
                                texts: [{ text: 'edit score', type: 'command' }],
                            },
                            {
                                languages: ['nl-NL'],
                                texts: [{ text: 'score bewerken', type: 'command' }],
                            },
                            {
                                languages: ['de-DE'],
                                texts: [{ text: 'Punktzahl bearbeiten', type: 'command' }],
                            },
                        ],
                    },
                ],
            },
            {
                title: $localize`:@@ENTER_YOUR_CHECKOUT:Enter your checkout`,
                type: CommandType.CHECKOUT,
                hasOptions: false,
                list: [
                    {
                        parts: [
                            {
                                languages: ['en-US', 'nl-NL', 'de-DE'],
                                texts: [
                                    { text: 'checkout', type: 'command' },
                                    { text: '+', type: 'text' },
                                    { text: $localize`:@@FIRST:First`, type: 'input' },
                                ],
                            },
                        ],
                    },
                    {
                        parts: [
                            {
                                languages: ['en-US', 'nl-NL', 'de-DE'],
                                texts: [
                                    { text: 'checkout', type: 'command' },
                                    { text: '+', type: 'text' },
                                    { text: $localize`:@@FIRST:First`, type: 'input' },
                                    { text: '+', type: 'text' },
                                    { text: 'and', type: 'command' },
                                    { text: '+', type: 'text' },
                                    { text: $localize`:@@SECOND:Second`, type: 'input' },
                                ],
                            },
                        ],
                    },
                ],
            },
        ];
    }
}
