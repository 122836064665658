<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@LOADING">Loading</ng-container>
}
<!-- End of translation containers-->

<app-page-header mainNavigation="back" defaultBack="statistics">
    <div title [translate]="'SHANGHAI'" i18n="@@SHANGHAI">Shanghai</div>
</app-page-header>

<ion-content class="bg-white-pattern">
    @if (itemsUnverified?.length) {
        <div class="tab-toggle-container">
            <div
                class="tab-toggle"
                [class.inner-border-b-2-orange]="segment === 'verified'"
                [class.opacity-60]="segment !== 'verified'"
                (click)="changeSegment('verified')">
                <div [translate]="'GAMES'" i18n="@@GAMES">Games</div>
            </div>
            <div
                class="tab-toggle"
                [class.inner-border-b-2-orange]="segment === 'unverified'"
                [class.opacity-60]="segment !== 'unverified'"
                (click)="changeSegment('unverified')">
                <div class="flex items-center">
                    <div [translate]="'UNVERIFIED_MATCHES'" i18n="@@UNVERIFIED_MATCHES">Unverified matches</div>
                    <ion-badge mode="md" class="ion-bg-yellow !ion-text-black ml-2 -mt-1">
                        {{ itemsUnverified?.length }}
                    </ion-badge>
                </div>
            </div>
        </div>
    }

    <div [ngSwitch]="segment">
        <div *ngSwitchCase="'verified'">
            <div class="m-4 flex text-sm">
                <div class="mr-1 w-full">
                    <div
                        class="mb-1 text-center text-sm uppercase text-black"
                        [translate]="'START_DATE'"
                        i18n="@@START_DATE">
                        Start date
                    </div>
                    <div class="rounded-lg bg-white p-2 text-black cursor-pointer" (click)="changeStartDate()">
                        {{ startDate | date: 'd MMM yyyy' : '' : translateService.currentLang }}
                    </div>
                </div>
                <div class="ml-1 w-full">
                    <div
                        class="mb-1 text-center text-sm uppercase text-black"
                        [translate]="'END_DATE'"
                        i18n="@@END_DATE">
                        End date
                    </div>
                    <div class="rounded-lg bg-white p-2 text-black cursor-pointer" (click)="changeEndDate()">
                        {{ endDate | date: 'd MMM yyyy' : '' : translateService.currentLang }}
                    </div>
                </div>
            </div>
            @if (items === null) {
                <div class="flex mt-5">
                    <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-black"></app-icon>
                </div>
            }

            @if (items && items.length === 0) {
                <div
                    class="text-center font-semibold text-black"
                    [translate]="'NO_GAMES_PLAYED'"
                    i18n="@@NO_GAMES_PLAYED">
                    No games played
                </div>
            }

            <div class="m-4">
                <div *ngFor="let item of items">
                    <app-shanghai-card [game]="item" (click)="ShowDetail(item)"></app-shanghai-card>
                </div>
            </div>

            <div class="m-3">
                <app-primary-button
                    *ngIf="!auth.user.is_ultimate && total > 0"
                    size="normal"
                    addedClasses="w-full mt-3"
                    color="blue"
                    (click)="OpenUltimateDialog()"
                    [translate]="'WANT_TO_VIEW_ALL_GAMES'"
                    i18n="@@WANT_TO_VIEW_ALL_GAMES">
                    Want to view all games? Become Ultimate!
                </app-primary-button>
            </div>

            <ion-infinite-scroll
                (ionInfinite)="GetItems($event)"
                *ngIf="items !== null && total !== null && items.length < total">
                <div class="flex mt-5">
                    <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-black"></app-icon>
                </div>
            </ion-infinite-scroll>
        </div>

        <div *ngSwitchCase="'unverified'">
            <div class="mx-4 mt-4">
                <div class="text-black" [translate]="'UNVERIFIED_TEXT'" i18n="@@UNVERIFIED_TEXT">
                    Your account has been used for local games. Tap 'Save' if you have played the game. Tap 'Remove' if
                    you haven't played the game.
                </div>
                <div class="flex gap-4 mt-3">
                    <div class="flex-1">
                        <app-primary-button
                            addedClasses="w-full"
                            size="normal"
                            [color]="'orange'"
                            (click)="TrySaveAll()"
                            [translate]="'SAVE_ALL'"
                            i18n="@@SAVE_ALL">
                            Save all
                        </app-primary-button>
                    </div>
                    <div class="flex-1">
                        <app-primary-button
                            addedClasses="w-full"
                            size="normal"
                            [color]="'red'"
                            (click)="TryRemoveAll()"
                            [translate]="'REMOVE_ALL'"
                            i18n="@@REMOVE_ALL">
                            Remvove all
                        </app-primary-button>
                    </div>
                </div>
            </div>

            <div class="m-3">
                <app-shanghai-card
                    *ngFor="let item of itemsUnverified; let i = index"
                    [game]="item"
                    [showArrow]="false">
                    <div class="flex items-center justify-end space-x-1 py-1">
                        <app-primary-button
                            color="orange"
                            size="extrasmall"
                            (click)="TrySave(item, i)"
                            [translate]="'SAVE'"
                            i18n="@@SAVE">
                            Save
                        </app-primary-button>
                        <app-primary-button
                            color="red"
                            size="extrasmall"
                            (click)="TryRemove(item, i)"
                            [translate]="'Remove'"
                            i18n="@@Remove">
                            Remove
                        </app-primary-button>
                        <app-primary-button
                            color="blue"
                            size="extrasmall"
                            (click)="ShowDetail(item)"
                            [translate]="'MORE_INFO'"
                            i18n="@@MORE_INFO">
                            More
                        </app-primary-button>
                    </div>
                </app-shanghai-card>
            </div>

            <ion-infinite-scroll
                (ionInfinite)="GetUnverifiedItems($event)"
                *ngIf="
                    itemsUnverified !== null && totalUnverified !== null && itemsUnverified.length < totalUnverified
                ">
                <div class="flex mt-5">
                    <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-black"></app-icon>
                </div>
            </ion-infinite-scroll>
        </div>
    </div>
</ion-content>
