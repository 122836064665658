import { Match, MatchTeam, MatchUser, User } from 'dc-core/dc-backend/dc-classes';
import { EntryMode, FinishMode } from 'dc-core/dc-backend/dc-enums';
import { RemovePlayerPayload } from 'dc-core/dc-gamelogic/game-interfaces';
import _ from 'lodash';
import * as moment from 'moment';
import { GameApiService } from '../../../../dc-api/game.api.service';
import { LOCAL_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import {
    BESTOFMODE,
    BestOfFirstTo,
    ENTRYMODE_DOUBLE,
    ENTRYMODE_MASTER,
    ENTRYMODE_STRAIGHT,
    FINISHMODE_DOUBLE,
    FINISHMODE_MASTER,
    FINISHMODE_STRAIGHT,
    FIRSTTOMODE,
    LEGMODE,
    PLAYERMODE,
    PlayerType,
    SETSMODE,
    STARTSCORE_301,
    STARTSCORE_501,
    STARTSCORE_701,
    STARTSCORE_CUSTOM,
    SetOrLeg,
    StartWith,
    TEAMMODE,
    dateTimeFormat,
} from '../../settings.globals';
import { DartbotLevel, DartbotMatchLevels } from '../dartbot/dartbot.config';
import { DartCounterGameSettings } from '../settings-classes';

export class DartCounterMatchSettings extends DartCounterGameSettings {
    public gameApiService: GameApiService;
    //CPU Settings
    public vsDartbot: boolean;
    public dartbotAdded: boolean = false;
    public dartbotLevel: DartbotLevel;

    public checkoutPercentage: boolean;
    public checkoutPercentageDisabled: boolean;
    public showTwoLegsDifference: boolean;
    public twoLegsDifference: boolean;
    public suddenDeath: boolean;

    public startWiths: { label: string; tag: string; type: StartWith }[];
    public startWith: StartWith;
    public customStartWith: number;
    public minCustomStartWith = 101;
    public maxCustomStartWith = 9999;

    public bestOfFirstTos: { label: string; tag: string; type: BestOfFirstTo }[];
    public bestOfFirstTo: BestOfFirstTo;
    public goalAmount: number;
    public minGoalAmount = 1;
    public maxGoalAmount = 30;
    public setsOrLegs: { label: string; tag: string; type: SetOrLeg }[];
    public setOrLeg: SetOrLeg;

    public entryModes: { label: string; tag: string; type: EntryMode }[];
    public entryMode: EntryMode;

    public finishModes: { label: string; tag: string; type: FinishMode }[];
    public finishMode: FinishMode;

    constructor() {
        //Set the super-class settings
        super();
        this.storageKey = LOCAL_GAME_SETTINGS_STORAGE_KEYS.MATCH;

        //Set the specific settings
        this.playerTypes = [PLAYERMODE, TEAMMODE];
        this.startWiths = [STARTSCORE_301, STARTSCORE_501, STARTSCORE_701, STARTSCORE_CUSTOM];
        this.bestOfFirstTos = [BESTOFMODE, FIRSTTOMODE];
        this.setsOrLegs = [LEGMODE, SETSMODE];
        this.entryModes = [ENTRYMODE_STRAIGHT, ENTRYMODE_DOUBLE, ENTRYMODE_MASTER];
        this.finishModes = [FINISHMODE_DOUBLE, FINISHMODE_MASTER, FINISHMODE_STRAIGHT];

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        } else {
            this.setDefaults();
        }
        this.checkCheckoutPercentageDisabled();
    }

    private setDefaults() {
        this.vsDartbot = false;
        this.dartbotAdded = false;
        this.dartbotLevel = DartbotMatchLevels[DartbotMatchLevels.length / 2];

        this.showTwoLegsDifference = false;
        this.twoLegsDifference = false;
        this.suddenDeath = false;

        this.startWith = STARTSCORE_501.type;
        this.customStartWith = 170;

        this.bestOfFirstTo = BESTOFMODE.type;
        this.goalAmount = 5;
        this.setOrLeg = LEGMODE.type;
        this.entryMode = ENTRYMODE_STRAIGHT.type;
        this.finishMode = FINISHMODE_DOUBLE.type;
        this.playerType = PLAYERMODE.type;

        this.users = [];
        this.teams = [];
    }

    private setStoredSettings(storedSettings: DartCounterMatchSettings) {
        this.vsDartbot = storedSettings.vsDartbot;
        this.dartbotAdded = storedSettings.dartbotAdded;
        this.dartbotLevel = storedSettings.dartbotLevel;

        this.showTwoLegsDifference = storedSettings.showTwoLegsDifference;
        this.twoLegsDifference = storedSettings.twoLegsDifference;
        this.suddenDeath = storedSettings.suddenDeath;

        this.startWith = storedSettings.startWith;
        this.customStartWith = storedSettings.customStartWith;

        this.bestOfFirstTo = storedSettings.bestOfFirstTo;
        this.goalAmount = storedSettings.goalAmount;
        this.setOrLeg = storedSettings.setOrLeg;
        this.entryMode = storedSettings.entryMode;
        this.finishMode = storedSettings.finishMode;

        this.playerType = storedSettings.playerType;

        this.users = storedSettings.users;
        this.teams = storedSettings.teams;
    }

    public setUser(user: User): void {
        super.setUser(user);
        this.checkoutPercentage = user.profile.has_checkout_rate;
    }

    public changePlayerType(playerType: PlayerType): void {
        super.changePlayerType(playerType);
        this.enableTwoLegsDifference();
    }

    public addUser(user, team): void {
        super.addUser(user, team);
        this.enableTwoLegsDifference();
    }

    public removePlayer(event: RemovePlayerPayload): User {
        let removedUser: User = super.removePlayer(event);
        if (removedUser && removedUser.is_cpu) {
            this.vsDartbot = false;
            this.dartbotAdded = false;
        }

        this.enableTwoLegsDifference();
        return removedUser;
    }

    private enableTwoLegsDifference(): void {
        if (this.playerType == 'players') {
            if (this.users.length > 1) {
                this.showTwoLegsDifference = true;
            } else {
                this.showTwoLegsDifference = false;
            }
        } else if (this.playerType == 'teams') {
            if (this.teams.length > 1) {
                this.showTwoLegsDifference = true;
            } else {
                this.showTwoLegsDifference = false;
            }
        }

        // Best of 'even number' -> Dont allow two legs difference
        if (this.bestOfFirstTo === 'best_of' && this.goalAmount % 2 == 0) {
            this.showTwoLegsDifference = false;
        }
    }

    changeStartWith(startWith: StartWith): void {
        this.startWith = startWith;
    }

    changeBestOfFirstTo(bestOfFirstTo: BestOfFirstTo): void {
        this.bestOfFirstTo = bestOfFirstTo;
        this.enableTwoLegsDifference();
    }

    changeSetOrLeg(setOrLeg: SetOrLeg): void {
        this.setOrLeg = setOrLeg;
    }

    changeEntryMode(entryMode: EntryMode): void {
        this.entryMode = entryMode;
    }

    changeFinishMode(finishMode: FinishMode): void {
        this.finishMode = finishMode;
        this.checkCheckoutPercentageDisabled();
    }

    toggleCheckoutPercentage(checkoutPercentage: boolean): void {
        this.checkoutPercentage = checkoutPercentage;
    }

    toggleTwoLegsDifference(): void {
        this.twoLegsDifference = !this.twoLegsDifference;
    }

    vsDartbotChangedWithUser(value: boolean, dartbot: User, dartbotLevel: DartbotLevel): void {
        const user = _.cloneDeep(dartbot);

        if (value === true && dartbot) {
            this.vsDartbot = true;
            this.dartbotLevel = dartbotLevel;

            user.cpu_level = dartbotLevel.level;
            user.first_name += ' (' + dartbotLevel.level + ')';
            user.full_name += ' (' + dartbotLevel.level + ')';

            if (this.dartbotAdded === true) {
                this.dartbotAdded = false;
                if (this.playerType === 'players') {
                    this.users.forEach((userFromList, userIndex) => {
                        if (userFromList.is_cpu) {
                            this.users[userIndex] = user;
                        }
                    });
                } else if (this.playerType === 'teams') {
                    this.teams.forEach((team, teamIndex) => {
                        team.users.forEach((userFromList, userIndex) => {
                            if (userFromList.is_cpu) {
                                this.teams[teamIndex].users[userIndex] = user;
                            }
                        });
                    });
                }
            } else {
                this.dartbotAdded = false;
                if (this.playerType === 'players') {
                    if (this.users.length === 4) {
                        this.users.splice(3, 1);
                    }
                    this.addPlayer({ user });
                } else if (this.playerType === 'teams') {
                    if (this.teams.length < 2) {
                        this.teams.push({ users: [user] });
                    } else {
                        if (this.teams[1].users.length === 1) {
                            this.addPlayer({ user, team: this.teams[1] });
                        } else if (this.teams[0].users.length === 1) {
                            this.addPlayer({ user, team: this.teams[0] });
                        } else {
                            this.teams[1].users.splice(3, 1);
                            this.addPlayer({ user, team: this.teams[1] });
                        }
                    }
                }
            }

            this.dartbotAdded = true;
        } else {
            this.vsDartbot = false;
            this.dartbotAdded = false;
            if (this.playerType === 'players') {
                this.users.forEach((user, userIndex) => {
                    if (user.is_cpu) {
                        this.removePlayer({ userIndex });
                    }
                });
            } else if (this.playerType === 'teams') {
                this.teams.forEach((team, teamIndex) => {
                    team.users.forEach((user, userIndex) => {
                        if (user.is_cpu) {
                            this.removePlayer({ userIndex, teamIndex });
                        }
                    });
                });
            }
        }
    }

    checkDartbotOnly(): boolean {
        let hasOtherUser = false;
        if (this.playerType === 'players') {
            this.users.forEach((user) => {
                if (!user.is_cpu) {
                    hasOtherUser = true;
                }
            });
        } else if (this.playerType === 'teams') {
            this.teams.forEach((team) => {
                team.users.forEach((user) => {
                    if (!user.is_cpu) {
                        hasOtherUser = true;
                    }
                });
            });
        }
        return !hasOtherUser;
    }

    checkCheckoutPercentageDisabled(): void {
        if (this.finishMode === 'master_out' || this.finishMode === 'straight_out') {
            this.checkoutPercentageDisabled = true;
        } else {
            this.checkoutPercentageDisabled = false;
        }
    }

    checkForErrors(): string[] {
        let errors = [];

        if (this.checkDartbotOnly()) {
            errors.push($localize`:@@CANT_START_GAME_WITH_DARTBOT_ONLY:You cannot start a game with only the Dartbot!`);

            return errors;
        }

        if (this.startWith === 'custom' && this.customStartWith < this.minCustomStartWith) {
            this.customStartWith = this.minCustomStartWith;
            errors.push($localize`:@@ERROR_MIN_START_SCORE:The minimum start score is 101`);
        } else if (this.startWith === 'custom' && this.customStartWith > this.maxCustomStartWith) {
            this.customStartWith = this.maxCustomStartWith;
            errors.push($localize`:@@ERROR_MAX_START_SCORE:The maximum start score is 9999`);
        }

        if (this.goalAmount < this.minGoalAmount) {
            this.goalAmount = this.minGoalAmount;
            errors.push($localize`:@@ERROR_MIN_GOAL_AMOUNT:The minimum goal amount is 1 sets or legs`);
        } else if (this.goalAmount > this.maxGoalAmount) {
            this.goalAmount = this.maxGoalAmount;
            errors.push($localize`:@@ERROR_MAX_GOAL_AMOUNT:The maximum goal amount is 30 sets or legs`);
        }

        this.enableTwoLegsDifference();

        if (!this.checkoutPercentageDisabled) {
            if (this.finishMode === 'straight_out' && this.checkoutPercentage) {
                errors.push(
                    $localize`:@@CHECKOUT_RATE_NOT_WITH_STRAIGHT_OUT:Checkout rate not supported with straight-out mode`
                );
            } else if (this.finishMode === 'master_out' && this.checkoutPercentage) {
                errors.push(
                    $localize`:@@CHECKOUT_RATE_NOT_WITH_MASTER_OUT:Checkout rate not supported with master-out mode`
                );
            }
        }

        return errors;
    }

    saveGameSettings() {
        let storageObject = {
            vsDartbot: this.vsDartbot,
            dartbotAdded: this.dartbotAdded,
            dartbotLevel: this.dartbotLevel,
            showTwoLegsDifference: this.showTwoLegsDifference,
            twoLegsDifference: this.twoLegsDifference,
            suddenDeath: this.suddenDeath,
            startWith: this.startWith,
            customStartWith: this.customStartWith,
            bestOfFirstTo: this.bestOfFirstTo,
            goalAmount: this.goalAmount,
            setOrLeg: this.setOrLeg,
            entryMode: this.entryMode,
            finishMode: this.finishMode,
            playerType: this.playerType,
            users: this.users,
            teams: this.teams,
        };
        localStorage.setItem(this.storageKey, JSON.stringify(storageObject));
    }

    mapToMatchObject(): Match {
        let hasCheckoutRate = false;

        const teams: MatchTeam[] = [];
        if (this.playerType === 'players') {
            this.users.forEach((user, index) => {
                const newTeam: MatchTeam = {
                    id: index + 1,
                    match_id: 0,
                    users: [],
                    total_score: 0,
                    darts_thrown: 0,
                    total_score_legs: 0,
                    total_score_sets: 0,
                    has_checkout_rate: false,
                };
                let convertedUser = this.convertUserToMatchUser(user, newTeam.id, index + 1);
                if (convertedUser.has_checkout_rate) {
                    hasCheckoutRate = true;
                    newTeam.has_checkout_rate = convertedUser.has_checkout_rate;
                }
                if (user.is_cpu) {
                    convertedUser.is_cpu = true;
                    convertedUser.cpu_level = this.dartbotLevel.level;
                } else {
                    convertedUser.is_cpu = false;
                }

                if (this.checkoutPercentageDisabled) {
                    newTeam.has_checkout_rate = false;
                    convertedUser.has_checkout_rate = false;
                }

                newTeam.users.push(convertedUser);
                teams.push(newTeam);
            });
        } else {
            let userIndex = 1;
            this.teams.forEach((team, index) => {
                const newTeam: MatchTeam = {
                    id: index + 1,
                    match_id: 0,
                    users: [],
                    total_score: 0,
                    darts_thrown: 0,
                    total_score_legs: 0,
                    total_score_sets: 0,
                    has_checkout_rate: false,
                };
                team.users.forEach((user) => {
                    let convertedUser = this.convertUserToMatchUser(user, newTeam.id, userIndex++);
                    if (convertedUser.has_checkout_rate) {
                        hasCheckoutRate = true;
                        newTeam.has_checkout_rate = convertedUser.has_checkout_rate;
                    }
                    if (user.is_cpu) {
                        convertedUser.is_cpu = true;
                        convertedUser.cpu_level = this.dartbotLevel.level;
                    } else {
                        convertedUser.is_cpu = false;
                    }

                    if (this.checkoutPercentageDisabled) {
                        newTeam.has_checkout_rate = false;
                        convertedUser.has_checkout_rate = false;
                    }

                    newTeam.users.push(convertedUser);
                });
                teams.push(newTeam);
            });
        }

        return <Match>{
            id: 1,
            has_teams: this.playerType === 'teams',
            start_score: this.startWith === 'custom' ? this.customStartWith : parseInt(this.startWith),
            is_best_of: this.bestOfFirstTo === 'best_of',
            has_sets: this.setOrLeg === 'sets',
            goal_amount: this.goalAmount,
            vs_cpu: this.vsDartbot === true,
            cpu_level: this.vsDartbot ? this.dartbotLevel.level : null,
            has_checkout_rate: this.checkoutPercentageDisabled ? false : hasCheckoutRate,
            two_legs_difference: this.showTwoLegsDifference ? this.twoLegsDifference : false,
            enable_sudden_death: this.showTwoLegsDifference && this.twoLegsDifference ? this.suddenDeath : false,
            start_with: this.entryMode,
            end_with: this.finishMode,
            sets: [],
            legs: [],
            is_online: false,
            started_at: moment().utc().format(dateTimeFormat),
            teams,
        };
    }

    convertUserToMatchUser(user: User, team_id: number, id: number): MatchUser {
        return <MatchUser>{
            id,
            match_id: 0,
            name: user.id === null ? user.full_name : user.first_name,
            user_id: user.id,
            user,
            ghost_name: user.id === null ? user.full_name : null,
            team_id: team_id,
            has_checkout_rate: user.id === null ? this.checkoutPercentage : user.profile?.has_checkout_rate,
            is_verified: this.user.id === user.id ? true : false,
        };
    }
}
