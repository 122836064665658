import { Component, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CTAActionTarget } from 'dc-core/dc-backend/dc-enums';
import { components } from 'dc-core/dc-typings/dc-backend-definitions';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-cta-module-dialog',
    templateUrl: 'ctamodule.html',
    styleUrls: ['ctamodule.scss'],
})
export class CTAModuleDialogComponent {
    public module: components['schemas']['CTAModule'];
    public image: string = null;
    public images: string[] = [];
    public summary: string[] = [];

    constructor(public view: ModalController, private navParams: NavParams, public auth: AuthService) {
        this.module = this.navParams.get('module');

        if (this.module.summary_count && this.module.summary_key) {
            for (let i = 1; i <= this.module.summary_count; i++) {
                this.summary.push(this.module.summary_key + i);
            }
        }

        if (this.module.button_action === 'upgrade') {
            //ULTIMATE
            if (auth.user && auth.user.is_ultimate) {
                this.module.button_action = 'close';
                this.module.button_key = 'CLOSE';
            }
            //REGULAR USER
            else if (auth.user && !auth.user.is_ultimate) {
                this.module.button_action = 'upgrade';
                this.module.button_key = 'UPGRADE_BTN';
            }
            //GUEST USER
            else if (!auth.user) {
                this.module.button_action = 'create_account';
                this.module.button_key = 'LOGIN';
            }
        }
    }

    public DoAction(target: CTAActionTarget = null, addon: string = null) {
        if (target) {
            this.DismissModal({
                target,
                addon,
            });
        }
    }

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
