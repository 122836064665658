import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-smart-device-invite-popover',
    templateUrl: './invite-popover.component.html',
    styleUrls: ['invite-popover.component.scss'],
})
export class SmartDeviceInvitePopoverComponent {
    constructor(private popover: PopoverController) {}

    ClosePopover(res = null) {
        this.popover.dismiss(res);
    }
}
