import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserApiService } from '@dc-api/user.api.service';
import { IonContent, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HotkeyKeyboardOptions } from 'dc-core/dc-gamelogic/game-interfaces';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { components } from 'dc-core/dc-typings/dc-backend-definitions';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-hotkeys-dialog',
    templateUrl: 'hotkeys.dialog.html',
    styleUrls: ['hotkeys.dialog.scss'],
})
export class HotkeysDialogComponent implements OnInit {
    @ViewChild(IonContent) content: IonContent;
    public hotkeyKeyboardOptions: HotkeyKeyboardOptions;
    Birthday: any;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute,
        public loading: DCLoadingService,
        private _alertService: DartCounterAlertService,
        private translate: TranslateService,
        private userApiService: UserApiService
    ) {
        this.hotkeyKeyboardOptions = {
            component: this,
        };
    }

    ngOnInit() {
        this.userApiService.getHotkeysByUserId({ userId: this.auth.user.id }).then((res) => {
            this.auth.hotkeys = HotkeysDialogComponent.fromHotkeys(res.data);
        });
    }

    updateHotkeys(hotkeys: number[] = null) {
        const updatedHotkeys = HotkeysDialogComponent.toHotkeys(hotkeys ?? this.auth.hotkeys);
        updatedHotkeys.userId = this.auth.user.id;

        this.loading.ShowDefaultLoader();
        this.userApiService
            .updateHotkeysByUserId(updatedHotkeys)
            .then(() => {
                $localize`:@@HOTKEYS_UPDATED:Your hotkeys have been updated`;
                this._alertService.createAlert({
                    title: this.translate.instant('HOTKEYS_UPDATED'),
                });
                this.loading.DismissLoader();
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    public static fromHotkeys(hotkeys: components['schemas']['UserHotkey']): number[] {
        return [
            hotkeys.hotkey_1,
            hotkeys.hotkey_2,
            hotkeys.hotkey_3,
            hotkeys.hotkey_4,
            hotkeys.hotkey_5,
            hotkeys.hotkey_6,
            hotkeys.hotkey_7,
            hotkeys.hotkey_8,
            hotkeys.hotkey_9,
            hotkeys.hotkey_10,
        ];
    }

    public static toHotkeys(hotkeys: number[]) {
        return {
            userId: null,
            hotkey_1: hotkeys[0],
            hotkey_2: hotkeys[1],
            hotkey_3: hotkeys[2],
            hotkey_4: hotkeys[3],
            hotkey_5: hotkeys[4],
            hotkey_6: hotkeys[5],
            hotkey_7: hotkeys[6],
            hotkey_8: hotkeys[7],
            hotkey_9: hotkeys[8],
            hotkey_10: hotkeys[9],
        };
    }
}
