import { Injectable, signal, WritableSignal } from '@angular/core';
import { User } from '@dc-core/dc-backend/dc-classes';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import { ONLINESTATUS } from '@dc-core/dc-firestore/globals/firestore.tables';
import { PreferenceLocalStorageKey } from '@dc-core/dc-services/preference/preference.models';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { MenuController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageHelper } from 'dc-core/dc-helpers/local-storage.helper';
import { LocalStorageKey } from 'dc-core/dc-localstorage';
import { paths } from 'dc-core/dc-typings/dc-backend-definitions';
import { UserCredential } from 'firebase/auth';
import OneSignal from 'onesignal-cordova-plugin';
import { Fetcher, Middleware } from 'openapi-typescript-fetch';
import { catchError, from, Observable, of, Subject, switchMap, take } from 'rxjs';
import { DEFAULT_HOTKEYS } from 'src/app/app.globals';
import { EchoService } from 'src/app/services/echo.service';
import { HotkeysDialogComponent } from 'src/dialogs/hotkeys/hotkeys.dialog';
import { environment } from 'src/environments/environment';

/*
  Generated class for the AuthService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class AuthService {
    public user: User = null;
    public hotkeys = DEFAULT_HOTKEYS;

    public fetcher = Fetcher.for<paths>();
    public accessToken = '';
    public userIsUltimate: Subject<boolean> = new Subject<boolean>();

    public forceDeactivateRoute = false;

    public viewedDashboard: WritableSignal<boolean> = signal(false);

    private _authenticated = false;
    private fireStoreIsInitializing = false;
    private fireStoreInitialized = false;
    private isSigningOut = false;

    constructor(
        public menu: MenuController,
        private translate: TranslateService,
        private platform: Platform,
        private preferenceService: DartCounterPreferenceService,
        private dcFireAuth: FireStoreAuthService,
        private _usersCollectionService: UsersCollectionService,
        private _echoService: EchoService
    ) {
        this.initFetcher();
    }

    initFetcher() {
        const headerMiddleWare: Middleware = async (url, init, next) => {
            // Set the Backend Language
            if (this.translate.currentLang) {
                init.headers.append('Accept-Language', this.translate.currentLang);
            }

            // Set the Bearer Token
            if (this.accessToken !== null || this.accessToken !== '') {
                init.headers.append('Authorization', 'Bearer ' + this.accessToken);
            }
            const response = await next(url, init);
            return response;
        };

        this.fetcher.configure({
            baseUrl: environment.apiUrl,
            use: [headerMiddleWare],
        });

        this._echoService.init(this.accessToken);
    }

    getAccessToken(): string {
        return this.accessToken;
    }

    saveAccessToken(accessToken) {
        this.accessToken = accessToken;
        localStorage.setItem(LocalStorageKey.accessToken, JSON.stringify(accessToken));
        this.initFetcher();
    }

    setupFireStore(subject: Subject<boolean> = null) {
        if (!this.fireStoreIsInitializing) {
            this.fireStoreIsInitializing = true;

            this.fetcher
                .path('/firebase/token')
                .method('get')
                .create()({})
                .then((res) => {
                    this.dcFireAuth.signIn(res.data.token).then((userCreds: UserCredential) => {
                        // console.log('Logged into Firestore:', userCreds.user.uid);
                        this.dcFireAuth.userCreds = userCreds;
                        this._usersCollectionService.userStartedApp(this.user);

                        this.fireStoreInitialized = true;
                        this.fireStoreIsInitializing = false;

                        if (subject) subject.next(true);
                    });
                })
                .catch(() => {
                    this.fireStoreIsInitializing = false;
                    this.fireStoreInitialized = false;
                });
        }
    }

    public checkAuthentication(): Observable<boolean> {
        const localUserData = JSON.parse(localStorage.getItem(LocalStorageKey.userData));

        if (localUserData && localUserData.accessToken) {
            return from(
                this.fetcher.path('/old-verification').method('post').create()({ old_token: localUserData.accessToken })
            ).pipe(
                switchMap((res) => {
                    this.saveAuthentication(res.data.user, res.data.access_token);

                    localStorage.removeItem(LocalStorageKey.userData);
                    return of(true);
                })
            );
        } else {
            this.setUser(JSON.parse(localStorage.getItem(LocalStorageKey.user)));

            const localToken = JSON.parse(localStorage.getItem(LocalStorageKey.accessToken));
            if (localToken) {
                this.saveAccessToken(localToken);

                return from(this.fetcher.path('/user').method('get').create()({})).pipe(
                    switchMap((res) => {
                        this.saveAuthentication(res.data, localToken, true);
                        return of(true);
                    }),
                    catchError((err) => {
                        if (typeof err.getActualType === 'function' && err.getActualType() !== undefined) {
                            const error = err.getActualType();
                            if (error.status === 401) {
                                this.signOut();
                            }
                        }
                        return of(false);
                    })
                );
            }
        }

        return of(false);
    }

    setUser(user: User | null): void {
        this.user = user;
        this.userIsUltimate.next(this.user?.is_ultimate);
    }

    saveAuthentication(user, token = null, setupFirestore = false) {
        if (token != null) {
            this.saveAccessToken(token);
        }

        this.setUser(user);

        if (this.user.locale) {
            if (this.user.locale !== this.translate.currentLang) {
                this.translate
                    .use(this.user.locale)
                    .pipe(take(1))
                    .subscribe(() => {
                        localStorage.setItem(LocalStorageKey.language, this.user.locale);
                        this.preferenceService.changePreference(PreferenceLocalStorageKey.lang, this.user.locale);
                        this.preferenceService.langSignal.set(this.user.locale);
                    });
            } else {
                this.preferenceService.langSignal.set(this.user.locale);
            }
        } else if (!this.user.locale) {
            this.fetcher
                .path('/users')
                .method('put')
                .create()({ locale: this.translate.currentLang })
                .then(() => {
                    this.user.locale = this.translate.currentLang;
                });
        }

        if (user.hotkeys == null) {
            this.hotkeys = DEFAULT_HOTKEYS;
        } else {
            this.hotkeys = HotkeysDialogComponent.fromHotkeys(user.hotkeys);
        }

        this._authenticated = true;

        if (setupFirestore && !this.fireStoreInitialized) {
            this.setupFireStore();
        }

        localStorage.setItem(LocalStorageKey.user, JSON.stringify(this.user));

        try {
            if (this.platform.is('capacitor')) {
                if (!this.platform.is('desktop')) {
                    OneSignal.setExternalUserId(this.user.id.toString());
                    OneSignal.getDeviceState((response) => {
                        this.fetcher
                            .path('/push-tokens/link')
                            .method('post')
                            .create()({
                                one_signal_id: response.userId,
                                token: response.pushToken,
                            })
                            .then(() => {
                                localStorage.setItem(LocalStorageKey.linkedOneSignalData, JSON.stringify(response));
                            });
                    });
                }
            }
        } catch (_) {}
    }

    logoutFirestore() {
        if (this.dcFireAuth.userCreds) {
            this._usersCollectionService.updateUserStatus(ONLINESTATUS.OFFLINE);
            this.dcFireAuth.signOut();
        }
        this.dcFireAuth.userCreds = null;
        this.fireStoreIsInitializing = false;
        this.fireStoreInitialized = false;
    }

    signOut(): Promise<void> {
        return new Promise((resolve) => {
            if (this.isSigningOut) {
                return;
            }

            this.isSigningOut = true;

            this.fetcher
                .path('/logout')
                .method('post')
                .create()({})
                .catch(console.error)
                .finally(() => {
                    this.logoutFirestore();

                    localStorage.removeItem(LocalStorageKey.userData);
                    localStorage.removeItem(LocalStorageKey.user);
                    localStorage.removeItem(LocalStorageKey.accessToken);
                    localStorage.removeItem('activeGameType');

                    this.clearStorage();

                    this.user = null;
                    this.accessToken = '';
                    this.initFetcher();
                    this._echoService.disconnect();

                    this._authenticated = false;

                    this.isSigningOut = false;

                    resolve();
                });
        });
    }

    clearStorage(): void {
        LocalStorageHelper.itemsToClearWhenLogOut().forEach((item) => {
            localStorage.removeItem(item);
        });
    }

    setFSUserSatus(status: ONLINESTATUS): void {
        this._usersCollectionService.updateUserStatus(status);
    }

    setInApp(): void {
        this._usersCollectionService.updateUserStatus(ONLINESTATUS.IN_APP);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated && this.fireStoreInitialized) {
            return of(true);
        }

        return this.checkAuthentication();
    }
}
