import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { StartWith } from '@dc-core/dc-gamelogic/settings/settings.globals';
import { User } from 'dc-core/dc-backend/dc-classes';
import { GameStatistics } from '../game-statistics.classes';

export interface Checkout121OverallStatsData {
    highest: { average?: number; best?: number; worst?: number };
    streak: { average?: number; best?: number; worst?: number };
    success_rate: { average?: number; best?: number; worst?: number };
}

export interface ScoreTrainingStatDetails {}

export class Checkout121OverallStats extends GameStatistics {
    public startScoreOption: StartWith = '121';
    public startScore: number = 170;
    public maxDart: string = 'all';

    public overallStats: Checkout121OverallStatsData = {
        highest: {},
        streak: {},
        success_rate: {},
    };

    public loadedOverallStats: boolean = false;
    public gameStatisticsApiService: GameStatisticsApiService;

    private _localStorageKey = 'checkout121OverallStats';

    constructor(user: User, gameStatisticsApiService: GameStatisticsApiService) {
        super(user);
        this.gameStatisticsApiService = gameStatisticsApiService;
    }

    setFilters(): void {
        super.setFilters();
    }

    saveFilters() {
        localStorage.setItem(
            this._localStorageKey,
            JSON.stringify({
                statisticsGameType: this.statisticsGameType,
                statisticsRange: this.statisticsRange,
                year: this.year,
                month: this.month,
            })
        );
        super.saveFilters();
    }

    async loadOverallStats(): Promise<void> {
        this.loadedOverallStats = false;
        await this.getUserStats();
        this.loadedOverallStats = true;
        this.saveFilters();
    }

    async getUserStats(): Promise<void> {
        let startScore;
        if ((this.startScoreOption as any) === 'custom') {
            startScore = this.startScore;
        } else if (this.startScoreOption === '121') {
            startScore = '121';
        }

        const maxDarts = this.maxDart === 'all' ? null : this.maxDart;

        return this.gameStatisticsApiService
            .getCheckout121StatisticsDetails({
                start_score: startScore,
                max_darts: maxDarts,
                from_date: this.fromDate,
                to_date: this.untilDate,
            })
            .then((res) => {
                this.overallStats = res.data as any;
            })
            .catch((e) => {
                console.error(e);
            });
    }
}
