import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OnlineUser } from '../dc-gamelogic/online-game/online.ingame.globals';
import { JanusRoom } from './camera/camera.models';
import { UserMedia } from './dc-janus/DartCounterUserMedia';

declare let cast: any;
declare var chrome: any;

export interface CastPlayer {
    name?: string;
    sets: number;
    legs: number;
    score: number;
    user?: OnlineUser;
    room?: JanusRoom;
    userMedia?: UserMedia;
}

@Injectable()
export class CastService {
    public session: any;

    private namespace = 'urn:x-cast:com.dartcounter.net';
    private castContext: any;

    constructor() {
        // this.initializeCastContext();
    }

    private initializeCastContext() {
        try {
            if (typeof cast !== 'undefined') {
                this.castContext = cast.framework.CastContext.getInstance();
                this.castContext.setOptions({
                    receiverApplicationId: environment.chromecast.receiverApplicationId,
                    autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
                });

                this.checkCurrentSession();
            }
        } catch (_) {}
    }

    startCasting(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!this.castContext) {
                resolve(false);
            } else {
                try {
                    await this.castContext.requestSession();

                    this.session = this.castContext.getCurrentSession();
                    resolve(this.session != null);
                } catch (error) {
                    console.error('Error during cast initialization: ', error);
                    resolve(false);
                }
            }
        });
    }

    async stopCasting() {
        try {
            if (!this.checkCurrentSession()) return;

            this.session.endSession(true);
            this.session = null;
        } catch (error) {
            console.error('Error during ending the cast: ', error);
        }
    }

    private checkCurrentSession(): boolean {
        if (!this.session) {
            try {
                this.session = this.castContext.getCurrentSession();
            } catch (error) {
                console.error('Error during session check: ', error);
            }

            if (!this.session) {
                console.log('No cast session');
                return false;
            }
        }

        return true;
    }

    private sendMessage(action: string, data: any): void {
        try {
            if (!this.checkCurrentSession()) return;

            const message: { action: string; data: any } = { action, data };
            this.session
                .sendMessage(this.namespace, message)
                .then(() => {
                    console.log('Message is sent to: ' + this.namespace, message);
                })
                .catch((err) => {
                    console.error('Error sending message:', err);
                });
        } catch (_) {}
    }

    setTitle(title: string): void {
        this.sendMessage('setTitle', title);
    }

    loadPlayers(players: CastPlayer[]): void {
        this.sendMessage('loadPlayers', players);
    }

    updateScores(players: CastPlayer[]): void {
        this.sendMessage('updateScores', players);
    }

    setCurrentPlayer(index: number): void {
        this.sendMessage('setCurrentPlayer', index);
    }
}
