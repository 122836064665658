import { Component, Input } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-date-picker-dialog',
    templateUrl: 'date-picker.dialog.html',
    styleUrls: ['date-picker.dialog.scss'],
})
export class DatePickerDialogComponent {
    @Input() title: string;
    public date: string;
    public maxDate: string = null;

    private _backButtonSubscription: Subscription;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public navParams: NavParams,
        private _platform: Platform
    ) {}

    ionViewWillEnter(): void {
        this.title = this.navParams.get('title');
        this.date = moment(this.navParams.get('date')).utc().toISOString();
        this.maxDate = this.navParams.get('maxDate')
            ? moment(this.navParams.get('maxDate')).add(1, 'day').utc().toISOString()
            : null;

        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.dismiss();
        });
    }

    checkDate(): void {
        if (this.maxDate && moment(this.date).isSameOrAfter(moment(this.maxDate))) {
            const newDate = moment(this.maxDate).subtract(1, 'days').format('YYYY-MM-DD');
            this.date = newDate + 'T' + this.date.split('T')[1];
        }
    }

    ionViewWillLeave(): void {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }
    }

    public dismiss(): void {
        this.view.dismiss(this.date ? this.date.split('+')[0] : null);
    }
}
