import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

export type SMARTDEVICE_SHUTDOWN_RESPONSE = 'reboot' | 'shutdown';

@Component({
    selector: 'app-shutdown-popover',
    templateUrl: './omni-shutdown-popover.component.html',
    styleUrls: ['omni-shutdown-popover.scss'],
})
export class ShutdownPopoverComponent {

    constructor(
        private popover: PopoverController,
    ) {}

    ClosePopover(res: SMARTDEVICE_SHUTDOWN_RESPONSE = null) {
        this.popover.dismiss(res);
    }
}
