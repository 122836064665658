/* eslint-disable max-len */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameApiService } from '@dc-api/game.api.service';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { RouterEventsService } from '@services/router-events.service';
import _ from 'lodash';
import * as moment from 'moment';
import { take } from 'rxjs';
import {
    AssignGameLabelsDialogComponent,
    AssignGameLabelsDialogPayload,
} from 'src/dialogs/assign-game-labels/assign-game-labels.dialog';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-score-training-details',
    templateUrl: 'score-training-details.component.html',
    styleUrls: ['score-training-details.component.scss'],
})
export class ScoreTrainingDetailsComponent {
    public title: string;
    public item: any = null;
    public turns: any;

    constructor(
        public auth: AuthService,
        public nav: NavController,
        public translateService: TranslateService,
        private modal: ModalController,
        private loading: DCLoadingService,
        public route: ActivatedRoute,
        private gameApiService: GameApiService,
        private _previousRouteService: RouterEventsService
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.item = params.item;
            if ((!this.item || !this.item.id) && params.gameId) {
                this.item = {
                    id: params.gameId,
                };
            }

            if (this.item.id) {
                this.gameApiService
                    .getScoreTrainingById({ scoreTrainingId: this.item.id })
                    .then((res) => {
                        this.item = res.data;
                        this.turns = _.groupBy(this.item.turns, 'score_training_user_id');

                        this.item.started_at = moment(this.item.started_at).toISOString();

                        this.item.users.forEach((item) => {
                            item.expanded = false;
                        });
                        setTimeout(() => {
                            this.loading.DismissLoader();
                        }, 250);
                    })
                    .catch(() => {
                        setTimeout(() => {
                            this.loading.DismissLoader();
                        }, 250);
                        // Error go back
                        this.nav.pop();
                    });
            }
        });
    }

    getUserName(user: any): string {
        if (user.user) {
            return user.user.first_name;
        } else {
            return user.ghost_name;
        }
    }

    getAverage(avg) {
        return parseFloat(avg);
    }

    TryRemove() {
        //Show Dialog, do you want to invite your opponent to continue this match?
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@REMOVE_GAME:Remove game`,
                    text: $localize`:@@REMOVING_UNVERIFIED_GAME:Are you sure you want to delete this game from your statistics?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        $localize`:@@REMOVING_IN_PROGRESS:We're removing the game, please wait`;
                        this.loading
                            .ShowCustomLoader({
                                showBackdrop: true,
                                spinner: 'dots',
                                cssClass: 'whiteText',
                                message: this.translateService.instant('REMOVING_IN_PROGRESS'),
                            })
                            .then(() => {
                                this.gameApiService
                                    .removeScoreTrainings({
                                        score_training_ids: [this.item.id],
                                    })
                                    .then(() => {
                                        this.loading.DismissLoader();
                                        this.back(true);
                                    })
                                    .catch(() => {
                                        this.loading.DismissLoader();
                                    });
                            });
                    }
                });
            });
    }

    assignGameLabels() {
        this.modal
            .create({
                component: AssignGameLabelsDialogComponent,
                componentProps: {
                    gameMode: 'score_training',
                    gameId: this.item.id,
                    assignedGameLabels: this.item.labels,
                } as AssignGameLabelsDialogPayload,
                cssClass: 'auto-height',
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onWillDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.item.labels = dialogRes.data;
                    }
                });
            });
    }

    back(refresh: boolean): void {
        if (this._previousRouteService.getPreviousUrl()?.includes('statistics/score-training/list')) {
            this.nav.navigateBack('statistics/score-training/list', {
                queryParams: {
                    refresh,
                },
            });
        } else {
            this.nav.back();
        }
    }

    expandItem(item): void {
        item.expanded = !item.expanded;
    }
}
