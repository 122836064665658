import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

@Injectable()
export class VirtServerService {
    constructor(public http: HttpClient) {}

    sendToVirt(attrs: any, url: string, timeoutSecs: number = null, method: 'get' | 'post' = 'get'): Observable<any> {
        url = 'http://' + url;

        if (timeoutSecs) {
            return this.http
                .request(method, url, {
                    body: attrs,
                    responseType: 'json',
                })
                .pipe(timeout(timeoutSecs * 1000), map(this.extractData), catchError(this.handleError));
        } else {
            return this.http
                .request(method, url, {
                    body: attrs,
                    responseType: 'json',
                })
                .pipe(map(this.extractData), catchError(this.handleError));
        }
    }

    private extractData(res) {
        return res || {};
    }

    private handleError(result) {
        let errMsg: string;
        if (result instanceof HttpErrorResponse) {
            if (!result.error) {
                errMsg = `${result.status} - ${result.statusText || ''}`;
            } else {
                const body = result.error || '';
                if (body.data) {
                    errMsg = body.data;
                } else {
                    if (typeof body !== 'string') {
                        JSON.stringify(body);
                    }
                    errMsg = body;
                }
            }
        } else {
            errMsg = result.message ? result.message : result.toString();
        }

        return observableThrowError(errMsg);
    }
}
