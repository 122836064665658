/* eslint-disable max-len */
import { Component, effect, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ChatsCollectionService,
    SendChatPayload,
} from '@dc-core/dc-firestore/collection-helpers/chats.collection.service';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { CHAT_MSG, ChatMessage } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { VoiceCallService, VoiceCallStatus } from '@dc-core/dc-services/voice/voice-call.service';
import { ModalController, Platform } from '@ionic/angular';
import { Microphone } from '@mozartec/capacitor-microphone';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { GamesLeaveBusterService } from 'src/app/modules/games/services/games-leave-buster.service';

export type VoiceCallDialogPayload = {
    callerUser: DCFireStoreUser;
    callingUser: DCFireStoreUser;
    gameplayDocId: string;
};

export type VoiceCallDialogResponse = 'chat';

@Component({
    selector: 'app-voice-call-dialog',
    templateUrl: 'voice-call.dialog.html',
})
export class VoiceCallDialogComponent implements OnInit, OnDestroy {
    @Input() callerUser: DCFireStoreUser;
    @Input() callingUser: DCFireStoreUser;
    @Input() gameplayDocId: string;

    status: VoiceCallStatus = VoiceCallStatus.None;

    VoiceCallStatus = VoiceCallStatus;

    messages: ChatMessage[] = [
        {
            chat_msg: CHAT_MSG.BE_RIGHT_BACK,
            text: 'BRB',
        },
        {
            chat_msg: CHAT_MSG.CALL_YOU_SOON,
            text: $localize`:@@CHAT_CALL_YOU_SOON:Call you soon!`,
        },
    ];

    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);
    private gamesLeaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);

    private _backButtonSubscription: Subscription;

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement: HTMLElement): void {
        if (targetElement.classList.contains('ion-page')) {
            this.close();
        }
    }

    constructor(
        public videoRoomService: JanusVideoRoomService,
        public voiceCallService: VoiceCallService,
        public ingameCameraService: InGameCameraService,
        public chatsCollectionService: ChatsCollectionService,
        public view: ModalController,
        private _auth: AuthService,
        private _platform: Platform,
        private _alertService: DartCounterAlertService,
        private _preferenceService: DartCounterPreferenceService,
        private _translateService: TranslateService,
        private _chatsCollectionService: ChatsCollectionService
    ) {
        effect(() => {
            this.status = this.voiceCallService.currentStatus();
        });

        effect(() => {
            if (!this.gamesLeaveBusterService.inGame()) {
                this.close();
            }
        });
    }

    ngOnInit(): void {
        this.voiceCallService.showingDialog = true;
        this.voiceCallService.authUserId = this._auth.user.id;
        this.voiceCallService.callingUser = this.callingUser;
        this.voiceCallService.missedCalls.set(0);

        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });
    }

    async call(): Promise<void> {
        if (
            this.appFeaturesService.enabledAppFeatures().speech_to_score &&
            this._preferenceService.enableSpeechToScore
        ) {
            this._alertService.createAlert({
                title: $localize`:@@CANNOT_USE_VOICE_CALL_WITH_SPEECH_TO_SCORE:Cannot use Voice chat with Speech to score`,
                text: $localize`:@@PLEASE_DISABLE_SPEECH_TO_SCORE_TO_USE_VOICE_CALL:Please disable Speech to score in order to use Voice chat.`,
                icon: 'info',
            });
            return;
        }

        if (this._platform.is('capacitor')) {
            try {
                const checkMicPermissions = await Microphone.checkPermissions();
                if (checkMicPermissions.microphone !== 'granted') {
                    const requestMicResult = await Microphone.requestPermissions();
                    if (requestMicResult.microphone === 'granted') {
                        setTimeout(() => {
                            this.call();
                        }, 500);
                        return;
                    } else {
                        throw 'No permission';
                    }
                }
            } catch (_) {
                this._alertService.createAlert({
                    title: $localize`:@@ENABLE_MIC_APP_PERMS:Please enable microphone permissions in your device settings.`,
                    icon: 'error',
                    timer: 2000,
                    timerProgressBar: true,
                    showCloseButton: true,
                });
                return;
            }
        }

        this.voiceCallService.call();
    }

    hangup(close = false): void {
        this.voiceCallService.hangup();
        if (close) {
            this.close();
        }
    }

    decline(returnValue: VoiceCallDialogResponse = null): void {
        this.voiceCallService.decline();
        this.close(returnValue);
    }

    accept(): void {
        this.voiceCallService.accept();
    }

    toggleMute(): void {
        this.videoRoomService.ownAudioUserMedia.toggleMuteAudio();
    }

    translated(tag: string): string {
        switch (tag) {
            case 'USER_IS_BUSY':
                $localize`:@@USER_IS_BUSY:${this.callingUser.first_name}:user: is busy`;
                return this._translateService.instant('USER_IS_BUSY', {
                    user: this.callingUser.first_name,
                });
            case 'USER_HUNG_UP_THE_CALL':
                $localize`:@@USER_HUNG_UP_THE_CALL:${this.callingUser.first_name}:user: hung up the call`;
                return this._translateService.instant('USER_HUNG_UP_THE_CALL', {
                    user: this.callingUser.first_name,
                });
        }
    }

    sendMessage(message: ChatMessage): void {
        this._chatsCollectionService.sendChatMessage({
            sender: this.callerUser,
            gameplay_doc_id: this.gameplayDocId,
            chat_msg: message.chat_msg,
        } as SendChatPayload);

        this.decline('chat');
    }

    public close(returnValue: VoiceCallDialogResponse = null): void {
        this.view.dismiss(returnValue);
    }

    ngOnDestroy(): void {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }

        const currentCall = this.voiceCallService.currentCall();
        if (
            currentCall &&
            (currentCall.status === VoiceCallStatus.Hangup ||
                currentCall.status === VoiceCallStatus.Declined ||
                currentCall.status === VoiceCallStatus.NoResponse)
        ) {
            this.voiceCallService.currentCall.set(null);
        }

        this.voiceCallService.missedCalls.set(0);
        this.voiceCallService.showingDialog = false;
    }
}
