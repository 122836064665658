import { Injectable } from '@angular/core';
import { collection, CollectionReference, Firestore, QueryDocumentSnapshot } from '@angular/fire/firestore';
import _ from 'lodash';
import { FIRESTORE_COLLECTION } from './globals/firestore.tables';

const converter = <T>() => ({
    toFirestore: (data: T) => data,
    fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

@Injectable()
export class FireStoreCollectionsService {
    public getConvertedData: <T>(collectionPath: FIRESTORE_COLLECTION) => CollectionReference<T>;

    constructor(firestore: Firestore) {
        this.getConvertedData = <T>(collectionPath: FIRESTORE_COLLECTION) =>
            collection(firestore, collectionPath).withConverter(converter<T>()) as T;
    }

    getAttributeString(obj, expression) {
        try {
            let res = {};
            Object.keys(obj).map((k) => {
                res[k] = () => k;
            });
            let result = expression(res)();

            if (!_.isEmpty(result)) {
                return result;
            }
        } catch (err) {
            throw `DC FireStore TypeError: Column does not exist on Collection`;
        }
    }
}
