import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonicModule, PopoverController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

export type ChatsConversationOptionsPopoverResponse = 'profile' | 'report' | 'delete';

@Component({
    selector: 'app-chats-conversation-options-popover',
    templateUrl: './chats-conversation-options-popover.component.html',
    standalone: true,
    imports: [CommonModule, IonicModule, TranslateModule],
})
export class ChatsConversationOptionsPopoverComponent {
    private popoverController: PopoverController = inject(PopoverController);

    close(res: ChatsConversationOptionsPopoverResponse = null) {
        this.popoverController.dismiss(res);
    }
}
