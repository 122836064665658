import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { take } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-sudden-death-dialog',
    templateUrl: 'suddendeath.dialog.html',
    styleUrls: ['suddendeath.dialog.scss'],
})
export class SuddenDeathDialogComponent {
    suddenDeath: boolean;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.suddenDeath = params.suddenDeath;
        });
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
