<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <div class="title-container">
            <div class="title" [translate]="'ALSO_WANT_TO_LEAVE_GAME_Q'" i18n="@@ALSO_WANT_TO_LEAVE_GAME_Q">
                Do you also want to leave this game?
            </div>
        </div>
        <div class="button-container flex justify-between space-x-2">
            <app-primary-button
                size="normal"
                color="red"
                addedClasses="px-4"
                (click)="DismissModal()"
                [translate]="'NO'"
                i18n="@@NO">
                No
            </app-primary-button>
            <app-primary-button
                size="normal"
                color="blue"
                addedClasses="px-4"
                (click)="DismissModal(true)"
                [translate]="'YES'"
                i18n="@@YES">
                Yes
            </app-primary-button>
        </div>
    </div>
</modal-content>
