export interface HeadToHeadAudio {
    Splash: H2HAudioConfig[];
    Friendly: H2HAudioConfig[];
    Online: H2HAudioConfig[];
    LastOneThrilling: H2HAudioConfig[];
    LastOneClear: H2HAudioConfig[];
    LastMatchesFav: H2HAudioConfig[];
    LastMatchesBothGood: H2HAudioConfig[];
    LastMatchesBothMeh: H2HAudioConfig[];
}

export enum H2HSection {
    GENERAL_STATS = 'general_stats',
    LASTFIVE = 'lastfive',
    HEADTOHEAD = 'headtohead',
}

export interface H2HAudioConfig {
    file: string;
    duration: number;
    animation?: H2HAnimationConfig;
}

export interface H2HAnimationConfig {
    type: string;
    target: string;
    timeout: number;
}

export const _HeadToHeadAudioFiles: HeadToHeadAudio = {
    Splash: [
        {
            file: 'splash-future.mp3',
            duration: 4,
        },
        {
            file: 'splash-welcome.mp3',
            duration: 3,
        },
    ],
    Friendly: [
        {
            file: 'welcome_match1.mp3',
            duration: 10,
            animation: {
                type: 'flip',
                target: 'players',
                timeout: 8000,
            },
        },
        {
            file: 'welcome_match2.mp3',
            duration: 11,
            animation: {
                type: 'flip',
                target: 'players',
                timeout: 8500,
            },
        },
        {
            file: 'welcome_friendly_match.mp3',
            duration: 11,
            animation: {
                type: 'flip',
                target: 'players',
                timeout: 8000,
            },
        },
    ],
    Online: [
        {
            file: 'Online_match1.mp3',
            duration: 11,
            animation: {
                type: 'flip',
                target: 'players',
                timeout: 8000,
            },
        },
        {
            file: 'Online_match2.mp3',
            duration: 12,
            animation: {
                type: 'flip',
                target: 'players',
                timeout: 8000,
            },
        },
    ],
    LastOneThrilling: [
        {
            file: 'Last_game_thrilling.mp3',
            duration: 12,
        },
    ],
    LastOneClear: [
        {
            file: 'Last_game_clear1.mp3',
            duration: 11,
        },
        {
            file: 'Last_game_clear2.mp3',
            duration: 11,
        },
    ],
    LastMatchesFav: [
        {
            file: 'Last_matches_fav1.mp3',
            duration: 11,
            animation: {
                type: 'flip',
                target: 'profilepic',
                timeout: 3300,
            },
        },
        {
            file: 'Last_matches_fav2.mp3',
            duration: 12,
            animation: {
                type: 'flip',
                target: 'profilepic',
                timeout: 3300,
            },
        },
    ],
    LastMatchesBothGood: [
        {
            file: 'Last_matches_both_good.mp3',
            duration: 11,
        },
    ],
    LastMatchesBothMeh: [
        {
            file: 'Last_matches_meh1.mp3',
            duration: 8,
        },
        {
            file: 'Last_matches_meh2.mp3',
            duration: 8,
        },
    ],
};
