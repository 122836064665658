import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-login-or-create-dialog',
  templateUrl: 'login-or-create.html',
  styleUrls: ['login-or-create.scss'],
})
export class LoginOrCreateDialogComponent {
  showFB = false;

  constructor(
    public view: ModalController,
    private modal: ModalController,
    public route: ActivatedRoute,
    public navCtrl: NavController
  ) {}

  public DismissModal(returnValue: any = null): void {
    this.view.dismiss(returnValue);
  }

  GoToRegistration() {
    this.navCtrl.navigateForward('register');
    this.modal.dismiss();
  }
}
