/* eslint-disable max-len */
import { animate, style, transition, trigger } from '@angular/animations';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SubscriptionApiService } from '@dc-api/subscription.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { SubscriptionHelper } from '@dc-core/dc-helpers/subscription.helper';
import { CancelSubscription } from '@dc-core/dc-logic/cancel-subscription.classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { PurchaseService } from '@providers/PurchaseService';
import { AuthService } from '@services/auth.service';
import _ from 'lodash';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AppFeaturesService, UltimateFeature } from 'src/app/core/app-features/services/app-features.service';
import { environment } from 'src/environments/environment';

import { SwitchSalesFunnelDialogComponent } from '../switch-sales-funnel/switch-sales-funnel.dialog';

type SlideType = 'upgrade_to_yearly' | 'advantages_dartcounter' | 'cancel_subscription';

type Slide = {
    index: number;
    type: SlideType;
    title: string;
    name: string;
    nextText: string;
};

@Component({
    selector: 'app-dialog-cancel-subscription',
    templateUrl: 'cancel-subscription.html',
    styleUrls: ['cancel-subscription.scss'],
    animations: [
        trigger('slideAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('300ms ease-out', style({ transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)' }),
                animate('150ms ease-out', style({ transform: 'translateX(-100%)' })),
            ]),
        ]),
    ],
})
export class CancelSubscriptionDialogComponent extends CancelSubscription implements OnInit {
    public slides: Slide[] = [
        {
            index: 0,
            type: 'upgrade_to_yearly',
            title: $localize`:@@CANCEL_SUBSCRIPTION:Cancel subscription`,
            name: $localize`:@@CHANGE_YOUR_SUBSCRIPTION:Change your subscription`,
            nextText: $localize`:@@CONTINUE:Continue`,
        },
        {
            index: 1,
            type: 'advantages_dartcounter',
            title: $localize`:@@CANCEL_SUBSCRIPTION:Cancel subscription`,
            name: $localize`:@@THE_ADVANTAGES_OF_ULTIMATE:The advantages of ultimate`,
            nextText: $localize`:@@REMAIN_ULTIMATE:Remain ultimate`,
        },
        {
            index: 2,
            type: 'cancel_subscription',
            title: $localize`:@@CANCEL_SUBSCRIPTION:Cancel subscription`,
            name: $localize`:@@HOW_CAN_WE_IMPROVE_DARTCOUNTER:How can we improve DartCounter?`,
            nextText: $localize`:@@CONTINUE:Continue`,
        },
    ];
    public currentSlide: Slide = this.slides[0];
    public ultimateFeatures: UltimateFeature[] = [];
    public isEnd = false;
    public animationState = 'right';

    public cancelReasons: any;

    public isIOS = false;

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private _view: ModalController,
        private _view2: ModalController,
        public purchases: PurchaseService,
        public appFeaturesService: AppFeaturesService,
        private ga: DartCounterAnalyticsService,
        public subscriptionApiService: SubscriptionApiService,
        private _userApiService: UserApiService,
        public alertService: DartCounterAlertService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscription = this.auth.user.ultimate_subscription;
        this.howToCancel = SubscriptionHelper.howToCancel(this.subscription);
        this.subscriptionApiService.getSubscriptionCancelReasons({}).then((res) => {
            const shuffle = _.shuffle(res.data);
            shuffle.push(
                shuffle.splice(
                    shuffle.findIndex((item) => item.id == 56),
                    1
                )[0]
            );
            this.cancelReasons = shuffle;
            this.setCurrentCancelReasons();
        });

        // Get all Ultimate Features that are defined in the app-features service
        this.ultimateFeatures = this.appFeaturesService.getAvailableUltimateFeatures();

        if (this.subscription.plan?.interval_period === 'month') {
            this.slides[0].title = $localize`:@@UPGRADE_TO_YEARLY:Upgrade to Yearly`;
        } else if (this.subscription.plan?.interval_period === 'year') {
            this.slides[0].title = $localize`:@@UPGRADE_TO_MONTHLY:Upgrade to Monthly`;
        }
    }

    goBack(): void {
        this.nav.back();
    }

    public dismissModal(returnValue = null): void {
        this._view.dismiss(returnValue);
    }

    nextSlide(): void {
        if (this.currentSlide.index === this.slides[this.slides.length - 1].index) {
            return;
        }

        this.currentSlide = this.slides.find((slide) => slide.index === this.currentSlide.index + 1);
        this.animationState = 'left';
    }

    yearPricePerMonth(pricing: any): string {
        return formatCurrency(
            Math.floor(pricing.priceMicros / 10000 / 12) / 100,
            'en',
            getCurrencySymbol(pricing.currency, 'narrow')
        );
    }

    removeSpaces(str: string): string {
        if (!str) {
            return '';
        }
        return str.split(' ').join('');
    }

    public setRecurring(recurring: 'monthly' | 'yearly') {
        this.dismissModal();
        this._view2
            .create({
                component: SwitchSalesFunnelDialogComponent,
                componentProps: {
                    recurring,
                },
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
            });
    }

    public confirmCancel(): void {
        this.subscriptionApiService
            .cancelSubscriptionById({
                subscriptionId: this.subscription.id,
                cancel_reason_id: this.selectedReason.id,
                cancel_reason_explanation: this.explainedReason,
                answers: this.answers,
            })
            .then(async () => {
                await this._userApiService.getCurrentUser({}).then((res) => (this.auth.user = res.data));
                this.auth.user.is_ultimate = false;

                this.ga.trackEvent(GA_EVENTCATEGORIES.SUBSCRIPTIONS, GA_EVENTACTIONS.SUBSCRIPTION_CANCELLED);

                this.alertService.createAlert({
                    title: $localize`:@@YOU_CANCELLED_YOUR_SUBSCRIPTION:You cancelled your subscription`,
                    icon: 'success',
                });

                // this.close(true);
            })
            .catch((err) => this.alertService.errorFromApi(err));
    }
}
