import { Injectable } from '@angular/core';
import {
    CollectionReference,
    DocumentData,
    Firestore,
    QuerySnapshot,
    collection,
    getDocs,
    onSnapshot,
    query,
} from '@angular/fire/firestore';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { FIRESTORE_COLLECTION, DCDowntime, DowntimeStatus } from '../globals/firestore.tables';

export type DowntimeWithTime = {
    doc_id: string;
    active: boolean;
    minutesToGo?: number;
    downtime: DCDowntime;
};

@Injectable()
export class DowntimesCollectionService extends FireStoreCollectionsService {
    private defaultClass = new DCDowntime();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.DOWNTIMES;
    private firestore_collection: CollectionReference<DCDowntime>;

    public timeouts: any[] = [];
    downtimes$ = new BehaviorSubject<DowntimeWithTime | null>(null);
    currentDowntime: DCDowntime = null;

    constructor(private firestore: Firestore) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<DCDowntime>(this.collection_name);
    }

    enableDowntimes(): void {
        this.watchDowntimes().subscribe((downtimes: DCDowntime[]) => {
            this.timeouts.forEach((timeout) => {
                clearTimeout(timeout);
            });

            if (downtimes.length) {
                downtimes.forEach((downtime) => {
                    this.checkDowntime(downtime, true);
                });
            } else {
                this.endDowntime();
            }
        });
    }

    checkDowntime(downtime: DCDowntime, checkOnly = false): boolean {
        if (downtime) {
            if (downtime.status === DowntimeStatus.INACTIVE) {
                if (checkOnly) {
                    this.endDowntime();
                }
                return false;
            }

            const now = new Date().getTime();
            const startTime = downtime.from.toDate().getTime();
            const endTime = downtime.until?.toDate()?.getTime();

            if (
                downtime.status === DowntimeStatus.ACTIVE ||
                (now >= startTime && (!downtime.until || now <= endTime))
            ) {
                if (checkOnly) {
                    this.startDowntime(downtime);
                }
                return true;
            } else if (downtime.until && now > endTime) {
                if (checkOnly) {
                    this.endDowntime();
                }
            } else {
                if (checkOnly) {
                    this.scheduleDowntimeNotifications(downtime);
                }
            }
        }
        return false;
    }

    scheduleDowntimeNotifications(downtime: DCDowntime): void {
        const now = new Date().getTime();
        const startTime = downtime.from.toDate().getTime();

        // Define notification times in minutes
        const notificationTimes = [30, 15, 5, 1];

        notificationTimes.forEach((minutesToGo) => {
            const delay = startTime - now - minutesToGo * 60 * 1000; // Calculate delay in milliseconds

            if (delay > 0) {
                const timeout = setTimeout(() => {
                    this.downtimes$.next({ doc_id: downtime.doc_id, downtime, active: false, minutesToGo });
                }, delay);
                this.timeouts.push(timeout);
            }
        });

        // Timeout for downtime start
        const startDelay = startTime - now;
        if (startDelay > 0) {
            const timeout = setTimeout(() => {
                this.startDowntime(downtime);
            }, startDelay);
            this.timeouts.push(timeout);
        }
    }

    startDowntime(downtime: DCDowntime): void {
        this.currentDowntime = downtime;
        this.downtimes$.next({ doc_id: downtime.doc_id, downtime, active: true });

        const now = new Date().getTime();
        const endTime = downtime.until?.toDate()?.getTime();

        // Timeout for downtime start
        if (downtime.until) {
            const endDelay = endTime - now;
            if (endDelay > 0) {
                const timeout = setTimeout(() => {
                    this.endDowntime();
                }, endDelay);
                this.timeouts.push(timeout);
            }
        }
    }

    endDowntime(): void {
        this.currentDowntime = null;
        this.downtimes$.next(null);
    }

    getInitialDowntimes(): Promise<QuerySnapshot<DocumentData>> {
        const downtimesRef = collection(this.firestore, this.collection_name);

        const finalQuery = query(downtimesRef);

        return getDocs(finalQuery);
    }

    watchDowntimes(): Observable<DCDowntime[]> {
        return new Observable<DCDowntime[]>((observer) => {
            try {
                const downtimesQuery = query(this.firestore_collection);

                const downtimes: DCDowntime[] = [];

                const unsubscribe = onSnapshot(downtimesQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as DCDowntime;
                        const doc_id = change.doc.id;
                        const index = downtimes.findIndex((game) => game.doc_id === doc_id);

                        if (change.type === 'added') {
                            downtimes.push({ doc_id, ...data });
                        } else if (change.type === 'modified') {
                            if (index !== -1) {
                                downtimes[index] = { doc_id, ...data };
                            }
                        } else if (change.type === 'removed') {
                            if (index !== -1) {
                                downtimes.splice(index, 1);
                            }
                        }
                    });

                    observer.next(downtimes);
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }
}
