<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'HOW_MANY_TURNS'" i18n="@@HOW_MANY_TURNS">How many turns</div>
    </div>
    <div class="slide-modal-content">
        <div class="subtitle" [translate]="'ENTER_AN_AMOUNT'" i18n="@@ENTER_AN_AMOUNT">Enter an amount</div>
        <form #form="ngForm" [formGroup]="turnsForm" (ngSubmit)="turnsForm.valid && save()">
            <div class="my-4">
                <input
                    [ngClass]="{
                        '!border-red': form.submitted && turnsForm.get('amountOfTurns').errors
                    }"
                    formControlName="amountOfTurns"
                    type="number"
                    inputmode="numeric"
                    min="1"
                    max="999"
                    class="dialog-input text-center" />
                @if (form.submitted && turnsForm.get('amountOfTurns').errors) {
                    @if (turnsForm.get('amountOfTurns').errors.min) {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MIN_AMOUNT_OF_TURNS'" i18n="@@ERROR_MIN_AMOUNT_OF_TURNS">
                                The minimum amount is turns is 1
                            </span>
                        </app-validation-label>
                    } @else if (turnsForm.get('amountOfTurns').errors.max) {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MAX_AMOUNT_OF_TURNS'" i18n="@@ERROR_MAX_AMOUNT_OF_TURNS">
                                The maximum amount is turns is 999
                            </span>
                        </app-validation-label>
                    } @else {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MIN_AMOUNT_OF_TURNS'" i18n="@@ERROR_MIN_AMOUNT_OF_TURNS">
                                The minimum amount is turns is 1
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
            <div class="button-container">
                <app-primary-button
                    type="submit"
                    size="normal"
                    addedClasses="w-full"
                    color="orange"
                    [translate]="'CONFIRM'"
                    i18n="@@CONFIRM">
                    Confirm
                </app-primary-button>
            </div>
        </form>
    </div>
</div>
