import { CricketTactics } from 'dc-core/dc-backend/dc-classes';
import { CricketTacticsSettings } from 'dc-core/dc-backend/dc-enums';
import { ONLINE_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import { DartCounterOnlineGameSettings } from '../settings-classes';
import {
    BESTOFMODE,
    BestOfFirstTo,
    CRICKET_MODE,
    FIRSTTOMODE,
    LEGMODE,
    SETSMODE,
    SetOrLeg,
    TACTICS_MODE,
} from '../../settings.globals';
import { OnlineGameplay } from 'dc-core/dc-backend/dc-interfaces';

export class DartCounterOnlineCricketTacticsSettings extends DartCounterOnlineGameSettings {
    public modes: { label: string; tag: string; type: CricketTacticsSettings }[] = [];
    public mode: CricketTacticsSettings = 'cricket';
    public inGameTimer: number = 60;
    public minInGameTimer: number = 30;
    public maxInGameTimer: number = 80;
    public fastScoring: boolean = false;
    public bestOfFirstTos: { label: string; tag: string; type: BestOfFirstTo }[] = [];
    public bestOfFirstTo: BestOfFirstTo = 'best_of';
    public goalAmount: number = 5;
    public minGoalAmount = 1;
    public maxGoalAmount = 30;
    public setsOrLegs: { label: string; tag: string; type: SetOrLeg }[] = [];
    public setOrLeg: SetOrLeg = 'legs';
    public twoLegsDifference: boolean = false;
    public suddenDeath: boolean = false;

    constructor() {
        //Set the super-class settings
        super();
        this.storageKey = ONLINE_GAME_SETTINGS_STORAGE_KEYS.ONLINE_CRICKET_TACTICS;

        //Set the specific settings
        this.modes = [CRICKET_MODE, TACTICS_MODE];
        this.bestOfFirstTos = [BESTOFMODE, FIRSTTOMODE];
        this.setsOrLegs = [LEGMODE, SETSMODE];

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        }
    }

    private setStoredSettings(storedSettings: DartCounterOnlineCricketTacticsSettings) {
        this.mode = storedSettings.mode;
        this.inGameTimer = storedSettings.inGameTimer;
        this.fastScoring = storedSettings.fastScoring;
        this.bestOfFirstTo = storedSettings.bestOfFirstTo ?? this.bestOfFirstTo;
        this.goalAmount = storedSettings.goalAmount ?? this.goalAmount;
        this.setOrLeg = storedSettings.setOrLeg ?? this.setOrLeg;
        this.twoLegsDifference = storedSettings.twoLegsDifference ?? this.twoLegsDifference;
        this.suddenDeath = storedSettings.suddenDeath ?? this.suddenDeath;
        this.cameraOnly = storedSettings.cameraOnly;
    }

    changeMode(mode: CricketTacticsSettings): void {
        this.mode = mode;
    }

    changeBestOfFirstTo(bestOfFirstTo: BestOfFirstTo): void {
        this.bestOfFirstTo = bestOfFirstTo;
    }

    changeSetOrLeg(setOrLeg: SetOrLeg): void {
        this.setOrLeg = setOrLeg;
    }

    checkForErrors(): string[] {
        let errors = [];

        if (this.inGameTimer < this.minInGameTimer) {
            this.inGameTimer = this.minInGameTimer;
            errors.push($localize`:@@ERROR_MIN_TIMER:The minimum timer is 30 seconds`);
        } else if (this.inGameTimer > this.maxInGameTimer) {
            this.inGameTimer = this.maxInGameTimer;
            errors.push($localize`:@@ERROR_MAX_TIMER:The maximum timer is 80 seconds`);
        }

        if (this.goalAmount < this.minGoalAmount) {
            this.goalAmount = this.minGoalAmount;
        } else if (this.goalAmount > this.maxGoalAmount) {
            this.goalAmount = this.maxGoalAmount;
            errors.push($localize`:@@ERROR_MAX_GOAL_AMOUNT:The maximum goal amount is 30 sets or legs`);
        }

        return errors;
    }

    saveSettings(): void {
        localStorage.setItem(
            this.storageKey,
            JSON.stringify({
                mode: this.mode,
                inGameTimer: this.inGameTimer,
                fastScoring: this.fastScoring,
                bestOfFirstTo: this.bestOfFirstTo,
                goalAmount: this.goalAmount,
                setOrLeg: this.setOrLeg,
                twoLegsDifference: this.twoLegsDifference,
                suddenDeath: this.suddenDeath,
                cameraOnly: this.cameraOnly,
            })
        );
    }

    mapToOnlineGameplayObject(): OnlineGameplay {
        const onlineSavedGameplay: OnlineGameplay = {
            type: this.onlineGameMode,
            game: {
                has_teams: false,
                settings: this.mode,
                is_best_of: this.bestOfFirstTo === 'best_of',
                has_sets: this.setOrLeg === 'sets',
                goal_amount: this.goalAmount,
                vs_cpu: false,
                two_legs_difference: this.twoLegsDifference,
                enable_sudden_death: this.twoLegsDifference ? this.suddenDeath : false,
                sets: [],
                legs: [],
                is_online: true,
                teams: [],
            } as CricketTactics,
            owners: [],
            players: [],
            inGameTimer: this.inGameTimer,
            fastScoring: this.fastScoring,
            cameraOnly: this.cameraOnly,
        };
        return onlineSavedGameplay;
    }
}
