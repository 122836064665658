import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyboardClickEvent } from '@dc-core/dc-backend/dc-classes';
import { KeyboardClickSource } from '@dc-core/dc-backend/dc-enums';
import { WebKeyboardContainerDirective } from 'src/directives/web-keyboard-container.directive';
import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-new-numbers-keyboard',
    standalone: true,
    imports: [CommonModule, WebKeyboardContainerDirective, IconComponent],
    templateUrl: 'new-numbers-keyboard.component.html',
})
export class NewNumbersKeyboardComponent {
    @Input() undoScore = false;

    @Output() keyboardClickEvent = new EventEmitter<KeyboardClickEvent>();
    @Output() undoScoreEvent = new EventEmitter();

    onClick(key: any, source: KeyboardClickSource) {
        this.keyboardClickEvent.emit({
            key: key,
            source: source,
        });
    }

    emitUndoScore(): void {
        if (this.undoScore) {
            this.undoScoreEvent.emit();
        }
    }
}
