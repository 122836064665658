import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionStatus } from 'dc-core/dc-backend/dc-enums';

@Pipe({
    name: 'subscriptionStatus',
})
export class SubscriptionStatusPipe implements PipeTransform {
    transform(status: SubscriptionStatus): string {
        switch (status) {
            case 'on_trial': // Orange
                return $localize`:@@ON_TRIAL:On trial`;
            case 'on_grace_period':
                return $localize`:@@CANCELLED:Cancelled`;
            case 'active':
                return $localize`:@@ACTIVE:Active`;
            case 'ended':
                return $localize`:@@ENDED:Ended`;
            case 'cancelled':
                return $localize`:@@CANCELLED:Cancelled`;
            case 'valid':
                return $localize`:@@VALID:Valid`;
        }
    }
}
