import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    input,
    InputSignal,
    OnInit,
    Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { InGameKeyboardType } from '@dc-core/dc-gamelogic/game-interfaces';
import { ThemeService } from '@services/theme.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-keyboard-dropdown-black',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: 'keyboard-dropdown-black.component.html',
})
export class KeyboardDropdownBlackComponent implements OnInit {
    public isExtraLargeDevice: InputSignal<boolean> = input<boolean>();
    @Input() keyboard: InGameKeyboardType;
    @Input() disableOmni: boolean = false;

    @Output() changeKeyboardEvent = new EventEmitter<InGameKeyboardType>();

    public themeService: ThemeService = inject(ThemeService);

    keyboards: { name: InGameKeyboardType; icon_name: string }[] = [
        { name: 'numbers', icon_name: 'keyboard_default' },
        { name: 'single', icon_name: 'keyboard_single' },
        { name: 'hotkeys', icon_name: 'keyboard_shortcuts' },
    ];

    dropdownOpened = false;

    public isWeb: boolean = environment.isWeb;
    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        private _elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
        private _smartDeviceApiService: SmartDeviceApiService
    ) {}

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside && this.dropdownOpened) {
            this.dropdownOpened = false;
            this._changeDetectorRef.detectChanges();
        }
    }

    ngOnInit(): void {
        if (environment.isWeb) {
            this.keyboards.unshift({ name: 'none', icon_name: 'keyboard_only' });
        }

        this._smartDeviceApiService.hasOmni$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hasOmni) => {
            if (hasOmni && !environment.isWeb && !this.disableOmni) {
                this.keyboards.push({ name: 'omni', icon_name: 'dartboard' });
            }
        });
    }

    toggleDropdown(): void {
        this.dropdownOpened = !this.dropdownOpened;
    }

    changeKeyboard(keyboard: InGameKeyboardType): void {
        this.toggleDropdown();
        this.changeKeyboardEvent.emit(keyboard);
    }
}
