/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameLabelApiService } from '@dc-api/game-label.api.service';
import { GameLabel, GameLabelCategory } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAudioService } from '@dc-core/dc-services/audio/audio.service';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AlertPayload, DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { AuthService } from 'src/services/auth.service';

import {
    ManageGameLabelDialogComponent,
    ManageGameLabelDialogResponse,
} from '../manage-game-label/manage-game-label.dialog';
import {
    ManageGameLabelCategoryDialogComponent,
    ManageGameLabelCategoryDialogResponse,
} from '../manage-game-label-category/manage-game-label-category.dialog';
import { PromptDialogComponent } from '../prompt/prompt.dialog';

export interface GameLabelsDialogPayload {
    gameLabelCategories?: GameLabelCategory[];
}

@Component({
    selector: 'app-game-labels-dialog',
    templateUrl: 'game-labels.dialog.html',
    styleUrls: ['game-labels.dialog.scss'],
})
export class GameLabelsDialogComponent implements OnInit {
    gameLabelCategories: GameLabelCategory[];

    constructor(
        public platform: Platform,
        public view: ModalController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        private dcAudio: DartCounterAudioService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute,
        private _alertService: DartCounterAlertService,
        private _navParams: NavParams,
        private _gameLabelApiService: GameLabelApiService
    ) {}

    ngOnInit(): void {
        if (this._navParams.get('gameLabelCategories')) {
            this.gameLabelCategories = this._navParams.get('gameLabelCategories');
        } else {
            this._gameLabelApiService.getGameLabelCategories({}).then((res) => {
                this.gameLabelCategories = res.data;
            });
        }
    }

    manageCategory(gameLabelCategory: GameLabelCategory = null, categoryIndex: number = null): void {
        if (gameLabelCategory && !gameLabelCategory.user_id) {
            this._alertService.createAlert({
                title: $localize`:@@CANT_EDIT_DEFAULT_GAME_LABEL_CATEGORY:You cannot edit a default game label category`,
                icon: 'error',
            });
            return;
        }

        this._modal
            .create({
                component: ManageGameLabelCategoryDialogComponent,
                componentProps: {
                    gameLabelCategory: JSON.parse(JSON.stringify(gameLabelCategory)),
                },
                cssClass: 'auto-height',
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const response: ManageGameLabelCategoryDialogResponse = dialogRes.data;
                    if (response) {
                        const dialogGameLabel = response.gameLabelCategory;
                        if (response.type === 'manage') {
                            if (gameLabelCategory) {
                                this._gameLabelApiService
                                    .updateGameLabelCategoryById({
                                        gameLabelCategoryId: gameLabelCategory.id,
                                        title: dialogGameLabel.title,
                                        color: dialogGameLabel.color,
                                        text_color: dialogGameLabel.text_color,
                                    })
                                    .then((res) => {
                                        res.data.labels = gameLabelCategory.labels;
                                        this.gameLabelCategories[categoryIndex] = res.data;
                                        this.gameLabelCategories.sort((a, b) =>
                                            a.final_title.localeCompare(b.final_title)
                                        );

                                        this._alertService.createAlert({
                                            title: $localize`:@@GAME_LABEL_CATEGORY_UPDATED:Game label category updated successfully`,
                                        });
                                    });
                            } else {
                                this._gameLabelApiService
                                    .storeGameLabelCategory({
                                        title: dialogGameLabel.title,
                                        color: dialogGameLabel.color,
                                        text_color: dialogGameLabel.text_color,
                                    })
                                    .then((res) => {
                                        res.data.labels = [];
                                        this.gameLabelCategories.push(res.data);
                                        this.gameLabelCategories.sort((a, b) =>
                                            a.final_title.localeCompare(b.final_title)
                                        );

                                        this._alertService.createAlert({
                                            title: $localize`:@@GAME_LABEL_CATEGORY_CREATED:Game label category created successfully`,
                                        });
                                    });
                            }
                        } else if (response.type === 'delete') {
                            this._modal
                                .create({
                                    component: PromptDialogComponent,
                                    componentProps: {
                                        title: $localize`:@@REMOVE_GAME_LABEL_CATEGORY:Remove game label category`,
                                        text: $localize`:@@REMOVING_GAME_LABEL_CATEGORY:Do you want to remove this game label category?`,
                                        cancelText: $localize`:@@CANCEL:Cancel`,
                                        confirmText: $localize`:@@CONFIRM:Confirm`,
                                        confirmColor: 'red',
                                    } as AlertPayload,
                                    cssClass: 'auto-height',
                                    showBackdrop: true,
                                    backdropDismiss: false,
                                })
                                .then((elem) => {
                                    elem.present();
                                    elem.onDidDismiss().then((dialogRes) => {
                                        if (dialogRes.data) {
                                            this._gameLabelApiService
                                                .deleteGameLabelCategoryById({
                                                    gameLabelCategoryId: gameLabelCategory.id,
                                                })
                                                .then(() => {
                                                    this.gameLabelCategories.splice(categoryIndex, 1);

                                                    this._alertService.createAlert({
                                                        title: $localize`:@@GAME_LABEL_CATEGORY_DELETED:Game label category deleted successfully`,
                                                    });
                                                });
                                        }
                                    });
                                });
                        }
                    }
                });
            });
    }

    manageLabel(gameLabel: GameLabel = null, categoryIndex: number = null, labelIndex: number = null): void {
        if (gameLabel && !gameLabel.user_id) {
            this._alertService.createAlert({
                title: $localize`:@@CANT_EDIT_DEFAULT_GAME_LABEL:You cannot edit a default game label`,
                icon: 'error',
            });
            return;
        }

        this._modal
            .create({
                component: ManageGameLabelDialogComponent,
                componentProps: {
                    gameLabel: JSON.parse(JSON.stringify(gameLabel)),
                },
                cssClass: 'auto-height',
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const response: ManageGameLabelDialogResponse = dialogRes.data;
                    if (response) {
                        const dialogGameLabel = response.gameLabel;
                        if (response.type === 'manage') {
                            if (gameLabel) {
                                this._gameLabelApiService
                                    .updateGameLabelById({
                                        gameLabelId: gameLabel.id,
                                        category_id: gameLabel.category_id,
                                        title: dialogGameLabel.title,
                                    })
                                    .then((res) => {
                                        this.gameLabelCategories[categoryIndex].labels[labelIndex] = res.data;
                                        this.gameLabelCategories[categoryIndex].labels.sort((a, b) =>
                                            a.final_title.localeCompare(b.final_title)
                                        );

                                        this._alertService.createAlert({
                                            title: $localize`:@@GAME_LABEL_UPDATED:Game label updated successfully`,
                                        });
                                    });
                            } else {
                                this._gameLabelApiService
                                    .storeGameLabel({
                                        category_id: this.gameLabelCategories[categoryIndex].id,
                                        title: dialogGameLabel.title,
                                    })
                                    .then((res) => {
                                        this.gameLabelCategories[categoryIndex].labels.push(res.data);
                                        this.gameLabelCategories[categoryIndex].labels.sort((a, b) =>
                                            a.final_title.localeCompare(b.final_title)
                                        );

                                        this._alertService.createAlert({
                                            title: $localize`:@@GAME_LABEL_CREATED:Game label created successfully`,
                                        });
                                    });
                            }
                        } else if (response.type === 'delete') {
                            this._modal
                                .create({
                                    component: PromptDialogComponent,
                                    componentProps: {
                                        title: $localize`:@@REMOVE_GAME_LABEL:Remove game label`,
                                        text: $localize`:@@REMOVING_GAME_LABEL:Do you want to remove this game label?`,
                                        cancelText: $localize`:@@CANCEL:Cancel`,
                                        confirmText: $localize`:@@CONFIRM:Confirm`,
                                        confirmColor: 'red',
                                    } as AlertPayload,
                                    cssClass: 'auto-height',
                                    showBackdrop: true,
                                    backdropDismiss: false,
                                })
                                .then((elem) => {
                                    elem.present();
                                    elem.onDidDismiss().then((dialogRes) => {
                                        if (dialogRes.data) {
                                            this._gameLabelApiService
                                                .deleteGameLabelById({
                                                    gameLabelId: gameLabel.id,
                                                })
                                                .then(() => {
                                                    this.gameLabelCategories[categoryIndex].labels.splice(
                                                        labelIndex,
                                                        1
                                                    );

                                                    this._alertService.createAlert({
                                                        title: $localize`:@@GAME_LABEL_DELETED:Game label deleted successfully`,
                                                    });
                                                });
                                        }
                                    });
                                });
                        }
                    }
                });
            });
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
