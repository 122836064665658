import { Component, OnInit } from '@angular/core';
import { GameLabelCategory } from '@dc-core/dc-backend/dc-classes';
import { GameLabelColor, LABEL_COLORS } from '@dc-core/dc-globals/labels.globals';
import { ModalController, NavParams } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

export interface ManageGameLabelCategoryDialogResponse {
    type: 'manage' | 'delete';
    gameLabelCategory?: GameLabelCategory;
}

@Component({
    selector: 'app-manage-game-label-category-dialog',
    templateUrl: 'manage-game-label-category.dialog.html',
    styleUrls: ['manage-game-label-category.dialog.scss'],
})
export class ManageGameLabelCategoryDialogComponent implements OnInit {
    gameLabelCategory: GameLabelCategory;

    labelColors: GameLabelColor[] = LABEL_COLORS;

    constructor(
        public view: ModalController,
        public _view: ModalController,
        public auth: AuthService,
        private loading: DCLoadingService,
        private _alertService: DartCounterAlertService,
        private _navParams: NavParams
    ) {}

    ngOnInit(): void {
        this.gameLabelCategory = this._navParams.get('gameLabelCategory') ?? {
            id: null,
            user_id: null,
            title: '',
            color: '',
            text_color: '',
        };
    }

    selectColor(labelColor: GameLabelColor): void {
        this.gameLabelCategory.color = labelColor.color;
        this.gameLabelCategory.text_color = labelColor.textColor;
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    public createOrUpdate(): void {
        this.close({
            type: 'manage',
            gameLabelCategory: this.gameLabelCategory,
        } as ManageGameLabelCategoryDialogResponse);
    }

    public delete(): void {
        this.close({
            type: 'delete',
            gameLabelCategory: this.gameLabelCategory,
        } as ManageGameLabelCategoryDialogResponse);
    }
}
