<div class="flex h-full flex-col">
    <app-smart-device-item
        *ngIf="!omni.smartDevice"
        class="block flex-none px-6 py-4"
        [smartDevice]="omni.smartDevice"
        [allowedTypes]="['omni_scoring']"
        [canSelect]="true"
        [status]="getStatusClass()"
        (smartDeviceChanged)="smartDeviceChanged($event)"></app-smart-device-item>

    <div class="h-full overflow-y-auto" *ngIf="omni.smartDevice">
        <app-omni-throws class="block h-full w-full"></app-omni-throws>
    </div>
</div>
