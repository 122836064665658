import { Component, input, InputSignal } from '@angular/core';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { NavController } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';

@Component({
    selector: 'app-online-saved-gameplay',
    templateUrl: 'online-saved-gameplay.component.html',
})
export class OnlineSavedGameplayComponent {
    public disable: InputSignal<boolean> = input(false);
    constructor(
        public online: OnlineGamesService,
        public onlineFunctions: OnlineFunctions,
        private _navCtrl: NavController,
        private ga: DartCounterAnalyticsService
    ) {}

    createGame() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PREONLINE, GA_EVENTACTIONS.OPENONLINESETUP);
        this._navCtrl.navigateForward('online-setup');
    }
}
