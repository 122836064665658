import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-smart-device-light-indicators-dialog',
    standalone: true,
    templateUrl: 'smart-device-light-indicators.dialog.html',
    imports: [CommonModule, IonicModule, TranslateModule],
})
export class SmartDeviceLightIndicatorsDialogComponent {
    private _modalController: ModalController = inject(ModalController);

    // public ngOnInit(): void {
    // }

    public dismiss(returnValue: any = null): void {
        this._modalController.dismiss(returnValue);
    }
}
