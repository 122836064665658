import { Injectable } from '@angular/core';
import { DocumentData } from '@angular/fire/compat/firestore';
import {
    addDoc,
    collection,
    collectionData,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentReference,
    Firestore,
    getDocs,
    onSnapshot,
    query,
    QuerySnapshot,
    Timestamp,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { OnlineGameplay } from '../../dc-backend/dc-interfaces';
import { RXJSSubscriptionManager } from '../../dc-logging/rxjs-subscription.manager';
import { SUBSCRIPTION_KEY } from '../../dc-logging/subscription_enums';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { DCFireStorePause, FIRESTORE_COLLECTION } from '../globals/firestore.tables';

@Injectable()
export class PausesCollectionService extends FireStoreCollectionsService {
    private defaultClass = new DCFireStorePause();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.PAUSES;
    private firestore_collection: CollectionReference<DCFireStorePause>;

    public pauses$: Subject<DCFireStorePause> = new Subject();
    private _activeGameRef: DocumentReference<OnlineGameplay> = null;
    private _currentUserUid: string = null;

    constructor(
        private firestore: Firestore,
        private _rxjsSubscriptionManager: RXJSSubscriptionManager
    ) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<DCFireStorePause>(this.collection_name);
    }

    enablePauseFeature(gameDocRef: DocumentReference<OnlineGameplay>, userUid: string) {
        this.disablePauses();

        this._activeGameRef = gameDocRef;
        this._currentUserUid = userUid;

        // Get active pause (if any)
        this.getInitialPauses().then((pausesSnapshot: QuerySnapshot<DCFireStorePause>) => {
            if (pausesSnapshot.size > 0) {
                const pauseDocSnapshot = _.last(pausesSnapshot.docs);
                let pause = pauseDocSnapshot.data();
                pause.doc_id = pauseDocSnapshot.id;
                pause.doc_ref = pauseDocSnapshot.ref;

                this.activateBreak(pause);
            }
        });

        // Check for a new incoming pause
        const pauseSubscription = this.listenForPauses().subscribe((pauses: DCFireStorePause[]) => {
            if (pauses.length > 0) {
                const pause = _.last(pauses);
                this.activateBreak(pause);
            }
        });

        this._rxjsSubscriptionManager.addSubscription(
            'core pauses-collection',
            SUBSCRIPTION_KEY.CORE_PAUSES,
            pauseSubscription
        );
    }

    disablePauses() {
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.CORE_PAUSES);
    }

    activateBreak(pause: DCFireStorePause) {
        this.pauses$.next(pause);
    }

    getInitialPauses(): Promise<QuerySnapshot<DocumentData>> {
        const pauseCollectionRef = collection(this._activeGameRef, this.collection_name);

        const completedClause = this.getAttributeString(this.defaultClass, (obj: DCFireStorePause) => obj.completed);
        const activePauseQuery = query(pauseCollectionRef, where(completedClause, '==', false));

        return getDocs(activePauseQuery);
    }

    listenForPauses() {
        const pauseCollectionRef = collection(this._activeGameRef, this.collection_name);

        const completedClause = this.getAttributeString(this.defaultClass, (obj: DCFireStorePause) => obj.completed);
        const activePauseQuery = query(pauseCollectionRef, where(completedClause, '==', false));

        return collectionData(activePauseQuery, { idField: 'doc_id' });
    }

    addItem(newPause: DCFireStorePause): Promise<DocumentReference> {
        const chatCollectionRef = collection(this._activeGameRef, this.collection_name);
        return addDoc(chatCollectionRef, newPause);
    }

    getDocByID(id: string): DocumentReference<DocumentData> {
        const chatCollectionRef = collection(this._activeGameRef, this.collection_name);
        return doc(chatCollectionRef, id);
    }

    watchDoc(id: string, observerFn) {
        let docRef = this.getDocByID(id);
        return onSnapshot(docRef, observerFn);
    }

    updateItem(ref: DocumentReference<DocumentData>, updatedValues: DocumentData): Promise<void> {
        return updateDoc(ref, updatedValues);
    }

    async setReady(updatedValues: DCFireStorePause, doc_id: string) {
        let docRef = await this.getDocByID(doc_id);
        this.updateItem(docRef, updatedValues);
    }

    async updatePause(doc: DCFireStorePause) {
        // Update only the players + status
        const fsGameplay = <DCFireStorePause>{
            players: doc.players,
            completed_at: doc.completed ? Timestamp.now() : null,
            completed: doc.completed,
        };

        this.updateItem(doc.doc_ref, fsGameplay);
    }

    async deletePauseSubcollection(): Promise<void> {
        const pauseCollectionRef = collection(this._activeGameRef, this.collection_name);
        const removeBreaks = query(pauseCollectionRef);

        getDocs(removeBreaks).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                deleteDoc(doc.ref);
            });
        });
    }
}
