<div class="flex h-full w-full flex-col overflow-y-hidden overscroll-none rounded-t-lg">
    <div class="relative flex items-center rounded-t-lg bg-blue py-4 px-safe-offset-4" (click)="close()">
        <ion-buttons slot="start">
            <div
                class="ml-1 text-center text-sm font-bold !text-white cursor-pointer"
                [translate]="'HIDE'"
                i18n="@@HIDE">
                Hide
            </div>
        </ion-buttons>
        <div
            class="absolute left-0 right-0 my-auto w-full text-center font-bebas-neue text-2xl !text-white"
            [translate]="'CHAT'"
            i18n="@@CHAT">
            Chat
        </div>
    </div>

    <div class="flex flex-1 flex-col bg-white-pattern overflow-hidden">
        @if (voiceCallService.callingUser) {
            <div class="bg-white flex justify-between items-center p-4 space-x-2">
                <div class="flex items-center overflow-hidden">
                    <app-profile-photo
                        class="block h-full"
                        [url]="voiceCallService.callingUser.profile_photo_url"
                        [ultimate]="voiceCallService.callingUser.is_ultimate"
                        [badge]="voiceCallService.callingUser.badge"
                        [size]="60"
                        [big]="false"></app-profile-photo>
                    <div class="ml-4 text-lg font-bold text-black truncate">
                        {{ voiceCallService.callingUser.first_name }}
                    </div>
                </div>

                @if (canCall) {
                    @if (
                        status === VoiceCallStatus.None ||
                        status === VoiceCallStatus.Hangup ||
                        status === VoiceCallStatus.Declined ||
                        status === VoiceCallStatus.NoResponse
                    ) {
                        <div class="flex flex-col items-center space-y-1.5 cursor-pointer" (click)="call()">
                            <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-green p-2">
                                <app-icon icon="voice_call" size="medium" class="!text-white"></app-icon>
                            </div>
                            <div class="text-xxs text-neutral-400 leading-none" [translate]="'CALL'" i18n="@@CALL">
                                Call
                            </div>
                        </div>
                    } @else if (status === VoiceCallStatus.Calling) {
                        <div class="flex flex-col items-center space-y-1.5 cursor-pointer" (click)="hangup()">
                            <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-red p-2">
                                <app-icon icon="hangup" size="medium" class="!text-white"></app-icon>
                            </div>
                            <div class="text-xxs text-neutral-400 leading-none" [translate]="'HANGUP'" i18n="@@HANGUP">
                                Hangup
                            </div>
                        </div>
                    } @else if (status === VoiceCallStatus.Incoming) {
                        <div class="flex justify-center space-x-4">
                            <div class="flex flex-col items-center space-y-1.5 cursor-pointer" (click)="accept()">
                                <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-green p-2">
                                    <app-icon icon="voice_call" size="medium" class="!text-white"></app-icon>
                                </div>
                                <div
                                    class="text-xxs text-neutral-400 leading-none"
                                    [translate]="'ACCEPT'"
                                    i18n="@@ACCEPT">
                                    Accept
                                </div>
                            </div>

                            <div class="flex flex-col items-center space-y-1.5 cursor-pointer" (click)="decline()">
                                <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-red p-2">
                                    <app-icon icon="close" class="!text-white"></app-icon>
                                </div>
                                <div
                                    class="text-xxs text-neutral-400 leading-none"
                                    [translate]="'DECLINE'"
                                    i18n="@@DECLINE">
                                    Decline
                                </div>
                            </div>
                        </div>
                    } @else if (status === VoiceCallStatus.InCall) {
                        <div class="text-xl text-neutral-400">{{ voiceCallService.showTimer() }}</div>
                        <div class="flex justify-center space-x-4">
                            <div class="flex flex-col items-center space-y-1.5 cursor-pointer" (click)="toggleMute()">
                                <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-blue p-2">
                                    @if (videoRoomService.ownAudioUserMedia?.audioTrack?.enabled) {
                                        <app-icon icon="mic_off" class="!text-white"></app-icon>
                                    } @else {
                                        <app-icon icon="mic"></app-icon>
                                    }
                                </div>
                                <div class="text-xxs text-neutral-400 leading-none">
                                    @if (videoRoomService.ownAudioUserMedia?.audioTrack?.enabled) {
                                        <span [translate]="'MUTE'" i18n="@@MUTE">Mute</span>
                                    } @else {
                                        <span [translate]="'UNMUTE'" i18n="@@UNMUTE">Unmute</span>
                                    }
                                </div>
                            </div>
                            <div class="flex flex-col items-center space-y-1.5 cursor-pointer" (click)="hangup()">
                                <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-red p-2">
                                    <app-icon icon="hangup" size="medium" class="!text-white"></app-icon>
                                </div>
                                <div
                                    class="text-xxs text-neutral-400 leading-none"
                                    [translate]="'HANGUP'"
                                    i18n="@@HANGUP">
                                    Hangup
                                </div>
                            </div>
                        </div>
                    }
                }
            </div>
        }

        @if (chatSegment === 'chat') {
            <div class="h-full overflow-y-auto overflow-x-hidden p-4 space-y-3" appScrollToBottom>
                @for (groupedChatMessage of chatsCollectionService.chatLog; track $index) {
                    <div
                        class="flex"
                        [ngClass]="{
                            'justify-end pl-4 flex-row-reverse': groupedChatMessage.sender.user_id === auth.user.id,
                            'justify-start pr-4': groupedChatMessage.sender.user_id !== auth.user.id
                        }">
                        <app-profile-photo
                            class="h-fit"
                            [size]="22"
                            [ultimate]="groupedChatMessage.sender.is_ultimate"
                            [badge]="groupedChatMessage.sender.badge"
                            [url]="groupedChatMessage.sender.profile_photo_url"></app-profile-photo>
                        <div
                            class="w-full space-y-2"
                            [ngClass]="{
                                'mr-2': groupedChatMessage.sender.user_id === auth.user.id,
                                'ml-2': groupedChatMessage.sender.user_id !== auth.user.id
                            }">
                            @for (chatMessage of groupedChatMessage.messages; track $index) {
                                <div
                                    class="w-full bg-white py-2 px-4 text-black flex justify-between space-x-1"
                                    [ngClass]="{
                                        'rounded-l-2xl rounded-br-2xl':
                                            groupedChatMessage.sender.user_id === auth.user.id,
                                        'rounded-r-2xl rounded-bl-2xl':
                                            groupedChatMessage.sender.user_id !== auth.user.id
                                    }">
                                    <div class="text-black text-sm pb-1 break-words break-all whitespace-normal">
                                        {{ chatMessage.message }}
                                    </div>
                                    <div class="text-neutral-400 text-xs mt-auto">
                                        {{ chatMessage.sent_at.toDate() | date: 'HH:mm' }}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        } @else if (chatSegment === 'fast') {
            <div class="h-full p-4">
                <div class="flex justify-center items-center flex-wrap gap-2">
                    @for (comm of IN_GAME_COMMS; track $index) {
                        <div
                            class="rounded-md bg-blue !text-white py-0.5 px-1.5 font-semibold h-fit cursor-pointer"
                            (click)="sendChat(comm)">
                            {{ comm.text }}
                        </div>
                    }
                </div>
            </div>
        }

        <div class="bg-white pt-2 px-6 flex pb-safe-offset-2 space-x-2 items-center">
            <div class="w-full rounded-3xl py-1 pl-4 pr-3 bg-neutral-50 inline-flex items-center space-x-1">
                <textarea
                    #chatInput
                    class="text-black placeholder:text-neutral-400 w-full !min-h-7 text-[0.9rem]"
                    style="background: none; border: none; resize: none"
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3"
                    [placeholder]="customChatPlaceholder"
                    [(ngModel)]="customChatMessage"
                    (keydown)="onKeydown($event)"
                    appMaintainHeight></textarea>
                <app-icon
                    class="cursor-pointer"
                    icon="maps_ugc"
                    [class.text-neutral-400]="chatSegment === 'chat'"
                    [class.text-orange]="chatSegment === 'fast'"
                    (click)="toggleChatSegment()"></app-icon>
            </div>
            <div
                class="text-orange h-8 w-8 inline-flex justify-center items-center cursor-pointer"
                (click)="sendCustomChat()">
                <app-icon icon="send"></app-icon>
            </div>
        </div>
    </div>
</div>

<ng-template #player>
    <div class="flex flex-col justify-center">
        <app-profile-photo
            class="block h-full"
            [url]="callingUser.profile_photo_url"
            [ultimate]="callingUser.is_ultimate"
            [badge]="callingUser.badge"
            [size]="112"
            [big]="true"></app-profile-photo>
        <div class="mt-2 flex items-center justify-center space-x-1">
            <div class="text-2xl font-semibold text-white">{{ callingUser.first_name }}</div>
        </div>
    </div>
</ng-template>
