import { Component, inject, Input } from '@angular/core';
import { OmniCommunicationService } from '@dc-core/dc-services/omni/omni-communication.service';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-omni-settings-dialog',
    templateUrl: 'omni-settings.dialog.html',
})
export class OmniSettingsDialogComponent {
    @Input() goalAmount: any;

    public omniCommunicationService: OmniCommunicationService = inject(OmniCommunicationService);
    private _omniIngameService: OmniIngameService = inject(OmniIngameService);
    private _modalController: ModalController = inject(ModalController);

    public refreshOmni(): void {
        this.omniCommunicationService.refreshIngameConnection();
        this.dismiss();
    }

    public disableOmni(): void {
        this._omniIngameService.stopOmni();
        this._omniIngameService.disconnect(true);
        this.dismiss();
    }

    public dismiss(returnValue: any = null): void {
        if (returnValue !== null) {
            returnValue = Math.floor(returnValue);
        }
        this._modalController.dismiss(returnValue);
    }
}
