import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DartCounterLocalizeService {
    private translateService: TranslateService = inject(TranslateService);

    constructor() {}

    INVALID_SCORE2(score, mode): string {
        if (this.translateService) {
            return this.translateService.instant('INVALID_SCORE2', { var_score: score, var_mode: mode });
        } else {
            return $localize`:@@INVALID_SCORE2:${score}:var_score: is impossible with ${mode}:var_mode:`;
        }
    }

    INVALID_SCORE(score): string {
        if (this.translateService) {
            return this.translateService.instant('INVALID_SCORE', { var_score: score });
        } else {
            return $localize`:@@INVALID_SCORE:${score}:var_score: is impossible`;
        }
    }

    USER_EDITED_A_SCORE(playerName, newScore): string {
        if (this.translateService) {
            return this.translateService.instant('USER_EDITED_A_SCORE', { user: playerName, score: newScore });
        } else {
            return $localize`:@@USER_EDITED_A_SCORE:${playerName}:user: edited a score to ${newScore}:score:`;
        }
    }

    SCORE_CHANGED_ONLINE(oldScore, newScore): string {
        if (this.translateService) {
            return this.translateService.instant('SCORE_CHANGED_ONLINE', {
                old_score: oldScore,
                new_score: newScore,
            });
        } else {
            return $localize`:@@SCORE_CHANGED_ONLINE:Score ${oldScore || 0}:old_score: has been changed to ${newScore || 0}:new_score:`;
        }
    }

    FREE_ONLINE_GAMES_LEFT_THIS_WEEK_COUNT(count): string {
        if (this.translateService) {
            return this.translateService.instant('FREE_ONLINE_GAMES_LEFT_THIS_WEEK_COUNT', { count: count });
        } else {
            return $localize`:@@FREE_ONLINE_GAMES_LEFT_THIS_WEEK_COUNT:Free online games left this week: ${count}:count:`;
        }
    }

    USERS_TURN_TO_THROW(user): string {
        if (this.translateService) {
            return this.translateService.instant('USERS_TURN_TO_THROW', { user });
        } else {
            return $localize`:@@USERS_TURN_TO_THROW:${user}:user:'s turn to throw!`;
        }
    }
}
