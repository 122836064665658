import { Injectable } from '@angular/core';
import { DCFireStoreUser } from '../../dc-firestore/globals/firestore.tables';
import { Subject, Observable } from 'rxjs';
import { JanusRoom } from '../camera/camera.models';
import { PublishingMediaDevices } from './janus-video-room.service';
import { SmartDevice } from '@dc-core/dc-backend/dc-classes';

export enum JanusEventType {
    ExternalConnectionLost = 'external-connection-lost',
    VideoRoomUnavailable = 'room-unavailable',
    OwnDeviceDisconnected = 'own-device-disconnect',
    OwnAudioDeviceDisconnected = 'own-device-disconnect',
    UserLeftRoom = 'user-left',
    OtherUserStreamDisconnect = 'other-user-stream-disconnect',
    OwnDeviceConnected = 'own-device-connected',
    OwnExternalDeviceConnected = 'own-external-device-connected',
    ServerReconnected = 'server-reconnected',
    ServerDestroyed = 'server-destroyed',
    NewRoom = 'new-room',
    ResetCurrentDevice = 'reset-current-device',
    ResetExternalDevice = 'reset-external-device',
    ResetSmartDevice = 'reset-smart-device',
    PlayerCamNotEnabled = 'player-cam-not-enabled',
}

export interface JanusEvent {
    type: JanusEventType;
    previousType?: JanusEventType;
    user?: DCFireStoreUser;
    janusServerHost?: string;
    janusRoom?: JanusRoom;
    media?: PublishingMediaDevices;
    smartDevice?: SmartDevice;
}

@Injectable({
    providedIn: 'root',
})
export class JanusEventService {
    private eventSubject = new Subject<JanusEvent>();

    get events$(): Observable<JanusEvent> {
        return this.eventSubject.asObservable();
    }

    emitEvent(event: JanusEvent) {
        this.eventSubject.next(event);
    }
}
