/* eslint-disable max-len */
import { Component, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseApiService } from '@dc-api/firebase.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { CountsCollectionService } from '@dc-core/dc-firestore/collection-helpers/counts.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { PublicGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/public_games.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import {
    DCFireStoreInvite,
    DCFireStoreUser,
    GamesCounts,
    INVITATIONSTATUS,
    INVITATIONTYPE,
} from '@dc-core/dc-firestore/globals/firestore.tables';
import { UnfinishedGamesService } from '@dc-core/dc-firestore/unfinished-games.service';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { OnlineGameHelper } from '@dc-core/dc-helpers/online-game.helper';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { RXJSSubscriptionManager } from '@dc-core/dc-logging/rxjs-subscription.manager';
import { SUBSCRIPTION_KEY } from '@dc-core/dc-logging/subscription_enums';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { FreeMatchesService } from '@dc-core/dc-services/free-matches.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import { debounceTime, Subject, Subscription, take, takeUntil } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { TournamentService } from 'src/app/modules/tournaments/services/tournament.service';
import {
    CameraPreviewDialogComponent,
    CameraPreviewDialogPayload,
} from 'src/dialogs/camera-preview/camera-preview.dialog';
import { NewOnlineFeatureDialogComponent } from 'src/dialogs/new-online-feature/new-online-feature';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { environment } from 'src/environments/environment';
import { JoyrideService } from 'src/providers/ngx-joyride/src/lib/services/joyride.service';

import { GlobalMatchInfoComponent } from '../../globalMatchInfo/globalMatchInfo';
import { ProfileDialogComponent } from '../../profileDialog/profileDialog';

type LobbySegment = 'lobby' | 'live';

@Component({
    selector: 'app-global-lobby',
    templateUrl: 'global-lobby.component.html',
})
export class GlobalLobbyComponent {
    private router: Router = inject(Router);

    public segment: LobbySegment = 'lobby';
    public isWeb = environment.isWeb;

    public searchControlPlaceholder: string = $localize`:@@SEARCH:Search`;
    public liveGamesSearchControl: UntypedFormControl = new UntypedFormControl();

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _subscriptionsToDestroy: string[] = [];

    private _backButtonSubscription: Subscription;
    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);
    private tournamentService: TournamentService = inject(TournamentService);

    constructor(
        public auth: AuthService,
        private nav: NavController,
        public route: ActivatedRoute,
        private ga: DartCounterAnalyticsService,
        private _modal: ModalController,
        private platform: Platform,
        private upgrade: UpgradeService,
        private _alertService: DartCounterAlertService,
        private joyrideService: JoyrideService,
        public _gameApiService: GameApiService,
        public onlineFunctions: OnlineFunctions,
        public freeMatchesService: FreeMatchesService,
        public lobbySettingsService: LobbySettingsCollectionService,
        private _dcFireAuth: FireStoreAuthService,
        private _usersCollectionService: UsersCollectionService,
        private menu: MenuController,
        public dcInvites: InviteCollectionService,
        private _firebaseApiService: FirebaseApiService,
        private _rxjsSubscriptionManager: RXJSSubscriptionManager,
        public unfinishedGamesService: UnfinishedGamesService,
        public online: OnlineGamesService,
        public videoRoomService: JanusVideoRoomService,
        private _publicGamesCollection: PublicGamesCollectionService,
        private _countsCollectionService: CountsCollectionService
    ) {
        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.globalTutorial)) ?? true;
        if (showTour) {
            this.showTour();
        } else {
            this.joyrideDone();
        }
    }

    ionViewWillEnter(): void {
        this.ga.trackView('Global mode');
        this.online.start(this.auth.user);

        if (!environment.isWeb) {
            this.menu.enable(false, 'mainmenu');
        }
        this.menu.enable(false, 'ingamemenu');

        this.route.queryParams.pipe(take(1)).subscribe(async (params) => {
            if (params.segment != null) {
                this.changeSegment(params.segment);
            } else {
                this.changeSegment(this.segment);
            }
        });

        this._backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.goBack();
        });

        this.liveGamesSearchControl.valueChanges
            .pipe(debounceTime(600), takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                if (value.length) {
                    this.online.searchLiveGames(value);
                } else {
                    this.loadPublicGames();
                }
            });
    }

    showTour() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.SHOWTOUR);
        setTimeout(() => {
            this.joyrideService.startTour({
                steps: ['global1'],
                showPrevButton: false,
                themeColor: '#444',
            });

            localStorage.setItem(LocalStorageKey.globalTutorial, JSON.stringify(false));
        }, 1500);
    }

    async joyrideDone() {
        if (!this.appFeaturesService.enabledAppFeatures().action_replays) {
            return;
        }

        const newOnlineFeatureKey = 'action-replays';

        const newOnlineFeature = JSON.parse(localStorage.getItem(LocalStorageKey.newOnlineFeatureDialog));

        if (newOnlineFeature != newOnlineFeatureKey) {
            this._modal
                .create({
                    component: NewOnlineFeatureDialogComponent,
                    cssClass: 'auto-height',
                    backdropDismiss: true,
                    showBackdrop: true,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(() => {
                        localStorage.setItem(
                            LocalStorageKey.newOnlineFeatureDialog,
                            JSON.stringify(newOnlineFeatureKey)
                        );
                    });
                });
        }
    }

    goBack(): void {
        this.online.controlSmartDeviceInactivity(true, false, false);
        this.nav.navigateRoot('online-games', { animated: true });
    }

    public checkInviteStatus(invite: DCFireStoreInvite) {
        switch (invite.status) {
            case INVITATIONSTATUS.ACCEPTED:
                this._alertService.createAlert({
                    title: $localize`:@@INVITE_ACCEPTED_JOINING_NOW:Your invite has been accepted!`,
                });
                this.removeOutgoingInvite();
                break;
            case INVITATIONSTATUS.DECLINED:
                this._alertService.createAlert({
                    icon: 'warning',
                    title: $localize`:@@INVITE_DECLINED:Your invite has been declined!`,
                });
                this.removeOutgoingInvite();
                break;
        }
    }

    public removeOutgoingInvite() {
        this.dcInvites.removeOutgoingInvites();
    }

    isBlocked(id: number) {
        for (const blockedUser of this.online.blockedUsers) {
            if (blockedUser.id == id) {
                return true;
            }
        }
        return false;
    }

    trackByFn(index: number, item: any): any {
        return item.doc_id || item.uid || index;
    }

    showPlayerInfo(user: DCFireStoreUser) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.SHOWPROFILEPOPUP);

        this._modal
            .create({
                component: ProfileDialogComponent,
                componentProps: {
                    user: this.onlineFunctions.fsUserToOnlineUser(user),
                    canReport: this.auth.user.id != user.user_id,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'report') {
                        this._modal
                            .create({
                                component: ReportUserDialogComponent,
                                cssClass: 'auto-height',
                                componentProps: {
                                    user: this.onlineFunctions.fsUserToOnlineUser(user),
                                },
                            })
                            .then((elem) => {
                                elem.present();
                            });
                    }
                });
            });
    }

    async openUpgradeDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.GLOBALLOBBY);
        this._modal
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }

    changeSegment(segment: LobbySegment) {
        this.segment = segment;

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { segment: segment },
            queryParamsHandling: 'merge',
        });

        if (segment === 'lobby') {
            this.clearLiveGames();
        } else if (segment === 'live') {
            this.loadPublicGames();
        }
    }

    loadPublicGames(event = null): void {
        if (event == null) {
            this.clearLiveGames();

            this._countsCollectionService.getGamesCounts().then((gamesCount: GamesCounts) => {
                this.online.liveGamesCount = gamesCount.publicGames;
            });

            const publicMatchesSub = this._publicGamesCollection.publicGames$.subscribe((liveGames) => {
                if (this.online.liveGames === null) {
                    this.online.liveGames = [];
                }
                this.online.liveGames = [...this.online.liveGames, ...liveGames];

                // Check the Janus Serverload in Live Lobby
                // this.checkJanusServerLoad();
            });

            this._rxjsSubscriptionManager.addSubscription(
                'globallobby',
                SUBSCRIPTION_KEY.PUBLIC_GAMES,
                publicMatchesSub
            );
            // Auto unwatch onDestroy
            this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.PUBLIC_GAMES);
        }

        //Load the actual games
        this._publicGamesCollection.fetchGames(this.online.liveGamesTake);

        if (event) {
            event.target.complete();
        }
    }

    checkJanusServerLoad() {
        const hostCounts = this.online.liveGames.reduce(
            (acc, game) => {
                game.players.forEach((player) => {
                    if (player.room?.janusServerHost) {
                        const host = player.room.janusServerHost;
                        acc[host] = (acc[host] || 0) + 1;
                    }
                });
                return acc;
            },
            {} as Record<string, number>
        );

        console.log(hostCounts);
    }

    clearLiveGames(): void {
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.PUBLIC_GAMES);
        this.online.liveGames = null;
        this._publicGamesCollection.clearFetchedGames();
    }

    createGame() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.OPENONLINESETUP);
        this.nav.navigateForward('online-setup');
    }

    showUnfinished() {
        this.nav.navigateForward('online/unfinished-games');
    }

    addToLobby(): void {
        if (!this.freeMatchesService.canPlay) {
            this._alertService.createAlert({
                icon: 'warning',
                title: $localize`:@@NO_MORE_FREE_GAMES_THIS_WEEK:Your free online games for this week have been used`,
            });
        } else if (_.find(this.online.lobbyGames, (game) => game.players[0].uid == this._dcFireAuth.getCurrentUID())) {
            this._alertService.createAlert({
                icon: 'warning',
                title: $localize`:@@ALREADY_HAVE_GAME_IN_LOBBY:You already have a game in the lobby`,
            });
        } else {
            this.online.setLobbyGame();
        }
    }

    removeFromLobby(): void {
        this.lobbySettingsService.removeOwnItems();
        this.dcInvites.removeIncomingInvites();
    }

    toggleOwnGame(): void {
        if (this.tournamentService.participatingTournament()) {
            this._alertService.createAlert({
                title: $localize`:@@CANNOT_PLAY_ONLINE_GAME_BECAUSE_TOURNAMENT:You cannot play an online game because you are participating in an online tournament`,
                icon: 'error',
            });
        } else {
            if (this.lobbySettingsService.currentGameInLobby) {
                this.removeFromLobby();
            } else {
                if (this.online.onlineSavedGameplay) {
                    this.addToLobby();
                } else {
                    this.online.addGameAfterCreate = true;
                    this.createGame();
                }
            }
        }
    }

    toggleCameraOnly(): void {
        if (!this.online.onlineSavedGameplay) {
            return;
        }

        switch (this.online.onlineSavedGameplay.type) {
            case 'match':
            case 'cricket_tactics':
                this.online.onlineSavedGameplay.cameraOnly = !this.online.onlineSavedGameplay.cameraOnly;
                break;
        }

        localStorage.setItem(LocalStorageKey.onlineSavedGameplay, JSON.stringify(this.online.onlineSavedGameplay));

        if (this.lobbySettingsService.currentGameInLobby) {
            this.online.updateLobbyGame();
        }
    }

    showGameInfo(gameplay: OnlineGameplay) {
        this.online.controlSmartDeviceInactivity(false, false, false);

        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.SHOWMATCHINFO);
        if (this.tournamentService.participatingTournament()) {
            this._alertService.createAlert({
                title: $localize`:@@CANNOT_PLAY_ONLINE_GAME_BECAUSE_TOURNAMENT:You cannot play an online game because you are participating in an online tournament`,
                icon: 'error',
            });
        } else if (!this.auth.user.is_ultimate && !this.freeMatchesService.canPlay) {
            this._alertService.createAlert({
                title: this.freeMatchesService.freeOnlineMatchesMessage,
                icon: 'error',
            });
        } else if (gameplay.cameraOnly && !this.videoRoomService.ownCamera?.roomID) {
            this._modal
                .create({
                    component: PromptDialogComponent,
                    componentProps: {
                        title: $localize`:@@CAMERA_ONLY:Camera only`,
                        text: $localize`:@@CAMERA_REQUIRED:You need to activate your camera to join this game`,
                        confirmText: $localize`:@@OK:OK`,
                        confirmColor: 'orange',
                        icon: 'warning',
                    } as AlertPayload,
                    cssClass: 'auto-height',
                    showBackdrop: true,
                    backdropDismiss: false,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            // this.linkCamera();
                        }
                    });
                });
        } else {
            this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.JOINMATCH);
            this._modal
                .create({
                    component: GlobalMatchInfoComponent,
                    componentProps: {
                        onlineGameplay: gameplay,
                        user: gameplay.players[0],
                    },
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                    showBackdrop: true,
                    backdropDismiss: true,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data == true) {
                            gameplay.name = this.onlineFunctions.onlineGameplayName(gameplay);

                            const fsUser = this._usersCollectionService.getDCFireStoreUser(this.auth.user);

                            const invite = {
                                sender_uid: fsUser.uid,
                                receiver_uid: gameplay.owners[0],
                                gameplay_doc_id: gameplay.doc_id,
                                invitation_type: INVITATIONTYPE.JOIN,
                                sent_at: Timestamp.now(),
                                status: INVITATIONSTATUS.PENDING,
                                user: fsUser,
                            } as DCFireStoreInvite;

                            this.dcInvites.sendFirestoreInvite(invite);
                            this._firebaseApiService.firebaseJoinGame({ user_ids: [gameplay.players[0].user_id] });
                        }
                    });
                });
        }
    }

    cancelJoin() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.CANCELJOINMATCH);
        // Show Dialog, do you want to invite your opponent to continue this match?
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@CANCEL_INVITE:Cancel invite`,
                    text: $localize`:@@CANCEL_GAMEINVITE_QUESTION:Do you want to cancel this invite?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.dcInvites.removeOutgoingInvites();
                    }
                });
            });
    }

    watch(gameplay: OnlineGameplay): void {
        this.online.stop();
        this.nav.navigateForward(OnlineGameHelper.getGameRoute(gameplay.type, gameplay.doc_id, true));
    }

    showCameraPreview(onlineGameplay: OnlineGameplay, user: DCFireStoreUser): void {
        if (!user.room?.roomID) {
            return;
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.SHOWCAMERAPREVIEW);

        this._modal
            .create({
                component: CameraPreviewDialogComponent,
                componentProps: {
                    onlineGameplay,
                    user,
                    isOwnCamera: user.user_id === this.auth.user.id,
                } as CameraPreviewDialogPayload,
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
            });
    }

    ionViewWillLeave(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Unsubscribe from all subscriptions
        this._subscriptionsToDestroy.forEach((key) => {
            this._rxjsSubscriptionManager.cleanSubscription(key);
        });

        this.freeMatchesService.clear();

        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }
    }
}
