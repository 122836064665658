<div class="ml-3 mr-3 flex items-end">
    <app-select
        title="{{ 'PERIOD' | translate }}"
        [options]="periodOptions"
        [initialValue]="statisticsRange"
        (selectionChange)="changeFilter('statisticsRange', $event)"
        class="mx-1 w-full"></app-select>
    <app-select
        title="{{ 'STARTSCORE' | translate }}"
        [options]="startScoreOptions"
        [initialValue]="startScoreOption"
        (selectionChange)="changeFilter('startScoreOption', $event)"
        class="mx-1 w-full"></app-select>
</div>

<div>
    @if (statisticsRange === 'custom' || startScoreOption === 'custom') {
        <ion-row style="margin: 10px 10px 0">
            @if (statisticsRange === 'custom') {
                <ion-col style="padding: 0">
                    <div class="flex gap-4">
                        <div>
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                [(ngModel)]="year"
                                (ionChange)="changeStatisticsRange('custom')">
                                <ion-select-option *ngFor="let yearOption of years" [value]="yearOption">
                                    {{ yearOption }}
                                </ion-select-option>
                            </ion-select>
                        </div>
                        <div>
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                [(ngModel)]="month"
                                (ionChange)="changeStatisticsRange('custom')">
                                <ion-select-option *ngFor="let monthOption of months" [value]="monthOption">
                                    {{ monthOption }}
                                </ion-select-option>
                            </ion-select>
                        </div>
                    </div>
                </ion-col>
            }
            @if (statisticsRange !== 'custom') {
                <ion-col></ion-col>
            }

            <ion-col></ion-col>

            <ion-col style="padding: 0" *ngIf="startScoreOption === 'custom'">
                <ion-col style="padding: 0" *ngIf="auth.user.is_ultimate" (click)="changeStartScore()">
                    <div style="overflow: auto; font-size: 13px; width: 100%; text-align: center" class="text-black">
                        {{ startScore }}
                    </div>
                </ion-col>
                <ion-col
                    style="padding: 0"
                    *ngIf="!auth.user.is_ultimate"
                    class="text-center"
                    (click)="OpenUltimateDialog()">
                    <span style="overflow: auto; font-size: 13px; width: 100%; text-align: center" class="text-black">
                        {{ startScore }}
                    </span>
                </ion-col>
            </ion-col>
            <ion-col *ngIf="startScoreOption !== 'custom'"></ion-col>
        </ion-row>
    }

    <div class="mx-3 mb-3 mt-5 space-y-2">
        <div class="flex items-center space-x-2">
            <div class="flex flex-col items-center space-y-2 rounded-lg bg-white px-4 py-8 basis-1/2">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    <div class="font-bebas-neue text-5xl font-normal leading-8 text-orange">
                        {{ overallStats.tournament.played }}
                    </div>
                }

                <div class="text-xs font-bold uppercase text-neutral-600">
                    <div [translate]="'TOURNAMENTS_PLAYED'" i18n="@@TOURNAMENTS_PLAYED">Tournaments played</div>
                </div>
            </div>
            <div class="flex flex-col items-center space-y-2 rounded-lg bg-white px-4 py-8 basis-1/2">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    <div class="font-bebas-neue text-5xl font-normal leading-8 text-orange">
                        {{ overallStats.tournament.won }}
                    </div>
                }

                <div class="text-xs font-bold uppercase text-neutral-600">
                    <div [translate]="'TOURNAMENTS_WON'" i18n="@@TOURNAMENTS_WON">Tournaments won</div>
                </div>
            </div>
        </div>

        <div class="flex items-center space-x-2">
            <div class="flex flex-col items-center space-y-1 rounded-lg bg-white p-4 basis-1/2">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    <div class="font-bebas-neue text-4xl font-normal leading-8 text-orange">
                        {{ overallStats.game.wins }}
                    </div>
                }

                <div class="text-xs font-bold uppercase text-neutral-600">
                    <div [translate]="'MATCH_WINS'" i18n="@@MATCH_WINS">Match wins</div>
                </div>
            </div>
            <div class="flex flex-col items-center space-y-1 rounded-lg bg-white p-4 basis-1/2">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    <div class="font-bebas-neue text-4xl font-normal leading-8 text-orange">
                        {{ overallStats.game.losses }}
                    </div>
                }

                <div class="text-xs font-bold uppercase text-neutral-600">
                    <div [translate]="'MATCH_LOSSES'" i18n="@@MATCH_LOSSES">Match losses</div>
                </div>
            </div>
        </div>
    </div>

    <div class="m-3 mt-5 text-black">
        <div class="grid grid-cols-9 gap-1 whitespace-nowrap text-sm">
            <div class="col-span-3"></div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'AVG'" i18n="@@AVG">Avg.</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'BEST'" i18n="@@BEST">Best</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'WORST'" i18n="@@WORST">Worst</div>
        </div>

        <!-- 501  -->
        <div class="game-stat-main-table-container">
            <div class="col-span-3" [translate]="'AVERAGE'" i18n="@@AVERAGE">3-dart avg.</div>
            <div class="col-span-2 py-1 text-center text-sm">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    {{ overallStats.threeDartAvg.average || 0 | number: '1.2-2' }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('threeDartAvg', 'best')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.threeDartAvg.best || 0 | number: '1.2-2' }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('threeDartAvg', 'worst')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.threeDartAvg.worst || 0 | number: '1.2-2' }}
                }
            </div>
        </div>

        <div class="relative">
            <div class="game-stat-table-container">
                <!-- First 9 avg -->
                <div class="col-span-3" [translate]="'FIRST_9_AVG'" i18n="@@FIRST_9_AVG">First 9 avg.</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader mode="dark" size="6"></app-loader>
                    } @else {
                        {{ overallStats.firstNineAvg.average || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('firstNineAvg', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.firstNineAvg.best || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('firstNineAvg', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.firstNineAvg.worst || 0 | number: '1.2-2' }}
                    }
                </div>

                <!-- Checkout rate -->
                <div class="col-span-3" [translate]="'CHECKOUT_RATE'" i18n="@@CHECKOUT_RATE">Checkout rate</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader mode="dark" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkoutRate.average || 0 | number: '1.2-2' }}%
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('checkoutRate', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkoutRate.best || 0 | number: '1.2-2' }}%
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('checkoutRate', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkoutRate.worst || 0 | number: '1.2-2' }}%
                    }
                </div>

                <!-- Checkouts -->
                <div class="col-span-3" [translate]="'CHECKOUTS'" i18n="@@CHECKOUTS">Checkouts</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader mode="dark" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkouts.average || 0 | number: '1.0-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('checkouts', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkouts.best || 0 }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('checkouts', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkouts.worst || 0 }}
                    }
                </div>

                <!-- Upgrade -->
                @if (auth.user.is_ultimate !== true) {
                    <div class="absolute bottom-0 left-0 right-0 top-0 grid w-full grid-cols-9">
                        <div class="top-0 col-span-6 col-start-4 rounded-md backdrop-blur-[5px] bg-[#ffffff2e]">
                            <div
                                (click)="OpenUltimateDialog()"
                                class="flex h-full flex-col items-center justify-center py-0.5">
                                <app-icon icon="closed_lock" size="medium" class="text-black mb-1"></app-icon>
                                <app-primary-button
                                    size="small"
                                    color="blue"
                                    [translate]="'UPGRADE_BTN'"
                                    i18n="@@UPGRADE_BTN"
                                    >Upgrade</app-primary-button
                                >
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
