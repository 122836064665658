import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-custom-score-dialog',
    templateUrl: 'customscore.dialog.html',
})
export class CustomScoreDialogComponent implements OnInit {
    @Input() customScore: any;
    @Input() minScore: any;
    @Input() maxScore: any;

    private modalController: ModalController = inject(ModalController);
    private formBuilder: FormBuilder = inject(FormBuilder);

    public scoreForm: FormGroup;

    public ngOnInit(): void {
        this.scoreForm = this.formBuilder.group({
            customScore: [this.customScore, [Validators.required, Validators.min(101), Validators.max(9999)]],
        });
    }

    public save(): void {
        const customScore = parseInt(this.scoreForm.get('customScore').value, 10);
        this.modalController.dismiss(isNaN(customScore) ? null : customScore);
    }

    public dismiss(returnValue: any = null): void {
        if (returnValue !== null) {
            returnValue = Math.floor(returnValue);
        }
        this.modalController.dismiss(returnValue);
    }
}
