function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
export default function makeInlineElement(type, attributes, contents) {
  var contentsObj = contents !== undefined ? {
    contents: contents
  } : {};
  var dataObj = Object.assign({}, attributes, contentsObj);
  return _defineProperty({}, type, dataObj);
}