<div class="flex items-center justify-between">
    <div
        [ngClass]="{
            'text-sm font-semibold text-black': mode === 'normal',
            'text-base text-black': mode === 'game_menu'
        }"
        [class.opacity-60]="disabled">
        <ng-content></ng-content>
    </div>
    <div class="inline-flex items-center">
        <ion-toggle
            mode="ios"
            class="!pr-2"
            style="zoom: 0.75"
            color="secondary"
            [checked]="model"
            [disabled]="disabled"
            (click)="changeItem($event)"></ion-toggle>
    </div>
</div>
