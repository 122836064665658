import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { take } from 'rxjs';

@Component({
    selector: 'app-tutorial-dialog',
    templateUrl: 'tutorial.html',
    styleUrls: ['tutorial.scss'],
})
export class TutorialDialogComponent {
    slides: any;

    constructor(
        public view: ModalController,
        public route: ActivatedRoute
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.slides = params.slides;
        });
    }

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
