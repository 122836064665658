<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'SEARCH_FRIEND'" i18n="@@SEARCH_FRIEND">Search friend</div>
    </div>
    <div class="slide-modal-content overflow-hidden">
        <div class="dialog-input with-icon">
            <app-icon icon="person_search"></app-icon>
            <input type="text" [formControl]="searchControl" placeholder="{{ 'SEARCH' | translate }}" />
        </div>

        @if (users?.length) {
            <div class="max-h-[24rem] overflow-y-auto mt-4">
                @for (user of users; track user.id) {
                    <div
                        class="flex items-center justify-between w-full p-2 text-black cursor-pointer"
                        (click)="dismiss(user)">
                        <div class="flex items-center space-x-2 overflow-hidden">
                            <app-profile-photo
                                [size]="40"
                                [ultimate]="user.is_ultimate"
                                [url]="user.profile?.profile_photo_url"
                                [badge]="user.profile?.badge"></app-profile-photo>
                            <div class="space-y-1 text-left overflow-hidden">
                                <div class="leading-normal font-bold truncate">{{ user.full_name }}</div>
                                @if (user.profile?.nickname) {
                                    <div class="!text-neutral-400 text-xs font-semibold truncate">
                                        {{ user.profile?.nickname }}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
</div>
