import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appPxHeight]',
})
export class PxHeightDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.el.nativeElement.style.height = this.el.nativeElement.offsetHeight + 'px';
        }, 500);
    }
}
