import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { SmartDevice, SmartDeviceNetwork } from '@dc-core/dc-backend/dc-classes';
import { SmartDeviceType } from '@dc-core/dc-backend/dc-enums';
import { ModalController } from '@ionic/angular';
import { SmartDeviceService } from '@services/smart-device.service';
import _ from 'lodash';

export type SmartDeviceStatusColor = 'red' | 'yellow' | 'green';
@Component({
    selector: 'app-smart-device-item',
    standalone: true,
    imports: [CommonModule],
    templateUrl: 'smart-device-item.component.html',
})
export class SmartDeviceItemComponent implements OnInit {
    @Input() smartDevice: SmartDevice;
    @Input() allowedTypes: SmartDeviceType[] = [];
    @Input() canSelect = false;
    @Input() status: SmartDeviceStatusColor;

    networks: SmartDeviceNetwork[] = [];
    totalDevices: number = null;

    @Output() smartDeviceChanged = new EventEmitter<SmartDevice>();

    constructor(
        private _modal: ModalController,
        private _smartDeviceService: SmartDeviceService,
        private _smartDeviceApiService: SmartDeviceApiService
    ) {}

    ngOnInit(): void {
        if (!this.status) {
            switch (this.smartDevice.type) {
                case 'virt_cam':
                    this._smartDeviceService.checkStatus(this.smartDevice).then((status) => {
                        switch (status.type) {
                            case 'off':
                                this.status = 'red';
                                break;
                            case 'ready':
                                this.status = 'yellow';
                                break;
                            case 'streaming':
                                this.status = 'green';
                                break;
                        }
                    });
                    break;
            }
        }

        if (this.canSelect) {
            let totalDevices = 0;
            this.networks = [];
            _.cloneDeep(this._smartDeviceApiService.smartDeviceNetworks).forEach((network) => {
                const devices = [];
                network.devices.forEach((device) => {
                    if (!(this.allowedTypes.length && !this.allowedTypes.includes(device.type))) {
                        devices.push(device);
                    }
                });
                totalDevices += devices.length;
                network.devices = devices;

                if (devices.length) {
                    this.networks.push(network);
                }
                this.totalDevices = totalDevices;
            });
        }
    }

    selectSmartDevice(smartDevice: SmartDevice = null): void {
        if (!this.canSelect) {
            return;
        }

        if (smartDevice) {
            this.smartDevice = smartDevice;
            this.smartDeviceChanged.emit(smartDevice);
        } else {
            this.smartDevice = null;
        }
    }
}
