<ul>
    <li i18n="@@TURNS_LEFT_ALT">turn left</li>
    <li i18n="@@FEATURE_CALLER">MasterCaller calls your name</li>
    <li i18n="@@FEATURE_PLAYONLINE">Play unlimited online games</li>
    <li i18n="@@FEATURE_ALLTIME">Detailed all-time statistics</li>
    <li i18n="@@FEATURE_MATCHDETAILS">View all of your game details</li>

    <li i18n="@@BOBS_DESCRIPTION">You start with 27 points. Every turn you throw 3 darts at the shown double.</li>
    <li i18n="@@BOBS_SLIDE1_TITLE">Start game</li>
    <li i18n="@@BOBS_SLIDE1_CONTENT">You start with 27 points. Every turn you throw 3 darts at the shown double.</li>
    <li i18n="@@BOBS_SLIDE2_TITLE">Enter your turn</li>
    <li i18n="@@BOBS_SLIDE2_CONTENT">
        Tap your hit and missed doubles in sequence using the ‘MISS’ and ‘Double’ buttons. The value of the doubles you
        have hit is added to your score. If you miss all three darts on the double, the value of the double will
        decrease from your score.
    </li>
    <li i18n="@@BOBS_SLIDE3_TITLE">End of the game</li>
    <li i18n="@@BOBS_SLIDE3_CONTENT">
        If you play ‘Easy mode’, you throw on all doubles including the BULL. If you play ‘Hard mode’, it is GAME OVER
        when your score reaches 0 or lower.
    </li>

    <li i18n="@@SCORES_DESCRIPTION">Try to hit your highest score every turn!</li>
    <li i18n="@@SCORES_SLIDE1_TITLE">Set your own high score</li>
    <li i18n="@@SCORES_SLIDE1_CONTENT">Try to hit your highest score every turn!</li>

    <li i18n="@@TACTICS_DESCRIPTION">
        In Cricket you need to 'close' all the numbers form 15 to 20 as quick as you can. You will 'close' the number by
        hitting them 3 times. Single = 1, Double = 2, Treble = 3.
    </li>
    <li i18n="@@TACTICS_SLIDE1_TITLE">Hit your numbers</li>
    <li i18n="@@TACTICS_SLIDE1_CONTENT">
        In Cricket you need to 'close' all the numbers form 15 to 20 as quick as you can. You will 'close' the number by
        hitting them 3 times. Single = 1, Double = 2, Treble = 3.
    </li>
    <li i18n="@@TACTICS_SLIDE2_TITLE">Enter your turn</li>
    <li i18n="@@TACTICS_SLIDE2_CONTENT">
        After each turn, you tap the number as many times as you've hit it on the board. Haven't you hit anything on the
        board? Just tap the white arrow sign.
    </li>
    <li i18n="@@TACTICS_SLIDE3_TITLE">Scoring points</li>
    <li i18n="@@TACTICS_SLIDE3_CONTENT">
        When you've hit a number 3 times, you've 'closed' it. When you close a number, but your opponent hasn't, you're
        able to score points on that number by hitting it.
    </li>
    <li i18n="@@TACTICS_SLIDE4_TITLE">Win the game!</li>
    <li i18n="@@TACTICS_SLIDE4_CONTENT">
        The game is finished when the player with the highest score closes all their numbers.
    </li>

    <li i18n="@@SINGLES_DESCRIPTION">
        In Singles Training you need to get the most points. Throw your darts at the shown number. Single = 1 pt, Double
        = 2 pts, Triple = 3 pts.
    </li>
    <li i18n="@@SINGLES_SLIDE1_TITLE">Improve your singles</li>
    <li i18n="@@SINGLES_SLIDE1_CONTENT">
        In Singles Training you need to get the most points. Throw your darts at the shown number. Single = 1 pt, Double
        = 2 pts, Triple = 3 pts.
    </li>
    <li i18n="@@SINGLES_SLIDE2_TITLE">The bullseye</li>
    <li i18n="@@SINGLES_SLIDE2_CONTENT">Single Bull = 1 pt, Bullseye = 2 pts.</li>

    <li i18n="@@DOUBLES_DESCRIPTION">
        Throw your darts at the shown double. Fill in the number of darts you needed to hit the double. Did you miss the
        double, then select 'Miss'.
    </li>
    <li i18n="@@DOUBLES_SLIDE1_TITLE">Hit your doubles</li>
    <li i18n="@@DOUBLES_SLIDE1_CONTENT">Throw your darts at the shown double</li>
    <li i18n="@@DOUBLES_SLIDE2_TITLE">Enter your turn</li>
    <li i18n="@@DOUBLES_SLIDE2_CONTENT">
        Fill in the number of darts you needed to hit the double. Did you miss the double, then select 'Miss'.
    </li>
    <li i18n="@@DOUBLES_SLIDE3_TITLE">Keep on throwing</li>
    <li i18n="@@DOUBLES_SLIDE3_CONTENT">Throw again at the double until you hit it.</li>
    <li i18n="@@DOUBLES_SLIDE4_TITLE">Double Bull</li>
    <li i18n="@@DOUBLES_SLIDE4_CONTENT">When you see 'BULL' you have to hit the Bullseye.</li>

    <li i18n="@@UNLINK">Unlink</li>

    <li i18n="@@TWO_LEGS_DIFFERENCE_EXPLANATION">
        If your goal is to reach 10 legs, but your opponent has 9, you will have to make it 11-9 to win. This will
        continue until there is a winner, unless you check the Sudden Death option.
    </li>
</ul>
