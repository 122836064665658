import { NgModule } from '@angular/core';
import { DartCounterGameService } from './game.service';
import { DCOnlineCore } from './online-core.functions';
import { OnlineFunctions } from './online-game/online.functions';

@NgModule({
    declarations: [],
    providers: [DartCounterGameService, DCOnlineCore, OnlineFunctions],
})
export class GameLogicModule {}
