<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@NEW_PASSWORD">New password</ng-container>
    <ng-container i18n="@@REPEAT_PASS">Repeat password</ng-container>
}
<!-- End of translation containers-->

<div class="slide-modal-outer">
    <div class="slide-modal-header mt-3">
        <div class="slide-modal-title" [translate]="'MUST_CHANGE_PASSWORD'" i18n="@@MUST_CHANGE_PASSWORD">
            Choose a password
        </div>
    </div>
    <div class="slide-modal-content mt-2">
        <app-profile-photo
            class="h-fit"
            [size]="80"
            [ultimate]="auth.user.is_ultimate"
            [badge]="auth.user.profile?.badge"
            [big]="true"
            [url]="auth.user.profile?.profile_photo_url">
        </app-profile-photo>
        <div class="text-center mt-2" [translate]="'MUST_CHANGE_PASSWORD_INFO'" i18n="@@MUST_CHANGE_PASSWORD_INFO">
            Please change your password to continue
        </div>
        <form (ngSubmit)="SubmitNewPassword()" class="accountform space-y-3 py-4" #newPassForm="ngForm">
            <div class="mt-2">
                <div class="dialog-input-label">
                    {{ 'NEW_PASSWORD' | translate }}
                </div>
                <input
                    type="password"
                    class="dialog-input"
                    placeholder="{{ 'NEW_PASSWORD' | translate }}"
                    [(ngModel)]="Password" />
            </div>
            <div class="mt-2">
                <div class="dialog-input-label">
                    {{ 'REPEAT_PASS' | translate }}
                </div>
                <input
                    type="password"
                    class="dialog-input"
                    placeholder="{{ 'REPEAT_PASS' | translate }}"
                    [(ngModel)]="Password2" />
            </div>
        </form>
        <app-primary-button
            size="normal"
            [addedClasses]="'w-full'"
            [color]="'orange'"
            (click)="SubmitNewPassword()"
            [translate]="'SAVE'"
            i18n="@@SAVE">
            Save
        </app-primary-button>
    </div>
</div>
