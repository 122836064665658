<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <div class="title-container">
            <div class="title" i18n="@@COMPLETE_ACCOUNT" [translate]="'COMPLETE_ACCOUNT'">Complete account</div>
        </div>
        <div class="mt-8 space-y-4">
            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'FIRST_NAME'" i18n="@@FIRST_NAME">First name</div>
                <input type="text" class="dialog-input" [(ngModel)]="firstName" />
            </div>

            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'LAST_NAME'" i18n="@@LAST_NAME">Last name</div>
                <input type="text" class="dialog-input" [(ngModel)]="lastName" />
            </div>

            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'USERNAME'" i18n="@@USERNAME">Username</div>
                <input type="text" class="dialog-input" [(ngModel)]="username" />
            </div>
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="submit()"
                [translate]="'SAVE'"
                i18n="@@SAVE">
                Save
            </app-primary-button>
        </div>
    </div>
</modal-content>
