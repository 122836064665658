import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageKey } from '../../dc-localstorage';
import { DartCounterAlertService } from '../alert.service';
import { DCStreamTrack, FacingModes } from '../camera/camera.models';
import { DartCounterPreferenceService } from '../preference/preference.service';
import { UserMedia } from './DartCounterUserMedia';
import { JanusVideoRoomService, PublishingMediaDevices } from './janus-video-room.service';
import { DropdownSelectItem } from '@dc-core/dc-statistics/statistics.globals';

type MediaTrackCapabilitiesWithZoom = MediaTrackCapabilities & {
    zoom?: { min: any; max: any; step: any };
    focusDistance?: { min: any; max: any; step: any };
    focusMode?: string[];
};

export enum UserMediaErrorType {
    DeviceInUse = 'Device already in use',
    PermissionDenied = 'Permission denied',
    NotFound = 'Device not found',
    NotReadable = 'Device not readable, it might already be in use',
    Overconstrained = 'Overconstrained',
    Abort = 'Abort',
    Other = 'Unknown reason',
}

export interface DCDevicePreferences {
    audioDevice?: string;
    videoDevice?: string;
    faceVideoDevice?: string;
    selectRearCam?: boolean;
}

export type DCMediaDeviceInfo = MediaDeviceInfo & { humanReadable?: string };

@Injectable()
export class UserMediaService {
    public isZoomSupported: boolean = null;
    public zoomLevel: number = 1.0; // Default zoom level

    public camCapabilities: MediaTrackCapabilitiesWithZoom = null;
    public camSettings: MediaTrackSettings = null;

    public audioDevices: DCMediaDeviceInfo[] = [];

    public rearCameraDevices: DCMediaDeviceInfo[] = [];
    public rearCameraDevicesOptions: DropdownSelectItem[] = [];
    public faceCameraDevices: DCMediaDeviceInfo[] = [];
    public faceCameraDevicesOptions: DropdownSelectItem[] = [];
    public selectRearCam = true;

    public selectedAudioDevice: string;
    public selectedRearVideoDevice: string;
    public selectedFaceVideoDevice: string;
    public hasConnectionError: boolean = false;

    isAudioEnabled: boolean = false;
    prevAllowCaller: boolean = null;
    private volumeMeterAnimationId: number | null = null;
    private lastUpdateTime?: number;
    volumeLevel: number = 0;

    private audioContext: AudioContext;
    private analyser: AnalyserNode;
    private dataArray: Uint8Array;

    constructor(
        private _preferenceService: DartCounterPreferenceService,
        private _deviceDetectorService: DeviceDetectorService,
        private _alertService: DartCounterAlertService,
        private _videoRoomService: JanusVideoRoomService
    ) {}

    getUserMedia(userMediaConstraints: PublishingMediaDevices = null): Promise<MediaStream> {
        if (!userMediaConstraints) {
            userMediaConstraints = this.getMediaConstraints(true, false);
        }
        return navigator.mediaDevices.getUserMedia(userMediaConstraints);
    }

    activateMicrophone(userMedia: UserMedia): Promise<PublishingMediaDevices> {
        return new Promise((resolve, reject) => {
            const media = this.getMediaConstraints(false, true);
            this.getUserMedia(media)
                .then((firstStream) => {
                    firstStream.getTracks().forEach((track) => track.stop());

                    this.isAudioEnabled = true;
                    userMedia.audioMuted = false;

                    setTimeout(() => {
                        this.getUserMedia(media)
                            .then((secondStream) => {
                                this.setStreamAsOwnUserMedia(userMedia, secondStream);
                                resolve(media);
                            })
                            .catch((error: DOMException) => {
                                this.isAudioEnabled = false;
                                reject(error.message);
                            });
                    }, 500);
                })
                .catch((error: DOMException) => {
                    this.isAudioEnabled = false;
                    reject(error.message);
                });
        });
    }

    disableCaller(): void {
        if (this.prevAllowCaller === null) {
            this.prevAllowCaller = this._preferenceService.allowCaller;
            this._preferenceService.allowCaller = false;
        }
    }

    enableCaller(): void {
        if (this.prevAllowCaller !== null) {
            this._preferenceService.allowCaller = this.prevAllowCaller;
            this.prevAllowCaller = null;
        }
    }

    mapToUserMediaErrorType(error: DOMException): UserMediaErrorType {
        switch (error.name) {
            case 'NotFoundError':
                return UserMediaErrorType.NotFound;
            case 'NotAllowedError':
                return UserMediaErrorType.PermissionDenied;
            case 'OverconstrainedError':
                return UserMediaErrorType.Overconstrained;
            case 'AbortError':
                return UserMediaErrorType.Abort;
            case 'NotReadableError':
                return UserMediaErrorType.NotReadable;
            default:
                return UserMediaErrorType.Other;
        }
    }

    saveSelectedDevicePreferences(): void {
        const selectedPreferences: DCDevicePreferences = {
            audioDevice: this.selectedAudioDevice,
            videoDevice: this.selectedRearVideoDevice,
            faceVideoDevice: this.selectedFaceVideoDevice,
            selectRearCam: this.selectRearCam,
        };

        localStorage.setItem(LocalStorageKey.selectedUserMediaDevices, JSON.stringify(selectedPreferences));
    }

    getSavedPreferences(): DCDevicePreferences {
        const storedDevicePreferences = localStorage.getItem(LocalStorageKey.selectedUserMediaDevices);
        return storedDevicePreferences
            ? JSON.parse(storedDevicePreferences)
            : <DCDevicePreferences>{ audioDevice: '', videoDevice: '', faceVideoDevice: null };
    }

    getDevices(userMedia: UserMedia) {
        navigator.mediaDevices
            .enumerateDevices()
            .then(async (devices: MediaDeviceInfo[]) => {
                this.audioDevices = devices.filter((device) => device.kind === 'audioinput');
                const videoDevices = devices.filter((device) => device.kind === 'videoinput');
                let bestVideoDeviceID: string = null;
                let bestVideoDeviceCapabilities: MediaTrackCapabilitiesWithZoom = null;

                let streams: MediaStream[] = [];
                if (this._deviceDetectorService.isDesktop()) {
                    this.rearCameraDevices = videoDevices;
                    this.rearCameraDevices.forEach((device) => {
                        this.rearCameraDevicesOptions.push({
                            label: device.humanReadable || device.label,
                            value: device.deviceId,
                        });
                    });
                } else {
                    this.rearCameraDevices = [];
                    this.faceCameraDevices = [];

                    for (const videoDevice of videoDevices) {
                        try {
                            // Get the capabilities of the device
                            const stream = await navigator.mediaDevices.getUserMedia({
                                video: { deviceId: { exact: videoDevice.deviceId } },
                            });
                            streams.push(stream);
                            const track = stream.getVideoTracks()[0];

                            let capabilities: MediaTrackCapabilitiesWithZoom = null;

                            if (typeof track.getCapabilities === 'function') {
                                // Check capabilities
                                capabilities = track.getCapabilities() as MediaTrackCapabilitiesWithZoom;
                            }

                            this.addVideoDevice(videoDevice, capabilities);

                            stream.getTracks().forEach((t) => t.stop()); // Stop using the device

                            // Apply heuristics to find the normal camera
                            if (
                                capabilities &&
                                capabilities.focusMode?.includes('continuous') &&
                                capabilities.focusDistance
                            ) {
                                if (
                                    !bestVideoDeviceID ||
                                    bestVideoDeviceCapabilities.focusDistance.max < capabilities.focusDistance.max
                                ) {
                                    bestVideoDeviceID = videoDevice.deviceId;
                                    bestVideoDeviceCapabilities = capabilities;
                                }
                            }
                        } catch (_) {}
                    }
                }

                this.addDefaultDeviceOptions();
                // Set default devices based on previously selected or default
                this.selectDefaultDevices();

                if (!this.selectedRearVideoDevice) {
                    if (bestVideoDeviceID) {
                        this.selectedRearVideoDevice = bestVideoDeviceID;
                    } else {
                        this.selectedRearVideoDevice = this.rearCameraDevices[0]
                            ? this.rearCameraDevices[0].deviceId
                            : this.faceCameraDevices[0]
                              ? this.faceCameraDevices[0]?.deviceId
                              : '';
                    }
                }

                setTimeout(() => {
                    this.updatePreview(userMedia);
                }, 500);
            })
            .catch(console.error);
    }

    public reverseCamera(userMedia: UserMedia) {
        // Reverse the camera selection (front/rear switch)
        this.selectRearCam = !this.selectRearCam;

        // Check if there was a previously selected preferred camera
        const { videoDevice, faceVideoDevice } = this.getSavedPreferences();
        this.selectPresetCamera(videoDevice, faceVideoDevice);

        // Update the preview
        this.updatePreview(userMedia);
    }

    private addVideoDevice(videoDevice: DCMediaDeviceInfo, capabilities: MediaTrackCapabilitiesWithZoom) {
        if (capabilities?.facingMode.includes('user')) {
            const camNr = this.faceCameraDevices.length + 1;
            videoDevice.humanReadable = 'Face Camera #' + camNr;
            this.faceCameraDevices.push(videoDevice);
            this.faceCameraDevicesOptions.push({
                label: videoDevice.humanReadable || videoDevice.label,
                value: videoDevice.deviceId,
            });
        } else {
            const camNr = this.rearCameraDevices.length + 1;
            videoDevice.humanReadable = 'Rear Camera #' + camNr;
            this.rearCameraDevices.push(videoDevice);
            this.rearCameraDevicesOptions.push({
                label: videoDevice.humanReadable || videoDevice.label,
                value: videoDevice.deviceId,
            });
        }
    }

    private addDefaultDeviceOptions() {
        // Add 'default' devices
        this.audioDevices.unshift(<any>{
            deviceId: '',
            kind: 'audioinput', // for audio devices
            label: 'Default',
        });
    }

    private selectDefaultDevices(): void {
        const { audioDevice, videoDevice, faceVideoDevice, selectRearCam } = this.getSavedPreferences();

        // Check if the previously selected audio device is still available
        this.selectedAudioDevice = this.audioDevices.some((d) => d.deviceId === audioDevice) ? audioDevice : '';

        if (selectRearCam != null) {
            this.selectRearCam = selectRearCam;
        }

        this.selectPresetCamera(videoDevice, faceVideoDevice);
    }

    selectPresetCamera(videoDevice, faceVideoDevice) {
        if (this.selectRearCam) {
            //Check for a saved (rear)'videoDevice' in the past
            this.selectedRearVideoDevice = this.rearCameraDevices?.some((d) => d.deviceId === videoDevice)
                ? videoDevice
                : '';
        } else if (!this.selectRearCam) {
            //Check for a saved 'faceVideoDevice' in the past
            let deviceID = this.faceCameraDevices?.some((d) => d.deviceId === faceVideoDevice) ? faceVideoDevice : null;
            if (!deviceID) {
                deviceID = this.faceCameraDevices[0]?.deviceId;
            }
            this.selectedFaceVideoDevice = deviceID;
        }
    }

    updateCamSettings(userMedia: UserMedia): void {
        if (userMedia.videoStreams?.board?.stream) {
            const videoTrack = userMedia.videoStreams.board.stream.getVideoTracks()[0];

            let capabilities: MediaTrackCapabilitiesWithZoom = null;
            if (typeof videoTrack.getCapabilities === 'function') {
                // Check capabilities
                capabilities = videoTrack.getCapabilities() as MediaTrackCapabilitiesWithZoom;
            }

            // Check if 'zoom' is supported
            if (capabilities && 'zoom' in capabilities) {
                this.isZoomSupported = true;

                if (!capabilities.zoom.step) {
                    capabilities.zoom.step = 0.1;
                }

                this.camCapabilities = capabilities;

                if (this.camCapabilities.zoom.min > this.zoomLevel || this.camCapabilities.zoom.max < this.zoomLevel) {
                    this.zoomLevel = this.camCapabilities.zoom.min;
                }

                videoTrack
                    .applyConstraints({
                        advanced: [{ zoom: this.zoomLevel }], // Type: any, because the Definitions don't know 'zoom' exists
                    } as any)
                    .then(() => {
                        this.camSettings = videoTrack.getSettings();
                    })
                    .catch((error) => {
                        console.error('Error applying zoom constraint:', error);
                    });
            } else {
                this.isZoomSupported = false;

                // Force Zoom capabilities
                capabilities = {
                    zoom: { step: 0.1, min: 1, max: 3 },
                };
                this.camCapabilities = capabilities;

                if (this.camCapabilities.zoom.min > this.zoomLevel || this.camCapabilities.zoom.max < this.zoomLevel) {
                    this.zoomLevel = this.camCapabilities.zoom.min;
                }
            }

            this.setZoomScale(userMedia);
        }
    }

    applyVideoConstraints(userMedia: UserMedia): void {
        if (userMedia.videoStreams?.board?.stream) {
            const videoTrack = userMedia.videoStreams.board.stream.getVideoTracks()[0];

            // Check capabilities
            let capabilities: MediaTrackCapabilitiesWithZoom = null;

            if (typeof videoTrack.getCapabilities === 'function') {
                // Check capabilities
                capabilities = videoTrack.getCapabilities() as MediaTrackCapabilitiesWithZoom;
            }

            // Check if 'zoom' is supported
            if (capabilities && 'zoom' in capabilities) {
                videoTrack
                    .applyConstraints({
                        advanced: [{ zoom: this.zoomLevel }], // Type: any, because the Definitions don't know 'zoom' exists
                    } as any)
                    .then(() => {
                        this.camSettings = videoTrack.getSettings();
                    })
                    .catch((error) => {
                        console.error('Error applying zoom constraint:', error);
                    });
            }
        }
    }

    setZoomScale(userMedia: UserMedia): void {
        if (this.isZoomSupported) {
            this._videoRoomService.ownCamera.scale = `scale(1)`;
        } else {
            this._videoRoomService.ownCamera.scale = `scale(${this.zoomLevel})`;
        }

        if (userMedia?.videoStreams?.board) {
            userMedia.videoStreams.board.scale = this._videoRoomService.ownCamera.scale;
        }
    }

    async stopMicrophone(): Promise<void> {
        await this._videoRoomService.stopMicrophone();
    }

    startVolumeMeter(): void {
        // Start or restart the volume meter visualization
        if (!this.volumeMeterAnimationId) {
            // Check if the loop is already running
            this.updateVolumeMeter();
        }
    }

    processAudioStream(stream: MediaStream) {
        this.setAudioContext();

        this.analyser = this.audioContext.createAnalyser();
        const audioSource = this.audioContext.createMediaStreamSource(stream);
        audioSource.connect(this.analyser);
        this.analyser.fftSize = 256;
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

        // if (this.audioContext.state === 'suspended') {
        //     this.audioContext.resume();
        // }

        this.updateVolumeMeter();
    }

    updateVolumeMeter() {
        if (!this.analyser) {
            return;
        }

        if (!this.isAudioEnabled) {
            // If audio is disabled, stop updating and reset the volume level
            this.volumeLevel = 0;
            return; // Exit the function here
        }

        // Throttle the update rate
        const updateRate = 100; // milliseconds
        if (this.lastUpdateTime && Date.now() - this.lastUpdateTime < updateRate) {
            this.volumeMeterAnimationId = requestAnimationFrame(() => this.updateVolumeMeter());
            return;
        }
        this.lastUpdateTime = Date.now();

        this.volumeMeterAnimationId = requestAnimationFrame(() => this.updateVolumeMeter());

        this.analyser.getByteFrequencyData(this.dataArray);

        let sum = 0;
        for (let i = 0; i < this.dataArray.length; i++) {
            sum += this.dataArray[i];
        }
        let average = sum / this.dataArray.length;

        // Apply a logarithmic scaling
        let volumePercent = (Math.log10(average + 1) / Math.log10(256)) * 100;

        // Update the volumeLevel property
        this.volumeLevel = volumePercent;
    }

    stopVolumeMeter(): void {
        if (!this.analyser) {
            return;
        }

        // Stop the volume meter visualization
        if (this.volumeMeterAnimationId) {
            cancelAnimationFrame(this.volumeMeterAnimationId);
            this.volumeMeterAnimationId = null;
        }
        this.volumeLevel = 100; // Reset the volume level
    }

    setAudioContext(): void {
        if (this.isAudioEnabled) {
            this.audioContext = new AudioContext();
        }
    }

    // Method to update the preview whenever the user selects a new device
    updatePreview(userMedia: UserMedia, userMediaConstraints: PublishingMediaDevices = null): Promise<void> {
        return new Promise((resolve, reject) => {
            if (userMediaConstraints) {
                userMedia.stopTracks(userMediaConstraints.video !== false, userMediaConstraints.audio !== false);

                if (userMediaConstraints.video === false && userMediaConstraints.audio === false) {
                    return;
                }
            } else {
                userMedia.cleanupUserMedia(true, true);
            }

            this.getUserMedia(userMediaConstraints)
                .then((stream) => {
                    this.hasConnectionError = false;
                    this.setStreamAsOwnUserMedia(userMedia, stream);

                    this.saveSelectedDevicePreferences();
                    setTimeout(() => {
                        this.updateCamSettings(userMedia);
                    }, 500);

                    resolve();
                })
                .catch((error: DOMException) => {
                    this.hasConnectionError = true;

                    const userMediaError = this.mapToUserMediaErrorType(error);
                    this._alertService.createAlert({
                        title: `${error.message}`,
                        text: `${userMediaError} `,
                        icon: 'error',
                        timer: null,
                        showCloseButton: true,
                        allowOutsideClick: true,
                        confirmButtonText: $localize`:@@CLOSE:Close`,
                    });
                    reject();
                });
        });
    }

    setStreamAsOwnUserMedia(userMedia: UserMedia, stream: MediaStream, withZoom = false) {
        // Separate the audio and video tracks from the new stream
        if (stream.getVideoTracks().length) {
            const videoTrack = stream.getVideoTracks()[0] as DCStreamTrack;
            if (withZoom && this.isZoomSupported === true) {
                videoTrack
                    .applyConstraints({
                        advanced: [{ zoom: this.zoomLevel }],
                    } as any)
                    .catch((error) => {
                        console.error('Error applying zoom constraint:', error);
                    });
            }
            userMedia.addVideoTrack(videoTrack, videoTrack.id.replace(/[{}]/g, ''), {
                facingMode: FacingModes.BOARD,
                scale: this._videoRoomService.ownCamera.scale,
                kind: 'app',
            });
        }

        // Update the audio track visualization
        if (this.isAudioEnabled && stream.getAudioTracks().length) {
            const audioTrack = stream.getAudioTracks()[0] as DCStreamTrack;

            // Create new MediaStreams for individual tracks
            userMedia.addAudioTrack(audioTrack, {
                facingMode: FacingModes.BOARD,
                scale: this._videoRoomService.ownCamera.scale,
                kind: 'app',
            });
            // this.processAudioStream(userMedia.audioStream);
        }
    }

    getSelectedVideoDeviceID(): string {
        if (this.selectRearCam) {
            return this.selectedRearVideoDevice;
        }
        return this.selectedFaceVideoDevice;
    }

    getMediaConstraints(withVideo: boolean, withAudio: boolean): PublishingMediaDevices {
        let userMediaConstraints: PublishingMediaDevices;

        userMediaConstraints = {
            audio: withAudio
                ? {
                      deviceId: this.selectedAudioDevice ? { exact: this.selectedAudioDevice } : undefined,
                  }
                : false,
            video: withVideo
                ? {
                      deviceId: this.getSelectedVideoDeviceID()
                          ? { exact: this.getSelectedVideoDeviceID() }
                          : undefined,
                      aspectRatio: { exact: 1 },
                      height: { max: 720 },
                      frameRate: { max: 30 },
                      zoom: true,
                  }
                : false,
        };

        if (this.getSelectedVideoDeviceID() === '' || !userMediaConstraints.video['deviceId']) {
            delete userMediaConstraints.video['deviceId'];
        }

        return userMediaConstraints;
    }
}
