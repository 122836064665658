import { Injectable } from '@angular/core';
import {
    NativeAudioFunctions,
    NativeAudioPluginListenerHandle,
} from '@dc-core/dc-services/audio/audio-service.interface';
import { CapacitorNativeAudio } from 'capacitor-native-audio/dist/esm';

@Injectable()
export class NativeAudioService implements NativeAudioFunctions {
    async changeAudioCategory(options: { category: 'default' | 'voiceCall' }): Promise<void> {
        await CapacitorNativeAudio.changeAudioCategory(options);
    }

    async playFromUrl(options: { url: string }): Promise<void> {
        await CapacitorNativeAudio.playFromUrl(options);
    }

    async playFromFile(options: { filePath: string }): Promise<void> {
        await CapacitorNativeAudio.playFromFile(options);
    }

    async stopPlayingAudio(): Promise<void> {
        await CapacitorNativeAudio.stopPlayingAudio();
    }

    async addListener(
        eventName: string,
        listenerFunc: (...args: any[]) => any
    ): Promise<NativeAudioPluginListenerHandle> {
        return await CapacitorNativeAudio.addListener(eventName, listenerFunc);
    }
}
