import { CartesianCoordinate } from '@dc-core/dc-services/omni/omni-ingame.service';
import { VoiceCallStatus } from '@dc-core/dc-services/voice/voice-call.service';
import { DocumentReference, Timestamp } from 'firebase/firestore';
import { MatchThrow, Tournament, TournamentGame } from '../../dc-backend/dc-classes';
import { Badge, GameMode } from '../../dc-backend/dc-enums';
import { OnlineGameplay, PLAYERSTATUS } from '../../dc-backend/dc-interfaces';
import { MatchEditScoreResponse } from '../../dc-gamelogic/game-interfaces';
import {
    CricketTacticsLogItem,
    DartCounterCricketTacticsTurn,
} from '../../dc-gamelogic/in-game/cricket-tactics/cricket-tactics.in-game.classes';
import { CHAT_MSG, SetSingleDartPayload } from '../../dc-gamelogic/in-game/ingame.globals';
import { ONLINE_GAME_STATE_ACTIONS } from '../../dc-gamelogic/online-game/online.ingame.globals';
import { JanusRoom } from '../../dc-services/camera/camera.models';

// Set the global Firestore Gameplay version
export const FireStoreOnlineVersion = 3;

export enum FIRESTORE_COLLECTION {
    USERS = 'dcUsers',
    INVITES = 'invites',
    TOURNAMENT_INVITES = 'tournamentInvites',
    RATINGS = 'ratings',
    BACKEND_EVENTS = 'backendEvents',
    OUTGOING_INVITES = 'outgoingInvites',

    DOWNTIMES = 'downtimes',

    // Online Settings objects
    LOBBY_SETTINGS = 'lobbySettings',

    // Online Gameplay collections
    ACTIONS = 'actions',
    COUNTS = 'counts',
    ACTIVE_GAMES = 'activeGames',
    SPECTATORS = 'spectators',
    CHATS = 'chats',
    GAME_EVENTS = 'gameEvents',
    PAUSES = 'pauses',
    PUBLIC_GAMES = 'publicGames',
}

export enum CountMetric {
    STATUSCOUNTS = 'statusCounts',
    GAMESCOUNTS = 'gamesCounts',
}

export interface UserCounts {
    online: number;
    playing: number;
}

export interface FSUserCount {
    online3: number;
    playing3: number;
}

export interface GamesCounts {
    publicGames: number;
}

export interface FSGamesCounts {
    publicGames3: number;
}

export class Item {
    owner?: DCFireStoreUser;
    owners?: string[];

    //Filterable fields need a default value
    name: string = '';
    is_test?: boolean = false;
}

export enum ONLINESTATUS {
    ONLINE = 'online',
    IN_APP = 'in_app',
    OFFLINE = 'offline',
    LOCAL_GAME = 'local_game',
    PLAYING = 'playing',
    BUSY = 'busy',
}

export enum INVITATIONSTATUS {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
}
export class DCFireStoreUser {
    uid: string = '';

    // DartCounter internal attributes
    user_id: number = 0;
    first_name: string;
    last_name: string;
    full_name: string;
    nickname: string;
    profile_photo_url: string;
    badge: Badge;
    is_ultimate: boolean;
    two_month_average?: number;
    last_results: any[];
    sound?: string;
    has_checkout_rate?: boolean;

    countryFlag?: string;
    room?: JanusRoom;
    average?: number;
    rating?: number;
    times_rated?: number;
    last_active: Timestamp = Timestamp.now(); //unix
    activeGameRef?: DocumentReference<OnlineGameplay>;
    is_ready: boolean = false;
    status: ONLINESTATUS = ONLINESTATUS.ONLINE;
    version: number;
    has_omni: boolean;

    team?: string;
    darts?: string;
    league?: string;
    division?: string;
}

export class DCGameAction {
    sender_user_id?: number;
    sender_team_id?: number;
    sent_at: Timestamp = Timestamp.now(); //unix
    actionType: ACTIONTYPE = ACTIONTYPE.ALERT;
    toSelf?: boolean;
}

export enum ACTIONTYPE {
    ALERT = 'alert',
    OMNI_STATE = 'omni_activated',
    SCORE = 'score',
    SINGLE_DART = 'SINGLE_DART',
    THROWOUT = 'throwout',
    EDITED_SCORE = 'edited_score',
    CAMERA = 'camera',
    VOICE_CALL = 'voice_call',
    GAME_STATE = 'game_state',
    REMATCH = 'rematch',
    SAVED_GAME = 'saved_game',
}

export class FSCameraAction extends DCGameAction {
    camera: JanusRoom;
}

export class FSVoiceCallAction extends DCGameAction {
    status: VoiceCallStatus;
    janusRoom?: JanusRoom;
}

export class FSRematchAction extends DCGameAction {
    status: INVITATIONSTATUS;
}

export class FSSavedGameAction extends DCGameAction {
    game_id: number;
    game_mode: GameMode;
}

export class FSGameEndedAction extends DCGameAction {
    game_id?: number;
    player_won_id?: number;
}

export class FSOmniStateAction extends DCGameAction {
    isActive: boolean;
}
export class FSGameStateAction extends DCGameAction {
    state: ONLINE_GAME_STATE_ACTIONS;
}

export class FSCHatMessage {
    sender?: DCFireStoreUser = new DCFireStoreUser();
    doc_id?: string = '';

    sent_at: Timestamp = Timestamp.now();
    chat_msg: CHAT_MSG;
    custom_msg?: string;
    unfiltered_msg?: string;
    emoji_only?: boolean;
    display_msg?: string;
    messages?: ChatMessage[];
}

export interface ChatMessage {
    message: string;
    unfiltered_message: string;
    emoji_only?: boolean;
    sent_at?: Timestamp;
}

export class FSScoringEvent extends DCGameAction {
    oldRemaining?: number;
    thrownScore?: number;
    throws?: MatchThrow[];

    forceCaller?: boolean = false;
    omniActive?: boolean = false;
    isThrowout?: boolean = false;
    dartsOnDouble?: number;
    dartsForCheckout?: number;
    editScoreEvent?: MatchEditScoreResponse;

    // For cricket
    cricketTurn?: DartCounterCricketTacticsTurn;
    cricketLog?: CricketTacticsLogItem[];
}

export class FSSingleDartEvent extends DCGameAction {
    singleDartPayload: SetSingleDartPayload;
    coordinates?: CartesianCoordinate;
    isValidThrow?: boolean;
}

export enum INVITATIONTYPE {
    JOIN = 'join',
    INVITE = 'invite',
    REINVITE = 'reinvite',
    TOURNAMENT_PRE_GAME_INVITE = 'tournament_pre_game_invite',
}
export class DCFireStoreInvite {
    sender_uid: string = ''; // FirestoreUID
    receiver_uid: string = ''; // FirestoreUID
    user: DCFireStoreUser;

    doc_ref?: DocumentReference<DCFireStoreInvite>;
    doc_id?: string;
    gameplay_doc_id?: string;
    sent_at: Timestamp = Timestamp.now(); //unix

    invitation_type: INVITATIONTYPE;
    status: INVITATIONSTATUS = INVITATIONSTATUS.PENDING;

    tournament_game?: TournamentGame;
}

export class DCFireStoreRating {
    sender_uid: string = ''; // FirestoreUID
    receiver_uid: string = ''; // FirestoreUID
    user: DCFireStoreUser;

    rating?: number;
    sent_at: Timestamp = Timestamp.now(); //unix
}

export class OutGoingInvite {
    sender_uid: string = ''; // FirestoreUID
    receiver_uid: string = ''; // FirestoreUID
    doc_id?: string;

    sent_at: Timestamp = Timestamp.now(); //unix
}

export class DCFireStorePause {
    doc_id?: string;
    doc_ref?: DocumentReference<any>;

    creator_uid: string; // uid
    owners?: string[]; // all uids for validation
    players: DCFireStoreUser[]; //is_ready

    playerOneStatus?: PLAYERSTATUS = PLAYERSTATUS.NONE;
    playerTwoStatus?: PLAYERSTATUS = PLAYERSTATUS.NONE;

    minutes?: number;
    break_ends_at?: Timestamp;
    completed_at?: Timestamp;
    completed: boolean = false;
}

export class DCFireStoreSpectator {
    doc_id?: string;
    user?: DCFireStoreUser;
}

export enum DowntimeStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    IMMINENT = 'imminent',
    PLANNED = 'planned',
}

export class DCDowntime {
    doc_id?: string;
    from?: Timestamp;
    until?: Timestamp;
    reason?: string;
    status?: DowntimeStatus;
    minutesToGo?: number;
    estimatedMinutes?: number;
}
