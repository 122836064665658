import { DartbotTurn } from './dartbot.classes';

export const DartbotCricketTacticsLevels: DartbotLevel[] = [
    { level: 1 },
    { level: 2 },
    { level: 3 },
    { level: 4 },
    { level: 5 },
    { level: 6 },
    { level: 7 },
    { level: 8 },
    { level: 9 },
    { level: 10 },
];

export const DartbotMatchLevels: DartbotLevel[] = [
    {
        level: 1,
        minAvg: 20,
        maxAvg: 25,
        minDarts: 61,
        maxDarts: 73,
    },
    {
        level: 2,
        minAvg: 26,
        maxAvg: 30,
        minDarts: 50,
        maxDarts: 60,
    },
    {
        level: 3,
        minAvg: 31,
        maxAvg: 35,
        minDarts: 43,
        maxDarts: 51,
    },
    {
        level: 4,
        minAvg: 36,
        maxAvg: 40,
        minDarts: 38,
        maxDarts: 44,
    },
    {
        level: 5,
        minAvg: 41,
        maxAvg: 45,
        minDarts: 34,
        maxDarts: 38,
    },
    {
        level: 6,
        minAvg: 46,
        maxAvg: 50,
        minDarts: 30,
        maxDarts: 34,
    },
    {
        level: 7,
        minAvg: 51,
        maxAvg: 55,
        minDarts: 27,
        maxDarts: 31,
    },
    {
        level: 8,
        minAvg: 56,
        maxAvg: 60,
        minDarts: 24,
        maxDarts: 28,
    },
    {
        level: 9,
        minAvg: 61,
        maxAvg: 65,
        minDarts: 22,
        maxDarts: 26,
    },
    {
        level: 10,
        minAvg: 66,
        maxAvg: 70,
        minDarts: 21,
        maxDarts: 23,
    },
    {
        level: 11,
        minAvg: 71,
        maxAvg: 75,
        minDarts: 20,
        maxDarts: 22,
    },
    {
        level: 12,
        minAvg: 76,
        maxAvg: 80,
        minDarts: 18,
        maxDarts: 21,
    },
    {
        level: 13,
        minAvg: 81,
        maxAvg: 85,
        minDarts: 17,
        maxDarts: 20,
    },
    {
        level: 14,
        minAvg: 86,
        maxAvg: 90,
        minDarts: 16,
        maxDarts: 19,
    },
    {
        level: 15,
        minAvg: 91,
        maxAvg: 95,
        minDarts: 15,
        maxDarts: 17,
    },
    {
        level: 16,
        minAvg: 96,
        maxAvg: 100,
        minDarts: 15,
        maxDarts: 16,
    },
    {
        level: 17,
        minAvg: 101,
        maxAvg: 110,
        minDarts: 13,
        maxDarts: 16,
    },
    {
        level: 18,
        minAvg: 110,
        minDarts: 10,
        maxDarts: 14,
    },
];

export const DartbotMatchSettings: DartBotConfig[] = [
    {
        minLevel: 20,
        maxLevel: 30,
        minCheckoutDarts: 4,
        maxCheckoutDarts: 9,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 1.5,
    },
    {
        minLevel: 31,
        maxLevel: 40,
        minCheckoutDarts: 2,
        maxCheckoutDarts: 8,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 1.5,
    },
    {
        minLevel: 41,
        maxLevel: 50,
        minCheckoutDarts: 1,
        maxCheckoutDarts: 6,
        lowerThanScoringChance: 0,
        aroundScoringChance: 100,
        higherThanScoringChance: 0,
        maxCheckoutMultiplier: 1.5,
    },
    {
        minLevel: 51,
        maxLevel: 60,
        minCheckoutDarts: 1,
        maxCheckoutDarts: 6,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 1.5,
    },
    {
        minLevel: 61,
        maxLevel: 70,
        minCheckoutDarts: 1,
        maxCheckoutDarts: 6,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 1.5,
    },
    {
        minLevel: 71,
        maxLevel: 80,
        minCheckoutDarts: 1,
        maxCheckoutDarts: 6,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 1.5,
    },
    {
        minLevel: 81,
        maxLevel: 90,
        minCheckoutDarts: 1,
        maxCheckoutDarts: 4,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 1.5,
    },
    {
        minLevel: 91,
        maxLevel: 100,
        minCheckoutDarts: 1,
        maxCheckoutDarts: 3,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 1.5,
    },
    {
        minLevel: 101,
        maxLevel: 110,
        minCheckoutDarts: 1,
        maxCheckoutDarts: 3,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 150,
    },
    {
        minLevel: 111,
        maxLevel: 120,
        minCheckoutDarts: 1,
        maxCheckoutDarts: 2,
        lowerThanScoringChance: 15,
        aroundScoringChance: 60,
        higherThanScoringChance: 25,
        maxCheckoutMultiplier: 170,
    },
];

export interface DartBotConfig {
    minLevel: number;
    maxLevel: number;
    minCheckoutDarts: number;
    maxCheckoutDarts: number;
    lowerThanScoringChance: number;
    aroundScoringChance: number;
    higherThanScoringChance: number;
    maxCheckoutMultiplier: number;
}

export interface DartbotLeg {
    amountOfDarts: number;
    turns: DartbotTurn[];
}

export interface DartbotScore {
    amount: number;
    dartsOnDouble: number;
    dartsThrown: number;
}

export interface DartbotLevel {
    level: number;
    minAvg?: number;
    maxAvg?: number;
    minDarts?: number;
    maxDarts?: number;
}
