<!-- Create gameplay setup-->
@if (!online.onlineSavedGameplay) {
    <div class="flex space-x-2 !bg-black p-6">
        <div class="flex w-full flex-col space-y-1">
            <div
                class="text-lg font-bold !text-white"
                [translate]="'CREATE_GAMEPLAY_SETUP'"
                i18n="@@CREATE_GAMEPLAY_SETUP">
                Create gameplay setup
            </div>
            <div class="text-xs !text-neutral-100" [translate]="'TO_ADD_YOUR_GAME'" i18n="@@TO_ADD_YOUR_GAME">
                to add your game to lobby or challenge friends!
            </div>
        </div>
        <div
            class="flex h-12 w-12 items-center justify-center rounded bg-orange px-2.5 hover:bg-orange-accent duration-200 cursor-pointer"
            (click)="createGame()">
            <app-icon icon="add" size="large" class="!text-white"></app-icon>
        </div>
    </div>
}

<!-- View gameplay setup-->
@if (online.onlineSavedGameplay) {
    <div class="flex space-x-2 !bg-black p-6">
        <div class="flex w-full flex-col space-y-2">
            <div class="flex items-center space-x-2">
                <div class="text-lg font-bold !text-white">
                    {{ onlineFunctions.onlineGameplayName(online.onlineSavedGameplay) }}
                </div>
                @if (online.onlineSavedGameplay.cameraOnly) {
                    <div class="flex items-center rounded-sm !bg-black-full px-1.5 text-xxxs uppercase !text-white">
                        <app-icon icon="videocam" size="small"></app-icon>
                        <div class="ml-0.5 text-xxxs font-bold uppercase" [translate]="'CAM_ONLY'" i18n="@@CAM_ONLY">
                            Cam only
                        </div>
                    </div>
                }
            </div>
            <div class="flex flex-wrap items-start gap-2">
                @switch (online.onlineSavedGameplay.type) {
                    @case ('match') {
                        @if (onlineFunctions.asMatch(online.onlineSavedGameplay.game).has_checkout_rate) {
                            <div class="rounded-full !bg-white px-2 text-xxxs font-bold uppercase !text-black">
                                {{ 'CHECKOUT_RATE' | translate }}
                            </div>
                        }
                        <div class="rounded-full !bg-white px-2 text-xxxs font-bold uppercase !text-black">
                            {{ onlineFunctions.asMatch(online.onlineSavedGameplay.game).start_with | entryMode }}
                        </div>
                        <div class="rounded-full !bg-white px-2 text-xxxs font-bold uppercase !text-black">
                            {{ onlineFunctions.asMatch(online.onlineSavedGameplay.game).end_with | finishMode }}
                        </div>
                        @if (onlineFunctions.asMatch(online.onlineSavedGameplay.game).two_legs_difference) {
                            <div
                                class="rounded-full !bg-white px-2 text-xxxs font-bold uppercase !text-black"
                                [translate]="'TWO_LEGS_DIFFERENCE'"
                                i18n="@@TWO_LEGS_DIFFERENCE">
                                Two legs difference
                            </div>
                        }
                        @if (onlineFunctions.asMatch(online.onlineSavedGameplay.game).enable_sudden_death) {
                            <div
                                class="rounded-full !bg-white px-2 text-xxxs font-bold uppercase !text-black"
                                [translate]="'SUDDEN_DEATH_MODE'"
                                i18n="@@SUDDEN_DEATH_MODE">
                                Sudden death mode
                            </div>
                        }
                    }

                    @case ('cricket_tactics') {
                        @if (onlineFunctions.asCricketTactics(online.onlineSavedGameplay.game).two_legs_difference) {
                            <div
                                class="rounded-full !bg-white px-2 text-xxxs font-bold uppercase !text-black"
                                [translate]="'TWO_LEGS_DIFFERENCE'"
                                i18n="@@TWO_LEGS_DIFFERENCE">
                                Two legs difference
                            </div>
                        }
                        @if (onlineFunctions.asCricketTactics(online.onlineSavedGameplay.game).enable_sudden_death) {
                            <div
                                class="rounded-full !bg-white px-2 text-xxxs font-bold uppercase !text-black"
                                [translate]="'SUDDEN_DEATH_MODE'"
                                i18n="@@SUDDEN_DEATH_MODE">
                                Sudden death mode
                            </div>
                        }
                    }
                }
            </div>
        </div>
        <button
            [disabled]="disable()"
            [class.opacity-60]="disable()"
            class="flex h-12 w-12 items-center justify-center rounded bg-orange px-2.5 hover:enabled:bg-orange-accent duration-200 cursor-pointer"
            (click)="createGame()">
            <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_830_4411)">
                    <path
                        d="M3 17.4605V20.5005C3 20.7805 3.22 21.0005 3.5 21.0005H6.54C6.67 21.0005 6.8 20.9505 6.89 20.8505L17.81 9.94055L14.06 6.19055L3.15 17.1005C3.05 17.2005 3 17.3205 3 17.4605ZM20.71 7.04055C21.1 6.65055 21.1 6.02055 20.71 5.63055L18.37 3.29055C17.98 2.90055 17.35 2.90055 16.96 3.29055L15.13 5.12055L18.88 8.87055L20.71 7.04055Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_830_4411">
                        <rect width="1.5rem" height="1.5rem" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </button>
    </div>
}
