import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class ReportedUserApiService extends ApiService {
    public getReportedUserById = super.getFetcher().path('/reported-users/{reportedUserId}').method('get').create();
    public getReportedUserReportsById = super
        .getFetcher()
        .path('/reported-users/{reportedUserId}/reports')
        .method('get')
        .create();
    public deleteReportedUserById = super
        .getFetcher()
        .path('/reported-users/{reportedUserId}')
        .method('delete')
        .create();
}
