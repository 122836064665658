import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-policy-dialog',
    templateUrl: 'policy.html',
    styleUrls: ['policy.scss'],
})
export class PolicyDialogComponent {
    scorings: any;
    teams: any;
    lang: string;

    constructor(public view: ModalController, private translate: TranslateService) {}

    ionViewWillEnter() {
        if (this.translate.currentLang) {
            this.lang = this.translate.currentLang;
        } else {
            this.lang = 'en';
        }
    }

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
