import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsletterApiService } from '@dc-api/newsletter.api.service';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-newsletter-opt-in-dialog',
    templateUrl: 'newsletter-opt-in.dialog.html',
})
export class NewsletterOptInDialogComponent implements OnInit {
    newsletterOptIn = false;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute,
        private _alertService: DartCounterAlertService,
        private _newsletterApiService: NewsletterApiService
    ) {}

    ngOnInit(): void {
        if (this.auth.user.newsletter_opt_in) {
            this.newsletterOptIn = true;
        }
    }

    public async save(): Promise<void> {
        if (this.newsletterOptIn === this.auth.user.newsletter_opt_in) {
            this.dismiss();
            return;
        }

        await this._newsletterApiService
            .updateNewsletterOptIn({
                newsletter_opt_in: this.newsletterOptIn,
            })
            .then((res) => {
                if (res.data.message) {
                    this.auth.user.newsletter_opt_in = this.newsletterOptIn;
                    this._alertService.createAlert({
                        title: res.data.message,
                        icon: 'success',
                    });
                }
            })
            .catch(console.error);

        this.dismiss();
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
