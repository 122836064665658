<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="DismissModal()">Close</div>
        <div class="slide-modal-title" [translate]="'SPECTATORS'" i18n="@@SPECTATORS">Spectators</div>
    </div>
    <div class="slide-modal-content">
        @if (!spectatorsCollectionService.spectators().length) {
            <div class="subtitle" [translate]="'NO_SPECTATORS_WATCHING'" i18n="@@NO_SPECTATORS_WATCHING">
                There are no spectators watching this game
            </div>
        }
        @if (spectatorsCollectionService.spectators().length) {
            @for (spectator of spectatorsCollectionService.spectators(); track spectator) {
                <div class="flex items-center mb-3">
                    <div>
                        <app-profile-photo
                            class="block h-full"
                            [size]="36"
                            [ultimate]="spectator.user.is_ultimate"
                            [url]="spectator.user.profile_photo_url"
                            [badge]="spectator.user.badge"></app-profile-photo>
                    </div>
                    <div class="ml-2 capitalize">
                        {{ spectator.user.full_name }}
                    </div>
                </div>
            }
        }
    </div>
</div>
