<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern">
    <app-page-header mainNavigation="custom" color="transparent">
        <div
            left
            class="text-center text-sm font-bold text-black cursor-pointer"
            (click)="close()"
            [translate]="'CLOSE'"
            i18n="@@CLOSE">
            Close
        </div>
        <div title class="text-black" [translate]="'LIVE_CAMERA'" i18n="@@LIVE_CAMERA">Live camera</div>
    </app-page-header>

    <div class="flex h-full portrait:flex-col portrait:sm:flex-row landscape:flex-row">
        @if (userMedia?.videoStreams.hasStreams === false) {
            <app-icon icon="loading" size="extralarge" class="mx-auto animate-spin text-orange"></app-icon>
        }

        <div class="relative mx-auto basis-1/2 overflow-hidden" *ngIf="userMedia?.videoStreams.board">
            <video
                *ngIf="userMedia?.videoStreams.board?.stream"
                class="ingame-camera w-full"
                [srcObject]="userMedia?.videoStreams.board.stream"
                [style.transform]="userMedia?.videoStreams.board.scale"
                autoplay
                playsinline
                webkit-playsinline
                muted></video>
            <div class="absolute left-4 top-4 h-7 w-7 rounded-md bg-orange p-1">
                <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
            </div>
        </div>
        <div class="relative mx-auto basis-1/2 overflow-hidden" *ngIf="userMedia?.videoStreams.player">
            <video
                *ngIf="userMedia?.videoStreams.player?.stream"
                class="ingame-camera w-full"
                [srcObject]="userMedia?.videoStreams.player.stream"
                autoplay
                playsinline
                webkit-playsinline
                muted></video>
            <div class="absolute right-4 top-4 h-7 w-7 rounded-md bg-orange p-1">
                <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
            </div>
        </div>
        <!-- <div class="targetVideo" style="min-height: 300px" *ngIf="userMedia && !userMedia.videoStreams.board">
            <div class="stream-paused-overlay">
                <div [translate]="'CAMERA_PAUSED'" i18n="@@CAMERA_PAUSED">Camera paused</div>
            </div>
        </div> -->
    </div>
</div>
