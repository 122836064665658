import { CameraPosition } from '@dc-core/dc-backend/dc-enums';
import { CameraStreamClip } from '../../dc-backend/dc-classes';

// Object to save the user flow
export interface UserCamerConnection {
    camType: CAMERA_TYPE;
    room: number;

    // optional fields
    code?: number;
    pass?: string;
}

export interface DCStreamTrack extends MediaStreamTrack {
    feedId: number;
}

export enum CAMERA_SLIDE {
    CAMERA = 'CAMERA',
    INFO = 'INFO',
    CONNECT = 'CONNECT',
    SMART_DEVICE = 'SMART_DEVICE',
    FINISH = 'FINISH',
}

export enum CAMERA_TYPE {
    CURRENT_DEVICE = 'current_device',
    EXTERNAL_DEVICE = 'external_device',
    SMART_DEVICE = 'smart_device',
}

export interface CameraStreamSetup {
    user_id: number;
    boardStream?: DartCounterVideoStream;
    playerStream?: DartCounterVideoStream;
    bitrate?: string;
    dualStreams?: boolean;
}

export interface BlobChunk {
    data: Blob;
    timestamp: number;
}

export interface DartCounterVideoStream {
    ID: string;
    rfid: string;
    boardCam: boolean;
    stream?: MediaStream;
    scale?: string;
    mediaRecorder?: MediaRecorder;
    recordedChunksSet?: BlobChunk[][];
}

export interface JanusRoom {
    janusServerHost: string;
    roomID: number;
    camType: CAMERA_TYPE;
    scale?: string;
    disablePlayerCamForSpectators?: boolean;
    disablePlayerCamForAll?: boolean;
    cameraPosition?: CameraPosition;
}

export interface DartCounterVideo {
    zoom?: number;
    isActive?: boolean; // Video stream active?
}

export interface DartCounterAudio {
    isActive?: boolean; // Audio stream active?
}

// In the lobby
export interface DartCounterRoom {
    room: number;
    camType?: CAMERA_TYPE;
    hasCamera: boolean;
    hasAudio: boolean;
}

export interface StreamMetaData {
    boardcam?: boolean;
    user?: string;
}

export enum FacingModes {
    BOARD = 'board',
    PLAYER = 'player',
}

export interface StreamDisplayMetaData {
    facingMode?: FacingModes;
    user?: string;
    scale?: string;
    kind?: StreamDisplayMetaDataKind;
}

export type StreamDisplayMetaDataKind = 'app' | 'virt';

export interface ClipEvent {
    event: 'start' | 'finished';
    clip?: CameraStreamClip;
}

export interface ClipCameraStreamDialogResponse {
    title: string;
    duration: number;
}
