<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'STARTSCORE'" i18n="@@STARTSCORE">Startscore</div>
    </div>
    <div class="slide-modal-content text-black">
        <form #form="ngForm" [formGroup]="scoreForm" (ngSubmit)="scoreForm.valid && save()">
            <div class="my-4">
                <input
                    [ngClass]="{
                        '!border-red': form.submitted && scoreForm.get('customScore').errors
                    }"
                    formControlName="customScore"
                    type="number"
                    inputmode="numeric"
                    min="101"
                    max="9999"
                    class="dialog-input" />
                @if (form.submitted && scoreForm.get('customScore').errors) {
                    @if (scoreForm.get('customScore').errors.min) {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MIN_START_SCORE'" i18n="@@ERROR_MIN_START_SCORE">
                                The minimum start score is 101
                            </span>
                        </app-validation-label>
                    } @else if (scoreForm.get('customScore').errors.max) {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MAX_START_SCORE'" i18n="@@ERROR_MAX_START_SCORE">
                                The maximum start score is 9999
                            </span>
                        </app-validation-label>
                    } @else {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MIN_START_SCORE'" i18n="@@ERROR_MIN_START_SCORE">
                                The minimum start score is 101
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
            <div class="button-container">
                <app-primary-button
                    type="submit"
                    size="normal"
                    addedClasses="w-full"
                    color="orange"
                    [translate]="'CONFIRM'"
                    i18n="@@CONFIRM">
                    Confirm
                </app-primary-button>
            </div>
        </form>
    </div>
</div>
