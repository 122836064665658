import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DartCounterMatchTeam } from '@dc-core/dc-gamelogic/in-game/match/match.in-game.classes';

@Component({
    selector: 'app-remaining-score',
    templateUrl: 'remaining-score.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class RemainingScoreComponent {
    @Input() team: DartCounterMatchTeam;
    @Input() busted: boolean;
}
