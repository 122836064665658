<ion-header mode="md">
    <ion-toolbar class="ion-bg-black">
        <ion-buttons slot="start">
            <ion-back-button color="light" defaultHref="/action-replays"></ion-back-button>
        </ion-buttons>
        <ion-title class="text-center ion-text-white">{{ clip?.title }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content id="video-player-container" class="bg-black" [fullscreen]="true">
    <ion-header mode="md" collapse="condense">
        <ion-toolbar class="ion-bg-black">
            <ion-buttons slot="start">
                <ion-back-button color="light" defaultHref="/action-replays"></ion-back-button>
            </ion-buttons>
            <ion-title class="text-center ion-text-white">{{ clip?.title }}</ion-title>
        </ion-toolbar>
    </ion-header>

    <div id="video-player" slot="fixed"></div>
</ion-content>
