/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-new-online-feature-dialog',
    templateUrl: 'new-online-feature.html',
    styleUrls: ['new-online-feature.scss'],
})
export class NewOnlineFeatureDialogComponent implements OnInit {
    public title: string;
    public slides: { title: string; desc: string; image?: string }[] = [];

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private _translate: TranslateService
    ) {}

    ngOnInit(): void {
        const name = $localize`:@@ACTION_REPLAYS:Action Replays`;
        $localize`:@@NEW_FEATURE_NAME:New ${name}:name: feature`;

        this.title = this._translate.instant('NEW_FEATURE_NAME', { name: name });
        this.slides = [
            {
                title: $localize`:@@ACTION_REPLAYS:Action Replays`,
                desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_1:Save your turn as an Action Replay`,
                image: 'assets/images/online-features/1-action-replays.png',
            },
            {
                title: $localize`:@@ACTIVATE_YOUR_CAMERA:Activate your camera`,
                desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_2:Use your camera in-game to save it as a replay`,
                image: 'assets/images/online-features/2-action-replays.png',
            },
            {
                title: $localize`:@@SAVE_AS_REPLAY:Save as Action Replay`,
                desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_3:Whenever your turn has passed, you can save it as an Action Replay`,
                image: 'assets/images/online-features/3-action-replays.png',
            },
            {
                title: $localize`:@@VIEW_YOUR_REPLAYS:View your replays`,
                desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_4:View, download and favourite your replays in the gallery`,
                image: 'assets/images/online-features/4-action-replays.png',
            },
        ];
    }

    public DismissModal(): void {
        this.view.dismiss(this.auth.user.is_ultimate ? null : 'upgrade');
    }
}
