<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <div class="title-container">
            <div class="title" *ngIf="title">{{ title }}</div>
        </div>
        <div class="mt-8 space-y-2">
            <app-checkbox
                class="block"
                [model]="agreements"
                color="white"
                (itemChange)="agreements = !agreements"
                *ngIf="!auth.user.accepted_terms_at">
                <div class="flex flex-wrap gap-1 text-black">
                    <span [translate]="'I_AGREE_TO_THE'" i18n="@@I_AGREE_TO_THE">I agree to the</span>
                    <a class="text-orange hover:underline" [routerLink]="['/terms-of-service']">
                        <span [translate]="'TERMS_OF_SERVICE'" i18n="@@TERMS_OF_SERVICE">Terms of Service</span>
                    </a>
                    <span [translate]="'AND'" i18n="@@AND">and</span>
                    <a class="text-orange hover:underline" [routerLink]="['/privacy-policy']">
                        <span [translate]="'PRIVACY_POLICY'" i18n="@@PRIVACY_POLICY">Privacy Policy</span>
                    </a>
                </div>
            </app-checkbox>

            <app-checkbox
                class="block"
                [model]="newsletterOptIn"
                color="white"
                (itemChange)="newsletterOptIn = !newsletterOptIn"
                *ngIf="auth.user.email">
                <div class="flex text-black" [translate]="'NEWSLETTER_OPT_IN_INFO'" i18n="@@NEWSLETTER_OPT_IN_INFO">
                    Yes, I want to hear about exclusive offers, announcements and the newest products from Target
                    DartCounter, as well as those tailored to my account and activity.
                </div>
            </app-checkbox>
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="continue()"
                [translate]="'CONTINUE'"
                i18n="@@CONTINUE">
                Continue
            </app-primary-button>
        </div>
    </div>
</modal-content>
