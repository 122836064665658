<modal-content scrollY="false" fullscreen class="bg-white-pattern text-black">
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="close()"></app-icon>
        <div class="title-container">
            <div class="title" i18n="@@REPORT_PLAYER" [translate]="'REPORT_PLAYER'">Report player</div>
        </div>
        <div class="mt-8 relative">
            <div class="dialog-input-label mb-2" [translate]="'REASON'" i18n="@@REASON">Reason</div>
            <app-select
                class="block"
                [error]="errors.suspensionReason"
                [initialValue]="suspensionReason"
                [options]="suspensionReasons"
                (selectionChange)="suspensionReason = $event"
                [relativePositioning]="false"
                mode="dialog-input"
                size="full"></app-select>
            @if (errors.suspensionReason) {
                <app-validation-label [noAbsolute]="true" textColor="black">
                    <span [translate]="'REASON_IS_REQUIRED'" i18n="@@REASON_IS_REQUIRED">Reason is required</span>
                </app-validation-label>
            }

            <input
                type="text"
                class="dialog-input mt-4"
                [ngClass]="{ '!border-red': errors.reason }"
                [(ngModel)]="reason"
                [placeholder]="ADDITIONAL_INFORMATION" />
            <app-toggle-switch class="block w-full py-3" [model]="block" (itemChange)="block = !block">
                <span [translate]="'ALSO_BLOCK_PLAYER'" i18n="@@ALSO_BLOCK_PLAYER">Also block player</span>
            </app-toggle-switch>
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="report()"
                [translate]="'CONFIRM'"
                i18n="@@CONFIRM">
                Confirm
            </app-primary-button>
        </div>
    </div>
</modal-content>
