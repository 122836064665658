import { Injectable } from "@angular/core";
import { Auth, signInAnonymously, signInWithCustomToken, signOut, UserCredential } from "@angular/fire/auth";

@Injectable()
export class FireStoreAuthService {

    public userCreds: UserCredential = null;

    constructor(
        private fireAuth: Auth
    ) {

    }

    public getCurrentUID(): string{
        if(this.userCreds){
            return this.userCreds.user.uid;
        }
        return null;
    }

    // Signs in anonymously or with a custom token
    signIn(token = null): Promise<UserCredential>{
        if(token){
            return signInWithCustomToken(this.fireAuth, token);
        }
        else{
            return signInAnonymously(this.fireAuth)
        }
    }

    signOut(): Promise<void>{
        this.userCreds = null;
        return signOut(this.fireAuth);
    }
    
}
