import { Component, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-crop-profile-photo-dialog',
    templateUrl: 'crop-profile-photo.dialog.html',
})
export class CropProfilePhotoDialogComponent {
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

    @Input() image: any = '';
    @Input() format: any = '';
    croppedImage = '';

    constructor(
        public view: ModalController,
        public auth: AuthService
    ) {}

    cropperReady(): void {
        const event = this.imageCropper.crop('base64');
        if (event) {
            this.imageCropped(event);
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        this.cropperReady();
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    save(): void {
        if (this.croppedImage) {
            this.dismiss(this.croppedImage);
        }
    }
}
