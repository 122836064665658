import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { Network } from '@capacitor/network';
import { AppFeaturesResponse } from '@dc-core/dc-backend/dc-responses';
import { firstValueFrom, take } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum UltimateFeatureID {
    PLAY_ONLINE = 'PLAY_ONLINE',
    MASTERCALLER = 'MASTERCALLER',
    ALLTIME = 'ALLTIME',
    ULTIMATE_ONLY_GAMES = 'ULTIMATE_ONLY_GAMES',
    ACTION_REPLAYS = 'ACTION_REPLAYS',
    MATCHDETAILS = 'MATCHDETAILS',
}

export interface UltimateFeature {
    id: UltimateFeatureID;
    title: string;
    image: string;

    description?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AppFeaturesService {
    public enabledAppFeatures: WritableSignal<AppFeaturesResponse> = signal({
        action_replays: false,
        private_chat: true,
        report_recording: false,
        speech_to_score: false,
        chromecast: false,
        tournaments: false,
        year_recap: null,
    });
    public featuresStatusUpdated: boolean = false;

    private availableUltimateFeatures: UltimateFeature[] = [];

    constructor(private http: HttpClient) {
        this.setUltimateFeatures();
    }

    public registerEnabledAppFeatures(): Promise<void> {
        return Network.getStatus()
            .then((status) => {
                if (!status.connected) {
                    return Promise.resolve();
                }

                const enabledAppFeatures$ = this.http
                    .get<AppFeaturesResponse>(`${environment.apiUrl}/app-features`)
                    .pipe(take(1));

                return firstValueFrom(enabledAppFeatures$)
                    .then((appFeatures) => {
                        this.enabledAppFeatures.update(() => appFeatures);
                        this.setUltimateFeatures();
                        this.featuresStatusUpdated = true;
                    })
                    .catch(() => Promise.resolve());
            })
            .catch(() => Promise.resolve());
    }

    public getAvailableUltimateFeatures(): UltimateFeature[] {
        return this.availableUltimateFeatures;
    }

    private setUltimateFeatures() {
        const ultimateFeatures: UltimateFeature[] = [];

        // Unlimited online games
        ultimateFeatures.push({
            id: UltimateFeatureID.PLAY_ONLINE,
            title: $localize`:@@FEATURE_PLAYONLINE:Play unlimited online games`,
            image: 'assets/images/features/1-playonline.png',
        });

        // Mastercaller calls your name
        ultimateFeatures.push({
            id: UltimateFeatureID.MASTERCALLER,
            title: $localize`:@@FEATURE_CALLER:MasterCaller calls your name`,
            image: 'assets/images/features/2-callname.png',
        });

        // All-time statistics
        ultimateFeatures.push({
            id: UltimateFeatureID.ALLTIME,
            title: $localize`:@@FEATURE_ALLTIME:Detailed all-time statistics`,
            image: 'assets/images/features/3-alltime.png',
        });

        // 121 Checkout
        ultimateFeatures.push({
            id: UltimateFeatureID.ULTIMATE_ONLY_GAMES,
            title: $localize`:@@FEATURE_ULTIMATE_ONLY_GAMES:Play Ultimate-only games`,
            image: 'assets/images/features/checkout121.png',
        });

        // Action Replays (if enabled)
        if (this.enabledAppFeatures().action_replays) {
            ultimateFeatures.push({
                id: UltimateFeatureID.ACTION_REPLAYS,
                title: $localize`:@@FEATURE_ACTIONREPLAYS_TEXT:Favourite and save more of your Action Replays`,
                image: 'assets/images/features/action-replays.png',
            });
        }

        // All game details
        ultimateFeatures.push({
            id: UltimateFeatureID.MATCHDETAILS,
            title: $localize`:@@FEATURE_MATCHDETAILS:View all of your game details`,
            image: 'assets/images/features/4-details.png',
        });

        this.availableUltimateFeatures = ultimateFeatures;
    }
}
