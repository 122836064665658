import { User } from 'dc-core/dc-backend/dc-classes';
import { GameStatistics } from '../game-statistics.classes';
import { RangeMode, WinningType } from '@dc-core/dc-backend/dc-enums';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';

export interface ShanghaiOverallStatsData {
    totalScore: { average?: number; best?: number; worst?: number };
    dartsHit: { average?: number; best?: number; worst?: number };
    hitRate: { average?: number; best?: number; worst?: number };
}

export interface ShanghaiStatDetails {
    rangeMode: RangeMode;
    winningType: WinningType;
    requireNumber: boolean;
    goalTriple: number;
}

export class ShanghaiOverallStats extends GameStatistics {
    public rangeMode: RangeMode = 'one_to_seven';
    public winningType: WinningType = 'score';
    public requireNumber: boolean = false;
    public goalTriple: number = 0;

    public overallStats: ShanghaiOverallStatsData = {
        totalScore: {},
        dartsHit: {},
        hitRate: {},
    } as any;

    public loadedOverallStats: boolean = false;
    public gameStatisticsApiService: GameStatisticsApiService;

    private _localStorageKey = 'shanghaiOverallStats';

    constructor(user: User, gameStatisticsApiService: GameStatisticsApiService) {
        super(user);
        this.gameStatisticsApiService = gameStatisticsApiService;
    }

    setFilters(): void {
        let savedFilters = JSON.parse(localStorage.getItem(this._localStorageKey));
        if (savedFilters) {
            this.rangeMode = savedFilters.rangeMode ?? this.rangeMode;
            this.winningType = savedFilters.winningType ?? this.winningType;
            this.requireNumber = savedFilters.requireNumber ?? this.requireNumber;
            this.goalTriple = savedFilters.goalTriple ?? this.goalTriple;
        }
        super.setFilters();
    }

    saveFilters() {
        localStorage.setItem(
            this._localStorageKey,
            JSON.stringify({
                rangeMode: this.rangeMode,
                winningType: this.winningType,
                requireNumber: this.requireNumber,
                goalTriple: this.goalTriple,
                statisticsGameType: this.statisticsGameType,
                statisticsRange: this.statisticsRange,
                year: this.year,
                month: this.month,
            })
        );
        super.saveFilters();
    }

    async loadOverallStats(): Promise<void> {
        this.loadedOverallStats = false;
        await this.initTotalScore();
        if (this.user.is_ultimate) {
            await this.initDartsHit();
            await this.initHitRate();
        }
        this.loadedOverallStats = true;
        this.saveFilters();
    }

    async initTotalScore(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getShanghaiTotalScoreStatistics({
                        type,
                        range_mode: this.rangeMode,
                        winning_type: this.winningType,
                        require_number: this.requireNumber,
                        goal_triple: this.goalTriple,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.totalScore[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initDartsHit(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getShanghaiDartsHitStatistics({
                        type,
                        range_mode: this.rangeMode,
                        winning_type: this.winningType,
                        require_number: this.requireNumber,
                        goal_triple: this.goalTriple,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.dartsHit[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initHitRate(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getShanghaiHitRateStatistics({
                        type,
                        range_mode: this.rangeMode,
                        winning_type: this.winningType,
                        require_number: this.requireNumber,
                        goal_triple: this.goalTriple,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.hitRate[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }
}
