import { UserHotkey } from 'dc-core/dc-backend/dc-classes';

export const defaultHotkeys = [26, 41, 45, 60, 66, 81, 85, 100, 140, 180];

export class HotkeyHelper {
    public static fromHotkeys(hotkeys: UserHotkey): number[] {
        return [
            hotkeys.hotkey_1,
            hotkeys.hotkey_2,
            hotkeys.hotkey_3,
            hotkeys.hotkey_4,
            hotkeys.hotkey_5,
            hotkeys.hotkey_6,
            hotkeys.hotkey_7,
            hotkeys.hotkey_8,
            hotkeys.hotkey_9,
            hotkeys.hotkey_10,
        ];
    }

    public static toHotkeys(
        hotkeys: number[],
        userId: number = null
    ): {
        user_id: number;
        userId: number;
        hotkey_1: number;
        hotkey_2: number;
        hotkey_3: number;
        hotkey_4: number;
        hotkey_5: number;
        hotkey_6: number;
        hotkey_7: number;
        hotkey_8: number;
        hotkey_9: number;
        hotkey_10: number;
    } {
        return {
            user_id: userId,
            userId: userId,
            hotkey_1: hotkeys[0],
            hotkey_2: hotkeys[1],
            hotkey_3: hotkeys[2],
            hotkey_4: hotkeys[3],
            hotkey_5: hotkeys[4],
            hotkey_6: hotkeys[5],
            hotkey_7: hotkeys[6],
            hotkey_8: hotkeys[7],
            hotkey_9: hotkeys[8],
            hotkey_10: hotkeys[9],
        };
    }
}
