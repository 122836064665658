/* eslint-disable @typescript-eslint/no-unused-vars */
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { fromPairs } from 'lodash';
import { map, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config.js';

export type TailwindScreenAlias = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'webxl' | '3xl';

@Injectable()
export class MediaWatcherService {
    private _onMediaChange: ReplaySubject<{ matchingAliases: TailwindScreenAlias[]; matchingQueries: any }> =
        new ReplaySubject<{
            matchingAliases: TailwindScreenAlias[];
            matchingQueries: any;
        }>(1);

    /**
     * Constructor
     */
    constructor(private _breakpointObserver: BreakpointObserver) {
        const fullConfig = resolveConfig(tailwindConfig);

        const screens = fullConfig.theme.screens;
        of({ screens })
            .pipe(
                map((config) =>
                    fromPairs(
                        Object.entries(config.screens).map(([alias, screen]) => [alias, `(min-width: ${screen})`])
                    )
                ),
                switchMap((screens) =>
                    this._breakpointObserver.observe(Object.values(screens)).pipe(
                        map((state) => {
                            // Prepare the observable values and set their defaults
                            const matchingAliases: TailwindScreenAlias[] = [];
                            const matchingQueries: any = {};

                            // Get the matching breakpoints and use them to fill the subject
                            const matchingBreakpoints =
                                Object.entries(state.breakpoints).filter(([_, matches]) => matches) ?? [];
                            for (const [query] of matchingBreakpoints) {
                                // Find the alias of the matching query
                                const matchingAlias = Object.entries(screens).find(([_, q]) => q === query)[0];

                                // Add the matching query to the observable values
                                if (matchingAlias) {
                                    matchingAliases.push(matchingAlias as TailwindScreenAlias);
                                    matchingQueries[matchingAlias] = query;
                                }
                            }

                            // Execute the observable
                            this._onMediaChange.next({
                                matchingAliases,
                                matchingQueries,
                            });
                        })
                    )
                )
            )
            .subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for _onMediaChange
     */
    get onMediaChange$(): Observable<{ matchingAliases: TailwindScreenAlias[]; matchingQueries: any }> {
        return this._onMediaChange.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On media query change
     *
     * @param query
     */
    onMediaQueryChange$(query: string | string[]): Observable<BreakpointState> {
        return this._breakpointObserver.observe(query);
    }
}
