import { Bobs27, Bobs27User } from 'dc-core/dc-backend/dc-classes';
import { Bobs27Difficulty, TrainingMode } from 'dc-core/dc-backend/dc-enums';
import { LOCAL_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import { DartCounterGameSettings } from '../settings-classes';
import { dateTimeFormat, DIFFICULTY_EASY, DIFFICULTY_HARD, TRAINING_MODES } from '../../settings.globals';
import * as moment from 'moment';

export class DartCounterBobs27Settings extends DartCounterGameSettings {
    public difficulty: Bobs27Difficulty;
    public difficulties: { label: string; tag: string; type: Bobs27Difficulty }[];

    public trainingMode: TrainingMode;
    public trainingModes = TRAINING_MODES;

    constructor() {
        //Set the super-class settings
        super();
        this.storageKey = LOCAL_GAME_SETTINGS_STORAGE_KEYS.BOBS_27;

        this.difficulties = [DIFFICULTY_EASY, DIFFICULTY_HARD];

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        } else {
            this.setDefaults();
        }
    }

    private setDefaults() {
        this.difficulty = 'easy';
        this.playerType = 'players';
        this.trainingMode = 'training';

        this.users = [];
        this.teams = [];
    }

    private setStoredSettings(storedSettings: DartCounterBobs27Settings) {
        this.difficulty = storedSettings.difficulty;
        this.trainingMode = storedSettings.trainingMode ?? 'training';

        this.users = storedSettings.users;
        this.teams = storedSettings.teams;
    }

    changeDifficulty(difficulty: Bobs27Difficulty): void {
        this.difficulty = difficulty;
    }

    changeTrainingMode(trainingMode: TrainingMode): void {
        this.trainingMode = trainingMode;
    }

    saveGameSettings() {
        let storageObject = {
            difficulty: this.difficulty,
            playerType: this.playerType,
            users: this.users,
            teams: this.teams,
        };
        localStorage.setItem(this.storageKey, JSON.stringify(storageObject));
    }

    mapToBobs27Object(): Bobs27 {
        const users: Bobs27User[] = [];
        this.users.forEach((user, index) => {
            users.push({
                id: index + 1,
                bobs27_id: 0,
                name: user.id === null ? user.full_name : user.first_name,
                user_id: user.id,
                user,
                ghost_name: user.id === null ? user.full_name : null,
                is_verified: this.user.id === user.id ? true : false,
            });
        });

        return <Bobs27>{
            id: 1,
            difficulty: this.difficulty,
            training_mode: this.users.length > 1 ? this.trainingMode : 'training',
            started_at: moment().utc().format(dateTimeFormat),
            users: users,
            turns: [],
        };
    }
}
