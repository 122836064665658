@if (absoluteSize) {
    <div
        [class.ultimate]="ultimate"
        style="display: flex; justify-content: center; align-items: center; position: relative; width: {{
            absoluteSize + 6
        }}px; height: {{ absoluteSize + 6 }}px;
    max-width: {{ absoluteSize + 6 }}px; min-width: {{ absoluteSize + 6 }}px; max-height: {{ absoluteSize + 6 }}px; 
    min-height: {{ absoluteSize + 6 }}px; margin-left: auto; margin-right: auto;">
        <img
            class="image"
            style="width: {{ ultimate ? absoluteSize + 6 : absoluteSize + 6 }}px; height: {{
                ultimate ? absoluteSize : absoluteSize + 6
            }}px;
    max-width: {{ ultimate ? absoluteSize : absoluteSize + 6 }}px; min-width: {{
                ultimate ? absoluteSize : absoluteSize + 6
            }}px; 
    max-height: {{ ultimate ? absoluteSize : absoluteSize + 6 }}px; min-height: {{
                ultimate ? absoluteSize : absoluteSize + 6
            }}px;"
            [src]="imageUrl" />
        <img
            *ngIf="badge && badgeImg"
            [ngClass]="{ big: big }"
            class="verification-icon"
            src="assets/images/verifications/{{ badgeImg }}" />
    </div>
} @else {
    <div
        [class.ultimate]="ultimate"
        style="display: flex; justify-content: center; align-items: center; position: relative; width: {{
            borderSize / 16
        }}rem; height: {{ borderSize / 16 }}rem;
        max-width: {{ borderSize / 16 }}rem; min-width: {{ borderSize / 16 }}rem; max-height: {{ borderSize / 16 }}rem; 
        min-height: {{ borderSize / 16 }}rem; margin-left: auto; margin-right: auto;"
        [ngClass]="{ big: big }">
        <img
            class="image"
            style="width: {{ ultimate ? size / 16 : borderSize / 16 }}rem; height: {{
                ultimate ? size / 16 : borderSize / 16
            }}rem;
        max-width: {{ ultimate ? size / 16 : borderSize / 16 }}rem; min-width: {{
                ultimate ? size / 16 : borderSize / 16
            }}rem; 
        max-height: {{ ultimate ? size / 16 : borderSize / 16 }}rem; min-height: {{
                ultimate ? size / 16 : borderSize / 16
            }}rem;"
            [src]="imageUrl" />
        <img
            *ngIf="badge && badgeImg"
            [ngClass]="{ big: big }"
            class="verification-icon"
            src="assets/images/verifications/{{ badgeImg }}" />
        <div *ngIf="showRating === true" class="rating-badge">
            <img class="ratingIcon" src="assets/images/verifications/star_{{ ratingColor }}.png" />
            <span class="ratingAverage">{{ rating | number: '1.1-1' }}</span>
        </div>
        <ng-content></ng-content>
    </div>
}
