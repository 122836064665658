import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthApiService } from '@dc-api/auth.api.service';
import { ModalController, NavController } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { SocialMediaService } from 'src/providers/SocialMediaService';
import { AuthService } from 'src/services/auth.service';

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return control.value.new_password === control.value.confirm_password ? null : { PasswordNoMatch: true };
};

@Component({
    selector: 'app-edit-password',
    templateUrl: 'edit-password.html',
})
export class EditPasswordComponent {
    public account: any;

    public editPasswordForm: FormGroup = this.formBuilder.group({
        new_password: ['', [Validators.required, Validators.min(6)]],
        confirm_password: ['', Validators.required],
    });

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public navCtrl: NavController,
        public loading: DCLoadingService,
        public social: SocialMediaService,
        public modal: ModalController,
        public route: ActivatedRoute,
        public authApiService: AuthApiService,
        private _alertService: DartCounterAlertService,
        private formBuilder: FormBuilder
    ) {
        $localize`:@@CURRENT_PASSWORD:Current password`;
        $localize`:@@REPEAT_NEW_PASSWORD:Repeat new password`;
    }

    SubmitPassword() {
        if (this.editPasswordForm.invalid) {
            return;
        }

        this.loading.ShowDefaultLoader();

        this.authApiService
            .changePasswordViaEmail({
                new_password: this.editPasswordForm.get('new_password').value,
                confirm_password: this.editPasswordForm.get('confirm_password').value,
            })
            .then((res) => {
                this.loading.DismissLoader();

                this._alertService.createAlert({
                    title: res.data.message,
                    icon: 'info',
                    timer: 5000,
                    showCloseButton: true,
                });
                this.nav.pop();
            })
            .catch((err) => {
                this.loading.DismissLoader();
                this._alertService.errorFromApi(err);
            });
    }

    dismiss() {
        this.modal.dismiss();
    }
}
