import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { DartCounterSpeechToScoreService } from '@dc-core/dc-services/speech-to-score/speech-to-score.service';
import { ModalController } from '@ionic/angular';
import { Subject, takeUntil } from 'rxjs';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { SpeechToScoreInfoDialogComponent } from 'src/dialogs/speech-to-score-info/speech-to-score-info.dialog';

@Component({
    selector: 'app-speech-bars',
    standalone: true,
    imports: [CommonModule],
    templateUrl: 'speech-bars.html',
    styleUrls: ['speech-bars.scss'],
})
export class SpeechBarsComponent implements OnInit {
    @Input() total = 8;
    numbers: number[];

    recognizing: boolean;
    processing: boolean;
    playingAudio: boolean;

    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _modal: ModalController,
        private _speechToScoreService: DartCounterSpeechToScoreService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.numbers = Array(this.total).fill(0);
    }

    ngOnInit(): void {
        this._speechToScoreService.recognizing$.pipe(takeUntil(this._unsubscribeAll)).subscribe((recognizing) => {
            this.recognizing = recognizing;
            this._changeDetectorRef.detectChanges();
        });
        this._speechToScoreService.processing$.pipe(takeUntil(this._unsubscribeAll)).subscribe((processing) => {
            this.processing = processing;
            this._changeDetectorRef.detectChanges();
        });
        this._speechToScoreService.playingAudio$.pipe(takeUntil(this._unsubscribeAll)).subscribe((playingAudio) => {
            this.playingAudio = playingAudio;
            this._changeDetectorRef.detectChanges();
        });
    }

    ionViewWillLeave(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    isListening(): boolean {
        return this.recognizing === true && this.processing === false && this.playingAudio === false;
    }

    showSpeechToScore(): void {
        this._modal
            .create({
                component: SpeechToScoreInfoDialogComponent,
            })
            .then((elem) => {
                elem.present();
            });
    }
}
