@if ((!team.tempSubtext?.text || !team.allowTempScores || team.busted) && team.subtext) {
    @if (team.subtext.outshot) {
        {{ team.subtext.text }}
    } @else if (!team.subtext.outshot) {
        <div
            class="truncate text-center"
            *ngIf="!team.tempSubtext?.text && team.subtext?.text && !team.subtext.outshot"
            [translate]="'NO_POSSIBLE_OUT'"
            i18n="@@NO_POSSIBLE_OUT">
            No outshot
        </div>
    }
} @else if (team.tempSubtext?.text && team.allowTempScores) {
    {{ team.tempSubtext.text }}
} @else if (!team.subtext && !team.tempSubtext) {
    &nbsp;
}
