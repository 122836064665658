<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@NAME_OF_PLAYER">Player name</ng-container>
}
<!-- End of translation containers-->

<div class="slide-modal-outer slide-modal-full bg-pattern">
    <div class="slide-modal-header">
        <div class="slide-modal-close" (click)="dismiss()" [translate]="'CANCEL'" i18n="@@CANCEL">Cancel</div>
        <div class="slide-modal-title" [translate]="'SELECT_YOUR_NAME'" i18n="@@SELECT_YOUR_NAME">Call your name</div>
    </div>

    <div class="slide-modal-content">
        <div class="flex flex-col overflow-y-auto flex-1">
            <div class="mx-4 mb-2 text-black text-center">
                @if (!relink) {
                    <div [translate]="'TEXT_LINK_SOUND'" i18n="@@TEXT_LINK_SOUND" class="text-sm">
                        Search for your name and link it to your account
                    </div>
                    @if (!searchLoading && hideImage) {
                        <div [translate]="'NAME_NOT_FOUND'" i18n="@@NAME_NOT_FOUND" (click)="contact()" class="text-xs">
                            Is your name not in the list? Contact us and we will add it!
                        </div>
                    }
                } @else {
                    <div [translate]="'TEXT_RELINK_SOUND'" i18n="@@TEXT_RELINK_SOUND">
                        Link your name once again for the online game
                    </div>
                }
            </div>

            @if (!hideImage) {
                <div class="teaser mx-5">
                    <img src="assets/images/ray.png" />
                </div>
            }

            <div class="p-4 pt-0">
                <div class="dialog-input with-icon">
                    <app-icon icon="search"></app-icon>
                    <input type="text" [formControl]="searchControl" placeholder="{{ 'NAME_OF_PLAYER' | translate }}" />
                </div>
            </div>

            @if (searchLoading) {
                <div class="flex">
                    <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-black"></app-icon>
                </div>
            }

            @if (sounds.length) {
                <div>
                    <div class="dialog-input-label mx-4" [translate]="'NAMES_FOUND'" i18n="@@NAMES_FOUND">
                        Names found
                    </div>
                    @for (sound of sounds; track sound) {
                        <div
                            class="scroll-list-item px-4 py-2 mx-4 text-black flex justify-between items-center cursor-pointer rounded-md"
                            [class.selected]="sound.id === selectedSound?.id"
                            (click)="selectSound(sound)">
                            <ion-label>{{ sound.name }}</ion-label>
                            @if (sound.id === selectedSound?.id) {
                                <ion-icon name="checkmark-outline" color="secondary" style="font-size: 20px"></ion-icon>
                            }
                        </div>
                    }
                </div>
            }
        </div>

        <div class="flex-none mt-auto pt-4">
            @if (user.is_ultimate && user.profile?.sound) {
                <app-primary-button
                    (click)="UnlinkSound()"
                    fill="clear"
                    color="black"
                    addedClasses="w-full"
                    size="small"
                    [translate]="'UNLINK_NAME'"
                    i18n="@@UNLINK_NAME">
                    Unlink name
                </app-primary-button>
            }
            <div class="px-4 pt-4">
                @if (!user.is_ultimate) {
                    <app-primary-button
                        size="normal"
                        [disabled]="!selectedSound"
                        addedClasses="w-full"
                        color="blue"
                        fill="full"
                        (click)="LinkSound(selectedSound)"
                        [translate]="'LINK_SOUND'"
                        i18n="@@LINK_SOUND">
                        Link name
                    </app-primary-button>
                } @else {
                    <app-primary-button
                        color="orange"
                        class="w-full"
                        [disabled]="!selectedSound"
                        [addedClasses]="'w-full'"
                        size="normal"
                        fill="full"
                        [translate]="'LINK_SOUND'"
                        i18n="@@LINK_SOUND"
                        (click)="LinkSound(selectedSound)">
                        Link name
                    </app-primary-button>
                }
            </div>
        </div>
    </div>
</div>
