<modal-content scrollY="false" class="{{ module?.theme }} bg-white-pattern text-black" fullscreen>
    <app-page-header mainNavigation="custom" color="white">
        <div title>{{ module?.title_key | translate }}</div>
        @if (!module?.is_persistent) {
            <app-icon icon="close" (click)="DismissModal()"></app-icon>
        }
    </app-page-header>

    <ion-row *ngIf="module?.desc_key" style="width: 90%; margin: 0 auto 10px">
        <ion-col class="text-center">{{ module?.desc_key | translate }}</ion-col>
    </ion-row>
    <!-- <ion-row class="features" style="width:90%; margin: 0 auto 20px;">
        
    </ion-row> -->
    <img
        *ngIf="image"
        [src]="image"
        (click)="DoAction(module?.image_action, module?.image_action_addon)"
        class="featimage" />

    <swiper-container
        (click)="DoAction(module?.image_action, module?.image_action_addon)"
        *ngIf="module?.image_urls?.length"
        [pagination]="true">
        <swiper-slide *ngFor="let image of module?.image_urls">
            <!-- <h5>1. {{'FEATURE_CALLER' | translate}}</h5> -->
            <img [src]="image" />
        </swiper-slide>
    </swiper-container>

    <ul *ngIf="summary?.length" class="summaryList">
        <li *ngFor="let summaryLine of summary">{{ summaryLine | translate }}</li>
    </ul>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div
            class="bottomCTA {{ module?.button_color }}"
            style="position: relative"
            (click)="DoAction(module?.button_action, module?.button_action_addon)">
            <p class="text-center" style="height: 72px">{{ module?.button_key | translate }}</p>
        </div>
    </ion-toolbar>
</ion-footer>
