import { Component, inject, OnInit } from '@angular/core';
import { EventOverlayService, OverlayText } from '@services/event-overlay.service';

@Component({
    selector: 'app-event-overlay',
    templateUrl: './event-overlay.component.html',
    styleUrls: ['./event-overlay.component.scss'],
})
export class EventOverlayComponent implements OnInit {
    isVisible: boolean = false;
    isHiding: boolean = false;
    texts: OverlayText[] = [];
    private overlayTimeout: any;

    protected eventOverlayService: EventOverlayService = inject(EventOverlayService);

    constructor() {}

    ngOnInit() {
        this.eventOverlayService.overlayStatus.subscribe(({ isVisible, texts, duration }) => {
            this.isVisible = isVisible;
            this.texts = texts;

            if (isVisible && duration > 0) {
                this.clearTimeout();
                const maxDelay = Math.max(...texts.map((text) => text.delay + text.duration));
                this.overlayTimeout = setTimeout(() => {
                    this.startHiding();
                }, duration || maxDelay); // Ensure overlay stays visible until the last animation is done
            } else {
                // Don't hide the overlay automatically - No delay given
            }
        });

        this.eventOverlayService.hideOverlayManually.subscribe(() => {
            this.clearTimeout();
            this.startHiding();
        });
    }

    private startHiding() {
        this.isHiding = true;
        const maxHideDuration = Math.max(...this.texts.map((text) => text.hideDuration || 0));
        setTimeout(() => {
            this.isVisible = false;
            this.isHiding = false;
        }, maxHideDuration);
    }

    private clearTimeout() {
        if (this.overlayTimeout) {
            clearTimeout(this.overlayTimeout);
            this.overlayTimeout = null;
        }
    }
}
