import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

/*
  Generated class for the AuthService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class DCLoadingService {
    public activeLoader: HTMLIonLoadingElement;

    constructor(private loadingController: LoadingController) {}

    async ShowDefaultLoader(timer = 0) {
        if (this.activeLoader) {
            this.activeLoader.dismiss();
        }

        this.activeLoader = await this.loadingController.create({
            // message: 'Please wait...',
            cssClass: ['dcOrange', 'z-200'],
            duration: timer,
            spinner: 'circular',
        });
        await this.activeLoader.present();
    }

    async ShowCustomLoader(options: LoadingOptions) {
        if (this.activeLoader) {
            this.activeLoader.dismiss();
        }

        if (!options.spinner) {
            options.spinner = 'circular';
        }

        this.activeLoader = await this.loadingController.create(options);
        await this.activeLoader.present();
    }

    DismissLoader() {
        if (this.activeLoader) {
            this.activeLoader.dismiss();
        } else {
            setTimeout(() => {
                this.DismissLoader();
            }, 50);
        }
    }

    create(options: LoadingOptions) {
        return this.loadingController.create(options);
    }
}
