import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-goal-amount-dialog',
    templateUrl: 'goalamount.dialog.html',
})
export class GoalAmountDialogComponent implements OnInit {
    @Input() goalAmount: any;

    private modalController: ModalController = inject(ModalController);
    private formBuilder: FormBuilder = inject(FormBuilder);

    public amountForm: FormGroup;

    public ngOnInit(): void {
        this.amountForm = this.formBuilder.group({
            goalAmount: [this.goalAmount, [Validators.required, Validators.min(1), Validators.max(30)]],
        });
    }

    public save(): void {
        const goalAmount = parseInt(this.amountForm.get('goalAmount').value, 10);
        this.modalController.dismiss(isNaN(goalAmount) ? null : goalAmount);
    }

    public dismiss(returnValue: any = null): void {
        if (returnValue !== null) {
            returnValue = Math.floor(returnValue);
        }
        this.modalController.dismiss(returnValue);
    }
}
