import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';

@Component({
    selector: 'app-agreement-dialog',
    templateUrl: 'agreement.html',
    styleUrls: ['agreement.scss'],
})
export class AgreementDialogComponent {
    public isIOS: boolean = null;

    constructor(
        public view: ModalController,
        private _platform: Platform,
        public preferenceService: DartCounterPreferenceService
    ) {
        if (this._platform.is('ios')) {
            this.isIOS = true;
        } else {
            this.isIOS = false;
        }
    }

    getLongName(): string {
        if (this.isIOS === true) {
            return `Apple's App Store`;
        } else if (this.isIOS === false) {
            return `Google's Play Store`;
        }
    }

    getShortName(): string {
        if (this.isIOS === true) {
            return `iTunes`;
        } else if (this.isIOS === false) {
            return `Play Store`;
        }
    }

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
