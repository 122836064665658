<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'REQUIRE_TRIPLE'" i18n="@@REQUIRE_TRIPLE">Require triple</div>
    </div>
    <div class="slide-modal-content">
        <form #form="ngForm" [formGroup]="amountForm" (ngSubmit)="amountForm.valid && save()">
            <div class="my-4">
                <div class="dialog-input-label" [translate]="'ENTER_REQUIRED_TRIPLE'" i18n="@@ENTER_REQUIRED_TRIPLE">
                    Enter required triple
                </div>
                <input
                    [ngClass]="{
                        '!border-red': form.submitted && amountForm.get('goalTriple').errors
                    }"
                    formControlName="goalTriple"
                    type="number"
                    inputmode="numeric"
                    min="1"
                    max="20"
                    class="dialog-input text-center" />
                @if (form.submitted && amountForm.get('goalTriple').errors) {
                    @if (range === 'one_to_seven') {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span
                                [translate]="'ERROR_REQUIRED_TRIPLE_ONE_SEVEN'"
                                i18n="@@ERROR_REQUIRED_TRIPLE_ONE_SEVEN">
                                The required triple needs to be between 1 and 7
                            </span>
                        </app-validation-label>
                    } @else {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span
                                [translate]="'ERROR_REQUIRED_TRIPLE_ONE_TWENTY'"
                                i18n="@@ERROR_REQUIRED_TRIPLE_ONE_TWENTY">
                                The required triple needs to be between 1 and 20
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
            <div class="button-container">
                <app-primary-button
                    type="submit"
                    size="normal"
                    addedClasses="w-full"
                    color="orange"
                    [translate]="'CONFIRM'"
                    i18n="@@CONFIRM">
                    Confirm
                </app-primary-button>
            </div>
        </form>
    </div>
</div>
