import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';

export const shanghaiGameRoutes: Route[] = [
    {
        path: 'game/shanghai',
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/shanghai-local-game/shanghai-local-game.component').then(
                (m) => m.ShanghaiLocalGameComponent
            ),
    },
];
