<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'LANGUAGE'" i18n="@@LANGUAGE">Language</div>
    </div>
    <div class="slide-modal-content">
        <div class="dialog-input-label" [translate]="'LANGUAGES'" i18n="@@LANGUAGES">Languages</div>
        @if (languages) {
            <div class="h-80 overflow-y-auto">
                @for (language of languages; track language) {
                    <div
                        [class.active]="language.key === lang"
                        (click)="changeLanguage(language.key)"
                        class="border-b-1 border-neutral-200 flex py-2 items-center px-1">
                        <div class="pr-3">
                            <img
                                class="flag"
                                style="width: 27px; height: 16px"
                                src="assets/images/flags/rect/{{ language.flag }}" />
                        </div>
                        <div>{{ language.title }}</div>
                    </div>
                }
                <div class="country"></div>
            </div>
        }

        <app-primary-button
            color="black"
            (click)="dismiss()"
            size="normal"
            addedClasses="w-full"
            class="w-full"
            [translate]="'SAVE'"
            i18n="@@SAVE">
            Save
        </app-primary-button>
    </div>
</div>
