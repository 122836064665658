/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    DestroyRef,
    inject,
    input,
    InputSignal,
    OnInit,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { ShortSmartDeviceType } from '@dc-core/dc-backend/dc-enums';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { AlertService } from '@dc-core/dc-services/alert.service';
import { CAMERA_TYPE } from '@dc-core/dc-services/camera/camera.models';
import { ConnectionStatus, OmniCommunicationService } from '@dc-core/dc-services/omni/omni-communication.service';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { LocalCameraService } from '@services/local-camera.service';
import { SmartDeviceService } from '@services/smart-device.service';
import { JanusStateService } from 'src/app/services/janus/janus-state.service';
import { OmniActivationDialogComponent } from 'src/dialogs/omni-activation-dialog/omni-activation.dialog';
import { environment } from 'src/environments/environment';
import { SmartDeviceStatus } from 'src/pages/online/online-games/online-games.component';

import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { OmniConnectionLostDialogComponent } from '../../dialogs/omni-connection-lost.dialog/omni-connection-lost.dialog';

@Component({
    selector: 'app-activate-smart-devices',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: 'activate-smart-devices.component.html',
})
export class ActivateSmartDevicesComponent implements OnInit {
    public collapsible: InputSignal<boolean> = input.required<boolean>();

    public omniIngameService: OmniIngameService = inject(OmniIngameService);
    public janusStateService: JanusStateService = inject(JanusStateService);

    private omniCommunicationService: OmniCommunicationService = inject(OmniCommunicationService);
    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);
    private alertService: AlertService = inject(AlertService);
    private modalController: ModalController = inject(ModalController);
    private lobbySettingsService: LobbySettingsCollectionService = inject(LobbySettingsCollectionService);
    private online: OnlineGamesService = inject(OnlineGamesService);
    private localCameraService: LocalCameraService = inject(LocalCameraService);
    private navController: NavController = inject(NavController);
    private smartDeviceService: SmartDeviceService = inject(SmartDeviceService);

    public collapsed: WritableSignal<boolean> = signal(true);
    public statusCount: Signal<number> = computed(() =>
        this.omniStatus() === SmartDeviceStatus.ACTIVATED && this.virtStatus() === SmartDeviceStatus.ACTIVATED
            ? 2
            : this.omniStatus() === SmartDeviceStatus.ACTIVATED || this.virtStatus() === SmartDeviceStatus.ACTIVATED
                ? 1
                : 0
    );
    public omniStatus: Signal<SmartDeviceStatus> = computed(() => this.smartDeviceService.omniStatus());
    public virtStatus: Signal<SmartDeviceStatus> = computed(() => this.smartDeviceService.virtStatus());
    readonly SmartDeviceStatus = SmartDeviceStatus;

    public isWeb: boolean = environment.isWeb;

    protected destroyRef: DestroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        this.smartDeviceApiService.hasOmni$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hasOmni) => {
            if (hasOmni) {
                if (!this.omniIngameService.omniConnected()) {
                    this.smartDeviceService.omniStatus.set(SmartDeviceStatus.OWNED);
                } else {
                    this.smartDeviceService.omniStatus.set(SmartDeviceStatus.ACTIVATED);
                }
            }
        });

        this.smartDeviceApiService.hasVirt$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hasVirt) => {
            if (hasVirt) {
                if (this.janusStateService.videoRoom()?.camType !== 'smart_device') {
                    this.smartDeviceService.virtStatus.set(SmartDeviceStatus.OWNED);
                } else {
                    this.smartDeviceService.virtStatus.set(SmartDeviceStatus.ACTIVATED);
                }
            }
        });
    }

    public toggleCollapse(): void {
        this.collapsed.set(!this.collapsed());
    }

    public activateOmni(): void {
        const networks = this.smartDeviceService.getNetworksByType(['omni_scoring']);
        if (!networks.totalDevices) {
            return;
        }

        if (!this.omniIngameService.omniConnected()) {
            if (networks.totalDevices === 1) {
                const connectionSub = this.omniCommunicationService.connectionStatus$
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe((connectionStatus) => {
                        if (connectionStatus === ConnectionStatus.CONNECTED) {
                            this.alertService.clearAlerts();
                            connectionSub.unsubscribe();

                            this.checkOmniConnectionStatus(connectionStatus);
                        } else if (connectionStatus === ConnectionStatus.DISCONNECTED) {
                            this.alertService.clearAlerts();
                            connectionSub.unsubscribe();

                            this.checkOmniConnectionStatus(connectionStatus);
                        }
                    });

                this.omniCommunicationService.loadAndConnect(networks.networks[0].devices[0]);
                return;
            }

            if (!environment.isWeb || (environment.isWeb && this.smartDeviceApiService.webSupportedOmnis().length)) {
                this.modalController
                    .create({
                        component: OmniActivationDialogComponent,
                        componentProps: {
                            startGameAfterConnection: false, //Dont start watching darts, as you're still in the lobby
                        },
                        cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                        backdropDismiss: true,
                    })
                    .then((elem) => {
                        elem.present();
                        elem.onDidDismiss().then((dialogRes) => {
                            const connectionStatus = dialogRes.data as ConnectionStatus;
                            if (connectionStatus) {
                                this.checkOmniConnectionStatus(connectionStatus);

                                if (this.lobbySettingsService.currentGameInLobby()) {
                                    this.online.updateLobbyGame();
                                }
                            }
                        });
                    });
            }
        }
    }

    public disableOmni(): void {
        this.alertService.createAlert({
            id: 'DISCONNECT_OMNI',
            title: $localize`:@@DISCONNECT_OMNI:Disconnect the Omni Scoring`,
            icon: 'info',
            timer: null,
            showCloseButton: true,
            confirmButtonText: $localize`:@@DISCONNECT:Disconnect`,
            onConfirm: () => {
                this.omniIngameService.disconnect(true);
                this.smartDeviceService.omniStatus.set(SmartDeviceStatus.OWNED);

                if (this.lobbySettingsService.currentGameInLobby()) {
                    this.online.updateLobbyGame();
                }
            },
        });
    }

    public activateVirt(): void {
        const networks = this.smartDeviceService.getNetworksByType(['virt_cam']);
        if (!networks.totalDevices) {
            return;
        }

        if (this.janusStateService.videoRoom()?.camType !== 'smart_device') {
            if (networks.totalDevices === 1) {
                this.localCameraService.linkCamera(true, CAMERA_TYPE.SMART_DEVICE, networks.networks[0].devices[0]);
                return;
            }

            this.localCameraService.linkCamera(true, CAMERA_TYPE.SMART_DEVICE);
        }
    }

    public disableVirt(): void {
        if (this.janusStateService.videoRoom()?.camType === 'smart_device') {
            this.localCameraService.unlinkCamera(true);
        }
    }

    public setupSmartDevice(setup: ShortSmartDeviceType): void {
        this.navController.navigateForward('smart-devices', {
            queryParams: {
                setup,
            },
        });
    }

    private checkOmniConnectionStatus(connectionStatus: ConnectionStatus): void {
        if (connectionStatus === ConnectionStatus.CONNECTED) {
            this.smartDeviceService.omniStatus.set(SmartDeviceStatus.ACTIVATED);
        } else {
            this.modalController
                .create({
                    component: OmniConnectionLostDialogComponent,
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                    showBackdrop: true,
                    backdropDismiss: true,
                })
                .then((elem) => {
                    elem.present();
                });
        }
    }
}
