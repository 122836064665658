import { Component, input, InputSignal, OnDestroy, OnInit, output, OutputEmitterRef } from '@angular/core';
import { TournamentHelper } from '@dc-core/dc-helpers/tournament.helper';

import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-tournament-timer',
    templateUrl: 'tournament-timer.component.html',
    standalone: true,
    imports: [IconComponent],
})
export class TournamentTimerComponent implements OnInit, OnDestroy {
    public timerDate: InputSignal<string> = input.required();
    public timerType: InputSignal<'from' | 'until'> = input('until');
    public hideTimerWhenExpired: InputSignal<boolean> = input.required();

    public timerExpired: OutputEmitterRef<void> = output<void>();

    public timeFromDate: string = undefined;
    public hasTimer: boolean;

    public ngOnInit(): void {
        this.hasTimer = true;
        this.setTimer();
    }

    public ngOnDestroy(): void {
        this.hasTimer = false;
    }

    public setTimer(): void {
        if (!this.hasTimer) {
            return;
        }

        if (this.timeFromDate === '' || this.timeFromDate === '0s') {
            this.timerExpired.emit();
            this.hasTimer = false;
            this.timeFromDate = null;
            return;
        }

        this.timeFromDate = this.timerDate()
            ? TournamentHelper.timerFromDate(this.timerDate(), this.timerType())
            : null;
        setTimeout(() => {
            this.setTimer();
        }, 1000);
    }
}
