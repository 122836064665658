import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';
import { InGameKeyboardType } from '@dc-core/dc-gamelogic/game-interfaces';
import { TeamSwiperStat } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import {
    DartCounterMatchTeam,
    DartCounterPlayingMatch,
} from '@dc-core/dc-gamelogic/in-game/match/match.in-game.classes';
import { TranslateModule } from '@ngx-translate/core';
import { MatchBigSwiperStatsComponent } from 'src/app/match-big-swiper-stats/match-big-swiper-stats.component';
import { MatchTeamSwiperStatsComponent } from 'src/app/match-team-swiper-stats/match-team-swiper-stats.component';
import { RemainingScoreComponent } from 'src/app/remaining-score/remaining-score.component';

@Component({
    selector: 'app-match-team-score',
    standalone: true,
    imports: [
        CommonModule,
        MatchTeamSwiperStatsComponent,
        MatchBigSwiperStatsComponent,
        RemainingScoreComponent,
        TranslateModule,
    ],
    templateUrl: './match-team-score.component.html',
})
export class MatchTeamScoreComponent {
    public currentGame: InputSignal<DartCounterPlayingMatch> = input.required<DartCounterPlayingMatch>();
    public team: InputSignal<DartCounterMatchTeam> = input.required<DartCounterMatchTeam>();
    public currentTeam: InputSignal<DartCounterMatchTeam> = input<DartCounterMatchTeam>();
    public keyboard: InputSignal<InGameKeyboardType> = input<InGameKeyboardType>();
    public teamSwiperStats: InputSignal<TeamSwiperStat> = input<TeamSwiperStat>();
    public fullHeight: InputSignal<boolean> = input<boolean>(false);
    public bigSwiper: InputSignal<boolean> = input<boolean>(false);
    public smallLayout: InputSignal<boolean> = input<boolean>(false);
    public dualStream: InputSignal<boolean> = input<boolean>(false);
}
