<div
    class="flex h-full flex-col divide-y divide-white border-white text-black portrait:border-b"
    appWebKeyboardContainer>
    @if (currentSingleDartEvent) {
        <div
            class="text-white bg-neutral-900 dark:bg-neutral-50 p-3 w-full mx-auto flex flex-col items-center justify-center">
            <div class="flex flex-col items-center">
                <div class="text-center text-medium font-bold">
                    @if (currentSingleDartEvent === 'RemoveDarts') {
                        <span [translate]="'REMOVE_YOUR_DARTS'" i18n="@@REMOVE_YOUR_DARTS">Remove your darts </span>
                    } @else if (currentSingleDartEvent === 'BustedScore') {
                        <span [translate]="'BUSTED_SCORE'" i18n="@@BUSTED_SCORE">You have bust your score </span>
                    } @else if (currentSingleDartEvent === 'DoubleOutError') {
                        <span [translate]="'NEED_END_WITH_DOUBLE'" i18n="@@NEED_END_WITH_DOUBLE"
                            >You need to finish with a double
                        </span>
                    } @else if (currentSingleDartEvent === 'MasterOutError') {
                        <span [translate]="'NEED_END_WITH_DOUBLE_OR_TRIPLE'" i18n="@@NEED_END_WITH_DOUBLE_OR_TRIPLE"
                            >You need to finish with a double or triple
                        </span>
                    }
                </div>
            </div>
            @if (currentSingleDartEvent !== 'RemoveDarts') {
                <div class="flex justify-between">
                    <app-primary-button
                        size="extrasmall"
                        class="w-full"
                        (click)="emitConfirmSingleDarts()"
                        [addedClasses]="'px-2 w-full'"
                        [color]="'orange'"
                        [translate]="'CONFIRM'"
                        i18n="@@CONFIRM"
                        >Confirm
                    </app-primary-button>
                </div>
            }
        </div>
    }

    <div
        class="flex flex-1 bg-white dark:bg-black bg-opacity-50 text-sm uppercase landscape:text-xs landscape:md:text-sm landscape:lg:text-base">
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            [class]="multiplier === 1 ? 'selectedMultiplier' : ''"
            class="grid flex-1 items-center text-center font-bold cursor-pointer"
            (click)="selectMultiplier(1)">
            Single
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            [class]="multiplier === 2 ? 'selectedMultiplier' : ''"
            class="grid flex-1 items-center text-center font-bold cursor-pointer"
            (click)="selectMultiplier(2)">
            Double
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            [class]="multiplier === 3 ? 'selectedMultiplier' : ''"
            class="grid flex-1 items-center text-center font-bold cursor-pointer"
            (click)="selectMultiplier(3)">
            Treble
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="flex flex-1 flex-col items-center justify-center text-center cursor-pointer"
            (click)="selectAmount(25, 2)">
            <div class="font-bold leading-6">Bull</div>
            <div
                class="font-open-sans text-sm text-neutral-800 landscape:text-xs landscape:md:text-sm landscape:lg:text-base">
                (50)
            </div>
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="flex flex-1 flex-col items-center justify-center text-center cursor-pointer"
            (click)="selectAmount(25, 1)">
            <div class="font-bold leading-6">Outer</div>
            <div
                class="font-open-sans text-sm text-neutral-800 landscape:text-xs landscape:md:text-sm landscape:lg:text-base">
                (25)
            </div>
        </button>
    </div>
    <div class="flex flex-1 font-bebas-neue text-2xl">
        <ng-container *ngTemplateOutlet="numberRow; context: { add: 0 }"></ng-container>
    </div>
    <div class="flex flex-1 font-bebas-neue text-2xl">
        <ng-container *ngTemplateOutlet="numberRow; context: { add: 5 }"></ng-container>
    </div>
    <div class="flex flex-1 font-bebas-neue text-2xl">
        <ng-container *ngTemplateOutlet="numberRow; context: { add: 10 }"></ng-container>
    </div>
    <div class="flex flex-1 font-bebas-neue text-2xl">
        <ng-container *ngTemplateOutlet="numberRow; context: { add: 15 }"></ng-container>
    </div>
    <div class="flex flex-1 grid grid-cols-5 font-bebas-neue text-2xl">
        @if (undoScore) {
            <button
                type="button"
                data-twe-ripple-init
                data-twe-ripple-color="light"
                data-twe-ripple-centered="true"
                class="relative col-span-2 flex-1 text-center"
                (click)="emitUndoScore()">
                <div class="inline-flex h-7 w-7 items-center justify-center rounded-full cursor-pointer">
                    <app-icon class="h-8 w-8" icon="undo_outline" class="text-black"></app-icon>
                </div>
            </button>
        } @else {
            <div class="flex-1 text-center col-span-2"></div>
        }
        <button
            class="col-span-1"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="grid flex-1 items-center border-solid border-x border-white text-center cursor-pointer text-black"
            (click)="selectAmount(0, 0)"
            [translate]="'MISS'"
            i18n="@@MISS">
            Miss
        </button>
        <div class="relative flex-1 text-center col-span-2"></div>
    </div>
</div>

<ng-template #numberRow let-add="add">
    @for (num of [1, 2, 3, 4, 5]; track $index) {
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="flex flex-1 flex-col items-center justify-center text-center cursor-pointer border-solid"
            [class.border-white]="num > 1 && num < 5"
            [class.border-l]="num === 2"
            [class.border-x]="num === 3"
            [class.border-r]="num === 4"
            (click)="selectAmount(num + add)">
            <div class="leading-6">{{ num + add }}</div>

            @if (multiplier > 1) {
                <div
                    class="font-open-sans text-sm text-neutral-800 landscape:text-xs landscape:md:text-sm landscape:lg:text-base">
                    ({{ (num + add) * multiplier }})
                </div>
            }
        </button>
    }
</ng-template>
