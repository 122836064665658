import { Platform } from '../dc-backend/dc-enums';
import { StoreCricketTacticsRequest, StoreMatchRequest } from '../dc-backend/dc-requests';
import _ from 'lodash';
import { DartCounterCricketTacticsGame } from './in-game/cricket-tactics/cricket-tactics.in-game.classes';
import { DartCounterPlayingMatch } from './in-game/match/match.in-game.classes';

export class GameFunctions {
    constructor() {}

    public static getStoreMatchRequest(platform: Platform, match: DartCounterPlayingMatch): StoreMatchRequest {
        match.teams.forEach((team) => {
            team.users.forEach((user) => {
                user.total_score_legs = team.total_score_legs;
                user.total_score_sets = team.total_score_sets;
                user.first_nine_average = team.first_nine_average;
                user.result = team.result;
            });
        });

        let users = null;
        if (!match.has_teams) {
            users = [];
            match.teams.forEach((team) => {
                team.users.forEach((user) => {
                    users.push(user);
                });
            });
        }

        return {
            platform,
            firebase_id: match.firebase_id,
            start_score: match.start_score,
            has_teams: match.has_teams,
            is_best_of: match.is_best_of,
            has_sets: match.has_sets,
            goal_amount: match.goal_amount,
            vs_cpu: match.vs_cpu,
            has_checkout_rate: match.has_checkout_rate,
            two_legs_difference: match.two_legs_difference,
            enable_sudden_death: match.enable_sudden_death,
            start_with: match.start_with,
            end_with: match.end_with,
            is_online: match.is_online,
            started_at: match.started_at,
            finished_at: match.finished_at,
            teams: match.has_teams ? match.teams : [],
            users: !match.has_teams ? users : [],
            sets: match.has_sets ? match.sets : [],
            legs: !match.has_sets ? _.last(match.sets).legs : [],
        } as StoreMatchRequest;
    }

    public static getStoreCricketTacticsRequest(
        platform: Platform,
        cricketTactics: DartCounterCricketTacticsGame
    ): StoreCricketTacticsRequest {
        cricketTactics.teams.forEach((team) => {
            team.users.forEach((user) => {
                user.total_score_legs = team.total_score_legs;
                user.total_score_sets = team.total_score_sets;
                user.result = team.result;
            });
        });

        let users = null;
        if (!cricketTactics.has_teams) {
            users = [];
            cricketTactics.teams.forEach((team) => {
                team.users.forEach((user) => {
                    users.push(user);
                });
            });
        }

        return {
            platform,
            firebase_id: cricketTactics.firebase_id,
            has_teams: cricketTactics.has_teams,
            settings: cricketTactics.settings,
            is_best_of: cricketTactics.is_best_of,
            has_sets: cricketTactics.has_sets,
            goal_amount: cricketTactics.goal_amount,
            vs_cpu: cricketTactics.vs_cpu,
            cpu_level: cricketTactics.cpu_level,
            two_legs_difference: cricketTactics.two_legs_difference,
            enable_sudden_death: cricketTactics.enable_sudden_death,
            is_cut_throat: cricketTactics.is_cut_throat,
            is_online: cricketTactics.is_online,
            started_at: cricketTactics.started_at,
            finished_at: cricketTactics.finished_at,
            teams: cricketTactics.has_teams ? cricketTactics.teams : [],
            users: !cricketTactics.has_teams ? users : [],
            sets: cricketTactics.has_sets ? cricketTactics.sets : [],
            legs: !cricketTactics.has_sets ? _.last(cricketTactics.sets).legs : [],
            with_sets_and_legs: true,
        } as StoreCricketTacticsRequest;
    }
}
