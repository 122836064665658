import { Injectable } from '@angular/core';
import { Match, CricketTactics, MatchTeam, CricketTacticsTeam } from '../../dc-backend/dc-classes';
import { OnlineGameplay, PublicOnlineGameplay } from '../../dc-backend/dc-interfaces';
import { COUNTRIES } from '../../dc-firestore/globals/countries.globals';
import { DCFireStoreUser } from '../../dc-firestore/globals/firestore.tables';
import _ from 'lodash';
import { OnlineUser } from './online.ingame.globals';

@Injectable()
export class OnlineFunctions {
    constructor() {}

    // Gameplay helper
    public asMatch(val): Match {
        return val;
    }

    public asCricketTactics(val): CricketTactics {
        return val;
    }

    // Gameplay helper
    public onlineGameplayName(gameplay: OnlineGameplay): string {
        const name = [];
        switch (gameplay.type) {
            case 'match':
                const match = gameplay.game as Match;
                if (!match) {
                    return '';
                }
                if (match.is_best_of) {
                    name.push($localize`:@@BEST_OF:Best of`);
                } else {
                    name.push($localize`:@@FIRST_TO:First to`);
                }
                name.push(match.goal_amount);
                if (match.has_sets) {
                    name.push($localize`:@@SETS:Sets`);
                } else {
                    name.push($localize`:@@LEGS:Legs`);
                }
                return name.join(' ') + ' - ' + match.start_score;
            case 'cricket_tactics':
                const cricketTactics = gameplay.game as CricketTactics;
                if (!cricketTactics) {
                    return '';
                }
                if (cricketTactics.is_best_of) {
                    name.push($localize`:@@BEST_OF:Best of`);
                } else {
                    name.push($localize`:@@FIRST_TO:First to`);
                }
                name.push(cricketTactics.goal_amount);
                if (cricketTactics.has_sets) {
                    name.push($localize`:@@SETS:Sets`);
                } else {
                    name.push($localize`:@@LEGS:Legs`);
                }
                return (
                    ((gameplay.game as CricketTactics).settings === 'cricket'
                        ? $localize`:@@CRICKET:Cricket`
                        : $localize`:@@TACTICS:Tactics`) +
                    ' - ' +
                    name.join(' ')
                );
        }
    }

    public getInterim(gameplay: OnlineGameplay): string {
        switch (gameplay.type) {
            case 'match':
                const match = this.asMatch(gameplay.game);
                if (match.teams && match.teams[0] && match.teams[1]) {
                    if (match.has_sets) {
                        return match.teams[0].total_score_sets + ' - ' + match.teams[1].total_score_sets;
                    } else {
                        return match.teams[0].total_score_legs + ' - ' + match.teams[1].total_score_legs;
                    }
                }
                return '';
            case 'cricket_tactics':
                const cricketTactics = this.asCricketTactics(gameplay.game);
                if (cricketTactics.teams && cricketTactics.teams[0] && cricketTactics.teams[1]) {
                    if (cricketTactics.has_sets) {
                        return (
                            cricketTactics.teams[0].total_score_sets + ' - ' + cricketTactics.teams[1].total_score_sets
                        );
                    } else {
                        return (
                            cricketTactics.teams[0].total_score_legs + ' - ' + cricketTactics.teams[1].total_score_legs
                        );
                    }
                }
                return '';
        }
    }

    public getInterimType(gameplay: OnlineGameplay): 'sets' | 'legs' {
        switch (gameplay.type) {
            case 'match':
                const match = this.asMatch(gameplay.game);
                if (match.has_sets) {
                    return 'sets';
                }
                return 'legs';
            case 'cricket_tactics':
                const cricketTactics = this.asCricketTactics(gameplay.game);
                if (cricketTactics.has_sets) {
                    return 'sets';
                }
                return 'legs';
        }
    }

    public getInterimHasSets(gameplay: OnlineGameplay): boolean {
        switch (gameplay.type) {
            case 'match':
                const match = this.asMatch(gameplay.game);
                if (match.has_sets) {
                    return true;
                }
                return false;
            case 'cricket_tactics':
                const cricketTactics = this.asCricketTactics(gameplay.game);
                if (cricketTactics.has_sets) {
                    return true;
                }
                return false;
        }
    }

    // PUBLIC Gameplay helpers
    public publicOnlineGameplayName(gameplay: PublicOnlineGameplay): string {
        const name = [];
        if (gameplay.is_best_of) {
            name.push($localize`:@@BEST_OF:Best of`);
        } else {
            name.push($localize`:@@FIRST_TO:First to`);
        }
        name.push(gameplay.goal_amount);
        if (gameplay.has_sets) {
            name.push($localize`:@@SETS:Sets`);
        } else {
            name.push($localize`:@@LEGS:Legs`);
        }

        switch (gameplay.type) {
            case 'match':
                return name.join(' ') + ' - ' + gameplay.start_score;
            case 'cricket_tactics':
                return (
                    (gameplay.settings === 'cricket' ? $localize`:@@CRICKET:Cricket` : $localize`:@@TACTICS:Tactics`) +
                    ' - ' +
                    name.join(' ')
                );
        }
    }

    public getPublicInterim(gameplay: PublicOnlineGameplay): string {
        if (!gameplay?.player_interims?.length) {
            return '-';
        }

        if (gameplay.has_sets) {
            return gameplay.player_interims[0].sets + ' - ' + gameplay.player_interims[1].sets;
        } else {
            return gameplay.player_interims[0].legs + ' - ' + gameplay.player_interims[1].legs;
        }
    }

    fsUserToOnlineUser(user: DCFireStoreUser): OnlineUser {
        return {
            id: user.user_id || (user as any).id,
            first_name: user.first_name,
            last_name: user.last_name,
            full_name: user.full_name,
            is_ultimate: user.is_ultimate,
            countryFlag: user.countryFlag,
            profile: {
                user_id: user.user_id,
                nickname: user.nickname,
                badge: user.badge,
                profile_photo_url: user.profile_photo_url,
                sound: {
                    file: user.sound,
                },
                has_checkout_rate: user.has_checkout_rate,
            },
            times_rated: user.times_rated || null,
            two_month_average: user.two_month_average || null,
            last_results: user.last_results || [],
        };
    }

    getFlag(abbr: string): string {
        let flag = null;
        if (abbr) {
            const country = _.find(COUNTRIES, (country) => country.alpha2 === abbr);
            if (country) {
                flag = country.flag;
            }
        }

        return flag;
    }

    setTeams(gameplay: OnlineGameplay): void {
        switch (gameplay.type) {
            case 'match':
                this.setMatchTeams(gameplay);
                break;
            case 'cricket_tactics':
                this.setCricketTacticsTeams(gameplay);
                break;
        }
    }

    setMatchTeams(gameplay: OnlineGameplay) {
        if (!(gameplay.game as Match).teams.length) {
            let id = 0;
            gameplay.game.teams = [];
            gameplay.players.forEach((player: DCFireStoreUser) => {
                id++;
                const team = {
                    id,
                    match_id: 0,
                    users: [
                        {
                            id,
                            match_id: 0,
                            name: player.full_name,
                            user_id: player.user_id,
                            user: this.fsUserToOnlineUser(player),
                            team_id: id,
                            has_checkout_rate: player.has_checkout_rate,
                            is_verified: true,
                        },
                    ],
                    total_score: 0,
                    darts_thrown: 0,
                    has_checkout_rate: player.has_checkout_rate,
                    total_score_legs: 0,
                    total_score_sets: 0,
                } as MatchTeam;
                (gameplay.game as Match).teams.push(team);
            });
        }
    }

    setCricketTacticsTeams(gameplay: OnlineGameplay) {
        if (!(gameplay.game as CricketTactics).teams?.length) {
            let id = 0;
            gameplay.game.teams = [];
            gameplay.players.forEach((player: DCFireStoreUser) => {
                id++;
                const team = {
                    id,
                    cricket_tactics_id: 0,
                    users: [
                        {
                            id,
                            cricket_tactics_id: 0,
                            name: player.full_name,
                            user_id: player.user_id,
                            user: this.fsUserToOnlineUser(player),
                            team_id: id,
                            is_verified: true,
                        },
                    ],
                    total_score: 0,
                    darts_thrown: 0,
                    total_score_legs: 0,
                    total_score_sets: 0,
                } as CricketTacticsTeam;
                (gameplay.game as CricketTactics).teams.push(team);
            });
        }
    }
}
