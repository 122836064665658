import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { InAppReview } from '@capacitor-community/in-app-review';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { PrimaryButtonComponent } from '../../app/primary-button/primary-button.component';
import { DialogViewComponent } from '../../app/shared/components/dialog/dialog-view/dialog-view.component';

@Component({
    selector: 'app-rate-app-dialog',
    standalone: true,
    templateUrl: 'rate-app.dialog.html',
    imports: [CommonModule, PrimaryButtonComponent, DialogViewComponent, TranslateModule],
})
export class RateAppDialogDialogComponent {
    public slide: 'start' | 'no' | 'yes' = 'start';

    private modalController: ModalController = inject(ModalController);
    private navController: NavController = inject(NavController);

    translated(tag: string): string {
        switch (tag) {
            case 'DO_YOU_LIKE':
                return $localize`:@@DO_YOU_LIKE:Do you like` + ' ' + 'DartCounter?';
            case 'RATE_APP':
                return $localize`:@@RATE_APP:Rate` + ' ' + 'DartCounter';
        }
    }

    public dismiss(returnValue: 'feedback' | 'rate' = null): void {
        if (returnValue === 'feedback') {
            this.navController.navigateForward('contact');
        } else if (returnValue === 'rate') {
            InAppReview.requestReview();
        }

        this.modalController.dismiss(returnValue);
    }
}
