import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profilePopover.component.html',
  styleUrls: ['profilePopover.scss'],
})
export class ProfilePopoverComponent {
  public isBlocked = false;
  public friendStatus: string;

  constructor(private popover: PopoverController) {}

  ClosePopover(res = null) {
    this.popover.dismiss(res);
  }
}
