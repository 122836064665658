@if (onlineCore.pause) {
    <div class="mt-6 flex h-full flex-col items-center p-4">
        @if (!isSpectator()) {
            <div
                class="font-bebas-neue text-2xl text-black"
                [translate]="'BREAK_IN_PROGRESS'"
                i18n="@@BREAK_IN_PROGRESS">
                You've got a break
            </div>
        }

        <div
            class="mt-2 flex w-fit items-center space-x-0.5 rounded-sm border-2 border-orange bg-white px-2 py-1.5 pr-5 text-black">
            <app-icon icon="pause" class="text-black"></app-icon>
            <div class="pt-0.5 font-bebas-neue text-lg leading-none">
                <strong class="text-xl leading-none"> {{ onlineCore.getPauseTimerValue() }}</strong>
            </div>
        </div>
        @if (!isSpectator()) {
            <div class="mt-8 flex w-full flex-col items-center">
                @if (onlineCore.isReadyToContinue && onlineCore.pausePlayersReady !== onlineCore.pause.players.length) {
                    <div
                        class="text-center font-bebas-neue text-2xl text-black"
                        [translate]="'WAITING_FOR_OPPONENT'"
                        i18n="@@WAITING_FOR_OPPONENT">
                        Waiting for opponent
                    </div>
                }
                @if (!onlineCore.isReadyToContinue && onlineCore.pausePlayersReady > 0) {
                    <div
                        class="text-center font-bebas-neue text-2xl text-black"
                        [translate]="'OPPONENT_IS_READY'"
                        i18n="@@OPPONENT_IS_READY">
                        Opponent is ready
                    </div>
                }

                <app-primary-button
                    class="mt-2 block w-full"
                    size="large"
                    (click)="onlineCore.readyToContinue(authenticatedUserUUID)"
                    [disabled]="
                        onlineCore.isReadyToContinue && onlineCore.pausePlayersReady !== onlineCore.pause.players.length
                    ">
                    I'm ready
                </app-primary-button>
            </div>
        }
    </div>
}
