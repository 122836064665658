import { inject, Injectable, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { PrivateChatApiService } from '@dc-api/private-chat.api.service';
import { PausesCollectionService } from '@dc-core/dc-firestore/collection-helpers/pauses.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { DCOnlineCore } from '@dc-core/dc-gamelogic/online-core.functions';
import { AlertPayload, AlertService } from '@dc-core/dc-services/alert.service';
import { ModalController } from '@ionic/angular/standalone';
import { SmartDeviceService } from '@services/smart-device.service';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';

import { TournamentLogicService } from '../modules/tournaments/services/tournament-logic.service';
import { JanusPublisherService } from './janus/janus-publisher.service';
import { JanusServerService } from './janus/janus-server.service';
import { JanusStateService } from './janus/janus-state.service';
import { JanusUserMediaService } from './janus/janus-user-media.service';

@Injectable({ providedIn: 'root' })
export class AppStateService {
    private onlineCore: DCOnlineCore = inject(DCOnlineCore);
    private janusStateService: JanusStateService = inject(JanusStateService);
    private janusPublisherService: JanusPublisherService = inject(JanusPublisherService);
    private janusServerService: JanusServerService = inject(JanusServerService);
    private ngZone: NgZone = inject(NgZone);
    private usersCollectionService: UsersCollectionService = inject(UsersCollectionService);
    private smartDeviceService: SmartDeviceService = inject(SmartDeviceService);
    private privateChatApiService: PrivateChatApiService = inject(PrivateChatApiService);
    private tournamentLogicService: TournamentLogicService = inject(TournamentLogicService);
    private alertService: AlertService = inject(AlertService);
    private modalController: ModalController = inject(ModalController);
    private janusUserMediaService: JanusUserMediaService = inject(JanusUserMediaService);
    private pausesCollectionService: PausesCollectionService = inject(PausesCollectionService);

    public currentAppState: boolean = true;
    public latestNetworkStatus: ConnectionStatus;
    public networkListener: PluginListenerHandle;
    public splashScreenIsHidden = false;

    private networkDialog: HTMLIonModalElement = null;

    public listenToStateChanges(): void {
        App.addListener('appStateChange', ({ isActive }) => {
            // console.log('*** appStateChange', isActive);
            if (this.currentAppState === false && isActive === true) {
                this.onlineCore.isAway = false;

                this.janusServerService.reconnectServer(null);

                if (this.onlineCore.onlineGameplay) {
                    this.ngZone.run(() => {
                        if (this.onlineCore.pause()) {
                            this.onlineCore.initPauseTimer();
                        } else {
                            this.onlineCore.pauseTimer(); // Always pause the timer when returning back to prevent accidental timeouts from being actioned
                            this.onlineCore.setTimeLeft(true); // Set time left when returning back to the app
                            if (this.onlineCore.timeLeft <= 0) {
                                // Check if an existing pause is happening, else start the timer again
                                this.pausesCollectionService
                                    .setInitialPauses()
                                    .then((pause) => {
                                        if (!pause) this.onlineCore.startTimer(null);
                                    })
                                    .catch(() => this.onlineCore.startTimer(null));
                            } else {
                                // Start timer when time left is above 0
                                this.onlineCore.startTimer(null);
                            }
                        }
                    });
                }

                if (!this.janusStateService.isExternal) {
                    this.usersCollectionService.updateLastActive();
                }

                if (this.smartDeviceService.isSettingUpSmartDevice) {
                    this.smartDeviceService.refreshSetup$.next();
                }

                this.privateChatApiService.refreshChat$.next();

                this.tournamentLogicService.refreshTournament$.next();

                this.startNetworkListener();
            } else if (!isActive) {
                this.onlineCore.isAway = true;

                if (this.networkListener) {
                    this.networkListener.remove();
                    this.networkListener = null;
                }

                this.janusStateService.videoUserMedia.update((userMedia) => ({
                    ...this.janusUserMediaService.cleanupUserMedia(userMedia, true, true),
                }));

                if (this.smartDeviceService.userMedia().janusRoom.roomID) {
                    this.smartDeviceService.userMedia.update((userMedia) => ({
                        ...this.janusUserMediaService.cleanupUserMedia(userMedia, true, true),
                    }));
                }

                if (this.janusStateService.videoPublisher().isPublishing) {
                    this.janusPublisherService.stopOwnPublisher();
                }

                if (this.janusStateService.usingExternal) {
                    this.janusStateService.detachAllExternalPublisherHandles().catch(console.error);
                }

                if (!this.janusStateService.isExternal) {
                    this.usersCollectionService.setOffline();
                }
            }

            this.currentAppState = isActive;
        });
    }

    public async startNetworkListener(): Promise<void> {
        if (this.networkListener) {
            return;
        }

        this.networkListener = await Network.addListener('networkStatusChange', (status) => {
            // console.log('*** networkStatusChange', status);
            if (this.latestNetworkStatus.connected !== status.connected) {
                this.latestNetworkStatus = status;

                if (status.connected) {
                    this.janusServerService.reconnectServer(null);

                    if (this.networkDialog) {
                        this.networkDialog.dismiss();
                        this.networkDialog = null;
                    }
                } else {
                    this.janusServerService.destroyServer(null, null, false);

                    if (this.alertService.showNetworkAlert && !this.networkDialog) {
                        this.modalController
                            .create({
                                component: PromptDialogComponent,
                                componentProps: {
                                    title: $localize`:@@NO_CONNECTION:No connection`,
                                    text: $localize`:@@PLEASE_CHECK_YOUR_INTERNET:Please check your internet connection`,
                                    icon: 'error',
                                    confirmText: $localize`:@@OK:OK`,
                                } as AlertPayload,
                                cssClass: 'auto-height',
                                showBackdrop: true,
                                backdropDismiss: false,
                            })
                            .then((elem) => {
                                this.networkDialog = elem;
                                this.networkDialog.present();
                                this.networkDialog.onDidDismiss().then(() => {
                                    this.networkDialog = null;
                                });
                            });
                    }
                }
            }
        });
    }
}
