import convert from 'xml-js';
import ElementTypes12 from './inline-elements/ElementTypes12.js';
import { extractValue } from './xml-js/xmlToObject.js';
var xliff12ToJsClb = function xliff12ToJsClb(str, options, cb) {
  if (typeof options === 'function') {
    cb = options;
    options = {};
  }
  options = options || {};
  if (typeof str !== 'string') {
    var err = new Error('The first parameter was not a string');
    if (cb) return cb(err);
    return err;
  }
  var result = {};
  var xmlObj;
  try {
    xmlObj = convert.xml2js(str, {});
  } catch (err) {
    if (cb) return cb(err);
    return err;
  }
  var xliffRoot = xmlObj.elements.find(function (ele) {
    return ele.name === 'xliff';
  });
  var srcLang = xliffRoot.elements[0].attributes['source-language'];
  var trgLang = xliffRoot.elements[0].attributes['target-language'];
  result.sourceLanguage = srcLang;
  result.targetLanguage = trgLang;
  if (!result.targetLanguage) delete result.targetLanguage;
  result.resources = xliffRoot.elements.reduce(function (resources, file) {
    var namespace = options.namespace || file.attributes.original;
    var body = file.elements.find(function (e) {
      return e.name === 'body';
    });
    body.elements = body.elements || [];
    var bodyChildren = body.elements.filter(function (child) {
      return child.type !== 'comment';
    });
    resources[namespace] = createUnits(bodyChildren);
    return resources;
  }, {});
  if (cb) return cb(null, result);
  return result;
};
function createUnits(childElements) {
  return childElements.reduce(function (parent, child) {
    var key = child.attributes.id;
    var children = child.elements.filter(function (e) {
      return e.name === 'trans-unit' || e.name === 'group';
    });
    if (children.length) {
      parent[key] = createGroupTag(child, children);
    } else {
      parent[key] = createTransUnitTag(child);
    }
    return parent;
  }, {});
}
function createTransUnitTag(transUnit) {
  var jsUnit = transUnit.elements.reduce(function (unit, element) {
    switch (element.name) {
      case 'source':
      case 'target':
      case 'note':
        unit[element.name] = extractValue(element.elements, ElementTypes12);
        break;
    }
    return unit;
  }, {
    source: ''
  });
  return addAdditionalAttributes(jsUnit, transUnit.attributes);
}
function createGroupTag(groupUnit, children) {
  var jsGroupUnit = {
    groupUnits: createUnits(children)
  };
  return addAdditionalAttributes(jsGroupUnit, groupUnit.attributes);
}
function addAdditionalAttributes(jsUnit, attributes) {
  var additionalAttributes = attributes;
  delete additionalAttributes.id;
  if (Object.keys(additionalAttributes).length) {
    Object.assign(jsUnit, {
      additionalAttributes: additionalAttributes
    });
  }
  return jsUnit;
}
export default function xliff12ToJs(str, options, cb) {
  if (!cb && options === undefined) {
    return new Promise(function (resolve, reject) {
      return xliff12ToJsClb(str, options, function (err, ret) {
        return err ? reject(err) : resolve(ret);
      });
    });
  }
  if (!cb && typeof options !== 'function') {
    return new Promise(function (resolve, reject) {
      return xliff12ToJsClb(str, options, function (err, ret) {
        return err ? reject(err) : resolve(ret);
      });
    });
  }
  xliff12ToJsClb(str, options, cb);
}