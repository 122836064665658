<div class="flex flex-col md:flex-row gap-4 text-black">
    <div class="flex flex-col items-center h-full max-w-xl mx-auto p-8 w-full bg-white rounded-lg">
        <div class="font-bebas-neue text-3xl text-black mb-4" [translate]="'LOGIN'" i18n="@@LOGIN">Login</div>
        <form
            #form="ngForm"
            class="flex flex-col w-full space-y-6"
            [formGroup]="loginForm"
            (ngSubmit)="loginForm.valid && login()">
            <div class="relative flex flex-col">
                <input
                    type="text"
                    [ngClass]="{ '!border-red': form.submitted && loginForm.get('login').errors, '!py-2': isWeb }"
                    class="dialog-input"
                    placeholder="{{ 'EMAIL' | translate }}"
                    formControlName="login" />
                @if (form.submitted && loginForm.get('login').errors) {
                    <app-validation-label>
                        <span [translate]="'EMAIL_REQUIRED'" i18n="@@EMAIL_REQUIRED"> Email is required </span>
                    </app-validation-label>
                }
            </div>
            <div class="relative flex flex-col">
                <div class="relative">
                    <input
                        [ngClass]="{
                            '!border-red': form.submitted && loginForm.get('password').errors,
                            '!py-2': isWeb
                        }"
                        class="dialog-input"
                        [type]="loginPasswordVisible ? 'text' : 'password'"
                        placeholder="{{ 'PASSWORD' | translate }}"
                        formControlName="password" />
                    <app-icon
                        class="flex absolute -translate-y-1/2 top-1/2 opacity-60 right right-4 cursor-pointer text-black"
                        [icon]="loginPasswordVisible ? 'visibility_off' : 'visibility'"
                        (click)="toggleLoginPasswordVisibility()"></app-icon>
                </div>
                @if (form.submitted && loginForm.get('password').errors) {
                    <app-validation-label>
                        <span [translate]="'PASSWORD_REQUIRED'" i18n="@@PASSWORD_REQUIRED"> Password is required </span>
                    </app-validation-label>
                }
            </div>
            <app-primary-button
                class="space-y-6"
                addedClasses="w-full"
                size="normal"
                type="submit"
                [translate]="'LOGIN'"
                i18n="@@LOGIN">
                Login
            </app-primary-button>
        </form>
        <button
            class="text-black underline underline-offset-8 self-start !mx-0 my-4 text-sm"
            (click)="navigateToForgotPassword()"
            [translate]="'FORGOT_PASSWORD_Q'"
            i18n="@@FORGOT_PASSWORD_Q">
            Forgot password?
        </button>
        <div class="bg-white flex flex-col py-6 gap-4 text-black items-center w-full rounded">
            <app-social-logins type="continue"></app-social-logins>
        </div>
    </div>

    <div class="flex flex-col items-center h-full max-w-xl mx-auto p-8 w-full bg-white rounded-lg">
        <div class="font-bebas-neue text-3xl text-black mb-4" [translate]="'CREATE_ACCOUNT'" i18n="@@CREATE_ACCOUNT">
            Create account
        </div>
        <form
            #form="ngForm"
            class="flex flex-col w-full space-y-6"
            [formGroup]="registerForm"
            (ngSubmit)="registerForm.valid && register()">
            <div class="relative flex flex-col">
                <input
                    [ngClass]="{
                        '!border-red': form.submitted && registerForm.get('first_name').errors,
                        '!py-2': isWeb
                    }"
                    type="text"
                    class="dialog-input"
                    placeholder="{{ 'FIRST_NAME' | translate }}"
                    formControlName="first_name" />
                @if (form.submitted && registerForm.get('first_name').errors) {
                    <app-validation-label>
                        <span [translate]="'FIRST_NAME_REQUIRED'" i18n="@@FIRST_NAME_REQUIRED">
                            First name is required
                        </span>
                    </app-validation-label>
                }
            </div>
            <div class="relative flex flex-col">
                <input
                    [ngClass]="{
                        '!border-red': form.submitted && registerForm.get('last_name').errors,
                        '!py-2': isWeb
                    }"
                    type="text"
                    class="dialog-input"
                    placeholder="{{ 'LAST_NAME' | translate }}"
                    formControlName="last_name" />
                @if (form.submitted && registerForm.get('last_name').errors) {
                    <app-validation-label>
                        <span [translate]="'LAST_NAME_REQUIRED'" i18n="@@LAST_NAME_REQUIRED">
                            Last name is required
                        </span>
                    </app-validation-label>
                }
            </div>
            <div class="relative flex flex-col">
                <input
                    [ngClass]="{
                        '!border-red':
                            (form.submitted && registerForm.get('username').errors) ||
                            existingUsername === registerForm.get('username').value,
                        '!py-2': isWeb
                    }"
                    type="text"
                    class="dialog-input"
                    placeholder="{{ 'USERNAME' | translate }}"
                    formControlName="username" />
                @if (form.submitted && registerForm.get('username').errors) {
                    @if (registerForm.get('username').errors.required) {
                        <app-validation-label>
                            <span [translate]="'USERNAME_REQUIRED'" i18n="@@USERNAME_REQUIRED">
                                Username is required
                            </span>
                        </app-validation-label>
                    } @else if (
                        registerForm.get('username').errors.minlength || registerForm.get('username').errors.maxlength
                    ) {
                        <app-validation-label>
                            <span
                                [translate]="'USERNAME_MUST_BE_BETWEEN_5_AND_20_CHARACTERS'"
                                i18n="@@USERNAME_MUST_BE_BETWEEN_5_AND_20_CHARACTERS">
                                Username must be between 5 and 20 characters
                            </span>
                        </app-validation-label>
                    } @else if (registerForm.get('username').errors.pattern) {
                        <app-validation-label>
                            <span
                                [translate]="'USERNAME_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS'"
                                i18n="@@USERNAME_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS">
                                Username can not contain special characters
                            </span>
                        </app-validation-label>
                    }
                }
                @if (existingUsername === registerForm.get('username').value) {
                    <app-validation-label>
                        <span [translate]="'USERNAME_ALREADY_EXISTS'" i18n="@@USERNAME_ALREADY_EXISTS">
                            Username already exists</span
                        >
                    </app-validation-label>
                }
            </div>
            <div class="relative flex flex-col">
                <input
                    [ngClass]="{
                        '!border-red':
                            (form.submitted && registerForm.get('email').errors) ||
                            existingEmail === registerForm.get('email').value,
                        '!py-2': isWeb
                    }"
                    type="text"
                    class="dialog-input"
                    placeholder="{{ 'EMAIL' | translate }}"
                    formControlName="email" />
                @if (form.submitted && registerForm.get('email').errors) {
                    @if (registerForm.get('email').errors.required) {
                        <app-validation-label>
                            <span [translate]="'EMAIL_REQUIRED'" i18n="@@EMAIL_REQUIRED"> Email is required </span>
                        </app-validation-label>
                    } @else if (registerForm.get('email').errors.email) {
                        <app-validation-label>
                            <span [translate]="'INVALID_EMAIL'" i18n="@@INVALID_EMAIL"> Invalid email </span>
                        </app-validation-label>
                    }
                }
                @if (existingEmail === registerForm.get('email').value) {
                    <app-validation-label>
                        <span [translate]="'EMAIL_ALREADY_EXISTS'" i18n="@@EMAIL_ALREADY_EXISTS">
                            Email already exists</span
                        >
                    </app-validation-label>
                }
            </div>
            <div class="relative flex flex-col">
                <div class="relative">
                    <input
                        [ngClass]="{
                            '!border-red': form.submitted && registerForm.get('password').errors,
                            '!py-2': isWeb
                        }"
                        class="dialog-input"
                        [type]="registerPasswordVisible ? 'text' : 'password'"
                        placeholder="{{ 'PASSWORD' | translate }}"
                        formControlName="password" />
                    <app-icon
                        class="flex absolute -translate-y-1/2 top-1/2 opacity-60 right right-4 cursor-pointer text-black"
                        [icon]="registerPasswordVisible ? 'visibility_off' : 'visibility'"
                        (click)="toggleRegisterPasswordVisibility()"></app-icon>
                </div>
                @if (form.submitted && registerForm.get('password').errors) {
                    @if (registerForm.get('password').errors.required) {
                        <app-validation-label>
                            <span [translate]="'PASSWORD_REQUIRED'" i18n="@@PASSWORD_REQUIRED">
                                Password is required
                            </span>
                        </app-validation-label>
                    } @else if (registerForm.get('password').errors.minlength) {
                        <app-validation-label>
                            <span
                                [translate]="'PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG'"
                                i18n="@@PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG">
                                Password must be atleast 6 characters long
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
            <div class="relative flex flex-col">
                <app-checkbox-new color="black" [hasErrors]="form.submitted && registerForm.get('agreement').errors">
                    <div class="text-sm">
                        <span [translate]="'I_AGREE_TO_THE'" i18n="@@I_AGREE_TO_THE">I agree to the</span>
                        <a
                            class="text-orange hover:!underline mx-1"
                            (click)="openTermsOfService($event)"
                            [translate]="'TERMS_OF_SERVICE'"
                            i18n="@@TERMS_OF_SERVICE">
                            Terms of Service
                        </a>
                        <span [translate]="'AND'" i18n="@@AND">and</span>
                        <a
                            class="text-orange hover:!underline mx-1"
                            [translate]="'PRIVACY_POLICY'"
                            i18n="@@PRIVACY_POLICY"
                            (click)="openPrivacyPolicy($event)">
                            Privacy policy
                        </a>
                    </div>
                    <input type="checkbox" formControlName="agreement" />
                </app-checkbox-new>
                @if (form.submitted && registerForm.get('agreement').errors) {
                    <app-validation-label class="pl-[35px]">
                        <span [translate]="'PLEASE_AGREE_TO_THE_TERMS'" i18n="@@PLEASE_AGREE_TO_THE_TERMS">
                            Please agree to the Terms of Service!
                        </span>
                    </app-validation-label>
                }
            </div>
            <app-checkbox-new color="black">
                <span class="text-sm" [translate]="'NEWSLETTER_OPT_IN_INFO'" i18n="@@NEWSLETTER_OPT_IN_INFO">
                    Yes, I want to hear about exclusive offers, announcements and the newest products from Target
                    DartCounter, as well as those tailored to my account and activity.
                </span>
                <input type="checkbox" formControlName="newsletterOptIn" />
            </app-checkbox-new>

            <app-primary-button
                type="submit"
                size="normal"
                addedClasses="w-full"
                class="w-full"
                [translate]="'CREATE_ACCOUNT'"
                i18n="@@CREATE_ACCOUNT">
                Create account
            </app-primary-button>
        </form>
    </div>
</div>
