import { GameMode, StatisticsType } from '@dc-core/dc-backend/dc-enums';
import { User } from 'dc-core/dc-backend/dc-classes';
import * as moment from 'moment';
import {
    cricketTacticsGameSettings,
    StatisticsCricketTacticsGameSettingLabelType,
} from './cricket-tactics/cricket-tactics-statistics.globals';
import {
    startScoreRanges,
    StatisticsGameType,
    StatisticsGameTypeLabelType,
    statisticsGameTypes,
    StatisticsRange,
    StatisticsRangeLabelType,
    statisticsRanges,
    // StatisticsRequireNumber,
    // StatisticsRequireTriple,
    StatisticsStartScoreLabelType,
} from './statistics.globals';

export type FilterChangedFilter =
    | 'statisticsRange'
    | 'statisticsGameType'
    | 'startScoreOption'
    | 'startScore'
    | 'cricketGameSetting'
    | 'bobs27GameSetting'
    | 'singlesTrainingGameSettings'
    | 'doublesTrainingGameSettings'
    | 'scoreTrainingGameSettings'
    | 'throwout'
    | 'gameSetting'
    | 'rangeMode'
    | 'winningType'
    | 'goalTriple'
    | 'requireNumber'
    | 'numberSegment'
    | 'amountOfHits'
    | 'maxDarts';

export type FilterChangedEvent = { filter: FilterChangedFilter; value: any };

export type StatDetailType =
    | 'threeDartAvg'
    | 'oneDartAvg'
    | 'firstNineAvg'
    | 'checkoutRate'
    | 'checkouts'
    | 'legs'
    | 'marksPerRound'
    | 'totalScore'
    | 'avgHitsPerTurn'
    | 'avgTurnsTaken'
    | 'doubleHitRate'
    | 'dartsHit'
    | 'hitRate'
    | 'dartsNeeded'
    | 'totalDarts';

export type StatDetailSegment = 'best' | 'worst';

export interface OverallStatDetails {
    title: string;
    statsType: StatDetailType;
    segment: StatDetailSegment;
    gameMode: GameMode;
    fromDate: string;
    untilDate: string;
    gameSpecific: any;
}

export class GameStatistics {
    public user: User;

    public statisticsRange: StatisticsRange = 'this_year';
    public statisticsRanges: StatisticsRangeLabelType[] = statisticsRanges;

    public statisticsGameType: StatisticsGameType = 'all';
    public statisticsGameTypes: StatisticsGameTypeLabelType[] = statisticsGameTypes;

    public fromDate: string;
    public untilDate: string;

    public years: number[] = [];
    public year: number;
    public months: string[] = [];
    public month: string;

    public resultWidths: number[] = [33.3, 33.3, 33.3];

    public allStatTypes: StatisticsType[] = ['average', 'best', 'worst'];

    // Game specific ranges
    public startScoreRanges: StatisticsStartScoreLabelType[] = startScoreRanges;
    public cricketGameSettings: StatisticsCricketTacticsGameSettingLabelType[] = cricketTacticsGameSettings;

    private _mainLocalStorageKey = 'overallGameStatistics';

    constructor(user: User) {
        this.user = user;
        this.loadDates(false);

        this.months = moment.monthsShort();
        this.months.forEach((month) => {
            if (month === moment().format('MMM')) {
                this.month = month;
            }
        });
        this.months.unshift('All');

        let currentYear = parseInt(moment().format('YYYY'), 0);
        this.year = currentYear;
        let createdYear = parseInt(moment(this.user.created_at).format('YYYY'), 0);
        while (currentYear >= createdYear) {
            this.years.push(currentYear);
            currentYear--;
        }
    }

    loadDates(save = true): void {
        switch (this.statisticsRange) {
            case 'all_time':
                this.fromDate = moment(this.user.created_at).format('YYYY-MM-DD');
                this.untilDate = moment().format('YYYY-MM-DD');
                break;
            case 'this_month':
                this.fromDate = moment().format('YYYY-MM-01');
                this.untilDate = moment().format('YYYY-MM-') + moment().daysInMonth();
                break;
            case 'this_year':
                this.fromDate = moment().startOf('year').format('YYYY-MM-DD');
                this.untilDate = moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'custom':
                if (this.month === 'All') {
                    this.fromDate = moment(this.year + '-01-01').format('YYYY-MM-DD');
                    this.untilDate = moment(this.year + '-12-01')
                        .endOf('month')
                        .format('YYYY-MM-DD');
                } else {
                    const month = moment().month(this.month).format('MM');
                    this.fromDate = moment(this.year + '-' + month + '-01').format('YYYY-MM-DD');
                    this.untilDate = moment(this.year + '-' + month + '-01')
                        .endOf('month')
                        .format('YYYY-MM-DD');
                }
                break;
        }

        if (save) {
            this.saveFilters();
        }
    }

    getIsOnline(): boolean | null {
        switch (this.statisticsGameType) {
            case 'all':
                return null;
            case 'local':
                return false;
            case 'online':
                return true;
            case 'vs_cpu':
                return null;
        }
    }

    getVsCPU(): boolean | null {
        switch (this.statisticsGameType) {
            case 'all':
                return null;
            case 'local':
                return null;
            case 'online':
                return null;
            case 'vs_cpu':
                return true;
        }
    }

    updateStatisticsRange(range: StatisticsRange, save = true): void {
        this.statisticsRange = range;
        this.loadDates(save);
    }

    updateGameType(statisticsGameType: StatisticsGameType): void {
        this.statisticsGameType = statisticsGameType;
    }

    // updateThrowout(statisticsThrowout: StatisticsWinningType): void {
    //     this.statisticsThrowout = statisticsThrowout;
    // }

    // updateRequireNumber(statisticsRequireNumber: StatisticsRequireNumber): void {
    //     this.statisticsRequireNumber = statisticsRequireNumber;
    // }

    // updateRequireTriple(statisticsRequireTriple: StatisticsRequireTriple): void {
    //     this.StatisticsRequireTriple = statisticsRequireTriple;
    // }

    setFilters(): void {
        let savedFilters = JSON.parse(localStorage.getItem(this._mainLocalStorageKey));
        if (savedFilters) {
            this.statisticsGameType = savedFilters.statisticsGameType ?? this.statisticsGameType;
            this.year = savedFilters.year ?? this.year;
            this.month = savedFilters.month ?? this.month;

            this.statisticsRange = savedFilters.statisticsRange ?? this.statisticsRange;
            this.updateStatisticsRange(this.statisticsRange);
        }
    }

    saveFilters() {
        localStorage.setItem(
            this._mainLocalStorageKey,
            JSON.stringify({
                statisticsGameType: this.statisticsGameType,
                statisticsRange: this.statisticsRange,
                year: this.year,
                month: this.month,
            })
        );
    }
}
