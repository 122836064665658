<div class="flex flex-col rounded-lg bg-panel text-black border-b-2 border-orange">
    @if (collapsible()) {
        <div
            class="w-full flex justify-between cursor-pointer duration-200 pl-5 pr-4 py-2 rounded-lg"
            [class.hover:bg-white-accent]="isWeb"
            (click)="toggleCollapse()">
            <div
                class="font-bebas-neue text-xl text-black"
                [translate]="'YOUR_SMART_DEVICES'"
                i18n="@@YOUR_SMART_DEVICES">
                Your smart devices
            </div>
            <div class="flex justify-center items-center">
                <div
                    class="rounded-full bg-green inline-flex justify-center items-center w-7 h-7 mr-2 !text-white"
                    [class.bg-red]="statusCount() === 0"
                    [class.bg-orange]="statusCount() === 1"
                    [class.bg-green]="statusCount() === 2">
                    <div class="leading-none !text-white font-bold text-[0.8rem]">{{ statusCount() }}/2</div>
                </div>

                @if (statusCount() > 0) {
                    <div class="text-xs font-bold uppercase text-black" [translate]="'ACTIVATED'" i18n="@@ACTIVATED">
                        Activated
                    </div>
                } @else {
                    <div
                        class="text-xxs font-bold uppercase text-black"
                        [translate]="'NOT_ACTIVATED'"
                        i18n="@@NOT_ACTIVATED">
                        Not activated
                    </div>
                }

                <app-icon
                    [icon]="collapsed() ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
                    class="!text-neutral-200 ml-2"></app-icon>
            </div>
        </div>
    } @else {
        <div
            class="font-bebas-neue text-xl text-black text-center py-2"
            [translate]="'YOUR_SMART_DEVICES'"
            i18n="@@YOUR_SMART_DEVICES">
            Your smart devices
        </div>
    }

    @if (!collapsible() || !collapsed()) {
        <div class="flex border-t border-neutral-50 pt-3 pb-1">
            <div
                class="flex flex-col items-center basis-1/2 duration-200 hover:scale-[1.02] cursor-pointer"
                (click)="
                    virtStatus() === SmartDeviceStatus.OWNED && videoRoomService.ownCamera?.camType !== 'smart_device'
                        ? activateVirt()
                        : virtStatus() === SmartDeviceStatus.ACTIVATED ||
                            videoRoomService.ownCamera?.camType === 'smart_device'
                          ? disableVirt()
                          : setupSmartDevice()
                ">
                <img class="w-16" [src]="'assets/icon/ftr/virt_cam_logo.webp'" />

                <div class="flex items-center justify-center p-2">
                    @if (
                        virtStatus() === SmartDeviceStatus.OWNED &&
                        videoRoomService.ownCamera?.camType !== 'smart_device'
                    ) {
                        <div class="flex items-center space-x-2">
                            <div
                                class="!text-white bold bg-orange px-3 text-sm"
                                [translate]="'ACTIVATE'"
                                i18n="@@ACTIVATE">
                                Activate
                            </div>
                        </div>
                    } @else if (
                        virtStatus() === SmartDeviceStatus.ACTIVATED ||
                        videoRoomService.ownCamera?.camType === 'smart_device'
                    ) {
                        <div class="flex items-center space-x-2">
                            <div class="text-black bold text-sm" [translate]="'ACTIVATED'" i18n="@@ACTIVATED">
                                Activated
                            </div>
                            <div class="h-4 w-4 flex items-center justify-center rounded-full bg-green">
                                <app-icon icon="check_mark" size="extrasmall" class="!text-white"></app-icon>
                            </div>
                        </div>
                    } @else {
                        <div class="flex items-center space-x-2">
                            <div class="!text-white bold bg-orange px-3 text-sm" [translate]="'SETUP'" i18n="@@SETUP">
                                Setup
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div
                class="flex flex-col items-center basis-1/2 duration-200 hover:scale-[1.02] cursor-pointer"
                (click)="
                    omniStatus() === SmartDeviceStatus.OWNED && !omniIngameService.omniConnected()
                        ? activateOmni()
                        : omniIngameService.omniConnected()
                          ? disableOmni()
                          : setupSmartDevice()
                ">
                <img class="w-16" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />

                <div class="flex items-center justify-center p-2">
                    @if (omniStatus() === SmartDeviceStatus.OWNED && !omniIngameService.omniConnected()) {
                        <div class="flex items-center space-x-2">
                            <div
                                class="!text-white bold bg-orange px-3 text-sm"
                                [translate]="'ACTIVATE'"
                                i18n="@@ACTIVATE">
                                Activate
                            </div>
                        </div>
                    } @else if (omniIngameService.omniConnected()) {
                        <div class="flex items-center space-x-2">
                            <div class="text-black bold text-sm" [translate]="'ACTIVATED'" i18n="@@ACTIVATED">
                                Activated
                            </div>
                            <div class="h-4 w-4 flex items-center justify-center rounded-full bg-green">
                                <app-icon icon="check_mark" size="extrasmall" class="!text-white"></app-icon>
                            </div>
                        </div>
                    } @else {
                        <div class="flex items-center space-x-2">
                            <div class="!text-white bold bg-orange px-3 text-sm" [translate]="'SETUP'" i18n="@@SETUP">
                                Setup
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    }
</div>
