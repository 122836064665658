import { CricketTacticsGameSetting } from '@dc-core/dc-backend/dc-enums';

export interface StatisticsCricketTacticsGameSettingLabelType {
    label: string;
    type: CricketTacticsGameSetting;
}

export const ALL_STAT_RANGE: StatisticsCricketTacticsGameSettingLabelType = {
    label: $localize`:@@ALL:All`,
    type: 'all',
};

export const CRICKET_SETTING_RANGE: StatisticsCricketTacticsGameSettingLabelType = {
    label: $localize`:@@CRICKET:Cricket`,
    type: 'cricket',
};

export const TACTICS_SETTING_RANGE: StatisticsCricketTacticsGameSettingLabelType = {
    label: $localize`:@@TACTICS:Tactics`,
    type: 'tactics',
};

export const CRICKET_CUT_THROAT_SETTING_RANGE: StatisticsCricketTacticsGameSettingLabelType = {
    label: $localize`:@@CRICKET:Cricket` + '(' + $localize`:@@CUT_THROAT:Cut-throat` + ')',
    type: 'cricket_cut_throat',
};

export const TACTICS_CUT_THROAT_SETTING_RANGE: StatisticsCricketTacticsGameSettingLabelType = {
    label: $localize`:@@TACTICS:Tactics` + '(' + $localize`:@@CUT_THROAT:Cut-throat` + ')',
    type: 'tactics_cut_throat',
};

export var cricketTacticsGameSettings: StatisticsCricketTacticsGameSettingLabelType[] = [
    ALL_STAT_RANGE,
    CRICKET_SETTING_RANGE,
    TACTICS_SETTING_RANGE,
    CRICKET_CUT_THROAT_SETTING_RANGE,
    TACTICS_CUT_THROAT_SETTING_RANGE,
];
