/* eslint-disable max-len */
import { Component, EventEmitter, Output } from '@angular/core';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { StatisticNumberFormat } from '@dc-core/dc-backend/dc-enums';
import {
    FilterChangedFilter,
    OverallStatDetails,
    StatDetailSegment,
    StatDetailType,
} from '@dc-core/dc-statistics/game-statistics.classes';
import { ShanghaiOverallStats, ShanghaiStatDetails } from '@dc-core/dc-statistics/shanghai/shanghai-overall-stats';
import { DropdownSelectItem, StatisticsRange } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { UltimateFeatureID } from 'src/app/core/app-features/services/app-features.service';
import { UltimateDialogPayload } from 'src/dialogs/ultimate-subscription/ultimate-subscription';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-shanghai-table',
    templateUrl: 'shanghai-table.component.html',
})
export class ShanghaiTableComponent extends ShanghaiOverallStats {
    @Output() dateChangeEvent = new EventEmitter<void>();

    public periodOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL_TIME:All-time`,
            value: 'all_time',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@THIS_MONTH:This month`,
            value: 'this_month',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@THIS_YEAR:This year`,
            value: 'this_year',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CUSTOM:Custom`,
            value: 'custom',
            ultimateOnly: true,
        },
    ];

    public rangeModes: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL:All`,
            value: 'all',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@ONE_TO_SEVEN:One to seven`,
            value: 'one_to_seven',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@ONE_TO_TWENTY:One to twenty`,
            value: 'one_to_twenty',
            ultimateOnly: true,
        },
    ];

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public gameStatisticsApiService: GameStatisticsApiService,
        public modal: ModalController,
        private upgrade: UpgradeService
    ) {
        super(auth.user, gameStatisticsApiService);
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.OPENSHANGHAISTATSTABLE);

        this.setFilters(); // Set previous filters if any
        this.loadOverallStats();
    }

    changeFilter(filter: FilterChangedFilter, value: any): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.CHANGESHAINGHAIFILTER);
        switch (filter) {
            // Overall
            case 'statisticsRange':
                this.updateStatisticsRange(value);
                this.dateChangeEvent.emit();
                break;

            // Game specific
            case 'rangeMode':
                this.rangeMode = value;
                break;
            case 'winningType':
                this.winningType = value;
                break;
            case 'requireNumber':
                this.requireNumber = value;
                break;
            case 'goalTriple':
                this.goalTriple = value;
                break;
        }

        this.loadOverallStats();
    }

    changeStatisticsRange(statisticsRange: StatisticsRange): void {
        super.updateStatisticsRange(statisticsRange, false);
        this.loadOverallStats();
    }

    goToDetails(statsType: StatDetailType, segment: StatDetailSegment) {
        let title = '';
        let numberFormat: StatisticNumberFormat = null;
        switch (statsType) {
            case 'totalScore':
                title = $localize`:@@TOTAL_SCORE:Total score`;
                break;
            case 'dartsHit':
                title = $localize`:@@DARTS_HIT:Darts hit`;
                break;
            case 'hitRate':
                title = $localize`:@@HIT_RATE:Hit rate`;
                numberFormat = 'percentage';
                break;
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, 'View top 25 ' + segment + ' ' + statsType);

        this.nav.navigateForward('statistics/details', {
            queryParams: {
                // Overall
                title,
                numberFormat,
                statsType: statsType,
                segment: segment,
                fromDate: this.fromDate,
                untilDate: this.untilDate,
                // Game mode specific
                gameMode: 'shanghai',
                gameSpecific: {
                    rangeMode: this.rangeMode,
                    winningType: this.winningType,
                    requireNumber: this.requireNumber,
                    goalTriple: this.goalTriple,
                } as ShanghaiStatDetails,
            } as OverallStatDetails,
        } as NavigationOptions);
    }

    async OpenUltimateDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        this.modal
            .create({
                component: dialogComponent,
                componentProps: {
                    highlightedFeatureID: UltimateFeatureID.ALLTIME,
                } as UltimateDialogPayload,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }
}
