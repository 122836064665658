import { CommonModule } from '@angular/common';
import { Component, inject, model, ModelSignal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthApiService } from '@dc-api/auth.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from '@services/auth.service';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { AgreementDialogComponent } from 'src/dialogs/agreement/agreement';
import { PolicyDialogComponent } from 'src/dialogs/policy/policy';
import { TermsAndNewsletterDialogComponent } from 'src/dialogs/terms-and-newsletter/terms-and-newsletter.dialog';
import { environment } from 'src/environments/environment';

import { CheckboxNewComponent } from '../../../../shared/components/checkbox-new/checkbox-new.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { ValidationLabelComponent } from '../../../../shared/components/validation-label/validation-label.component';
import { GoogleWebSigninComponent } from '../google-web-signin/google-web-signin.component';
import { SocialLoginsComponent } from '../social-logins/social-logins.component';

@Component({
    selector: 'app-on-page-auth',
    standalone: true,
    templateUrl: './on-page-auth.component.html',
    imports: [
        GoogleWebSigninComponent,
        SocialLoginsComponent,
        ValidationLabelComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        IconComponent,
        CheckboxNewComponent,
        PrimaryButtonComponent,
    ],
})
export class OnPageAuthComponent {
    public user: ModelSignal<User> = model(null);

    private loadingService: DCLoadingService = inject(DCLoadingService);
    private modalController: ModalController = inject(ModalController);
    private navController: NavController = inject(NavController);
    private authService: AuthService = inject(AuthService);
    private authApiService: AuthApiService = inject(AuthApiService);
    private formBuilder: FormBuilder = inject(FormBuilder);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);

    public loginPasswordVisible: boolean = false;
    public loginForm: FormGroup = this.formBuilder.group({
        login: ['', Validators.required],
        password: ['', Validators.required],
    });

    public registerForm: FormGroup = this.formBuilder.group({
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        username: [
            '',
            [
                Validators.required,
                Validators.maxLength(20),
                Validators.minLength(5),
                Validators.pattern(/^[a-zA-Z0-9-._]+$/),
            ],
        ],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        agreement: [false, Validators.requiredTrue],
        newsletterOptIn: [false],
    });
    public registerPasswordVisible: boolean = false;
    public existingUsername: string;
    public existingEmail: string;

    public isWeb: boolean = environment.isWeb;

    public toggleLoginPasswordVisibility(): void {
        this.loginPasswordVisible = !this.loginPasswordVisible;
    }

    public toggleRegisterPasswordVisibility(): void {
        this.registerPasswordVisible = !this.registerPasswordVisible;
    }

    public navigateToRegistration(): void {
        this.navController.navigateForward('register');
    }

    public navigateToForgotPassword(): void {
        this.navController.navigateForward('forgot-password');
    }

    public login(): void {
        if (this.loginForm.invalid) {
            return;
        }

        this.loadingService.ShowDefaultLoader();

        this.authApiService
            .login(this.loginForm.value)
            .then((res) => {
                this.authService.clearStorage();
                this.authService.saveAuthentication(res.data.user, res.data.access_token, true);

                this.checkTermsAndNewsletter(res.data.user);
            })
            .catch((err) => {
                this.alertService.errorFromApi(err);
            })
            .finally(() => {
                this.loadingService.DismissLoader();
            });
    }

    public openTermsOfService(event: MouseEvent): void {
        event.stopPropagation();
        this.modalController.create({ component: AgreementDialogComponent }).then((elem) => elem.present());
    }

    public openPrivacyPolicy(event: MouseEvent): void {
        event.stopPropagation();
        this.modalController.create({ component: PolicyDialogComponent }).then((elem) => elem.present());
    }

    public register(): void {
        if (this.registerForm.invalid) {
            return;
        }

        this.loadingService.ShowDefaultLoader();

        const registerData = this.registerForm.value;
        delete registerData.agreement;

        this.authApiService
            .register(registerData)
            .then((res) => {
                this.loadingService.DismissLoader();
                this.authService.clearStorage();
                this.authService.saveAuthentication(res.data.user, res.data.access_token, true);

                this.user.set(res.data.user);
            })
            .catch((err) => {
                const error = err.getActualType();
                if (error.status === 422) {
                    if (
                        error.data.errors.username &&
                        error.data.errors.username.find((error) => error.includes('already exists'))
                    ) {
                        this.existingUsername = registerData.username;
                    }
                    if (
                        error.data.errors.email &&
                        error.data.errors.email.find((error) => error.includes('already exists'))
                    ) {
                        this.existingEmail = registerData.email;
                    }
                } else {
                    this.alertService.errorFromApi(err);
                }

                this.loadingService.DismissLoader();
            });
    }

    private checkTermsAndNewsletter(user: User): void {
        if (user.accepted_terms_at === null || (user.email && user.newsletter_opt_in === null)) {
            this.modalController
                .create({
                    component: TermsAndNewsletterDialogComponent,
                    backdropDismiss: false,
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(() => {
                        this.user.set(user);
                    });
                });
        } else {
            this.user.set(user);
        }
    }
}
