import { Injectable } from "@angular/core";

import { ApiService } from "./api.service";

@Injectable()
export class TransactionApiService extends ApiService {
  // GET Requests
  public getTransactions = super
    .getFetcher()
    .path("/transactions")
    .method("get")
    .create();
  public getUserTransactions = super
    .getFetcher()
    .path("/transactions/user")
    .method("get")
    .create();
}
