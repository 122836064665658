/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-list-switch',
    standalone: true,
    templateUrl: 'list-switch.component.html',
    imports: [TranslateModule],
})
export class ListSwitchComponent {
    @Input() item: any;
    @Input() items: any[];
    @Input() size: 'medium' | 'normal' | 'small' = 'normal';

    @Output() itemChange = new EventEmitter();

    changeItem(item: any): void {
        this.itemChange.emit(item);
    }
}
