import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthApiService } from '@dc-api/auth.api.service';
import { NewsletterApiService } from '@dc-api/newsletter.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-terms-and-newsletter-dialog',
    templateUrl: 'terms-and-newsletter.dialog.html',
})
export class TermsAndNewsletterDialogComponent implements OnInit {
    title: string;
    agreements = false;
    newsletterOptIn = false;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute,
        private _translate: TranslateService,
        private _alertService: DartCounterAlertService,
        private _authApiService: AuthApiService,
        private _newsletterApiService: NewsletterApiService,
        private _userApiService: UserApiService
    ) {}

    ngOnInit(): void {
        if (!this.auth.user.accepted_terms_at) {
            $localize`:@@HELLO_USER:Hello, ${this.auth.user.first_name}:user:!`;
            this.title = this._translate.instant('HELLO_USER', {
                user: this.auth.user.first_name || $localize`:@@NO_NAME:No name`,
            });
        } else {
            this.title = $localize`:@@EMAIL_PREFERENCES:Email preferences`;
        }

        if (this.auth.user.newsletter_opt_in) {
            this.newsletterOptIn = true;
        }
    }

    public async continue(): Promise<void> {
        if (!this.agreements && !this.auth.user.accepted_terms_at) {
            this._alertService.createAlert({
                title: $localize`:@@PLEASE_AGREE_TO_THE_TERMS:Please agree to the Terms of Service!`,
                icon: 'warning',
                timer: null,
                showCloseButton: true,
            });
            return;
        }

        if (!this.auth.user.accepted_terms_at) {
            await this._authApiService.acceptTerms({}).catch(console.error);
        }
        if (this.auth.user.email) {
            await this._newsletterApiService
                .updateNewsletterOptIn({
                    newsletter_opt_in: this.newsletterOptIn,
                })
                .then((res) => {
                    if (res.data.message && this.newsletterOptIn) {
                        this._alertService.createAlert({
                            title: res.data.message,
                            icon: 'success',
                        });
                    }
                })
                .catch(console.error);
        }
        await this._userApiService.getCurrentUser({}).then((res) => this.auth.saveAuthentication(res.data));

        this.view.dismiss();
    }
}
