<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern">
    <app-page-header mainNavigation="custom" color="transparent">
        <div
            left
            class="text-center font-bold text-black text-sm cursor-pointer"
            (click)="dismiss()"
            [translate]="'CLOSE'"
            i18n="@@CLOSE">
            Close
        </div>
        <div title class="text-black" [translate]="'PROFILE_PHOTO'" i18n="@@PROFILE_PHOTO">Profile photo</div>
    </app-page-header>

    <div class="flex flex-1 flex-col overflow-hidden p-4 justify-center">
        @if (image) {
            <image-cropper
                [imageBase64]="image"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                [format]="format"
                [output]="'base64'"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"></image-cropper>
        }
    </div>

    <app-primary-button size="normal" addedClasses="w-full" (click)="save()" [translate]="'SAVE'" i18n="@@SAVE">
        Save
    </app-primary-button>
</div>
