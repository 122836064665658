<div class="relative flex flex-col overflow-visible bg-white">
    <div
        class="flex w-full flex-col py-4 px-safe-offset-4 bg-white dark:bg-neutral-100"
        [ngClass]="{ 'border-b-2 border-neutral-50 rounded-bl-xl rounded-br-xl': !opened }">
        <div class="flex items-center justify-between cursor-pointer" (click)="opened = !opened">
            <div class="flex items-center">
                @if (gameApiService.unfinishedLocalMatches.length) {
                    <ion-badge mode="md" class="ion-bg-yellow !ion-text-black">
                        {{ gameApiService.unfinishedLocalMatches.length }}
                    </ion-badge>
                }
                <div
                    class="ml-1.5 text-sm font-bold !text-black"
                    [translate]="'UNFINISHED_GAMES'"
                    i18n="@@UNFINISHED_GAMES">
                    Unfinished games
                </div>
            </div>
            <app-icon
                [icon]="opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                class="text-neutral-200 dark:text-neutral-800"></app-icon>
        </div>
    </div>
    @if (opened) {
        <div
            style="backdrop-filter: brightness(50%); -webkit-backdrop-filter: brightness(50%)"
            class="absolute left-0 right-0 top-full overflow-scroll border-t-2 border-neutral-50"
            appDropdownModalHeightDirective>
            <div class="flex flex-col rounded-b-xl bg-white p-4 text-black">
                <div
                    class="mb-2 text-sm font-semibold text-neutral-400"
                    [translate]="'UNFINISHED_LOCAL_GAMES_INFO'"
                    i18n="@@UNFINISHED_LOCAL_GAMES_INFO">
                    Continue the game where you left off, or remove it from your account!
                </div>
                <div class="flex flex-col-reverse space-y-2">
                    @for (game of gameApiService.unfinishedLocalMatches; track game; let index = $index) {
                        <div class="flex flex-col">
                            <div
                                class="flex items-center justify-between rounded-t-md bg-neutral-50 dark:!bg-black-full px-4 py-2.5">
                                <div class="font-bold text-black">{{ game.name }}</div>
                                <div class="text-xxs font-semibold text-neutral-900">
                                    {{ game.started_at | date: 'dd-MMM-yyyy H:mm' : '' : translateService.currentLang }}
                                </div>
                            </div>
                            <div class="grid grid-cols-11 bg-white px-4 py-2 border-x-2 border-neutral-50">
                                <ng-container
                                    *ngTemplateOutlet="
                                        firstPlayer;
                                        context: { game, user: game.teams[0].users[0] }
                                    "></ng-container>
                                <div class="col-span-3 flex items-center justify-center font-bebas-neue">
                                    @if (!game.teams[1]) {
                                        <app-match-interim-score size="lobby">
                                            {{ getScore(game, 0) }}
                                        </app-match-interim-score>
                                    }
                                    @if (game.teams[1]) {
                                        <app-match-interim-score size="lobby">
                                            {{ getScore(game, 0) + ' - ' + getScore(game, 1) }}
                                        </app-match-interim-score>
                                    }
                                </div>
                                @if (game.teams[1]) {
                                    <ng-container
                                        *ngTemplateOutlet="
                                            secondPlayer;
                                            context: { game, user: game.teams[1].users[0] }
                                        "></ng-container>
                                }
                            </div>
                            <div
                                class="flex items-center justify-end space-x-1 rounded-b-md border-x-2 border-b-2 border-neutral-50 px-4 py-2">
                                <app-primary-button
                                    color="red"
                                    size="extrasmall"
                                    [translate]="'REMOVE'"
                                    i18n="@@REMOVE"
                                    (click)="remove(index)">
                                    Remove
                                </app-primary-button>
                                <app-primary-button
                                    color="orange"
                                    size="extrasmall"
                                    [translate]="'CONTINUE'"
                                    i18n="@@CONTINUE"
                                    (click)="continue(game, index)">
                                    Continue
                                </app-primary-button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    }
</div>

<ng-template #firstPlayer let-game="game" let-user="user">
    <div class="col-span-4 flex items-center justify-start space-x-2">
        <app-profile-photo
            class="h-fit"
            [ultimate]="user.user?.is_ultimate"
            [badge]="user.user?.profile?.badge"
            [url]="user.user?.profile?.profile_photo_url"
            [size]="32">
        </app-profile-photo>
        <div class="space-y-0.5 overflow-hidden">
            <div class="flex items-center space-x-1">
                <div class="truncate text-xs font-bold text-black">
                    {{ user.user ? user.user?.first_name : user.ghost_name || ('NO_NAME' | translate) }}
                </div>
            </div>
            <div class="flex items-center space-x-2.5"></div>
        </div>
    </div>
</ng-template>

<ng-template #secondPlayer let-game="game" let-user="user">
    <div class="col-span-4 flex items-center justify-end space-x-2">
        <div class="space-y-0.5 overflow-hidden">
            <div class="flex items-center justify-end space-x-1">
                <div class="truncate text-xs font-bold text-black">
                    {{ user.user ? user.user?.first_name : user.ghost_name || ('NO_NAME' | translate) }}
                </div>
            </div>
            <div class="flex items-center justify-end space-x-2.5"></div>
        </div>
        <app-profile-photo
            class="h-fit"
            [ultimate]="user.user?.is_ultimate"
            [badge]="user.user?.profile?.badge"
            [url]="user.user?.profile?.profile_photo_url"
            [size]="32">
        </app-profile-photo>
    </div>
</ng-template>
