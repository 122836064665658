import { Component, inject } from '@angular/core';
import { GameApiService } from '@dc-api/game.api.service';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { TournamentApiService } from '@dc-api/tournament.api.service';
import { Tournament } from '@dc-core/dc-backend/dc-classes';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { GameStatistics } from '@dc-core/dc-statistics/game-statistics.classes';
import { ListGameType } from '@dc-core/dc-statistics/statistics.globals';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import _ from 'lodash';
import moment from 'moment';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AppFeaturesService, UltimateFeatureID } from 'src/app/core/app-features/services/app-features.service';
import { UltimateDialogPayload } from 'src/dialogs/ultimate-subscription/ultimate-subscription';
import { environment } from 'src/environments/environment';
import { JoyrideService } from 'src/providers/ngx-joyride/src/lib/services/joyride.service';
import { UpgradeService } from 'src/providers/UpgradeService';
import { AuthService } from 'src/services/auth.service';

export type StatisticsView = 'games' | 'tournaments';

@Component({
    selector: 'app-page-statistics',
    templateUrl: 'statistics.html',
})
export class StatisticsComponent extends GameStatistics {
    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    public view: StatisticsView = 'games';

    public title: string;
    public xAxisLabel = '';
    public averagesInChart = [];
    public scheme = {
        domain: ['#E44E32'],
    };
    public unverified;

    public filter = 1;

    public gameTypes: ListGameType[] = [];
    public activeGameType: ListGameType;

    public lastGame: any = null;
    public lastGameLoaded = false;

    public lastTournament: Tournament = null;
    public lastTournamentLoaded = false;

    showExpandedFilters = false;

    public skeletonThemes = {
        info: {
            'border-radius': '5px',
            height: '25px',
            width: '33%',
            'background-color': 'rgb(76 76 76)',
        },
        info2: {
            'border-radius': '5px',
            height: '22px',
            width: '50%',
            'background-color': 'rgb(76 76 76)',
        },
        stats: {
            'border-radius': '5px',
            height: '50px',
            width: '50%',
            'background-color': 'rgb(76 76 76)',
        },
        profilepic: {
            border: '10px solid #2d2d2d',
            'border-radius': '50%',
            height: '135px',
            width: '135px',
            margin: '0 auto',
            'background-color': 'rgb(76 76 76)',
        },
    };

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        private upgrade: UpgradeService,
        private menu: MenuController,
        private readonly joyrideService: JoyrideService,
        private _menu: MenuController,
        public modal: ModalController,
        public gameStatisticsApiService: GameStatisticsApiService,
        public gameApiService: GameApiService,
        public tournamentApiService: TournamentApiService,
        private navCtrl: NavController
    ) {
        super(auth.user);

        this.title = 'statistics';
    }

    ionViewWillEnter() {
        this.ga.trackView('Statistics');

        this.gameTypes = [
            {
                value: 'match',
                gameMode: 'match',
                path: 'statistics/match/list',
                label: $localize`:@@MATCH:Match`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENMATCHSTATS,
                },
                unverifiedGames: this.gameApiService.unverifiedMatchesCount,
            },
            {
                value: 'checkout-121',
                gameMode: 'checkout_121',
                path: 'statistics/checkout-121/list',
                label: $localize`:@@121_CHECKOUT:121 Checkout`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENSINGLESSTATS,
                },
                ultimateOnly: true,
                unverifiedGames: this.gameApiService.unverifiedCheckout121sCount,
            },
            {
                value: 'around-the-clock',
                gameMode: 'around_the_clock',
                path: 'statistics/around-the-clock/list',
                label: $localize`:@@AROUND_THE_CLOCK:Around The Clock`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENAROUNDTHECLOCKSTATS,
                },
                unverifiedGames: this.gameApiService.unverifiedAroundTheClocksCount,
            },

            {
                value: 'bobs-27',
                gameMode: 'bobs_27',
                path: 'statistics/bobs-27/list',
                label: $localize`:@@BOBS_27:Bob's 27`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENBOBSSTATS,
                },
                unverifiedGames: this.gameApiService.unverifiedBobs27sCount,
            },
            {
                value: 'cricket-tactics',
                gameMode: 'cricket_tactics',
                path: 'statistics/cricket-tactics/list',
                label: $localize`:@@TACTICS_CRICKET_TRAINING:Cricket / Tactics`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENCRICKETSTATS,
                },
                unverifiedGames: this.gameApiService.unverifiedCricketTacticsCount,
            },
            {
                value: 'doubles-training',
                gameMode: 'doubles_training',
                path: 'statistics/doubles-training/list',
                label: $localize`:@@DOUBLES_TRAINING:Doubles training`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENDOUBLESSTATS,
                },
                unverifiedGames: this.gameApiService.unverifiedDoublesTrainingsCount,
            },
            {
                value: 'score-training',
                gameMode: 'score_training',
                path: 'statistics/score-training/list',
                label: $localize`:@@SCORE_TRAINING:Score training`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENSCORESSTATS,
                },
                unverifiedGames: this.gameApiService.unverifiedScoreTrainingsCount,
            },
            {
                value: 'shanghai',
                gameMode: 'shanghai',
                path: 'statistics/shanghai/list',
                label: $localize`:@@SHANGHAI:Shanghai`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENSHANGHAISTATS,
                },
                unverifiedGames: this.gameApiService.unverifiedShanghaisCount,
            },
            {
                value: 'singles-training',
                gameMode: 'singles_training',
                path: 'statistics/singles-training/list',
                label: $localize`:@@SINGLES_TRAINING:Singles training`,
                ga: {
                    category: GA_EVENTCATEGORIES.STATISTICS,
                    action: GA_EVENTACTIONS.OPENSINGLESSTATS,
                },
                unverifiedGames: this.gameApiService.unverifiedSinglesTrainingsCount,
            },
        ];

        const storedSettings = localStorage.getItem('activeGameType');
        if (storedSettings) {
            this.setGameType(storedSettings);
        } else {
            this.setGameType('match');
        }
    }

    ionViewDidEnter() {
        this.menu.enable(true, 'mainmenu');
        this.menu.enable(false, 'ingamemenu');

        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeStats)) ?? true;
        if (showTour) {
            setTimeout(() => {
                this.joyrideService.startTour({
                    steps: ['firstStep'],
                    showPrevButton: false,
                    themeColor: '#444',
                });

                localStorage.setItem(LocalStorageKey.firstTimeStats, JSON.stringify(false));
            }, 250);
        }
    }

    setGameType(gameType: any) {
        this.activeGameType = _.find(this.gameTypes, { value: gameType });
        if (!this.activeGameType) {
            this.activeGameType = this.gameTypes[0];
        }
        localStorage.setItem('activeGameType', gameType);

        this.loadLastGame();
    }

    setTournamentGameType(gameType: any) {
        this.activeGameType = _.find(this.gameTypes, { value: gameType });
        if (!this.activeGameType) {
            this.activeGameType = this.gameTypes[0];
        }
        localStorage.setItem('activeTournamentGameType', gameType);

        this.loadLastTournament();
    }

    loadLastGame(): void {
        this.setFilters();
        this.loadDates(false);
        this.lastGameLoaded = false;
        this.GetItems(this.activeGameType).then(() => {
            this.lastGameLoaded = true;
        });
    }

    loadLastTournament(): void {
        this.setFilters();
        this.loadDates(false);
        this.lastTournamentLoaded = false;
        this.getTournaments(this.activeGameType).then(() => {
            this.lastTournamentLoaded = true;
        });
    }

    showGameType(gameType: ListGameType) {
        this.ga.trackEvent(gameType.ga.category, gameType.ga.action);

        this.nav.navigateForward(gameType.path, {
            queryParams: {
                view: this.view,
            },
        } as NavigationOptions);
    }

    viewAllTournaments(): void {
        this.nav.navigateForward('tournaments/history');
    }

    async OpenUltimateDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        this.modal
            .create({
                component: dialogComponent,
                componentProps: {
                    highlightedFeatureID: UltimateFeatureID.ALLTIME,
                } as UltimateDialogPayload,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }

    openMenu() {
        this._menu.open('mainmenu');
    }

    toggleMenu() {
        this.showExpandedFilters = !this.showExpandedFilters;
    }

    GetItems(gameType: ListGameType): Promise<any> {
        return new Promise((resolve) => {
            this.lastGame = null;

            switch (gameType.gameMode) {
                case 'match':
                    this.gameApiService
                        .getMatches({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
                case 'cricket_tactics':
                    this.gameApiService
                        .getCricketTactics({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
                case 'around_the_clock':
                    this.gameApiService
                        .getAroundTheClocks({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
                case 'bobs_27':
                    this.gameApiService
                        .getBobs27s({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
                case 'shanghai':
                    this.gameApiService
                        .getShanghais({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
                case 'singles_training':
                    this.gameApiService
                        .getSinglesTrainings({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
                case 'doubles_training':
                    this.gameApiService
                        .getDoublesTrainings({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
                case 'score_training':
                    this.gameApiService
                        .getScoreTrainings({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
                case 'checkout_121':
                    this.gameApiService
                        .getCheckout121s({
                            limit: 1,
                            page: 1,
                            is_verified: true,
                            from_date: this.fromDate,
                            to_date: this.untilDate,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].started_at = moment(res.data.data[0].started_at).toISOString();
                                this.lastGame = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
            }
        });
    }

    getTournaments(gameType: ListGameType): Promise<any> {
        return new Promise((resolve) => {
            this.lastTournament = null;

            switch (gameType.gameMode) {
                case 'match':
                    this.tournamentApiService
                        .getTournaments({
                            list_type: 'participant_history',
                            limit: 1,
                            page: 1,
                        })
                        .then((res) => {
                            if (res.data.data) {
                                res.data.data[0].starting_at = moment(res.data.data[0].starting_at).toISOString();
                                this.lastTournament = res.data.data[0];
                            }
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(true);
                        });
                    break;
            }
        });
    }

    playFirstGame() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.STARTNEWGAME);
        this.navCtrl.navigateForward(
            'local-games/' + this.activeGameType.gameMode.toString().replace(new RegExp('_', 'g'), '-')
        );
    }

    playFirstTournament() {
        this.navCtrl.navigateRoot('tournaments');
    }

    goToLastGameDetail() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.SHOWLASTGAME);
        this.nav.navigateForward(
            'statistics/' + this.activeGameType.gameMode.toString().replace(new RegExp('_', 'g'), '-') + '/details',
            {
                queryParams: {
                    item: this.lastGame,
                    gameId: this.lastGame.id,
                },
            }
        );
    }

    goToLastTournamentDetail() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.SHOWLASTTOURNAMENT);
        this.nav.navigateForward(`tournaments/${this.lastTournament.slug}`);
    }

    public changeView(view: StatisticsView): void {
        this.view = view;

        if (view === 'games') {
            const storedSettings = localStorage.getItem('activeGameType');
            if (storedSettings) {
                this.setGameType(storedSettings);
            } else {
                this.setGameType('match');
            }
        } else if (view === 'tournaments') {
            this.ga.trackView('Tournaments | Statistics');

            const storedSettings = localStorage.getItem('activeTournamentGameType');
            if (storedSettings) {
                this.setTournamentGameType(storedSettings);
            } else {
                this.setTournamentGameType('match');
            }
        }
    }
}
