import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@dc-core/dc-backend/dc-classes';
import { ModalController, NavController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

export type RematchInviteDialogPayload = {
    user: User;
};

@Component({
    selector: 'app-rematch-invite-dialog',
    templateUrl: 'rematch-invite.dialog.html',
    styleUrls: ['rematch-invite.dialog.scss'],
})
export class RematchInviteDialogComponent {
    @Input() user: User;

    constructor(
        public view: ModalController,
        public route: ActivatedRoute,
        public nav: NavController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController
    ) {}

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
