import { Component, inject, input, InputSignal } from '@angular/core';
import { TrialStatusResponse } from '@dc-core/dc-backend/dc-responses';
import { ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UpgradeService } from '@providers/UpgradeService';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-games-trial-banner',
    standalone: true,
    imports: [IconComponent, TranslateModule],
    templateUrl: './games-trial-banner.component.html',
    styleUrl: './games-trial-banner.component.css',
})
export class GamesTrialBannerComponent {
    public trialStatus: InputSignal<TrialStatusResponse | undefined> = input();
    public applyBottomOffset: InputSignal<boolean> = input(false);

    private upgradeService: UpgradeService = inject(UpgradeService);
    private modalController: ModalController = inject(ModalController);

    public async openUpgradeDialog() {
        const dialogComponent = await this.upgradeService.GetUpgradeDialog(GA_EVENTCATEGORIES.GLOBALLOBBY);
        this.modalController
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }
}
