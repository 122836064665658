import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class GameStatisticsApiService extends ApiService {
    public getOverallStatistics = super.getFetcher().path('/statistics/overall').method('get').create();

    // Match
    public getMatchThreeDartAvgStatistics = super
        .getFetcher()
        .path('/game-statistics/match/three-dart-avg')
        .method('get')
        .create();
    public getMatchFirstNineAvgStatistics = super
        .getFetcher()
        .path('/game-statistics/match/first-nine-avg')
        .method('get')
        .create();
    public getMatchCheckoutRateStatistics = super
        .getFetcher()
        .path('/game-statistics/match/checkout-rate')
        .method('get')
        .create();
    public getMatchCheckoutsStatistics = super
        .getFetcher()
        .path('/game-statistics/match/checkouts')
        .method('get')
        .create();
    public getMatchLegsStatistics = super.getFetcher().path('/game-statistics/match/legs').method('get').create();

    // Around The Clock
    public getAroundTheClockHitRateStatistics = super
        .getFetcher()
        .path('/game-statistics/around-the-clock/hit-rate')
        .method('get')
        .create();
    public getAroundTheClockDartsThrownStatistics = super
        .getFetcher()
        .path('/game-statistics/around-the-clock/darts-thrown')
        .method('get')
        .create();
    public getAroundTheClockTotalScoreStatistics = super
        .getFetcher()
        .path('/game-statistics/around-the-clock/total-score')
        .method('get')
        .create();

    // Cricket Tactics
    public getCricketTacticsMarksPerRoundStatistics = super
        .getFetcher()
        .path('/game-statistics/cricket-tactics/marks-per-round')
        .method('get')
        .create();
    public getCricketTacticsTotalScoreStatistics = super
        .getFetcher()
        .path('/game-statistics/cricket-tactics/total-score')
        .method('get')
        .create();

    // Bob's 27
    public getBobs27TotalScoreStatistics = super
        .getFetcher()
        .path('/game-statistics/bobs-27/total-score')
        .method('get')
        .create();
    public getBobs27AvgHitsPerTurnStatistics = super
        .getFetcher()
        .path('/game-statistics/bobs-27/avg-hits-per-turn')
        .method('get')
        .create();
    public getBobs27AvgTurnsTakenStatistics = super
        .getFetcher()
        .path('/game-statistics/bobs-27/avg-turns-taken')
        .method('get')
        .create();
    public getBobs27DoubleHitRateStatistics = super
        .getFetcher()
        .path('/game-statistics/bobs-27/double-hit-rate')
        .method('get')
        .create();

    // Shanghai
    public getShanghaiTotalScoreStatistics = super
        .getFetcher()
        .path('/game-statistics/shanghai/total-score')
        .method('get')
        .create();
    public getShanghaiDartsHitStatistics = super
        .getFetcher()
        .path('/game-statistics/shanghai/darts-hit')
        .method('get')
        .create();
    public getShanghaiHitRateStatistics = super
        .getFetcher()
        .path('/game-statistics/shanghai/hit-rate')
        .method('get')
        .create();

    // 121 checkout
    public getCheckout121StatisticsDetails = super
        .getFetcher()
        .path('/game-statistics/checkout121/details')
        .method('get')
        .create();
    public getCheckout121Statistics = super.getFetcher().path('/game-statistics/checkout121').method('get').create();

    // Singles training
    public getSinglesTrainingTotalScoreStatistics = super
        .getFetcher()
        .path('/game-statistics/singles-training/total-score')
        .method('get')
        .create();
    public getSinglesTrainingDartsHitStatistics = super
        .getFetcher()
        .path('/game-statistics/singles-training/darts-hit')
        .method('get')
        .create();
    public getSinglesTrainingHitRateStatistics = super
        .getFetcher()
        .path('/game-statistics/singles-training/hit-rate')
        .method('get')
        .create();

    // Doubles training
    public getDoublesTrainingDartsNeededStatistics = super
        .getFetcher()
        .path('/game-statistics/doubles-training/darts-needed')
        .method('get')
        .create();
    public getDoublesTrainingHitRateStatistics = super
        .getFetcher()
        .path('/game-statistics/doubles-training/hit-rate')
        .method('get')
        .create();

    // Score training
    public getScoreTrainingTotalScoreStatistics = super
        .getFetcher()
        .path('/game-statistics/score-training/total-score')
        .method('get')
        .create();
    public getScoreTrainingOneDartAverageStatistics = super
        .getFetcher()
        .path('/game-statistics/score-training/one-dart-average')
        .method('get')
        .create();
    public getScoreTrainingThreeDartAverageStatistics = super
        .getFetcher()
        .path('/game-statistics/score-training/three-dart-average')
        .method('get')
        .create();
}
