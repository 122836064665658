import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';

@Component({
    selector: 'app-checkbox-new',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './checkbox-new.component.html',
    styleUrl: './checkbox-new.component.scss',
})
export class CheckboxNewComponent {
    public reverse: InputSignal<boolean> = input(false);
    public color: InputSignal<'white' | 'black'> = input('white');
    public hasErrors: InputSignal<boolean> = input<boolean>();
}
