<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'ENTER_YOUR_PASSWORD'" i18n="@@ENTER_YOUR_PASSWORD">
            Enter your password
        </div>
    </div>
    <div class="slide-modal-content">
        <div>{{ emailText }}</div>
        <div class="my-4">
            <div class="dialog-input-label" [translate]="'PASSWORD'" i18n="@@PASSWORD">Password</div>
            <input name="password" type="password" ngDefaultControl [(ngModel)]="password" class="dialog-input" />
        </div>
        <app-primary-button
            [translate]="'CONFIRM'"
            i18n="@@CONFIRM"
            class="mt-2"
            size="small"
            [addedClasses]="'h-full'"
            [color]="'orange'"
            (click)="confirm()">
            Confirm
        </app-primary-button>
    </div>
</div>
