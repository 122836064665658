<ion-app [class.web-scroll-container]="isWeb">
    <app-alerts></app-alerts>
    <app-ingame-menu></app-ingame-menu>
    <app-event-overlay></app-event-overlay>
    <app-ingame-animation></app-ingame-animation>

    <div
        class="transition bg-white w-full h-full sticky top-0 bottom-0"
        [ngClass]="{ visible: preferences?.transition }">
        <div class="flex flex-col justify-center items-center w-full h-full">
            <img
                class="max-w-[16rem] mx-auto w-full mb-6"
                [src]="
                    themeService.theme() === 'dark'
                        ? 'assets/icon/dctarget_stacked_orange.png'
                        : 'assets/icon/dctarget_stacked_white.png'
                " />
            <div class="flex">
                <app-icon icon="loading" size="large" class="mx-auto animate-spin text-orange"></app-icon>
            </div>
        </div>
    </div>

    <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
