<app-dialog-view [showCloseButton]="true" (onDismiss)="dismiss()">
    <div title>
        <span i18n="@@SELECT_PAYMENT_METHOD" [translate]="'SELECT_PAYMENT_METHOD'">Select payment method</span>
    </div>

    @if (info) {
        <div sub-title>
            {{ info }}
        </div>
    }

    <div class="gap-4 grid grid-cols-3">
        @for (method of methods; track $index) {
            <div class="col-span-1 cursor-pointer duration-200 hover:opacity-80" (click)="dismiss(method.method)">
                <div class="flex w-full flex-col items-center rounded-md bg-white p-2">
                    <img src="assets/images/payment-methods/{{ method.src }}" class="h-18 w-24" />
                    <span class="font-semibold text-sm text-black">{{ method.name }}</span>
                </div>
            </div>
        }
    </div>
</app-dialog-view>
