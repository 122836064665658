import { Route } from '@angular/router';

export const testingRoutes: Route[] = [
    {
        path: 'testing/virt',
        loadComponent: () => import('./pages/testing-virt/testing-virt.component').then((c) => c.TestingVirtComponent),
    },
    {
        path: 'testing/janus-streaming',
        loadComponent: () =>
            import('./pages/testing-janus-streaming/testing-janus-streaming.component').then(
                (c) => c.TestingJanusStreamingComponent
            ),
    },
];
