<div class="zoomed-omni-dart w-full global-lobby-shadow" (click)="editZoomedPoint()">
    <div class="zoomed-container !text-white">
        <svg
            #svgElement
            class="zoomed-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 453 453"
            preserveAspectRatio="xMidYMid meet">
            <!-- Add any initial content or keep it empty if it's dynamically loaded -->
        </svg>

        <!-- Overlay for placeholder states -->
        <div
            [class]="{
                animate__animated: true,
                animate__fadeOut: omniThrowResponseState === 'THROW' || omniThrowResponseState === 'IDLE',
                animate__fadeIn: omniThrowResponseState !== 'THROW'
            }"
            class="flex justify-center items-center text-center omni-placeholder h-full w-full font-bebas-neue bg-[#1e1e1ee3] text-xl !text-white top-1/2 left-1/2 absolute z-10">
            @if (omniThrowResponseState === 'WAITING' && !turnEnded()) {
                Waiting for dart
            }
            @if (omniThrowResponseState === 'WAITING' && turnEnded()) {
                Remove your darts
            } @else if (omniThrowResponseState === 'BOUNCEOUT') {
                Bounce-out
            } @else if (omniThrowResponseState === 'MISS') {
                Miss
            }
        </div>
    </div>
    <div
        class="relative w-full border-2 !border-neutral-50 !bg-neutral-50 px-2 pt-1"
        style="border-radius: 0 0 9px 9px">
        <app-keyboard-dart class="block w-2/3 !text-black"></app-keyboard-dart>
        <div class="in-game-score-field">
            {{ point()?.singleDart.amount * point()?.singleDart.multiplier || '' }}
        </div>
        <div class="absolute bottom-1 right-1 font-bebas-neue !text-black">
            {{ getScoreEvent(point()) }}
        </div>
    </div>
</div>
