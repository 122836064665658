// import { Injectable } from '@angular/core';
import { User } from 'dc-core/dc-backend/dc-classes';
import {
    AddPlayerPayload,
    RemovePlayerPayload,
    RemoveTeamPayload,
    ReorderUserPayload,
} from 'dc-core/dc-gamelogic/game-interfaces';
import { LOCAL_GAME_SETTINGS_STORAGE_KEYS, LOCAL_GAME_UNFINISHED_KEYS } from 'dc-core/dc-gamelogic/game-storage';
import { PlayerType } from '../settings.globals';

// @Injectable()
export class DartCounterGameSettings {
    public user: User;
    public storageKey: LOCAL_GAME_SETTINGS_STORAGE_KEYS;
    public unfinishedKey: LOCAL_GAME_UNFINISHED_KEYS;

    public playerTypes: { label: string; tag: string; type: PlayerType }[];
    public playerType: PlayerType = 'players';
    public users: User[] = [];
    public teams: { users: User[] }[] = [];

    constructor() {}

    public setUser(user: User) {
        this.user = user;
        if (this.playerType === 'players') {
            if (!this.users.length) {
                this.addUser(user);
            } else {
                this.users.forEach((userInLoop, userIndex) => {
                    if (user.id == userInLoop.id) {
                        this.users[userIndex] = user;
                    }
                });
            }
        } else if (this.playerType === 'teams') {
            if (!this.teams.length) {
                this.addTeam();
                this.addUser(user, this.teams[0]);
            } else {
                this.teams.forEach((team, teamIndex) => {
                    team.users.forEach((userInLoop, userIndex) => {
                        if (user.id == userInLoop.id) {
                            this.teams[teamIndex].users[userIndex] = user;
                        }
                    });
                });
            }
        }
    }

    hasOwnUser(): boolean {
        let foundOwnUser = false;
        if (this.playerType === 'players') {
            this.users.forEach((user) => {
                if (this.user.id == user.id) {
                    foundOwnUser = true;
                }
            });
        } else if (this.playerType === 'teams') {
            this.teams.forEach((team) => {
                team.users.forEach((user) => {
                    if (this.user.id == user.id) {
                        foundOwnUser = true;
                    }
                });
            });
        }
        return foundOwnUser;
    }

    changePlayerType(playerType: PlayerType): void {
        if (playerType === 'players') {
            this.users = [];
            if (this.teams.length) {
                this.teams.forEach((team) => {
                    team.users.forEach((user) => this.users.push(user));
                });
            }
        } else if (playerType === 'teams') {
            if (this.users.length) {
                if (this.users.length <= 2) {
                    this.teams = [];
                    this.users.forEach((user) => {
                        this.teams.push({ users: [user] });
                    });
                } else {
                    this.teams = [{ users: [] }, { users: [] }];
                    this.users.forEach((user, index) => {
                        const teamIndex = index < 2 ? 0 : 1;
                        this.teams[teamIndex].users.push(user);
                    });
                }
            } else {
                this.teams = [];
            }
        }

        this.playerType = playerType;
    }

    addPlayer(event: AddPlayerPayload): void {
        this.addUser(event.user, event.team);
    }

    addUser(user: User, team = null): void {
        if (this.playerType === 'players' || team == null) {
            if (this.users.length < 4) {
                this.users.push(user);
            }
        } else if (this.playerType === 'teams') {
            if (team.users.length < 2) {
                team.users.push(user);
            }
        }
    }

    updateUser(teamIndex: number, userIndex: number, user: User): void {
        if (this.playerType === 'players' || teamIndex == null) {
            this.users[userIndex] = user;
        } else if (this.playerType === 'teams') {
            this.teams[teamIndex].users[userIndex] = user;
        }
    }

    addTeam(): void {
        if (this.playerType === 'teams' && this.teams.length < 4) {
            this.teams.push({ users: [] });
        }
    }

    removePlayer(event: RemovePlayerPayload): User {
        let removedUser: User = null;
        if (event.teamIndex != null) {
            removedUser = this.teams[event.teamIndex].users[event.userIndex];
            this.teams[event.teamIndex].users.splice(event.userIndex, 1);
        } else {
            removedUser = this.users[event.userIndex];
            this.users.splice(event.userIndex, 1);
        }

        return removedUser;
    }

    removeTeam(event: RemoveTeamPayload): void {
        this.teams[event.teamIndex].users.forEach((user, userIndex) => {
            if (user.is_cpu) {
                this.removePlayer({ userIndex, teamIndex: event.teamIndex });
            }
        });

        this.teams.splice(event.teamIndex, 1);
    }

    reorderUser(event: ReorderUserPayload): void {
        if (event.currentIndex !== event.newIndex) {
            if (event.teamIndex != null) {
                const element = this.teams[event.teamIndex].users[event.currentIndex];
                this.teams[event.teamIndex].users.splice(event.currentIndex, 1);
                this.teams[event.teamIndex].users.splice(event.newIndex, 0, element);
            } else {
                const element = this.users[event.currentIndex];
                this.users.splice(event.currentIndex, 1);
                this.users.splice(event.newIndex, 0, element);
            }
        }
    }

    checkUsersForHeadToHead(): User[] | null {
        let users: User[] = [];
        if (this.playerType === 'teams') {
            if (this.teams.length === 2) {
                for (let team of this.teams) {
                    if (team.users.length === 1) {
                        for (let user of team.users) {
                            if (user.id !== null && !user.is_cpu) {
                                users.push(user);
                            }
                        }
                    }
                }
            }
        } else {
            if (this.users.length === 2) {
                for (let user of this.users) {
                    if (user.id !== null && !user.is_cpu) {
                        users.push(user);
                    }
                }
            }
        }

        if (users.length === 2) {
            if (users[0].id == users[1].id) {
                return null;
            }

            return users;
        }

        return null;
    }
}
