import { Injectable } from '@angular/core';
import { DocumentData } from '@angular/fire/compat/firestore';
import {
    addDoc,
    collection,
    deleteDoc,
    DocumentReference,
    Firestore,
    getDocs,
    limit,
    onSnapshot,
    query,
    Timestamp,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { DCFireStoreRating, FIRESTORE_COLLECTION } from '../globals/firestore.tables';
import { UsersCollectionService } from './users.collection.service';

@Injectable()
export class RatingsCollectionService extends FireStoreCollectionsService {
    private defaultClass = new DCFireStoreRating();

    private _lastTimestamp: Timestamp = Timestamp.now();

    constructor(firestore: Firestore, private _usersCollectionService: UsersCollectionService) {
        super(firestore);
    }

    sendFirestoreRating(rating: DCFireStoreRating) {
        let receiverDoc = this._usersCollectionService.getDocByID(rating.receiver_uid);

        const receiverRatingsCollection = collection(receiverDoc, FIRESTORE_COLLECTION.RATINGS);
        addDoc(receiverRatingsCollection, rating);
    }

    updateItem(ref: DocumentReference<DocumentData>, updatedValues: DocumentData): Promise<void> {
        return updateDoc(ref, updatedValues);
    }

    watchIncomingRatings(): Observable<DCFireStoreRating> {
        const collectionRef = collection(this._usersCollectionService.activeUserDocRef, FIRESTORE_COLLECTION.RATINGS);

        return new Observable<DCFireStoreRating>((observer) => {
            try {
                const sentAtClause = this.getAttributeString(
                    this.defaultClass,
                    (obj: DCFireStoreRating) => obj.sent_at
                );
                let ownRatings = query(collectionRef, where(sentAtClause, '>', this._lastTimestamp), limit(1));

                const unsubscribe = onSnapshot(ownRatings, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as DCFireStoreRating;
                        if (change.type === 'added') {
                            observer.next(data);
                        }
                    });
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    removeIncomingRatings(): void {
        try {
            const myRatingsCollection = collection(
                this._usersCollectionService.activeUserDocRef,
                FIRESTORE_COLLECTION.RATINGS
            );

            getDocs(myRatingsCollection).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    deleteDoc(doc.ref);
                });
            });
        } catch (err) {
            console.error(err);
        }
    }
}
