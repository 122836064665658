<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="dismiss()"></app-icon>
        <div class="title-container">
            <div class="title" [translate]="'ACTIVE_GAME'" i18n="@@ACTIVE_GAME">Active game</div>
            <div class="subtitle" [translate]="'ACTIVE_GAME_INFO'" i18n="@@ACTIVE_GAME_INFO">
                You still have an active game in the lobby, you can leave or continue to the game.
            </div>
        </div>

        <div class="mt-4 w-full flex flex-col" *ngIf="gameplay">
            <div class="flex items-center justify-between rounded-t-md bg-neutral-100 dark:bg-black-full px-4 py-2.5">
                <div class="font-bold text-black">{{ onlineFunctions.publicOnlineGameplayName(gameplay) }}</div>
            </div>
            <div class="grid grid-cols-11 bg-neutral-50 dark:bg-neutral-900 px-4 py-2 rounded-b-md">
                <ng-container *ngTemplateOutlet="firstPlayer; context: { user: gameplay.players[0] }"></ng-container>
                <div class="col-span-3 flex items-center justify-center font-bebas-neue">
                    <app-match-interim-score
                        size="lobby"
                        [type]="gameplay.has_sets ? 'sets' : 'legs'"
                        [showLabel]="true">
                        {{ onlineFunctions.getPublicInterim(gameplay) }}
                    </app-match-interim-score>
                </div>
                <ng-container *ngTemplateOutlet="secondPlayer; context: { user: gameplay.players[1] }"></ng-container>
            </div>
        </div>
        <div class="button-container flex justify-between space-x-2">
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="red"
                (click)="quit()"
                [translate]="'QUIT'"
                i18n="@@QUIT">
                Quit
            </app-primary-button>
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="green"
                (click)="continue()"
                [translate]="'CONTINUE'"
                i18n="@@CONTINUE">
                Continue
            </app-primary-button>
        </div>
    </div>
</modal-content>

<ng-template #firstPlayer let-user="user">
    <div class="col-span-4 flex items-center justify-start space-x-2">
        <app-profile-photo
            class="h-fit"
            [ultimate]="user.is_ultimate"
            [badge]="user.badge"
            [url]="user.profile_photo_url"
            [size]="32">
        </app-profile-photo>
        <div class="space-y-0.5 overflow-hidden">
            <div class="flex items-center space-x-1">
                <div class="truncate text-xs font-bold text-black">
                    {{ user.first_name || ('NO_NAME' | translate) }}
                </div>
                <img class="h-2.5" *ngIf="user.countryFlag" src="assets/images/flags/rect/{{ user.countryFlag }}" />
            </div>
            <app-online-player-stats [player]="user"></app-online-player-stats>
        </div>
    </div>
</ng-template>

<ng-template #secondPlayer let-user="user">
    <div class="col-span-4 flex items-center justify-end space-x-2">
        <div class="space-y-0.5 overflow-hidden">
            <div class="flex items-center justify-end space-x-1">
                <div class="truncate text-xs font-bold text-black">
                    {{ user.first_name || ('NO_NAME' | translate) }}
                </div>
                <img class="h-2.5" *ngIf="user.countryFlag" src="assets/images/flags/rect/{{ user.countryFlag }}" />
            </div>
            <app-online-player-stats [player]="user"></app-online-player-stats>
        </div>
        <app-profile-photo
            class="h-fit"
            [ultimate]="user.is_ultimate"
            [badge]="user.badge"
            [url]="user.profile_photo_url"
            [size]="32">
        </app-profile-photo>
    </div>
</ng-template>
