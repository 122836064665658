import { Component } from '@angular/core';
import { AuthApiService } from '@dc-api/auth.api.service';
import { ModalController, NavParams } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-guest-transition-dialog',
    templateUrl: 'guest-transition.dialog.html',
})
export class GuestTransitionDialogComponent {
    public email = '';
    public newsletterOptIn = false;
    public password = '';

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public loading: DCLoadingService,
        private _alertService: DartCounterAlertService,
        private navParams: NavParams,
        private _authApiService: AuthApiService
    ) {}

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    submit() {
        if (!this.email.replace(/\s/g, '').length || !this.password.replace(/\s/g, '').length) {
            this._alertService.createAlert({
                title: $localize`:@@FILL_IN_REQUIRED_FIELDS:Please fill in the required fields`,
                icon: 'error',
            });
        } else {
            this._authApiService
                .transitionGuestToUser({
                    email: this.email,
                    password: this.password,
                    newsletter_opt_in: this.newsletterOptIn,
                })
                .then((res) => {
                    this.auth.saveAuthentication(res.data.user, res.data.access_token);

                    if (res.data.message && res.data.user.newsletter_opt_in) {
                        this._alertService.createAlert({
                            title: res.data.message,
                            icon: 'success',
                        });
                    }

                    this.dismiss();
                })
                .catch((err) => {
                    this._alertService.errorFromApi(err);
                });
        }
    }
}
