import { Component, HostListener, OnInit } from '@angular/core';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { CommandType } from '@dc-core/dc-services/speech-to-score/speech-commands';
import {
    DartCounterSpeechToScoreService,
    SpeechCommandSubject,
} from '@dc-core/dc-services/speech-to-score/speech-to-score.service';
import { ModalController, NavParams } from '@ionic/angular';
import { CheckoutDialogResult } from 'dc-core/dc-gamelogic/game-interfaces';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-throwout-dialog',
    templateUrl: 'throwout.html',
    styleUrls: ['throwout.scss'],
})
export class ThrowoutDialogComponent implements OnInit {
    public possibleDarts: number[];
    public selectedDart: number;

    public possibleDartsAtDouble: number[];
    public selectedDartAtDouble: number;

    public doubleEntered: boolean = false;
    public isWeb: boolean = environment.isWeb;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        let numberPressed = parseInt(event.key, 10);

        // Check if its an arrow key and increment or decrement the current number
        if (isNaN(numberPressed)) {
            switch (event.key.toLowerCase()) {
                case 'arrowleft':
                    if (!this.doubleEntered) {
                        numberPressed = this.selectedDartAtDouble - 1;
                    } else {
                        numberPressed = this.selectedDart - 1;
                    }
                    break;
                case 'arrowright':
                    if (!this.doubleEntered) {
                        numberPressed = this.selectedDartAtDouble + 1;
                    } else {
                        numberPressed = this.selectedDart + 1;
                    }
                    break;
                case 'arrowdown':
                    if (this.possibleDarts.length > 0 && !this.doubleEntered) {
                        this.doubleEntered = true;
                    }
                    break;
                case 'arrowup':
                    if (this.possibleDarts.length > 0 && this.doubleEntered) {
                        this.doubleEntered = false;
                    }
                    break;
            }
        }

        if (!isNaN(numberPressed)) {
            if (!this.doubleEntered && this.possibleDartsAtDouble.includes(numberPressed)) {
                this.setSelectedDartAtDouble(numberPressed);
            } else if (this.possibleDarts.includes(numberPressed)) {
                this.setSelectedDart(numberPressed);
            }
        }

        if (event.key === 'Enter' && this.selectedDartAtDouble >= 0) {
            if (this.possibleDarts.length > 0) {
                if (!this.doubleEntered) {
                    this.doubleEntered = true;
                } else if (this.selectedDart) {
                    this.ReturnDarts();
                }
            } else {
                this.ReturnDarts();
            }
        }
    }

    constructor(
        public navParams: NavParams,
        public view: ModalController,
        private _alertService: DartCounterAlertService,
        private _speechToScoreService: DartCounterSpeechToScoreService
    ) {
        this.possibleDarts = navParams.get('possibleDarts');
        this.possibleDartsAtDouble = navParams.get('possibleDartsAtDouble');

        this.selectedDart = this.possibleDarts[0];
        this.selectedDartAtDouble = this.possibleDartsAtDouble[0];
    }

    ngOnInit(): void {
        this._speechToScoreService.processedSpeech$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((speechCommand: SpeechCommandSubject) => {
                if (speechCommand.type === CommandType.CHECKOUT) {
                    const first = speechCommand.attributes[0];
                    const second = speechCommand.attributes[1];
                    if (first != null && second != null) {
                        if (this.possibleDartsAtDouble.includes(first) && this.possibleDarts.includes(second)) {
                            this.selectedDartAtDouble = first;
                            this.selectedDart = second;
                            this.ReturnDarts();
                        } else {
                            this._alertService.createAlert({
                                title: $localize`:@@PLEASE_ENTER_A_VALID_CHECKOUT:Please enter a valid checkout`,
                                icon: 'error',
                            });
                        }
                    } else if (first != null && !this.possibleDartsAtDouble.length) {
                        if (this.possibleDartsAtDouble.includes(first)) {
                            this.selectedDartAtDouble = first;
                            this.ReturnDarts();
                        } else {
                            this._alertService.createAlert({
                                title: $localize`:@@PLEASE_ENTER_A_VALID_CHECKOUT:Please enter a valid checkout`,
                                icon: 'error',
                            });
                        }
                    }
                }
            });
    }

    /**
     * Returns the amount of thrown darts to the masterView
     *
     * @param {number} _dartsThrown
     * @memberof ThrowoutDialog
     */
    public ReturnDarts(): void {
        this.view.dismiss({
            dartsAtDouble: this.selectedDartAtDouble,
            dartsForCheckout: this.selectedDart,
        } as CheckoutDialogResult);
    }

    public DismissModal(): void {
        this.view.dismiss();
    }

    setSelectedDartAtDouble(dart) {
        this.selectedDartAtDouble = dart;
    }

    setSelectedDart(dart) {
        this.selectedDart = dart;
    }

    ionViewWillLeave(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
