import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { take } from 'rxjs';

@Component({
    selector: 'app-throw-for-bull-dialog',
    templateUrl: 'throw-for-bull.html',
    styleUrls: ['throw-for-bull.scss'],
})
export class ThrowForBullDialogComponent {
    public teams: Array<any>;
    public selectedPlayer = null;

    constructor(
        public view: ModalController,
        public route: ActivatedRoute,
        public modal: ModalController
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.teams = params.teams;
        });
    }

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    SelectPlayer(player) {
        if (this.selectedPlayer === player) {
            this.selectedPlayer = null;
            return false;
        }

        this.selectedPlayer = player;
    }
}
