<app-page-header mainNavigation="custom" color="transparent">
    <div title [translate]="'LOGIN'" i18n="@@LOGIN">Login</div>
    <app-icon icon="close" (click)="DismissModal()"></app-icon>
</app-page-header>

<ion-content class="login-content" padding>
    <div class="login-box">
        <ion-row *ngIf="showFB">
            <ion-col style="margin: 0 10px">
                <facebook-login [pop]="1"></facebook-login>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="showFB">
            <ion-col>
                <span style="color: var(--white)" [translate]="'OR'" i18n="@@OR">Or</span>
            </ion-col>
        </ion-row>

        <app-login-form></app-login-form>

        <ion-row>
            <ion-col>
                <span style="color: var(--white)" [translate]="'OR'" i18n="@@OR">Or</span>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-button
                    class="extra-btn"
                    color="grey"
                    expand="block"
                    fill="outline"
                    (click)="GoToRegistration()"
                    [translate]="'CREATE_ACOUNT'"
                    i18n="@@CREATE_ACOUNT"
                    >Create account</ion-button
                >
            </ion-col>
        </ion-row>
    </div>
</ion-content>
