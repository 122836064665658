/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, InputSignal, signal, WritableSignal } from '@angular/core';
import { TournamentApiService } from '@dc-api/tournament.api.service';
import { Tournament } from '@dc-core/dc-backend/dc-classes';
import { OnlineGameHelper } from '@dc-core/dc-helpers/online-game.helper';
import { TournamentExtendedStatus, TournamentHelper } from '@dc-core/dc-helpers/tournament.helper';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { TournamentView } from 'src/app/modules/tournaments/pages/tournaments/view/tournament-view.component';

import { PrimaryButtonComponent } from '../../../../primary-button/primary-button.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import {
    TournamentNotificationDialogComponent,
    TournamentNotificationDialogPayload,
} from '../../dialogs/tournament-notification-dialog/tournament-notification.dialog';
import { TournamentService } from '../../services/tournament.service';
import { TournamentTimerComponent } from '../tournament-timer/tournament-timer.component';

@Component({
    selector: 'app-tournament-participating-status',
    templateUrl: 'tournament-participating-status.component.html',
    standalone: true,
    imports: [IconComponent, CommonModule, PrimaryButtonComponent, TournamentTimerComponent, TranslateModule],
})
export class TournamentParticipatingStatusComponent {
    public tournament: InputSignal<Tournament> = input.required();

    public tournamentService: TournamentService = inject(TournamentService);

    private tournamentApiService: TournamentApiService = inject(TournamentApiService);
    private translateService: TranslateService = inject(TranslateService);
    private navController: NavController = inject(NavController);
    private modalController: ModalController = inject(ModalController);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);

    public status: TournamentExtendedStatus;
    public title: string;
    public subtext: string;
    public buttonText: string;
    public timeUntil: string;

    public collapsed: WritableSignal<boolean> = signal(true);

    private isCheckingIn = false;

    constructor() {
        effect(
            () => {
                const tournament = this.tournament();
                if (tournament) {
                    this.determineStatus();
                }
            },
            { allowSignalWrites: true }
        );
    }

    public goToTournament(view: TournamentView = 'info'): void {
        this.navController.navigateForward(`tournaments/${this.tournament().slug}`, {
            queryParams: {
                view,
            },
        });
    }

    public checkIn(): void {
        if (this.isCheckingIn || this.tournament().participation.checked_in_at) {
            return;
        }

        this.isCheckingIn = true;

        this.tournamentApiService
            .checkInForTournament({ tournamentId: this.tournament().id })
            .then((res) => {
                this.ga.trackEvent(GA_EVENTCATEGORIES.TOURNAMENTS, GA_EVENTACTIONS.CHECKIN);

                this.modalController
                    .create({
                        component: TournamentNotificationDialogComponent,
                        componentProps: {
                            tournament: this.tournament(),
                            type: 'checked_in',
                        } as TournamentNotificationDialogPayload,
                        cssClass: 'auto-height',
                        showBackdrop: true,
                        backdropDismiss: true,
                    })
                    .then((elem) => {
                        elem.present();
                    });

                this.tournament().participation = res.data.participant;
                this.tournament().current_participants = res.data.tournament.current_participants;
                this.determineStatus();

                this.tournamentService.updatedTournament.set(this.tournament());
                this.tournamentService.setNewParticipatingTournament(this.tournament());
            })
            .finally(() => (this.isCheckingIn = false));
    }

    public goToGame(): void {
        this.navController.navigateForward(
            OnlineGameHelper.getGameRoute(
                this.tournament().game_mode,
                this.tournament().participation?.current_game?.active_game_doc_id,
                false
            )
        );
    }

    public toggleCollapse(): void {
        this.collapsed.set(!this.collapsed());
    }

    public nextStatus(): void {
        if (this.status === 'waiting_for_check_in') {
            this.status = 'check_in_period';
        } else if (this.status === 'check_in_period' || this.status === 'checked_in') {
            this.status = 'starting';
        }
        this.setStatus();
    }

    private determineStatus(): void {
        this.status = TournamentHelper.getPlayStatus(this.tournament());
        this.setStatus();
    }

    private setStatus(): void {
        switch (this.status) {
            case 'waiting_for_approval':
                this.title = $localize`:@@TOURNAMENT_WAITING_FOR_APPROVAL:Tournament waiting for approval`;
                $localize`:@@WAIT_FOR_HOST_TO_APPROVE_JOIN_REQUEST:Wait for the host to approve your join request in the ${this.tournament().name}:name: tournament`;
                this.subtext = this.translateService.instant('WAIT_FOR_HOST_TO_APPROVE_JOIN_REQUEST', {
                    name: this.tournament().name,
                });
                this.timeUntil = null;
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'waiting_for_check_in':
                this.title = $localize`:@@TOURNAMENT_WAITING_FOR_CHECK_IN:Tournament waiting for check-in`;
                $localize`:@@WAIT_FOR_CHECK_IN_PERIOD_TO_START:You need to wait for the check-in period to start for the ${this.tournament().name}:name: tournament`;
                this.subtext = this.translateService.instant('WAIT_FOR_CHECK_IN_PERIOD_TO_START', {
                    name: this.tournament().name,
                });
                this.timeUntil = this.tournament().check_in_from;
                this.buttonText = null;
                break;
            case 'check_in_period':
                this.title = $localize`:@@TOURNAMENT_CHECK_IN_REQUIRED:Tournament check-in required`;
                $localize`:@@CHECK_IN_IS_NOW_OPEN_FOR_THE_TOURNAMENT:Check-in is now open for the ${this.tournament().name}:name: tournament`;
                this.subtext = this.translateService.instant('CHECK_IN_IS_NOW_OPEN_FOR_THE_TOURNAMENT', {
                    name: this.tournament().name,
                });
                break;
            case 'checked_in':
                this.title = $localize`:@@TOURNAMENT_IS_STARTING_SOON:Tournament is starting soon`;
                $localize`:@@PLEASE_WAIT_FOR_THE_TOURNAMENT_TO_START:Please wait for the ${this.tournament().name}:name: tournament to start`;
                this.subtext = this.translateService.instant('PLEASE_WAIT_FOR_THE_TOURNAMENT_TO_START', {
                    name: this.tournament().name,
                });
                this.timeUntil = this.tournament().starting_at;
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'starting':
                this.title = $localize`:@@TOURNAMENT_IS_STARTING_SOON:Tournament is starting soon`;
                $localize`:@@GET_READY_TO_THROW_GOOD_DARTS_IN_THE_TOURNAMENT:Get ready to throw good darts in the ${this.tournament().name}:name: tournament`;
                this.subtext = this.translateService.instant('GET_READY_TO_THROW_GOOD_DARTS_IN_THE_TOURNAMENT', {
                    name: this.tournament().name,
                });
                this.timeUntil = null;
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'playing':
                if (
                    this.tournament().participation?.current_game &&
                    this.tournament().participation.current_game.active_game_doc_id &&
                    !this.tournament().participation?.current_game.finished_at
                ) {
                    this.title = $localize`:@@TOURNAMENT_GAME_HAS_STARTED:Tournament game has started`;
                    $localize`:@@JOIN_YOUR_GAME_IN_THE_TOURNAMENT:Join your game in the ${this.tournament().name}:name: tournament`;
                    this.subtext = this.translateService.instant('JOIN_YOUR_GAME_IN_THE_TOURNAMENT', {
                        name: this.tournament().name,
                    });
                    this.timeUntil = null;
                    this.buttonText = null;
                    this.collapsed.set(false);
                } else {
                    this.title = $localize`:@@TOURNAMENT_GAME_STARTING_SOON:Tournament game starting soon`;
                    $localize`:@@WAIT_FOR_YOUR_NEXT_GAME_IN_TOURNAMENT:Wait for your next game in the ${this.tournament().name}:name: tournament`;
                    this.subtext = this.translateService.instant('WAIT_FOR_YOUR_NEXT_GAME_IN_TOURNAMENT', {
                        name: this.tournament().name,
                    });
                    this.timeUntil = null;
                    this.buttonText = $localize`:@@GO_TO_BRACKET:Go to bracket`;
                }
                break;
        }
    }

    public static checkIfDashboardStatusIsShown(status: TournamentExtendedStatus): boolean {
        switch (status) {
            case 'waiting_for_approval':
            case 'waiting_for_check_in':
            case 'check_in_period':
            case 'checked_in':
            case 'starting':
            case 'playing':
                return true;
            default:
                return false;
        }
    }
}
