/* eslint-disable max-len */
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameApiService } from '@dc-api/game.api.service';
import {
    CricketTacticsLeg,
    CricketTacticsResult,
    CricketTacticsSet,
    CricketTacticsUser,
} from '@dc-core/dc-backend/dc-classes';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { RouterEventsService } from '@services/router-events.service';
import * as moment from 'moment';
import { take } from 'rxjs';
import {
    AssignGameLabelsDialogComponent,
    AssignGameLabelsDialogPayload,
} from 'src/dialogs/assign-game-labels/assign-game-labels.dialog';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-cricket-tactics-details',
    templateUrl: 'cricket-tactics-details.component.html',
    styleUrls: ['cricket-tactics-details.component.scss'],
})
export class CricketTacticsDetailsComponent {
    public translateService: TranslateService = inject(TranslateService);

    public title: string;
    public item: any = null;
    public teams: any;
    gameValues: any[] = [];

    public sets: {
        index: number;
        winner: string | null;
        expanded: boolean;
        legs: {
            index: number;
            winner: string | null;
            results: CricketTacticsResult[];
            scores: number[];
            expanded: boolean;
        }[];
    }[] = [];

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private loading: DCLoadingService,
        private modal: ModalController,
        public route: ActivatedRoute,
        private gameApiService: GameApiService,
        private _previousRouteService: RouterEventsService
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.item = params.item;
            if ((!this.item || !this.item.id) && params.gameId) {
                this.item = {
                    id: params.gameId,
                };
            }

            if (this.item.id) {
                this.gameApiService
                    .getCricketTacticsById({ cricketTacticsId: this.item.id })
                    .then((res) => {
                        this.loading.DismissLoader();
                        this.item = res.data;
                        this.item.started_at = moment(this.item.started_at).toISOString();

                        if (this.item.settings == 'cricket') {
                            this.gameValues = [
                                { val: 'n20', title: 20 },
                                { val: 'n19', title: 19 },
                                { val: 'n18', title: 18 },
                                { val: 'n17', title: 17 },
                                { val: 'n16', title: 16 },
                                { val: 'n15', title: 15 },
                                { val: 'n25', title: 'BULL' },
                            ];
                        } else {
                            this.gameValues = [
                                { val: 'n20', title: 20 },
                                { val: 'n19', title: 19 },
                                { val: 'n18', title: 18 },
                                { val: 'n17', title: 17 },
                                { val: 'n16', title: 16 },
                                { val: 'n15', title: 15 },
                                { val: 'n14', title: 14 },
                                { val: 'n13', title: 13 },
                                { val: 'n12', title: 12 },
                                { val: 'n11', title: 11 },
                                { val: 'n10', title: 10 },
                                { val: 'n25', title: 'BULL' },
                            ];
                        }

                        if (this.item.has_sets) {
                            this.item.sets.forEach((set: CricketTacticsSet, setIndex) => {
                                const legs = [];
                                set.legs.forEach((leg: CricketTacticsLeg, legIndex) => {
                                    legs.push({
                                        index: legIndex + 1,
                                        winner: this.getWinnerForTeamLeg(leg),
                                        results: leg.results,
                                        scores: this.getLegTotalScore(leg),
                                    });
                                });
                                this.sets.push({
                                    index: setIndex + 1,
                                    winner: this.getWinnerForTeamSet(set),
                                    legs,
                                    expanded: false,
                                });
                            });
                        } else {
                            const legs = [];
                            this.item.legs.forEach((leg: CricketTacticsLeg, index) => {
                                legs.push({
                                    index: index + 1,
                                    winner: this.getWinnerForTeamLeg(leg),
                                    results: leg.results,
                                    scores: this.getLegTotalScore(leg),
                                });
                            });
                            this.sets.push({
                                index: 1,
                                winner: this.getWinnerForGame(),
                                legs,
                                expanded: false,
                            });
                        }
                    })
                    .catch((err) => {
                        console.error(err);

                        setTimeout(() => {
                            this.loading.DismissLoader();
                        }, 250);
                        // Error go back
                        this.nav.pop();
                    });
            }
        });
    }

    getWinnerForGame(): string {
        if (this.item.has_teams) {
            for (const team of this.item.teams) {
                for (const user of team.users) {
                    if (user.result === 'won') {
                        return this.GetPlayers(team);
                    }
                }
            }
        } else {
            for (const user of this.item.users) {
                if (user.result === 'won') {
                    return this.getCricketTacticsUserName(user);
                }
            }
        }
        return '';
    }

    getWinnerForTeamSet(set: CricketTacticsSet): string | null {
        if (this.item.has_teams) {
            for (const team of this.item.teams) {
                for (const user of team.users) {
                    if (set.winner_id === user.id) {
                        return team.name;
                    }
                }
            }
        } else {
            for (const user of this.item.users) {
                if (set.winner_id === user.id) {
                    return user.name;
                }
            }
        }
        return null;
    }

    getWinnerForTeamLeg(leg: CricketTacticsLeg): string | null {
        if (this.item.has_teams) {
            for (const team of this.item.teams) {
                for (const user of team.users) {
                    if (leg.winner_id === user.id) {
                        return team.name;
                    }
                }
            }
        } else {
            for (const user of this.item.users) {
                if (leg.winner_id === user.id) {
                    return user.name;
                }
            }
        }
        return null;
    }

    getLegTotalScore(leg: CricketTacticsLeg): number[] {
        const scores = [];
        if (this.item.has_teams) {
            for (const team of this.item.teams) {
                let totalScore = 0;
                team.users.forEach((user) => {
                    leg.turns.forEach((turn) => {
                        if (turn.cricket_tactics_user_id === user.id) {
                            totalScore += turn.total_score;
                        }
                    });
                });
                scores.push(totalScore);
            }
        } else {
            this.item.users.forEach((user) => {
                let totalScore = 0;
                leg.turns.forEach((turn) => {
                    if (turn.cricket_tactics_user_id === user.id) {
                        totalScore += turn.total_score;
                    }
                });
                scores.push(totalScore);
            });
        }
        return scores;
    }

    GetTeamTotalScore(team): number {
        let totalScore = 0;
        team.users.forEach((user) => {
            totalScore += user.total_score;
        });
        return totalScore;
    }

    GetPlayers(team): string {
        const playerNames = team.users.map((user) => {
            return this.getCricketTacticsUserName(user);
        });

        return playerNames.join(', ');
    }

    getCricketTacticsUserName(cricketTacticsUser: CricketTacticsUser): string {
        let name = '';

        if (cricketTacticsUser.user) {
            name = cricketTacticsUser.user.first_name;
        } else {
            name = cricketTacticsUser.ghost_name;
        }

        if (cricketTacticsUser.is_cpu) {
            name = name + ' (' + cricketTacticsUser.cpu_level + ')';
        }

        return name;
    }

    TryRemove() {
        //Show Dialog, do you want to invite your opponent to continue this match?
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@REMOVE_GAME:Remove game`,
                    text: $localize`:@@REMOVING_UNVERIFIED_GAME:Are you sure you want to delete this game from your statistics?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        $localize`:@@REMOVING_IN_PROGRESS:We're removing the game, please wait`;
                        this.loading
                            .ShowCustomLoader({
                                showBackdrop: false,
                                spinner: 'dots',
                                cssClass: 'whiteText',
                                message: this.translateService.instant('REMOVING_IN_PROGRESS'),
                            })
                            .then(() => {
                                this.gameApiService
                                    .removeCricketTactics({
                                        cricket_tactics_ids: [this.item.id],
                                    })
                                    .then(() => {
                                        this.loading.DismissLoader();
                                        this.back(true);
                                    })
                                    .catch(() => {
                                        this.loading.DismissLoader();
                                    });
                            });
                    }
                });
            });
    }

    assignGameLabels() {
        this.modal
            .create({
                component: AssignGameLabelsDialogComponent,
                componentProps: {
                    gameMode: 'cricket_tactics',
                    gameId: this.item.id,
                    assignedGameLabels: this.item.labels,
                } as AssignGameLabelsDialogPayload,
                cssClass: 'auto-height',
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onWillDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.item.labels = dialogRes.data;
                    }
                });
            });
    }

    back(refresh: boolean): void {
        if (this._previousRouteService.getPreviousUrl()?.includes('statistics/cricket-tactics/list')) {
            this.nav.navigateBack('statistics/cricket-tactics/list', {
                queryParams: {
                    refresh,
                },
            });
        } else {
            this.nav.back();
        }
    }

    expandItem(item, items): void {
        if (item.expanded) {
            item.expanded = false;
        } else {
            items.map((listItem) => {
                if (item === listItem) {
                    listItem.expanded = !listItem.expanded;
                } else {
                    listItem.expanded = false;
                }
                return listItem;
            });
        }
    }
}
