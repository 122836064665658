import { NgModule } from '@angular/core';
import { AuthService } from '@services/auth.service';

import { ApiService } from './api.service';
import { AuthApiService } from './auth.api.service';
import { CameraStreamClipApiService } from './camera-stream-clip.api.service';
import { CameraStreamApiService } from './camera-stream.api.service';
import { ConfigApiService } from './config.api.service';
import { ContactApiService } from './contact.api.service';
import { CouponApiService } from './coupon.api.service';
import { CTAApiService } from './cta.api.service';
import { DashboardApiService } from './dashboard.api.service';
import { FirebaseApiService } from './firebase.api.service';
import { FriendApiService } from './friend.api.service';
import { GameLabelApiService } from './game-label.api.service';
import { GameStatisticsApiService } from './game-statistics.api.service';
import { GameApiService } from './game.api.service';
import { JanusServerApiService } from './janus-server.api.service';
import { PaymentApiService } from './payment.api.service';
import { ProfanityApiService } from './profanity.api.service';
import { ProfileApiService } from './profile.api.service';
import { PromotionApiService } from './promotion.api.service';
import { PrivateChatApiService } from './private-chat.api.service';
import { RatingApiService } from './rating.api.service';
import { ReportedUserApiService } from './reported-user.api.service';
import { ScoresApiService } from './scores.api.service';
import { SoundApiService } from './sound.api.service';
import { StatisticsApiService } from './statistics.api.service';
import { SubscriptionApiService } from './subscription.api.service';
import { TransactionApiService } from './transaction.api.service';
import { UserApiService } from './user.api.service';
import { SmartDeviceApiService } from './smart-device.api.service';
import { NewsletterApiService } from './newsletter.api.service';
import { TournamentApiService } from './tournament.api.service';
import { TournamentStatisticsApiService } from './tournament-statistics.api.service';
import { UserNotificationApiService } from './user-notification.api.service';
import { RecapApiService } from './recap.api.service';

@NgModule({
    declarations: [],
    providers: [
        AuthService,
        ApiService,
        AuthApiService,
        CameraStreamClipApiService,
        CameraStreamApiService,
        ConfigApiService,
        ContactApiService,
        CouponApiService,
        CTAApiService,
        DashboardApiService,
        FirebaseApiService,
        FriendApiService,
        GameLabelApiService,
        GameStatisticsApiService,
        GameApiService,
        JanusServerApiService,
        NewsletterApiService,
        PaymentApiService,
        PrivateChatApiService,
        ProfanityApiService,
        ProfileApiService,
        PromotionApiService,
        RatingApiService,
        RecapApiService,
        ReportedUserApiService,
        ScoresApiService,
        SmartDeviceApiService,
        SoundApiService,
        StatisticsApiService,
        SubscriptionApiService,
        TournamentStatisticsApiService,
        TournamentApiService,
        TransactionApiService,
        UserNotificationApiService,
        UserApiService,
    ],
})
export class ApiServicesModule {}
