<div class="rounded-md">
    <div
        class="bg-white px-4 py-2.5 flex items-center justify-between text-lg text-black cursor-pointer"
        [ngClass]="isExpanded ? 'rounded-t-md' : 'rounded-md'"
        (click)="toggleExpand()">
        <!-- Custom header content -->
        <ng-content select="[accordion-header]"></ng-content>
        <div>
            <app-icon [icon]="isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" class="justify-center flex">
            </app-icon>
        </div>
    </div>

    @if (isExpanded) {
        <!-- Custom body content -->
        <ng-content select="[accordion-body]"></ng-content>
    }
</div>
