/* eslint-disable max-len */
import { Component, EventEmitter, Output } from '@angular/core';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { StatisticNumberFormat } from '@dc-core/dc-backend/dc-enums';
import {
    FilterChangedFilter,
    OverallStatDetails,
    StatDetailSegment,
    StatDetailType,
} from '@dc-core/dc-statistics/game-statistics.classes';
import { MatchOverallStats, MatchStatDetails } from '@dc-core/dc-statistics/match/match-overall-stats';
import { DropdownSelectItem, StatisticsRange } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { UltimateFeatureID } from 'src/app/core/app-features/services/app-features.service';
import { CustomScoreDialogComponent } from 'src/dialogs/customscore/customscore.dialog';
import { UltimateDialogPayload } from 'src/dialogs/ultimate-subscription/ultimate-subscription';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-match-table',
    templateUrl: 'match-table.component.html',
    styleUrls: ['match-table.component.scss'],
})
export class MatchTableComponent extends MatchOverallStats {
    @Output() dateChangeEvent = new EventEmitter<void>();

    public periodOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL_TIME:All-time`,
            value: 'all_time',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@THIS_MONTH:This month`,
            value: 'this_month',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@THIS_YEAR:This year`,
            value: 'this_year',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CUSTOM:Custom`,
            value: 'custom',
            ultimateOnly: true,
        },
    ];

    public gameTypeOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL:All`,
            value: 'all',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@LOCAL:Local`,
            value: 'local',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@ONLINE:Online`,
            value: 'online',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@VS_DARTBOT:vs. Dartbot`,
            value: 'vs_cpu',
            ultimateOnly: false,
        },
    ];

    public startScoreOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL:All`,
            value: 'all',
            ultimateOnly: false,
        },
        {
            label: '501',
            value: '501',
            ultimateOnly: false,
        },
        {
            label: '701',
            value: '701',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CUSTOM:Custom`,
            value: 'custom',
            ultimateOnly: true,
        },
    ];

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public gameStatisticsApiService: GameStatisticsApiService,
        public modal: ModalController,
        private upgrade: UpgradeService
    ) {
        super(auth.user, gameStatisticsApiService);
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.OPENMATCHSTATSTABLE);

        this.setFilters(); // Set previous filters if any
        this.loadOverallStats();
    }

    changeFilter(filter: FilterChangedFilter, value: any): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.CHANGEMATCHFILTER);
        switch (filter) {
            // Overall
            case 'statisticsRange':
                this.updateStatisticsRange(value);
                this.dateChangeEvent.emit();
                break;
            case 'statisticsGameType':
                this.updateGameType(value);
                break;

            // Game specific
            case 'startScoreOption':
                this.updateStartScoreOption(value);
                break;
            case 'startScore':
                this.updateStartScore(value);
                break;
        }

        this.loadOverallStats();
    }

    changeStatisticsRange(statisticsRange: StatisticsRange): void {
        super.updateStatisticsRange(statisticsRange, false);
        this.loadOverallStats();
    }

    goToDetails(statsType: StatDetailType, segment: StatDetailSegment) {
        let title = '';
        let numberFormat: StatisticNumberFormat = null;
        switch (statsType) {
            case 'threeDartAvg':
                title = $localize`:@@AVERAGE:3-dart avg.`;
                break;
            case 'firstNineAvg':
                title = $localize`:@@FIRST_9_AVERAGE:First 9 avg.`;
                break;
            case 'checkoutRate':
                title = $localize`:@@CHECKOUT_RATE:Checkout rate`;
                numberFormat = 'percentage';
                break;
            case 'checkouts':
                title = $localize`:@@CHECKOUTS:Checkouts`;
                numberFormat = 'number';
                break;
            case 'legs':
                title = $localize`:@@LEGS:Legs`;
                numberFormat = 'number';
                break;
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, 'View top 25 ' + segment + ' ' + statsType);

        this.nav.navigateForward('statistics/details', {
            queryParams: {
                // Overall
                title,
                numberFormat,
                statsType: statsType,
                segment: segment,
                fromDate: this.fromDate,
                untilDate: this.untilDate,
                // Game mode specific
                gameMode: 'match',
                gameSpecific: {
                    isOnline: this.getIsOnline(),
                    vsCPU: this.getVsCPU(),
                    startScore: this.getStartScore(),
                } as MatchStatDetails,
            } as OverallStatDetails,
        } as NavigationOptions);
    }

    changeStartScore(): void {
        this.modal
            .create({
                component: CustomScoreDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    customScore: this.startScore,
                    minScore: 101,
                    maxScore: 9999,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.startScore = dialogRes.data;
                        this.loadOverallStats();
                    }
                });
            });
    }

    async OpenUltimateDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        this.modal
            .create({
                component: dialogComponent,
                componentProps: {
                    highlightedFeatureID: UltimateFeatureID.ALLTIME,
                } as UltimateDialogPayload,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }
}
