<app-dialog-view [showCloseButton]="false" (onDismiss)="dismiss()">
    <div title>
        @if (slide === 'start') {
            <span>{{ translated('DO_YOU_LIKE') }}</span>
        } @else if (slide === 'no') {
            <span i18n="@@GIVE_US_FEEDBACK" [translate]="'GIVE_US_FEEDBACK'">
                Would you like to give us some feedback?
            </span>
        } @else if (slide === 'yes') {
            <span>{{ translated('RATE_APP') }}</span>
        }
    </div>

    @if (slide === 'yes') {
        <div sub-title>
            <span [translate]="'RATE_OUR_APP_QUESTION'" i18n="@@RATE_OUR_APP_QUESTION">
                Would you please rate our app? Thank you for your support!
            </span>
        </div>
    }

    <div class="button-container flex justify-between space-x-2">
        @if (slide === 'start') {
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="black"
                fill="outline"
                i18n="@@NO"
                [translate]="'NO'"
                (click)="slide = 'no'">
                No
            </app-primary-button>
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="orange"
                fill="full"
                i18n="@@YES"
                [translate]="'YES'"
                (click)="slide = 'yes'">
                Yes
            </app-primary-button>
        } @else if (slide === 'no') {
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="black"
                fill="outline"
                i18n="@@NO"
                [translate]="'NO'"
                (click)="dismiss()">
                No
            </app-primary-button>
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="orange"
                fill="full"
                i18n="@@YES"
                [translate]="'YES'"
                (click)="dismiss('feedback')">
                Yes
            </app-primary-button>
        } @else if (slide === 'yes') {
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="black"
                fill="outline"
                i18n="@@NO_THANKS"
                [translate]="'NO_THANKS'"
                (click)="dismiss()">
                No, thanks
            </app-primary-button>
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="orange"
                fill="full"
                i18n="@@YES"
                [translate]="'YES'"
                (click)="dismiss('rate')">
                Yes
            </app-primary-button>
        }
    </div>
</app-dialog-view>
