import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class CTAApiService extends ApiService {
    public getCTAModules = super.getFetcher().path('/cta-modules').method('get').create();
    public getCTAModuleOverview = super.getFetcher().path('/cta-modules/overview').method('get').create();
    public storeCTAModule = super.getFetcher().path('/cta-modules').method('post').create();
    public updateCTAModuleById = super.getFetcher().path('/cta-modules/{moduleId}').method('put').create();
    public actionCTAModuleById = super.getFetcher().path('/cta-modules/{moduleId}/action').method('post').create();
}
