import { Component, OnInit } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import { UnfinishedGamesService } from '@dc-core/dc-firestore/unfinished-games.service';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { ModalController, Platform } from '@ionic/angular';
import { AuthService } from '@services/auth.service';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { ReinviteDialogComponent } from 'src/pages/online/reinviteDialog/reinviteDialog';

@Component({
    selector: 'app-unfinished-online-games-dropdown',
    templateUrl: 'unfinished-online-games-dropdown.component.html',
})
export class UnfinishedOnlineGamesDropdownComponent implements OnInit {
    opened = false;

    uid = '';

    constructor(
        public auth: AuthService,
        public online: OnlineGamesService,
        public unfinishedGamesService: UnfinishedGamesService,
        public onlineFunctions: OnlineFunctions,
        private _dcFireAuth: FireStoreAuthService,
        private _platform: Platform,
        private _modal: ModalController
    ) {}

    ngOnInit() {
        this.uid = this._dcFireAuth.getCurrentUID();
    }

    public removeAll() {
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_UNFINISHED_GAMES:Delete unfinished games`,
                    text: $localize`:@@DELETE_THESE_UNFINISHED_GAMES:Do you want to delete these unfinished games?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinishedGamesService.removeAll();
                        this.opened = false;
                    }
                });
            });
    }

    remove(unfinishedGame: OnlineGameplay, index: number) {
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_UNFINISHED_GAME:Delete unfinished game`,
                    text: $localize`:@@DELETE_THIS_UNFINISHED_GAME:Do you want to remove this unfinished game?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinishedGamesService.remove(unfinishedGame, index);
                        if (!this.unfinishedGamesService.unfinishedGames.length) {
                            this.opened = false;
                        }
                    }
                });
            });
    }

    reinvite(gameplay: OnlineGameplay) {
        this._modal
            .create({
                component: ReinviteDialogComponent,
                cssClass: 'auto-height',
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinishedGamesService.reinvite(this.auth.user, gameplay);
                    }
                });
            });
    }

    save(unfinishedGame: OnlineGameplay, index: number) {
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@SAVE_MATCH:Save game`,
                    text: $localize`:@@SAVING_UNFINISHED_MATCH:Do you want to save this game to your statistics?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinishedGamesService.save(
                            this._platform.is('ios') ? 'ios' : 'android',
                            unfinishedGame,
                            index
                        );

                        if (!this.unfinishedGamesService.unfinishedGames.length) {
                            this.opened = false;
                        }
                    }
                });
            });
    }
}
