import { Injectable, WritableSignal, signal } from '@angular/core';
import { User } from '../../dc-backend/dc-classes';
import { LocalStorageKey } from 'dc-core/dc-localstorage';
import { PreferenceLocalStorageKey } from './preference.models';
import { GameMode } from '@dc-core/dc-backend/dc-enums';

@Injectable()
export class DartCounterPreferenceService {
    allowCaller: boolean = true;
    showHeadToHead: boolean = true;
    callDartbot: boolean = true;
    callOmniImmediately: boolean = false;
    callYouRequire: boolean = true;
    dartbotTimeout: number = 2.5;
    showAdvancedBar: boolean = true;
    showAvg: boolean = true;
    showCheckoutHint: boolean = true;
    showInGameChat: boolean = true;
    enableSpeechToScore: boolean = false;
    enableActionReplays: boolean = true;
    promptActionReplay: boolean = true;

    transition: boolean = false;
    lang: string = 'en';
    langSignal: WritableSignal<string> = signal('en');

    matchDartbot: User;
    cricketTacticsDartbot: User;

    public ingameMode: GameMode = null;
    public ingameData: any = null;

    public onOffList: { label: string; tag: string; type: boolean }[] = [
        { label: $localize`:@@ON:On`, tag: 'ON', type: true },
        { label: $localize`:@@OFF:Off`, tag: 'OFF', type: false },
    ];

    public pricing = {
        monthly: 3.99,
        yearly: 29.99,
        discount: 23.99,
    };

    constructor() {
        let preferences: this = JSON.parse(localStorage.getItem(LocalStorageKey.preferences));
        if (preferences) {
            this.setPreferences(preferences);
        }
    }

    setPreferences(preferences: DartCounterPreferenceService): void {
        this.allowCaller = preferences.allowCaller ?? this.allowCaller;
        this.showHeadToHead = preferences.showHeadToHead ?? this.showHeadToHead;
        this.callYouRequire = preferences.callYouRequire ?? this.callYouRequire;
        this.callOmniImmediately = preferences.callOmniImmediately ?? this.callOmniImmediately;
        this.callDartbot = preferences.callDartbot ?? this.callDartbot;
        this.dartbotTimeout = preferences.dartbotTimeout ?? this.dartbotTimeout;
        this.showAdvancedBar = preferences.showAdvancedBar ?? this.showAdvancedBar;
        this.showAvg = preferences.showAvg ?? this.showAvg;
        this.showCheckoutHint = preferences.showCheckoutHint ?? this.showCheckoutHint;
        this.showInGameChat = preferences.showInGameChat ?? this.showInGameChat;
        this.enableSpeechToScore = preferences.enableSpeechToScore ?? this.enableSpeechToScore;
        this.enableActionReplays = preferences.enableActionReplays ?? this.enableActionReplays;
        this.promptActionReplay = preferences.promptActionReplay ?? this.promptActionReplay;

        this.transition = preferences.transition ?? this.transition;
        this.lang = preferences.lang ?? this.lang;
    }

    savePreferences(): void {
        localStorage.setItem(
            LocalStorageKey.preferences,
            JSON.stringify({
                allowCaller: this.allowCaller,
                showHeadToHead: this.showHeadToHead,
                callYouRequire: this.callYouRequire,
                callOmniOnPlayerChange: this.callOmniImmediately,
                callDartbot: this.callDartbot,
                dartbotTimeout: this.dartbotTimeout,
                showAdvancedBar: this.showAdvancedBar,
                showAvg: this.showAvg,
                showCheckoutHint: this.showCheckoutHint,
                showInGameChat: this.showInGameChat,
                enableSpeechToScore: this.enableSpeechToScore,
                enableActionReplays: this.enableActionReplays,
                promptActionReplay: this.promptActionReplay,

                transition: this.transition,
                lang: this.lang,
            })
        );
    }

    changePreference(key: PreferenceLocalStorageKey | string, value: any): void {
        this[key.toString()] = value;
        this.savePreferences();
    }
}
