import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appButtonOverflowVisible]',
})
export class ButtonOverflowVisibleDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.el.nativeElement) {
                this.el.nativeElement.style.overflow = 'visible';
                this.el.nativeElement.style.contain = 'none';

                const toolbarOverflowElement: any = this.el.nativeElement.shadowRoot.querySelector('.button-native');

                toolbarOverflowElement.style.overflow = 'visible';
                toolbarOverflowElement.style.contain = 'none';
            }
        }, 1000);
    }
}
