<app-page-header mainNavigation="custom" defaultBack="statistics">
    <app-icon left icon="arrow_back" (click)="back(false)"></app-icon>
    <div title>{{ title | translate }}</div>
    @if (item && !item.tournament_game) {
        <app-option-button right icon="bin" (click)="TryRemove()" color="text-white"></app-option-button>
    }
</app-page-header>

@if (item) {
    <ion-content class="ion-text-black bg-white-pattern">
        <div class="flex">
            <div class="w-1/3"></div>
        </div>
        <div class="bg-white flex flex-col text-center text-black py-6">
            <div class="text-lg font-bold">{{ item.name }} - {{ item.start_score }}</div>
            <div class="text-sm text-neutral-700">
                {{ item.started_at | date: 'dd-MMM-yyyy H:mm' : '' : translateService.currentLang }}
            </div>
            <div class="flex justify-center gap-2 mt-3">
                <div class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs">
                    {{ item.start_with | entryMode }}
                </div>
                <div class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs">
                    {{ item.end_with | finishMode }}
                </div>
                @if (item.two_legs_difference) {
                    <div
                        class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs"
                        [translate]="'TWO_LEGS_DIFFERENCE'"
                        i18n="@@TWO_LEGS_DIFFERENCE">
                        Two legs difference
                    </div>
                }
                @if (item.enable_sudden_death) {
                    <div
                        class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs"
                        [translate]="'SUDDEN_DEATH_MODE'"
                        i18n="@@SUDDEN_DEATH_MODE">
                        Sudden death mode
                    </div>
                }
            </div>
        </div>
        @if (enableCapture) {
            <ion-button expand="full" (click)="Share()">
                <ion-icon style="left: 0; position: absolute" name="share-social-outline"></ion-icon>
                <span [translate]="'SHARE_MATCH'" i18n="@@SHARE_MATCH"> Share match</span>
            </ion-button>
        }
        <div class="relative mb-3" appWebContainer>
            <div class="flex sticky top-0 px-8 py-3 bg-white rounded-md">
                <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"></div>
                <div
                    [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                    class="flex justify-center">
                    @if (item.has_teams) {
                        @for (team of item.teams; track team) {
                            <div class="w-1/2 flex flex-col items-center text-center text-black">
                                {{ GetPlayers(team) }}
                                @if (team.result === 'won') {
                                    <app-icon icon="emoji_events" class="text-orange"></app-icon>
                                }
                            </div>
                        }
                    }
                    @if (!item.has_teams) {
                        @for (user of item.users; track user) {
                            <div
                                [ngClass]="
                                    item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/' + item.users.length
                                ">
                                @if (user.user) {
                                    <app-profile-photo
                                        [ultimate]="user.user.is_ultimate"
                                        [badge]="user.user.profile?.badge"
                                        [size]="32"
                                        [url]="user.user.profile?.profile_photo_url"
                                        style="height: 32px"></app-profile-photo>
                                } @else {
                                    <app-profile-photo [size]="32" style="height: 32px"></app-profile-photo>
                                }
                                <div class="mt-1 text-center truncate capitalize text-black">
                                    {{ getMatchUserName(user) }}
                                </div>
                                @if (user.result === 'won') {
                                    <app-icon icon="emoji_events" class="text-orange justify-center flex"></app-icon>
                                }
                            </div>
                        }
                    }
                </div>
            </div>
            <div class="flex px-8 py-3 text-sm">
                <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'">
                    {{ item?.has_sets ? 'Sets' : 'Legs' }}
                </div>
                <div
                    [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                    class="flex justify-center text-center">
                    @if (item.has_teams) {
                        @for (team of item.teams; track team) {
                            <div class="w-1/2">
                                {{ item.has_sets ? team.users[0].total_score_sets : team.users[0].total_score_legs }}
                            </div>
                        }
                    }
                    @if (!item.has_teams) {
                        @for (user of item.users; track user) {
                            <div [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-1/' + item.users.length">
                                {{ item.has_sets ? user.total_score_sets : user.total_score_legs }}
                            </div>
                        }
                    }
                </div>
            </div>
            <div class="mt-3 mx-4">
                <div class="rounded-t-md bg-white px-4 py-2.5">
                    <div
                        class="col-span-6 flex items-center text-lg font-bold text-black"
                        [translate]="'STATISTICS'"
                        i18n="@@STATISTICS">
                        Statistics
                    </div>
                </div>
                <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'AVERAGE'"
                        i18n="@@AVERAGE">
                        3-dart avg.
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    {{ team.three_dart_average | number: '1.0-2' }}
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    {{ user.three_dart_average | number: '1.0-2' }}
                                </div>
                            }
                        }
                    </div>
                </div>
                <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'FIRST_9_AVERAGE'"
                        i18n="@@FIRST_9_AVERAGE">
                        First 9 avg.
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    {{ team.first_nine_average || 0 | number: '1.2-2' }}
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    {{ user.first_nine_average || 0 | number: '1.2-2' }}
                                </div>
                            }
                        }
                    </div>
                </div>
                <div class="flex items-center border-b-white px-4 py-3 text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'HIGHEST_SCORE'"
                        i18n="@@HIGHEST_SCORE">
                        Highest score
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    {{ team.highest_score || '-' }}
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    {{ user.highest_score || '-' }}
                                </div>
                            }
                        }
                    </div>
                </div>
            </div>
            <div class="mt-3 mx-4">
                <div class="rounded-t-md bg-white px-4 py-2.5">
                    <div
                        class="col-span-6 flex items-center text-lg font-bold text-black"
                        [translate]="'CHECKOUTS'"
                        i18n="@@CHECKOUTS">
                        Checkouts
                    </div>
                </div>
                <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'HIGHEST_FINISH'"
                        i18n="@@HIGHEST_FINISH">
                        Highest finish
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    {{ team.highest_finish || '-' }}
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    {{ user.highest_finish || '-' }}
                                </div>
                            }
                        }
                    </div>
                </div>
                <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'CHECKOUT_RATE'"
                        i18n="@@CHECKOUT_RATE">
                        Checkout %
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    @if (team.has_checkout_rate) {
                                        {{ team.checkout_rate | number: '1.0-2' }}%
                                    }
                                    @if (!team.has_checkout_rate) {
                                        -
                                    }
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    @if (user.has_checkout_rate) {
                                        {{ user.checkout_rate || 0 | number: '1.0-2' }}%
                                    }
                                    @if (!user.has_checkout_rate) {
                                        -
                                    }
                                </div>
                            }
                        }
                    </div>
                </div>
                <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'CHECKOUTS'"
                        i18n="@@CHECKOUTS">
                        Checkouts
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    @if (team.has_checkout_rate) {
                                        {{ team.checked_legs || 0 }}/{{ team.darts_on_double || 0 }}
                                    }
                                    @if (!team.has_checkout_rate) {
                                        -
                                    }
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    @if (user.has_checkout_rate) {
                                        {{ user.checked_legs || 0 }}/{{ user.darts_on_double || 0 }}
                                    }
                                    @if (!user.has_checkout_rate) {
                                        -
                                    }
                                </div>
                            }
                        }
                    </div>
                </div>
            </div>
            <div class="mt-3 mx-4">
                <div class="rounded-t-md bg-white px-4 py-2.5">
                    <div
                        class="col-span-6 flex items-center text-lg font-bold text-black"
                        [translate]="'SCORES'"
                        i18n="@@SCORES">
                        Scores
                    </div>
                </div>
                @if (scores.length > 1) {
                    @for (score of [1, 2, 3, 4, 5, 6, 7, 8]; track score) {
                        <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
                            <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'">
                                {{ scores[score].label }}
                            </div>
                            <div
                                [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                                class="flex text-center justify-center">
                                @for (contender of contenders; track contender; let contenderIndex = $index) {
                                    @if (item.has_teams) {
                                        <div class="w-1/2">
                                            {{ scores[score].data[contenderIndex] }}
                                        </div>
                                    }
                                    @if (!item.has_teams) {
                                        <div class="w-1/{{ item.users.length }}">
                                            {{ scores[score].data[contenderIndex] }}
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                    }
                }
            </div>
            @if (item.has_sets) {
                @for (set of sets; track set) {
                    <app-accordion
                        class="block mt-3 mx-4 mb-2"
                        [isExpanded]="set.expanded"
                        (expandChange)="expandItem(set, sets)">
                        <div accordion-header class="flex flex-row items-center justify-between w-full">
                            <div class="font-bold text-sm whitespace-nowrap">Set #{{ set.index }}</div>
                            @if (set.winner) {
                                <div class="flex flex-1 items-center justify-center overflow-hidden">
                                    <div class="text-base font-normal truncate capitalize mx-2">
                                        {{ set.winner }}
                                    </div>
                                    <app-icon icon="emoji_events" class="text-orange ml-2"></app-icon>
                                </div>
                            }
                        </div>
                        <div accordion-body class="p-2 bg-gray-50">
                            @for (leg of set.legs; track leg) {
                                <app-accordion
                                    class="block mt-3 mx-4 mb-2"
                                    [isExpanded]="leg.expanded"
                                    (expandChange)="expandItem(leg, set.legs)">
                                    <div accordion-header class="flex flex-row items-center justify-between w-full">
                                        <div class="font-bold text-sm whitespace-nowrap">Leg #{{ leg.index }}</div>

                                        @if (leg.winner) {
                                            <div class="flex flex-1 items-center justify-center overflow-hidden">
                                                <div class="text-base font-normal truncate capitalize mx-2">
                                                    {{ leg.winner }}
                                                </div>
                                                <app-icon icon="emoji_events" class="text-orange ml-2"></app-icon>
                                            </div>
                                        }
                                    </div>
                                    <div accordion-body class="p-2 bg-gray-50">
                                        <ng-container
                                            *ngTemplateOutlet="legContainer; context: { leg: leg }"></ng-container>
                                    </div>
                                </app-accordion>
                            }
                        </div>
                    </app-accordion>
                }
            }

            @if (!item.has_sets && sets) {
                @for (leg of sets[0]?.legs; track leg) {
                    <app-accordion
                        class="block mt-3 mx-4 mb-2"
                        [isExpanded]="leg.expanded"
                        (expandChange)="expandItem(leg, sets[0]?.legs)">
                        <div accordion-header class="flex flex-row items-center justify-between w-full">
                            <div class="font-bold text-sm whitespace-nowrap">Leg #{{ leg.index }}</div>
                            @if (leg.winner) {
                                <div class="flex flex-1 items-center justify-center overflow-hidden">
                                    <div class="text-base font-normal truncate capitalize mx-2">
                                        {{ leg.winner }}
                                    </div>
                                    <app-icon icon="emoji_events" class="text-orange ml-2"></app-icon>
                                </div>
                            }
                        </div>
                        <div accordion-body class="p-2 bg-gray-50">
                            <ng-container *ngTemplateOutlet="legContainer; context: { leg: leg }"></ng-container>
                        </div>
                    </app-accordion>
                }
            }
        </div>

        @if (!auth.user.is_ultimate) {
            <div class="flex flex-col space-y-2 px-5 pt-5 pb-safe-offset-5">
                <p [translate]="'SEE_MORE_CTA'" i18n="@@SEE_MORE_CTA" class="text-center">
                    Do you want to see more? Become Ultimate!
                </p>
                <app-primary-button
                    size="normal"
                    addedClasses="w-full"
                    (click)="OpenSubscriptionDialog()"
                    [translate]="'UPGRADE_BTN'"
                    i18n="@@UPGRADE_BTN">
                    Upgrade
                </app-primary-button>
            </div>
        }
    </ion-content>
}

<ng-template #legContainer let-leg="leg">
    @if (auth.user.is_ultimate) {
        <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
            <div
                [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                [translate]="'AVERAGE'"
                i18n="@@AVERAGE">
                3-dart avg.
            </div>
            <div [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" class="flex text-center justify-center">
                @for (legDetail of leg.details; track legDetail; let index = $index) {
                    @if (item.has_teams) {
                        <div class="w-1/2">
                            {{ legDetail.threeDartAverage }}
                        </div>
                    }
                    @if (!item.has_teams) {
                        <div class="w-1/{{ item.users.length }}">
                            {{ legDetail.threeDartAverage }}
                        </div>
                    }
                }
            </div>
        </div>
        <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
            <div
                [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                [translate]="'DARTS_THROWN'"
                i18n="@@DARTS_THROWN">
                Darts thrown
            </div>
            <div [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" class="flex text-center justify-center">
                @for (legDetail of leg.details; track legDetail) {
                    @if (item.has_teams) {
                        <div class="w-1/2">
                            {{ legDetail.dartsThrown }}
                        </div>
                    }
                    @if (!item.has_teams) {
                        <div class="w-1/{{ item.users.length }}">
                            {{ legDetail.dartsThrown }}
                        </div>
                    }
                }
            </div>
        </div>
        <div class="flex flex-wrap text-center text-sm">
            @for (legDetail of leg.details; track legDetail) {
                <div class="w-1/2 mt-5 px-2">
                    <div class="mb-2 text-lg flex justify-center items-center space-x-1 flex-1">
                        <div class="truncate capitalize">{{ legDetail.name }}</div>
                        @if (legDetail.hasWon) {
                            <ion-icon style="top: 1px; position: relative" name="trophy" color="secondary"></ion-icon>
                        }
                    </div>
                    <div class="flex border-b-white border-b-1 py-2">
                        <div [translate]="'SCORE'" i18n="@@SCORE" class="w-1/2">Score</div>
                        <div [translate]="'REMAINING'" i18n="@@REMAINING" class="w-1/2">Remaining</div>
                    </div>
                    @for (remainingScore of legDetail.remainingScores; track remainingScore) {
                        <div class="flex border-b-white border-b-1 py-2">
                            <div class="w-1/2">{{ remainingScore.score }}</div>
                            <div class="w-1/2">{{ remainingScore.remaining }}</div>
                        </div>
                    }
                </div>
            }
        </div>
    }
</ng-template>
