import { registerPlugin } from '@capacitor/core';

import type { CapacitorSmartDeviceClientPlugin } from './definitions';

const CapacitorSmartDeviceClient =
  registerPlugin<CapacitorSmartDeviceClientPlugin>(
    'CapacitorSmartDeviceClient',
    {
      web: () =>
        import('./web').then(m => new m.CapacitorSmartDeviceClientWeb()),
    },
  );

export * from './definitions';
export {
  VirtCamCommandRequest,
  VirtCamCommands,
  VirtCamHelper,
  VirtCamResponses,
  VirtCamStartWiFiAPRequest,
} from './definitions';
export { CapacitorSmartDeviceClient };
