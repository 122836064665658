<div class="list-switch">
    @for (itemFromList of items; track $index) {
        <button
            type="button"
            class="switch-item text-black"
            [class.selected]="item === itemFromList.type"
            [class.medium]="size === 'medium'"
            (click)="changeItem(itemFromList.type)">
            @if (itemFromList.tag) {
                <span>{{ itemFromList.tag | translate }}</span>
            } @else {
                <span> {{ itemFromList.label }}</span>
            }
        </button>
    }
</div>
