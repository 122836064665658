/* eslint-disable max-len */
import { Component } from '@angular/core';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { CommandOption, CommandType } from '@dc-core/dc-services/speech-to-score/speech-commands';
import { DartCounterSpeechToScoreService } from '@dc-core/dc-services/speech-to-score/speech-to-score.service';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-speech-to-score-info-dialog',
    templateUrl: 'speech-to-score-info.dialog.html',
    styleUrls: ['speech-to-score-info.dialog.scss'],
})
export class SpeechToScoreInfoDialogComponent {
    editableType: CommandType = null;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public preferenceService: DartCounterPreferenceService,
        public speechToScoreService: DartCounterSpeechToScoreService,
        private _alertService: DartCounterAlertService,
        private _videoRoomService: JanusVideoRoomService
    ) {}

    toggleSpeechToScore(): void {
        if (this.preferenceService.enableSpeechToScore) {
            if (this._videoRoomService.isPublishingAudio) {
                this.preferenceService.enableSpeechToScore = false;
                this._alertService.createAlert({
                    title: $localize`:@@CANNOT_USE_SPEECH_TO_SCORE_WITH_VOICE_CALL:Cannot use Speech to score with Voice chat`,
                    text: $localize`:@@PLEASE_DISABLE_VOICE_CALL_TO_USE_SPEECH_TO_SCORE:Please disable Voice chat in order to use Speech to score.`,
                    icon: 'info',
                });
                return;
            }

            this.preferenceService.savePreferences();
            this.speechToScoreService.start();
        } else {
            this.preferenceService.savePreferences();
            this.speechToScoreService.stop();
        }
    }

    changeType(type: CommandType): void {
        if (this.editableType) {
            this.editableType = null;
        } else {
            this.editableType = type;
        }
    }

    changeOption(option: CommandOption): void {
        this.speechToScoreService.changeOption(this.editableType, option);
        this.editableType = null;
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
