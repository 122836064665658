import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';
import { gamesTrialGuard } from '../guards/games-trial.guard';

export const checkout121GameRoutes: Route[] = [
    {
        path: 'local-games/checkout-121',
        loadComponent: () =>
            import('./pages/checkout-121-new-game/checkout-121-new-game.component').then(
                (c) => c.Checkout121NewGameComponent
            ),
    },
    {
        path: 'game/checkout-121',
        data: {
            game_mode: 'checkout_121',
        },
        canActivate: [gamesTrialGuard],
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/checkout-121-local-game/checkout-121-local-game.component').then(
                (c) => c.Checkout121LocalGameComponent
            ),
    },
];
