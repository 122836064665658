<div scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        @if (showCloseButton()) {
            <app-icon icon="close" class="text-black close-button" (click)="dismiss()"></app-icon>
        }
        <div class="title-container">
            <div class="title">
                <ng-content select="[title]"></ng-content>
            </div>
            <div class="subtitle">
                <ng-content select="[sub-title]"></ng-content>
            </div>
        </div>
        <div class="mt-8">
            <ng-content></ng-content>
        </div>
    </div>
</div>
