import { Tournament, TournamentGame } from '@dc-core/dc-backend/dc-classes';

export type TournamentExtendedStatus =
    | 'waiting_to_join'
    | 'joinable'
    | 'waiting_for_approval'
    | 'waiting_for_check_in'
    | 'check_in_period'
    | 'checked_in'
    | 'starting'
    | 'started'
    | 'playing'
    | 'lost'
    | 'finished';

export type TournamentViewStatus = 'waiting' | 'check_in_period' | 'starting' | 'playing' | 'lost' | 'finished';

export class TournamentHelper {
    public static calculateRounds(players: number) {
        // Round up to the nearest power of 2
        const nextPowerOf2 = Math.pow(2, Math.ceil(Math.log2(players)));
        return Math.log2(nextPowerOf2);
    }

    public static timerFromDate(date: string, type: 'from' | 'until'): string {
        if (!date) {
            return '';
        }

        const now = new Date().getTime();
        const targetTime = new Date(date).getTime();

        let diffInMilliseconds = 0;
        if (type === 'from') {
            diffInMilliseconds = now - targetTime;
        } else if (type === 'until') {
            diffInMilliseconds = targetTime - now;
        }

        if (diffInMilliseconds <= 0) {
            return '0s';
        }

        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const days = Math.floor(diffInSeconds / (60 * 60 * 24));
        const hours = Math.floor((diffInSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((diffInSeconds % (60 * 60)) / 60);
        const seconds = diffInSeconds % 60;

        const dayString = days > 0 ? `${days}d` : '';
        const hourString = hours > 0 ? `${hours}h` : '';
        const minuteString = minutes > 0 ? `${minutes}m` : '';
        const secondString = `${seconds}s`;

        return `${dayString} ${hourString} ${minuteString} ${secondString}`.trim();
    }

    public static getPlayStatus(tournament: Tournament): TournamentExtendedStatus {
        if (tournament.status === 'finished') {
            return 'finished';
        } else if (tournament.status === 'started') {
            if (tournament.participation) {
                if (tournament.participation.result === 'lost') {
                    return 'lost';
                } else if (tournament.started_at) {
                    return 'playing';
                } else {
                    return 'starting';
                }
            } else {
                if (tournament.started_at) {
                    return 'started';
                } else {
                    return 'starting';
                }
            }
        } else if (tournament.status === 'waiting_to_join') {
            return 'waiting_to_join';
        } else if (!tournament.participation || !tournament.participation.joined_at) {
            return 'joinable';
        } else {
            if (tournament.needs_approval && !tournament.participation.approved_at) {
                return 'waiting_for_approval';
            } else if (tournament.status === 'check_in_period') {
                if (tournament.participation.checked_in_at) {
                    return 'checked_in';
                } else {
                    return 'check_in_period';
                }
            } else if (tournament.status === 'joining_period') {
                return 'waiting_for_check_in';
            }
        }
    }

    public static getHostStatus(tournament: Tournament): TournamentExtendedStatus {
        if (tournament.status === 'finished') {
            return 'finished';
        } else if (tournament.status === 'started') {
            if (tournament.participation) {
                if (tournament.participation.result === 'lost') {
                    return 'lost';
                } else if (tournament.started_at) {
                    return 'playing';
                } else {
                    return 'starting';
                }
            } else {
                if (tournament.started_at) {
                    return 'started';
                } else {
                    return 'starting';
                }
            }
        } else if (tournament.status === 'waiting_to_join') {
            return 'waiting_to_join';
        } else {
            if (tournament.status === 'check_in_period') {
                return 'check_in_period';
            } else if (tournament.status === 'joining_period') {
                return 'waiting_for_check_in';
            }
        }
    }

    public static getViewStatus(extendedStatus: TournamentExtendedStatus): TournamentViewStatus {
        switch (extendedStatus) {
            case 'waiting_to_join':
            case 'joinable':
            case 'waiting_for_approval':
            case 'waiting_for_check_in':
                return 'waiting';
            case 'check_in_period':
            case 'checked_in':
                return 'check_in_period';
            case 'starting':
                return 'starting';
            case 'started':
            case 'playing':
                return 'playing';
            case 'lost':
                return 'lost';
            case 'finished':
                return 'finished';
        }
    }

    public static getRoundText(currentRound: number, amountOfRounds: number): string {
        if (currentRound === amountOfRounds) {
            return $localize`:@@FINAL:Final`;
        } else if (currentRound === amountOfRounds - 1 && amountOfRounds >= 2) {
            return 'SF';
        } else if (currentRound === amountOfRounds - 2 && amountOfRounds >= 3) {
            return 'QF';
        }
        return `R${currentRound}`;
    }

    public static getTournamentGameTitle(game: TournamentGame): string {
        return (
            $localize`:@@TOURNAMENT_ROUND:Tournament round` +
            `: ${TournamentHelper.getRoundText(game?.round ?? 0, game.tournament.amount_of_rounds)}`
        );
    }
}
