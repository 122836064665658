import { Component, computed, inject, OnInit, Signal } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { ProfilePrivacy } from '@dc-core/dc-backend/dc-enums';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { AppTheme, ThemeService } from '@services/theme.service';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';

@Component({
    selector: 'app-profile-privacy-dialog',
    templateUrl: './profile-privacy.dialog.html',
    standalone: true,
    imports: [IonicModule, TranslateModule, PrimaryButtonComponent],
})
export class ProfilePrivacyDialogComponent implements OnInit {
    public authService: AuthService = inject(AuthService);

    private themeService: ThemeService = inject(ThemeService);
    private modalController: ModalController = inject(ModalController);
    private userApiService: UserApiService = inject(UserApiService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);

    public currentTheme: Signal<AppTheme> = computed(() => this.themeService.theme());
    public privacySetting: ProfilePrivacy;

    ngOnInit(): void {
        this.privacySetting = this.authService.user.profile_privacy;
    }

    public changePrivacy(profilePrivacy: ProfilePrivacy): void {
        this.privacySetting = profilePrivacy;
    }

    public SavePrivacySetting() {
        if (this.privacySetting !== this.authService.user.profile_privacy) {
            this.userApiService
                .updateUser({ profile_privacy: this.privacySetting })
                .then(() => {
                    this.authService.user.profile_privacy = this.privacySetting;
                    this.dismiss();
                })
                .catch((err) => {
                    this.alertService.errorFromApi(err);
                });
        } else {
            this.dismiss();
        }
    }

    public dismiss(): void {
        this.modalController.dismiss();
    }
}
