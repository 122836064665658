import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class ScoresApiService extends ApiService {
    // GET Requests
    public getScores = super.getFetcher().path('/scores').method('get').create();
    public getSpecificScores = super.getFetcher().path('/scores/specific').method('get').create();
}
