import { Component, inject } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular/standalone';

@Component({
    selector: 'app-login-required-dialog',
    templateUrl: 'login-required.html',
    styleUrls: ['login-required.scss'],
})
export class LoginRequiredDialogComponent {
    showFB = false;

    private modalController: ModalController = inject(ModalController);

    constructor(public navCtrl: NavController) {}

    public DismissModal(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    GoToRegistration() {
        this.navCtrl.navigateForward('register');
        this.modalController.dismiss();
    }
}
