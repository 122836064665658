<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'RESET_ACCOUNT'" i18n="@@RESET_ACCOUNT">Reset statistics</div>
    </div>
    <div class="slide-modal-content">
        <div class="mb-4 space-y-2">
            <div class="grid gap-x-3 flex-col text-black">
                <div (click)="changeResetFrom()">
                    <div class="dialog-input-label" [translate]="'RESET_FROM'" i18n="@@RESET_FROM">Reset from</div>
                    <div
                        [ngClass]="{
                            '!py-2': isWeb
                        }"
                        class="dialog-input">
                        {{
                            startDate
                                ? (startDate | date: 'd MMM yyyy' : '' : translateService.currentLang)
                                : ('RESET_FROM' | translate)
                        }}
                    </div>
                </div>
                <div (click)="changeResetUntil()" class="mt-2">
                    <div class="dialog-input-label" [translate]="'RESET_UNTIL'" i18n="@@RESET_UNTIL">Reset until</div>
                    <div
                        [ngClass]="{
                            '!py-2': isWeb
                        }"
                        class="dialog-input">
                        {{
                            endDate
                                ? (endDate | date: 'd MMM yyyy' : '' : translateService.currentLang)
                                : ('RESET_FROM' | translate)
                        }}
                    </div>
                </div>
            </div>
        </div>
        <app-primary-button
            (click)="ResetAccount()"
            addedClasses="w-full"
            size="normal"
            color="orange"
            type="submit"
            [translate]="'CONFIRM_RESET'"
            i18n="@@CONFIRM_RESET">
            Confirm reset
        </app-primary-button>
    </div>
</div>
