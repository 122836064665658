<app-dialog-view [showCloseButton]="false" (onDismiss)="dismiss()">
    <div title>
        <span i18n="@@WE_NEED_YOUR_LOCATION" [translate]="'WE_NEED_YOUR_LOCATION'">We need your location</span>
    </div>

    <div sub-title>
        <span
            [translate]="'LOCATION_PERMISSION_EXPLANATION_FOR_SMART_DEVICE'"
            i18n="@@LOCATION_PERMISSION_EXPLANATION_FOR_SMART_DEVICE">
            To have a fast smart device setup, the app will ask for location permissions on your device. We are not
            storing or tracking your location in any way.
        </span>
    </div>

    <div>
        <app-primary-button
            size="normal"
            addedClasses="w-full"
            color="orange"
            fill="full"
            i18n="@@NEXT"
            [translate]="'NEXT'"
            (click)="dismiss()">
            Next
        </app-primary-button>
    </div>
</app-dialog-view>
