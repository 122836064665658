import { Component } from '@angular/core';
import { SubscriptionApiService } from '@dc-api/subscription.api.service';
import { TransactionApiService } from '@dc-api/transaction.api.service';
import { SUBSCRIPTIONS, TRANSACTIONS } from '@dc-core/dc-gamelogic/settings/settings.globals';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PurchaseService } from '@providers/PurchaseService';
import { UpgradeService } from '@providers/UpgradeService';
import { Subscription, Transaction } from 'dc-core/dc-backend/dc-classes';
import { SubscriptionStatus, TransactionStatus } from 'dc-core/dc-backend/dc-enums';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { CancelSubscriptionDialogComponent } from 'src/dialogs/cancel-subscription/cancel-subscription';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

/**
 * Generated class for the Settings page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-subscriptions',
    templateUrl: 'subscriptions.html',
    styleUrls: ['subscriptions.scss'],
})
export class SubscriptionsComponent {
    public ultimateSubscription: Subscription;

    public subscriptions: Subscription[] = [];
    public offset = 0;
    public total = 0;

    public transactions: Transaction[] = [];
    public offsetTrans = 0;
    public totalTrans = 0;

    public limit = 10;
    public segment: 'subscriptions' | 'transactions' = 'subscriptions';
    public segments: { label: string; tag: string; type: string }[] = [];

    public isWeb = environment.isWeb;

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public preferenceService: DartCounterPreferenceService,
        public translateService: TranslateService,
        public purchases: PurchaseService,
        public modal: ModalController,
        private upgrade: UpgradeService,
        private _subscriptionApiService: SubscriptionApiService,
        private _transactionApiService: TransactionApiService
    ) {
        this.segments = [SUBSCRIPTIONS, TRANSACTIONS];
    }

    ionViewWillEnter(): void {
        this.ultimateSubscription = this.auth.user.ultimate_subscription;

        this.loadSubscriptions();
        this.loadTransactions();
    }

    cancelSubscription(): void {
        this.modal
            .create({
                component: CancelSubscriptionDialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => {
                    this.subscriptions = [];
                    this.offset = 0;
                    this.total = 0;
                    this.loadSubscriptions();
                });
            });
    }

    resumeSubscription(): void {
        //
    }

    loadSubscriptions(event = null): void {
        this._subscriptionApiService
            .getUserSubscriptions({
                limit: this.limit,
                page: this.offset === 0 ? 1 : this.offset / this.limit + 1,
            })
            .then((res) => {
                res.data.data.forEach((subscription) => this.subscriptions.push(subscription));

                if (res.data.data.length) {
                    this.offset += this.limit;
                }
                this.total = res.data.total;

                if (event) {
                    event.target.complete();
                }
            });
    }

    getSubscriptionStatusColor(status: SubscriptionStatus): string {
        switch (status) {
            case 'on_trial':
            case 'on_grace_period':
                return 'orange-status';
            case 'active':
            case 'valid':
                return 'green-status';
            case 'ended':
            case 'cancelled':
                return 'red-status';
        }
    }

    loadTransactions(event = null): void {
        this._transactionApiService
            .getUserTransactions({
                limit: this.limit,
                page: this.offsetTrans === 0 ? 1 : this.offsetTrans / this.limit + 1,
            })
            .then((res) => {
                res.data.data.forEach((transaction) => this.transactions.push(transaction));

                if (res.data.data.length) {
                    this.offsetTrans += this.limit;
                }
                this.totalTrans = res.data.total;

                if (event) {
                    event.target.complete();
                }
            });
    }

    getTransactionStatusColor(status: TransactionStatus): string {
        switch (status) {
            case 'pending':
                return 'orange-status';
            case 'paid':
                return 'green-status';
            case 'cancelled':
                return 'gray-status';
            case 'chargeback':
            case 'failed':
            case 'expired':
                return 'red-status';
        }
    }

    changeSegment(segment: 'subscriptions' | 'transactions'): void {
        this.segment = segment;
    }

    async openSubscriptionDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.SUBSCRIPTIONS);
        this.modal
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => {
                    this.loadSubscriptions();
                    this.loadTransactions();
                });
            });
    }
}
