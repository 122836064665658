import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';

export const singlesTrainingGameRoutes: Route[] = [
    {
        path: 'game/singles-training',
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/singles-training-local-game/singles-training-local-game.component').then(
                (m) => m.SinglesTrainingLocalGameComponent
            ),
    },
];
