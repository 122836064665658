<!-- Translation containers-->
@if (0) {
    <ng-container i18n="@@DATE">Date</ng-container>
    <ng-container i18n="@@TUT_CUSTOMDATE"
        >Edit the date-range to show statistics from an previous period.
    </ng-container>
    <ng-container i18n="@@STATISTICS">Statistics</ng-container>
    <ng-container i18n="@@TUT_TOP25"
        >Click to show a top 25 of your best and worst statistics. The date-filter will apply!</ng-container
    >
    <ng-container i18n="@@STATS_PER_GAME">Statistics per game</ng-container>
    <ng-container i18n="@@TUT_DETAILSTATS">Click on a gameplay to show detailed results!</ng-container>
    <ng-container i18n="@@SETTING">Setting</ng-container>
    <ng-container i18n="@@GAME_TYPE">Game type</ng-container>
    <ng-container i18n="@@PERIOD">Period</ng-container>
    <ng-container 18n="@@STARTSCORE">Startscore</ng-container>
}
<!-- End of translation containers-->
<app-page-header mainNavigation="menu">
    <div title [translate]="'PAGE_STATISTICS'" i18n="@@PAGE_STATISTICS">Statistics</div>
</app-page-header>

<div id="main-content" class="relative flex h-full flex-col overflow-hidden bg-white-pattern">
    @if (appFeaturesService.enabledAppFeatures().tournaments) {
        <div class="bg-white flex-none">
            <div class="tab-toggle-container" appWebContainer>
                <div
                    class="tab-toggle"
                    [class.inner-border-b-2-orange]="view === 'games'"
                    [class.opacity-60]="view !== 'games'"
                    (click)="changeView('games')">
                    <div [translate]="'GAMES'" i18n="@@GAMES">Games</div>
                </div>
                <div
                    class="tab-toggle"
                    [class.inner-border-b-2-orange]="view === 'tournaments'"
                    [class.opacity-60]="view !== 'tournaments'"
                    (click)="changeView('tournaments')">
                    <div [translate]="'TOURNAMENTS'" i18n="@@TOURNAMENTS">Tournaments</div>
                </div>
            </div>
        </div>
    }

    <div class="flex flex-col flex-1 overflow-auto overscroll-none">
        @if (view === 'games' || !appFeaturesService.enabledAppFeatures().tournaments) {
            <div class="flex-1" appWebContainer>
                @if (activeGameType) {
                    <app-select
                        class="m-4 block"
                        [initialValue]="activeGameType.value"
                        [options]="gameTypes"
                        (selectionChange)="setGameType($event)"
                        size="full"></app-select>

                    @if (activeGameType.gameMode === 'match') {
                        <app-match-table (dateChangeEvent)="loadLastGame()"></app-match-table>
                    }
                    @if (activeGameType.gameMode === 'cricket_tactics') {
                        <app-cricket-tactics-table (dateChangeEvent)="loadLastGame()"></app-cricket-tactics-table>
                    }
                    @if (activeGameType.gameMode === 'around_the_clock') {
                        <app-around-the-clock-table (dateChangeEvent)="loadLastGame()"></app-around-the-clock-table>
                    }
                    @if (activeGameType.gameMode === 'bobs_27') {
                        <app-bobs-27-table (dateChangeEvent)="loadLastGame()"></app-bobs-27-table>
                    }
                    @if (activeGameType.gameMode === 'shanghai') {
                        <app-shanghai-table (dateChangeEvent)="loadLastGame()"></app-shanghai-table>
                    }
                    @if (activeGameType.gameMode === 'singles_training') {
                        <app-singles-training-table (dateChangeEvent)="loadLastGame()"></app-singles-training-table>
                    }
                    @if (activeGameType.gameMode === 'doubles_training') {
                        <app-doubles-training-table (dateChangeEvent)="loadLastGame()"></app-doubles-training-table>
                    }
                    @if (activeGameType.gameMode === 'score_training') {
                        <app-score-training-table (dateChangeEvent)="loadLastGame()"></app-score-training-table>
                    }
                    @if (activeGameType.gameMode === 'checkout_121') {
                        <app-checkout-121-table (dateChangeEvent)="loadLastGame()"></app-checkout-121-table>
                    }
                }

                @if (!lastGameLoaded) {
                    <div class="flex mt-5">
                        <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-orange"></app-icon>
                    </div>
                }

                @if (lastGameLoaded) {
                    <div class="m-4">
                        @if (lastGame) {
                            <div
                                class="my-3 mt-4 text-center font-bebas-neue text-3xl text-black"
                                [translate]="'LAST_GAME'"
                                i18n="@@LAST_GAME">
                                Last game
                            </div>
                        }
                        @if (!lastGame && activeGameType.unverifiedGames === 0) {
                            <app-primary-button
                                size="normal"
                                addedClasses="w-full mt-3"
                                color="orange"
                                (click)="playFirstGame()"
                                [translate]="'PLAY_FIRST_GAME'"
                                i18n="@@PLAY_FIRST_GAME">
                                Play your first game
                            </app-primary-button>
                        }

                        @if (activeGameType.gameMode === 'match' && lastGame) {
                            <app-match-card [game]="lastGame" (click)="goToLastGameDetail()"></app-match-card>
                        }
                        @if (activeGameType.gameMode === 'cricket_tactics' && lastGame) {
                            <app-cricket-tactics-card
                                [game]="lastGame"
                                (click)="goToLastGameDetail()"></app-cricket-tactics-card>
                        }
                        @if (activeGameType.gameMode === 'around_the_clock' && lastGame) {
                            <app-around-the-clock-card
                                [game]="lastGame"
                                (click)="goToLastGameDetail()"></app-around-the-clock-card>
                        }
                        @if (activeGameType.gameMode === 'bobs_27' && lastGame) {
                            <app-bobs-27-card [game]="lastGame" (click)="goToLastGameDetail()"></app-bobs-27-card>
                        }
                        @if (activeGameType.gameMode === 'shanghai' && lastGame) {
                            <app-shanghai-card [game]="lastGame" (click)="goToLastGameDetail()"></app-shanghai-card>
                        }
                        @if (activeGameType.gameMode === 'singles_training' && lastGame) {
                            <app-singles-training-card
                                [game]="lastGame"
                                (click)="goToLastGameDetail()"></app-singles-training-card>
                        }
                        @if (activeGameType.gameMode === 'doubles_training' && lastGame) {
                            <app-doubles-training-card
                                [game]="lastGame"
                                (click)="goToLastGameDetail()"></app-doubles-training-card>
                        }
                        @if (activeGameType.gameMode === 'score_training' && lastGame) {
                            <app-score-training-card
                                [game]="lastGame"
                                (click)="goToLastGameDetail()"></app-score-training-card>
                        }
                        @if (activeGameType.gameMode === 'checkout_121' && lastGame) {
                            <app-checkout-121-card
                                [game]="lastGame"
                                (click)="goToLastGameDetail()"></app-checkout-121-card>
                        }
                        @if (auth.user.is_ultimate && (lastGame || activeGameType.unverifiedGames > 0)) {
                            <app-primary-button
                                size="normal"
                                addedClasses="w-full mt-3"
                                color="orange"
                                (click)="showGameType(activeGameType)">
                                <div class="flex items-center space-x-2">
                                    <div [translate]="'VIEW_ALL_GAMES'" i18n="@@VIEW_ALL_GAMES">View all games</div>
                                    @if (activeGameType.unverifiedGames > 0) {
                                        <ion-badge mode="md" class="ion-bg-yellow ion-text-black">
                                            {{ activeGameType.unverifiedGames }}
                                        </ion-badge>
                                    }
                                </div>
                            </app-primary-button>
                        } @else if (!auth.user.is_ultimate) {
                            @if (activeGameType.unverifiedGames > 0) {
                                <app-primary-button
                                    size="normal"
                                    addedClasses="w-full mt-3"
                                    color="orange"
                                    (click)="showGameType(activeGameType)">
                                    <div class="flex items-center space-x-2">
                                        <div [translate]="'VIEW_ALL_GAMES'" i18n="@@VIEW_ALL_GAMES">View all games</div>
                                        @if (activeGameType.unverifiedGames > 0) {
                                            <ion-badge mode="md" class="ion-bg-yellow ion-text-black">
                                                {{ activeGameType.unverifiedGames }}
                                            </ion-badge>
                                        }
                                    </div>
                                </app-primary-button>
                            } @else if (lastGame) {
                                <app-primary-button
                                    size="normal"
                                    addedClasses="w-full mt-3"
                                    color="blue"
                                    (click)="OpenUltimateDialog()"
                                    [translate]="'WANT_TO_VIEW_ALL_GAMES'"
                                    i18n="@@WANT_TO_VIEW_ALL_GAMES">
                                    Want to view all games? Become Ultimate!
                                </app-primary-button>
                            }
                        }
                    </div>
                }
            </div>
        } @else if (view === 'tournaments') {
            <div class="flex-1 pt-3" appWebContainer>
                <app-tournament-match-table></app-tournament-match-table>

                @if (!lastTournamentLoaded) {
                    <div class="flex mt-5">
                        <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-orange"></app-icon>
                    </div>
                }

                @if (lastTournamentLoaded) {
                    <div class="m-4">
                        @if (lastTournament) {
                            <div
                                class="my-3 mt-4 text-center font-bebas-neue text-3xl text-black"
                                [translate]="'LAST_TOURNAMENT'"
                                i18n="@@LAST_TOURNAMENT">
                                Last tournament
                            </div>
                        } @else {
                            <app-primary-button
                                size="normal"
                                addedClasses="w-full mt-3"
                                color="orange"
                                (click)="playFirstTournament()"
                                [translate]="'PLAY_FIRST_TOURNAMENT'"
                                i18n="@@PLAY_FIRST_TOURNAMENT">
                                Play your first tournament
                            </app-primary-button>
                        }

                        @if (activeGameType.gameMode === 'match' && lastTournament) {
                            <app-tournament-card
                                [tournament]="lastTournament"
                                listType="participant_history"
                                (click)="goToLastTournamentDetail()">
                            </app-tournament-card>
                        }

                        @if (lastTournament) {
                            <app-primary-button
                                size="normal"
                                addedClasses="w-full mt-3"
                                color="orange"
                                (click)="viewAllTournaments()">
                                <div class="flex items-center space-x-2">
                                    <div [translate]="'VIEW_ALL_TOURNAMENTS'" i18n="@@VIEW_ALL_TOURNAMENTS">
                                        View all tournaments
                                    </div>
                                </div>
                            </app-primary-button>
                        }
                    </div>
                }
            </div>
        }
    </div>

    <app-bottom-navigation class="w-full" active="statistics"></app-bottom-navigation>
</div>
