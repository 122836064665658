<div class="flex flex-col w-full space-y-2 mb-4 relative">
    <button
        class="bg-white dark:!bg-white cursor-pointer grid grid-cols-12 items-center rounded-sm text-black dark:!text-black w-full py-1.5 box-border"
        (click)="facebookLogin()">
        <div class="flex justify-center col-span-3">
            <img class="max-h-8" src="assets/images/socials/facebook.png" />
        </div>
        <div class="font-bold col-span-6 w-full text-center whitespace-nowrap">
            @if (type() === 'continue') {
                <span [translate]="'CONTINUE_WITH_FACEBOOK'" i18n="@@CONTINUE_WITH_FACEBOOK">
                    Continue with Facebook
                </span>
            } @else if (type() === 'sign_up') {
                <span [translate]="'SIGN_UP_WITH_FACEBOOK'" i18n="@@SIGN_UP_WITH_FACEBOOK">Sign up with Facebook</span>
            }
        </div>
        <div class="col-span-3"></div>
    </button>

    @if (appleAuth) {
        <button
            class="bg-white dark:!bg-white cursor-pointer grid grid-cols-12 items-center rounded-sm text-black dark:!text-black w-full py-1.5 box-border"
            (click)="appleLogin()">
            <div class="flex justify-center col-span-3">
                <img class="max-h-8" src="assets/images/socials/apple.png" />
            </div>
            <div class="font-bold col-span-6 w-full text-center whitespace-nowrap">
                @if (type() === 'continue') {
                    <span [translate]="'CONTINUE_WITH_APPLE'" i18n="@@CONTINUE_WITH_APPLE">Continue with Apple</span>
                } @else if (type() === 'sign_up') {
                    <span [translate]="'SIGN_UP_WITH_APPLE'" i18n="@@SIGN_UP_WITH_APPLE">Sign up with Apple</span>
                }
            </div>
            <div class="col-span-3"></div>
        </button>
    }

    @if (isWeb) {
        <app-google-web-signin [type]="type()" class="h-14" (loginWithGoogle)="googleSignin($event)">
        </app-google-web-signin>
    } @else {
        <button
            class="bg-white dark:!bg-white cursor-pointer grid grid-cols-12 items-center rounded-sm text-black dark:!text-black w-full py-1.5 box-border"
            (click)="googleLogin()">
            <div class="flex justify-center col-span-3">
                <img class="max-h-8" src="assets/images/socials/google.png" />
            </div>
            <div class="font-bold col-span-6 w-full text-center whitespace-nowrap">
                @if (type() === 'continue') {
                    <span [translate]="'CONTINUE_WITH_GOOGLE'" i18n="@@CONTINUE_WITH_GOOGLE">
                        Continue with Google
                    </span>
                } @else if (type() === 'sign_up') {
                    <span [translate]="'SIGN_UP_WITH_GOOGLE'" i18n="@@SIGN_UP_WITH_GOOGLE">Sign up with Google</span>
                }
            </div>
            <div class="col-span-3"></div>
        </button>
    }
</div>
