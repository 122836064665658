import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decimalRound',
    standalone: true,
})
export class DecimalRoundPipe implements PipeTransform {
    transform(value: number): string {
        const decimalPart = value % 1;
        if (decimalPart !== 0) {
            return value.toFixed(1);
        }
        return value.toString();
    }
}
