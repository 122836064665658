<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern">
    <app-page-header mainNavigation="custom" color="transparent">
        <div left>
            <div
                class="text-center text-sm font-bold text-black"
                (click)="dismissModal()"
                [translate]="'CANCEL'"
                i18n="@@CANCEL">
                Cancel
            </div>
        </div>
        @if (currentSlide) {
            <div title class="text-black">{{ currentSlide.title }}</div>
        }
    </app-page-header>

    <div class="mt-2 flex-1 rounded-t-4xl bg-white pt-6 px-safe" *ngIf="currentSlide">
        <div class="space-y-3 bg-white px-5" *ngIf="!isEnd">
            <div class="dialog-input-label">{{ currentSlide.name }}</div>
            <div class="flex items-center justify-between space-x-2">
                <div
                    class="h-1.5 w-full rounded-full"
                    [class.bg-orange-50]="slide.index > currentSlide.index"
                    [class.bg-orange]="slide.index <= currentSlide.index"
                    *ngFor="let slide of slides"></div>
            </div>
        </div>

        <div class="mt-8 flex overflow-x-scroll bg-white scrollbar-hide">
            <div class="flex-remain px-5" *ngIf="currentSlide.type === 'upgrade_to_yearly'" [@slideAnimation]>
                <div *ngIf="recurring === 'yearly'">
                    <div
                        class="text-xl font-bold text-black"
                        [translate]="'SWITCH_TO_YEARLY'"
                        i18n="@@SWITCH_TO_YEARLY">
                        Switch to Yearly
                    </div>
                    <div
                        class="text-neutral-400"
                        [translate]="'SWITCH_TO_YEARLY_INTRODUCTION'"
                        i18n="@@SWITCH_TO_YEARLY_INTRODUCTION">
                        Can't get enough of DartCounter? Change to an yearly Ultimate subscription and save 38% per year
                        instantly!
                    </div>
                </div>
                <div *ngIf="recurring === 'monthly'">
                    <div
                        class="text-xl font-bold text-black"
                        [translate]="'SWITCH_TO_MONTHLY'"
                        i18n="@@SWITCH_TO_MONTHLY">
                        Switch to Monthly
                    </div>
                    <div
                        class="text-neutral-400"
                        [translate]="'SWITCH_TO_MONTHLY_INTRODUCTION'"
                        i18n="@@SWITCH_TO_MONTHLY_INTRODUCTION">
                        Can't get enough of DartCounter? Change to a monthly Ultimate subscription and always be able to
                        cancel within a month.
                    </div>
                </div>
                <div>
                    @for (feature of ultimateFeatures; track feature) {
                        <div class="mt-4 grid grid-cols-12 gap-3">
                            <div class="col-span-2 inline-flex items-center">
                                <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                                    <div class="font-bebas-neue text-lg">{{ $index + 1 }}</div>
                                </div>
                            </div>
                            <div class="col-span-10">
                                <div class="text-neutral-400">{{ feature.title }}</div>
                            </div>
                        </div>
                    }
                </div>
                <div class="mt-4">
                    <div
                        class="grid grid-cols-12 items-center rounded-lg bg-neutral-50 p-3 text-black"
                        (click)="setRecurring('yearly')"
                        *ngIf="recurring === 'yearly'">
                        <div class="col-span-7">
                            <div [translate]="'YEARLY'" i18n="@@YEARLY" class="text-xl font-semibold">Yearly</div>
                            <div
                                class="mt-1 text-xxs font-extralight text-neutral-400"
                                *ngIf="purchases.yearlyProduct?.pricing?.price">
                                {{ 'BILLED_ANNUALLY' | translate }} ({{
                                    removeSpaces(purchases.yearlyProduct?.pricing?.price)
                                }})
                            </div>
                            <div
                                *ngIf="purchases.yearlyProduct?.pricing?.price"
                                class="mt-2 inline-flex flex-row items-end rounded-md border-2 border-orange px-3 py-2 font-bebas-neue text-black">
                                <div class="text-4xl">{{ yearPricePerMonth(purchases.yearlyProduct?.pricing) }}</div>
                                <div class="ml-1 text-lg" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</div>
                            </div>
                            <div class="mt-2 font-bebas-neue text-sm font-extralight text-neutral-400">
                                <div
                                    class="old-price relative inline-block"
                                    *ngIf="purchases.monthlyProduct?.pricing?.price">
                                    {{ removeSpaces(purchases.monthlyProduct?.pricing?.price) }}
                                    <div [translate]="'PER_MONTH'" i18n="@@PER_MONTH" class="inline">per month</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-3 col-start-9 inline-flex items-end">
                            <div
                                class="sale inline-flex w-full flex-col items-center rounded-md bg-orange p-2 text-center font-bebas-neue !text-white">
                                <div class="text-1xl" [translate]="'SAVE_DISCOUNT'" i18n="@@SAVE_DISCOUNT">Save</div>
                                <div class="text-4xl">38%</div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="mt-3 grid grid-cols-12 items-center rounded-lg bg-neutral-50 p-3 text-black"
                        *ngIf="recurring === 'monthly'">
                        <div class="col-span-7">
                            <div [translate]="'MONTHLY'" i18n="@@MONTHLY" class="text-xl font-semibold">Monthly</div>
                            <div
                                [translate]="'MONTHLY_PAYMENT'"
                                i18n="@@MONTHLY_PAYMENT"
                                class="mt-1 text-xxs font-extralight text-neutral-400">
                                Monthly payment
                            </div>
                            <div
                                *ngIf="purchases.monthlyProduct?.pricing?.price"
                                class="mt-2 inline-flex flex-row items-end rounded-md border-2 border-black px-3 py-2 font-bebas-neue text-black">
                                <div class="text-4xl">{{ removeSpaces(purchases.monthlyProduct?.pricing?.price) }}</div>
                                <div class="ml-1 text-lg" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-col space-y-2 bg-white px-5 pl-10 pr-10 pt-5 pb-safe-offset-5">
        <app-primary-button size="normal" addedClasses="w-full" (click)="purchase()" *ngIf="!isEnd">
            {{ currentSlide.nextText }}
        </app-primary-button>
        <div class="pl-10 pr-10">
            <app-primary-button
                size="extrasmall"
                color="white"
                addedClasses="w-full text-neutral-400 font-extralight"
                fill="clear"
                [underline]="false"
                [translate]="'CANCEL_ANYTIME'"
                i18n="@@CANCEL_ANYTIME"
                [bold]="false">
                Not convinced? You can cancel your subscription at any time.
            </app-primary-button>
        </div>
    </div>
</div>
