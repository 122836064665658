import { Injectable, WritableSignal, signal } from '@angular/core';
import {
    CollectionReference,
    DocumentReference,
    Firestore,
    addDoc,
    collection,
    deleteDoc,
    doc,
    onSnapshot,
    query,
} from '@angular/fire/firestore';
import { getDoc } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { OnlineGameplay } from '../../dc-backend/dc-interfaces';
import { RXJSSubscriptionManager } from '../../dc-logging/rxjs-subscription.manager';
import { SUBSCRIPTION_KEY } from '../../dc-logging/subscription_enums';
import { DartCounterPreferenceService } from '../../dc-services/preference/preference.service';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { DCFireStoreSpectator, DCFireStoreUser, FIRESTORE_COLLECTION } from '../globals/firestore.tables';
import { UsersCollectionService } from './users.collection.service';

@Injectable()
export class SpectatorsCollectionService extends FireStoreCollectionsService {
    private defaultClass = new DCFireStoreSpectator();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.SPECTATORS;
    private firestore_collection: CollectionReference<DCFireStoreSpectator>;

    public spectators: WritableSignal<DCFireStoreSpectator[]> = signal([]);
    private _activeGameRef: DocumentReference<OnlineGameplay> = null;
    private _currentSpectatorRef: DocumentReference<DCFireStoreSpectator> = null;

    constructor(
        private firestore: Firestore,
        private _rxjsSubscriptionManager: RXJSSubscriptionManager,
        private _preferenceService: DartCounterPreferenceService,
        private _usersCollectionService: UsersCollectionService
    ) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<DCFireStoreSpectator>(this.collection_name);
    }

    enableSpectatorsFeature(gameDocRef: DocumentReference<OnlineGameplay>, spectate: boolean = false) {
        this.disableSpectatorsFeature();

        this._activeGameRef = gameDocRef;

        // Set the initial chat
        const spectatorsSubscription = this.getSpectators().subscribe((spectators: DCFireStoreSpectator[]) => {
            this.spectators.set(spectators);
        });
        this._rxjsSubscriptionManager.addSubscription(
            'core spectators-collection',
            SUBSCRIPTION_KEY.SPECTATORS,
            spectatorsSubscription
        );

        if (spectate && this._usersCollectionService.activeUserDocRef) {
            getDoc(this._usersCollectionService.activeUserDocRef).then((snapshot) => {
                this.spectate(snapshot.data() as DCFireStoreUser);
            });
        }
    }

    disableSpectatorsFeature() {
        if (this._currentSpectatorRef) {
            deleteDoc(this._currentSpectatorRef);
        }

        this.spectators.set([]);
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.SPECTATORS);
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.LOGGED_IN);
    }

    getSpectators(): Observable<DCFireStoreSpectator[]> {
        const collectionRef = collection(this._activeGameRef, this.collection_name);

        return new Observable<DCFireStoreSpectator[]>((observer) => {
            try {
                const spectatorsQuery = query(collectionRef);

                const spectators: DCFireStoreSpectator[] = [];

                const unsubscribe = onSnapshot(spectatorsQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as DCFireStoreSpectator;
                        const index = spectators.findIndex((spectator) => spectator.user.uid === data.user.uid);

                        if (change.type === 'added') {
                            spectators.push(data);
                        } else if (change.type === 'removed') {
                            if (index !== -1) {
                                spectators.splice(index, 1);
                            }
                        }
                    });

                    observer.next(spectators);
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    spectate(fsUser: DCFireStoreUser): void {
        const spectatorsCollectionRef = collection(this._activeGameRef, FIRESTORE_COLLECTION.SPECTATORS);
        addDoc(spectatorsCollectionRef, {
            doc_id: this._activeGameRef.id,
            user: fsUser,
        } as DCFireStoreSpectator).then((docRef) => {
            this._currentSpectatorRef = docRef;
        });
    }

    getDocByID(id: string): DocumentReference<DCFireStoreSpectator> {
        return doc(this.firestore_collection, id);
    }
}
