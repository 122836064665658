@if (false) {
    <ng-container i18n="@@MAX_DARTS">Max darts</ng-container>
}

<div class="ml-3 mr-3 flex items-end">
    <app-select
        title="{{ 'PERIOD' | translate }}"
        [options]="periodOptions"
        [initialValue]="statisticsRange"
        (selectionChange)="changeFilter('statisticsRange', $event)"
        class="mx-1 w-full"></app-select>
    <app-select
        title="{{ 'MAX_DARTS' | translate }}"
        [options]="maxDarts"
        [initialValue]="maxDart"
        (selectionChange)="changeFilter('maxDarts', $event)"
        class="mx-1 w-full"></app-select>
    <app-select
        title="{{ 'STARTSCORE' | translate }}"
        [options]="startScoreOptions"
        [initialValue]="startScoreOption"
        (selectionChange)="changeFilter('startScore', $event)"
        class="mx-1 w-full"></app-select>
</div>

<div>
    @if (statisticsRange === 'custom' || startScoreOption === 'custom') {
        <ion-row style="color: white; margin: 10px 10px 0">
            @if (statisticsRange === 'custom') {
                <ion-col style="padding: 0; display: flex">
                    <ion-col style="padding: 0">
                        <ion-select
                            style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                            interface="popover"
                            [(ngModel)]="year"
                            (ionChange)="changeStatisticsRange('custom')">
                            <ion-select-option *ngFor="let yearOption of years" [value]="yearOption">
                                {{ yearOption }}
                            </ion-select-option>
                        </ion-select>
                    </ion-col>
                    <ion-col style="padding: 0">
                        <ion-select
                            style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                            interface="popover"
                            [(ngModel)]="month"
                            (ionChange)="changeStatisticsRange('custom')">
                            <ion-select-option *ngFor="let monthOption of months" [value]="monthOption">
                                {{ monthOption }}
                            </ion-select-option>
                        </ion-select>
                    </ion-col>
                </ion-col>
            } @else {
                <ion-col></ion-col>
            }

            @if (startScoreOption === 'custom') {
                <ion-col style="padding: 0">
                    <ion-col style="padding: 0" (click)="changeStartScore()">
                        <div
                            style="overflow: auto; font-size: 13px; width: 100%; text-align: center"
                            class="text-black">
                            {{ startScore }}
                        </div>
                    </ion-col>
                </ion-col>
            } @else {
                <ion-col></ion-col>
            }
        </ion-row>
    }

    <div class="m-3">
        <div class="grid grid-cols-9 gap-1 whitespace-nowrap text-sm text-black">
            <div class="col-span-3"></div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'AVG'" i18n="@@AVG">Avg.</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'BEST'" i18n="@@BEST">Best</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'WORST'" i18n="@@WORST">Worst</div>
        </div>
        <div class="game-stat-main-table-container">
            <div class="col-span-3" [translate]="'HIGHEST_NUMBER'" i18n="@@HIGHEST_NUMBER">Highest number</div>
            <div class="col-span-2 py-1 text-center text-sm">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    {{ overallStats.highest.average || 0 }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('highest', 'best')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.highest.best || 0 }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('highest', 'worst')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.highest.worst || 0 }}
                }
            </div>

            <div class="col-span-3" [translate]="'STREAK'" i18n="@@STREAK">Streak</div>
            <div class="col-span-2 py-1 text-center text-sm">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    {{ overallStats.streak.average || 0 }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('streak', 'best')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.streak.best || 0 }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('streak', 'worst')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.streak.worst || 0 }}
                }
            </div>

            <div class="col-span-3" [translate]="'SUCCESS_RATE'" i18n="@@SUCCESS_RATE">Success rate</div>
            <div class="col-span-2 py-1 text-center text-sm">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    {{ overallStats.success_rate.average || 0 }}%
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('success_rate', 'best')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.success_rate.best || 0 }}%
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('success_rate', 'worst')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.success_rate.worst || 0 }}%
                }
            </div>
        </div>
    </div>
</div>
