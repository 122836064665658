import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AfterGameService } from '@services/after-game.service';
import { tap } from 'rxjs';

import { GamesLeaveBusterService } from '../services/games-leave-buster.service';

export const gamesLocalLeaveBusterGuard: CanActivateFn = (route, state) => {
    const leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);
    const afterGameService: AfterGameService = inject(AfterGameService);

    if (leaveBusterService.isAllowedToLeaveLocalGame()) {
        afterGameService.cleanupAfterLocalGame();

        return true;
    } else {
        return leaveBusterService.startLocalLeaveProcess().pipe(
            tap((hasLeft) => {
                if (hasLeft) {
                    afterGameService.cleanupAfterLocalGame();
                }
            })
        );
    }
};
