<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="DismissModal()"></app-icon>
        <div class="title-container">
            <div class="title" [translate]="'OPPONENT_LEFT'" i18n="@@OPPONENT_LEFT">Opponent left</div>
            @if (opponent) {
                <div
                    class="flex justify-center items-center space-x-2 !my-4 rounded-md bg-neutral-50 p-3 w-full cursor-pointer"
                    (click)="showPlayerInfo()">
                    <app-profile-photo
                        class="h-fit"
                        [size]="40"
                        [ultimate]="opponent.is_ultimate"
                        [url]="opponent.profile_photo_url"
                        [badge]="opponent.badge"></app-profile-photo>
                    <div class="font-bold">{{ opponent.full_name }}</div>
                </div>
            }
            @if (!leaveBusterService.currentTournamentGame) {
                <div class="subtitle" [translate]="'QUIT_OR_SAVE_GAME'" i18n="@@QUIT_OR_SAVE_GAME">
                    Do you want to quit or save this game?
                </div>
            }
        </div>

        <div class="button-container flex justify-between space-x-2">
            <app-primary-button
                size="normal"
                color="red"
                [class.w-full]="leaveBusterService.currentTournamentGame"
                [addedClasses]="leaveBusterService.currentTournamentGame ? 'w-full' : 'px-4'"
                (click)="DismissModal('quit')"
                [translate]="'QUIT'"
                i18n="@@QUIT">
                Quit
            </app-primary-button>
            @if (!leaveBusterService.currentTournamentGame) {
                <app-primary-button
                    size="normal"
                    color="blue"
                    addedClasses="px-4"
                    (click)="DismissModal('save')"
                    [translate]="'SAVE'"
                    i18n="@@SAVE">
                    Save
                </app-primary-button>
            }
        </div>
    </div>
</modal-content>
