import { inject, Injectable } from '@angular/core';
import { FriendApiService } from '@dc-api/friend.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { ModalController } from '@ionic/angular';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import { forkJoin, from, map } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';
import { VirtCamInactivityDialogComponent } from 'src/dialogs/virt-cam-inactivity/virt-cam-inactivity.dialog';
import { User } from '../dc-backend/dc-classes';
import { OnlineGameplay, ONLINEGAMESTATUS, PublicOnlineGameplay } from '../dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from '../dc-firestore/collection-helpers/active_games.collection.service';
import { CountsCollectionService } from '../dc-firestore/collection-helpers/counts.collection.service';
import { InviteCollectionService } from '../dc-firestore/collection-helpers/invite.collection.service';
import { LobbySettingsCollectionService } from '../dc-firestore/collection-helpers/lobby_settings.collection.service';
import { PublicGamesCollectionService } from '../dc-firestore/collection-helpers/public_games.collection.service';
import { UsersCollectionService } from '../dc-firestore/collection-helpers/users.collection.service';
import { FireStoreAuthService } from '../dc-firestore/firestore-auth.service';
import {
    DCFireStoreUser,
    FireStoreOnlineVersion,
    GamesCounts,
    ONLINESTATUS,
    UserCounts,
} from '../dc-firestore/globals/firestore.tables';
import { UnfinishedGamesService } from '../dc-firestore/unfinished-games.service';
import { LobbyUser } from '../dc-gamelogic/game-interfaces';
import { LocalStorageKey } from '../dc-localstorage';
import { RXJSSubscriptionManager } from '../dc-logging/rxjs-subscription.manager';
import { SUBSCRIPTION_KEY } from '../dc-logging/subscription_enums';
import { CAMERA_TYPE } from './camera/camera.models';
import { JanusVideoRoomService } from './dc-janus/janus-video-room.service';
import { OwnJanusService } from './dc-janus/own-janus.service';
import { FreeMatchesService } from './free-matches.service';
import { DartCounterAlertService } from './alert.service';

@Injectable()
export class OnlineGamesService {
    public started = false;
    public loaded = false;

    public user: User;
    public uid = null;
    public onlineSavedGameplay: OnlineGameplay;
    public showGameInLobby: boolean;
    public addGameAfterCreate: boolean = false;
    public redirectToLobbyAfterAdding: boolean = false;

    public FREE_MATCHES = 3;
    public rating: any = {};
    public blockedUsers: User[] = [];

    // Other users
    public onlineUsers: number = null;
    public playingUsers: number = null;

    // Friends
    public friends: LobbyUser[] = null;
    public totalFriends: number = null;
    public onlineFriends: LobbyUser[] = null;
    public inGameFriends: LobbyUser[] = null;
    public offlineFriends: LobbyUser[] = null;

    // Lobby observables
    public lobbyGames: OnlineGameplay[] = [];

    public liveGames: PublicOnlineGameplay[] = null;
    public liveGamesSearch = '';
    public liveGamesTake = 25;
    public liveGamesCount = null;

    private modalController: ModalController = inject(ModalController);
    private videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);

    private _subscriptionsToDestroy: string[] = [];

    constructor(
        private _gameApiService: GameApiService,
        private _statisticsApiService: StatisticsApiService,
        private _dcFireAuth: FireStoreAuthService,
        private _unfinishedGamesService: UnfinishedGamesService,
        private _userApiService: UserApiService,
        private _usersCollectionService: UsersCollectionService,
        private _activeGamesCollectionService: ActiveGamesCollectionService,
        private _rxjsSubscriptionManager: RXJSSubscriptionManager,
        private _lobbySettingsService: LobbySettingsCollectionService,
        private _freeMatchesService: FreeMatchesService,
        private _inviteCollectionService: InviteCollectionService,
        private _friendApiService: FriendApiService,
        private _countsCollectionService: CountsCollectionService,
        private _publicGamesCollection: PublicGamesCollectionService,
        private _ownJanusService: OwnJanusService,
        private userService: UserService
    ) {}

    start(user: User): void {
        if (this.started) {
            return;
        }

        this.started = true;
        this.loaded = false;

        this._inviteCollectionService.allowInvites = true;
        this._activeGamesCollectionService.isJoining = false;

        this.reset();

        const onlineSavedGameplayFromStorage = localStorage.getItem(LocalStorageKey.onlineSavedGameplay);
        if (onlineSavedGameplayFromStorage !== null) {
            const onlineSavedGameplay = JSON.parse(onlineSavedGameplayFromStorage) as OnlineGameplay;
            onlineSavedGameplay.cameraOnly = this._ownJanusService.ownCamera?.roomID
                ? onlineSavedGameplay.cameraOnly
                : false;

            if (onlineSavedGameplay.type === 'cricket_tactics' && !onlineSavedGameplay.game?.goal_amount) {
                localStorage.removeItem(LocalStorageKey.onlineSavedGameplay);
            } else {
                localStorage.setItem(LocalStorageKey.onlineSavedGameplay, JSON.stringify(onlineSavedGameplay));
                this.onlineSavedGameplay = onlineSavedGameplay;
            }
        }

        this._userApiService.getAllBlockedUsers({}).then((res) => {
            this.blockedUsers = res.data;
        });

        const loggedInSub = this._usersCollectionService.isLoggedIn$.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this._usersCollectionService.updateUserStatus(ONLINESTATUS.ONLINE);

                // Start watching invites if user is logged in
                this.watchLobbyNow();
                this._unfinishedGamesService.loadUnfinishedGames();

                setTimeout(() => {
                    this.uid = this._dcFireAuth.getCurrentUID();
                    this.user = user;
                    this.setUser();

                    this.loaded = true;
                }, 300);
            }
        });
        this._rxjsSubscriptionManager.addSubscription('online-games service', SUBSCRIPTION_KEY.LOGGED_IN, loggedInSub);
        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.LOGGED_IN);
    }

    stop(): void {
        this.started = false;

        // Unsubscribe from all subscriptions
        this._subscriptionsToDestroy.forEach((key) => {
            this._rxjsSubscriptionManager.cleanSubscription(key);
        });

        this._freeMatchesService.clear();

        this.reset();
    }

    reset(): void {
        this.onlineUsers = 0;
        this.playingUsers = 0;
        this.lobbyGames = [];
        this.liveGames = null;

        this._publicGamesCollection.clearFetchedGames();
    }

    searchLiveGames(playerName: string) {
        this.liveGames = [];

        if (playerName) {
            this._publicGamesCollection.searchGamesByPlayer(playerName);
        } else {
            this._publicGamesCollection.clearFetchedGames();
            this._publicGamesCollection.fetchGames(this.liveGamesTake);
        }
    }

    setUser() {
        this._statisticsApiService.getOnlineStatistics({ user_id: this.user.id }).then((res) => {
            this.user.last_results = res.data.last_results;
            this.user.two_month_average = res.data.two_month_average;

            this._usersCollectionService.updateFSUser(this.user);
        });

        this.loadFreeMatches();

        this.getPlayersCount();

        const watchOwnLobbyGames = this._lobbySettingsService.watchOwnLobbyGames().subscribe((onlineGameplays) => {
            if (onlineGameplays.length) {
                this._lobbySettingsService.currentGameInLobby = _.first(onlineGameplays);
                this.showGameInLobby = true;
            } else {
                this.showGameInLobby = false;
            }
        });

        // Manage the subscription in the core
        this._rxjsSubscriptionManager.addSubscription(
            'online-games service',
            SUBSCRIPTION_KEY.OWN_LOBBY_GAMES,
            watchOwnLobbyGames
        );

        // Auto unwatch onDestroy
        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.OWN_LOBBY_GAMES);
    }

    public loadFreeMatches(): Promise<void> {
        return new Promise((resolve) => {
            this._gameApiService
                .getStartedMatches({})
                .then((res) => {
                    this.user.weekly_started_matches = res.data ? res.data.length : 0;
                    const count = 3 - this.user.weekly_started_matches;
                    this._freeMatchesService.gamesPlayed = this.user.weekly_started_matches;
                    this._freeMatchesService.check(count, res.data[0] ? res.data[0].created_at : null, this.user);
                })
                .finally(() => resolve());
        });
    }

    public checkFreeMatches(showAlert: boolean): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.user.is_ultimate) {
                resolve(true);
                return;
            }

            this._gameApiService.getStartedMatches({}).then((res) => {
                this.user.weekly_started_matches = res.data ? res.data.length : 0;
                const count = 3 - this.user.weekly_started_matches;
                this._freeMatchesService.gamesPlayed = this.user.weekly_started_matches;
                this._freeMatchesService.check(count, res.data[0] ? res.data[0].created_at : null, this.user);

                if (showAlert && !this._freeMatchesService.canPlay) {
                    this.alertService.createAlert({
                        title: $localize`:@@NO_MORE_FREE_GAMES_THIS_WEEK:Your free online games for this week have been used`,
                        icon: 'error',
                    });
                }

                resolve(this._freeMatchesService.canPlay);
            });
        });
    }

    public watchLobbyNow() {
        // Watch the lobby
        const lobbyGamesSub = this._lobbySettingsService.getLobby().subscribe((res) => {
            this.lobbyGames = res;
        });

        // Manage the subscription in the core
        this._rxjsSubscriptionManager.addSubscription(
            'online-games service',
            SUBSCRIPTION_KEY.LOBBY_GAMES,
            lobbyGamesSub
        );

        // Auto unwatch onDestroy
        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.LOBBY_GAMES);

        this._countsCollectionService.getGamesCounts().then((gamesCount: GamesCounts) => {
            this.liveGamesCount = gamesCount.publicGames;
        });
    }

    async getPlayersCount(): Promise<void> {
        this._countsCollectionService.getUserCounts().then((userCount: UserCounts) => {
            this.playingUsers = userCount.playing;
            this.onlineUsers = userCount.online + userCount.playing;
        });

        this.getFriendList();
    }

    getFriendList() {
        this._friendApiService.getFriends({ user_id: this.user.id, limit: 1 }).then((firstRes) => {
            this._friendApiService.getFriends({ user_id: this.user.id, limit: firstRes.data.total }).then((res) => {
                this.totalFriends = res.data.total;
                this.friends = res.data.data as any;

                const friendIds = this.friends.map((friend) => friend.id);
                const friendIdChunks = this.chunkArray(friendIds, 10);

                // Create an array of Observables for each chunk query
                const chunkQueries = friendIdChunks.map((chunk) =>
                    from(this._usersCollectionService.getOnlineUsersByIDs(chunk))
                );

                if (friendIds.length) {
                    // Use forkJoin to execute all chunk queries simultaneously and wait for all of them
                    forkJoin(chunkQueries)
                        .pipe(
                            map((results) => results.flat()) // Flatten the results array
                        )
                        .subscribe({
                            next: (allStatuses) => {
                                this.processStatuses(allStatuses);
                            },
                            error: (error) => console.error('Error fetching statuses:', error),
                            // Complete handler if needed
                        });
                } else {
                    this.processStatuses([]);
                }
            });
        });
    }

    // Helper function to chunk the friend IDs array
    chunkArray<T>(array: T[], size: number): T[][] {
        return array.reduce((chunks, item, index) => {
            const chunk = Math.floor(index / size);
            if (!chunks[chunk]) {
                chunks[chunk] = []; // start a new chunk
            }
            chunks[chunk].push(item);
            return chunks;
        }, []);
    }

    processStatuses(fsFriends: DCFireStoreUser[]) {
        // Reset lists to process new statuses
        this.onlineFriends = [];
        this.inGameFriends = [];
        this.offlineFriends = _.cloneDeep(this.friends); // Start with all friends as offline

        fsFriends.forEach((fsFriend) => {
            if (
                fsFriend.status === ONLINESTATUS.ONLINE ||
                fsFriend.status === ONLINESTATUS.IN_APP ||
                fsFriend.status === ONLINESTATUS.LOCAL_GAME ||
                fsFriend.status === ONLINESTATUS.PLAYING
            ) {
                const friendIndex = this.offlineFriends.findIndex((friend) => friend.id === fsFriend.user_id);
                if (friendIndex !== -1) {
                    const friend = this.offlineFriends.splice(friendIndex, 1)[0]; // Remove from offline list
                    friend.uid = fsFriend.uid;
                    friend.last_results = fsFriend.last_results;
                    friend.two_month_average = fsFriend.two_month_average;

                    if (fsFriend.status === ONLINESTATUS.ONLINE || fsFriend.status === ONLINESTATUS.IN_APP) {
                        friend.status = ONLINESTATUS.ONLINE;
                        this.onlineFriends.push(friend);
                    } else if (
                        fsFriend.status === ONLINESTATUS.LOCAL_GAME ||
                        fsFriend.status === ONLINESTATUS.PLAYING
                    ) {
                        friend.status = fsFriend.status;
                        this.inGameFriends.push(friend);
                    }
                }
            }
        });

        // At this point, `this.offlineFriends` already contains friends not in `friendsStatuses`
    }

    public unwatchLiveGames() {
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.PUBLIC_GAMES);
    }

    public unwatchPLayers() {
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.ONLINE_USERS);
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.PLAYING_USERS);
    }

    isBlocked(id: number) {
        for (const blockedUser of this.blockedUsers) {
            if (blockedUser.id == id) {
                return true;
            }
        }
        return false;
    }

    trackByFn(index: number, item: any): any {
        return item.doc_id || item.uid || index;
    }

    prepareLobbyGame(
        status: ONLINEGAMESTATUS.INITIAL | ONLINEGAMESTATUS.INVITE_ONLY,
        additionalOwners: LobbyUser[] = []
    ): void {
        const uid = this._dcFireAuth.getCurrentUID();
        const user = this.user;
        this.onlineSavedGameplay.owners = [uid];
        additionalOwners.forEach((additionalOwner) => {
            this.onlineSavedGameplay.owners.push(additionalOwner.uid);
        });
        const firstPlayer = this._usersCollectionService.getDCFireStoreUser(user);
        this.onlineSavedGameplay.players = [firstPlayer];
        this.onlineSavedGameplay.onlineGameStatus = status;
        this.onlineSavedGameplay.last_updated = Timestamp.now();
        if (!this.onlineSavedGameplay.onlineGameVersion) {
            this.onlineSavedGameplay.onlineGameVersion = FireStoreOnlineVersion;
        }
    }

    setLobbyGame() {
        this.userService.checkSuspension('online', true).then((suspension) => {
            if (!suspension.suspended) {
                this.prepareLobbyGame(ONLINEGAMESTATUS.INITIAL);

                this.onlineSavedGameplay.cameraOnly = this._ownJanusService.ownCamera?.roomID
                    ? this.onlineSavedGameplay.cameraOnly
                    : false;
                this._lobbySettingsService.addItem(this.onlineSavedGameplay);

                this.addGameAfterCreate = false;
            }
        });
    }

    updateLobbyGame(): void {
        this.prepareLobbyGame(ONLINEGAMESTATUS.INITIAL);
        this._lobbySettingsService.updateOwnLobbyGame(this.onlineSavedGameplay);

        this.addGameAfterCreate = false;
    }

    controlSmartDeviceInactivity(showDialog: boolean, autoStopStreaming: boolean, hasTimeout: boolean): void {
        if (this.videoRoomService.smartDeviceInactivityTimeout) {
            clearTimeout(this.videoRoomService.smartDeviceInactivityTimeout);
            this.videoRoomService.smartDeviceInactivityTimeout = null;
        }

        if (
            showDialog &&
            this.videoRoomService.usingExternal &&
            this.videoRoomService.ownCamera.camType === CAMERA_TYPE.SMART_DEVICE &&
            this.videoRoomService.smartDevice
        ) {
            this.videoRoomService.smartDeviceInactivityTimeout = setTimeout(
                () => {
                    this.videoRoomService.smartDeviceInactivityTimeout = null;
                    this.modalController
                        .create({
                            component: VirtCamInactivityDialogComponent,
                            componentProps: {
                                autoStopStreaming,
                            },
                            backdropDismiss: false,
                            cssClass: 'auto-height',
                        })
                        .then((elem) => {
                            elem.present();
                        });
                },
                hasTimeout ? 60 * 1000 : 0
            );
        }
    }
}
