<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'THROWN_SCORES'" i18n="@@THROWN_SCORES">Scoring</div>
    </div>
    <div class="slide-modal-content">
        @if (scores) {
            @for (score of scoresList; track score; let index = $index) {
                <div
                    class="flex justify-center py-2"
                    [ngClass]="{ 'border-b-1 border-gray-100': index !== scoresList.length - 1 }">
                    @if (score.from === score.to) {
                        <div class="w-20 pl-8">{{ score.from }}</div>
                    }
                    @if (score.from !== score.to) {
                        <div class="w-20 pl-8">{{ score.from }}+</div>
                    }
                    <div class="w-20 pr-8 text-right">
                        {{ scores[score.key].scores || 0 }}
                    </div>
                </div>
            }
        }
    </div>
</div>
