/* eslint-disable max-len */
import {
    Component,
    effect,
    ElementRef,
    HostListener,
    inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ProfanityApiService } from '@dc-api/profanity.api.service';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import {
    ChatsCollectionService,
    SendChatPayload,
} from '@dc-core/dc-firestore/collection-helpers/chats.collection.service';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { CHAT_MSG, ChatMessage, IN_GAME_COMMS } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { VoiceCallService, VoiceCallStatus } from '@dc-core/dc-services/voice/voice-call.service';
import { ModalController, Platform } from '@ionic/angular';
import { Microphone } from '@mozartec/capacitor-microphone';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { GamesLeaveBusterService } from 'src/app/modules/games/services/games-leave-buster.service';
import { MaintainHeightDirective } from 'src/directives/maintain-height.directive';
import { environment } from 'src/environments/environment';

import {
    VoiceCallDialogComponent,
    VoiceCallDialogPayload,
    VoiceCallDialogResponse,
} from '../voice-call/voice-call.dialog';

export type InGameCommsDialogPayload = {
    onlineGameplay: OnlineGameplay;
    authedUserUid: string;
    canCall: boolean;
};

@Component({
    selector: 'app-in-game-comms-dialog',
    templateUrl: 'in-game-comms.dialog.html',
})
export class InGameCommsDialogComponent implements OnInit, OnDestroy {
    @Input() onlineGameplay: OnlineGameplay;
    @Input() authedUserUid: string;
    @Input() canCall: boolean = true;

    callingUser: DCFireStoreUser;
    status: VoiceCallStatus = VoiceCallStatus.None;

    VoiceCallStatus = VoiceCallStatus;

    messages: ChatMessage[] = [
        {
            chat_msg: CHAT_MSG.BE_RIGHT_BACK,
            text: 'BRB',
        },
        {
            chat_msg: CHAT_MSG.CALL_YOU_SOON,
            text: $localize`:@@CHAT_CALL_YOU_SOON:Call you soon!`,
        },
    ];

    public disableChat = false;
    public chatSegment: 'chat' | 'fast' = 'chat';
    public customChatMessage = '';
    public IN_GAME_COMMS = IN_GAME_COMMS;
    public customChatPlaceholder: string = $localize`:@@SEND_A_MESSAGE_DOTS:Send a message...`;

    @ViewChild(MaintainHeightDirective, { static: false }) maintainHeightDirective;
    @ViewChild('chatInput') private chatInput: ElementRef;
    private gamesLeaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);
    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    private _backButtonSubscription: Subscription;

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement: HTMLElement): void {
        if (targetElement.classList.contains('ion-page')) {
            this.close();
        }
    }

    constructor(
        public videoRoomService: JanusVideoRoomService,
        public voiceCallService: VoiceCallService,
        public ingameCameraService: InGameCameraService,
        public modal: ModalController,
        public _modal: ModalController,
        public auth: AuthService,
        public chatsCollectionService: ChatsCollectionService,
        private _profanityApiService: ProfanityApiService,
        private _auth: AuthService,
        private _platform: Platform,
        private _alertService: DartCounterAlertService,
        private _preferenceService: DartCounterPreferenceService,
        private _translateService: TranslateService
    ) {
        effect(() => {
            this.status = this.voiceCallService.currentStatus();
        });

        effect(() => {
            if (!this.gamesLeaveBusterService.inGame()) {
                this.close();
            }
        });
    }

    public ngOnInit(): void {
        this.chatsCollectionService.showingDialog = true;
        this.voiceCallService.authUserId = this._auth.user.id;
        this.voiceCallService.callingUser = _.find(this.onlineGameplay.players, (p) => p.uid != this.authedUserUid);
        this.chatsCollectionService.unreadChatMessages.set(0);
        this.voiceCallService.missedCalls.set(0);

        this.focusInput();

        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });
    }

    onKeydown(event?: KeyboardEvent): void {
        if (event.key.toLowerCase() === 'enter') {
            if (!event.shiftKey) {
                this.sendCustomChat();
            }
        }
    }

    sendChat(message: ChatMessage): void {
        if (this.disableChat) {
            return;
        }

        this.disableChat = true;

        const sender = _.find(this.onlineGameplay.players, (p) => p.uid == this.authedUserUid);
        this.chatsCollectionService
            .sendChatMessage({
                sender,
                gameplay_doc_id: this.onlineGameplay.doc_id,
                chat_msg: message.chat_msg,
            } as SendChatPayload)
            .finally(() => {
                this.disableChat = false;
            });

        this.chatSegment = 'chat';
    }

    sendEmoji(event: EmojiEvent): void {
        this.customChatMessage = event.emoji.native;
        this.sendCustomChat(true);
    }

    sendCustomChat(emojiOnly = false): void {
        if (this.disableChat) {
            return;
        }

        this.customChatMessage = this.customChatMessage.replace(/\n/g, '');

        if (!this.customChatMessage.length) {
            return;
        }

        this.disableChat = true;

        this._profanityApiService
            .filterProfanityText({ text: this.customChatMessage })
            .then((res) => {
                // Send alert to opponent
                const sender = _.find(this.onlineGameplay.players, (p) => p.uid == this.authedUserUid);
                this.chatsCollectionService
                    .sendChatMessage({
                        sender,
                        gameplay_doc_id: this.onlineGameplay.doc_id,
                        custom_msg: res.data.filtered,
                        unfiltered_msg: this.customChatMessage,
                        emoji_only: emojiOnly,
                    } as SendChatPayload)
                    .finally(() => {
                        this.disableChat = false;
                    });

                this.customChatMessage = '';
                this.maintainHeightDirective?.reset();
                this.chatSegment = 'chat';
            })
            .catch(() => {
                this.disableChat = false;
            });
    }

    toggleChatSegment(): void {
        if (this.chatSegment === 'chat') {
            this.chatSegment = 'fast';
        } else {
            this.chatSegment = 'chat';
        }
    }

    async call(): Promise<void> {
        if (
            this.appFeaturesService.enabledAppFeatures().speech_to_score &&
            this._preferenceService.enableSpeechToScore
        ) {
            this._alertService.createAlert({
                title: $localize`:@@CANNOT_USE_VOICE_CALL_WITH_SPEECH_TO_SCORE:Cannot use Voice chat with Speech to score`,
                text: $localize`:@@PLEASE_DISABLE_SPEECH_TO_SCORE_TO_USE_VOICE_CALL:Please disable Speech to score in order to use Voice chat.`,
                icon: 'info',
            });
            return;
        }

        if (this._platform.is('capacitor')) {
            try {
                const checkMicPermissions = await Microphone.checkPermissions();
                if (checkMicPermissions.microphone !== 'granted') {
                    const requestMicResult = await Microphone.requestPermissions();
                    if (requestMicResult.microphone === 'granted') {
                        setTimeout(() => {
                            this.call();
                        }, 500);
                        return;
                    } else {
                        throw 'No permission';
                    }
                }
            } catch (_) {
                this._alertService.createAlert({
                    title: $localize`:@@ENABLE_MIC_APP_PERMS:Please enable microphone permissions in your device settings.`,
                    icon: 'error',
                    timer: 2000,
                    timerProgressBar: true,
                    showCloseButton: true,
                });
                return;
            }
        }

        this.voiceCallService.call();

        this.showVoiceCall();
    }

    showVoiceCall(): void {
        if (!this.voiceCallService.showingDialog) {
            this._modal
                .create({
                    component: VoiceCallDialogComponent,
                    componentProps: {
                        callerUser: _.find(this.onlineGameplay.players, (p) => p.uid == this.authedUserUid),
                        callingUser: _.find(this.onlineGameplay.players, (p) => p.uid != this.authedUserUid),
                        gameplayDocId: this.onlineGameplay.doc_id,
                    } as VoiceCallDialogPayload,
                    cssClass: ['comms-modal', 'from-bottom'],
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        const data: VoiceCallDialogResponse = dialogRes.data;
                        if (!data) {
                            this.close();
                        }
                    });
                });
        }
    }

    hangup(): void {
        this.voiceCallService.hangup();
    }

    decline(): void {
        this.voiceCallService.decline();
    }

    accept(): void {
        this.voiceCallService.accept();
    }

    toggleMute(): void {
        this.videoRoomService.ownAudioUserMedia.toggleMuteAudio();
    }

    translated(tag: string): string {
        switch (tag) {
            case 'USER_IS_BUSY':
                $localize`:@@USER_IS_BUSY:${this.callingUser.first_name}:user: is busy`;
                return this._translateService.instant('USER_IS_BUSY', {
                    user: this.callingUser.first_name,
                });
            case 'USER_HUNG_UP_THE_CALL':
                $localize`:@@USER_HUNG_UP_THE_CALL:${this.callingUser.first_name}:user: hung up the call`;
                return this._translateService.instant('USER_HUNG_UP_THE_CALL', {
                    user: this.callingUser.first_name,
                });
        }
    }

    public close(returnValue: any = null): void {
        this.modal.dismiss(returnValue);
    }

    ngOnDestroy(): void {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }

        const currentCall = this.voiceCallService.currentCall();
        if (
            currentCall &&
            (currentCall.status === VoiceCallStatus.Hangup ||
                currentCall.status === VoiceCallStatus.Declined ||
                currentCall.status === VoiceCallStatus.NoResponse)
        ) {
            this.voiceCallService.currentCall.set(null);
        }

        this.chatsCollectionService.unreadChatMessages.set(0);
        this.voiceCallService.missedCalls.set(0);
        this.chatsCollectionService.showingDialog = false;
    }

    private focusInput(): void {
        setTimeout(() => {
            if (this.chatInput?.nativeElement && environment.isWeb) {
                //Set focus to the score input element
                this.chatInput.nativeElement.focus();
            }
        }, 250);
    }
}
