import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameApiService } from '@dc-api/game.api.service';
import { PlayerType } from '@dc-core/dc-gamelogic/settings/settings.globals';
import { GameLabelService } from '@dc-core/dc-services/game-label.service';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { CricketTactics, Sound } from 'dc-core/dc-backend/dc-classes';
import { SoundPath } from 'dc-core/dc-backend/dc-enums';
import { AddPlayerPayload, EditPlayerPayload } from 'dc-core/dc-gamelogic/game-interfaces';
import { DartCounterCricketTacticsSettings } from 'dc-core/dc-gamelogic/settings/local/cricket-tactics/cricket-tactics-settings.default';
import { DartbotLevel } from 'dc-core/dc-gamelogic/settings/local/dartbot/dartbot.config';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterAudioService } from 'dc-core/dc-services/audio/audio.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import _ from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { TUTORIALS } from 'src/app/app.globals';
import {
    AddPlayerDialogResponse,
    GamesLocalAddPlayerDialogComponent,
} from 'src/app/modules/games/components/games-local-add-player-dialog/games-local-add-player-dialog.component';
import {
    AssignGameLabelsDialogComponent,
    AssignGameLabelsDialogPayload,
} from 'src/dialogs/assign-game-labels/assign-game-labels.dialog';
import { GoalAmountDialogComponent } from 'src/dialogs/goalamount/goalamount.dialog';
import { OwnAccountWarningDialogComponent } from 'src/dialogs/own-account-warning/own-account-warning';
import { PlayerOptionsModalComponent } from 'src/dialogs/playerOptions.modal/playerOptions.modal';
import { SuddenDeathDialogComponent } from 'src/dialogs/suddendeath/suddendeath.dialog';
import { TutorialDialogComponent } from 'src/dialogs/tutorial/tutorial';
import { VSDartbotDialogComponent } from 'src/dialogs/vsdartbot/vsdartbot.dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import { LoginOrCreateDialogComponent } from '../../../dialogs/login-or-create/login-or-create';

@Component({
    selector: 'app-cricket-tactics-new-game',
    templateUrl: 'cricket-tactics.new-game.component.html',
})
export class CricketTacticsNewGameComponent extends DartCounterCricketTacticsSettings implements OnInit {
    public isWeb = environment.isWeb;

    public disableReorder = false;
    public tutorials: number;
    public tutorialsKey: string;

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public menu: MenuController,
        public platform: Platform,
        private loading: DCLoadingService,
        private _audioService: DartCounterAudioService,
        private _preferenceService: DartCounterPreferenceService,
        private _alertService: DartCounterAlertService,
        public modal: ModalController,
        private dragulaService: DragulaService,
        public route: ActivatedRoute,
        private _gameApiService: GameApiService,
        public gameLabelService: GameLabelService,
        private ga: DartCounterAnalyticsService
    ) {
        super();
    }

    ngOnInit() {
        this.setUser(this.auth.user);

        this.tutorials = TUTORIALS.TACTICS;
        this.tutorialsKey = 'TACTICS';

        this.menu.enable(true, 'mainmenu');
        this.menu.enable(false, 'ingamemenu');
    }

    ionViewWillEnter() {
        this.ga.trackView('Cricket / Tactics | Setup');
    }

    changePlayerType(playerType: PlayerType): void {
        if (this.playerType === 'players' && playerType === 'teams' && this.users.length === 1) {
            this._alertService.createAlert({
                title: $localize`:@@ADD_SECOND_PLAYER_FOR_TEAMS:Add a second player to play in teams`,
                icon: 'info',
            });
            return;
        }

        super.changePlayerType(playerType);
    }

    showPlayerOptions(event: EditPlayerPayload) {
        if (event.user.is_cpu) {
            this.vsDartbotChanged(true);
        } else {
            this.modal
                .create({
                    component: PlayerOptionsModalComponent,
                    componentProps: {
                        user: event.user.id ? event.user : _.cloneDeep(event.user),
                    },
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.updateUser(event.teamIndex, event.userIndex, dialogRes.data);
                        }
                    });
                });
        }
    }

    vsDartbotChanged(value: boolean): void {
        if (value) {
            this.modal
                .create({
                    component: VSDartbotDialogComponent,
                    backdropDismiss: false,
                    componentProps: {
                        cpuLevel: this.dartbotLevel.level,
                        gameMode: 'cricket_tactics',
                    },
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        const result: DartbotLevel = dialogRes.data;
                        if (result) {
                            if (this._preferenceService.cricketTacticsDartbot) {
                                this.vsDartbotChangedWithUser(
                                    true,
                                    this._preferenceService.cricketTacticsDartbot,
                                    result
                                );
                            } else {
                                this.loading.ShowDefaultLoader();

                                this._gameApiService
                                    .getDartbot({ game_mode: 'cricket_tactics' })
                                    .then((res) => {
                                        this._preferenceService.cricketTacticsDartbot = res.data;
                                        this.vsDartbotChangedWithUser(
                                            true,
                                            this._preferenceService.cricketTacticsDartbot,
                                            result
                                        );
                                    })
                                    .finally(() => this.loading.DismissLoader());
                            }
                        } else {
                            this.vsDartbotChangedWithUser(false, null, null);
                        }
                    });
                });
        } else {
            this.vsDartbotChangedWithUser(false, null, null);
        }
    }

    showGoalAmountDialog() {
        this.modal
            .create({
                component: GoalAmountDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    goalAmount: JSON.parse(JSON.stringify(this.goalAmount)),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.goalAmount = dialogRes.data;
                        const errors = this.checkForErrors();
                        if (errors.length) {
                            this._alertService.createAlert({
                                title: errors.join('\n'),
                                icon: 'error',
                                timer: 3000,
                            });
                        }
                    }
                });
            });
    }

    ShowLogin() {
        this.modal.create({ component: LoginOrCreateDialogComponent }).then((elem) => elem.present());
    }

    public ToggleReorder() {
        this.disableReorder = !this.disableReorder;
    }

    toggleTwoLegsDifference() {
        super.toggleTwoLegsDifference();

        if (this.twoLegsDifference) {
            let dialogRef;
            this.modal
                .create({
                    component: SuddenDeathDialogComponent,
                    backdropDismiss: true,
                    componentProps: {
                        suddenDeath: JSON.parse(JSON.stringify(this.suddenDeath)),
                    },
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                    dialogRef = elem;
                    dialogRef.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.suddenDeath = dialogRes.data;
                        }
                    });
                });
        } else {
            this.suddenDeath = false;
        }
    }

    ShowHelp() {
        const slides = [];
        for (let i = 1; i <= this.tutorials; i++) {
            slides.push({
                title: this.tutorialsKey + '_SLIDE' + i + '_TITLE',
                description: this.tutorialsKey + '_SLIDE' + i + '_CONTENT',
            });
        }

        this.modal
            .create({
                component: TutorialDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    slides: slides,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
            });
    }

    addPlayer(event: AddPlayerPayload): void {
        if (event.user) {
            super.addUser(event.user, event.team);
        } else {
            this.modal
                .create({
                    component: GamesLocalAddPlayerDialogComponent,
                    backdropDismiss: false,
                    showBackdrop: true,
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        const addPlayer: AddPlayerDialogResponse = dialogRes.data;
                        if (addPlayer) {
                            this.addUser(addPlayer.user, event.team);
                        }
                    });
                });
        }
    }

    startGame(forceOwnUser = false): void {
        const errors = this.checkForErrors();
        if (errors.length) {
            this._alertService.createAlert({
                title: errors.join('\n'),
                icon: 'error',
                timer: 3000,
            });
        } else {
            if (!this.hasOwnUser() && !forceOwnUser) {
                this.modal
                    .create({
                        component: OwnAccountWarningDialogComponent,
                        componentProps: {
                            playerType: this.playerType,
                            users: JSON.parse(JSON.stringify(this.users)),
                            teams: JSON.parse(JSON.stringify(this.teams)),
                        },
                        cssClass: 'auto-height',
                        showBackdrop: true,
                    })
                    .then((elem) => {
                        elem.present();
                        elem.onDidDismiss().then((dialogRes) => {
                            if (dialogRes.data) {
                                if (this.playerType === 'players') {
                                    this.users = dialogRes.data.users;
                                } else {
                                    this.teams = dialogRes.data.teams;
                                }
                                this.startGame(true);
                            }
                        });
                    });
                return;
            }

            this.saveGameSettings();
            const cricketTactics: CricketTactics = this.mapToCricketTacticsObject();

            this._preferenceService.transition = true;
            setTimeout(() => {
                this._preferenceService.transition = false;
            }, 1500);

            setTimeout(() => {
                if (this._preferenceService.allowCaller) {
                    let isUltimate: boolean;
                    let sound: Sound = null;
                    if (this.playerType === 'players') {
                        isUltimate = this.users[0].is_ultimate;
                        sound = this.users[0]?.profile?.sound;
                    } else {
                        isUltimate = this.teams[0].users[0].is_ultimate;
                        sound = this.teams[0].users[0]?.profile?.sound;
                    }

                    if (isUltimate && sound) {
                        this._audioService.playAudioFromBackend(SoundPath.NAMES, sound.file);
                        this._audioService.addDeviceToAudioStack(SoundPath.TEXTS, 'tothrowfirst.mp3');
                        this._audioService.addDeviceToAudioStack(SoundPath.TEXTS, 'gameon.mp3');
                    } else {
                        this._audioService.playAudioFromDevice(SoundPath.TEXTS, 'gameon.mp3');
                    }
                }

                this.nav.navigateForward('/game/cricket-tactics', { state: { cricketTactics } });
            }, 1000);
        }
    }

    assignGameLabels() {
        this.modal
            .create({
                component: AssignGameLabelsDialogComponent,
                componentProps: {
                    assignedGameLabels: this.gameLabelService.selectedLabels,
                } as AssignGameLabelsDialogPayload,
                cssClass: 'auto-height',
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onWillDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.gameLabelService.setSelectedLabels(dialogRes.data);
                    }
                });
            });
    }

    goBack(): void {
        this.nav.navigateBack('/local-games');
    }
}
