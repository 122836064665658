import { Pipe, PipeTransform } from '@angular/core';
import { SafeHouseSettings } from 'dc-core/dc-backend/dc-enums';

@Pipe({
    name: 'safeHouse',
    standalone: true,
})
export class SafeHousePipe implements PipeTransform {
    transform(safeHouse: SafeHouseSettings): string {
        switch (safeHouse) {
            case 'back_to_start':
                return $localize`:@@BACK_TO_START:Back to the start`;
            case 'after_check_with_three':
                return $localize`:@@AFTHER_CHECK_WITH_THREE:After check with 3 darts`;
            case 'after_check_with_six':
                return $localize`:@@AFTHER_CHECK_WITH_SIX:After check with 6 darts`;
            case 'after_three_in_a_row':
                return $localize`:@@AFTER_THREE_IN_A_ROW:After check with 6 darts`;
            case 'after_five_in_a_row':
                return $localize`:@@AFTER_FIVE_IN_A_ROW:After five checks in a row`;
            case 'after_each':
                return $localize`:@@AFTER_EACH:After each check`;
        }
    }
}
