import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { User } from '@dc-core/dc-backend/dc-classes';
import { DartCounterCricketTacticsTurn } from '@dc-core/dc-gamelogic/in-game/cricket-tactics/cricket-tactics.in-game.classes';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

export type HitNumber = {
    number: number;
    amount: number;
};

export type CricketTacticsTurnDialogPayload = {
    user: User;
    cricketTacticsTurn: DartCounterCricketTacticsTurn;
};

@Component({
    selector: 'app-cricket-turn-dialog',
    templateUrl: 'cricket-tactics-turn.dialog.html',
})
export class CricketTacticsTurnDialogComponent implements OnInit, OnDestroy {
    @Input() user: User;
    @Input() cricketTacticsTurn: DartCounterCricketTacticsTurn;
    hitNumbers: HitNumber[] = [];
    score = 0;

    private timeoutId: number;

    constructor(
        public view: ModalController,
        public auth: AuthService
    ) {}

    ngOnInit(): void {
        if (this.cricketTacticsTurn) {
            if (this.cricketTacticsTurn.amountOf.length) {
                this.cricketTacticsTurn.amountOf.forEach((value, key) => {
                    if (value > 0) {
                        this.hitNumbers.push({ number: key, amount: value });
                    }
                });
            }
            this.score = this.cricketTacticsTurn.total_score;
        }

        this.timeoutId = setTimeout(() => {
            this.close();
        }, 3000);
    }

    getMptext(multiplier: number): string {
        switch (multiplier) {
            case 1:
                return 'S';
            case 2:
                return 'D';
            case 3:
                return 'T';
        }
    }

    public ngOnDestroy(): void {
        clearTimeout(this.timeoutId);
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
