export enum OmniResponseType {
    INFO = 'info',
    EXPOSURE_DONE = 'exposure_done',
    THROW = 'throw',
    MESSAGE = 'message',
    PLAYERCHANGE = 'playerchange',
    SYSTEM_READY = 'system_ready',
    REMOVE_DARTS = 'remove_darts',
    AUTO_EXPOSURE = 'auto_exposure',
    MONITORING = 'monitoring',
    BOARD_EMPTY_STATE = 'board_empty_state',
    CAM_STATUS = 'cam_status',
}

export enum OmniRequestType {
    //[GET] requests from the Omni
    GET_FINAL_SEGMENTS = 'get_final_segments',
}

export enum OmniAlertType {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
    LOADING = 'loading',
}

export enum OmniReportType {
    AUTO_EXPOSURE = 'auto_exposure',
    CALIB_DONE = 'calibdone',
    CAM_SETUP = 'cam_setup',
    ANGLE = 'angle',
    SHIFT = 'shift',
}

export enum OmniMessageType {
    INFO = 'info',
    RESPONSE = 'response',
    REPORT = 'report',
    REQUEST = 'request',
    ALERT = 'alert',
}

export enum OmniCameraReports {
    AUTO_EXPOSURE = 'auto_exposure',
    CALIB_DONE = 'calibdone',
    CAM_SETUP = 'cam_setup',
    ANGLE = 'angle',
    SHIFT = 'shift',
    CAP_EXCEPTION = 'capexception',
    CORRUPTED = 'corrupted',
    LOWFPS = 'lowfps',
    FPS = 'fps',
    VIDIOC_DQBUF = 'vidioc_dqbuf',
}

export enum OmniErrorType {
    CAM_STOPPED = 'cam_stopped',
    CAM_ERROR = 'cam_error',
}

export enum OmniCameraErrorType {
    SHIFT = 'shift',
    CAP_EXCEPTION = 'capexception',
    CORRUPTED = 'corrupted',
    LOWFPS = 'lowfps',
    FPS = 'fps',
    VIDIOC_DQBUF = 'vidioc_dqbuf',
}

export enum OmniState {
    INITIALIZING = 'INITIALIZING',
    READY = 'READY',
    CAM = 'CAM',
    CALIBRATING = 'CALIBRATING',
    WAITING_ON_BOARD_EMPTY = 'WAITING_ON_BOARD_EMPTY',
    WAITING_FOR_DART = 'WAITING_FOR_DART',
    WAITING_FOR_PLAYERCHANGE = 'WAITING_FOR_PLAYERCHANGE',
    AUTO_EXPOSURE = 'AUTO_EXPOSURE',
}

export interface OmniResponse {
    humanReadable: string;
    messageType: OmniMessageType;
    responseType: OmniResponseType;
}

export interface OmniRequest {
    humanReadable: string;
    messageType: OmniMessageType;
    requestType: OmniRequestType;
}

export interface OmniReport {
    humanReadable: string;
    messageType: OmniMessageType;
    reportType: OmniReportType;
    cam1: boolean;
    cam2: boolean;
    cam3: boolean;
    cam4: boolean;
}

///////

export interface CameraError {
    camErrorType: string;
}

export interface OmniCameraStatus {
    camNumber: number;
    angle?: number;
    succeeded?: boolean;
    errors: CameraError[];
}

export interface OmniCameraStatusResponse extends OmniResponse {
    camera: OmniCameraStatus;
}
