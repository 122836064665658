import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova, cordovaPropertyGet, cordovaPropertySet } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var HTTP = /** @class */function (_super) {
  __extends(HTTP, _super);
  function HTTP() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  HTTP.prototype.getBasicAuthHeader = function (username, password) {
    return cordova(this, "getBasicAuthHeader", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.useBasicAuth = function (username, password) {
    return cordova(this, "useBasicAuth", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.getHeaders = function (host) {
    return cordova(this, "getHeaders", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.setHeader = function (host, header, value) {
    return cordova(this, "setHeader", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.getDataSerializer = function () {
    return cordova(this, "getDataSerializer", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.setDataSerializer = function (serializer) {
    return cordova(this, "setDataSerializer", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.setCookie = function (url, cookie) {
    return cordova(this, "setCookie", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.clearCookies = function () {
    return cordova(this, "clearCookies", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.removeCookies = function (url, cb) {
    return cordova(this, "removeCookies", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.getCookieString = function (url) {
    return cordova(this, "getCookieString", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.getRequestTimeout = function () {
    return cordova(this, "getRequestTimeout", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.setRequestTimeout = function (timeout) {
    return cordova(this, "setRequestTimeout", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.getFollowRedirect = function () {
    return cordova(this, "getFollowRedirect", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.setFollowRedirect = function (follow) {
    return cordova(this, "setFollowRedirect", {
      "sync": true
    }, arguments);
  };
  HTTP.prototype.setServerTrustMode = function (mode) {
    return cordova(this, "setServerTrustMode", {}, arguments);
  };
  HTTP.prototype.setClientAuthMode = function (mode, options) {
    return cordova(this, "setClientAuthMode", {}, arguments);
  };
  HTTP.prototype.post = function (url, body, headers) {
    return cordova(this, "post", {}, arguments);
  };
  HTTP.prototype.postSync = function (url, body, headers, success, failure) {
    return cordova(this, "post", {
      "methodName": "post",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.get = function (url, parameters, headers) {
    return cordova(this, "get", {}, arguments);
  };
  HTTP.prototype.getSync = function (url, parameters, headers, success, failure) {
    return cordova(this, "get", {
      "methodName": "get",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.put = function (url, body, headers) {
    return cordova(this, "put", {}, arguments);
  };
  HTTP.prototype.putSync = function (url, body, headers, success, failure) {
    return cordova(this, "put", {
      "methodName": "put",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.patch = function (url, body, headers) {
    return cordova(this, "patch", {}, arguments);
  };
  HTTP.prototype.patchSync = function (url, body, headers, success, failure) {
    return cordova(this, "patch", {
      "methodName": "patch",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.delete = function (url, parameters, headers) {
    return cordova(this, "delete", {}, arguments);
  };
  HTTP.prototype.deleteSync = function (url, parameters, headers, success, failure) {
    return cordova(this, "delete", {
      "methodName": "delete",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.head = function (url, parameters, headers) {
    return cordova(this, "head", {}, arguments);
  };
  HTTP.prototype.headSync = function (url, parameters, headers, success, failure) {
    return cordova(this, "head", {
      "methodName": "head",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.options = function (url, parameters, headers) {
    return cordova(this, "options", {}, arguments);
  };
  HTTP.prototype.optionsSync = function (url, parameters, headers, success, failure) {
    return cordova(this, "options", {
      "methodName": "options",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.uploadFile = function (url, body, headers, filePath, name) {
    return cordova(this, "uploadFile", {}, arguments);
  };
  HTTP.prototype.uploadFileSync = function (url, body, headers, filePath, name, success, failure) {
    return cordova(this, "uploadFile", {
      "methodName": "uploadFile",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.downloadFile = function (url, body, headers, filePath) {
    return cordova(this, "downloadFile", {}, arguments);
  };
  HTTP.prototype.downloadFileSync = function (url, body, headers, filePath, success, failure) {
    return cordova(this, "downloadFile", {
      "methodName": "downloadFile",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.sendRequest = function (url, options) {
    return cordova(this, "sendRequest", {}, arguments);
  };
  HTTP.prototype.sendRequestSync = function (url, options, success, failure) {
    return cordova(this, "sendRequest", {
      "methodName": "sendRequest",
      "sync": true
    }, arguments);
  };
  HTTP.prototype.abort = function (requestId) {
    return cordova(this, "abort", {}, arguments);
  };
  Object.defineProperty(HTTP.prototype, "ErrorCode", {
    get: function () {
      return cordovaPropertyGet(this, "ErrorCode");
    },
    set: function (value) {
      cordovaPropertySet(this, "ErrorCode", value);
    },
    enumerable: false,
    configurable: true
  });
  HTTP.ɵfac = /* @__PURE__ */(() => {
    let ɵHTTP_BaseFactory;
    return function HTTP_Factory(__ngFactoryType__) {
      return (ɵHTTP_BaseFactory || (ɵHTTP_BaseFactory = i0.ɵɵgetInheritedFactory(HTTP)))(__ngFactoryType__ || HTTP);
    };
  })();
  HTTP.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: HTTP,
    factory: HTTP.ɵfac
  });
  HTTP.pluginName = "HTTP";
  HTTP.plugin = "cordova-plugin-advanced-http";
  HTTP.pluginRef = "cordova.plugin.http";
  HTTP.repo = "https://github.com/silkimen/cordova-plugin-advanced-http";
  HTTP.platforms = ["Android", "iOS"];
  HTTP = __decorate([], HTTP);
  return HTTP;
}(AwesomeCordovaNativePlugin);
export { HTTP };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HTTP, [{
    type: Injectable
  }], null, {
    ErrorCode: [],
    getBasicAuthHeader: [],
    useBasicAuth: [],
    getHeaders: [],
    setHeader: [],
    getDataSerializer: [],
    setDataSerializer: [],
    setCookie: [],
    clearCookies: [],
    removeCookies: [],
    getCookieString: [],
    getRequestTimeout: [],
    setRequestTimeout: [],
    getFollowRedirect: [],
    setFollowRedirect: [],
    setServerTrustMode: [],
    setClientAuthMode: [],
    post: [],
    postSync: [],
    get: [],
    getSync: [],
    put: [],
    putSync: [],
    patch: [],
    patchSync: [],
    delete: [],
    deleteSync: [],
    head: [],
    headSync: [],
    options: [],
    optionsSync: [],
    uploadFile: [],
    uploadFileSync: [],
    downloadFile: [],
    downloadFileSync: [],
    sendRequest: [],
    sendRequestSync: [],
    abort: []
  });
})();
