import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class SoundApiService extends ApiService {
    // GET Requests
    public getSounds = super.getFetcher().path('/sounds/{name}').method('get').create();
    public uploadNameOnlySounds = super.getFetcher().path('/sounds/names-only/upload').method('post').create();
}
