import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { GamesLeaveBusterService } from '../services/games-leave-buster.service';

export const gamesLocalLeaveBusterGuard: CanActivateFn = (route, state) => {
    const leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);

    if (leaveBusterService.isAllowedToLeaveLocalGame()) {
        return true;
    } else {
        return leaveBusterService.startLocalLeaveProcess();
    }
};
