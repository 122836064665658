<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title">{{ title }}</div>
    </div>
    <div class="slide-modal-content">
        <div class="flex mb-2">
            <div class="mx-auto !text-black">
                @if (maxDate) {
                    <ion-datetime
                        displayFormat="DD MMM YYYY"
                        presentation="date"
                        name="date"
                        [(ngModel)]="date"
                        [max]="maxDate"
                        (ngModelChange)="checkDate()"></ion-datetime>
                } @else {
                    <ion-datetime
                        class="date-time-picker"
                        displayFormat="DD MMM YYYY"
                        presentation="date"
                        name="date"
                        [(ngModel)]="date"></ion-datetime>
                }
            </div>
        </div>
        <app-primary-button
            size="normal"
            addedClasses="w-full"
            color="orange"
            [translate]="'CONFIRM'"
            i18n="@@CONFIRM"
            (click)="dismiss()">
            Confirm
        </app-primary-button>
    </div>
</div>
