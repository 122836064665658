import { registerLocaleData } from '@angular/common';
import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHr from '@angular/common/locales/hr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeSr from '@angular/common/locales/sr';
import localeSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';
import localeZh from '@angular/common/locales/zh';
import { Injectable } from '@angular/core';
import { DC_LANGUAGES } from 'src/app/app.globals';

@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    constructor() {}

    public registerLocales(): void {
        DC_LANGUAGES.forEach((language) => {
            registerLocaleData(this.getLocaleByKey(language.key), language.key);
        });
    }

    private getLocaleByKey(key: string): (unknown | undefined)[] {
        switch (key) {
            case 'en':
                return localeEn;
            case 'nl':
                return localeNl;
            case 'de':
                return localeDe;
            case 'bg':
                return localeBg;
            case 'zh-CN':
                return localeZh;
            case 'cs':
                return localeCs;
            case 'da':
                return localeDa;
            case 'el':
                return localeEl;
            case 'es':
                return localeEs;
            case 'fr':
                return localeFr;
            case 'hr':
                return localeHr;
            case 'it':
                return localeIt;
            case 'ja':
                return localeJa;
            case 'hu':
                return localeHu;
            case 'pl':
                return localePl;
            case 'pt':
                return localePt;
            case 'sr':
                return localeSr;
            case 'fi':
                return localeFi;
            case 'sv':
                return localeSv;
            case 'tr':
                return localeTr;
            default:
                return localeEn;
        }
    }
}
