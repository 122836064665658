import { Injectable } from '@angular/core';

export type LogViewerLog = { message: string; type: 'log' | 'warn' | 'error'; timestamp: string; expanded: boolean };

@Injectable()
export class LoggingService {
    private logs: LogViewerLog[] = [];

    constructor() {
        if (false) {
            this.interceptConsoleLog();
            this.interceptConsoleWarn();
            this.interceptConsoleError();
        }
    }

    private log(args: any[], type: 'log' | 'warn' | 'error') {
        const timestamp = new Date().toISOString();
        const formattedMessage = this.formatMessage(args);
        this.logs.unshift({ message: formattedMessage, type, timestamp, expanded: false });
    }

    clear(): void {
        this.logs = [];
    }

    private formatMessage(args: any[]): string {
        if (args.length === 0) {
            return '[]';
        }

        return args
            .map((arg) => {
                if (Array.isArray(arg) && arg.length === 0) {
                    return '[]';
                } else if (typeof arg === 'object') {
                    try {
                        return JSON.stringify(arg);
                    } catch (e) {
                        return String(arg);
                    }
                } else {
                    return String(arg);
                }
            })
            .join(' ');
    }

    private interceptConsoleLog() {
        const originalConsoleLog = console.log;
        console.log = (...args) => {
            this.log(args, 'log');
            try {
                originalConsoleLog.apply(console, args);
            } catch (_) {}
        };
    }

    private interceptConsoleWarn() {
        const originalConsoleWarn = console.warn;
        console.warn = (...args) => {
            this.log(args, 'warn');
            try {
                originalConsoleWarn.apply(console, args);
            } catch (_) {}
        };
    }

    private interceptConsoleError() {
        const originalConsoleError = console.error;
        console.error = (...args) => {
            this.log(args, 'error');
            try {
                originalConsoleError.apply(console, args);
            } catch (_) {}
        };
    }

    public getLogs() {
        return this.logs;
    }
}
