import { Injectable } from '@angular/core';
import { AlertIcon } from '../alert.service';
import { OmniResponse } from './omni-interfaces';
import { CartesianCoordinate, OmniSingleDart, PolarCoordinate } from './omni-ingame.service';

export interface OmniDartThrow extends OmniResponse {
    dartIndex: number;
    fieldHit: number;
    multiplier: number;
    coordinate_rho: number;
    coordinate_theta: number;
    isBounceout: boolean;
    isOuterField: boolean;
    has_exception: boolean;
    no_image_recorded: boolean;

    //Own fields
    amount: number; // = fieldHit
    total: number; // = fieldHit * multiplier
    coordinates: CartesianCoordinate;
    isValidThrow: boolean;
}

export interface OmniMessage extends OmniResponse {
    message: string;
}

export interface OmniPlayerChangeEvent extends OmniResponse {
    playerchange: any;
}

export interface OmniSystemReady extends OmniResponse {
    waitingForDarts: boolean; //Ready to throw darts?
}

export interface OmniLoadingEvent extends OmniResponse {
    alertKey: string;
    show: boolean;
    type?: AlertIcon;
    title?: string;
    text?: string;
    timeout?: number;
    showCloseButton?: boolean;
}

export interface OmniBoardEmptyCheckEvent extends OmniResponse {
    is_empty: boolean;
}

export interface OmniAutoExposureStartEvent extends OmniResponse {
    wb: 'True' | 'False'; // A boolean as a string
}

export interface OmniAutoExposureStartEvent extends OmniResponse {
    wb: 'True' | 'False'; // A boolean as a string
}
export interface OmniInfo extends OmniResponse {
    firmware: string; //Version string i.e. "1.3.6"
    successfulCamerasetup?: boolean;
    freespace_in_MB?: number;
}

@Injectable({
    providedIn: 'root',
})
export class OmniResponseMappingService {
    constructor() {
        localStorage.debug = '*';
    }

    getDartScore(dartThrow: OmniDartThrow) {
        try {
            if (dartThrow.fieldHit == 50) {
                dartThrow.fieldHit = 25;
            }

            const multipliers = ['Single', 'Double', 'Treble'];
            dartThrow.humanReadable = `${multipliers[dartThrow.multiplier - 1]} ${dartThrow.fieldHit}`;
            dartThrow.amount = dartThrow.fieldHit;
            dartThrow.total = dartThrow.multiplier * dartThrow.fieldHit;

            dartThrow.coordinates = this.polarToCartesian({
                r: dartThrow.coordinate_rho,
                theta: dartThrow.coordinate_theta,
            });

            //Check if the throw is valid for (BounceOuts / Errors should not be included on the SVGs)
            dartThrow.isValidThrow = this.isValidThrow(dartThrow);

            //Not valid? Note it as a 'MISS'
            if (!dartThrow.isValidThrow || dartThrow.has_exception) {
                dartThrow.humanReadable = `MISS`;
                dartThrow.amount = null;
                dartThrow.total = 0;
            }
        } catch (e) {
            return;
        }
    }

    polarToCartesian(d: PolarCoordinate): CartesianCoordinate {
        return {
            x: d.r * Math.cos(d.theta),
            y: -d.r * Math.sin(d.theta), // SVG's y-axis is inverted compared to the mathematical y-axis used in polar coordinates
        };
    }

    isValidThrow(dart: OmniDartThrow) {
        if (!dart || dart?.isBounceout) {
            return false;
        } else if (
            dart.coordinates.x > 250 ||
            dart.coordinates.x < -250 ||
            dart.coordinates.y > 250 ||
            dart.coordinates.y < -250 ||
            (!dart.coordinates.x && !dart.amount)
        ) {
            return false;
        }

        return true;
    }
}
