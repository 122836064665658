<modal-content fullscreen="true" class="bg-white-pattern text-black">
    <div class="result-header">
        <ion-icon name="close" class="visibility-hidden" (click)="close()"></ion-icon>
        <div class="title-container" *ngIf="user">
            <span class="title" [translate]="'REMATCH'" i18n="@@REMATCH">Rematch</span>
            <span class="game-title">
                {{ user.first_name }}
                <span style="margin-left: 0.1rem" [translate]="'WANTS_A_REMATCH'" i18n="@@WANTS_A_REMATCH">
                    wants a rematch
                </span>
            </span>
        </div>
        <ion-icon name="close" (click)="close()"></ion-icon>
    </div>

    <div class="result-footer">
        <div style="display: flex; justify-content: center">
            <button class="dimentional-button red" style="width: 50%" (click)="close()">
                <span class="front red" [translate]="'DECLINE'" i18n="@@DECLINE">Decline</span>
            </button>
            <button class="dimentional-button blue" style="width: 50%; margin-left: 0.75rem" (click)="close(true)">
                <span class="front blue" [translate]="'ACCEPT'" i18n="@@ACCEPT">Accept</span>
            </button>
        </div>
    </div>
</modal-content>
