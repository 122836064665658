/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, input, InputSignal, ViewChild } from '@angular/core';
import { CartesianCoordinate, OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';

import { ListSwitchComponent } from '../../../app/list-switch/list-switch.component';
import { OmniDartBoardComponent } from '../omni-dartboard.component';
import { ZoomedPointComponent } from '../zoomed-point.component';

@Component({
    selector: 'app-omni-opponent-view',
    standalone: true,
    templateUrl: './omni-opponent-view.component.html',
    styleUrls: ['./omni-opponent-view.component.scss'],
    imports: [OmniDartBoardComponent, ListSwitchComponent, ZoomedPointComponent, CommonModule],
})
export class OmniOpponentViewComponent {
    @ViewChild('opponentDartboard') public omniDartboard: OmniDartBoardComponent;
    public isLargeDevice: InputSignal<boolean> = input(false);

    public coordinates: CartesianCoordinate[] = [];

    constructor(public omniIngameService: OmniIngameService) {}
}
