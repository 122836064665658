/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { Injectable } from '@angular/core';
import { addDoc, collection, DocumentReference, Firestore, WhereFilterOp } from '@angular/fire/firestore';
import { DocumentData, getDocs, query, QuerySnapshot, where } from 'firebase/firestore';
import * as moment from 'moment';

const token =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJkYXJ0Y291bnRlci1vbmxpbmVAYXBwc3BvdC5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZGFydGNvdW50ZXItb25saW5lQGFwcHNwb3QuZ3NlcnZpY2VhY2NvdW50LmNvbSIsImF1ZCI6Imh0dHBzOlwvXC9pZGVudGl0eXRvb2xraXQuZ29vZ2xlYXBpcy5jb21cL2dvb2dsZS5pZGVudGl0eS5pZGVudGl0eXRvb2xraXQudjEuSWRlbnRpdHlUb29sa2l0IiwiY2xhaW1zIjp7ImlzVWx0aW1hdGUiOnRydWV9LCJ1aWQiOiJzb21lLXVpZCIsImlhdCI6MTU5NTc2Mjc3MywiZXhwIjoxNTk1NzY2MzczfQ.AaeuHPJgoN_JSUPTaVs3VlPEgLSCAqvFRx6Ga0zWDZGVVYuj4uTExuyuxAmLY0HCiU16gM8VwejrFW4MFDEjmui_bXcA9EqmL1_LqKJg6EWESxcMOa_vuFDGgiPwso0VHltdCNKF9CD2kl7s8ZO0eOxrQIeoWQeT5ThMehn-1oogwbIQF7Tf5ICYKilGcE3SRC2jqOXsU-DwX_EWWWxpMKPmA8RcLrdZp-dv6eivXXwnRlrXi_isXapkiV2idEeSQTICzifr0jPicjvQkSL-Slzve0Z8CDQkLGdOKW7aN6Nxv0vyKbxhoN00Zrl2V5jLf7c6AdxsvKgb00BZOI4W5g';
/*
  Generated class for the AuthService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class FireStoreService {
    public fsToken = null;

    constructor(
        public firestore: Firestore // private afAuth: AngularFireAuth,
    ) {}

    // public customAuthenticate(token) {
    //   this.fsToken = token;

    //   // this.afAuth.auth.signInAnonymously();
    //   this.afAuth.signInWithCustomToken(token).then((creds) => {
    //     //The UID Logged in for the first time with FireAuth
    //     if (creds.additionalUserInfo.isNewUser) {
    //       console.log('New user');
    //     }
    //     console.log(creds);
    //     this.updateUserData(creds.user);
    //   }, (err) => {
    //     console.error('Custom Auth Error', err);
    //   });
    // }

    // private updateUserData(user: FireStoreUser) {
    //   // Sets user data to firestore on login
    //   const userRef: AngularFirestoreDocument<FireStoreUser> = this.firestore.doc(`users/${user.uid}`);

    //   const data = {
    //     uid: user.uid,
    //     displayName: user.displayName,
    //   };

    //   return userRef.set(data, { merge: true });

    // }

    getToken() {
        // this.afAuth.auth.signInWithCustomToken();
    }

    async getByCollectionAndID(collectionName, id): Promise<QuerySnapshot<DocumentData>> {
        const collectionRef = collection(this.firestore, collectionName);
        const appQuery = query(collectionRef, where('id', '==', id));
        return getDocs(appQuery);
    }

    addToCollection(collectionName, obj): Promise<DocumentReference> {
        const collectionRef = collection(this.firestore, collectionName);
        return addDoc(collectionRef, obj);
    }

    async getInvitations(collectionName, id) {
        const collectionRef = collection(this.firestore, collectionName);
        const appQuery = query(collectionRef, where('receiverID', '==', id));

        const result: DocumentData[] = [];
        const querySnapshot = getDocs(appQuery);
        (await querySnapshot).forEach((d) => {
            result.push(d);
        });

        return result;
    }

    async getJoinRequests(collectionName, id) {
        const collectionRef = collection(this.firestore, collectionName);
        const appQuery = query(collectionRef, where('firebaseID', '==', id));

        const result: DocumentData[] = [];
        const querySnapshot = getDocs(appQuery);
        (await querySnapshot).forEach((d) => {
            result.push(d);
        });

        return result;
    }

    watchCollection(collection) {
        return collection(this.firestore, collection);
    }

    async watchItems(collectionName, param, value, opstr: WhereFilterOp = '==') {
        const collectionRef = collection(this.firestore, collectionName);
        const appQuery = query(collectionRef, where(param, opstr, value));
        // const items$ = appQuery.valueChanges({ idField: 'customID'});

        const result: DocumentData[] = [];
        const querySnapshot = getDocs(appQuery);
        (await querySnapshot).forEach((d) => {
            result.push(d);
        });

        return result;
    }

    watchItemsWithParamsAndTime(collection, param = null, value = null) {
        return collection(this.firestore, collection, (obj) => {
            let query: any = obj;
            const unix = moment().unix();
            if (param) {
                query = query.where(param, '==', value);
            }
            query = query.where('unix', '>=', unix - 600);
            return query;
        });
    }

    watchItemsWithParamsNoTime(collection, param = null, value = null) {
        return collection(this.firestore, collection, (obj) => {
            let query: any = obj;
            const unix = moment().unix();
            if (param) {
                query = query.where(param, '==', value);
            }
            query = query.where('unix', '==', null);
            return query;
        });
    }

    updateWholeItem(collection, id, obj) {
        return collection(this.firestore, collection).doc(id).update(obj);
    }

    updateAttributesForItem(collection, id, updatedAttrs) {
        return collection(this.firestore, collection).doc(id).set(updatedAttrs, { merge: true });
    }

    removeItem(collection, id) {
        return collection(this.firestore, collection).doc(id).delete();
    }
}
