<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'GOAL'" i18n="@@GOAL">Goal</div>
    </div>
    <div class="slide-modal-content">
        <form #form="ngForm" [formGroup]="amountForm" (ngSubmit)="amountForm.valid && save()">
            <div class="my-4">
                <div class="dialog-input-label" [translate]="'ENTER_GOAL_AMOUNT'" i18n="@@ENTER_GOAL_AMOUNT">
                    Enter goal amount
                </div>
                <input
                    [ngClass]="{
                        '!border-red': form.submitted && amountForm.get('goalAmount').errors
                    }"
                    formControlName="goalAmount"
                    type="number"
                    inputmode="numeric"
                    min="1"
                    max="30"
                    class="dialog-input text-center" />
                @if (form.submitted && amountForm.get('goalAmount').errors) {
                    @if (amountForm.get('goalAmount').errors.min) {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MIN_GOAL_AMOUNT'" i18n="@@ERROR_MIN_GOAL_AMOUNT">
                                The minimum goal amount is 1 sets or legs
                            </span>
                        </app-validation-label>
                    } @else if (amountForm.get('goalAmount').errors.max) {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MAX_GOAL_AMOUNT'" i18n="@@ERROR_MAX_GOAL_AMOUNT">
                                The maximum goal amount is 30 sets or legs
                            </span>
                        </app-validation-label>
                    } @else {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'ERROR_MIN_GOAL_AMOUNT'" i18n="@@ERROR_MIN_GOAL_AMOUNT">
                                The minimum goal amount is 1 sets or legs
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
            <div class="button-container">
                <app-primary-button
                    type="submit"
                    size="normal"
                    addedClasses="w-full"
                    color="orange"
                    [translate]="'CONFIRM'"
                    i18n="@@CONFIRM">
                    Confirm
                </app-primary-button>
            </div>
        </form>
    </div>
</div>
