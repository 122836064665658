<div class="flex h-full flex-col overflow-hidden bg-white-pattern">
    <app-page-header mainNavigation="custom" color="orange">
        <div
            left
            class="text-center font-bold text-black cursor-pointer"
            (click)="close()"
            [translate]="'CLOSE'"
            i18n="@@CLOSE">
            Close
        </div>
        <div title [translate]="'ACTION_REPLAYS'" i18n="@@ACTION_REPLAYS">Action Replays</div>
    </app-page-header>

    <div class="mt-4 flex flex-1 flex-col px-safe pb-safe text-black overflow-hidden">
        <div class="flex-none text-center space-y-1">
            <div class="text-black font-bebas-neue text-xl">{{ savedReplaysText }}</div>
            <div class="text-neutral-800 text-sm font-semibold">{{ recordedText }}</div>
        </div>

        <div class="mt-4 flex-1 overflow-y-auto px-4">
            <div class="grid sm:grid-cols-2 2xl:grid-cols-3 gap-4">
                @for (clip of clips; track $index) {
                    <div class="col-span-1 w-full overflow-hidden rounded-lg relative bg-black flex flex-col">
                        <div class="relative flex-1 aspect-[16/12]">
                            <img
                                class="rounded-lg w-full h-full object-cover object-center"
                                src="{{ clip.thumbnail_url }}" />
                            <div class="absolute bottom-3 right-3">
                                <div class="bg-orange flex space-x-1 items-center rounded px-1 cursor-pointer w-fit">
                                    <app-icon icon="videocam" size="small"></app-icon>
                                    <div class="font-bold text-xxs !text-white">
                                        {{ clip.durationText }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex-none flex px-5 py-3 justify-between items-center w-full bg-black rounded-b-lg space-x-2">
                            <div class="space-y-1 min-w-0 flex-1">
                                <div class="text-white font-bold text-sm truncate">
                                    {{ clip.title }}
                                </div>
                            </div>
                            <div class="flex items-center flex-none">
                                <app-icon
                                    [icon]="clip.favorited_at ? 'bookmark_star' : 'bookmark_star_outline'"
                                    [class]="
                                        clip.favorited_at ? 'text-green' : 'text-neutral-100 dark:text-neutral-300'
                                    "
                                    class="cursor-pointer"
                                    (click)="toggleFavorite(clip)"></app-icon>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

        <app-primary-button
            class="block p-4 flex-none"
            size="medium"
            addedClasses="w-full"
            [translate]="'VIEW_IN_GALLERY'"
            i18n="@@VIEW_IN_GALLERY"
            (click)="gallery()">
            View in gallery
        </app-primary-button>
    </div>
</div>
