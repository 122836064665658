<div class="bg-white divide-y divide-neutral-200 text-black">
    <div class="px-5 py-3 flex justify-between items-center" (click)="onCardClick()">
        <div class="font-bebas-neue text-2xl truncate leading-none mr-4">
            {{ tournament().name }}
        </div>
    </div>

    <div class="px-5 py-2 flex flex-wrap gap-2" (click)="onCardClick()">
        @if (tournament().camera_required) {
            <div class="flex items-center rounded-sm bg-neutral-50 dark:bg-black-full text-black p-1.5 space-x-2 w-fit">
                <app-icon icon="videocam" size="small"></app-icon>
                <div class="ml-1 text-xxxs font-bold uppercase leading-none" [translate]="'CAM_ONLY'" i18n="@@CAM_ONLY">
                    Cam only
                </div>
            </div>
        }

        @if (tournament().omni_scoring_required) {
            <div class="flex items-center rounded-sm bg-neutral-50 dark:bg-black-full text-black p-1.5 space-x-2 w-fit">
                <img class="w-16" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
            </div>
        }
    </div>

    <div class="px-6 py-3" (click)="onCardClick()">
        <app-tournament-details [tournament]="tournament()" [withGameplayName]="true"></app-tournament-details>
    </div>

    <div class="px-5 py-3 flex space-x-2 items-center">
        @switch (status) {
            @case ('waiting_to_join') {
                <app-tournament-button
                    class="w-full"
                    type="join_in"
                    [untilDate]="tournament().joinable_from"
                    (changeButton)="changeButton('joining_period')">
                </app-tournament-button>
                <app-tournament-button class="w-full" [type]="isHost ? 'manage' : 'view'" (click)="goToTournament()">
                </app-tournament-button>
            }
            @case ('joinable') {
                <app-tournament-button
                    class="w-full"
                    type="join"
                    [disabled]="tournament().amount_of_participants === tournament().current_participants"
                    (click)="join()">
                </app-tournament-button>
                <app-tournament-button class="w-full" [type]="isHost ? 'manage' : 'view'" (click)="goToTournament()">
                </app-tournament-button>
            }
            @case ('waiting_for_approval') {
                <app-tournament-button class="w-full" type="waiting_for_approval"> </app-tournament-button>
                <app-tournament-button class="w-full" [type]="isHost ? 'manage' : 'view'" (click)="goToTournament()">
                </app-tournament-button>
            }
            @case ('waiting_for_check_in') {
                <app-tournament-button
                    class="w-full"
                    type="waiting_for_check_in"
                    [untilDate]="tournament().check_in_from"
                    (changeButton)="changeButton('check_in_period')">
                </app-tournament-button>
                <app-tournament-button class="w-full" [type]="isHost ? 'manage' : 'view'" (click)="goToTournament()">
                </app-tournament-button>
            }
            @case ('check_in_period') {
                <app-tournament-button
                    class="w-full"
                    type="check_in"
                    (click)="checkIn()"
                    [disabled]="isCheckingIn"></app-tournament-button>
                <app-tournament-button class="w-full" [type]="isHost ? 'manage' : 'view'" (click)="goToTournament()">
                </app-tournament-button>
            }
            @case ('checked_in') {
                <app-tournament-button class="w-full" type="checked_in"> </app-tournament-button>
                <app-tournament-button class="w-full" [type]="isHost ? 'manage' : 'view'" (click)="goToTournament()">
                </app-tournament-button>
            }
            @case ('starting') {
                <app-tournament-button
                    class="w-full"
                    type="go_to_tournament"
                    (click)="goToTournament('bracket')"></app-tournament-button>
            }
            @case ('started') {
                <app-tournament-button
                    class="w-full"
                    type="go_to_tournament"
                    (click)="goToTournament('bracket')"></app-tournament-button>
            }
            @case ('playing') {
                <app-tournament-button
                    class="w-full"
                    type="go_to_tournament"
                    (click)="goToTournament('bracket')"></app-tournament-button>
            }
            @case ('lost') {
                <app-tournament-button class="w-full" [type]="isHost ? 'manage' : 'view'" (click)="goToTournament()">
                </app-tournament-button>
            }
            @case ('finished') {
                <app-tournament-button class="w-full" type="view" (click)="goToTournament()"> </app-tournament-button>
            }
        }
    </div>
</div>
