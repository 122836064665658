import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertIcon } from '@dc-core/dc-services/alert.service';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-prompt-dialog',
    templateUrl: 'prompt.dialog.html',
})
export class PromptDialogComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() text: string;
    @Input() icon: AlertIcon = null;
    @Input() cancelText: string;
    @Input() confirmText: string;
    @Input() confirmColor: 'black' | 'red' | 'green' = 'black';
    @Input() showCloseButton = false;
    @Input() timer: number | 'default' = null;
    @Input() timerProgressBar: boolean;

    private backButtonSubscription: Subscription;

    constructor(
        public route: ActivatedRoute,
        private modalCtrl: ModalController,
        private platform: Platform,
        public auth: AuthService
    ) {}

    ngOnInit(): void {
        if (this.timer) {
            if (this.timer === 'default') {
                this.timer = 1125;
            }

            setTimeout(() => {
                this.dismiss();
            }, this.timer);
        } else {
            this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
                this.dismiss();
            });
        }
    }

    ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }
    }

    public dismiss(returnValue: any = null): void {
        this.modalCtrl.dismiss(returnValue);
    }
}
