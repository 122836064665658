import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appSetViewportHeight]',
})
export class SetViewportHeightDirective implements AfterViewInit {
    @Input() headerHeightValue = 0;

    ngAfterViewInit() {
        this.setViewportHeight();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setViewportHeight();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.headerHeightValue && typeof changes.headerHeightValue.currentValue !== 'undefined') {
            this.setViewportHeight();
        }
    }

    private setViewportHeight(): void {
        const adjustedHeight = window.innerHeight - this.headerHeightValue;
        this.renderer.setStyle(this.el.nativeElement, 'height', `${adjustedHeight}px`);
    }

    constructor(private el: ElementRef, private renderer: Renderer2) {}
}
