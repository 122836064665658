import { Injectable } from '@angular/core';
import {
    CollectionReference,
    doc,
    DocumentSnapshot,
    Firestore,
    getDoc,
} from '@angular/fire/firestore';
import _ from 'lodash';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { CountMetric, FIRESTORE_COLLECTION, FSGamesCounts, FSUserCount, GamesCounts, UserCounts } from '../globals/firestore.tables';

@Injectable()
export class CountsCollectionService extends FireStoreCollectionsService {
    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.COUNTS;
    private firestore_collection: CollectionReference<any>;

    constructor(private firestore: Firestore) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<any>(this.collection_name);
    }

    async getUserCounts(): Promise<UserCounts> {
        const docRef = doc(this.firestore_collection, CountMetric.STATUSCOUNTS);
        const countDocument: DocumentSnapshot<FSUserCount> = await getDoc(docRef);

        let result: UserCounts = {
            online: 0,
            playing: 0,
        };
        if (countDocument.exists()) {
            const data = countDocument.data();

            //TODO improve -> online3 refers to online users with version 3
            result.online = data.online3 || 0;
            result.playing = data.playing3 || 0;
        }

        return result;
    }
    
    async getGamesCounts(): Promise<GamesCounts> {
        const docRef = doc(this.firestore_collection, CountMetric.GAMESCOUNTS);
        const countDocument: DocumentSnapshot<FSGamesCounts> = await getDoc(docRef);

        let result: GamesCounts = {
            publicGames: 0,
        };
        if (countDocument.exists()) {
            const data = countDocument.data();
            //TODO improve -> publicGames refers to publicGames with version 3
            result.publicGames = data.publicGames3 || 0;
        }

        return result;
    }
}
