import { User } from '../../dc-backend/dc-classes';
import { UserMedia } from '../../dc-services/dc-janus/DartCounterUserMedia';

export type OnlineUser = User & {
    countryFlag?: string;
    userMedia?: UserMedia;
};

export enum ONLINE_LOCAL_ACTIONS {
    WAITING_FOR_OPPONENT = 'waiting_',
    OPPONENT_NOT_RESPONDING = 'opponent_not_responding',
    OPPONENT_LEFT = 'opponent_left',
    OWN_TIMER_RAN_OUT = 'own_timer_ran_out',
}

export enum ONLINE_GAME_STATE_ACTIONS {
    OPPONENT_QUIT = 'opponent_quit',
    OPPONENT_SAVED_TO_CONTINUE = 'opponent_saved_to_continue',
    OPPONENT_SAVED_TO_STATS = 'opponent_saved_to_stats',
}
