export const X01_THROWOUTS: ThrowoutHint[] = [
    { score: 1, default: 'S1' },
    { score: 2, default: 'D1' },
    { score: 3, default: '1, D1' },
    { score: 4, default: 'D2' },
    { score: 5, default: '1, D2' },
    { score: 6, default: 'D3' },
    { score: 7, default: '3, D2' },
    { score: 8, default: 'D4' },
    { score: 9, default: '1, D4' },
    { score: 10, default: 'D5' },
    { score: 11, default: '3, D4' },
    { score: 12, default: 'D6' },
    { score: 13, default: '5, D4' },
    { score: 14, default: 'D7' },
    { score: 15, default: '7, D4' },
    { score: 16, default: 'D8' },
    { score: 17, default: '1, D8' },
    { score: 18, default: 'D9' },
    { score: 19, default: '3, D8' },
    { score: 20, default: 'D10' },
    { score: 21, default: '5, D8' },
    { score: 22, default: 'D11' },
    { score: 23, default: '7, D8' },
    { score: 24, default: 'D12' },
    { score: 25, default: '9, D8' },
    { score: 26, default: 'D13' },
    { score: 27, default: '11, D8' },
    { score: 28, default: 'D14' },
    { score: 29, default: '13, D8' },
    { score: 30, default: 'D15' },
    { score: 31, default: '15, D8' },
    { score: 32, default: 'D16' },
    { score: 33, default: '1, D16' },
    { score: 34, default: 'D17' },
    { score: 35, default: '3, D16' },
    { score: 36, default: 'D18' },
    { score: 37, d18: '1, D18', default: '5, D16' },
    { score: 38, default: 'D19' },
    { score: 39, d18: '3, D18', default: '7, D16' },
    { score: 40, default: 'D20' },
    { score: 41, d20: '1, D20', d18: '5, D18', default: '9, D16' },
    { score: 42, d20: '2, D20', d18: '6, D18', default: '10, D16' },
    { score: 43, d20: '3, D20', d18: '7, D18', default: '11, D16' },
    { score: 44, d20: '4, D20', d18: '8, D18', default: '12, D16' },
    { score: 45, d20: '5, D20', d18: '9, D18', default: '13, D16' },
    { score: 46, d20: '6, D20', d18: '10, D18', default: '14, D16' },
    { score: 47, d20: '7, D20', d18: '11, D18', default: '15, D16' },
    { score: 48, d20: '8, D20', d18: '12, D18', default: '16, D16' },
    { score: 49, d20: '9, D20', d18: '13, D18', default: '17, D16' },
    { score: 50, d20: '10, D20', d18: '14, D18', default: '18, D16' },
    { score: 51, d20: '11, D20', d18: '15, D18', default: '19, D16' },
    { score: 52, d20: '12, D20', d18: '16, D18', default: '20, D16' },
    { score: 53, d18: '17, D18', default: '13, D20' },
    { score: 54, d18: '18, D18', default: '14, D20' },
    { score: 55, d18: '19, D18', default: '15, D20' },
    { score: 56, d18: '20, D18', default: '16, D20' },
    { score: 57, default: '17, D20' },
    { score: 58, default: '18, D20' },
    { score: 59, default: '19, D20' },
    { score: 60, default: '20, D20' },
    { score: 61, default: '25, D18' },
    { score: 62, d20: 'T14, D10', default: 'T10, D16' },
    { score: 63, d18: 't9, D18', default: 'T13, D12' },
    { score: 64, default: 'T16, D8' },
    { score: 65, d16: 'T11, D16', default: '25, D20' },
    { score: 66, default: 'T10, D18' },
    { score: 67, d20: 't9, D20', default: 'T17, D8' },
    { score: 68, d16: 'T12, D16', default: 'T20, D4' },
    { score: 69, default: 'T15, D12' },
    { score: 70, d20: 'T10, D20', default: 'T18, D8' },
    { score: 71, d20: 'T17, D10', default: 'T13, D16' },
    { score: 72, default: 'T12, D18' },
    { score: 73, default: 'T19, D8' },
    { score: 74, default: 'T14, D16' },
    { score: 75, default: 'T17, D12' },
    { score: 76, default: 'T20, D8' },
    { score: 77, default: 'T19, D10' },
    { score: 78, default: 'T18, D12' },
    { score: 79, d20: 'T13, D20', default: 'T19, D11' },
    { score: 80, d16: 'T16, D16', default: 'T20, D10' },
    { score: 81, d18: 'T15, D18', default: 'T19, D12' },
    { score: 82, d20: 'T14, D20', default: 'BULL, D16' },
    { score: 83, default: 'T17, D16' },
    { score: 84, d18: 'T16, D18', default: 'T20, D12' },
    { score: 85, default: 'T15, D20' },
    { score: 86, default: 'T18, D16' },
    { score: 87, default: 'T17, D18' },
    { score: 88, d20: 'T16, D20', default: 'T20, D14' },
    { score: 89, default: 'T19, D16' },
    { score: 90, d18: 'T18, D18', default: 'T20, D15' },
    { score: 91, default: 'T17, D20' },
    { score: 92, default: 'T20, D16' },
    { score: 93, default: 'T19, D18' },
    { score: 94, default: 'T18, D20' },
    { score: 95, default: 'T19, D19' },
    { score: 96, default: 'T20, D18' },
    { score: 97, default: 'T19, D20' },
    { score: 98, default: 'T20, D19' },
    { score: 99, d20: 'T19, 2, D20', d18: 'T19, 6, D18', default: 'T19, 10, D16' },
    { score: 100, default: 'T20, D20' },
    { score: 101, d20: 'T20, 1, D20', d18: 'T18, 5, D18', default: 'T20, 9, D16' },
    { score: 102, d20: 'T20, 2, D20', d18: 'T20, 6, D18', default: 'T20, 10, D16' },
    { score: 103, d20: 'T20, 3, D20', default: 'T20, 11, D16' },
    { score: 104, d20: 'T20, 4, D20', d18: '20, T16, D18', default: 'T20, 12, D16' },
    { score: 105, d20: 'T20, 5, D20', d18: 'T20, 9, D18', default: 'T20, 13, D16' },
    { score: 106, d20: 'T20, 6, D20', d18: 'T20, 10, D18', default: 'T20, 14, D16' },
    { score: 107, d20: 'T20, 7, D20', d18: '17, T18, D18', default: 'T19, 18, D16' },
    { score: 108, d20: 'T20, 8, D20', d18: 'T18, 18, D18', default: 'T19, 19, D16' },
    { score: 109, d20: 'T20, 9, D20', d18: '19, T18, D18', default: 'T19, 20, D16' },
    { score: 110, d20: 'T20, 10, D20', d18: 'T18, 20, D18', default: 'T20, 18, D16' },
    { score: 111, d20: 'T19, 14, D20', d18: 'T19, 18, D18', default: 'T20, 19, D16' },
    { score: 112, d20: 'T20, 12, D20', d18: 'T19, 19, D18', default: 'T20, 20, D16' },
    { score: 113, d20: 'T20, 13, D20', default: 'T19, 20, D18' },
    { score: 114, d18: 'T20, 18, D18', default: 'T20, 14, D20' },
    { score: 115, d18: 'T20, 19, D18', default: 'T19, 18, D20' },
    { score: 116, d18: 'T20, 20, D18', default: 'T20, 16, D20' },
    { score: 117, default: 'T20, 17, D20' },
    { score: 118, d16: 'T18, T16, D8', default: 'T20, 18, D20' },
    { score: 119, default: 'T19, T12, D13' },
    { score: 120, default: 'T20, 20, D20' },
    { score: 121, default: 'T20, T11, D14' },
    { score: 122, default: 'T18, T18, D7' },
    { score: 123, default: 'T19, T16, D9' },
    { score: 124, default: 'T20, T14, D11' },
    { score: 125, d16: 'T18, T13, D16', default: '25, T20, D20' },
    { score: 126, default: 'T19, T19, D6' },
    { score: 127, default: 'T20, T17, D8' },
    { score: 128, d20: 'T18, T18, D10', default: 'T18, T14, D16' },
    { score: 129, default: 'T19, T20, D6' },
    { score: 130, default: 'T20, T20, D5' },
    { score: 131, default: 'T20, T13, D16' },
    { score: 132, d16: 'BULL, BULL, D16', default: 'BULL, T14, D20' },
    { score: 133, d20: 'T20, T13, D20', default: 'T20, T19, D8' },
    { score: 134, d20: 'T18, T20, D10', default: 'T20, T14, D16' },
    { score: 135, default: 'BULL, T15, D20' },
    { score: 136, default: 'T20, T20, D8' },
    { score: 137, default: 'T20, T19, D10' },
    { score: 138, default: 'T20, T18, D12' },
    { score: 139, d16: 'T19, BULL, D16', default: 'T19, T14, D20' },
    { score: 140, d16: 'T20, T16, D16', default: 'T20, T20, D10' },
    { score: 141, d18: 'T20, T15, D18', default: 'T20, T19, D12' },
    { score: 142, d16: 'T20, BULL, D16', default: 'T20, T14, D20' },
    { score: 143, default: 'T20, T17, D16' },
    { score: 144, d18: 'T18, T18, D18', default: 'T20, T20, D12' },
    { score: 145, default: 'T20, T15, D20' },
    { score: 146, default: 'T20, T18, D16' },
    { score: 147, default: 'T20, T17, D18' },
    { score: 148, default: 'T20, T16, D20' },
    { score: 149, default: 'T20, T19, D16' },
    { score: 150, d20: 'T20, BULL, D20', default: 'T20, T18, D18' },
    { score: 151, default: 'T20, T17, D20' },
    { score: 152, default: 'T20, T20, D16' },
    { score: 153, default: 'T20, T19, D18' },
    { score: 154, default: 'T20, T18, D20' },
    { score: 155, default: 'T20, T19, D19' },
    { score: 156, default: 'T20, T20, D18' },
    { score: 157, default: 'T20, T19, D20' },
    { score: 158, default: 'T20, T20, D19' },
    { score: 160, default: 'T20, T20, D20' },
    { score: 161, default: 'T20, T17, BULL' },
    { score: 164, default: 'T20, T18, BULL' },
    { score: 167, default: 'T20, T19, BULL' },
    { score: 170, default: 'T20, T20, BULL' },

    //Master out options
    { score: 159, default: 'T20, T20, T13' },
    { score: 162, default: 'T20, T20, T14' },
    { score: 165, default: 'T20, T20, T15' },
    { score: 168, default: 'T20, T20, T16' },
    { score: 171, default: 'T20, T20, T17' },
    { score: 174, default: 'T20, T20, T18' },
    { score: 177, default: 'T20, T20, T19' },
    { score: 180, default: 'T20, T20, T20' },
];

export const X01_THROWOUTWITHTWO: ThrowoutHint[] = [
    { score: 61, default: 'T11, D14' },
    { score: 62, default: 'T12, D13' },
    { score: 63, default: 'T13, D12' },
    { score: 64, default: 'T14, D11' },
    { score: 65, default: 'T15, D10' },
    { score: 66, default: 'T16, D9' },
    { score: 67, default: 'T17, D8' },
    { score: 68, default: 'T18, D7' },
    { score: 69, default: 'T19, D6' },
    { score: 70, default: 'T20, D5' },
    { score: 100, default: 'T20, D20' },
    { score: 101, default: 'T17, BULL' },
    { score: 104, default: 'T18, BULL' },
    { score: 107, default: 'T19, BULL' },
    { score: 110, default: 'T20, BULL' },
];

export const X01_LEAVEWITHONE: ThrowoutHint[] = [
    { score: 3, default: 'S1' },
    { score: 5, default: 'S1' },
    { score: 7, default: 'S3' },
    { score: 9, default: 'S1' },
    { score: 11, default: 'S3' },
    { score: 13, default: 'S5' },
    { score: 15, default: 'S7' },
    { score: 17, default: 'S1' },
    { score: 19, default: 'S3' },
    { score: 21, default: 'S5' },
    { score: 23, default: 'S7' },
    { score: 25, default: 'S9' },
    { score: 27, default: 'S11, D8' },
    { score: 29, default: 'S13, D8' },
    { score: 31, default: 'S15, D8' },
    { score: 33, default: 'S1, D16' },
    { score: 35, default: 'S3' },
    { score: 37, default: 'S5' },
    { score: 39, default: 'S7' },
    { score: 41, default: 'S9' },
    { score: 42, default: 'S10' },
    { score: 43, default: 'S11' },
    { score: 44, default: '12' },
    { score: 45, default: 'S13' },
    { score: 46, default: '14' },
    { score: 47, default: 'S15' },
    { score: 48, default: '16' },
    { score: 49, default: 'S17' },
    { score: 50, default: 'BULL' },
    { score: 51, default: 'S19' },
    { score: 52, default: 'S20' },
    { score: 53, default: 'S13' },
    { score: 54, default: 'S14' },
    { score: 55, default: 'S15' },
    { score: 56, default: 'S16' },
    { score: 57, default: 'S17' },
    { score: 58, default: 'S18' },
    { score: 59, default: 'S19' },
    { score: 60, default: 'S20' },
    { score: 61, default: '25' },
    { score: 62, default: 'T10' },
    { score: 63, default: 'T13' },
    { score: 64, default: 'T16' },
    { score: 65, default: '25' },
    { score: 66, default: 'T10' },
    { score: 67, default: 'T17' },
    { score: 68, default: 'T20' },
    { score: 69, default: 'T15' },
    { score: 70, default: 'T18' },
    { score: 71, default: 'T13' },
    { score: 72, default: 'T12' },
    { score: 73, default: 'T19' },
    { score: 74, default: 'T14' },
    { score: 75, default: 'T17' },
    { score: 76, default: 'T20' },
    { score: 77, default: 'T19' },
    { score: 78, default: 'T18' },
    { score: 79, default: 'T19' },
    { score: 80, default: 'T20' },
    { score: 81, default: 'T19' },
    { score: 82, default: 'BULL' },
    { score: 83, default: 'T17' },
    { score: 84, default: 'T20' },
    { score: 85, default: 'T15' },
    { score: 86, default: 'T18' },
    { score: 87, default: 'T17' },
    { score: 88, default: 'T20' },
    { score: 89, default: 'T19' },
    { score: 90, default: 'T20' },
    { score: 91, default: 'T17' },
    { score: 92, default: 'T20' },
    { score: 93, default: 'T19' },
    { score: 94, default: 'T18' },
    { score: 95, default: 'T19' },
    { score: 96, default: 'T20' },
    { score: 97, default: 'T19' },
    { score: 98, default: 'T20' },
    { score: 99, default: 'T19' },
    { score: 100, default: 'T20' },
];

export const X01_IMPOSSIBLETHROWS: number[] = [163, 166, 169, 172, 173, 175, 176, 178, 179];
export const X01_IMPOSSIBLEOUTS: number[] = [159, 162, 163, 165, 166, 168, 169];
export const X01_IMPOSSIBLEOUTSMASTER: number[] = [163, 166, 169];
export const X01_THREEDARTFINISH: number[] = [99, 102, 103, 105, 106, 108, 109];
export const X01_TWODARTFINISH: number[] = [
    3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 42, 43, 44, 45, 46, 47, 48, 49,
];
export const X01_IMPOSSIBLE_WITH_TWO_DARTS: number[] = [99, 102, 103, 105, 106, 108, 109];

export const X01_SCORINGS = [
    { min: 40, max: 59, amount: 0 },
    { min: 60, max: 79, amount: 0 },
    { min: 80, max: 99, amount: 0 },
    { min: 100, max: 119, amount: 0 },
    { min: 120, max: 139, amount: 0 },
    { min: 140, max: 159, amount: 0 },
    { min: 160, max: 179, amount: 0 },
    { min: 180, max: 180, amount: 0 },
];

export interface ThrowoutHint {
    score: number;
    default: string;
    singleDartHint?: boolean;
    d20?: string;
    d18?: string;
    d16?: string;
    d14?: string;
}
