import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type AlertPayload = {
    id?: string;
    title: string;
    text?: string;
    icon?: AlertIcon;
    cancelText?: string;
    confirmText?: string;
    confirmColor?: 'black' | 'red' | 'green' | 'orange';
    showCloseButton?: boolean;
    timer?: number | 'default';
    timerProgressBar?: boolean;
    showBackdrop?: boolean;
    backdropDismiss?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    onClose?: () => void;

    // Web
    confirmButtonText?: any;
};

export type AlertIcon = 'success' | 'error' | 'loading' | 'warning' | 'info';

export type AlertAction = {
    action: 'close';
    id: string;
};

export type AlertOptions = {
    id?: string;
    title?: string;
    text?: string;
    icon?: AlertIcon;
    timer?: number | 'default';
    timerProgressBar?: boolean;
    allowOutsideClick?: boolean;
    showCloseButton?: boolean;
    confirmButtonText?: any;
    cancelButtonText?: any;
    showBackdrop?: boolean;
    didOpen?: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
};

@Injectable()
export class DartCounterAlertService {
    showNetworkAlert = true;
    $alerts: Subject<AlertPayload> = new Subject<AlertPayload>();
    $alertActions: Subject<AlertAction> = new Subject<AlertAction>();

    errorFromApi(err, timer: number | 'default' = undefined, showCloseButton: boolean = undefined): void {
        let error = err;
        if (typeof err.getActualType === 'function' && err.getActualType() !== undefined) {
            error = err.getActualType().data.message;
        }
        this.createAlert({
            title: error,
            icon: 'error',
            timer,
            showCloseButton,
        });
    }

    closeAlertById(id: string): void {
        this.$alertActions.next({
            action: 'close',
            id,
        });
    }

    createAlert(options: AlertOptions): void {
        this.$alerts.next({
            id: options.id,
            title: options.title,
            text: options.text,
            icon: options.icon === undefined ? 'success' : options.icon,
            // cancelText: options.showCloseButton ? $localize`:@@CLOSE:Close` : null,
            showCloseButton: options.showCloseButton,
            timer: options.timer === undefined ? 'default' : options.timer,
            timerProgressBar: options.timerProgressBar,
            showBackdrop: options.showBackdrop ?? true,
            backdropDismiss: options.allowOutsideClick ?? (options.timer === null ? false : true),
            confirmButtonText: options.confirmButtonText,
            cancelText: options.cancelButtonText,
            confirmText: options.confirmButtonText,
            onConfirm: options.onConfirm,
            onCancel: options.onCancel,
            onClose: options.onClose,
        });
    }

    showPrompt(title: string, icon: AlertIcon, timer: number | 'default' = null): void {
        this.$alerts.next({
            title,
            icon,
            confirmText: timer ? null : $localize`:@@OK:OK`,
            timer,
            backdropDismiss: false,
        });
    }

    clearAlerts(): void {
        this.$alerts.next(null);
    }
}
