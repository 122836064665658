/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, InputSignal, OnInit, untracked } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { TournamentApiService } from '@dc-api/tournament.api.service';
import { Tournament, TournamentParticipant } from '@dc-core/dc-backend/dc-classes';
import { TournamentListType, TournamentStatus } from '@dc-core/dc-backend/dc-enums';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { TournamentExtendedStatus, TournamentHelper } from '@dc-core/dc-helpers/tournament.helper';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { TournamentView } from 'src/app/modules/tournaments/pages/tournaments/view/tournament-view.component';
import { TournamentService } from 'src/app/modules/tournaments/services/tournament.service';
import { environment } from 'src/environments/environment';

import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { JoinTournamentDialogComponent } from '../../dialogs/join-tournament-dialog/join-tournament.dialog';
import {
    TournamentNotificationDialogComponent,
    TournamentNotificationDialogPayload,
} from '../../dialogs/tournament-notification-dialog/tournament-notification.dialog';
import { TournamentButtonComponent } from '../tournament-button/tournament-button.component';
import { TournamentDetailsComponent } from '../tournament-details/tournament-details.component';

@Component({
    selector: 'app-tournament-card',
    templateUrl: 'tournament-card.component.html',
    standalone: true,
    imports: [CommonModule, IconComponent, TournamentDetailsComponent, TournamentButtonComponent, TranslateModule],
})
export class TournamentCardComponent implements OnInit {
    public tournament: InputSignal<Tournament> = input.required();
    public listType: InputSignal<TournamentListType> = input.required();
    public redirectOnClick: InputSignal<boolean> = input(true);

    public translateService: TranslateService = inject(TranslateService);
    public onlineFunctions: OnlineFunctions = inject(OnlineFunctions);

    private authService: AuthService = inject(AuthService);
    private navController: NavController = inject(NavController);
    private modalController: ModalController = inject(ModalController);
    private tournamentApiService: TournamentApiService = inject(TournamentApiService);
    private tournamentService: TournamentService = inject(TournamentService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);
    private upgradeService: UpgradeService = inject(UpgradeService);
    private inAppBrowser: InAppBrowser = inject(InAppBrowser);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);

    public gameplayName: string;
    public isHost: boolean;
    public status: TournamentExtendedStatus;

    public isCheckingIn = false;

    constructor() {
        effect(() => {
            const untrackedTournament = untracked(this.tournament);
            const updatedTournament = this.tournamentService.updatedTournament();
            if (untrackedTournament && updatedTournament && updatedTournament.id === untrackedTournament.id) {
                this.tournament().status = updatedTournament.status;
                this.tournament().amount_of_participants = updatedTournament.amount_of_participants;
                this.tournament().current_participants = updatedTournament.current_participants;
                this.tournament().participation = updatedTournament.participation;
                this.determineStatus();
            }
        });
    }

    public ngOnInit(): void {
        this.gameplayName = this.onlineFunctions.onlineGameplayName(
            this.tournament().game_details[0] as OnlineGameplay
        );
        this.isHost = this.tournament().user_id === this.authService.user.id;

        this.determineStatus();
    }

    public onCardClick(): void {
        if (!this.redirectOnClick()) return;
        this.goToTournament();
    }

    public goToTournament(view: TournamentView = 'info'): void {
        this.navController.navigateForward(`tournaments/${this.tournament().slug}`, {
            queryParams: {
                view,
            },
        });
    }

    public async join(): Promise<void> {
        if (!this.authService.user.is_ultimate) {
            const dialogComponent = await this.upgradeService.GetUpgradeDialog(GA_EVENTCATEGORIES.TOURNAMENTS);
            this.modalController
                .create({
                    component: dialogComponent,
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                });
            return;
        }

        if (this.tournament().amount_of_participants === this.tournament().current_participants) {
            return;
        }

        if (this.tournament().omni_scoring_required && !this.smartDeviceApiService.omniScorings.length) {
            this.alertService.createAlert({
                title: $localize`:@@NEED_TO_HAVE_AN_OMNI_SCORING:You need to have an OMNI Scoring`,
                icon: 'error',
                timer: null,
                showCloseButton: true,
                confirmButtonText: $localize`:@@VISIT_TARGET_DARTS_STORE:Visit Target Darts store`,
                onConfirm: () => {
                    this.inAppBrowser.create('https://www.target-darts.co.uk/omni', '_system', {
                        location: 'yes',
                    } as InAppBrowserOptions);
                },
            });
            return;
        }

        this.tournamentApiService
            .checkTournamentRestriction({ restriction: 'join', tournament_id: this.tournament().id })
            .then((res) => {
                if (!res.data.restricted) {
                    this.modalController
                        .create({
                            component: JoinTournamentDialogComponent,
                            cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                            componentProps: {
                                tournament: this.tournament(),
                            },
                            backdropDismiss: true,
                            showBackdrop: true,
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                if (dialogRes.data) {
                                    const participation = dialogRes.data.participant as TournamentParticipant;

                                    this.modalController
                                        .create({
                                            component: TournamentNotificationDialogComponent,
                                            componentProps: {
                                                tournament: this.tournament(),
                                                type:
                                                    this.tournament().needs_approval && !participation.approved_at
                                                        ? 'waiting_for_approval'
                                                        : 'joined',
                                            } as TournamentNotificationDialogPayload,
                                            cssClass: 'auto-height',
                                            showBackdrop: true,
                                            backdropDismiss: true,
                                        })
                                        .then((elem) => {
                                            elem.present();
                                        });

                                    this.tournament().participation = participation;
                                    this.tournament().current_participants =
                                        dialogRes.data.tournament.current_participants;
                                    this.determineStatus();

                                    this.tournamentService.setNewParticipatingTournament(this.tournament());
                                }
                            });
                        });
                } else {
                    this.alertService.createAlert({
                        title: res.data.message,
                        icon: 'error',
                    });
                }
            });
    }

    public leave(): void {
        this.tournamentApiService.leaveTournament({ tournamentId: this.tournament().id }).then((res) => {
            this.tournament().participation = null;
            this.tournament().current_participants = res.data.current_participants;
            this.determineStatus();

            this.tournamentService.clearListeners(this.tournament().id);
        });
    }

    public checkIn(): void {
        if (this.isCheckingIn || this.tournament().participation.checked_in_at) {
            return;
        }

        this.isCheckingIn = true;

        this.tournamentApiService
            .checkInForTournament({ tournamentId: this.tournament().id })
            .then((res) => {
                this.ga.trackEvent(GA_EVENTCATEGORIES.TOURNAMENTS, GA_EVENTACTIONS.CHECKIN);

                this.modalController
                    .create({
                        component: TournamentNotificationDialogComponent,
                        componentProps: {
                            tournament: this.tournament(),
                            type: 'checked_in',
                        } as TournamentNotificationDialogPayload,
                        cssClass: 'auto-height',
                        showBackdrop: true,
                        backdropDismiss: true,
                    })
                    .then((elem) => {
                        elem.present();
                    });

                this.tournament().participation = res.data.participant;
                this.tournament().current_participants = res.data.tournament.current_participants;
                this.determineStatus();

                this.tournamentService.setNewParticipatingTournament(this.tournament());
            })
            .finally(() => (this.isCheckingIn = false));
    }

    public changeButton(status: TournamentStatus): void {
        this.tournament().status = status;
        this.determineStatus();
    }

    private determineStatus(): void {
        this.status = TournamentHelper.getPlayStatus(this.tournament());
    }
}
