@if (game) {
    <div class="global-lobby-shadow rounded-md col-span-1 mb-3 flex flex-col" [class.cursor-pointer]="showArrow">
        <div class="bg-game-card-top px-4 py-2.5 rounded-t-md">
            <div class="grid grid-cols-11 items-center">
                <div class="col-span-6 flex items-center text-lg font-bold text-black">
                    <div>
                        {{ game.name }}
                        <p
                            class="text-sm text-neutral-800"
                            [translate]="'ONLINE_MATCH'"
                            i18n="@@ONLINE_MATCH"
                            *ngIf="game.finished_at && game.is_online">
                            Online game
                        </p>
                        <p
                            class="text-sm text-neutral-800"
                            [translate]="'UNFINISHED_ONLINE_MATCH'"
                            i18n="@@UNFINISHED_ONLINE_MATCH"
                            *ngIf="!game.finished_at && game.is_online">
                            Unfinished online game
                        </p>
                    </div>
                </div>
                <div class="col-span-3 text-center text-sm text-neutral-800">
                    {{ game.started_at | date: 'dd MMM yyyy' : '' : translateService.currentLang }} <br />{{
                        game.started_at | date: 'H:mm'
                    }}
                </div>
                @if (showArrow) {
                    <div class="col-span-2 flex items-center justify-end text-black">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                }
            </div>
        </div>
        <div class="space-y-3 rounded-b-md bg-game-card-bottom px-4 py-2">
            @if (!game.has_teams) {
                <div class="grid grid-cols-11 items-center text-black" *ngFor="let user of game.users; let i = index">
                    <div class="col-span-6 flex items-center justify-start space-x-2 overflow-hidden">
                        <div class="relative">
                            <app-profile-photo
                                class="h-fit"
                                [ultimate]="user.user?.is_ultimate"
                                [badge]="user.user?.profile?.badge"
                                [url]="user.user?.profile?.profile_photo_url"
                                [size]="32">
                            </app-profile-photo>
                        </div>
                        <div class="space-y-0.5 overflow-hidden">
                            <div class="flex items-center space-x-1">
                                <div class="flex-1 ucfirst truncate text-xs font-bold leading-none">
                                    {{ getMatchUserName(user) }}
                                </div>
                                @if (user.result === 'won') {
                                    <app-icon icon="emoji_events" class="text-orange"></app-icon>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 flex items-center justify-center space-x-2">
                        {{ game.has_sets ? user.total_score_sets : user.total_score_legs }}
                    </div>
                    <div class="col-span-2 flex items-center justify-end space-x-2 text-right text-xs">
                        {{ user.three_dart_average || 0 | number: '1.2-2' }}
                    </div>
                </div>
            } @else {
                <div class="grid grid-cols-11 items-center text-black" *ngFor="let team of game.teams; let i = index">
                    <div class="col-span-6 flex items-center justify-start space-x-2 overflow-hidden">
                        <div class="relative">
                            <app-profile-photo
                                class="h-fit"
                                [ultimate]="team.users[0].user?.is_ultimate"
                                [badge]="team.users[0].user?.badge"
                                [url]="team.users[0].user?.profile_photo_url"
                                [size]="32">
                            </app-profile-photo>
                        </div>
                        <div class="space-y-0.5 overflow-hidden">
                            <div class="flex items-center space-x-1">
                                <div class="flex-1 ucfirst truncate text-xs font-bold leading-none">
                                    {{ GetPlayers(team) }}
                                </div>
                                @if (team.result === 'won') {
                                    <app-icon icon="emoji_events" class="text-orange"></app-icon>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 flex items-center justify-center space-x-2">
                        {{ game.has_sets ? team.total_score_sets : team.total_score_legs }}
                    </div>
                    <div class="col-span-2 flex items-center justify-end space-x-2 text-right text-xs">
                        {{ team.three_dart_average || 0 | number: '1.2-2' }}
                    </div>
                </div>
            }
        </div>
    </div>
}
