import { CommonModule } from '@angular/common';
import { Component, inject, input, InputSignal } from '@angular/core';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PrimaryButtonComponent } from '../primary-button/primary-button.component';

@Component({
    selector: 'app-ingame-turn-info',
    templateUrl: 'ingame-turn-info.component.html',
    standalone: true,
    imports: [CommonModule, TranslateModule, PrimaryButtonComponent],
})
export class IngameTurnInfoComponent {
    public omniActive: InputSignal<boolean> = input.required<boolean>();
    public playerCount: InputSignal<number> = input.required<number>();
    public playerName: InputSignal<string> = input.required<string>();

    public isOnline: InputSignal<boolean> = input<boolean>(null);
    public isPaused: InputSignal<boolean> = input<boolean>(null);
    public gameType: InputSignal<GameMode> = input<GameMode>(null);
    public myTurn: InputSignal<boolean> = input<boolean>(true);

    public omniIngameService: OmniIngameService = inject(OmniIngameService);
    private _translateService: TranslateService = inject(TranslateService);

    // Disallow the 'users turn to throw label'
    private disallowGameTypes: GameMode[] = ['score_training', 'checkout_121'];
    private disallowWhenOmniActive: GameMode[] = ['shanghai', 'doubles_training', 'singles_training', 'bobs_27'];

    public playerChange() {
        this.omniIngameService.forcePlayerChange();
    }

    public forceOmniTurnReady() {
        this.omniIngameService.forceOmniTurnReady();
    }

    public userThrowsLabel(): string {
        const playername = this.truncateString(this.playerName(), 20);
        return this._translateService.instant('USERS_TURN_TO_THROW', { user: playername });
    }

    public showUserThrowsLabel(): boolean {
        // Hide label in an online game with OMNI
        if (this.omniActive() && this.isOnline() && this.myTurn()) {
            return false;
        }

        // Hide label in specific gametypes
        if (this.disallowGameTypes.indexOf(this.gameType()) >= 0) {
            return false;
        }

        // Hide label in specific gametypes if the OMNI is enabled
        if (this.omniActive() && this.disallowWhenOmniActive.indexOf(this.gameType()) >= 0) {
            return false;
        }

        return true;
    }

    private truncateString(str, num): string {
        if (str.length > num) {
            return str.slice(0, num) + '...';
        } else {
            return str;
        }
    }
}
