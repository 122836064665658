import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

export type LiveCameraPreviewDialogPayload = {
    userId: number;
};

@Component({
    selector: 'app-live-camera-preview-dialog',
    templateUrl: 'live-camera-preview.dialog.html',
})
export class LiveCameraPreviewDialogComponent implements OnInit, OnDestroy {
    @Input() userId: number;
    @Input() title: string = null;

    private _backButtonSubscription: Subscription;

    constructor(
        public ingameCameraService: InGameCameraService,
        public view: ModalController,
        private _platform: Platform
    ) {}

    ngOnInit(): void {
        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    ngOnDestroy(): void {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }
    }
}
