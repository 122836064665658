<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="DismissModal()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'BREAK_SETTINGS'" i18n="@@BREAK_SETTINGS">Take a short break</div>
    </div>
    <div class="slide-modal-content">
        <div class="text-center" [translate]="'SELECT_BREAK_DURATION'" i18n="@@SELECT_BREAK_DURATION">
            How many minutes break?
        </div>
        <div class="tab-toggle-container my-4">
            @for (minute of minuteOptions; track minute) {
                <div
                    class="tab-toggle"
                    [class.inner-border-b-2-orange]="minute === selectedMinutes"
                    [class.opacity-60]="minute !== selectedMinutes"
                    (click)="changeDuration(minute)">
                    <div>{{ minute }}</div>
                </div>
            }
        </div>
        <app-primary-button
            [addedClasses]="'w-full'"
            [color]="'orange'"
            [bold]="false"
            (click)="StartPause()"
            [translate]="'START_PAUSE'"
            i18n="@@START_PAUSE">
            Start break
        </app-primary-button>
    </div>
</div>
