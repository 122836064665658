import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';

@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    standalone: true,
    imports: [IonicModule, PrimaryButtonComponent],
})
export class HelpModalComponent {
    title: string;
    contents: string;
    button: {
        text: string;
        url: string;
    };

    constructor(public modal: ModalController) {}

    dismiss() {
        this.modal.dismiss();
    }

    visitCallToAction() {
        window.open(this.button.url, '_blank');
    }
}
