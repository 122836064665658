<div class="flex flex-col space-y-2">
    <!-- Game settings -->
    <div class="new-game-card">
        <div class="title-container">
            <div class="icon">
                <app-icon icon="tune" size="small"></app-icon>
            </div>
            <div class="title" [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</div>
        </div>

        <div class="flex flex-col space-y-4">
            <div class="flex flex-col space-y-2">
                <app-list-switch [item]="mode" [items]="modes" (itemChange)="changeMode($event)"></app-list-switch>
                <div
                    class="text-left text-xs text-neutral-600"
                    *ngIf="mode === 'cricket'"
                    [translate]="'CRICKET_EXPLANATION'"
                    i18n="@@CRICKET_EXPLANATION">
                    In Cricket you need to close all the numbers from 15 to 20 as quick as you can. You will 'close' the
                    number by hitting them 3 times. Single = 1, Double = 2, Treble = 3.
                </div>
                <div
                    class="text-left text-xs text-neutral-600"
                    *ngIf="mode === 'tactics'"
                    [translate]="'TACTICS_EXPLANATION'"
                    i18n="@@TACTICS_EXPLANATION">
                    In Tactics you need to close all the numbers from 10 to 20 as quick as you can. You will 'close' the
                    number by hitting them 3 times. Single = 1, Double = 2, Treble = 3.
                </div>

                <div class="flex items-center space-x-1">
                    <app-list-switch
                        class="w-full"
                        [item]="bestOfFirstTo"
                        [items]="bestOfFirstTos"
                        (itemChange)="changeBestOfFirstTo($event)"></app-list-switch>
                    <div
                        class="rounded bg-orange px-3 pb-[0.15rem] pt-[0.25rem] font-bebas-neue text-3xl !text-white cursor-pointer"
                        (click)="showGoalAmountDialog()">
                        {{ goalAmount }}
                    </div>
                    <app-list-switch
                        class="w-full"
                        [item]="setOrLeg"
                        [items]="setsOrLegs"
                        (itemChange)="changeSetOrLeg($event)"></app-list-switch>
                </div>
            </div>

            <div class="flex flex-col space-y-3">
                <app-toggle-switch
                    class="block w-full"
                    [model]="twoLegsDifference"
                    (itemChange)="twoLegsDifference = !twoLegsDifference"
                    [translate]="'TWO_LEGS_DIFFERENCE'"
                    i18n="@@TWO_LEGS_DIFFERENCE">
                    Two legs difference
                </app-toggle-switch>

                <app-toggle-switch
                    class="block w-full"
                    [model]="suddenDeath"
                    (itemChange)="suddenDeath = !suddenDeath"
                    [translate]="'SUDDEN_DEATH'"
                    i18n="@@SUDDEN_DEATH"
                    *ngIf="twoLegsDifference">
                    Sudden death
                </app-toggle-switch>
            </div>
        </div>
    </div>

    <!-- In-game timer -->
    <div class="new-game-card !py-3">
        <div class="title-container">
            <div class="icon">
                <app-icon icon="timer" size="small"></app-icon>
            </div>
            <div class="title" [translate]="'INGAME_TIMER'" i18n="@@INGAME_TIMER">In-game timer</div>
            <div
                class="ml-auto rounded bg-orange px-2 pb-[0.15rem] pt-[0.25rem] font-bebas-neue !text-white cursor-pointer"
                (click)="showTimerDurationDialog()">
                <strong class="text-2xl">{{ inGameTimer }}</strong>
                s
            </div>
        </div>
    </div>

    <!-- Fast mode -->
    <div class="new-game-card">
        <div class="title-container">
            <div class="icon">
                <app-icon icon="speedmode" size="small"></app-icon>
            </div>
            <div class="title" [translate]="'SEND_SCORES_IMMEDIATELY'" i18n="@@SEND_SCORES_IMMEDIATELY">
                Send scores immediately
            </div>
            <div
                class="bg-blue p-0.5 !text-white rounded-full cursor-pointer ml-2"
                (click)="showHelpModal('SEND_SCORES_IMMEDIATELY', 'FAST_OR_SLOW')">
                <app-icon icon="questionmark" size="small"></app-icon>
            </div>

            <ion-toggle
                mode="ios"
                class="ml-auto !pr-2"
                style="zoom: 0.75"
                color="secondary"
                [(ngModel)]="fastScoring"></ion-toggle>
        </div>
    </div>
</div>
