import { UltimateFeature } from 'src/app/core/app-features/services/app-features.service';
import { SubscriptionApiService } from '../dc-api/subscription.api.service';
import { CancelReasonAnswer, Subscription, SubscriptionCancelReason } from '../dc-backend/dc-classes';
import { DartCounterAlertService } from '../dc-services/alert.service';
import _ from 'lodash';

type ExtendedSubscriptionCancelReason = SubscriptionCancelReason & { selected?: boolean; explanation?: string };

export class CancelSubscription {
    public alertService: DartCounterAlertService;
    public subscriptionApiService: SubscriptionApiService;

    public subscription: Subscription = null;
    public howToCancel: string | null;
    public reasonPlaceholder: string = $localize`:@@YOUR_REASON:Your reason`;

    public cancelStage: 1 | 2 | 3 = 1;
    public cancelReasons: SubscriptionCancelReason[] = [];
    public currentCancelReasons: ExtendedSubscriptionCancelReason[] = [];
    public selectedReason: SubscriptionCancelReason = null;
    public selectedReasonId: number = null;
    public lastSelectedReason: SubscriptionCancelReason = null;
    public selectedReasons: SubscriptionCancelReason[] = [];
    public explainedReason: string = '';
    public answers: CancelReasonAnswer[] = [];

    constructor() {}

    public setStage(cancelStage: 1 | 2 | 3): void {
        this.cancelStage = cancelStage;
    }

    selectReason(reason: SubscriptionCancelReason): void {
        this.selectedReason = reason;
        this.selectedReasonId = reason.id;
    }

    getLastSelectedReason(): SubscriptionCancelReason {
        if (!this.selectedReasons.length) {
            return null;
        }

        return _.last(this.selectedReasons);
    }

    setCurrentCancelReasons(): void {
        this.lastSelectedReason = this.getLastSelectedReason();
        if (this.lastSelectedReason) {
            if (this.lastSelectedReason.reasons.length) {
                this.currentCancelReasons = this.lastSelectedReason.reasons;
            } else {
                this.currentCancelReasons = [];
            }

            if (this.lastSelectedReason.type === 'multiple') {
                this.selectedReason = this.lastSelectedReason;
                this.selectedReasonId = this.lastSelectedReason.id;
            }
        } else {
            this.currentCancelReasons = this.cancelReasons;
        }
    }

    toggleAnswer(reason: ExtendedSubscriptionCancelReason): void {
        if (reason.selected) {
            reason.selected = false;
        } else {
            reason.selected = true;
        }
    }

    public goBack(): void {
        if (!this.selectedReasons.length) {
            return;
        }

        if (this.lastSelectedReason) {
            this.answers.forEach((answer, index) => {
                if (answer.cancel_reason_id === this.lastSelectedReason.id) {
                    this.answers.splice(index, 1);
                }
            });
        }

        this.currentCancelReasons.forEach((cancelReason) => {
            cancelReason.selected = false;
            cancelReason.explanation = null;
        });

        this.selectedReasons.splice(this.selectedReasons.length - 1, 1);
        this.selectedReason = null;
        this.selectedReasonId = null;
        this.setCurrentCancelReasons();
    }

    public continueToCancel(): void {
        if (!this.selectedReason) {
            this.alertService.createAlert({
                title: $localize`:@@PLEASE_SELECT_AN_OPTION:Please select an option`,
                icon: 'error',
            });
            return;
        }

        if (this.selectedReason.type === 'open' && !this.explainedReason) {
            this.alertService.createAlert({
                title: $localize`:@@CANCEL_REASON_IS_REQUIRED:Your cancel reason is required`,
                icon: 'error',
            });
            return;
        }

        if (this.lastSelectedReason) {
            this.currentCancelReasons.forEach((cancelReason) => {
                if (cancelReason.selected) {
                    this.answers.push({
                        cancel_reason_id: cancelReason.id,
                        user_id: this.subscription.user_id,
                        subscription_id: this.subscription.id,
                        explanation: cancelReason.explanation,
                    });
                }
            });
        }

        this.selectedReasons.push(this.selectedReason);
        if (
            this.selectedReason?.reasons.length &&
            (!this.lastSelectedReason || this.lastSelectedReason.type !== 'multiple')
        ) {
            this.selectedReason = null;
            this.setCurrentCancelReasons();
        } else {
            this.setStage(3);

            if (this.howToCancel) {
                this.subscriptionApiService
                    .cancelSubscriptionById({
                        subscriptionId: this.subscription.id,
                        cancel_reason_id: this.selectedReason.id,
                        cancel_reason_explanation: this.explainedReason,
                        answers: this.answers,
                    })
                    .catch((err) => this.alertService.errorFromApi(err));
            }
        }
    }
}
