<!-- Translation containers-->
<ng-container *ngIf="0" i18n="@@ENTER_NEW_VALUE">Enter new value</ng-container>
<!-- End of translation containers-->

<div class="slide-modal-outer slide-modal-full">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="close()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'EDIT_SCORES'" i18n="@@EDIT_SCORES">Edit score</div>
    </div>
    <div class="slide-modal-content bg-white-pattern !mx-0 px-4">
        @if (turns) {
            @if (!turns.length) {
                <h4
                    *ngIf="!turns.length"
                    class="text-center mt-4 text-black"
                    [translate]="'THERE_ARE_NO_THROWS'"
                    i18n="@@THERE_ARE_NO_THROWS">
                    There are no throws!
                </h4>
            } @else {
                <h4
                    *ngIf="turns.length"
                    class="text-center my-4 text-black"
                    [translate]="'SELECT_SCORE_TO_EDIT'"
                    i18n="@@SELECT_SCORE_TO_EDIT">
                    Which score do you want to edit?
                </h4>

                <div class="flex w-full flex-col space-y-4">
                    @for (turn of turns; track turnIndex; let turnIndex = $index) {
                        <app-accordion
                            [isExpanded]="selectedTurnIndex === turnIndex"
                            (expandChange)="toggleEditTurn(turn, turnIndex)">
                            <div accordion-header class="flex text-sm items-center justify-between w-full">
                                <div class="font-bold text-black">
                                    <span [translate]="'TURN'" i18n="@@TURN">Turn</span>
                                    <span class="ml-1">#{{ turnIndex + 1 }}</span>
                                </div>
                                <div class="text-black">
                                    <span [translate]="'TOTAL_SCORE'" i18n="@@TOTAL_SCORE">Total score</span>:
                                    {{ turn.total_score }}
                                </div>
                            </div>
                            <div accordion-body class="bg-white">
                                @if (activeEditTurn) {
                                    <div class="p-4 space-y-2 text-black">
                                        @if (!activeEditTurn.single_dart_mode) {
                                            <div
                                                class="dialog-input-label"
                                                [translate]="'TOTAL_SCORE'"
                                                i18n="@@TOTAL_SCORE">
                                                Total score
                                            </div>
                                            <input
                                                type="number"
                                                min="0"
                                                max="180"
                                                class="dialog-input text-sm w-full mt-1"
                                                placeholder="{{ 'ENTER_NEW_VALUE' | translate }}"
                                                [(ngModel)]="activeEditTurn.total_score" />
                                        }
                                        @if (activeEditTurn.single_dart_mode && activeEditTurn.throws?.length) {
                                            <div class="space-y-2">
                                                @for (
                                                    throw of activeEditTurn.throws;
                                                    track throwIndex;
                                                    let throwIndex = $index
                                                ) {
                                                    <div class="flex justify-between space-x-2">
                                                        <div class="w-full bg-white">
                                                            <div
                                                                class="dialog-input-label"
                                                                [translate]="'MULTIPLIER'"
                                                                i18n="@@MULTIPLIER">
                                                                Multiplier
                                                            </div>
                                                            <select
                                                                id="multiplier-{{ throwIndex }}"
                                                                name="multiplier-{{ throwIndex }}"
                                                                class="dialog-input text-sm w-full mt-1 cursor-pointer"
                                                                [ngModel]="throw.multiplier"
                                                                (ngModelChange)="
                                                                    updateThrow(throw.number.toString(), $event, throw)
                                                                ">
                                                                @for (multiplier of multiplierValues; track $index) {
                                                                    <option
                                                                        [value]="multiplier.value"
                                                                        [disabled]="
                                                                            multiplier.value === 3 &&
                                                                            throw.number === 25
                                                                        ">
                                                                        {{ multiplier.label }}
                                                                    </option>
                                                                }
                                                            </select>
                                                        </div>
                                                        <div class="w-full bg-white">
                                                            <div
                                                                class="dialog-input-label"
                                                                [translate]="'NUMBER'"
                                                                i18n="@@NUMBER">
                                                                Number
                                                            </div>
                                                            <select
                                                                id="number-{{ throwIndex }}"
                                                                name="number-{{ throwIndex }}"
                                                                class="dialog-input text-sm w-full mt-1 cursor-pointer"
                                                                [ngModel]="throw.number"
                                                                (ngModelChange)="
                                                                    updateThrow(
                                                                        $event,
                                                                        throw.multiplier?.toString(),
                                                                        throw
                                                                    )
                                                                ">
                                                                @for (number of numberValues; track $index) {
                                                                    <option [value]="number.value">
                                                                        {{ number.label }}
                                                                    </option>
                                                                }
                                                            </select>
                                                        </div>
                                                        <div class="w-full bg-white">
                                                            <div
                                                                class="dialog-input-label"
                                                                [translate]="'VALUE'"
                                                                i18n="@@VALUE">
                                                                Value
                                                            </div>
                                                            <input
                                                                id="value-{{ throwIndex }}"
                                                                name="value-{{ throwIndex }}"
                                                                class="dialog-input text-sm mt-1 w-full"
                                                                disabled
                                                                [value]="throw.value" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div class="flex justify-end">
                                            <app-primary-button
                                                class="block"
                                                size="small"
                                                addedClasses="w-full"
                                                (click)="saveTurn(turnIndex)">
                                                <span [translate]="'SAVE'" i18n="@@SAVE">Save</span>
                                            </app-primary-button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </app-accordion>
                    }
                </div>
            }
        }
    </div>
</div>
