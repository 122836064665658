@if (appFeaturesService.enabledAppFeatures().speech_to_score) {
    <div id="bars" (click)="showSpeechToScore()">
        @for (number of numbers; track number) {
            <div
                class="bar !bg-white"
                [class.not-animate]="!(recognizing === true && processing === false && playingAudio === false)"
                [class.animate]="recognizing === true && processing === false && playingAudio === false"></div>
        }
    </div>
}
