import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartesianCoordinate } from '@dc-core/dc-services/omni/omni-ingame.service';
import { ModalController } from '@ionic/angular/standalone';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-heatmap-dialog',
    templateUrl: 'heatmap.dialog.html',
})
export class HeatmapDialogComponent {
    @Input() coordinates: CartesianCoordinate[];

    private modalController: ModalController = inject(ModalController);

    constructor(
        public auth: AuthService,
        public route: ActivatedRoute
    ) {}

    public dismiss(): void {
        this.modalController.dismiss();
    }
}
