import { GameMode } from '@dc-core/dc-backend/dc-enums';

export class OnlineGameHelper {
    public static getGameRoute(gameMode: GameMode, docId: string, spectate = false): string {
        let route = '';
        switch (gameMode) {
            case 'match':
                route += 'online-game/match/';
                break;
            case 'cricket_tactics':
                route += 'online-game/cricket-tactics/';
                break;
        }
        if (spectate) {
            route += 'spectate/';
        }
        return route + docId;
    }
}
