import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { AmountOfHits, AroundTheClockThrowout, NumberSegment } from '@dc-core/dc-backend/dc-enums';
import { User } from 'dc-core/dc-backend/dc-classes';
import { GameStatistics } from '../game-statistics.classes';

export interface AroundTheClockOverallStatsData {
    hitRate: { average?: number; best?: number; worst?: number };
    dartsThrown: { average?: number; best?: number; worst?: number };
    totalScore: { average?: number; best?: number; worst?: number };
}

export interface AroundTheClockStatDetails {
    throwout: AroundTheClockThrowout;
    numberSegment: NumberSegment;
    amountOfHits: AmountOfHits;
}

export class AroundTheClockOverallStats extends GameStatistics {
    public throwout: AroundTheClockThrowout = 'all';
    public numberSegment: NumberSegment = 'all';
    public amountOfHits: AmountOfHits = 'all';

    public overallStats: AroundTheClockOverallStatsData = {
        hitRate: {},
        dartsThrown: {},
        totalScore: {},
    } as any;

    public loadedOverallStats: boolean = false;
    public gameStatisticsApiService: GameStatisticsApiService;

    private _localStorageKey = 'aroundTheClockOverallStats';

    constructor(user: User, gameStatisticsApiService: GameStatisticsApiService) {
        super(user);
        this.gameStatisticsApiService = gameStatisticsApiService;
    }

    setFilters(): void {
        let savedFilters = JSON.parse(localStorage.getItem(this._localStorageKey));
        if (savedFilters) {
            this.throwout = savedFilters.throwout ?? this.throwout;
            this.numberSegment = savedFilters.numberSegment ?? this.numberSegment;
            this.amountOfHits = savedFilters.amountOfHits ?? this.amountOfHits;
        }
        super.setFilters();
    }

    saveFilters() {
        localStorage.setItem(
            this._localStorageKey,
            JSON.stringify({
                throwout: this.throwout,
                numberSegment: this.numberSegment,
                amountOfHits: this.amountOfHits,
                statisticsGameType: this.statisticsGameType,
                statisticsRange: this.statisticsRange,
                year: this.year,
                month: this.month,
            })
        );
        super.saveFilters();
    }

    async loadOverallStats(): Promise<void> {
        this.loadedOverallStats = false;
        await this.initHitRate();
        if (this.user.is_ultimate) {
            await this.initDartsUsed();
        }
        this.loadedOverallStats = true;
        this.saveFilters();
    }

    async initHitRate(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getAroundTheClockHitRateStatistics({
                        type,
                        throwout: this.throwout,
                        number_segment: this.numberSegment,
                        amount_of_hits: this.amountOfHits,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.hitRate[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });
                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initDartsUsed(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getAroundTheClockDartsThrownStatistics({
                        type,
                        throwout: this.throwout,
                        number_segment: this.numberSegment,
                        amount_of_hits: this.amountOfHits,
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.dartsThrown[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });
                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }
}
