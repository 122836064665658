import { Component, input, InputSignal, output, OutputEmitterRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SocialLoginType } from '../social-logins/social-logins.component';

@Component({
    selector: 'app-google-web-signin',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './google-web-signin.component.html',
    styleUrl: './google-web-signin.component.css',
})
export class GoogleWebSigninComponent {
    public type: InputSignal<SocialLoginType> = input.required<SocialLoginType>();

    public loginWithGoogle: OutputEmitterRef<any> = output<any>();

    public handleGoogleLogin(): void {
        this.loginWithGoogle.emit(this.createFakeGoogleWrapper());
    }

    private createFakeGoogleWrapper(): { click: () => void } {
        const googleLoginWrapper = document.createElement('div');
        googleLoginWrapper.style.display = 'none';
        googleLoginWrapper.classList.add('custom-google-button');
        document.body.appendChild(googleLoginWrapper);
        (window as any).google.accounts.id.renderButton(googleLoginWrapper, {
            type: 'icon',
            width: '200',
        });

        const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]') as HTMLElement;

        return {
            click: () => {
                googleLoginWrapperButton?.click();
            },
        };
    }
}
