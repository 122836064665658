import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class CameraStreamApiService extends ApiService {
    public getCameraStreamRoomNumber = super.getFetcher().path('/camera-streams').method('get').create();
    public storeCameraStreamByRoomNumber = super.getFetcher().path('/camera-streams').method('post').create();
    public updateCameraStreamByCodeAndPassword = super.getFetcher().path('/camera-streams').method('put').create();
    public deleteCameraStream = super.getFetcher().path('/camera-streams').method('delete').create();
}
