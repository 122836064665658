@if (authenticatedUserId()) {
    <div
        (click)="showInGameComms()"
        class="inline-flex portrait:h-10 portrait:w-10 landscape:h-9 landscape:w-9 items-center justify-center rounded-full bg-blue p-2 cursor-pointer">
        @if (currentCall()?.status === VoiceCallStatus.InCall) {
            <app-voice-bars></app-voice-bars>
        } @else {
            <app-icon icon="in_game_comms" class="!text-white"></app-icon>
        }

        @if (unreadChatMessages() > 0 || missedCalls() > 0) {
            <ion-badge mode="md" class="ion-bg-white ion-text-black absolute -right-0.5 -top-0.5">
                {{ unreadChatMessages() + missedCalls() }}
            </ion-badge>
        }

        @if (incomingChatMessages().length) {
            <div class="speech-bubble-container">
                @for (message of incomingChatMessages(); track $index) {
                    <div class="bubble" (click)="removeIncomingMessage($event, message)">
                        <div>{{ message.display_msg }}</div>
                    </div>
                }
            </div>
        }
    </div>
}
