<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@NEW_GAME">New game</ng-container>
    <ng-container i18n="@@TUT_NEWGAME">Start a local match or training.</ng-container>
    <ng-container i18n="@@ONLINE_GLOBAL">Online global</ng-container>
    <ng-container i18n="@@TUT_GLOBAL">Play online against friends or random players.</ng-container>
    <ng-container i18n="@@SELECT_YOUR_NAME">Call your name</ng-container>
    <ng-container i18n="@@TUT_CALLER"
        >Our mastercaller makes the app come to live. Link your name for even more fun!
    </ng-container>
    <ng-container i18n="@@STATISTICS">Statistics</ng-container>
    <ng-container i18n="@@TUT_STATISTICS"
        >We're keeping track of your progress. Practice and see how you improve. Good Darts!</ng-container
    >
}
<!-- End of translation containers-->

<app-page-header mainNavigation="menu">
    <img
        title
        class="trbl-0 absolute m-auto h-5"
        src="assets/icon/dctarget-line-grey.png"
        alt="dartcounter logo dark" />
    <div right class="right-page-header">
        @if (appFeaturesService.enabledAppFeatures().tournaments) {
            <app-notifications-icon [category]="null"></app-notifications-icon>
        }
        <app-fullscreen-icon></app-fullscreen-icon>
    </div>
</app-page-header>

<div class="relative flex h-full flex-col overflow-hidden text-white" id="main-content">
    <div class="flex flex-1 flex-col overflow-auto overscroll-none bg-white-pattern" id="scroll-content">
        <!-- Profile -->
        <div class="flex items-center !bg-black py-5 px-safe-offset-6">
            <app-profile-photo
                class="h-fit cursor-pointer"
                [size]="72"
                [ultimate]="auth.user.is_ultimate"
                [badge]="auth.user.profile?.badge"
                [big]="true"
                [url]="auth.user.profile ? auth.user.profile?.profile_photo_url : null"
                (click)="ShowProfile()"></app-profile-photo>
            <div class="ml-5 w-full overflow-hidden">
                <div class="flex items-center space-x-2 cursor-pointer" (click)="ShowProfile()">
                    <div class="text-lg font-bold leading-none !text-white truncate">{{ auth.user.full_name }}</div>
                    @if (auth.user?.profile?.country) {
                        <img class="h-3.5" src="assets/images/flags/rect/{{ getFlag(auth.user.profile.country) }}" />
                    }
                </div>
                <div
                    class="mt-1 text-sm font-semibold !text-neutral-200 cursor-pointer truncate"
                    (click)="ShowProfile()">
                    {{ auth.user.profile?.nickname || ('NO_NICKNAME' | translate) }}
                </div>
                <div class="mt-4 flex items-center justify-between">
                    <div class="flex items-center space-x-2.5">
                        <div class="flex items-center space-x-0.5">
                            <app-icon icon="star" size="medium" class="text-orange"></app-icon>
                            <div class="font-bebas-neue !text-neutral-100">
                                {{ auth.user.rating || 0 | number: '1.1-1' }}
                            </div>
                        </div>
                        <div class="flex items-center space-x-0.5">
                            <app-icon icon="three_dots" size="medium" class="text-orange"></app-icon>
                            <div class="font-bebas-neue !text-neutral-100">
                                {{ auth.user.two_month_average || 0 | number: '1.2-2' }}
                            </div>
                        </div>
                    </div>
                    @if (!auth.user.is_ultimate) {
                        <div
                            class="flex items-center rounded bg-blue py-1 pl-2 pr-0.5 !text-white"
                            (click)="OpenSubscriptionDialog()">
                            <div class="text-xs cursor-pointer" [translate]="'UPGRADE_BTN'" i18n="@@UPGRADE_BTN">
                                Upgrade
                            </div>
                            <app-icon icon="keyboard_arrow_right" size="medium"></app-icon>
                        </div>
                    }
                    @if (auth.user.is_ultimate) {
                        <div class="flex items-center text-orange cursor-pointer" (click)="OpenSubscriptionDialog()">
                            <div class="text-xs text-orange" [translate]="'ULTIMATE'" i18n="@@ULTIMATE">Ultimate</div>
                            <app-icon icon="keyboard_arrow_right" size="medium" class="text-orange"></app-icon>
                        </div>
                    }
                </div>
            </div>
        </div>

        <!-- Buttons -->
        <div class="flex flex-col space-y-2 py-2 px-safe-offset-4" appWebContainer>
            <!-- Recap -->
            @if (canRecap) {
                <img
                    class="rounded-lg duration-200 hover:scale-[1.02] cursor-pointer"
                    src="assets/images/recap/{{ recapYear }}_recap_dashboard.jpg"
                    (click)="showRecap()" />
            }

            <!-- Offline and  online -->
            <div class="flex space-x-2">
                <div
                    class="w-1/2 rounded-lg bg-panel p-4 duration-200 hover:scale-[1.02] cursor-pointer"
                    (click)="NewGame()"
                    (done)="joyrideDone()"
                    joyrideStep="dashb1"
                    title="{{ 'NEW_GAME' | translate }}"
                    text="{{ 'TUT_NEWGAME' | translate }}">
                    <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-orange">
                        <app-icon icon="dartboard" class="!text-white"></app-icon>
                    </div>
                    <div class="mt-4 flex items-center space-x-1.5">
                        <div class="font-bebas-neue text-lg text-black" [translate]="'PLAY_LOCAL'" i18n="@@PLAY_LOCAL">
                            Play local
                        </div>
                        @if (gameApiService.unfinishedLocalMatches.length) {
                            <ion-badge mode="md" class="ion-bg-yellow !ion-text-black">
                                {{ gameApiService.unfinishedLocalMatches.length }}
                            </ion-badge>
                        }
                    </div>

                    <div
                        class="text-xs font-semibold text-neutral-400"
                        [translate]="'ENJOY_LOCAL_GAMES'"
                        i18n="@@ENJOY_LOCAL_GAMES">
                        Enjoy local games with anyone at anytime!
                    </div>
                </div>

                <div
                    class="w-1/2 rounded-lg bg-panel p-4 duration-200 hover:scale-[1.02] cursor-pointer"
                    (click)="goToOnlineGames()"
                    (done)="joyrideDone()"
                    joyrideStep="dashb2"
                    title="{{ 'ONLINE_GLOBAL' | translate }}"
                    text="{{ 'TUT_GLOBAL' | translate }}">
                    <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-orange">
                        <app-icon icon="language" class="!text-white"></app-icon>
                    </div>
                    <div
                        class="mt-4 font-bebas-neue text-lg text-black"
                        [translate]="'PLAY_ONLINE'"
                        i18n="@@PLAY_ONLINE">
                        Play online
                    </div>
                    <div
                        class="text-xs font-semibold text-neutral-400"
                        [translate]="'ONLINE_GAMES_INFO'"
                        i18n="@@ONLINE_GAMES_INFO">
                        Find opponents from anywhere in the world or watch live games
                    </div>
                </div>
            </div>

            <!-- Slider -->
            <div class="flex items-center rounded-lg bg-panel px-4 pb-1 pt-4">
                <swiper-container
                    #swiper
                    class="dc-swiper orange w-full"
                    [pagination]="true"
                    autoplay-delay="4000"
                    autoplay-disable-on-interaction="false">
                    @if (images.blackFriday && !auth.user.is_ultimate) {
                        <swiper-slide class="space-y-2 cursor-pointer" (click)="OpenSubscriptionDialog()">
                            <img class="rounded-md" [src]="images.blackFriday" *ngIf="images.blackFriday" />
                            <div class="text-center text-xs font-bold uppercase text-neutral-400">Black Friday!</div>
                        </swiper-slide>
                    }
                    <swiper-slide class="space-y-2 cursor-pointer" (click)="open121Checkout()">
                        <img class="rounded-md" [src]="images.checkout121" />
                        @if (!auth.user.is_ultimate) {
                            <div
                                class="text-center text-xs font-bold uppercase text-neutral-400"
                                [translate]="'NEW_GAMEMODE_CHECKOUT121'"
                                i18n="@@NEW_GAMEMODE_CHECKOUT121">
                                Try this Ultimate-only game for free
                            </div>
                        } @else {
                            <div
                                class="text-center text-xs font-bold uppercase text-neutral-400"
                                [translate]="'NEW_GAMEMODE_CHECKOUT121_ULTIMATE'"
                                i18n="@@NEW_GAMEMODE_CHECKOUT121_ULTIMATE">
                                Play 121 checkout now
                            </div>
                        }
                    </swiper-slide>
                    @if (!auth.user.is_ultimate) {
                        <swiper-slide class="space-y-2 cursor-pointer" (click)="OpenSubscriptionDialog()">
                            <img class="rounded-md" [src]="images.upgradeToUltimate" *ngIf="images.upgradeToUltimate" />
                            <div
                                class="text-center text-xs font-bold uppercase text-neutral-400"
                                [translate]="'UPGRADE_TO_ULTIMATE'"
                                i18n="@@UPGRADE_TO_ULTIMATE">
                                Upgrade to Ultimate
                            </div>
                        </swiper-slide>
                    }
                    @if (images.action_replays && appFeaturesService.enabledAppFeatures().action_replays) {
                        <swiper-slide class="space-y-2 cursor-pointer" (click)="showFeatureSlides('action_replays')">
                            <img class="rounded-md" [src]="images.action_replays" />
                            <div
                                class="text-center text-xs font-bold uppercase text-neutral-400"
                                [translate]="'NEW_ACTION_REPLAYS'"
                                i18n="@@NEW_ACTION_REPLAYS">
                                New: Action Replays
                            </div>
                        </swiper-slide>
                    }
                    @if (images.follow_us) {
                        <swiper-slide class="space-y-2 cursor-pointer" (click)="OpenFollowUsModal()">
                            <img class="rounded-md" [src]="images.follow_us" />
                            <div
                                class="text-center text-xs font-bold uppercase text-neutral-400"
                                [translate]="'FOLLOW_US'"
                                i18n="@@FOLLOW_US">
                                Follow us through our channels
                            </div>
                        </swiper-slide>
                    }
                    <swiper-slide class="space-y-2 cursor-pointer" (click)="preorderOmni()">
                        <img class="rounded-md" [src]="images.omni" *ngIf="images.omni" />
                        <div
                            class="text-center text-xs font-bold uppercase text-neutral-400"
                            [translate]="'PRE_ORDER_OMNI'"
                            i18n="@@PRE_ORDER_OMNI">
                            Pre-order Omni
                        </div>
                    </swiper-slide>
                    <swiper-slide
                        class="space-y-2 cursor-pointer"
                        (click)="openLink2('https://www.target-darts.co.uk/virt')">
                        <img class="rounded-md" [src]="images.virt" *ngIf="images.virt" />
                        <div
                            class="text-center text-xs font-bold uppercase text-neutral-400"
                            [translate]="'PURCHASE_NOW'"
                            i18n="@@PURCHASE_NOW">
                            Purchase now
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>

            @if (appFeaturesService.enabledAppFeatures().tournaments) {
                <!-- Tournaments -->
                <div
                    class="w-full rounded-lg bg-panel p-4 duration-200 hover:scale-[1.02] cursor-pointer"
                    (click)="goToTournaments()">
                    <div class="flex items-center space-x-3 mb-4">
                        <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-orange">
                            <app-icon icon="tournaments" class="!text-white"></app-icon>
                        </div>
                        <div
                            class="font-bebas-neue text-xl text-black"
                            [translate]="'ONLINE_TOURNAMENTS'"
                            i18n="@@ONLINE_TOURNAMENTS">
                            Online tournaments
                        </div>
                    </div>

                    <div
                        class="text-xs font-semibold text-neutral-400"
                        [translate]="'COMPETE_AGAINST_PLAYERS_IN_TOURNAMENTS'"
                        i18n="@@COMPETE_AGAINST_PLAYERS_IN_TOURNAMENTS">
                        Find, watch or compete against players from around the world in tournaments
                    </div>
                </div>
            }

            <!-- Statistics -->
            <div
                class="flex items-center rounded-lg bg-panel p-4 duration-200 hover:scale-[1.02] cursor-pointer"
                (click)="OpenStatistics()"
                (done)="joyrideDone()"
                joyrideStep="dashb3"
                title="{{ 'STATISTICS' | translate }}"
                text="{{ 'TUT_STATISTICS' | translate }}">
                <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-neutral-50 p-1">
                    <app-icon icon="trending_up" class="text-black" size="small"></app-icon>
                </div>
                <div class="ml-4">
                    <div class="flex items-center space-x-1.5">
                        <div
                            class="font-bebas-neue text-lg leading-none text-black"
                            [translate]="'STATISTICS'"
                            i18n="@@STATISTICS">
                            Statistics
                        </div>
                        @if (gameApiService.totalUnverifiedGames() > 0) {
                            <ion-badge mode="md" class="ion-bg-yellow !ion-text-black">
                                {{ gameApiService.totalUnverifiedGames() }}
                            </ion-badge>
                        }
                    </div>
                    <div
                        class="mt-0.5 text-xs font-semibold text-neutral-400"
                        [translate]="'CHECK_YOUR_STATS_AND_PROGRESS'"
                        i18n="@@CHECK_YOUR_STATS_AND_PROGRESS">
                        Check your stats and track your progress
                    </div>
                </div>
                <app-icon icon="keyboard_arrow_right" class="ml-auto text-neutral-200"></app-icon>
            </div>

            <!-- MasterCaller -->
            <div
                class="flex items-center rounded-lg bg-panel p-4 duration-200 hover:scale-[1.02] cursor-pointer"
                (click)="LinkName()"
                (done)="joyrideDone()"
                joyrideStep="dashb4"
                title="{{ 'SELECT_YOUR_NAME' | translate }}"
                text="{{ 'TUT_CALLER' | translate }}">
                <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-neutral-50 p-1">
                    <app-icon icon="mic" size="small" class="text-black"></app-icon>
                </div>
                <div class="ml-4">
                    <div
                        class="font-bebas-neue text-lg leading-none text-black"
                        [translate]="'MASTERCALLER'"
                        i18n="@@MASTERCALLER">
                        MasterCaller
                    </div>
                    <div
                        class="mt-0.5 text-xs font-semibold text-neutral-400"
                        [translate]="'LET_MC_CALL_YOUR_NAME'"
                        i18n="@@LET_MC_CALL_YOUR_NAME">
                        Let our MasterCaller announce your name
                    </div>
                </div>
                <app-icon icon="keyboard_arrow_right" class="ml-auto text-neutral-200"></app-icon>
            </div>

            @if (isLocal || isDev) {
                <app-primary-button color="black" size="normal" addedClasses="w-full" (click)="goToDev()">
                    Dev
                </app-primary-button>
            }
        </div>
    </div>

    <app-bottom-navigation class="w-full" active="dashboard"></app-bottom-navigation>
</div>
