<app-page-header mainNavigation="custom" color="transparent">
    <app-icon left icon="arrow_back" (click)="dismiss()"></app-icon>
    <div title>Logs</div>
</app-page-header>

<ion-content scrollY="true" fullscreen>
    <div style="display: flex; justify-content: space-between">
        <ion-select aria-label="Type" interface="popover" [value]="type" (ionChange)="setType($event.target.value)">
            <ion-select-option value="">All</ion-select-option>
            <ion-select-option value="log">Log</ion-select-option>
            <ion-select-option value="warn">Warn</ion-select-option>
            <ion-select-option value="error">Error</ion-select-option>
        </ion-select>
        <div style="padding: 0.5rem" (click)="clearLog()">Clear</div>
    </div>
    <div style="display: flex; flex-direction: column; overflow: scroll">
        <div
            (click)="toggleLog(log)"
            *ngFor="let log of logs"
            [ngClass]="{
                'log-item': true,
                error: log.type === 'error',
                warn: log.type === 'warn',
                log: log.type === 'log'
            }">
            [{{ log.timestamp | date: 'HH:mm:ss' }}]
            <div [ngClass]="{ 'log-message': true, collapsed: !log.expanded }">{{ log.message }}</div>
        </div>
    </div>
</ion-content>
