@if (game) {
    <div class="global-lobby-shadow rounded-md col-span-1 mb-3 flex flex-col" [class.cursor-pointer]="showArrow">
        <div class="bg-game-card-top px-4 py-2.5 rounded-t-md">
            <div class="grid grid-cols-11 items-center">
                <div class="col-span-6 flex items-center text-lg font-bold text-black">
                    <div>
                        {{ game.name }}
                    </div>
                </div>
                <div class="col-span-3 text-center text-sm text-neutral-800">
                    {{ game.started_at | date: 'dd MMM yyyy' : '' : translateService.currentLang }} <br />{{
                        game.started_at | date: 'H:mm'
                    }}
                </div>
                @if (showArrow) {
                    <div class="col-span-2 flex items-center justify-end text-black">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                }
            </div>
        </div>
        <div class="space-y-3 rounded-b-md bg-game-card-bottom px-4 py-2">
            <div class="grid grid-cols-11 items-center text-black" *ngFor="let user of game.users; let i = index">
                <div class="col-span-6 flex items-center justify-start space-x-2 overflow-hidden">
                    <div class="relative">
                        <app-profile-photo
                            class="h-fit"
                            [ultimate]="user.user?.is_ultimate"
                            [badge]="user.user?.profile?.badge"
                            [url]="user.user?.profile?.profile_photo_url"
                            [size]="32">
                        </app-profile-photo>
                    </div>
                    <div class="space-y-0.5 overflow-hidden">
                        <div class="flex items-center space-x-1">
                            <div class="flex-1 ucfirst truncate text-xs font-bold leading-none">
                                {{ getUserName(user) }}
                            </div>
                            @if (user.result === 'won' && game.training_mode === 'versus') {
                                <app-icon icon="emoji_events" class="text-orange"></app-icon>
                            }
                        </div>
                    </div>
                </div>
                <div class="col-span-5">
                    <div class="flex items-center justify-between">
                        <div [translate]="'TOTAL_SCORE'" i18n="@@TOTAL_SCORE" class="text-xs">Total score</div>
                        <div class="ml-1 text-right text-xs">{{ user.total_score }}</div>
                    </div>
                    <div class="flex items-center justify-between">
                        <div [translate]="'DARTS_HIT'" i18n="@@DARTS_HIT" class="text-xs">Darts hit</div>
                        <div class="ml-1 text-right text-xs">{{ user.darts_hit }}/{{ user.turns_count * 3 }}</div>
                    </div>
                    <div class="flex items-center justify-between">
                        <div [translate]="'HIT_RATE'" i18n="@@HIT_RATE" class="text-xs">Hit rate</div>
                        <div class="ml-1 text-right text-xs">
                            {{ (user.darts_hit / (user.turns_count * 3)) * 100 | number: '1.0-2' }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
