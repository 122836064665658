import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserApiService } from '@dc-api/user.api.service';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavParams } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-edit-username-dialog',
    templateUrl: 'edit-username-dialog.html',
})
export class EditUsernameDialogComponent {
    username: string;

    private authService: AuthService = inject(AuthService);

    constructor(
        public view: ModalController,
        private ga: DartCounterAnalyticsService,
        private navParams: NavParams,
        public modal: ModalController,
        public _modal: ModalController,
        private loading: DCLoadingService,
        public userApiService: UserApiService,
        public route: ActivatedRoute,
        private alertService: DartCounterAlertService
    ) {}

    ionViewWillEnter() {
        this.username = this.navParams.get('username');
    }

    SaveUsername() {
        this.loading.ShowDefaultLoader();

        this.userApiService
            .updateUser({
                username: this.username,
            })
            .then(() => {
                this.authService.user.username = this.username;
                this.loading.DismissLoader();
                this.dismiss();
            })
            .catch((err) => {
                this.loading.DismissLoader();
                this.alertService.errorFromApi(err);
            });
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
