/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Injectable()
export class ApiService {
    constructor(private authService?: AuthService) {}

    getFetcher() {
        return this.authService.fetcher;
    }
}
