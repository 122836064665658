<div class="main-content overflow-hidden flex h-full w-full flex-col pt-0">
    @if (!zoomedOnly()) {
        <div class="flex relative h-16 min-h-16 bg-neutral-50 z-[101]">
            <button
                class="inline-flex basis-1/2 items-center justify-center"
                [class.inner-border-b-2-orange]="omniIngameService.omniViewMode === 'zoomed'"
                [class.opacity-60]="omniIngameService.omniViewMode !== 'zoomed'"
                (click)="omniIngameService.changeOmniView('zoomed')">
                <div
                    class="font-bebas-neue text-xl text-black"
                    [translate]="'OMNI_ZOOMED_VIEW'"
                    i18n="@@OMNI_ZOOMED_VIEW">
                    Zoomed
                </div>
            </button>
            <!-- <button
                class="inline-flex basis-1/2 items-center justify-center"
                [class.inner-border-b-2-orange]="omniIngameService.omniViewMode === 'heatmap'"
                [class.opacity-60]="omniIngameService.omniViewMode !== 'heatmap'"
                (click)="omniIngameService.changeOmniView('heatmap')">
                <div class="font-bebas-neue text-xl text-black" [translate]="'HEATMAP'" i18n="@@HEATMAP">Heatmap</div>
            </button> -->
            <button
                class="inline-flex basis-1/2 items-center justify-center"
                [class.inner-border-b-2-orange]="omniIngameService.omniViewMode === 'board'"
                [class.opacity-60]="omniIngameService.omniViewMode !== 'board'"
                (click)="omniIngameService.changeOmniView('board')">
                <div
                    class="font-bebas-neue text-xl text-black"
                    [translate]="'OMNI_BOARD_VIEW'"
                    i18n="@@OMNI_BOARD_VIEW">
                    Full board
                </div>
            </button>
            <button class="inline-flex items-center justify-center px-4" (click)="openOmniSettings()">
                <app-icon class="text-black" icon="settings" size="medium"></app-icon>
            </button>
        </div>
    }

    <div class="flex flex-col relative h-full">
        @if (omniIngameService.currentSingleDartEvent) {
            <div
                class="!text-white absolute animate__animated animate__slideInDown z-[100] bg-orange top-0 p-3 w-full mx-auto flex flex-col items-center justify-center bg-opacity-95">
                <div class="flex flex-col items-center">
                    <div class="text-center text-medium font-bold">
                        @if (omniIngameService.currentSingleDartEvent === 'RemoveDarts') {
                            <span [translate]="'REMOVE_YOUR_DARTS'" i18n="@@REMOVE_YOUR_DARTS">Remove your darts </span>
                        } @else if (omniIngameService.currentSingleDartEvent === 'BustedScore') {
                            <span [translate]="'BUSTED_SCORE_REMOVE_DARTS'" i18n="@@BUSTED_SCORE_REMOVE_DARTS"
                                >You have bust your score, remove your darts
                            </span>
                        } @else if (omniIngameService.currentSingleDartEvent === 'DoubleOutError') {
                            <span [translate]="'NEED_END_WITH_DOUBLE'" i18n="@@NEED_END_WITH_DOUBLE"
                                >You need to finish with a double, remove your darts
                            </span>
                        } @else if (omniIngameService.currentSingleDartEvent === 'MasterOutError') {
                            <span [translate]="'NEED_END_WITH_DOUBLE_OR_TRIPLE'" i18n="@@NEED_END_WITH_DOUBLE_OR_TRIPLE"
                                >You need to finish with a double or triple, remove your darts
                            </span>
                        }
                    </div>
                </div>
            </div>
        }

        <div
            class="flex zoomed-points w-full mt-4"
            [hidden]="omniIngameService.omniViewMode !== 'zoomed' && !zoomedOnly()">
            @for (hit of omniIngameService.dartHits$ | async; track $index) {
                <app-zoomed-point
                    [turnEnded]="omniIngameService.currentSingleDartEvent !== null"
                    [editable]="true"
                    [point]="hit"
                    [dartIndex]="$index">
                </app-zoomed-point>
            }
        </div>
        @if (omniIngameService.omniViewMode === 'board' && !zoomedOnly()) {
            <div class="flex h-full w-full p-6 overflow-y-auto">
                <app-omni-dartboard #omniCoordinateDartboard class="h-full w-full"></app-omni-dartboard>
            </div>
        }
        <!-- @else if (omniIngameService.omniViewMode === 'heatmap' && !zoomedOnly()) {
            <div class="flex h-full w-full p-6">
                <app-omni-heatmap #omniHeatmapDartboard class="h-full w-full"></app-omni-heatmap>
            </div>
        } -->
    </div>

    <!-- <div class="dartboard-container mb-6 flex justify-center items-center w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3"> -->
    <!-- </div> -->
</div>
