import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-provide-password-dialog',
    templateUrl: 'provide-password.dialog.html',
})
export class ProvidePasswordDialogComponent {
    email: string;
    emailText: string;

    password: string;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute,
        public navParams: NavParams,
        private translate: TranslateService
    ) {
        this.email = this.navParams.get('email');

        $localize`:@@ACCOUNT_WITH_EMAIL_FOUND:An account with email ${this.email}:EMAIL: has been found`;
        this.emailText = this.translate.instant('ACCOUNT_WITH_EMAIL_FOUND', { EMAIL: this.email });
    }

    public confirm(): void {
        this.view.dismiss(this.password);
    }

    public dismiss(): void {
        this.view.dismiss(null);
    }
}
