<div
    class="flex items-center justify-between bg-blue pt-3 px-safe-offset-4 cursor-pointer"
    [class.pb-safe-offset-3]="applyBottomOffset()"
    [class.pb-3]="!applyBottomOffset()"
    (click)="openUpgradeDialog()">
    <div class="flex flex-col">
        <div class="flex items-center space-x-1 font-bold">
            @if (trialStatus()) {
                <div class="font-bebas-neue text-2xl !text-white">{{ 3 - (trialStatus()?.games_played ?? 0) }}</div>

                @if (trialStatus()?.game_mode) {
                    <div class="!text-white" [translate]="'FREE_GAMES_LEFT'" i18n="@@FREE_GAMES_LEFT">
                        FREE games left!
                    </div>
                } @else {
                    <div
                        class="!text-white"
                        [translate]="'FREE_GAMES_LEFT_THIS_WEEK'"
                        i18n="@@FREE_GAMES_LEFT_THIS_WEEK">
                        FREE games left this week!
                    </div>
                }
            }
        </div>
        <div class="flex flex-wrap items-center gap-1 text-sm">
            <div class="font-semibold !text-white" [translate]="'GET_VERB'" i18n="@@GET_VERB">Get</div>

            <div
                class="rounded-md bg-yellow-icon-alert px-1 py-1 text-xs leading-none font-bold uppercase text-black-header"
                [translate]="'ULTIMATE'"
                i18n="@@ULTIMATE">
                Ultimate
            </div>
            <div class="font-semibold !text-white" [translate]="'FOR_UNLIMITED_GAMES'" i18n="@@FOR_UNLIMITED_GAMES">
                for unlimited games
            </div>
        </div>
    </div>
    <app-icon icon="keyboard_arrow_right" class="!text-white"></app-icon>
</div>
