<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'PROFILE_PRIVACY'" i18n="@@PROFILE_PRIVACY">Profile privacy</div>
    </div>

    <div class="slide-modal-content text-black">
        <div class="flex justify-center gap-4 my-4">
            <button class="space-y-2 basis-1/3 cursor-pointer" (click)="changePrivacy('public')">
                <img
                    class="rounded-lg"
                    [src]="
                        currentTheme() === 'dark'
                            ? 'assets/images/privacy-settings/public_dark.webp'
                            : 'assets/images/privacy-settings/public_light.webp'
                    " />
                <div class="flex items-center mb-4 cursor-pointer">
                    <div
                        class="w-5 h-5 rounded-full border-2 border-neutral-400 flex items-center justify-center bg-white mr-2">
                        @if (!privacySetting || privacySetting === 'public') {
                            <div class="w-2 h-2 bg-orange rounded-full"></div>
                        }
                    </div>
                    <label
                        class="text-sm"
                        [class.font-semibold]="!privacySetting || privacySetting === 'public'"
                        [translate]="'PUBLIC'"
                        i18n="@@PUBLIC"
                        >Public</label
                    >
                </div>
            </button>

            <button class="space-y-2 basis-1/3 cursor-pointer" (click)="changePrivacy('friends_only')">
                <img
                    class="rounded-lg"
                    [src]="
                        currentTheme() === 'dark'
                            ? 'assets/images/privacy-settings/friends_only_dark.webp'
                            : 'assets/images/privacy-settings/friends_only_light.webp'
                    " />
                <div class="flex items-center mb-4 cursor-pointer">
                    <div
                        class="w-5 h-5 rounded-full border-2 border-neutral-400 flex items-center justify-center bg-white mr-2">
                        @if (privacySetting === 'friends_only') {
                            <div class="w-2 h-2 bg-orange rounded-full"></div>
                        }
                    </div>
                    <label
                        class="text-sm"
                        [class.font-semibold]="privacySetting === 'friends_only'"
                        [translate]="'FRIENDS_ONLY'"
                        i18n="@@FRIENDS_ONLY"
                        >Friends-only</label
                    >
                </div>
            </button>
        </div>
        <app-primary-button
            addedClasses="w-full"
            size="medium"
            color="orange"
            (click)="SavePrivacySetting()"
            [translate]="'SAVE'"
            i18n="@@SAVE">
            Save
        </app-primary-button>
    </div>
</div>
