<div class="popover-container">
    @if (friendStatus === 'no_friends') {
        <div class="popover-button" (click)="ClosePopover('add')" [translate]="'ADD_FRIEND'" i18n="@@ADD_FRIEND">
            Add friend
        </div>
    } @else if (friendStatus === 'pending') {
        <div class="popover-button !cursor-default" [translate]="'PENDING'" i18n="@@PENDING">Pending</div>
    } @else if (friendStatus === 'incoming') {
        <div
            class="popover-button"
            (click)="ClosePopover('accept')"
            [translate]="'ACCEPT_FRIEND'"
            i18n="@@ACCEPT_FRIEND">
            Accept friend
        </div>
        <div
            class="popover-button"
            (click)="ClosePopover('decline')"
            [translate]="'DECLINE_FRIEND'"
            i18n="@@DECLINE_FRIEND">
            Decline friend
        </div>
    } @else if (friendStatus === 'friends') {
        <div
            class="popover-button"
            (click)="ClosePopover('remove')"
            [translate]="'REMOVE_FRIEND'"
            i18n="@@REMOVE_FRIEND">
            Remove friend
        </div>
    }

    @if (isBlocked) {
        <div
            class="popover-button"
            (click)="ClosePopover('unblock')"
            [translate]="'UNBLOCK_PLAYER'"
            i18n="@@UNBLOCK_PLAYER">
            Unblock player
        </div>
    } @else {
        <div class="popover-button" (click)="ClosePopover('block')" [translate]="'BLOCK_PLAYER'" i18n="@@BLOCK_PLAYER">
            Block player
        </div>
    }

    <div class="popover-button" (click)="ClosePopover('report')" [translate]="'REPORT_PLAYER'" i18n="@@REPORT_PLAYER">
        Report player
    </div>
</div>
