<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog flex flex-col overflow-y-auto">
        <div class="title-container">
            <div class="title" [translate]="'SOMEONE_CHALLENGED_YOU'" i18n="@@SOMEONE_CHALLENGED_YOU">
                Someone in the lobby wants to join your game!
            </div>
        </div>
        <div class="mt-8 flex w-full flex-col" *ngIf="gameplay && gameplay.players[0] && gameplay.players[1]">
            <div class="flex items-center justify-between rounded-t-md bg-neutral-50 dark:bg-black-full px-4 py-2.5">
                <div class="font-bold text-black">{{ onlineFunctions.onlineGameplayName(gameplay) }}</div>
                <div class="flex justify-center space-x-2" *ngIf="gameplay.players[0].has_omni">
                    <div class="flex items-center rounded-sm bg-black-full p-1.5">
                        <img class="ml-0.5 w-[2.75rem]" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                    </div>
                </div>
                <div class="flex justify-center space-x-2" *ngIf="gameplay.players[0].room">
                    <div class="flex items-center rounded-sm bg-panel dark:bg-black text-black p-1.5">
                        <app-icon icon="videocam" size="small"></app-icon>
                        <img
                            class="ml-0.5 w-[2.75rem]"
                            [src]="'assets/icon/ftr/virt_cam_logo.webp'"
                            *ngIf="gameplay.players[0].room.camType === 'smart_device'" />
                        <div
                            class="ml-1 text-xxxs font-bold uppercase leading-none"
                            [translate]="'CAM_ONLY'"
                            i18n="@@CAM_ONLY"
                            *ngIf="gameplay.cameraOnly">
                            Cam only
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="relative flex items-center justify-between rounded-b-md bg-neutral-100 px-4 py-2 overflow-hidden">
                <div class="flex items-center justify-start space-x-2 overflow-hidden basis-[45%]">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="gameplay.players[0].is_ultimate"
                        [badge]="gameplay.players[0].badge"
                        [url]="gameplay.players[0].profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div class="space-y-0.5 overflow-hidden">
                        <div class="flex items-center space-x-1">
                            <div class="truncate text-xs font-bold text-black">
                                {{ gameplay.players[0].first_name || ('NO_NAME' | translate) }}
                            </div>
                        </div>
                        <app-online-player-stats [player]="gameplay.players[0]"></app-online-player-stats>
                    </div>
                </div>

                <div
                    class="absolute font-bebas-neue leading-none text-black"
                    style="left: 50%; transform: translateX(-50%)">
                    VS
                </div>

                <div class="flex items-center justify-end space-x-2 overflow-hidden basis-[45%]">
                    <div class="space-y-0.5 overflow-hidden">
                        <div class="flex items-center justify-end space-x-1">
                            <div class="truncate text-xs font-bold text-black">
                                {{ gameplay.players[1].first_name || ('NO_NAME' | translate) }}
                            </div>
                        </div>
                        <app-online-player-stats [player]="gameplay.players[1]"></app-online-player-stats>
                    </div>
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="gameplay.players[1].is_ultimate"
                        [badge]="gameplay.players[1].badge"
                        [url]="gameplay.players[1].profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                </div>
            </div>
        </div>

        @if (userMedia?.videoStreams.board) {
            <div class="relative flex mx-auto mt-4 max-w-xs overflow-hidden h-full min-h-24">
                <video
                    class="ingame-camera w-full"
                    [srcObject]="userMedia?.videoStreams.board.stream"
                    [style.transform]="userMedia?.videoStreams.board.scale"
                    autoplay
                    playsinline
                    webkit-playsinline
                    muted></video>
                <div
                    class="absolute left-4 top-4 h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center">
                    <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                </div>
            </div>
        }

        <div class="button-container flex justify-between space-x-2">
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="red"
                (click)="decline()"
                [translate]="'DECLINE'"
                i18n="@@DECLINE">
                Decline
            </app-primary-button>
            <app-primary-button
                size="normal"
                addedClasses="px-1"
                color="green"
                (click)="accept()"
                [translate]="'ACCEPT'"
                i18n="@@ACCEPT">
                Accept
            </app-primary-button>
        </div>
    </div>
</modal-content>
