import { registerPlugin } from '@capacitor/core';

import type { CapacitorNativeAudioPlugin } from './definitions';

const CapacitorNativeAudio = registerPlugin<CapacitorNativeAudioPlugin>('CapacitorNativeAudio', {
  web: () => import('./web').then(m => new m.CapacitorNativeAudioWeb()),
});

export * from './definitions';
export { CapacitorNativeAudio };
