import { Component, Input } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { ModalController } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-complete-account-dialog',
    templateUrl: 'complete-account.dialog.html',
})
export class CompleteAccountDialogComponent {
    @Input() firstName = '';
    @Input() lastName = '';
    @Input() username = '';

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public loading: DCLoadingService,
        private _alertService: DartCounterAlertService,
        private userApiService: UserApiService
    ) {}

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    submit() {
        if (
            !this.firstName.replace(/\s/g, '').length ||
            !this.lastName.replace(/\s/g, '').length ||
            !this.username.replace(/\s/g, '').length
        ) {
            this._alertService.createAlert({
                title: $localize`:@@FILL_IN_REQUIRED_FIELDS:Please fill in the required fields`,
                icon: 'error',
            });
        } else {
            this.userApiService
                .updateUser({
                    first_name: this.firstName,
                    last_name: this.lastName,
                    username: this.username,
                })
                .then((res) => {
                    this.auth.saveAuthentication(res.data);

                    this.DismissModal(true);
                })
                .catch((err) => this._alertService.errorFromApi(err));
        }
    }
}
