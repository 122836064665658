import { Directive, ElementRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
    selector: '[appSmallWebContainer]',
    standalone: true,
})
export class SmallWebContainerDirective implements OnInit {
    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        if (environment.isWeb) {
            this.el.nativeElement.classList.add('w-full', 'max-w-lg', 'mx-auto');
        }
    }
}
