export const numbersInEnglish = (n) => {
    if (n === 0) return 'zero';
    if (n < 20) {
        return [
            'one',
            'two',
            'three',
            'four',
            'five',
            'six',
            'seven',
            'eight',
            'nine',
            'ten',
            'eleven',
            'twelve',
            'thirteen',
            'fourteen',
            'fifteen',
            'sixteen',
            'seventeen',
            'eighteen',
            'nineteen',
        ][n - 1];
    } else if (n < 100) {
        const tens = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
        return tens[Math.floor(n / 10) - 2] + (n % 10 ? ' ' + numbersInEnglish(n % 10) : '');
    } else {
        const remainder = n % 100;
        if (remainder === 0) return 'one hundred';
        return ['one hundred ' + numbersInEnglish(remainder), 'one hundred and ' + numbersInEnglish(remainder)];
    }
};

// Enhanced Dutch Numbers 1-180
export const numbersInDutch = (n) => {
    const base = [
        'een',
        'twee',
        'drie',
        'vier',
        'vijf',
        'zes',
        'zeven',
        'acht',
        'negen',
        'tien',
        'elf',
        'twaalf',
        'dertien',
        'veertien',
        'vijftien',
        'zestien',
        'zeventien',
        'achttien',
        'negentien',
    ];
    const tens = ['', '', 'twintig', 'dertig', 'veertig', 'vijftig', 'zestig', 'zeventig', 'tachtig', 'negentig'];

    if (n === 0) return 'nul';
    if (n < 20) return base[n - 1];
    if (n < 100) {
        const remainder = n % 10;
        return remainder === 0 ? tens[n / 10] : base[remainder - 1] + 'en' + tens[Math.floor(n / 10)];
    } else {
        const remainder = n % 100;
        if (remainder === 0) return 'honderd';
        return ['honderd ' + numbersInDutch(remainder), 'honderd en ' + numbersInDutch(remainder)];
    }
};

// Enhanced German Numbers 1-180
export const numbersInGerman = (n) => {
    const base = [
        'eins',
        'zwei',
        'drei',
        'vier',
        'fünf',
        'sechs',
        'sieben',
        'acht',
        'neun',
        'zehn',
        'elf',
        'zwölf',
        'dreizehn',
        'vierzehn',
        'fünfzehn',
        'sechzehn',
        'siebzehn',
        'achtzehn',
        'neunzehn',
    ];
    const tens = ['', '', 'zwanzig', 'dreißig', 'vierzig', 'fünfzig', 'sechzig', 'siebzig', 'achtzig', 'neunzig'];

    if (n === 0) return 'null';
    if (n < 20) return base[n - 1];
    if (n < 100) {
        const remainder = n % 10;
        return remainder === 0 ? tens[n / 10] : base[remainder - 1] + 'und' + tens[Math.floor(n / 10)];
    } else {
        const remainder = n % 100;
        if (remainder === 0) return 'hundert';
        return ['hundert ' + numbersInGerman(remainder), 'hundert und ' + numbersInGerman(remainder)];
    }
};

// Generate the grammars
const flatten = (arr) => arr.reduce((acc, val) => acc.concat(val instanceof Array ? flatten(val) : val), []);

export const NumbersGrammarEN = `#JSGF V1.0; grammar numbers; public <num> = ${flatten(
    Array.from({ length: 181 }, (_, i) => numbersInEnglish(i))
).join(' | ')};`;
export const NumbersGrammarNL = `#JSGF V1.0; grammar numbers; public <num> = ${flatten(
    Array.from({ length: 181 }, (_, i) => numbersInDutch(i))
).join(' | ')};`;
export const NumbersGrammarDE = `#JSGF V1.0; grammar numbers; public <num> = ${flatten(
    Array.from({ length: 181 }, (_, i) => numbersInGerman(i))
).join(' | ')};`;
