import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-option-button',
    standalone: true,
    templateUrl: 'option-button.component.html',
    imports: [CommonModule, IconComponent],
})
export class OptionButtonComponent implements OnInit {
    @Input() icon:
        | 'settings'
        | 'statistics'
        | 'add'
        | 'camera'
        | 'video_call'
        | 'help'
        | 'edit'
        | 'more_vert'
        | 'bin'
        | 'person_add'
        | 'trending_up'
        | 'videocam'
        | 'person_add'
        | 'close'
        | 'in_game_comms'
        | 'share';
    @Input() color: 'text-black' | 'text-white' = 'text-black';

    @Input() clickable = true;

    ngOnInit() {
        if (this.icon === 'statistics') {
            this.icon = 'trending_up';
        } else if (this.icon === 'camera') {
            this.icon = 'videocam';
        }
    }
}
