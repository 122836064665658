<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'INGAME_TIMER'" i18n="@@INGAME_TIMER">In-game timer</div>
    </div>
    <div class="slide-modal-content">
        <div class="subtitle" [translate]="'INGAME_TIMER_INFO'" i18n="@@INGAME_TIMER_INFO">
            How much time do you have to throw your darts and enter your score?
        </div>
        <form #form="ngForm" [formGroup]="timerForm" (ngSubmit)="timerForm.valid && save()">
            <div class="my-4">
                <input
                    [ngClass]="{
                        '!border-red': form.submitted && timerForm.get('timer').errors
                    }"
                    formControlName="timer"
                    type="number"
                    inputmode="numeric"
                    min="30"
                    max="80"
                    class="dialog-input" />
                @if (form.submitted && timerForm.get('timer').errors) {
                    @if (timerForm.get('timer').errors.required) {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span [translate]="'INGAME_TIMER_REQUIRED'" i18n="@@INGAME_TIMER_REQUIRED">
                                Ingame timer is required
                            </span>
                        </app-validation-label>
                    }
                    @if (timerForm.get('timer').errors.min || timerForm.get('timer').errors.max) {
                        <app-validation-label [textColor]="'black'" [noAbsolute]="true">
                            <span
                                [translate]="'INGAME_TIMER_MUST_BE_BETWEEN_30_80'"
                                i18n="@@INGAME_TIMER_MUST_BE_BETWEEN_30_80">
                                Ingame timer must be between 30 and 80 seconds
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
            <div class="button-container">
                <app-primary-button
                    type="submit"
                    size="normal"
                    addedClasses="w-full"
                    color="orange"
                    [translate]="'CONFIRM'"
                    i18n="@@CONFIRM">
                    Confirm
                </app-primary-button>
            </div>
        </form>
    </div>
</div>
