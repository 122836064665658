import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input, InputSignal, OnInit, Signal } from '@angular/core';
import { UserNotificationApiService } from '@dc-api/user-notification.api.service';
import { NotificationCategory } from '@dc-core/dc-backend/dc-enums';
import { IonicModule } from '@ionic/angular';
import { NotificationsService } from '@services/notifications.service';

import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-notifications-icon',
    templateUrl: 'notifications-icon.component.html',
    standalone: true,
    imports: [IonicModule, IconComponent, CommonModule],
})
export class NotificationsIconComponent implements OnInit {
    public category: InputSignal<NotificationCategory | null> = input.required();

    private notificationsService: NotificationsService = inject(NotificationsService);
    private userNotificationApiService: UserNotificationApiService = inject(UserNotificationApiService);

    public showNotifications: Signal<boolean> = computed(() => this.notificationsService.showNotifications());
    public newNotifications: Signal<number> = computed(() => this.notificationsService.newNotifications());

    constructor() {
        effect(
            () => {
                const newNotification = this.userNotificationApiService.newNotification();
                if (newNotification) {
                    if (!this.category() || newNotification.category === this.category()) {
                        if (this.showNotifications()) {
                            this.notificationsService.notifications.update((notifications) => {
                                notifications.unshift(newNotification);
                                return notifications;
                            });
                            this.userNotificationApiService.newNotification.set(null);
                            this.userNotificationApiService.markUserNotificationsAsSeen({
                                category: newNotification.category,
                                notification_id: newNotification.id,
                            });
                        } else {
                            this.notificationsService.newNotifications.update((value) => value + 1);
                        }
                    }
                } else {
                    this.notificationsService.newNotifications.set(0);
                }
            },
            { allowSignalWrites: true }
        );
    }

    public ngOnInit(): void {
        this.userNotificationApiService.getUnseenUserNotificationsCount({ category: this.category() }).then((res) => {
            this.notificationsService.newNotifications.set(res.data.unseen_count);
        });
    }

    public toggleNotifications(): void {
        this.notificationsService.toggleNotifications(this.category());
    }
}
