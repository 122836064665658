<div class="bottom-nav-container" [ngClass]="{ 'lg:hidden': isWeb }" id="bottom-nav">
    <div
        class="nav-option"
        [class.icon-only]="!showTitles"
        [class.border-t-2]="active === 'dashboard'"
        [class.!border-orange]="active === 'dashboard'"
        [class.!text-orange]="active === 'dashboard'"
        (click)="goToDashboard()">
        <app-icon icon="dashboard" [size]="showTitles ? 'normal' : 'large'"></app-icon>

        @if (showTitles) {
            <div
                class="nav-text"
                [class.font-semibold]="active !== 'dashboard'"
                [class.font-bold]="active === 'dashboard'"
                [translate]="'HOME'"
                i18n="@@HOME">
                Home
            </div>
        }
    </div>

    <div
        class="nav-option"
        [class.icon-only]="!showTitles"
        [class.border-t-2]="active === 'local'"
        [class.!border-orange]="active === 'local'"
        [class.!text-orange]="active === 'local'"
        (click)="goToLocalGames()">
        <app-icon icon="dartboard" [size]="showTitles ? 'normal' : 'large'"></app-icon>

        @if (showTitles) {
            <div
                class="nav-text"
                [class.font-semibold]="active !== 'local'"
                [class.font-bold]="active === 'local'"
                [translate]="'LOCAL'"
                i18n="@@LOCAL">
                Local
            </div>
        }
    </div>

    <div
        class="nav-option"
        [class.icon-only]="!showTitles"
        [class.border-t-2]="active === 'online'"
        [class.!border-orange]="active === 'online'"
        [class.!text-orange]="active === 'online'"
        (click)="goToOnlineGames()">
        <app-icon icon="language" [size]="showTitles ? 'normal' : 'large'"></app-icon>

        @if (showTitles) {
            <div
                class="nav-text"
                [class.font-semibold]="active !== 'online'"
                [class.font-bold]="active === 'online'"
                [translate]="'ONLINE'"
                i18n="@@ONLINE">
                Online
            </div>
        }
    </div>

    @if (appFeaturesService.enabledAppFeatures().tournaments) {
        <div
            class="nav-option"
            [class.icon-only]="!showTitles"
            [class.border-t-2]="active === 'tournaments'"
            [class.!border-orange]="active === 'tournaments'"
            [class.!text-orange]="active === 'tournaments'"
            (click)="goToTournaments()">
            <app-icon icon="tournaments" [size]="showTitles ? 'normal' : 'large'"></app-icon>

            @if (showTitles) {
                <div
                    class="nav-text"
                    [class.font-semibold]="active !== 'tournaments'"
                    [class.font-bold]="active === 'tournaments'"
                    [translate]="'TOURNAMENTS'"
                    i18n="@@TOURNAMENTS">
                    Tournaments
                </div>
            }
        </div>
    }

    <div
        class="nav-option"
        [class.icon-only]="!showTitles"
        [class.border-t-2]="active === 'statistics'"
        [class.!border-orange]="active === 'statistics'"
        [class.!text-orange]="active === 'statistics'"
        (click)="goToStatistics()">
        <app-icon icon="trending_up" [size]="showTitles ? 'normal' : 'large'"></app-icon>

        @if (showTitles) {
            <div
                class="nav-text"
                [class.font-semibold]="active !== 'statistics'"
                [class.font-bold]="active === 'statistics'"
                [translate]="'STATISTICS'"
                i18n="@@STATISTICS">
                Statistics
            </div>
        }
    </div>

    @if (!appFeaturesService.enabledAppFeatures().tournaments) {
        <div
            class="nav-option"
            [class.icon-only]="!showTitles"
            [class.border-t-2]="active === 'profile'"
            [class.!border-orange]="active === 'profile'"
            [class.!text-orange]="active === 'profile'"
            (click)="goToProfile()">
            <app-icon icon="person" [size]="showTitles ? 'normal' : 'large'"></app-icon>

            @if (showTitles) {
                <div
                    class="nav-text"
                    [class.font-semibold]="active !== 'profile'"
                    [class.font-bold]="active === 'profile'"
                    [translate]="'PROFILE'"
                    i18n="@@PROFILE">
                    Profile
                </div>
            }
        </div>
    }

    @if (appFeaturesService.enabledAppFeatures().private_chat) {
        <div
            class="nav-option"
            [class.icon-only]="!showTitles"
            [class.border-t-2]="active === 'chats'"
            [class.!border-orange]="active === 'chats'"
            [class.!text-orange]="active === 'chats'"
            (click)="goToChats()">
            <div class="flex items-center justify-center space-x-1">
                <app-icon icon="chat_bubble" [size]="showTitles ? 'normal' : 'large'"></app-icon>
                <ion-badge
                    mode="md"
                    class="ion-bg-yellow !ion-text-black"
                    *ngIf="privateChatApiService.unreadCount() > 0">
                    {{ privateChatApiService.unreadCount() }}
                </ion-badge>
            </div>

            @if (showTitles) {
                <div
                    class="nav-text"
                    [class.font-semibold]="active !== 'chats'"
                    [class.font-bold]="active === 'chats'"
                    [translate]="'CHATS'"
                    i18n="@@CHATS">
                    Chats
                </div>
            }
        </div>
    }
</div>
