<div class="flex h-full flex-col overflow-hidden items-center justify-center !bg-gray-pattern !text-white py-safe">
    <div class="absolute top-0 left-0 right-0 z-50 flex-none space-y-3 mb-4 pt-safe-offset-4 px-4 flex flex-col">
        <!-- Progress indicators -->
        <div class="w-full flex h-[0.3rem] gap-2">
            @for (screen of screens(); track $index) {
                <div class="flex-1 h-full !bg-gray-400 rounded-full relative overflow-hidden">
                    <!-- Completed and Active Screens -->
                    <div
                        class="h-full !bg-white absolute inset-0 transition-all ease-linear"
                        [ngStyle]="{ width: screen.progress + '%' }"></div>
                </div>
            }
        </div>

        <!-- Action buttons -->
        <div class="flex justify-end items-center space-x-2">
            @if (screenIndex() < this.screens().length - 1) {
                <button class="option-button-wrapper" (click)="togglePause()">
                    <app-icon
                        [icon]="isPaused() ? 'play_arrow' : 'pause'"
                        class="!text-white cursor-pointer"></app-icon>
                </button>
            }
            <button class="option-button-wrapper" (click)="toggleAudio()">
                <app-icon
                    [icon]="allowAudio ? 'volume_high' : 'volume_mute'"
                    size="medium"
                    class="!text-white cursor-pointer"></app-icon>
            </button>
            <button class="option-button-wrapper" (click)="dismiss()">
                <app-icon icon="close" class="!text-white cursor-pointer"></app-icon>
            </button>
        </div>
    </div>

    <div
        (click)="onTap($event)"
        class="flex flex-1 flex-col justify-center w-full relative overflow-hidden"
        id="tap-container">
        @if (recap()) {
            <!-- Screen 1: Your 2024 screen with logo -->
            @if (screenIndex() === 0) {
                <!-- Top Text Section -->
                <div class="flex items-end justify-center h-1/4 pb-6 pt-8">
                    <div
                        class="opacity-0"
                        [ngClass]="{ 'animate-slideup-in': isTextAnimating(), 'animate-slideup-out': isTextFading }">
                        <div class="font-bebas-neue text-7xl md:text-8xl font-bold" [translate]="'YOUR'" i18n="@@YOUR">
                            Your
                        </div>
                    </div>
                </div>

                <!-- Orange Circle Section -->
                <div class="flex items-center justify-center h-1/2" id="circle-container">
                    @if (useHeightForOrangeCircle() !== null) {
                        <div
                            class="flex circle aspect-[1/1] bg-orange items-center justify-center rounded-full relative"
                            [class]="useHeightForOrangeCircle() ? 'h-full' : 'w-3/4'"
                            [ngClass]="{ 'animate-grow-shrink': animateOrangeCircle }">
                            <img
                                [ngClass]="{ 'scale-0': isTextFading }"
                                class="transition-transform duration-400"
                                [class]="useHeightForOrangeCircle() ? 'h-[35%]' : 'w-[70%]'"
                                src="assets/images/recap/dc_logo.png" />
                        </div>
                    }
                </div>

                <!-- Bottom Text Section -->
                <div class="flex items-start justify-center h-1/4 pt-6">
                    <div
                        class="opacity-0 font-bebas-neue"
                        [ngClass]="{
                            'animate-slidedown-in': isTextAnimating(),
                            'animate-slidedown-out': isTextFading
                        }">
                        <div class="font-bebas-neue text-7xl md:text-8xl font-bold">{{ recap().year }}</div>
                    </div>
                </div>
            } @else if (screenIndex() <= 2) {
                <!-- Screen 2 and Screen 3 -->
                <!-- Top Section -->
                <div
                    class="flex items-center justify-center transition-all duration-1000 overflow-hidden pt-8"
                    [ngClass]="{ 'h-0': isCircleMoving, 'h-1/4': !isCircleMoving }">
                    @if (screenIndex() === 1) {
                        <div
                            class="text-center opacity-0"
                            [ngClass]="{
                                'animate-slideup-in': isTextAnimating(),
                                'animate-slideup-out': isTextFading
                            }">
                            <div
                                class="font-bebas-neue text-6xl md:text-7xl"
                                [translate]="'YOU_HAVE_THROWN_1'"
                                i18n="@@YOU_HAVE_THROWN_1">
                                You've thrown
                            </div>
                        </div>
                    }
                </div>

                <!-- Orange Circle Section -->
                <div
                    class="flex items-center justify-center relative transition-all h-1/2 duration-1000"
                    [ngClass]="{
                        'translate-x-[-100%] translate-y-[-100%] scale-50': isCircleExiting
                    }">
                    <div
                        class="circle aspect-[1/1] bg-orange rounded-full flex items-center justify-center"
                        [class]="useHeightForOrangeCircle() ? 'h-full' : 'w-3/4'">
                        <div
                            class="absolute w-[20%] right-[20%] opacity-0 z-10"
                            [ngClass]="{ 'animate-dart1-animation': dartsAnimating >= 1 }">
                            <img src="assets/images/recap/littler_dart.png" />
                        </div>

                        <div
                            class="absolute w-[20%] mt-[45px] right-[13%] opacity-0 z-10"
                            [ngClass]="{ 'animate-dart2-animation': dartsAnimating >= 2 }">
                            <img src="assets/images/recap/littler_dart.png" />
                        </div>
                        @if (screenIndex() === 1) {
                            <div
                                class="text-center opacity-0"
                                [ngClass]="{ 'animate-fade-in': isTextAnimating(), 'opacity-0': isTextFading }">
                                <div
                                    class="font-bebas-neue font-bold"
                                    [ngClass]="{
                                        'text-6xl md:text-7xl': recap().total_darts_thrown >= 10000000,
                                        'text-7xl md:text-8xl':
                                            recap().total_darts_thrown >= 100000 &&
                                            recap().total_darts_thrown < 10000000,
                                        'text-8xl md:text-9xl': recap().total_darts_thrown < 100000
                                    }">
                                    {{ recap().total_darts_thrown | number: '' : 'nl-NL' }}
                                </div>
                            </div>
                        }
                        @if (screenIndex() === 2) {
                            <div
                                class="text-center opacity-0"
                                [ngClass]="{ 'animate-fade-in': isTextAnimating(), 'opacity-0': isTextFading }">
                                <div
                                    class="font-bebas-neue font-bold"
                                    [ngClass]="{
                                        'text-6xl md:text-7xl': recap().total_matches >= 10000000,
                                        'text-7xl md:text-8xl':
                                            recap().total_matches >= 100000 && recap().total_matches < 10000000,
                                        'text-8xl md:text-9xl': recap().total_matches < 100000
                                    }">
                                    {{ recap().total_matches | number: '' : 'nl-NL' }}
                                </div>
                            </div>

                            <!-- Last dart -->
                            <div
                                class="absolute w-[20%] mt-[90px] right-[6%] opacity-0 z-10"
                                [ngClass]="{ 'animate-dart3-animation': dartsAnimating >= 3 }">
                                <img src="assets/images/recap/littler_dart.png" />
                            </div>
                        }
                    </div>
                </div>

                <!-- Bottom Section -->
                <div
                    class="flex flex-col items-center justify-evenly transition-all duration-1000"
                    [ngClass]="{ 'h-1/2': isCircleMoving, 'h-1/4': !isCircleMoving }">
                    @if (screenIndex() === 1) {
                        <div
                            class="text-center opacity-0"
                            [ngClass]="{
                                'animate-slidedown-in': isTextAnimating(),
                                'animate-slidedown-out': isTextFading
                            }">
                            <div
                                class="font-bebas-neue text-6xl md:text-7xl"
                                [translate]="'YOU_HAVE_THROWN_2'"
                                i18n="@@YOU_HAVE_THROWN_2">
                                Darts
                            </div>
                        </div>
                    } @else if (screenIndex() === 2) {
                        <div
                            class="text-center opacity-0"
                            [ngClass]="{
                                'animate-slidedown-in': isTextAnimating(),
                                'animate-slidedown-out': isTextFading
                            }">
                            <div
                                class="font-bebas-neue text-6xl md:text-7xl"
                                [translate]="'GAMES_PLAYED'"
                                i18n="@@GAMES_PLAYED">
                                Games played
                            </div>
                        </div>
                        <div
                            class="text-center opacity-0"
                            [ngClass]="{
                                'animate-slidedown-in': isTextAnimating(),
                                'animate-slidedown-out': isTextFading
                            }">
                            <div class="text-xl md:text-2xl text-center px-8">
                                {{ rankMessage(recap().total_matches_percentage) }}
                            </div>
                        </div>
                    }
                </div>
            } @else if (screenIndex() === 3 || screenIndex() === 4) {
                <!-- Screen 4 -->
                <div class="h-full w-full rounded-2xl flex-1 flex items-center justify-center relative overflow-hidden">
                    <div #omniDartboardWrapper class="absolute inset-0 w-full h-full">
                        <!-- SVG will load here -->
                    </div>

                    <div class="absolute top-0 left-0 right-0 bottom-0 flex flex-col">
                        @if (screenIndex() === 3) {
                            <div
                                class="basis-1/2 flex flex-col justify-center items-center opacity-0"
                                [ngClass]="{
                                    'animate-slidedown-in': isTextAnimating(),
                                    'animate-slideup-out': isTextFading
                                }">
                                <div class="font-bebas-neue text-4xl md:text-5xl text-center w-1/2">
                                    {{ dartboardTexts.thrownLegs }}
                                </div>
                                <div class="font-bebas-neue text-4xl md:text-5xl text-center w-1/2 mt-8">
                                    {{ dartboardTexts.wonLegs }}
                                </div>
                            </div>

                            <div
                                class="basis-1/2 flex flex-col justify-center items-center opacity-0 pt-[10%]"
                                [ngClass]="{
                                    'animate-slideup-in': isTextAnimating(),
                                    'animate-slidedown-out': isTextFading
                                }">
                                <div class="font-bebas-neue text-5xl md:text-6xl text-center w-[25%]">
                                    {{ dartboardTexts.legsPercentage }}
                                </div>
                            </div>
                        } @else if (screenIndex() === 4) {
                            <div
                                class="basis-1/2 flex flex-col justify-center items-center opacity-0 !text-black"
                                [ngClass]="{
                                    'animate-slidedown-in': isTextAnimating(),
                                    'animate-slideup-out': isTextFading
                                }">
                                <div class="font-bebas-neue text-5xl md:text-6xl text-center w-1/2">
                                    {{ dartboardTexts.average }}
                                </div>
                            </div>

                            @if (dartboardTexts.averagePercentage) {
                                <div
                                    class="basis-1/2 flex flex-col justify-center items-center opacity-0 pt-[10%] !text-black"
                                    [ngClass]="{
                                        'animate-slideup-in': isTextAnimating(),
                                        'animate-slidedown-out': isTextFading
                                    }">
                                    <div
                                        class="font-bebas-neue text-[2rem] leading-[2.375rem] md:text-4xl text-center w-[25%]">
                                        {{ dartboardTexts.averagePercentage }}
                                    </div>
                                </div>
                            }
                        }
                    </div>
                </div>
            } @else if (screenIndex() === 5) {
                <!-- Final screen -->
                <div class="flex flex-col flex-1 px-5 pt-[5rem] pb-5 overflow-x-hidden overflow-y-auto">
                    @if (slideInLastSlide()[0]) {
                        <div
                            class="flex justify-between md:justify-evenly items-center border-b-2 !border-neutral-700 mb-5 pb-5"
                            [ngClass]="{ 'animate__animated animate__fadeInDown': isTextAnimating() }">
                            <div
                                class="flex justify-center items-center space-x-2"
                                [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                <div class="font-bebas-neue text-6xl md:text-7xl font-bold">
                                    {{ recap().year }}
                                </div>
                                <div
                                    class="font-bebas-neue text-6xl md:text-7xl font-bold !text-orange"
                                    [translate]="'RECAP'"
                                    i18n="@@RECAP">
                                    Recap
                                </div>
                            </div>

                            <app-profile-photo
                                class="block h-fit"
                                [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }"
                                [size]="56"
                                [ultimate]="authService.user.is_ultimate"
                                [badge]="authService.user.profile?.badge"
                                [big]="true"
                                [url]="authService.user.profile?.profile_photo_url">
                            </app-profile-photo>
                        </div>

                        <div class="flex flex-col space-y-4 md:space-y-5 font-bebas-neue text-3xl md:text-4xl">
                            @for (slideIn of slideInLastSlide(); track $index) {
                                @if (slideIn && $index === 1) {
                                    <div class="grid grid-cols-5 gap-x-4 items-center">
                                        <div
                                            class="col-span-2 text-orange text-right"
                                            [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                            {{ recap().total_darts_thrown | number: '' : 'nl-NL' }}
                                        </div>
                                        <div
                                            class="col-span-3 whitespace-nowrap"
                                            [translate]="'DARTS_THROWN'"
                                            i18n="@@DARTS_THROWN"
                                            [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }">
                                            Darts thrown
                                        </div>
                                    </div>
                                }
                                @if (slideIn && $index === 2) {
                                    <div class="grid grid-cols-5 gap-x-4 items-center">
                                        <div
                                            class="col-span-2 text-orange text-right"
                                            [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                            {{ recap().total_matches | number: '' : 'nl-NL' }}
                                        </div>
                                        <div
                                            class="col-span-3 whitespace-nowrap"
                                            [translate]="'MATCHES_PLAYED'"
                                            i18n="@@MATCHES_PLAYED"
                                            [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }">
                                            Matches played
                                        </div>
                                    </div>
                                }
                                @if (slideIn && $index === 3) {
                                    <div class="grid grid-cols-5 gap-x-4 items-center">
                                        <div
                                            class="col-span-2 text-orange text-right"
                                            [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                            {{ recap().three_dart_average || 0 | number: '1.2-2' }}
                                        </div>
                                        <div
                                            class="col-span-3 whitespace-nowrap"
                                            [translate]="'AVERAGE'"
                                            i18n="@@AVERAGE"
                                            [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }">
                                            3-dart avg.
                                        </div>
                                    </div>
                                }
                                @if (slideIn && $index === 4) {
                                    <div class="grid grid-cols-5 gap-x-4 items-center">
                                        <div
                                            class="col-span-2 text-orange text-right"
                                            [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                            {{ recap().best_three_dart_average || 0 | number: '1.2-2' }}
                                        </div>
                                        <div
                                            class="col-span-3 whitespace-nowrap"
                                            [translate]="'BEST_AVERAGE'"
                                            i18n="@@BEST_AVERAGE"
                                            [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }">
                                            Best average
                                        </div>
                                    </div>
                                }
                                @if (slideIn && $index === 5) {
                                    <div class="grid grid-cols-5 gap-x-4 items-center">
                                        <div
                                            class="col-span-2 text-orange text-right"
                                            [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                            @if (recap().least_darts_thrown_in_leg_501) {
                                                {{ recap().least_darts_thrown_in_leg_501 | number: '' : 'nl-NL' }}
                                                <span class="!font-bebas-neue" [translate]="'DARTS'" i18n="@@DARTS">
                                                    Darts
                                                </span>
                                            } @else {
                                                {{ '-' }}
                                            }
                                        </div>
                                        <div
                                            class="col-span-3 whitespace-nowrap"
                                            [translate]="'BEST_LEG'"
                                            i18n="@@BEST_LEG"
                                            [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }">
                                            Best leg
                                        </div>
                                    </div>
                                }
                                @if (slideIn && $index === 6) {
                                    <div class="grid grid-cols-5 gap-x-4 items-center">
                                        <div
                                            class="col-span-2 text-orange text-right"
                                            [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                            {{ recap().highest_checkout }}
                                        </div>
                                        <div
                                            class="col-span-3 whitespace-nowrap"
                                            [translate]="'HIGHEST_FINISH'"
                                            i18n="@@HIGHEST_FINISH"
                                            [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }">
                                            Highest finish
                                        </div>
                                    </div>
                                }
                                @if (slideIn && $index === 7) {
                                    <div class="grid grid-cols-5 gap-x-4 items-center">
                                        <div
                                            class="col-span-2 text-orange text-right"
                                            [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                            {{ recap().highest_score }}
                                        </div>
                                        <div
                                            class="col-span-3 whitespace-nowrap"
                                            [translate]="'HIGHEST_SCORE'"
                                            i18n="@@HIGHEST_SCORE"
                                            [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }">
                                            Highest score
                                        </div>
                                    </div>
                                }
                                @if (slideIn && $index === 8) {
                                    <div class="grid grid-cols-5 gap-x-4 items-center">
                                        <div
                                            class="col-span-2 text-orange text-right"
                                            [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }">
                                            {{ recap().amount_of_180s | number: '' : 'nl-NL' }}
                                        </div>
                                        <div
                                            class="col-span-3 whitespace-nowrap"
                                            [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }">
                                            180s
                                        </div>
                                    </div>
                                }
                            }
                        </div>
                    }

                    @if (slideInLastSlide()[9]) {
                        <div class="pt-8 mt-auto flex space-x-4 items-center flex-none" id="final-buttons">
                            <app-primary-button
                                [ngClass]="{ 'animate__animated animate__fadeInLeft': isTextAnimating() }"
                                fill="clear"
                                addedSlotClasses="whitespace-nowrap !text-white"
                                size="normal"
                                [translate]="'REFRESH'"
                                i18n="@@REFRESH"
                                (click)="refresh()">
                                Refresh
                            </app-primary-button>
                            <app-primary-button
                                [ngClass]="{ 'animate__animated animate__fadeInRight': isTextAnimating() }"
                                class="w-full"
                                color="orange"
                                addedClasses="w-full"
                                size="normal"
                                (click)="share()">
                                <app-icon icon="share" class="mr-1"></app-icon>
                                <div [translate]="'SHARE'" i18n="@@SHARE">Share</div>
                            </app-primary-button>
                        </div>
                    }
                </div>
            }
        }

        <!-- @if (!hideArrows() && screens()[screenIndex()].progress >= 100 && screenIndex() < screens().length - 1) {
            @if (screenIndex() !== 0) {
                <div class="absolute left-2 top-1/2 -translate-y-1/2 z-50" [@fade]>
                    <app-icon
                        icon="keyboard_arrow_left"
                        size="large"
                        class="!text-neutral-100 cursor-pointer animate-pulse"></app-icon>
                </div>
            }

            <div class="absolute right-2 top-1/2 -translate-y-1/2 z-50" [@fade]>
                <app-icon
                    icon="keyboard_arrow_right"
                    size="large"
                    class="!text-neutral-100 cursor-pointer animate-pulse"></app-icon>
            </div>
        } -->
    </div>
</div>
