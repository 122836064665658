<div class="pt-safe px-safe-offset-4 {{ classes }}" id="page-header">
    <div class="relative flex items-center h-[3.375rem] min-h-[3.375rem]">
        <div
            class="text-center text-sm font-bold cursor-pointer relative z-50"
            [ngClass]="{ 'lg:hidden': isWeb && (mainNavigation === 'menu' || mainNavigation === 'back') }">
            @if (mainNavigation === 'menu') {
                <app-icon icon="menu" (click)="toggleMenu()"></app-icon>
                @if (
                    auth.user &&
                    (!auth.user.profile?.country ||
                        !auth.user.username ||
                        !auth.user.profile?.birthdate ||
                        !auth.user.email_verified_at)
                ) {
                    <span class="absolute top-0 right-0 flex h-2.5 w-2.5">
                        <span
                            class="absolute inline-flex h-full w-full rounded-full bg-yellow-icon-alert opacity-75"></span>
                        <span class="relative inline-flex h-2.5 w-2.5 rounded-full bg-yellow-icon-alert"></span>
                    </span>
                }
            } @else if (mainNavigation === 'back') {
                <app-icon icon="arrow_back" (click)="goBack()"></app-icon>
            } @else if (mainNavigation === 'custom') {
                <ng-content select="[left]"> </ng-content>
            }
        </div>
        <div
            class="absolute left-0 right-0 my-auto w-full text-center font-bebas-neue text-2xl inline-flex justify-center items-center">
            <ng-content select="[title]"> </ng-content>
        </div>
        <div class="absolute right-0 z-50">
            <ng-content select="[right]"> </ng-content>
        </div>
    </div>
</div>
