export default function ofjs(js, what, cb) {
  var res = {};
  var nsKeys = Object.keys(js.resources || {});
  if (nsKeys.length === 1) {
    var ns = js.resources[nsKeys[0]];
    var keys = Object.keys(ns);
    keys.forEach(function (key) {
      res[key] = ns[key][what];
    });
    if (cb) return cb(null, res);
    return res;
  }
  nsKeys.forEach(function (nsKey) {
    res[nsKey] = {};
    var ns = js.resources[nsKey];
    var keys = Object.keys(ns);
    keys.forEach(function (key) {
      res[nsKey][key] = ns[key][what];
    });
  });
  if (cb) return cb(null, res);
  return res;
}