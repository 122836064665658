import { Component, inject } from '@angular/core';
import { GameApiService } from '@dc-api/game.api.service';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import _ from 'lodash';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';

@Component({
    selector: 'app-unfinished-local-games-dropdown',
    templateUrl: 'unfinished-local-games-dropdown.component.html',
})
export class UnfinishedLocalGamesDropdownComponent {
    public translateService: TranslateService = inject(TranslateService);

    opened = false;

    constructor(
        public auth: AuthService,
        public gameApiService: GameApiService,
        private _preferenceService: DartCounterPreferenceService,
        private _modal: ModalController,
        private _nav: NavController
    ) {}

    getUser(game: any, index: number): any {
        return game.teams[index].users[0];
    }

    getScore(game: any, index: number): any {
        return game.has_sets ? game.teams[index].total_score_sets : game.teams[index].total_score_legs;
    }

    public remove(index: number) {
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_MATCH:Remove game`,
                    text: $localize`:@@DELETING_LOCAL_MATCH:Do you want to remove this unfinished match?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.gameApiService.unfinishedLocalMatches.splice(index, 1);
                        this.gameApiService.setUnfinishedLocalGames();
                        if (!this.gameApiService.unfinishedLocalMatches) {
                            this.opened = false;
                        }
                    }
                });
            });
    }

    public continue(game, index): void {
        const copiedGame = _.cloneDeep(game);
        this.gameApiService.unfinishedLocalMatches.splice(index, 1);
        this.gameApiService.setUnfinishedLocalGames();

        this._preferenceService.transition = true;
        setTimeout(() => {
            this._preferenceService.transition = false;
        }, 1500);

        setTimeout(() => {
            this._nav.navigateForward('/game/match', { state: { match: copiedGame } });
        }, 1000);
    }
}
