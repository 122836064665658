@if (game) {
    <div class="global-lobby-shadow rounded-md col-span-1 mb-3 flex flex-col" [class.cursor-pointer]="showArrow">
        <div class="bg-game-card-top px-4 py-2.5 rounded-t-md">
            <div class="grid grid-cols-11 items-center">
                <div class="col-span-6 flex items-center text-lg font-bold text-black">
                    <div>
                        {{ game.name }}
                    </div>
                </div>
                <div class="col-span-3 text-center text-sm text-neutral-800">
                    {{ game.started_at | date: 'dd MMM yyyy' : '' : translateService.currentLang }} <br />{{
                        game.started_at | date: 'H:mm'
                    }}
                </div>
                @if (showArrow) {
                    <div class="col-span-2 flex items-center justify-end text-black">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                }
            </div>
        </div>
        <div class="space-y-3 rounded-b-md bg-game-card-bottom px-4 py-2">
            <div class="grid grid-cols-12 items-center text-black divide-x-2 divide-white">
                <div class="col-span-6 flex flex-col items-center justify-start space-y-2 overflow-hidden">
                    @for (user of game.users; track user.id) {
                        <div class="flex w-full items-center space-x-2">
                            <div class="relative">
                                <app-profile-photo
                                    class="h-fit"
                                    [ultimate]="user.user?.is_ultimate"
                                    [badge]="user.user?.profile?.badge"
                                    [url]="user.user?.profile?.profile_photo_url"
                                    [size]="32">
                                </app-profile-photo>
                            </div>
                            <div class="overflow-hidden">
                                <div class="flex items-center space-x-1">
                                    <div class="flex-1 ucfirst truncate text-xs font-bold leading-none">
                                        {{ getUserName(user) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div class="col-span-6 text-black h-full flex flex-col justify-center pl-2">
                    <div class="flex items-center justify-between">
                        <div [translate]="'HIGHEST_NUMBER'" i18n="@@HIGHEST_NUMBER" class="text-xs">Highest number</div>
                        <div class="ml-1 text-right text-xs">
                            {{ game.highest }}
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <div [translate]="'STREAK'" i18n="@@STREAK" class="text-xs">Streak</div>
                        <div class="ml-1 text-right text-xs">{{ game.streak }}</div>
                    </div>
                    <div class="flex items-center justify-between">
                        <div [translate]="'SUCCESS_RATE'" i18n="@@SUCCESS_RATE" class="text-xs">Success rate</div>
                        <div class="ml-1 text-right text-xs">{{ game.success_rate }}%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
