import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { GameLabelService } from '@dc-core/dc-services/game-label.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { LocalCameraService } from '@services/local-camera.service';
import { GameMode } from 'dc-core/dc-backend/dc-enums';
import { Subject } from 'rxjs';
import {
    AssignGameLabelsDialogComponent,
    AssignGameLabelsDialogPayload,
} from 'src/dialogs/assign-game-labels/assign-game-labels.dialog';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-online-setup',
    templateUrl: 'online-setup.component.html',
})
export class OnlineSetupComponent implements OnInit {
    public gameMode: GameMode = 'match';
    public gameModes: { title: string; type: GameMode }[] = [
        {
            title: $localize`:@@MATCH:Match`,
            type: 'match',
        },
        {
            title: $localize`:@@CRICKET_TACTICS:Cricket / Tactics`,
            type: 'cricket_tactics',
        },
    ];
    saveSubject: Subject<void> = new Subject<void>();

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public menu: MenuController,
        public platform: Platform,
        public modal: ModalController,
        public route: ActivatedRoute,
        public gameLabelService: GameLabelService,
        private cameraPreview: CameraPreview,
        private _online: OnlineGamesService,
        private _alertService: DartCounterAlertService,
        private _localCameraService: LocalCameraService
    ) {}

    ngOnInit() {
        this.menu.enable(true, 'mainmenu');
        this.menu.enable(false, 'ingamemenu');

        if (this._online.onlineSavedGameplay) {
            this.gameMode = this._online.onlineSavedGameplay.type;
        }

        this._localCameraService.checkPublishingVideo();
    }

    saveGameplay(): void {
        this.saveSubject.next();
    }

    assignGameLabels() {
        this.modal
            .create({
                component: AssignGameLabelsDialogComponent,
                componentProps: {
                    assignedGameLabels: this.gameLabelService.selectedLabels,
                } as AssignGameLabelsDialogPayload,
                cssClass: 'auto-height',
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onWillDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.gameLabelService.setSelectedLabels(dialogRes.data);
                    }
                });
            });
    }

    goBack(onlineSavedGameplay?: OnlineGameplay): void {
        this.setOnlineSavedGameplay(onlineSavedGameplay);
        if (onlineSavedGameplay && this._online.redirectToLobbyAfterAdding) {
            this._online.redirectToLobbyAfterAdding = false;
            this.nav.navigateBack('online-games/global-lobby');
        } else {
            this.nav.back();
        }
    }

    setOnlineSavedGameplay(onlineSavedGameplay?: OnlineGameplay): void {
        if (onlineSavedGameplay) {
            localStorage.setItem(LocalStorageKey.onlineSavedGameplay, JSON.stringify(onlineSavedGameplay));
        } else {
            localStorage.removeItem(LocalStorageKey.onlineSavedGameplay);
        }
    }
}
