<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'EDIT_USERNAME'" i18n="@@EDIT_USERNAME">Edit username</div>
    </div>
    <div class="slide-modal-content">
        <div class="w-full mb-4">
            <div class="dialog-input-label" [translate]="'USERNAME'" i18n="@@USERNAME">Username</div>
            <input type="text" class="dialog-input" [(ngModel)]="username" />
        </div>
        <app-primary-button
            size="normal"
            addedClasses="w-full"
            color="orange"
            (click)="SaveUsername()"
            [translate]="'SAVE'"
            i18n="@@SAVE">
            Save
        </app-primary-button>
    </div>
</div>
