import { Injectable } from '@angular/core';
import {
    collection,
    deleteDoc,
    doc,
    DocumentReference,
    Firestore,
    getDocs,
    onSnapshot,
    query,
    Timestamp,
} from '@angular/fire/firestore';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { FIRESTORE_COLLECTION } from '../globals/firestore.tables';
import { UsersCollectionService } from './users.collection.service';
import { FirestoreUserBackendEvent } from '../../dc-backend/dc-interfaces';

@Injectable()
export class BackendEventsCollectionService extends FireStoreCollectionsService {
    public incomingEvents$: Subject<FirestoreUserBackendEvent> = new Subject();

    constructor(
        firestore: Firestore,
        private _usersCollectionService: UsersCollectionService
    ) {
        super(firestore);
    }

    getIncomingEvents(): Promise<FirestoreUserBackendEvent[]> {
        return new Promise((resolve) => {
            const collectionRef = collection(
                this._usersCollectionService.activeUserDocRef,
                FIRESTORE_COLLECTION.BACKEND_EVENTS
            );

            const ownEvents = query(collectionRef);

            getDocs(ownEvents).then((querySnapshot) => {
                let events: FirestoreUserBackendEvent[] = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as FirestoreUserBackendEvent;
                    data.doc_id = doc.id;
                    data.doc_ref = doc.ref;

                    events.push(data);
                });

                resolve(events);
            });
        });
    }

    watchIncomingEvents(): Observable<FirestoreUserBackendEvent> {
        const collectionRef = collection(
            this._usersCollectionService.activeUserDocRef,
            FIRESTORE_COLLECTION.BACKEND_EVENTS
        );

        return new Observable<FirestoreUserBackendEvent>((observer) => {
            try {
                const ownEvents = query(collectionRef);

                const unsubscribe = onSnapshot(ownEvents, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as FirestoreUserBackendEvent;
                        data.doc_id = change.doc.id;
                        data.doc_ref = change.doc.ref;
                        if (change.type === 'added') {
                            observer.next(data);
                        }
                    });
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    removeBackendEvent(doc_ref: DocumentReference<any>): void {
        try {
            deleteDoc(doc_ref);
        } catch (err) {
            console.error(err);
        }
    }
}
