import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';
import { gamesOnlineLeaveBusterGuard } from '../guards/games-online-leave-buster.guard';

export const cricketTacticsGameRoutes: Route[] = [
    {
        path: 'online-game/cricket-tactics/:id',
        data: {
            hideTournamentNotification: true,
        },
        canDeactivate: [gamesOnlineLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/cricket-tactics-online-game/cricket-tactics-online-game.component').then(
                (c) => c.CricketTacticsOnlineGameComponent
            ),
    },
    {
        path: 'game/cricket-tactics',
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/cricket-tactics-local-game/cricket-tactics-local-game.component').then(
                (m) => m.CricketTacticsLocalGameComponent
            ),
    },
];
