import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class AuthApiService extends ApiService {
    public login = super.getFetcher().path('/login').method('post').create();
    public loginUsingId = super.getFetcher().path('/login-using-id').method('post').create();
    public register = super.getFetcher().path('/register').method('post').create();
    public logout = super.getFetcher().path('/logout').method('post').create();

    public registerAsGuest = super.getFetcher().path('/guest/register').method('post').create();
    public transitionGuestToUser = super.getFetcher().path('/guest/transition').method('post').create();

    public facebookLogin = super.getFetcher().path('/auth/facebook/login').method('post').create();
    public unlinkFacebook = super.getFetcher().path('/auth/facebook/unlink').method('post').create();
    public appleLogin = super.getFetcher().path('/auth/apple/login').method('post').create();
    public unlinkApple = super.getFetcher().path('/auth/apple/unlink').method('post').create();
    public googleLogin = super.getFetcher().path('/auth/google/login').method('post').create();
    public unlinkGoogle = super.getFetcher().path('/auth/google/unlink').method('post').create();

    public forgotPassword = super.getFetcher().path('/password/forgot').method('post').create();
    public recoverPassword = super.getFetcher().path('/password/recover').method('post').create();
    public resetPassword = super.getFetcher().path('/password/reset').method('post').create();
    public changePassword = super.getFetcher().path('/password/change').method('post').create();
    public changePasswordViaEmail = super.getFetcher().path('/password/change/email').method('post').create();

    public changeEmail = super.getFetcher().path('/email/change').method('post').create();
    public sendVerificationEmail = super.getFetcher().path('/email/verification/send').method('post').create();
    public verifyEmailByCode = super.getFetcher().path('/email/verify/code').method('post').create();

    public getPushTokens = super.getFetcher().path('/push-tokens').method('get').create();
    public linkPushToken = super.getFetcher().path('/push-tokens/link').method('post').create();
    public unlinkPushToken = super.getFetcher().path('/push-tokens/unlink').method('post').create();

    public acceptTerms = super.getFetcher().path('/terms/accept').method('post').create();
}
