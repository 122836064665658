<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'EDIT_SCORES'" i18n="@@EDIT_SCORES">Edit score</div>
    </div>
    <div class="slide-modal-content">
        <div class="flex-1 my-4">
            <div class="zoomed-omni-dart w-1/2 mx-auto">
                @if (svgContent) {
                    <div class="zoomed-container">
                        <div [innerHTML]="sanitizedSvgContent"></div>
                    </div>
                    <div
                        class="relative w-full border-1 !border-neutral-50 !bg-neutral-50 px-2 pt-1"
                        style="border-radius: 0 0 9px 9px">
                        <app-keyboard-dart class="!text-black block w-2/3"></app-keyboard-dart>
                        <div class="in-game-score-field">
                            {{ editablePoint?.amount * editablePoint?.multiplier || 0 }}
                        </div>

                        <div class="absolute bottom-1 right-1 font-bebas-neue !text-black">
                            {{ getScoreEvent(editablePoint) }}
                        </div>
                    </div>
                }
            </div>
        </div>

        <app-new-single-dart-keyboard
            class="bg-white-pattern block flex-none h-[18rem]"
            (setSingleDart)="setNewSingleDartValue($event)"
            (asetSingleDart)="setSingleDart.emit($event)"
            [keepMultiplier]="true"
            [myTurn]="true"></app-new-single-dart-keyboard>

        <app-primary-button
            type="submit"
            size="normal"
            addedClasses="w-full"
            color="orange"
            (click)="saveEditedScore()"
            [translate]="'CONFIRM'"
            i18n="@@CONFIRM">
            Confirm
        </app-primary-button>
    </div>
</div>
