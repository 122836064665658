import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

import { PrimaryButtonComponent } from '../../app/primary-button/primary-button.component';
import { DialogViewComponent } from '../../app/shared/components/dialog/dialog-view/dialog-view.component';

@Component({
    selector: 'app-ask-for-location-dialog',
    standalone: true,
    templateUrl: 'ask-for-location.dialog.html',
    imports: [CommonModule, PrimaryButtonComponent, DialogViewComponent, TranslateModule],
})
export class AskForLocationDialogComponent {
    private modalController: ModalController = inject(ModalController);

    public openLocationSettings(): void {
        NativeSettings.open({
            optionAndroid: AndroidSettings.Location,
            optionIOS: IOSSettings.LocationServices,
        });
    }

    public dismiss(): void {
        this.modalController.dismiss();
    }
}
