import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-checkbox',
    templateUrl: 'checkbox.component.html',
    standalone: true,
    imports: [CommonModule, IconComponent],
})
export class CheckboxComponent {
    @Input() model: boolean;
    @Input() color: 'white' | 'black' | 'gray' = 'white';
    @Input() position: 'left' | 'right' | 'center' = 'left';
    @Input() space: 'normal' | 'between' = 'normal';
    @Input() size: 'medium' | 'small' = 'medium';
    @Output() itemChange = new EventEmitter();

    changeItem(): void {
        this.itemChange.emit(!this.model);
    }

    onClick(event: any): void {
        event.preventDefault();
    }
}
