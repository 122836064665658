<app-page-header mainNavigation="custom" defaultBack="statistics">
    <app-icon left icon="arrow_back" (click)="back(false)"></app-icon>
    <div title>{{ title | translate }}</div>
    <app-option-button right icon="bin" (click)="TryRemove()" color="text-white"></app-option-button>
</app-page-header>

<ion-content class="ion-text-black bg-white-pattern">
    @if (item) {
        <div class="bg-white flex flex-col text-center text-black py-6">
            <div class="text-lg font-bold">{{ item.name }}</div>
            <div class="text-sm text-neutral-700">
                {{ item.started_at | date: 'dd-MMM-yyyy H:mm' : '' : translateService.currentLang }}
            </div>
            <div class="flex justify-center gap-2 mt-3">
                <div class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs">
                    {{ item.start_with | entryMode }}
                </div>
                <div class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs">
                    {{ item.end_with | finishMode }}
                </div>
                @if (item.two_legs_difference) {
                    <div
                        class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs"
                        [translate]="'TWO_LEGS_DIFFERENCE'"
                        i18n="@@TWO_LEGS_DIFFERENCE">
                        Two legs difference
                    </div>
                }
                @if (item.enable_sudden_death) {
                    <div
                        class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs"
                        [translate]="'SUDDEN_DEATH_MODE'"
                        i18n="@@SUDDEN_DEATH_MODE">
                        Sudden death mode
                    </div>
                }
                @if (item.cutThroat) {
                    <div
                        class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs"
                        [translate]="'CUT_THROAT'"
                        i18n="@@CUT_THROAT">
                        Cut throat
                    </div>
                }
            </div>
        </div>
        <div class="relative mb-3" appWebContainer>
            <div class="flex sticky top-0 px-12 py-3 bg-white rounded-md z-10">
                <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"></div>
                <div
                    [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                    class="flex text-center justify-center">
                    @if (item.has_teams) {
                        @for (team of item.teams; track team) {
                            <div class="w-1/2">
                                {{ GetPlayers(team) }}
                                @if (team.result === 'won') {
                                    <app-icon icon="emoji_events" class="text-orange"></app-icon>
                                }
                            </div>
                        }
                    }
                    @if (!item.has_teams) {
                        @for (user of item.users; track user) {
                            <div [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-1/' + item.users.length">
                                @if (user.user) {
                                    <app-profile-photo
                                        [ultimate]="user.user.is_ultimate"
                                        [badge]="user.user.profile?.badge"
                                        [size]="32"
                                        [url]="user.user.profile?.profile_photo_url"
                                        style="height: 32px"></app-profile-photo>
                                } @else {
                                    <app-profile-photo [size]="32" style="height: 32px"></app-profile-photo>
                                }
                                <div class="mt-1 truncate capitalize">{{ getCricketTacticsUserName(user) }}</div>
                                @if (user.result === 'won') {
                                    <app-icon icon="emoji_events" class="text-orange justify-center flex"></app-icon>
                                }
                            </div>
                        }
                    }
                </div>
            </div>
            <div class="mt-3 mx-4">
                <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                    <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'">
                        {{ item.has_sets ? 'Sets' : 'Legs' }}
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    {{
                                        item.has_sets ? team.users[0].total_score_sets : team.users[0].total_score_legs
                                    }}
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    {{ item.has_sets ? user.total_score_sets : user.total_score_legs }}
                                </div>
                            }
                        }
                    </div>
                </div>
                <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'TOTAL_SCORE'"
                        i18n="@@TOTAL_SCORE">
                        Total score
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    {{ team.total_score }}
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    {{ user.total_score }}
                                </div>
                            }
                        }
                    </div>
                </div>
                <div class="flex px-8 py-3 text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'MARKS_PER_ROUND'"
                        i18n="@@MARKS_PER_ROUND">
                        Marks per round
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    {{ team.marks_per_round }}
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    {{ user.marks_per_round }}
                                </div>
                            }
                        }
                    </div>
                </div>
                <div class="flex px-8 py-3 text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'"
                        [translate]="'TURNS_TAKEN'"
                        i18n="@@TURNS_TAKEN">
                        Turns taken
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @if (item.has_teams) {
                            @for (team of item.teams; track team) {
                                <div class="w-1/2">
                                    {{ team.turns_count | number: '1.0-2' }}
                                </div>
                            }
                        }
                        @if (!item.has_teams) {
                            @for (user of item.users; track user) {
                                <div class="w-1/{{ item.users.length }}">
                                    {{ user.turns_count | number: '1.0-2' }}
                                </div>
                            }
                        }
                    </div>
                </div>
            </div>

            @if (item.with_sets_and_legs) {
                @if (item.has_sets && sets) {
                    <div class="mt-3 mx-4">
                        @for (set of sets; track set) {
                            <div
                                class="bg-white px-4 py-2.5 flex items-center justify-between text-lg font-bold text-black cursor-pointer"
                                [ngClass]="set.expanded ? 'rounded-t-md' : 'rounded-md'"
                                (click)="expandItem(set, sets)">
                                <div class="font-normal text-sm">Set #{{ set.index }}</div>
                                @if (set.winner) {
                                    <div class="flex items-center overflow-hidden">
                                        <div class="text-base font-normal truncate capitalize mx-2">
                                            {{ set.winner }}
                                        </div>
                                        <app-icon icon="emoji_events" class="text-orange ml-2"></app-icon>
                                    </div>
                                }
                                <div>
                                    @if (!set.expanded) {
                                        <app-icon icon="keyboard_arrow_down" class="justify-center flex"></app-icon>
                                    } @else {
                                        <app-icon icon="keyboard_arrow_up" class="justify-center flex"></app-icon>
                                    }
                                </div>
                            </div>
                            @if (set.expanded) {
                                @for (leg of set.legs; track leg) {
                                    <div class="mx-4 my-2">
                                        <div
                                            class="bg-white px-3 py-2 flex items-center justify-between text-lg text-black cursor-pointer"
                                            [ngClass]="leg.expanded ? 'rounded-t-md' : 'rounded-md'"
                                            (click)="expandItem(leg, set.legs)">
                                            <div class="flex items-center">
                                                <div class="font-normal text-sm mr-4">Leg #{{ leg.index }}</div>
                                                @if (leg.winner) {
                                                    <div class="flex items-center overflow-hidden">
                                                        <div class="text-base font-normal truncate capitalize mx-2">
                                                            {{ leg.winner }}
                                                        </div>
                                                        <app-icon
                                                            icon="emoji_events"
                                                            class="text-orange ml-2"></app-icon>
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                @if (!leg.expanded) {
                                                    <app-icon
                                                        icon="keyboard_arrow_down"
                                                        class="justify-center flex"></app-icon>
                                                } @else {
                                                    <app-icon
                                                        icon="keyboard_arrow_up"
                                                        class="justify-center flex"></app-icon>
                                                }
                                            </div>
                                        </div>
                                        @if (leg.expanded) {
                                            <ng-container
                                                *ngTemplateOutlet="legContainer; context: { leg: leg }"></ng-container>
                                        }
                                    </div>
                                }
                            }
                        }
                    </div>
                } @else if (!item.has_sets && sets) {
                    <div class="mt-3 mx-4">
                        @for (leg of sets[0]?.legs; track leg) {
                            <div class="mt-3 mx-4">
                                <div
                                    class="bg-white px-4 py-2.5 flex items-center justify-between text-lg font-bold text-black cursor-pointer"
                                    [ngClass]="leg.expanded ? 'rounded-t-md' : 'rounded-md'"
                                    (click)="expandItem(leg, sets[0]?.legs)">
                                    <div class="font-normal text-sm">Leg #{{ leg.index }}</div>
                                    @if (leg.winner) {
                                        <div class="flex items-center overflow-hidden">
                                            <div class="text-base font-normal truncate capitalize mx-2">
                                                {{ leg.winner }}
                                            </div>
                                            <app-icon icon="emoji_events" class="text-orange ml-2"></app-icon>
                                        </div>
                                    }
                                    <div>
                                        <app-icon icon="keyboard_arrow_down"></app-icon>
                                    </div>
                                </div>
                                @if (leg.expanded) {
                                    <ng-container
                                        *ngTemplateOutlet="legContainer; context: { leg: leg }"></ng-container>
                                }
                            </div>
                        }
                    </div>
                }
            } @else {
                @if (item.has_teams) {
                    <div class="mt-3 mx-4">
                        @for (number of gameValues; track number) {
                            <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
                                <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'">
                                    {{ number.title }}
                                </div>
                                <div
                                    [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                                    class="flex text-center">
                                    @for (team of item.teams; track team) {
                                        <div class="w-1/2 tex-center">
                                            @if (team.cricket_tactics_result[number.val] > 2) {
                                                <app-icon
                                                    icon="tactics_full"
                                                    size="medium"
                                                    class="text-black"></app-icon>
                                            }
                                            @if (team.cricket_tactics_result[number.val] === 2) {
                                                <app-icon
                                                    icon="tactics_two"
                                                    size="medium"
                                                    class="text-black"></app-icon>
                                            }
                                            @if (team.cricket_tactics_result[number.val] === 1) {
                                                <app-icon
                                                    icon="tactics_one"
                                                    size="medium"
                                                    class="text-black"></app-icon>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                @if (!item.has_teams) {
                    <div class="mt-3 mx-4">
                        @for (number of gameValues; track number) {
                            <div class="flex border-b-1 items-center border-b-white px-8 py-3 text-sm">
                                <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'">
                                    {{ number.title }}
                                </div>
                                <div
                                    [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                                    class="flex text-center">
                                    @for (user of item.users; track user) {
                                        <div class="w-1/{{ item.users.length }}">
                                            @if (user.cricket_tactics_result[number.val] > 2) {
                                                <app-icon
                                                    icon="tactics_full"
                                                    size="medium"
                                                    class="text-black"></app-icon>
                                            }
                                            @if (user.cricket_tactics_result[number.val] === 2) {
                                                <app-icon
                                                    icon="tactics_two"
                                                    size="medium"
                                                    class="text-black"></app-icon>
                                            }
                                            @if (user.cricket_tactics_result[number.val] === 1) {
                                                <app-icon
                                                    icon="tactics_one"
                                                    size="medium"
                                                    class="text-black"></app-icon>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }

                <div class="mt-3 mx-4">
                    <div class="flex border-b-1 items-center border-b-white px-8 py-3 text-sm">
                        <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'">Score</div>
                        <div
                            [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'"
                            class="flex text-center">
                            @if (item.has_teams) {
                                @for (team of item.teams; track team) {
                                    <div class="w-1/2">
                                        {{ team.total_score }}
                                    </div>
                                }
                            }
                            @if (!item.has_teams) {
                                @for (user of item.users; track user) {
                                    <div class="w-1/{{ item.users.length }}">
                                        {{ user.total_score }}
                                    </div>
                                }
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</ion-content>

<ng-template #legContainer let-leg="leg">
    @for (number of gameValues; track number) {
        <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm">
            <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'" class="pl-3">
                {{ number.title }}
            </div>
            <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'" class="flex justify-center">
                @for (cricket_tactics_result of leg.results; track cricket_tactics_result) {
                    <div class="w-1/{{ item.users.length }}">
                        @if (cricket_tactics_result[number.val] > 2) {
                            <app-icon icon="tactics_full" size="medium"></app-icon>
                        }
                        @if (cricket_tactics_result[number.val] === 2) {
                            <app-icon icon="tactics_two" size="medium"></app-icon>
                        }
                        @if (cricket_tactics_result[number.val] === 1) {
                            <app-icon icon="tactics_one" size="medium"></app-icon>
                        }
                    </div>
                }
            </div>
        </div>
    }
    <div class="flex border-b-1 items-center border-b-white px-4 py-3 text-sm font-bold">
        <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-1/4'" class="pl-3">Score</div>
        <div [ngClass]="item.users?.length <= 2 || item.has_teams ? 'w-1/2' : 'w-3/4'" class="flex justify-center">
            @for (score of leg.scores; track score) {
                <div class="w-1/{{ item.users.length }} text-center">
                    {{ score }}
                </div>
            }
        </div>
    </div>
</ng-template>
