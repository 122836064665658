@if (authenticatedUserId() && canShowPrompt) {
    <div class="relative">
        @if (canSave && !showPrompt && saveState === 'prompt') {
            <div
                (click)="prompt('full', true)"
                class="absolute bottom-0 left-3 inline-flex portrait:h-10 portrait:w-10 landscape:h-9 landscape:w-9 items-center justify-center rounded-full bg-blue p-2 cursor-pointer animate__animated animate__slideInLeft">
                <app-icon icon="bookmark_outline" size="medium" class="!text-white"></app-icon>
            </div>
        }

        @if (promptType === 'full') {
            <div
                class="absolute bottom-0 left-0 rounded-r-lg bg-orange py-1 px-4 flex items-center space-x-4 !text-white group cursor-pointer animate__animated"
                [ngClass]="{
                    animate__slideInLeft: showPrompt,
                    animate__slideOutLeft: !showPrompt
                }"
                (click)="save()">
                <div class="h-20 w-20 rounded-lg overflow-hidden relative">
                    @if (currentActionReplay()?.clipFrame) {
                        <img class="w-full h-full object-cover object-center" [src]="currentActionReplay().clipFrame" />
                    }

                    <div
                        class="absolute bottom-0 left-0 right-0 font-bebas-neue text-xs !bg-black !text-white text-center pt-0.5"
                        [translate]="'ACTION_REPLAY'"
                        i18n="@@ACTION_REPLAY">
                        Action Replay
                    </div>
                </div>

                @if (saveState === 'prompt') {
                    <div class="flex flex-col items-center group-hover:scale-105 duration-200">
                        <div class="text-2xl font-bebas-neue" [translate]="'SAVE'" i18n="@@SAVE">Save</div>
                        <app-icon icon="bookmark_outline" size="medium" class="!text-white"></app-icon>
                    </div>
                } @else if (saveState === 'saving') {
                    <div class="flex flex-col items-center animate-pulse">
                        <div class="text-2xl font-bebas-neue" [translate]="'SAVING'" i18n="@@SAVING">Saving</div>
                    </div>
                } @else if (saveState === 'saved') {
                    <div class="flex flex-col items-center">
                        <div class="text-2xl font-bebas-neue" [translate]="'SAVED'" i18n="@@SAVED">Saved</div>
                    </div>
                } @else if (saveState === 'error') {
                    <div class="flex flex-col items-center">
                        <div class="text-2xl font-bebas-neue" [translate]="'ERROR'" i18n="@@ERROR">Error</div>
                    </div>
                }
            </div>
        }
    </div>
}
