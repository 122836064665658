import { Component, OnInit } from '@angular/core';
import { GameLabel } from '@dc-core/dc-backend/dc-classes';
import { ModalController, NavParams } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

export interface ManageGameLabelDialogResponse {
    type: 'manage' | 'delete';
    gameLabel?: GameLabel;
}

@Component({
    selector: 'app-manage-game-label-dialog',
    templateUrl: 'manage-game-label.dialog.html',
    styleUrls: ['manage-game-label.dialog.scss'],
})
export class ManageGameLabelDialogComponent implements OnInit {
    gameLabel: GameLabel;

    constructor(
        public view: ModalController,
        public _view: ModalController,
        public auth: AuthService,
        private loading: DCLoadingService,
        private _alertService: DartCounterAlertService,
        private _navParams: NavParams
    ) {}

    ngOnInit(): void {
        this.gameLabel = this._navParams.get('gameLabel') ?? {
            id: null,
            category_id: null,
            user_id: null,
            title: '',
        };
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    public createOrUpdate(): void {
        this.close({ type: 'manage', gameLabel: this.gameLabel } as ManageGameLabelDialogResponse);
    }

    public delete(): void {
        this.close({ type: 'delete', gameLabel: this.gameLabel } as ManageGameLabelDialogResponse);
    }
}
