<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="close()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'UPGRADE_REASONS'" i18n="@@UPGRADE_REASONS">Upgrade reasons</div>
    </div>
    <div class="slide-modal-content">
        <div class="mb-3" [translate]="'WHY_UPGRADE_TO_ULTIMATE'" i18n="@@WHY_UPGRADE_TO_ULTIMATE">
            What was your main reason to upgrade to Ultimate?
        </div>
        @if (upgradeReasons.length) {
            @for (reason of upgradeReasons; track reason) {
                <div class="mb-2">
                    <app-checkbox
                        [model]="reason.selected"
                        position="right"
                        space="between"
                        (itemChange)="toggleAnswer(reason)">
                        <div class="text-md text-black">{{ reason.title }}</div>
                    </app-checkbox>
                    @if (reason.type === 'open_option' && reason.selected) {
                        <div>
                            <textarea
                                class="dialog-input h-28"
                                name="reason"
                                [(ngModel)]="reason.explanation"
                                [placeholder]="reasonPlaceholder"
                                autocapitalize="on"></textarea>
                        </div>
                    }
                </div>
            }

            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="black"
                [translate]="'SEND'"
                i18n="@@SEND"
                (click)="send()">
                Send
            </app-primary-button>
        }
    </div>
</div>
