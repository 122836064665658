<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'JOIN_TOURNAMENT'" i18n="@@JOIN_TOURNAMENT">Join tournament</div>
    </div>
    <div class="slide-modal-content">
        @if (isLoading) {
            <div class="h-16 flex justify-center items-center">
                <app-icon icon="loading" size="large" class="animate-spin text-orange h-fit"></app-icon>
            </div>
        } @else {
            <form #form="ngForm" [formGroup]="joinForm" (ngSubmit)="joinForm.valid && join()">
                <div class="my-4">
                    @if (lastTenOnlineGames && notEnoughOnlineGames) {
                        <div
                            class="flex items-center bg-neutral-200 p-4 text-black overflow-hidden relative w-full rounded-md">
                            <app-icon icon="warning" size="large"></app-icon>
                            <div class="flex flex-col w-full ml-4">
                                <div
                                    class="text-xl font-bebas-neue"
                                    [translate]="'NOT_ENOUGH_ONLINE_GAMES_PLAYED'"
                                    i18n="@@NOT_ENOUGH_ONLINE_GAMES_PLAYED">
                                    Not enough online games played
                                </div>
                                <div
                                    class="text-sm font-medium mt-1"
                                    [translate]="'NEED_TEN_ONLINE_GAMES_PLAYED_FOR_THIS_TOURNAMENT'"
                                    i18n="@@NEED_TEN_ONLINE_GAMES_PLAYED_FOR_THIS_TOURNAMENT">
                                    In order to play in this tournaments, you need to have at least 10 online games
                                    played
                                </div>
                                <div class="flex space-x-1 text-sm font-medium mt-2">
                                    <div [translate]="'YOU_HAVE_PLAYED_COLON'" i18n="@@YOU_HAVE_PLAYED_COLON">
                                        You have played:
                                    </div>
                                    <div>
                                        {{ lastTenOnlineGames.count }}
                                    </div>
                                </div>
                                <div class="flex justify-end items-center space-x-1 mt-2">
                                    <app-primary-button
                                        size="small"
                                        addedClasses="w-full"
                                        (click)="goToGlobalLobby()"
                                        [translate]="'GO_TO_GLOBAL_LOBBY'"
                                        i18n="@@GO_TO_GLOBAL_LOBBY">
                                        Go to global lobby
                                    </app-primary-button>
                                </div>
                            </div>
                        </div>
                    } @else {
                        @if (canJoin) {
                            @if (tournament.is_private) {
                                <div class="new-game-card tournament mb-2">
                                    <div class="title-container">
                                        <div class="icon">
                                            <app-icon icon="closed_lock" size="small"></app-icon>
                                        </div>
                                        <div class="title" [translate]="'PASSWORD'" i18n="@@PASSWORD">Password</div>
                                    </div>

                                    <input
                                        [ngClass]="{
                                            '!border-red': form.submitted && joinForm.get('password').errors
                                        }"
                                        formControlName="password"
                                        type="text"
                                        class="dialog-input text-center" />
                                </div>
                            }

                            @if (tournament.min_three_dart_avg || tournament.max_three_dart_avg) {
                                <div class="new-game-card tournament mb-2">
                                    <div class="title-container">
                                        <div class="icon">
                                            <app-icon icon="three_dots" size="small"></app-icon>
                                        </div>
                                        <div
                                            class="title"
                                            [translate]="'THREE_DART_AVERAGE'"
                                            i18n="@@THREE_DART_AVERAGE">
                                            Three dart average
                                        </div>
                                    </div>

                                    <div class="flex justify-between items-center font-bebas-neue text-lg">
                                        @if (!tournament.min_three_dart_avg) {
                                            <div [translate]="'NO_MINIMUM'" i18n="@@NO_MINIMUM">No minimum</div>
                                        } @else {
                                            <div class="flex items-center space-x-1">
                                                <div [translate]="'MINIMUM'" i18n="@@MINIMUM">Minimum</div>
                                                <div>{{ tournament.min_three_dart_avg }}</div>
                                            </div>
                                        }

                                        @if (!tournament.max_three_dart_avg) {
                                            <div [translate]="'NO_MAXIMUM'" i18n="@@NO_MAXIMUM">No maximum</div>
                                        } @else {
                                            <div class="flex items-center space-x-1">
                                                <div [translate]="'MAXIMUM'" i18n="@@MAXIMUM">Maximum</div>
                                                <div>{{ tournament.max_three_dart_avg }}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            @if (tournament.needs_approval) {
                                <div class="new-game-card tournament mb-2">
                                    <div class="title-container">
                                        <div class="icon">
                                            <app-icon icon="camera_alt" size="small"></app-icon>
                                        </div>
                                        <div class="title" [translate]="'ONLINE_SETUP'" i18n="@@ONLINE_SETUP">
                                            Online setup
                                        </div>
                                    </div>

                                    <div
                                        class="relative overflow-hidden w-full max-w-xs mx-auto rounded-md"
                                        [ngClass]="{
                                            'bg-gray-50 h-40 w-40': !authService.user.profile?.online_setup_url
                                        }">
                                        @if (authService.user.profile?.online_setup_url) {
                                            <img
                                                class="w-full h-full rounded-md"
                                                [src]="authService.user.profile?.online_setup_url" />
                                        } @else {
                                            <img
                                                class="absolute bottom-0 left-0 right-0 top-0 m-auto w-28 grayscale"
                                                src="assets/icon/dctarget_stacked_white.png" />
                                        }
                                    </div>
                                    <app-primary-button
                                        class="block mt-2"
                                        size="small"
                                        fill="outline"
                                        addedClasses="w-full"
                                        color="orange"
                                        (click)="uploadOnlineSetup()"
                                        [translate]="'UPLOAD_ONLINE_SETUP'"
                                        i18n="@@UPLOAD_ONLINE_SETUP">
                                        Upload online setup
                                    </app-primary-button>
                                </div>
                            } @else {
                                <div class="new-game-card tournament mb-2">
                                    <div
                                        class="text-center text-black font-semibold"
                                        [translate]="'NO_ONLINE_SETUP_REQUIRED'"
                                        i18n="@@NO_ONLINE_SETUP_REQUIRED">
                                        No online setup required
                                    </div>
                                </div>
                            }
                        } @else {
                            <div
                                class="flex items-center bg-neutral-200 p-4 text-black overflow-hidden relative w-full rounded-md">
                                <app-icon icon="warning" size="large"></app-icon>
                                <div class="flex flex-col w-full ml-4">
                                    <div
                                        class="text-xl font-bebas-neue"
                                        [translate]="'THREE_DART_AVERAGE_DOES_NOT_MATCH'"
                                        i18n="@@THREE_DART_AVERAGE_DOES_NOT_MATCH">
                                        Three dart average does not match
                                    </div>
                                    <div class="text-sm font-medium mt-1">
                                        {{ joinError }}
                                    </div>
                                </div>
                            </div>
                        }
                    }
                </div>

                @if (!(lastTenOnlineGames && notEnoughOnlineGames) && canJoin) {
                    <div class="button-container">
                        <app-primary-button
                            color="blue"
                            type="submit"
                            size="normal"
                            addedClasses="w-full"
                            [translate]="'JOIN'"
                            i18n="@@JOIN">
                            Join
                        </app-primary-button>
                    </div>
                }
            </form>
        }
    </div>
</div>
