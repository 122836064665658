import { User } from 'dc-core/dc-backend/dc-classes';
import { GameStatistics, OverallStatDetails } from '../game-statistics.classes';
import { StartScoreOption } from '../statistics.globals';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';

export interface MatchOverallStatsData {
    results: { total?: number; wins?: number; draws?: number; losses?: number };
    threeDartAvg: { average?: number; best?: number; worst?: number };
    firstNineAvg: { average?: number; best?: number; worst?: number };
    checkoutRate: { average?: number; best?: number; worst?: number };
    checkouts: { average?: number; best?: number; worst?: number };
    legs: { average?: number; best?: number; worst?: number };
}

export type MatchStatDetailType = 'threeDartAvg' | 'firstNineAvg' | 'checkoutRate' | 'checkouts' | 'legs';

export interface MatchStatDetails {
    isOnline: boolean;
    vsCPU: boolean;
    startScore: number;
}

export class MatchOverallStats extends GameStatistics {
    public startScoreOption: StartScoreOption = '501';
    public startScore: number = 170;

    public overallStats: MatchOverallStatsData = {
        results: {},
        threeDartAvg: {},
        firstNineAvg: {},
        checkoutRate: {},
        checkouts: {},
        legs: {},
    } as any;

    public loadedOverallStats: boolean = false;
    public gameStatisticsApiService: GameStatisticsApiService;

    private _localStorageKey = 'matchOverallStats';

    constructor(user: User, gameStatisticsApiService: GameStatisticsApiService) {
        super(user);
        this.gameStatisticsApiService = gameStatisticsApiService;
    }

    setFilters(): void {
        let savedFilters = JSON.parse(localStorage.getItem(this._localStorageKey));
        if (savedFilters) {
            this.startScoreOption = savedFilters.startScoreOption ?? this.startScoreOption;
            this.startScore = savedFilters.startScore ?? this.startScore;
        }
        super.setFilters();
    }

    saveFilters() {
        localStorage.setItem(
            this._localStorageKey,
            JSON.stringify({
                startScoreOption: this.startScoreOption,
                startScore: this.startScore,
            })
        );
        super.saveFilters();
    }

    async loadOverallStats(): Promise<void> {
        this.loadedOverallStats = false;
        if (this.user.is_ultimate) {
            await this.initOverall();
        } else {
            await this.initThreeDartAvg();
        }
        this.loadedOverallStats = true;
        this.saveFilters();
    }

    async initThreeDartAvg(): Promise<void> {
        this.resultWidths = null;

        this.allStatTypes.forEach(async (type, index) => {
            await this.gameStatisticsApiService
                .getMatchThreeDartAvgStatistics({
                    type,
                    start_score: this.getStartScore(),
                    is_online: this.getIsOnline(),
                    vs_cpu: this.getVsCPU(),
                    limit: 1,
                    from_date: this.fromDate,
                    to_date: this.untilDate,
                })
                .then((res) => {
                    this.overallStats.threeDartAvg[type] = res.data[0] ? res.data[0].value : 0;
                })
                .catch((e) => {
                    console.error(e);
                });
        });
    }

    async initOverall(): Promise<void> {
        await this.gameStatisticsApiService
            .getOverallStatistics({
                start_score: this.getStartScore(),
                is_online: this.getIsOnline(),
                vs_cpu: this.getVsCPU(),
                from_date: this.fromDate,
                to_date: this.untilDate,
            })
            .then((res) => {
                this.overallStats.results = res.data.results;
                this.overallStats.threeDartAvg = res.data.three_dart_average;
                this.overallStats.firstNineAvg = res.data.first_nine_average;
                this.overallStats.checkoutRate = res.data.checkout_rate;
                this.overallStats.checkouts = res.data.checkouts;
                this.overallStats.legs = res.data.legs;

                this.calculateResultWidths();

                this.loadedOverallStats = true;
            })
            .catch((e) => {
                console.error(e);
            });
    }

    updateStartScoreOption(startScoreOption: StartScoreOption): void {
        this.startScoreOption = startScoreOption;
    }

    updateStartScore(startScore: number = null): void {
        this.startScore = startScore;
    }

    calculateResultWidths() {
        const total = this.overallStats.results.total;

        const widths = [];
        if (total === 0) {
            widths.push(33.3);
            widths.push(33.3);
            widths.push(33.3);
        } else {
            widths.push((79 / total) * this.overallStats.results.wins + 7);
            widths.push((79 / total) * this.overallStats.results.draws + 7);
            widths.push((79 / total) * this.overallStats.results.losses + 7);
        }

        this.resultWidths = widths;
    }

    getStartScore(): number | null {
        switch (this.startScoreOption) {
            case 'all':
                return null;
            case '501':
                return 501;
            case '701':
                return 701;
            case 'custom':
                return this.startScore;
        }
    }
}
