<modal-content class="light" fullscreen class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="transparent">
        <div title>{{ title }}</div>
        <app-icon icon="close" (click)="DismissModal()"></app-icon>
    </app-page-header>

    <div style="max-height: 70vh; overflow: auto">
        <swiper-container class="dc-swiper" [pagination]="true">
            <swiper-slide class="flex flex-col items-center" style="padding: 0.75rem 1rem" *ngFor="let slide of slides">
                <div class="text-xl font-bold">{{ slide.title }}</div>
                <div>{{ slide.desc }}</div>
                <img class="mt-4" [src]="slide.image" *ngIf="slide.image" />
            </swiper-slide>
        </swiper-container>
    </div>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA orange" style="position: relative" (click)="DismissModal()">
            <p class="text-center" style="height: 72px" [translate]="'CLOSE'" i18n="@@CLOSE">Close</p>
        </div>
    </ion-toolbar>
</ion-footer>
