import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CameraStreamClip } from '@dc-core/dc-backend/dc-classes';
import { NavController } from '@ionic/angular';
import { CapacitorVideoPlayerWeb } from 'capacitor-video-player/dist/esm/web';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-action-replay-view',
    templateUrl: './action-replay-view.component.html',
    styleUrls: ['./action-replay-view.component.scss'],
})
export class ActionReplayViewComponent implements OnInit, AfterViewInit, OnDestroy {
    public clip: CameraStreamClip;

    private videoPlayer: any;
    private handlerPlay: any;
    private handlerPause: any;
    private handlerEnded: any;
    private handlerReady: any;
    private handlerExit: any;

    constructor(private navController: NavController) {}

    ngOnInit() {
        this.clip = history.state.clip;
    }

    async ngAfterViewInit() {
        // Define the platform and the video player
        this.videoPlayer = new CapacitorVideoPlayerWeb();

        setTimeout(async () => {
            // add plugin listeners
            await this.addListenersToPlayerPlugin();
            const props: any = {};

            // Initialize the video player
            if (this.clip!.video_url != null) {
                props.mode = environment.isWeb ? 'embedded' : 'fullscreen';
                props.url = this.clip!.video_url;
                props.playerId = 'video-player';
                props.componentTag = 'app-action-replay-view';
                props.showControls = true;
                props.chromecast = false;
                props.width = document.getElementById('video-player-container').clientWidth;
                props.height = document.getElementById('video-player-container').clientHeight;

                console.log(props.width, props.height);

                await this.videoPlayer.initPlayer(props);
            }
        }, 100);
    }

    async ngOnDestroy(): Promise<void> {
        // Remove the plugin listeners
        await this.handlerPlay.remove();
        await this.handlerPause.remove();
        await this.handlerEnded.remove();
        await this.handlerReady.remove();
        await this.handlerExit.remove();
        await this.videoPlayer.stopAllPlayers();
        return;
    }

    // *******************
    // Private Functions *
    // *******************

    // Define the plugin listeners
    private async addListenersToPlayerPlugin(): Promise<void> {
        this.handlerPlay = await this.videoPlayer.addListener(
            'jeepCapVideoPlayerPlay',
            (data: any) => {
                const fromPlayerId = data.fromPlayerId;
                const currentTime = data.currentTime;
                this.log(`<<<< onPlay in ViewerVideo ${fromPlayerId} ct: ${currentTime}`);
            },
            false
        );
        this.handlerPause = await this.videoPlayer.addListener(
            'jeepCapVideoPlayerPause',
            (data: any) => {
                const fromPlayerId = data.fromPlayerId;
                const currentTime = data.currentTime;
                this.log(`<<<< onPause in ViewerVideo ${fromPlayerId} ct: ${currentTime}`);
            },
            false
        );
        this.handlerEnded = await this.videoPlayer.addListener(
            'jeepCapVideoPlayerEnded',
            (data: any) => {
                const fromPlayerId = data.fromPlayerId;
                const currentTime = data.currentTime;
                this.log(`<<<< onEnded in ViewerVideo ${fromPlayerId} ct: ${currentTime}`);
                this.playerLeave();
            },
            false
        );
        this.handlerExit = await this.videoPlayer.addListener(
            'jeepCapVideoPlayerExit',
            (data: any) => {
                const dismiss = data.dismiss;
                this.log(`<<<< onExit in ViewerVideo ${dismiss}`);
                this.playerLeave();
            },
            false
        );
        this.handlerReady = await this.videoPlayer.addListener(
            'jeepCapVideoPlayerReady',
            (data: any) => {
                const fromPlayerId = data.fromPlayerId;
                const currentTime = data.currentTime;
                this.log(`<<<< onReady in ViewerVideo ${fromPlayerId} ct: ${currentTime}`);
            },
            false
        );
        return;
    }

    // Action when the player ended or exit
    private playerLeave() {
        this.navController.back();
        return;
    }

    private log(message?: any, ...optionalParams: any[]): void {
        if (environment.debug) {
            console.log(message, ...optionalParams);
        }
    }
}
