/* eslint-disable max-len */
import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { ThemeService } from '@services/theme.service';
import { Subscription } from 'dc-core/dc-backend/dc-classes';
import { AppFeaturesService, UltimateFeature } from 'src/app/core/app-features/services/app-features.service';
import { environment } from 'src/environments/environment';
import { PurchaseService } from 'src/providers/PurchaseService';
import { AuthService } from 'src/services/auth.service';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

import { AgreementDialogComponent } from '../agreement/agreement';
import { PolicyDialogComponent } from '../policy/policy';

@Component({
    selector: 'app-dialog-all-features',
    templateUrl: 'all-features.html',
})
export class AllFeaturesDialogComponent implements OnInit, AfterViewInit {
    public ultimateFeatures: UltimateFeature[] = [];
    public devicePlatform: 'web' | 'ios' | 'android' = null;

    public subscription: Subscription = null;
    public translateService: TranslateService = inject(TranslateService);
    public themeService: ThemeService = inject(ThemeService);
    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public platform: Platform,
        public purchases: PurchaseService
    ) {}

    ngOnInit() {
        if (environment.isWeb || !this.platform.is('capacitor')) {
            this.devicePlatform = 'web';
        } else if (this.platform.is('ios')) {
            this.devicePlatform = 'ios';
        } else if (this.platform.is('android')) {
            this.devicePlatform = 'android';
        }

        this.ga.trackView('Is Ultimate subscription dialog');

        // Get all Ultimate Features that are defined in the app-features service
        this.ultimateFeatures = this.appFeaturesService.getAvailableUltimateFeatures();

        this.initSwiper();
    }

    ngAfterViewInit() {
        this.subscription = this.auth.user.ultimate_subscription;
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    OpenAgreementDialog() {
        this._modal.create({ component: AgreementDialogComponent }).then((elem) => elem.present());
    }

    OpenPolicyDialog() {
        this._modal.create({ component: PolicyDialogComponent }).then((elem) => elem.present());
    }

    private initSwiper(): void {
        const swiperEl: SwiperContainer = document.querySelector('#ultimate-features-slider');
        const swiperParams: SwiperOptions = {
            pagination: false,
            navigation: true,
            autoplay: environment.isWeb
                ? {
                      delay: 3000,
                  }
                : false,
        };
        Object.assign(swiperEl, swiperParams);
        swiperEl.initialize();
    }
}
