import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SetSingleDartPayload, SingleDartEvent } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import { WebKeyboardContainerDirective } from 'src/directives/web-keyboard-container.directive';

import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-new-single-dart-keyboard',
    standalone: true,
    imports: [CommonModule, WebKeyboardContainerDirective, PrimaryButtonComponent, IconComponent],
    templateUrl: 'new-single-dart-keyboard.component.html',
})
export class NewSingleDartKeyboardComponent {
    @Input() myTurn = false;
    @Input() keepMultiplier = false;
    @Input() undoScore = false;
    @Input() currentSingleDartEvent: SingleDartEvent = null;

    @Output() setSingleDart = new EventEmitter<SetSingleDartPayload>();
    @Output() undoSingleDart = new EventEmitter();
    @Output() confirmSingleDarts = new EventEmitter<SetSingleDartPayload>();

    multiplier = 1;
    amount: number = null;

    selectMultiplier(multiplier) {
        this.multiplier = multiplier;
    }

    selectAmount(amount, multiplier = null) {
        this.amount = amount;

        let finalMultiplier = this.multiplier;
        if (amount === 25) {
            if (multiplier) {
                finalMultiplier = multiplier;
            } else {
                finalMultiplier = 1;
            }
        } else if (multiplier) {
            this.multiplier = multiplier;
        }

        this.setSingleDart.emit({
            amount: this.amount,
            multiplier: finalMultiplier,
            needConfirm: false,
            isOmni: false,
        });

        if (!this.keepMultiplier) {
            // Reset to 'single' after tap
            this.multiplier = 1;
        }
        this.amount = null;
    }

    emitUndoScore(): void {
        if (this.undoScore) {
            this.undoSingleDart.emit();
        }
    }

    emitConfirmSingleDarts(): void {
        this.confirmSingleDarts.emit();
    }
}
