<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'STATISTICS'" i18n="@@STATISTICS">STATISTICS</div>
    </div>
    <div class="slide-modal-content">
        @if (statistics) {
            <div class="flex justify-between py-2 border-b-1 border-gray-100">
                <div [translate]="'ALL_TIME_AVG'" i18n="@@ALL_TIME_AVG">All-time 501 avg.</div>
                <div>{{ statistics.all_time_average || 0 | number: '1.0-2' }}</div>
            </div>
            <div class="flex justify-between py-2 border-b-1 border-gray-100">
                <div [translate]="'DARTS_THROWN'" i18n="@@DARTS_THROWN">Darts thrown</div>
                <div>{{ statistics.darts_thrown || 0 }}</div>
            </div>
            <div class="flex justify-between py-2 border-b-1 border-gray-100">
                <div [translate]="'MATCHES'" i18n="@@MATCHES">Matches</div>
                <div>{{ statistics.matches_played || 0 }}</div>
            </div>
            <div class="flex justify-between py-2 border-b-1 border-gray-100">
                <div [translate]="'TRAININGS'" i18n="@@TRAININGS">Trainings</div>
                <div>{{ statistics.trainings_played || 0 }}</div>
            </div>
            <div class="flex justify-between py-2">
                <div [translate]="'HIGHEST_FINISHES'" i18n="@@HIGHEST_FINISHES">Highest finishes</div>
                <div>{{ statistics.highest_checkouts?.join(', ') }}</div>
            </div>
        }
    </div>
</div>
