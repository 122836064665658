import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { InGameKeyboardType } from '@dc-core/dc-gamelogic/game-interfaces';
import { TeamSwiperStat } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import { DartCounterMatchTeam } from '@dc-core/dc-gamelogic/in-game/match/match.in-game.classes';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MediaWatcherService } from '@services/media-watcher.service';
import { Subject, takeUntil } from 'rxjs';

import { CheckoutSuggestionComponent } from '../checkout-suggestion/checkout-suggestion.component';
import { RemainingScoreComponent } from '../remaining-score/remaining-score.component';

@Component({
    selector: 'app-match-big-swiper-stats',
    standalone: true,
    imports: [CommonModule, TranslateModule, RemainingScoreComponent, CheckoutSuggestionComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: 'match-big-swiper-stats.component.html',
})
export class MatchBigSwiperStatsComponent implements OnInit {
    @ViewChild('swiper', { static: false }) swiperRef: ElementRef | undefined;

    @Input() team: DartCounterMatchTeam;
    @Input() isOnline: boolean;
    @Input() checkoutPossible: boolean;
    @Input() halfWidth = false;
    @Input() keyboard: InGameKeyboardType;
    @Input() teamSwiperStats: TeamSwiperStat[];
    @Input() currentTeam: any;

    currentOrientation: 'portrait' | 'landscape';
    isTablet: boolean;
    isLargeTablet: boolean;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public preferenceService: DartCounterPreferenceService,
        private _ngZone: NgZone,
        private _mediaWatcherService: MediaWatcherService,
        private _screenOrientation: ScreenOrientation,
        private _platform: Platform
    ) {}

    ngOnInit(): void {
        this.isTablet = this._platform.is('capacitor') ? this._platform.is('tablet') : true;

        this.checkOrientation();
        this._screenOrientation
            .onChange()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._ngZone.run(() => {
                    this.checkOrientation();
                });
            });

        this._mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.isLargeTablet =
                    matchingAliases.includes('lg') &&
                    (this._platform.is('capacitor') ? this._platform.is('tablet') : true);
            });
    }

    async onSlidesChanged(type: 'small' | 'expanded') {
        const index = await this.swiperRef?.nativeElement.swiper.activeIndex;
        if (type === 'small') {
            this.teamSwiperStats[this.team.id].swiperStatsSmall = index;
        } else if (type === 'expanded') {
            this.teamSwiperStats[this.team.id].swiperStatsExpanded = index;
        }
    }

    checkOrientation() {
        if (this._screenOrientation.type.indexOf('portrait') >= 0) {
            this.currentOrientation = 'portrait';
        } else if (this._screenOrientation.type.indexOf('landscape') >= 0) {
            this.currentOrientation = 'landscape';
        }
    }

    ionViewWillLeave(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
