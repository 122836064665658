import { Component, inject } from '@angular/core';
import { HelpModalComponent } from '@components/help-modal/help-modal.component';
import { UserApiService } from '@dc-api/user.api.service';
import { Sound } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PreferenceLocalStorageKey } from 'dc-core/dc-services/preference/preference.models';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { GameLabelsDialogComponent } from 'src/dialogs/game-labels/game-labels.dialog';
import { HotkeysDialogComponent } from 'src/dialogs/hotkeys/hotkeys.dialog';
import { LinkSoundDialogComponent } from 'src/dialogs/link-sound/link-sound';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

/**
 * Generated class for the Settings page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-page-global-game-settings',
    templateUrl: 'global-game-settings.html',
})
export class GlobalGameSettingsComponent {
    public allowCaller: boolean;

    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public preferenceService: DartCounterPreferenceService,
        public translate: TranslateService,
        public modal: ModalController,
        private _alertService: DartCounterAlertService,
        private _userApiService: UserApiService
    ) {}

    toggleCaller() {
        this.preferenceService.changePreference(
            PreferenceLocalStorageKey.allowCaller,
            !this.preferenceService.allowCaller
        );
    }

    toggleHeadtohead() {
        this.preferenceService.changePreference(
            PreferenceLocalStorageKey.showHeadToHead,
            !this.preferenceService.showHeadToHead
        );
    }

    toggleHasCheckoutRate() {
        this.auth.user.profile.has_checkout_rate = !this.auth.user.profile.has_checkout_rate;
        this._userApiService.updateUser({
            profile: {
                has_checkout_rate: this.auth.user.profile.has_checkout_rate,
            },
        });
    }

    toggleActionReplays() {
        this.preferenceService.changePreference(
            PreferenceLocalStorageKey.enableActionReplays,
            !this.preferenceService.enableActionReplays
        );
    }

    ShowHotkeys() {
        this.modal
            .create({
                component: HotkeysDialogComponent,
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
            });
    }

    LinkName() {
        this.modal
            .create({
                component: LinkSoundDialogComponent,
                componentProps: {
                    user: this.auth.user,
                },
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === false) {
                        this.RemoveSound();
                    } else if (dialogRes.data !== null) {
                        this.AddSound(dialogRes.data);
                    }
                });
            });
    }

    openGameLabels() {
        this.modal
            .create({
                component: GameLabelsDialogComponent,
            })
            .then((elem) => {
                elem.present();
            });
    }

    AddSound(sound: Sound) {
        this._userApiService
            .updateUser({
                profile: {
                    sound_id: sound.id,
                },
            })
            .then(() => {
                this.auth.user.profile.sound = sound;
                this.auth.user.profile.sound_id = sound.id;
            });

        if (this.auth.user.is_ultimate) {
            this._alertService.createAlert({
                title: $localize`:@@SOUND_LINKED:Sound has been linked`,
            });
        }
    }

    RemoveSound() {
        this._userApiService
            .updateUser({
                profile: {
                    sound_id: null,
                },
            })
            .then(() => {
                this.auth.user.profile.sound = null;
                this.auth.user.profile.sound_id = null;
            });

        $localize`:@@SOUND_REMOVED:Sound has been removed`;
        this._alertService.createAlert({
            title: this.translate.instant('SOUND_REMOVED'),
        });
    }

    ionViewWillEnter() {
        this.allowCaller = this.preferenceService.allowCaller;
    }

    showHelpModal(title, contents) {
        this.modal
            .create({
                component: HelpModalComponent,
                componentProps: {
                    title: this.translate.instant(title),
                    contents: this.translate.instant(contents),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
            })
            .then((elem) => {
                elem.present();
            });
    }
}
