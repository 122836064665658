<div
    class="trbl-0 absolute inline-flex items-center justify-center"
    style="z-index: 30000"
    [ngStyle]="{
        'pointer-events': currentAlert() ? 'all' : 'none'
    }"
    [class.blurred-alert]="currentAlert()"
    (click)="checkBackdropDismiss()">
    @if (currentAlert()) {
        <div
            class="relative m-4 w-full max-w-sm rounded-lg bg-white px-6 py-10"
            style="z-index: 30001; pointer-events: all"
            [@fade]>
            @if (currentAlert().showCloseButton) {
                <app-icon
                    icon="close"
                    class="text-black absolute right-4 top-4 w-[1.5rem] cursor-pointer"
                    (click)="dismissAlert()"></app-icon>
            }

            <div class="flex flex-col items-center space-y-2.5">
                @if (currentAlert().icon) {
                    <div
                        class="inline-flex h-16 w-16 items-center justify-center rounded-full"
                        [class.border-2]="currentAlert().icon !== 'loading'"
                        [class.border-green]="currentAlert().icon === 'success'"
                        [class.border-red]="currentAlert().icon === 'error'"
                        [class.border-orange]="currentAlert().icon === 'warning'"
                        [class.border-blue]="currentAlert().icon === 'info'">
                        @if (currentAlert().icon === 'success') {
                            <svg
                                class="bg-green bg-opacity-5 text-green"
                                width="3.188rem"
                                height="3.125rem"
                                viewBox="0 0 51 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_229_18130)">
                                    <path
                                        d="M20.0573 31.6505L14.1043 25.6975C13.441 25.0342 12.3864 25.0342 11.7231 25.6975C11.0598 26.3608 11.0598 27.4154 11.7231 28.0787L18.8497 35.2053C19.5131 35.8687 20.5846 35.8687 21.2479 35.2053L39.2771 17.1932C39.9404 16.5298 39.9404 15.4753 39.2771 14.812C38.6138 14.1486 37.5592 14.1486 36.8959 14.812L20.0573 31.6505Z"
                                        fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_229_18130">
                                        <rect
                                            width="3.125rem"
                                            height="3.125rem"
                                            fill="white"
                                            transform="translate(0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        }

                        @if (currentAlert().icon === 'warning' || currentAlert().icon === 'info') {
                            <div
                                class="inline-flex h-16 w-16 items-center justify-center rounded-full bg-opacity-5"
                                [class.bg-orange]="currentAlert().icon === 'warning'"
                                [class.bg-blue]="currentAlert().icon === 'info'">
                                <svg
                                    class="h-8 w-8"
                                    [class.text-orange]="currentAlert().icon === 'warning'"
                                    [class.text-blue]="currentAlert().icon === 'info'"
                                    width="1.5rem"
                                    height="1.5rem"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997ZM12.0001 13.9997C11.4501 13.9997 11.0001 13.5497 11.0001 12.9997V10.9997C11.0001 10.4497 11.4501 9.99969 12.0001 9.99969C12.5501 9.99969 13.0001 10.4497 13.0001 10.9997V12.9997C13.0001 13.5497 12.5501 13.9997 12.0001 13.9997ZM13.0001 17.9997H11.0001V15.9997H13.0001V17.9997Z"
                                        fill="currentColor" />
                                </svg>
                            </div>
                        }

                        @if (currentAlert().icon === 'error') {
                            <svg
                                class="text-red"
                                width="2.938rem"
                                height="2.875rem"
                                viewBox="0 0 47 46"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_604_14966)">
                                    <path
                                        d="M35.5749 10.944C34.8274 10.1965 33.6199 10.1965 32.8724 10.944L23.4999 20.2974L14.1274 10.9249C13.3799 10.1774 12.1724 10.1774 11.4249 10.9249C10.6774 11.6724 10.6774 12.8799 11.4249 13.6274L20.7974 22.9999L11.4249 32.3724C10.6774 33.1199 10.6774 34.3274 11.4249 35.0749C12.1724 35.8224 13.3799 35.8224 14.1274 35.0749L23.4999 25.7024L32.8724 35.0749C33.6199 35.8224 34.8274 35.8224 35.5749 35.0749C36.3224 34.3274 36.3224 33.1199 35.5749 32.3724L26.2024 22.9999L35.5749 13.6274C36.3032 12.8991 36.3032 11.6724 35.5749 10.944Z"
                                        fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_604_14966">
                                        <rect width="46" height="46" fill="white" transform="translate(0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        }

                        @if (currentAlert().icon === 'loading') {
                            <app-icon
                                icon="loading"
                                size="extralarge"
                                class="mx-auto animate-spin text-orange"></app-icon>
                        }
                    </div>
                }

                <div class="text-center text-xl font-bold text-black" style="word-break: break-word">
                    {{ currentAlert().title }}
                </div>
                <div class="text-center text-base text-neutral-400">{{ currentAlert().text }}</div>
            </div>

            @if (currentAlert().cancelText || currentAlert().confirmText) {
                <div
                    class="mt-8 flex justify-between"
                    [class.space-x-2]="currentAlert().cancelText && currentAlert().confirmText">
                    @if (currentAlert().cancelText) {
                        <app-primary-button
                            size="normal"
                            [class.w-full]="!currentAlert().confirmText"
                            [addedClasses]="currentAlert().confirmText ? 'px-1' : 'px-4 w-full'"
                            color="black"
                            fill="outline"
                            (click)="dismissAlert(false)">
                            {{ currentAlert().cancelText }}
                        </app-primary-button>
                    }

                    @if (currentAlert().confirmText) {
                        <app-primary-button
                            size="normal"
                            [class.w-full]="!currentAlert().cancelText"
                            [addedClasses]="currentAlert().cancelText ? 'px-1' : 'px-4 w-full'"
                            [color]="currentAlert().confirmColor"
                            (click)="dismissAlert(true)">
                            {{ currentAlert().confirmText }}
                        </app-primary-button>
                    }
                </div>
            }
        </div>
    }
</div>
