import { Injectable, OnInit } from '@angular/core';
import { GameLabelApiService } from '../dc-api/game-label.api.service';
import { GameLabel, GameLabelCategory } from '../dc-backend/dc-classes';
import { GameMode } from '../dc-backend/dc-enums';
import { LocalStorageKey } from '../dc-localstorage';

@Injectable()
export class GameLabelService implements OnInit {
    public gameLabelCategories: GameLabelCategory[];
    public selectedLabels: GameLabel[] = [];

    constructor(private _gameLabelApiService: GameLabelApiService) {}

    ngOnInit(): void {
        const selectedLabels = localStorage.getItem(LocalStorageKey.selectedGameLabels);
        if (selectedLabels) {
            this.selectedLabels = JSON.parse(selectedLabels);
        }
    }

    setSelectedLabels(selectedLabels: GameLabel[]) {
        this.selectedLabels = selectedLabels;
        localStorage.setItem(LocalStorageKey.selectedGameLabels, JSON.stringify(this.selectedLabels));
    }

    assignSelectedLabels(gameMode: GameMode, gameId: number): void {
        this.selectedLabels.forEach((selectedLabel) => {
            this._gameLabelApiService.labelGame({ game_type: gameMode, game_id: gameId, label_id: selectedLabel.id });
        });
    }

    async checkSelectedLabels(gameLabelCategories: GameLabelCategory[]): Promise<void> {
        if (!this.selectedLabels.length) {
            return;
        }

        if (gameLabelCategories) {
            this.gameLabelCategories = gameLabelCategories;
        } else {
            await this._gameLabelApiService.getGameLabelCategories({}).then((res) => {
                this.gameLabelCategories = res.data;
            });
        }

        this.selectedLabels.forEach((selectedLabel, index) => {
            if (!this.labelExistsInCategories(selectedLabel)) {
                this.selectedLabels.splice(index, 1);
            }
        });

        localStorage.setItem(LocalStorageKey.selectedGameLabels, JSON.stringify(this.selectedLabels));
    }

    labelExistsInCategories(gameLabel: GameLabel): boolean {
        let exists = false;
        this.gameLabelCategories.forEach((category) => {
            category.labels.forEach((label) => {
                if (label.id === gameLabel.id) {
                    exists = true;
                }
            });
        });
        return exists;
    }
}
