<div class="normal-keyboard-container" appWebKeyboardContainer>
    <div class="key-wrapper">
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[0], 'full')">
            {{ hotkeys[0] }}
        </button>
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[1], 'full')">
            {{ hotkeys[1] }}
        </button>
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[2], 'full')">
            {{ hotkeys[2] }}
        </button>
    </div>
    <div class="key-wrapper">
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[3], 'full')">
            {{ hotkeys[3] }}
        </button>
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[4], 'full')">
            {{ hotkeys[4] }}
        </button>
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[5], 'full')">
            {{ hotkeys[5] }}
        </button>
    </div>
    <div class="key-wrapper">
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[6], 'full')">
            {{ hotkeys[6] }}
        </button>
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[7], 'full')">
            {{ hotkeys[7] }}
        </button>
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[8], 'full')">
            {{ hotkeys[8] }}
        </button>
    </div>
    <div class="key-wrapper">
        @if (undoScore) {
            <button
                type="button"
                data-twe-ripple-init
                data-twe-ripple-color="light"
                data-twe-ripple-centered="true"
                class="icon-key"
                (click)="emitUndoScore()">
                <app-icon class="h-8 w-8" icon="undo_outline" class="text-black"></app-icon>
            </button>
        } @else {
            <div class="flex-1 text-center"></div>
        }
        <button
            class="key"
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            (click)="onClick(hotkeys[9], 'full')">
            {{ hotkeys[9] }}
        </button>
        <div class="flex-1 text-center"></div>
    </div>
</div>
