import { LOCAL_GAME_SETTINGS_STORAGE_KEYS, ONLINE_GAME_SETTINGS_STORAGE_KEYS } from 'dc-core/dc-gamelogic/game-storage';
import { LocalStorageKey } from 'dc-core/dc-localstorage';

export class LocalStorageHelper {
    public static itemsToClearWhenLogOut(): string[] {
        return [
            LocalStorageKey.onlineSavedGameplay,
            LocalStorageKey.camZoomLevel,
            LocalStorageKey.preferences,
            LocalStorageKey.viewedCTAModules,
            LocalStorageKey.linkedOneSignalData,
            LocalStorageKey.recentFriendSearches,
            LocalStorageKey.lastRecapYear,

            LOCAL_GAME_SETTINGS_STORAGE_KEYS.MATCH,
            LOCAL_GAME_SETTINGS_STORAGE_KEYS.CRICKET_TACTICS,
            LOCAL_GAME_SETTINGS_STORAGE_KEYS.BOBS_27,
            LOCAL_GAME_SETTINGS_STORAGE_KEYS.SINGLES_TRAINING,
            LOCAL_GAME_SETTINGS_STORAGE_KEYS.DOUBLES_TRAINING,
            LOCAL_GAME_SETTINGS_STORAGE_KEYS.SCORE_TRAINING,
            LOCAL_GAME_SETTINGS_STORAGE_KEYS.AROUND_THE_CLOCK,
            LOCAL_GAME_SETTINGS_STORAGE_KEYS.SHANGHAI,
            LOCAL_GAME_SETTINGS_STORAGE_KEYS.CHECKOUT_121,

            ONLINE_GAME_SETTINGS_STORAGE_KEYS.ONLINE_X01_GAMES,
            ONLINE_GAME_SETTINGS_STORAGE_KEYS.ONLINE_CRICKET_TACTICS,
        ];
    }
}
