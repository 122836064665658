import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';

@Component({
    selector: 'app-omni-not-supported',
    templateUrl: 'omni-not-supported.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class OmniNotSupportedComponent implements OnInit {
    public hasOmni = false;

    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);
    private destroyRef: DestroyRef = inject(DestroyRef);

    ngOnInit(): void {
        this.smartDeviceApiService.hasOmni$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hasOmni) => {
            this.hasOmni = hasOmni;
        });
    }
}
