@if (icon() === 'premium') {
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12ZM8 15C8 13.67 10.665 13 12 13C13.335 13 16 13.67 16 15V15.5C16 15.775 15.775 16 15.5 16H8.5C8.225 16 8 15.775 8 15.5V15Z"
            fill="url(#paint0_linear_2311_22)" />
        <defs>
            <linearGradient id="paint0_linear_2311_22" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
                <stop stop-color="#ECD676" />
                <stop offset="0.24" stop-color="#D39E3A" />
                <stop offset="0.655" stop-color="#EDB948" />
                <stop offset="1" stop-color="#BB8B32" />
            </linearGradient>
        </defs>
    </svg>
} @else {
    <span
        class="flex"
        [class]="'icon-' + icon()"
        [class.!font-bold]="bold()"
        [ngClass]="{
            'text-[0.75rem]': size() === 'extrasmall',
            'text-[0.9rem]': size() === 'small',
            'text-[1.25rem]': size() === 'medium',
            'text-[1.5rem]': size() === 'normal',
            'text-[1.75rem]': size() === 'large',
            'text-[2.75rem]': size() === 'extralarge'
        }"></span>
}
