import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appScrollToBottom]',
})
export class ScrollToBottomDirective implements OnInit {
    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.scrollToBottom();
        }, 50);
    }

    scrollToBottom(delay = 0): void {
        setTimeout(() => {
            this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight;
        }, delay);
    }
}
