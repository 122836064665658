import { Injectable } from '@angular/core';
import { Platform, SoundPath } from '../../dc-backend/dc-enums';
import { environment } from 'src/environments/environment';
import {
    AudioServiceInterface,
    NativeAudioFunctions,
    NativeAudioPluginListenerHandle,
} from '../../dc-services/audio/audio-service.interface';
import { DartCounterPreferenceService } from '../preference/preference.service';
import { DartCounterSpeechToScoreService } from '../speech-to-score/speech-to-score.service';

@Injectable()
export class DartCounterAudioService implements AudioServiceInterface {
    platform: Platform;
    file: any;
    nativeAudio: NativeAudioFunctions;

    audioStack: {
        asset: string;
        type: 'file' | 'url';
    }[] = [];

    audioPlayer: HTMLAudioElement = undefined;
    playingAudio: {
        asset: string;
        type: 'file' | 'url';
    } = null;
    listeners: {
        onstart: NativeAudioPluginListenerHandle;
        onpause: NativeAudioPluginListenerHandle;
        onended: NativeAudioPluginListenerHandle;
        onerror: NativeAudioPluginListenerHandle;
    } = {
        onstart: null,
        onpause: null,
        onended: null,
        onerror: null,
    };
    callbackTimeout: any = null;

    isPlaying = false;

    constructor(
        private _preferenceService: DartCounterPreferenceService,
        private _speechToScoreService: DartCounterSpeechToScoreService
    ) {}

    public initialize(platform: Platform, file: any, nativeAudio: NativeAudioFunctions): void {
        this.platform = platform;
        this.file = file;
        this.nativeAudio = nativeAudio;
    }

    async changeAudioCategory(category: 'default' | 'voiceCall'): Promise<void> {
        if (this.platform === 'ios' && this.nativeAudio) {
            try {
                await this.nativeAudio.changeAudioCategory({ category });
            } catch (_) {}
        }
    }

    async playAudio(
        asset: string,
        type: 'file' | 'url',
        clearStack: boolean = true,
        secondsForTimeout: number = null
    ): Promise<void> {
        this.isPlaying = true;

        if (clearStack) {
            this.clearAudioStack();
        }

        if (this.callbackTimeout) {
            clearTimeout(this.callbackTimeout);
            this.callbackTimeout = null;
        }

        await this.stopAudio();

        return this.platform !== 'web' && this.file && this.nativeAudio
            ? this.playNativeAudio(asset, type, secondsForTimeout)
            : this.playHTMLAudio(asset, secondsForTimeout);
    }

    private playNativeAudio(asset: string, type: 'file' | 'url', secondsForTimeout: number = null): Promise<void> {
        return new Promise(async (resolve, reject) => {
            this.playingAudio = { asset, type };

            if (this._preferenceService.enableSpeechToScore) {
                this._speechToScoreService.setPlayingAudio(true);
            }

            this.listeners.onstart = await this.nativeAudio.addListener('onstart', () => {
                this.onStart(secondsForTimeout, resolve);
            });
            this.listeners.onpause = await this.nativeAudio.addListener('onpause', () => {
                this.onPause(secondsForTimeout, resolve);
            });
            this.listeners.onended = await this.nativeAudio.addListener('onended', () => {
                this.onEnded(secondsForTimeout, resolve);
            });
            this.listeners.onerror = await this.nativeAudio.addListener('onerror', (err: any) => {
                this.onError(secondsForTimeout, reject, err);
            });

            try {
                if (type === 'file') {
                    const finalAsset = this.adjustAssetPathForLocalFile(asset);
                    this.nativeAudio.playFromFile({ filePath: finalAsset });
                } else if (type === 'url') {
                    this.nativeAudio.playFromUrl({ url: asset });
                }
            } catch (err) {
                this.onCatch(secondsForTimeout, reject, err);
            }
        });
    }

    private playHTMLAudio(asset: string, secondsForTimeout: number = null): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.audioPlayer === undefined) {
                this.audioPlayer = typeof Audio !== 'undefined' ? new Audio() : null;
            }

            if (this.audioPlayer === null) {
                reject();
                return;
            }

            if (this._preferenceService.enableSpeechToScore) {
                this._speechToScoreService.setPlayingAudio(true);
            }

            this.audioPlayer.src = asset;
            this.audioPlayer.volume = 1.0;
            this.audioPlayer.load();

            this.audioPlayer.onpause = () => {
                this.onPause(secondsForTimeout, resolve);
            };
            this.audioPlayer.onended = () => {
                this.onEnded(secondsForTimeout, resolve);
            };
            this.audioPlayer.onerror = (err) => {
                this.onError(secondsForTimeout, reject, err);
            };

            this.audioPlayer.onloadedmetadata = () => {
                this.audioPlayer
                    .play()
                    .then(() => {
                        this.onStart(secondsForTimeout, resolve);
                    })
                    .catch((err) => {
                        this.onCatch(secondsForTimeout, reject, err);
                    });
            };
        });
    }

    private onStart(secondsForTimeout: number = null, resolve): void {
        if (secondsForTimeout) {
            this.callbackTimeout = setTimeout(() => {
                this.checkAudioStack();
                resolve();
            }, secondsForTimeout * 1000);
        }
    }

    private onPause(secondsForTimeout: number = null, resolve): void {
        if (!secondsForTimeout) {
            this.checkAudioStack();
            resolve();
        }
    }

    private onEnded(secondsForTimeout: number = null, resolve): void {
        if (!secondsForTimeout) {
            this.checkAudioStack();
            resolve();
        }
    }

    private onError(secondsForTimeout: number = null, reject, err): void {
        if (!secondsForTimeout) {
            this.checkAudioStack();
            reject(err);
        }
    }

    private onCatch(secondsForTimeout: number = null, reject, err): void {
        if (!this.callbackTimeout) {
            this.callbackTimeout = setTimeout(
                () => {
                    this.checkAudioStack();
                    reject(err);
                },
                secondsForTimeout ? secondsForTimeout * 1000 : 0
            );
        }
    }

    private adjustAssetPathForLocalFile(asset: string): string {
        asset = this.file.applicationDirectory + 'public/' + asset;
        asset = asset.replace(/^file:\/\//, '');
        return asset;
    }

    public clearAudioStack() {
        this.audioStack = [];
    }

    public playAudioFromDevice(
        path: SoundPath,
        asset: string,
        clearStack = true,
        secondsForTimeout: number = null
    ): Promise<void> {
        return this.playAudio('assets/sounds/' + path + '/' + asset, 'file', clearStack, secondsForTimeout);
    }

    public playAudioFromBackend(
        path: SoundPath,
        asset: string,
        clearStack = true,
        secondsForTimeout: number = null
    ): Promise<void> {
        return this.playAudio(
            environment.apiUrl + '/sound/' + path + '/' + asset,
            'url',
            clearStack,
            secondsForTimeout
        );
    }

    async checkAudioStack() {
        if (this.playingAudio) {
            try {
                await this.removeAllListeners();
            } catch (_) {}

            try {
                await this.nativeAudio.stopPlayingAudio();
            } catch (_) {}
        }

        if (this.audioStack.length) {
            const audio = this.audioStack.shift();
            this.playAudio(audio.asset, audio.type, false); // Don't clear the stack
        } else {
            this.isPlaying = false;
            if (this._preferenceService.enableSpeechToScore) {
                this._speechToScoreService.setPlayingAudio(false);
                this._speechToScoreService.restart();
            }
        }
    }

    public async stopAudio(clearStack: boolean = false) {
        if (clearStack) {
            this.clearAudioStack();
        }

        if (this.audioPlayer) {
            this.audioPlayer.pause();
        }

        if (this.playingAudio) {
            try {
                await this.removeAllListeners();
            } catch (_) {}

            try {
                await this.nativeAudio.stopPlayingAudio();
            } catch (_) {}
        }
    }

    async removeAllListeners(): Promise<void> {
        await this.listeners.onstart?.remove();
        await this.listeners.onpause?.remove();
        await this.listeners.onended?.remove();
        await this.listeners.onerror?.remove();
    }

    public addDeviceToAudioStack(path: SoundPath, asset: string) {
        if (this.isPlaying) {
            this.audioStack.push({
                asset: 'assets/sounds/' + path + '/' + asset,
                type: 'file',
            });
        } else {
            this.playAudioFromDevice(path, asset);
        }
    }

    public addBackendToAudioStack(path: SoundPath, asset: string) {
        if (this.isPlaying) {
            this.audioStack.push({
                asset: environment.apiUrl + '/sound/' + path + '/' + asset,
                type: 'url',
            });
        } else {
            this.playAudioFromBackend(path, asset);
        }
    }
}
