import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var Instagram = /** @class */function (_super) {
  __extends(Instagram, _super);
  function Instagram() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Instagram.prototype.isInstalled = function () {
    return cordova(this, "isInstalled", {
      "callbackStyle": "node"
    }, arguments);
  };
  Instagram.prototype.share = function (canvasIdOrDataUrl, caption) {
    return cordova(this, "share", {
      "callbackStyle": "node"
    }, arguments);
  };
  Instagram.prototype.shareAsset = function (assetLocalIdentifier) {
    return cordova(this, "shareAsset", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Instagram.ɵfac = /* @__PURE__ */(() => {
    let ɵInstagram_BaseFactory;
    return function Instagram_Factory(__ngFactoryType__) {
      return (ɵInstagram_BaseFactory || (ɵInstagram_BaseFactory = i0.ɵɵgetInheritedFactory(Instagram)))(__ngFactoryType__ || Instagram);
    };
  })();
  Instagram.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Instagram,
    factory: Instagram.ɵfac
  });
  Instagram.pluginName = "Instagram";
  Instagram.plugin = "cordova-instagram-plugin";
  Instagram.pluginRef = "Instagram";
  Instagram.repo = "https://github.com/vstirbu/InstagramPlugin";
  Instagram.platforms = ["Android", "iOS"];
  Instagram = __decorate([], Instagram);
  return Instagram;
}(AwesomeCordovaNativePlugin);
export { Instagram };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Instagram, [{
    type: Injectable
  }], null, {
    isInstalled: [],
    share: [],
    shareAsset: []
  });
})();
