import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { KeyboardClickEvent, User } from 'dc-core/dc-backend/dc-classes';
import { KeyboardClickSource } from 'dc-core/dc-backend/dc-enums';
import { HotkeyKeyboardOptions } from 'dc-core/dc-gamelogic/game-interfaces';
import { defaultHotkeys, HotkeyHelper } from 'dc-core/dc-helpers/hotkey.helper';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
const _IMPOSSIBLETHROWS = [163, 166, 169, 172, 173, 175, 176, 178, 179];

@Component({
    selector: 'app-hotkey-keyboard',
    templateUrl: 'hotkey-keyboard.component.html',
    styleUrls: ['hotkey-keyboard.component.scss'],
})
export class HotkeyKeyboardComponent implements OnChanges {
    // inputs
    @Input() user: User;
    @Input() options: HotkeyKeyboardOptions;
    @Input() visible = false;
    @Input() clickfn: any;
    @Input() keys: any = [];
    @Input() myTurn = false;
    @Input() timer;
    // outputs

    @Output() keyboardClickEvent = new EventEmitter<KeyboardClickEvent>();

    public hotkeys: number[] = [];

    public editMode = false;
    public selected: any = false;

    constructor(
        public translate: TranslateService,
        public preferenceService: DartCounterPreferenceService,
        private _alertService: DartCounterAlertService,
        private _userApiService: UserApiService,
        private auth: AuthService
    ) {}

    ngOnChanges(): void {
        this.hotkeys = this.user && this.user.hotkeys ? HotkeyHelper.fromHotkeys(this.user.hotkeys) : defaultHotkeys;
    }

    onClick(key: any, source: KeyboardClickSource) {
        this.keyboardClickEvent.emit({
            key: key,
            source: source,
        });
    }

    toggleEditmode() {
        if (this.editMode && this.user) {
            this.hotkeys.forEach((hotkey, index) => {
                if (hotkey < 0) {
                    this.hotkeys[index] = 0;
                } else if (hotkey > 180) {
                    this.hotkeys[index] = 180;
                }
            });

            this.user.hotkeys = HotkeyHelper.toHotkeys(this.hotkeys, this.user.id);

            if (this.user.id === this.auth.user.id) {
                this._userApiService
                    .updateHotkeysByUserId(HotkeyHelper.toHotkeys(this.hotkeys, this.user.id))
                    .then(() => {
                        this._alertService.createAlert({
                            title: $localize`:@@UPDATED_HOTKEYS:You have updated your hotkeys`,
                            icon: 'success',
                        });
                    })
                    .catch((err) => {
                        this._alertService.errorFromApi(err);
                    });
            }
        }

        this.editMode = !this.editMode;
        this.selected = false;
    }

    check(index) {
        const key = this.hotkeys[index];
        if (key > 180 || key < 0 || _IMPOSSIBLETHROWS.indexOf(key) >= 0) {
            // Show score error
            $localize`:@@INVALID_SCORE:{{var_score}} is impossible`;
            this._alertService.createAlert({
                title: this.translate.instant('INVALID_SCORE', { var_score: key }),
                icon: 'error',
            });
            this.hotkeys[index] = 100;
        }
    }
}
