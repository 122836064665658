import { Component } from '@angular/core';
import { AuthApiService } from '@dc-api/auth.api.service';
import { NewsletterApiService } from '@dc-api/newsletter.api.service';
import { ModalController, NavParams } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-enter-email-dialog',
    templateUrl: 'enter-email.html',
    styleUrls: ['enter-email.scss'],
})
export class EnterEmailDialogComponent {
    public email = '';
    newsletterOptIn = false;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public loading: DCLoadingService,
        private _alertService: DartCounterAlertService,
        private navParams: NavParams,
        private authApiService: AuthApiService,
        private _newsletterApiService: NewsletterApiService
    ) {}

    ionViewWillEnter() {
        this.email = this.navParams.get('email') ?? '';
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    submit() {
        if (!this.email.replace(/\s/g, '').length) {
            this._alertService.createAlert({
                title: $localize`:@@FILL_IN_REQUIRED_FIELDS:Please fill in the required fields`,
                icon: 'error',
            });
        } else {
            this._newsletterApiService
                .updateNewsletterOptIn({
                    newsletter_opt_in: this.newsletterOptIn,
                })
                .then((res) => {
                    if (res.data.message && this.newsletterOptIn) {
                        this._alertService.createAlert({
                            title: res.data.message,
                            icon: 'success',
                        });
                    }
                })
                .catch(console.error);

            this.authApiService
                .changeEmail({
                    email: this.email,
                    type: 'link',
                })
                .then(() => {
                    this.auth.user.email = this.email;

                    this.dismiss(true);
                })
                .catch((err) => this._alertService.errorFromApi(err));
        }
    }
}
