export class UltimateHelper {
    public static getFeatures(): string[] {
        const ultimateFeatures = [];
        ultimateFeatures.push($localize`:@@ULTIMATE_FEAT_1:MasterCaller Ray Martin calls your name`);
        ultimateFeatures.push($localize`:@@ULTIMATE_FEAT_2:Play unlimited online games`);
        ultimateFeatures.push($localize`:@@ULTIMATE_FEAT_3:Detailed all-time statistics`);
        ultimateFeatures.push($localize`:@@ULTIMATE_FEAT_6:Filter statistics by date`);
        return ultimateFeatures;
    }
}
