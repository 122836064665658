import { Injectable } from '@angular/core';
import { OmniLocalStorageKeys } from '@dc-core/dc-localstorage';
import { Platform } from '@ionic/angular';
import {
    CapacitorSmartDeviceClient,
    VirtCamCommandRequest,
    VirtCamCommands,
    VirtCamHelper,
    VirtCamResponses,
} from 'capacitor-smart-device-client/dist/esm';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
// import { Socket as ioSocket } from 'socket.io';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { SmartDevice } from '../../dc-backend/dc-classes';
import { DartCounterAlertService } from '../alert.service';
import {
    OmniCameraStatus,
    OmniCameraStatusResponse,
    OmniMessageType,
    OmniReport,
    OmniReportType,
    OmniRequest,
    OmniRequestType,
    OmniResponse,
    OmniResponseType,
} from './omni-interfaces';
import {
    OmniLoadingEvent as OmniAlertEvent,
    OmniBoardEmptyCheckEvent,
    OmniDartThrow,
    OmniInfo,
    OmniResponseMappingService,
    OmniSystemReady,
} from './omni-response-mapping.service';
import { environment } from 'src/environments/environment';

export enum ConnectionStatus {
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
    NONE = 'none',
    DISCONNECTED = 'disconnected',
    TIMED_OUT = 'timed_out',
}

export enum LogType {
    INCOMING,
    OUTGOING,
    ERROR,
    WARNING,
    INFO,
}

export enum OmniCommand {
    Interrupt = 'Interrupt',
    StartGame = 'StartGame',
    FinalSegments = 'FinalSegments',
    BoardEmpty = 'BoardEmpty',
    AllThrown = 'AllThrown',
    ForcePlayerChange = 'ForcePlayerChange',
    Calibrate = 'Calibrate',
    Info = 'info',
    Settings = 'Settings',
    GetState = 'GetState',
    SaveImages = 'SaveImages',
    Cam = 'Cam',
}

export interface LogEntry {
    type: LogType;
    message: string;
    timestamp: Date;
}

export interface OmniWebscocketCommand {
    command: OmniCommand;
}

export interface OmniScoreCorrectionCommand extends OmniWebscocketCommand {
    throwCorrections: ThrowCorrection[];
}

export interface OmniFinalSegmentsCommand extends OmniWebscocketCommand {
    segments: ScoredSegment[];
}

export interface ThrowCorrection {
    dartIndex: number;
    oldSegment: string;
    correctSegment: string;
}
export interface ScoredSegment {
    text: string;
    multiplier: number;
    amount: number;
    isOutside?: boolean;
    isDropout?: boolean;
}

export interface OmniCalibrateCommand extends OmniWebscocketCommand {
    isManual: boolean;
    boardInfo: DartBoardInfo;
    saveToArchive: boolean;
}

export interface DartBoardInfo {
    bullSize: number;
    sbullSize: number;
    innerTrebleSize: number;
    outerTrebleSize: number;
    innerDoubleSize: number;
    outerDoubleSize: number;
    boardSize: number;
}

export interface OmniCameraCommand extends OmniWebscocketCommand {
    camNumber: number;
}
export interface OmniStartGameCommand extends OmniWebscocketCommand {
    saveAllImages: boolean;
}

export interface omniDebugSettings {
    saveToArchive: boolean;
    saveAllImages: boolean;
}

export interface omniSettings {
    omniSingleDartCountdown: boolean;
    omniSoundEffects: boolean;
}

// export interface omniSoundSettings {
//     hitSound: 'none' | 'pling';
//     missSound: 'none' | 'pop';
// }

@Injectable({
    providedIn: 'root',
})
export class OmniCommunicationService {
    public smartDevice: SmartDevice & {
        freespace_in_MB?: number;
        successfulCamerasetup?: boolean;
        calibrationFailure?: boolean;
    };
    public port: number;
    public startGameAfterConnection = false;

    private logsSubject = new BehaviorSubject<LogEntry[]>([]);
    public logs$ = this.logsSubject.asObservable();

    private eventsSubject = new Subject<OmniResponse>();
    public events$ = this.eventsSubject.asObservable();

    private requestsSubject = new Subject<OmniRequest>();
    public requests$ = this.requestsSubject.asObservable();

    // Subject for single cams + full calibration report
    private camStatusSubject = new Subject<OmniCameraStatus>();
    public camStatus$ = this.camStatusSubject.asObservable();
    private calibrationReportSubject = new Subject<OmniReport>();
    public calibrationReports$ = this.calibrationReportSubject.asObservable();

    public statusSubject = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.NONE);
    public connectionStatus$ = this.statusSubject.asObservable();
    private socket: Socket = null;

    public debugSettings: omniDebugSettings = {
        saveToArchive: false,
        saveAllImages: false,
    };

    public omniSettings: omniSettings = {
        omniSingleDartCountdown: true,
        omniSoundEffects: true,
    };

    private ngUnsubscribe = new Subject<void>();

    constructor(
        public omniResponseMapping: OmniResponseMappingService,
        private _platform: Platform,
        private _alertService: DartCounterAlertService
    ) {
        localStorage.debug = '*';

        const debugSettings = localStorage.getItem(OmniLocalStorageKeys.omniDebugSettings);
        if (debugSettings) {
            this.debugSettings.saveToArchive =
                (JSON.parse(debugSettings) as omniDebugSettings).saveToArchive ?? this.debugSettings.saveToArchive;
            this.debugSettings.saveAllImages =
                (JSON.parse(debugSettings) as omniDebugSettings).saveAllImages ?? this.debugSettings.saveAllImages;
        }

        const omniSettings = localStorage.getItem(OmniLocalStorageKeys.omniSettings);
        if (omniSettings) {
            this.omniSettings.omniSingleDartCountdown =
                (JSON.parse(omniSettings) as omniSettings).omniSingleDartCountdown ??
                this.omniSettings.omniSingleDartCountdown;

            this.omniSettings.omniSoundEffects =
                (JSON.parse(omniSettings) as omniSettings).omniSoundEffects ?? this.omniSettings.omniSoundEffects;
        }
    }

    // To test report flows without needing an Omni
    public triggerTestReport() {
        console.log('_triggerTestReport');
        const report: OmniReport = {
            cam1: false,
            cam2: false,
            cam3: true,
            cam4: true,
            messageType: OmniMessageType.REPORT,
            reportType: OmniReportType.CAM_SETUP,
            humanReadable: null,
        };

        this.handleOmniReports(report);
    }

    public loadAndConnect(smartDevice: SmartDevice): void {
        this._alertService.createAlert({
            id: 'CONNECTING_TO_OMNI',
            title: 'Activating OMNI Auto Scoring',
            timer: null,
            icon: 'loading',
        });

        this.loadSmartDevice(smartDevice);
        this.connect(this.startGameAfterConnection);
    }

    public loadSmartDevice(smartDevice: SmartDevice, port = 8765): void {
        this.smartDevice = smartDevice;
        this.port = port;
    }

    public toggleDebugSetting(settingKey) {
        this.debugSettings[settingKey] = !this.debugSettings[settingKey];
        localStorage.setItem(OmniLocalStorageKeys.omniDebugSettings, JSON.stringify(this.debugSettings));
    }

    public toggleOmniSetting(settingKey) {
        this.omniSettings[settingKey] = !this.omniSettings[settingKey];
        localStorage.setItem(OmniLocalStorageKeys.omniSettings, JSON.stringify(this.omniSettings));
    }

    public connect(startGameAfterConnection: boolean = false): void {
        if (!this.canConnect()) {
            return;
        }

        this.startGameAfterConnection = startGameAfterConnection;

        this.cleanupSubscriptions();
        this.addLog(LogType.INFO, `Trying to connect to ${this.smartDevice.ip_address}:${this.port}`);
        const config: SocketIoConfig = {
            url: `ws://${this.smartDevice.ip_address}:${this.port}`,
            options: {
                transports: ['websocket', 'polling'],
                timeout: 3000,
                reconnection: true,
                reconnectionAttempts: 1,
            },
        };
        this.socket = new Socket(config);

        this.listenForErrors();
        this.listenForConnectionChanges();

        this.socket.connect();
    }

    public sendMessage(omniSocketCommand: OmniWebscocketCommand): void {
        if (this.socket && this.statusSubject.getValue() === ConnectionStatus.CONNECTED) {
            const stringifiedCommand = JSON.stringify(omniSocketCommand);
            this.socket.emit('message', stringifiedCommand);
            this.addLog(LogType.OUTGOING, stringifiedCommand);
        }
    }

    public startCalibration(calibrationCommand: OmniCalibrateCommand): boolean {
        if (this.socket && this.statusSubject.getValue() === ConnectionStatus.CONNECTED) {
            const stringifiedCommand = JSON.stringify(calibrationCommand);
            this.socket.emit('message', stringifiedCommand);
            this.addLog(LogType.OUTGOING, stringifiedCommand);
            return true;
        }
        return false;
    }

    public singleDartCountdownEnabled(): boolean {
        if (this.statusSubject.value === ConnectionStatus.CONNECTED) {
            return this.omniSettings.omniSingleDartCountdown;
        }
        // Default, we allow singledart countdown
        return true;
    }

    public sendCustomMessage(command: string): void {
        if (this.socket && this.statusSubject.getValue() === ConnectionStatus.CONNECTED) {
            this.socket.emit('message', command);
            this.addLog(LogType.OUTGOING, command);
        }
    }

    public startWaitingForDarts() {
        this.sendMessage({
            command: OmniCommand.StartGame,
            saveAllImages: !environment.production ? this.debugSettings.saveAllImages : false,
        } as OmniStartGameCommand);
    }

    public stopWaitingForDarts() {
        this.sendMessage({ command: OmniCommand.Interrupt });
    }

    public refreshIngameConnection() {
        if (this.socket && this.statusSubject.getValue() === ConnectionStatus.CONNECTED) {
            this.stopWaitingForDarts();
            setTimeout(() => {
                this.startWaitingForDarts();
            }, 500);
        } else {
            this.connect(true);
        }
    }

    private listenForMessages(): void {
        this.socket
            .fromEvent('message')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((socketResult: any) => {
                //Check if it's an object
                if (this.isObject(socketResult)) {
                    if (socketResult.messageType == OmniMessageType.ALERT) {
                        this.handleOmniAlertEvents(socketResult as OmniAlertEvent);
                    } else if (socketResult.messageType == OmniMessageType.REPORT) {
                        this.handleOmniReports(socketResult as OmniReport);
                    } else if (socketResult.messageType == OmniMessageType.REQUEST) {
                        console.log('INCOMING REQUEST', socketResult);
                        this.handleOmniRequests(socketResult as OmniRequest);
                    } else if (socketResult.messageType == OmniMessageType.INFO) {
                        this.handleInfoEvents(socketResult as OmniResponse);
                    }

                    if (socketResult.humanReadable) {
                        this.addLog(LogType.INCOMING, socketResult.humanReadable);
                    }
                } else {
                    this.addLog(LogType.INCOMING, socketResult);
                }
            });
    }

    private handleOmniAlertEvents(alertEvent: OmniAlertEvent) {
        if (!alertEvent.show) {
            this._alertService.closeAlertById(alertEvent.alertKey);
        } else {
            this._alertService.createAlert({
                id: alertEvent.alertKey,
                title: alertEvent.title || null,
                text: alertEvent.text || null,
                icon: alertEvent.type || 'info',
                timer: alertEvent.timeout || null,
                showCloseButton: alertEvent.showCloseButton,
                allowOutsideClick: false,
            });
        }
    }

    private handleOmniReports(report: OmniReport) {
        let camErrors = [];

        camErrors = this.getFaultyCamerasByReport(report);

        switch (report.reportType) {
            case OmniReportType.CALIB_DONE:
                this._alertService.clearAlerts();
                if (!camErrors.length) {
                    this.smartDevice.calibrationFailure = false;
                    this._alertService.createAlert({
                        id: 'CALIBRATED',
                        title: $localize`:@@CALIBRATED:Calibrated`,
                        allowOutsideClick: true,
                        showCloseButton: true,
                        timer: null,
                        text: $localize`:@@CALIBRATION_SUCCEEDED:The Omni is ready. Game on!`,
                        icon: 'success',
                    });
                } else {
                    this.smartDevice.calibrationFailure = true;
                    this._alertService.createAlert({
                        id: 'CALIBRATION_FAILED',
                        title: $localize`:@@CALIBRATION_FAILED:Calibration failed`,
                        timer: null,
                        showCloseButton: true,
                        text: `The following cameras failed calibrating: ${camErrors.join(', ')}`,
                        icon: 'error',
                    });
                }

                this.addCalibrationResult(report);

                break;
            case OmniReportType.CAM_SETUP:
                if (camErrors.length) {
                    this.smartDevice.successfulCamerasetup = false;
                    this.addSingleCamStatus({
                        camNumber: 1,
                        succeeded: report.cam1,
                        errors: [],
                    });
                    this.addSingleCamStatus({
                        camNumber: 2,
                        succeeded: report.cam2,
                        errors: [],
                    });
                    this.addSingleCamStatus({
                        camNumber: 3,
                        succeeded: report.cam3,
                        errors: [],
                    });
                    this.addSingleCamStatus({
                        camNumber: 4,
                        succeeded: report.cam4,
                        errors: [],
                    });
                } else {
                    this.smartDevice.successfulCamerasetup = true;
                }
                break;
            default:
                console.log('Report not handled yet:', report.reportType);
        }

        report.humanReadable = JSON.stringify(report);
    }

    private handleOmniRequests(request: OmniRequest) {
        if (request.requestType == OmniRequestType.GET_FINAL_SEGMENTS) {
            request.humanReadable = 'OMNI asks for the final segments';
        }

        //Add it to the Requests subject
        this.addRequest(request);
    }

    private handleInfoEvents(event: OmniResponse) {
        // If its a throw
        if (event.responseType === OmniResponseType.THROW) {
            this.omniResponseMapping.getDartScore(event as OmniDartThrow);
        } else if (event.responseType == OmniResponseType.BOARD_EMPTY_STATE) {
            event.humanReadable = 'Board empty: ' + (event as OmniBoardEmptyCheckEvent).is_empty;

            if ((event as OmniBoardEmptyCheckEvent).is_empty) {
                this._alertService.closeAlertById('REMOVE_DARTS');
                return;
            }

            this._alertService.createAlert({
                id: 'REMOVE_DARTS',
                title: $localize`:@@REMOVE_YOUR_DARTS:Remove your darts`,
                icon: 'info',
                timer: null,
                confirmButtonText: $localize`:@@BOARD_IS_EMPTY:The board is empty`,
                onConfirm: () => {
                    this.sendMessage({ command: OmniCommand.BoardEmpty });
                    // this.sendMessage({ command: OmniCommand.ForcePlayerChange });
                },
            });
            //NEW JSON response
        } else if (event.responseType === OmniResponseType.PLAYERCHANGE) {
            event.humanReadable = 'Omni asks for a playerchange';
        } else if (event.responseType === OmniResponseType.CAM_STATUS) {
            //When calibrating you get individual states per camera (Firmware update)
            // this.addSingleCamStatus((event as OmniCameraStatusResponse)?.camera);
        } else if (event.responseType === OmniResponseType.SYSTEM_READY) {
            const omniCamReady = (event as OmniSystemReady).waitingForDarts;

            event.humanReadable = 'System Ready, cam ready? ' + omniCamReady;
            if (omniCamReady) {
                this._alertService.clearAlerts();
            }
        } else if (event.responseType === OmniResponseType.INFO) {
            const omniInfo = event as OmniInfo;
            this.smartDevice.version = omniInfo.firmware;
            this.smartDevice.successfulCamerasetup = omniInfo.successfulCamerasetup;
            this.smartDevice.freespace_in_MB = omniInfo.freespace_in_MB;

            event.humanReadable = 'INFO: ' + JSON.stringify(omniInfo);
        } else {
            console.error('Unknown response type', event.responseType);
            event.humanReadable = JSON.stringify(event);
        }

        //Add it to the Events subject
        this.addEvent(event);
    }

    private getFaultyCamerasByReport(omniReport: OmniReport): string[] {
        let faultyCameras = [];

        if (!omniReport.cam4) {
            faultyCameras.push('Bottom left');
        }
        if (!omniReport.cam1) {
            faultyCameras.push('Top left');
        }
        if (!omniReport.cam2) {
            faultyCameras.push('Top right');
        }
        if (!omniReport.cam3) {
            faultyCameras.push('Bottom right');
        }

        return faultyCameras;
    }

    private isObject(value: any): boolean {
        return typeof value === 'object' && value !== null && !Array.isArray(value);
    }

    public canConnect() {
        // If there's a smartDevice and the status is not 'connected' OR 'connecting'
        return (
            (this.smartDevice && this.statusSubject.value !== ConnectionStatus.CONNECTED) ||
            this.statusSubject.value !== ConnectionStatus.CONNECTING
        );
    }

    private listenForErrors(): void {
        this.socket.on('connect_error', (error) => {
            console.log('EVENT: connect_error', error);
            this.addLog(LogType.ERROR, `Connect error: ${error}`);
            this.statusSubject.next(ConnectionStatus.DISCONNECTED);
            this.cleanupSubscriptions();
        });

        this.socket.on('connect_timeout', (data) => {
            console.log('EVENT: connect_timeout', data);
            this.addLog(LogType.ERROR, `Connection timeout`);
            this.statusSubject.next(ConnectionStatus.DISCONNECTED);
            this.cleanupSubscriptions();
        });

        this.socket.on('disconnect', (data) => {
            console.log('EVENT: disconnect', data);
            this.addLog(LogType.ERROR, `Disconnected from server`);
            this.statusSubject.next(ConnectionStatus.DISCONNECTED);
            this.cleanupSubscriptions();
        });
    }

    private listenForConnectionChanges(): void {
        this.socket.on('connect', () => {
            this.addLog(LogType.INFO, `Connected to server`);
            this.statusSubject.next(ConnectionStatus.CONNECTED);
            this._alertService.closeAlertById('CONNECTING_TO_OMNI');

            console.log('CONNECTED! startGame?', this.startGameAfterConnection);
            // We are connected > Ask the omni to watch for throws on the dartboard
            if (this.startGameAfterConnection) {
                this.startWaitingForDarts();
            }

            this.listenForMessages();

            // Initially ask for the Omni Info
            this.sendMessage({ command: OmniCommand.Info });
        });

        // this.socket.onAny((event, data) => {
        //     console.log('ANY EVENT', event, data);
        // });

        this.socket.on('connecting', () => {
            this.addLog(LogType.INFO, `Connecting to server`);
            this.statusSubject.next(ConnectionStatus.CONNECTING);
        });
    }

    private checkTLSSupport(): boolean {
        if (this._platform.is('capacitor')) {
            return true;
        }

        this._alertService.createAlert({
            title: 'Not supported',
            text: 'This feature is not implemented in the web-browser yet.',
            icon: 'error',
        });

        return false;
    }

    public reboot() {
        if (this.checkTLSSupport()) {
            this._alertService.createAlert({
                title: 'Rebooting OMNI',
                icon: 'loading',
            });

            const data: VirtCamCommandRequest = {
                command: VirtCamCommands.SystemReboot,
            };

            CapacitorSmartDeviceClient.sendTLSRequest({
                host: this.smartDevice.ip_address,
                port: 443,
                data: JSON.stringify(data),
            })
                .then((res) => {
                    const status = VirtCamHelper.getResponse(res).status;
                    if (status == VirtCamResponses.Success) {
                        this._alertService.createAlert({
                            title: 'Successfully rebooted the OMNI',
                            icon: 'success',
                        });
                    } else {
                        this._alertService.createAlert({
                            title: 'SystemReboot: ERROR',
                            icon: 'error',
                        });
                    }
                })
                .catch((err) => {
                    console.error('Error: ' + JSON.stringify(err));

                    this._alertService.createAlert({
                        title: 'SystemReboot: ERROR',
                        icon: 'error',
                    });
                });
        }
    }

    public shutdown() {
        if (this.checkTLSSupport()) {
            this._alertService.createAlert({
                title: 'Shutting down OMNI',
                icon: 'loading',
            });

            const data: VirtCamCommandRequest = {
                command: VirtCamCommands.SystemShutdown,
            };

            CapacitorSmartDeviceClient.sendTLSRequest({
                host: this.smartDevice.ip_address,
                port: 443,
                data: JSON.stringify(data),
            })
                .then((res) => {
                    const status = VirtCamHelper.getResponse(res).status;
                    if (status == VirtCamResponses.Success) {
                        this._alertService.createAlert({
                            title: 'Successfully shut down the OMNI',
                            icon: 'success',
                        });
                    } else {
                        this._alertService.createAlert({
                            title: 'SystemShutdown: ERROR',
                            icon: 'error',
                        });
                    }
                })
                .catch((err) => {
                    console.error('Error: ' + JSON.stringify(err));
                });
        }
    }

    public clearLog() {
        this.logsSubject.next([]);
    }

    private addLog(type: LogType, message: string): void {
        const newLog: LogEntry = {
            type,
            message,
            timestamp: new Date(),
        };
        const currentLogs = this.logsSubject.getValue();
        this.logsSubject.next([...currentLogs, newLog]);
    }

    private addEvent(event): void {
        this.eventsSubject.next(event);
    }

    private addRequest(request: OmniRequest): void {
        this.requestsSubject.next(request);
    }

    private addSingleCamStatus(camReport: OmniCameraStatus): void {
        //Optionally do something when a single cam gets reported
        console.log(`Report of camera ${camReport.camNumber}`, camReport);
        this.camStatusSubject.next(camReport);
    }

    private addCalibrationResult(fullCalibReport: OmniReport): void {
        this.calibrationReportSubject.next(fullCalibReport);
    }

    public disconnect(): boolean {
        if (this.statusSubject.value === ConnectionStatus.NONE) {
            return false;
        }

        this.cleanupSubscriptions();

        if (this.socket) {
            // Ensure no reconnection occurs by removing listeners and disconnecting cleanly
            this.socket.removeAllListeners();

            // Pass `true` to close the underlying connection without reconnecting
            this.socket.disconnect(true);
        }

        this.statusSubject.next(ConnectionStatus.NONE);
        this.addLog(LogType.INFO, 'Disconnected manually');

        return true;
    }

    private cleanupSubscriptions(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        // reset the subject in case we want to set up subscriptions again later
        this.ngUnsubscribe = new Subject<void>();
    }
}
