import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-amount-of-turns-dialog',
    templateUrl: 'amountofturns.dialog.html',
})
export class AmountOfTurnsDialogComponent implements OnInit {
    @Input() amountOfTurns: any;

    private modalController: ModalController = inject(ModalController);
    private formBuilder: FormBuilder = inject(FormBuilder);

    public turnsForm: FormGroup;

    public ngOnInit(): void {
        this.turnsForm = this.formBuilder.group({
            amountOfTurns: [this.amountOfTurns, [Validators.required, Validators.min(1), Validators.max(999)]],
        });
    }

    public save(): void {
        const amountOfTurns = parseInt(this.turnsForm.get('amountOfTurns').value, 10);
        this.modalController.dismiss(isNaN(amountOfTurns) ? null : amountOfTurns);
    }

    public dismiss(returnValue: any = null): void {
        if (returnValue !== null) {
            returnValue = Math.floor(returnValue);
        }
        this.modalController.dismiss(returnValue);
    }
}
