import { Injectable } from "@angular/core";

import { ApiService } from "./api.service";

@Injectable()
export class ConfigApiService extends ApiService {
    public getConfig = super
        .getFetcher()
        .path("/config")
        .method("get")
        .create();
    public storeConfig = super
        .getFetcher()
        .path("/config")
        .method("post")
        .create();
    public updateConfig = super
        .getFetcher()
        .path("/config/{configId}")
        .method("put")
        .create();
}
