<div class="popover-container">
    @if (ownCamera && !hasStreams) {
        <div
            class="popover-button"
            (click)="ClosePopover('activate_camera')"
            [translate]="'ACTIVATE_CAMERA'"
            i18n="@@ACTIVATE_CAMERA">
            Activate camera
        </div>
    } @else if (ownCamera && hasStreams) {
        <div
            class="popover-button"
            (click)="ClosePopover('activate_camera')"
            [translate]="'RECONNECT_YOUR_CAMERA'"
            i18n="@@RECONNECT_YOUR_CAMERA">
            Reconnect your camera
        </div>
    }

    @if (hasStreams) {
        <div
            class="popover-button"
            (click)="ClosePopover('view_camera')"
            [translate]="'VIEW_CAMERA'"
            i18n="@@VIEW_CAMERA">
            View camera
        </div>

        @if (!ownCamera) {
            <div
                class="popover-button"
                (click)="ClosePopover('refresh_camera')"
                [translate]="'REFRESH_CAMERA'"
                i18n="@@REFRESH_CAMERA">
                Refresh camera
            </div>
        }
    }
</div>
