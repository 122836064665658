<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="DismissModal()"></app-icon>
        <div class="title-container">
            <div class="title" [translate]="'QUIT_GAME'" i18n="@@QUIT_GAME">Quit game</div>
            <div class="subtitle" [translate]="'QUIT_THE_GAME'" i18n="@@QUIT_THE_GAME">
                Do you want to quit the game?
            </div>
        </div>
        <div class="button-container">
            <app-primary-button
                class="w-full"
                size="normal"
                color="red"
                addedClasses="px-4 w-full"
                (click)="DismissModal('quit')"
                [translate]="'QUIT'"
                i18n="@@QUIT">
                Quit
            </app-primary-button>
        </div>
    </div>
</modal-content>
