import { Component, Input, OnInit } from '@angular/core';
import { PlayerType } from '@dc-core/dc-gamelogic/settings/settings.globals';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-own-account-warning-dialog',
    templateUrl: 'own-account-warning.html',
})
export class OwnAccountWarningDialogComponent implements OnInit {
    @Input() playerType: PlayerType;
    @Input() users = [];
    @Input() teams = [];

    public hasLinked = false;

    constructor(private view: ModalController, private auth: AuthService) {}

    ngOnInit(): void {
        if (this.playerType === 'players') {
            this.teams = [{ users: this.users }];
        }
    }

    public dismiss(): void {
        this.view.dismiss(null);
    }

    public continue(): void {
        this.view.dismiss({
            users: this.users,
            teams: this.teams,
        });
    }

    linkOwn(teamIndex: number, userIndex: number = null): void {
        if (this.playerType === 'players') {
            this.users[userIndex] = this.auth.user;
        } else {
            this.teams[teamIndex].users[userIndex] = this.auth.user;
        }

        this.hasLinked = true;
    }
}
