import { DC_Environment, SUPPORTED_ENVIRONMENTS } from './environment.models';

export const environment: DC_Environment = {
    isWeb: true,
    local: false,
    debug: true,
    production: false,
    apiUrl: 'https://api.dev.dartcounter.argo-ict.net',
    stage: SUPPORTED_ENVIRONMENTS.DEVELOPMENT,
    firebase: {
        projectId: 'dartcounter-nosql',
        appId: '1:727944833839:web:641e22cfa072886dd10fda',
        storageBucket: 'dartcounter-nosql.appspot.com',
        apiKey: 'AIzaSyCAunJY2SA0FMUigCu5W-sjkh_fDoLMsDc',
        authDomain: 'dartcounter-nosql.firebaseapp.com',
        messagingSenderId: '727944833839',
        measurementId: 'G-C29RC4YG2X',
    },
    echo: {
        key: 'gw15jtm74slpgmfwyw3a',
        wsHost: 'wss-dev.dartcounter.net',
        wsPort: 443,
        wssPort: 443,
        scheme: 'https',
    },
    chromecast: {
        receiverApplicationId: '2B7D4C6C',
    },
    recaptcha: '6Ld0Zj8lAAAAAExqwkT4H9hPUwbX97k3_z8PdIXG',
};

export const socials = {
    facebook: {
        clientId: '224780451025678',
    },
    apple: {
        clientId: 'com.dartcounter.ios',
        redirectUrl: 'https://dartcounter.net',
    },
    google: {
        clientId: '1031691752383-lmrthmuhotrgj1pk6o7n7fk1kcve4m65.apps.googleusercontent.com',
    },
};
