import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appDropdownModalHeightDirective]',
})
export class DropdownModalHeightDirective implements OnInit {
    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        const contentHeight = document.getElementById('behind-dropdown-modal').offsetHeight;
        // if (this.el.nativeElement.offsetHeight > contentHeight) {
        this.el.nativeElement.style.height = contentHeight + 'px';
        // }
    }
}
