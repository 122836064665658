import { Component, inject, OnInit } from '@angular/core';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { LOCAL_GAME_UNFINISHED_KEYS } from '@dc-core/dc-gamelogic/game-storage';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { AuthService } from 'src/services/auth.service';

import { PromptDialogComponent } from '../prompt/prompt.dialog';

export interface UnfinishedGamesPayload {
    gameMode: GameMode;
    storage_key: LOCAL_GAME_UNFINISHED_KEYS;
}

export interface UnfinishedGamesDialogResult {
    game: any;
}

@Component({
    selector: 'app-unfinished-local-dialog',
    templateUrl: 'unfinishedLocal.dialog.html',
    styleUrls: ['unfinishedLocal.dialog.scss'],
})
export class UnfinishedLocalDialogComponent implements OnInit {
    public translateService: TranslateService = inject(TranslateService);

    unfinisheds: any[];
    gameMode: GameMode;

    private _storageKey: LOCAL_GAME_UNFINISHED_KEYS = null;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private modal2: ModalController,
        private navParams: NavParams
    ) {}

    ngOnInit() {
        this.gameMode = this.navParams.get('game_mode');
        this._storageKey = this.navParams.get('storage_key');

        this.unfinisheds = JSON.parse(localStorage.getItem(this._storageKey)) || [];
    }

    public dismiss(result: UnfinishedGamesDialogResult = null): void {
        this.view.dismiss(result);
    }

    public RemoveAll() {
        let promptDialog;
        this.modal2
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_MATCHES:Remove matches`,
                    text: $localize`:@@DELETE_THESE_MATCHES:Do you want to remove these matches?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        setTimeout(() => {
                            localStorage.removeItem(this._storageKey);
                            this.unfinisheds = [];
                            this.dismiss();
                        }, 1000);
                    }
                });
            });
    }

    public getName(match): string {
        const name = [];
        if (match.is_best_of) {
            name.push($localize`:@@BEST_OF:Best of`);
        } else {
            name.push($localize`:@@FIRST_TO:First to`);
        }
        name.push(match.goal_amount);
        if (match.has_sets) {
            name.push($localize`:@@SETS:Sets`);
        } else {
            name.push($localize`:@@LEGS:Legs`);
        }
        return name.join(' ');
    }

    public RemoveItemFromStorageList(index) {
        let promptDialog;
        this.modal2
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_MATCH:Remove game`,
                    text: $localize`:@@DELETING_LOCAL_MATCH:Do you want to remove this unfinished match?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinisheds.splice(index, 1);
                        localStorage.setItem(this._storageKey, JSON.stringify(this.unfinisheds));
                        if (!this.unfinisheds || !this.unfinisheds.length) {
                            this.dismiss();
                        }
                    }
                });
            });
    }

    public ContinueGame(game, index): void {
        const gameCopy = _.cloneDeep(game);
        this.unfinisheds.splice(index, 1);
        localStorage.setItem(this._storageKey, JSON.stringify(this.unfinisheds));
        this.dismiss({ game: gameCopy });
    }
}
