import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    effect,
    ElementRef,
    inject,
    input,
    InputSignal,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { DCOnlineCore } from '@dc-core/dc-gamelogic/online-core.functions';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { UserMedia } from '@dc-core/dc-services/dc-janus/DartCounterUserMedia';
import { ModalController, PopoverController } from '@ionic/angular';
import { PopoverService } from '@services/popover.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import {
    LiveCameraPreviewDialogComponent,
    LiveCameraPreviewDialogPayload,
} from 'src/dialogs/live-camera-preview/live-camera-preview.dialog';
import { environment } from 'src/environments/environment';
import {
    CAMERA_POPOVER_RESPONSE,
    CameraOptionsPopoverComponent,
} from 'src/pages/online/cam-options-popover/camera-options-popover.component';

@Component({
    selector: 'app-games-online-board-camera',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './games-online-board-camera.component.html',
    styleUrl: './games-online-board-camera.component.css',
})
export class GamesOnlineBoardCameraComponent implements OnInit, AfterViewInit, OnDestroy {
    public authenticatedUserId: InputSignal<number> = input.required<number>();
    public currentUserId: InputSignal<number> = input.required<number>();
    public players: InputSignal<DCFireStoreUser[]> = input.required<DCFireStoreUser[]>();
    public isSpectator: InputSignal<boolean> = input<boolean>(false);
    public visible: InputSignal<boolean> = input<boolean>(true);
    public onlineCore: DCOnlineCore = inject(DCOnlineCore);
    public allowOwnCamView: InputSignal<boolean> = input<boolean>(false);

    private popoverController: PopoverController = inject(PopoverController);
    private popoverService: PopoverService = inject(PopoverService);
    private modalController: ModalController = inject(ModalController);
    private ingameCameraService: InGameCameraService = inject(InGameCameraService);
    private cam1ResizeObserver: ResizeObserver;
    private cam2ResizeObserver: ResizeObserver;
    private isWeb: boolean = environment.isWeb;

    @ViewChildren('onlineBoardCam')
    private onlineBoardCams: QueryList<ElementRef<HTMLVideoElement>>;

    constructor() {
        effect(() => {
            const videoIdToActivate = [...this.cameraStreams().keys()].find(
                (userId) => userId === this.currentUserId()
            );
            const videoIdsToPause = [...this.cameraStreams().keys()].filter(
                (userId) => userId !== this.currentUserId()
            );

            this.toggleVideoPlayback(videoIdToActivate, videoIdsToPause);
        });
    }

    public ngOnInit(): void {
        const videoIdToActivate = [...this.cameraStreams().keys()].find((userId) => userId === this.currentUserId());
        const videoIdsToPause = [...this.cameraStreams().keys()].filter((userId) => userId !== this.currentUserId());

        this.toggleVideoPlayback(videoIdToActivate, videoIdsToPause);
    }

    public ngAfterViewInit(): void {
        if (this.isWeb) {
            setTimeout(() => {
                this.setupResizeObservers();
            }, 1000);
        }
    }

    public ngOnDestroy(): void {
        if (this.cam1ResizeObserver) this.cam1ResizeObserver.disconnect();
        if (this.cam2ResizeObserver) this.cam2ResizeObserver.disconnect();
    }

    public cameraStreams(): Map<number, UserMedia> {
        return this.ingameCameraService.userMediaMap;
    }

    public showCameraOptions(authenticatedUserId: number, currentUserId: number, players: DCFireStoreUser[]): void {
        if (authenticatedUserId !== currentUserId) {
            this.popoverController
                .create({
                    component: CameraOptionsPopoverComponent,
                    componentProps: {
                        userId: currentUserId,
                        currentUserId: authenticatedUserId,
                    },
                })
                .then((popover) => {
                    popover.present();
                    this.popoverService.init(popover);
                    popover.onDidDismiss().then((res) => {
                        this.popoverService.destroy();

                        const result = res.data as CAMERA_POPOVER_RESPONSE;
                        switch (result) {
                            case 'view_camera':
                                this.viewCamera(currentUserId);
                                break;
                            case 'refresh_camera':
                                this.ingameCameraService.refreshCameras(players, currentUserId);
                                break;
                        }
                    });
                });
        }
    }

    private setupResizeObservers() {
        this.onlineBoardCams.forEach((onlineBoardCam, index) => {
            if (index === 0) {
                this.cam1ResizeObserver = new ResizeObserver((entries) => {
                    for (const entry of entries) {
                        if (
                            entry.target === onlineBoardCam.nativeElement &&
                            onlineBoardCam.nativeElement.classList.contains('active-board-cam')
                        ) {
                            const height = entry.contentRect.height;
                            this.ingameCameraService.setPlayerCamMaxHeight(height);
                        }
                    }
                });

                this.cam1ResizeObserver.observe(onlineBoardCam.nativeElement);
            } else {
                this.cam2ResizeObserver = new ResizeObserver((entries) => {
                    for (const entry of entries) {
                        if (
                            entry.target === onlineBoardCam.nativeElement &&
                            onlineBoardCam.nativeElement.classList.contains('active-board-cam')
                        ) {
                            const height = entry.contentRect.height;
                            this.ingameCameraService.setPlayerCamMaxHeight(height);
                        }
                    }
                });

                this.cam2ResizeObserver.observe(onlineBoardCam.nativeElement);
            }
        });
    }

    private toggleVideoPlayback(videoIdToActivate: number, videoIdsToPause: number[]): void {
        videoIdsToPause.forEach((videoId) => {
            const videoElement = document.getElementById(`board-cam-${videoId}`) as HTMLVideoElement;
            videoElement?.pause();
        });

        const videoElement = document.getElementById(`board-cam-${videoIdToActivate}`) as HTMLVideoElement;
        videoElement?.play();
    }

    private viewCamera(userId: number): void {
        this.modalController
            .create({
                component: LiveCameraPreviewDialogComponent,
                componentProps: {
                    userId: userId,
                } as LiveCameraPreviewDialogPayload,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }
}
