import { Component, inject, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { DCFireStoreInvite } from '@dc-core/dc-firestore/globals/firestore.tables';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { AlertService } from '@dc-core/dc-services/alert.service';
import { ModalController } from '@ionic/angular/standalone';
import { Unsubscribe } from 'firebase/auth';
import { onSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { JanusServerService } from 'src/app/services/janus/janus-server.service';
import { JanusStateService } from 'src/app/services/janus/janus-state.service';
import { JanusSubscriberService } from 'src/app/services/janus/janus-subscriber.service';
import { JanusUserMedia, JanusUserMediaService } from 'src/app/services/janus/janus-user-media.service';
import { AuthService } from 'src/services/auth.service';

export interface AcceptDenyJoinGameRequestDialogResponse {
    acceptedInvite: boolean;
}

@Component({
    selector: 'app-accept-deny-join-game-request-dialog',
    templateUrl: 'accept-deny-join-game-request.html',
})
export class AcceptDenyJoinGameRequestDialogComponent implements OnInit, OnDestroy {
    @Input() queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>;
    gameplay: OnlineGameplay;
    invite: DCFireStoreInvite;
    unsubscribe: Unsubscribe;
    lobbySub: Subscription;

    private janusStateService: JanusStateService = inject(JanusStateService);
    private janusSubscriberService: JanusSubscriberService = inject(JanusSubscriberService);
    private janusServerService: JanusServerService = inject(JanusServerService);
    private janusUserMediaService: JanusUserMediaService = inject(JanusUserMediaService);

    public userMedia: WritableSignal<JanusUserMedia> = signal(this.janusUserMediaService.getDefaultUserMedia());

    private modalController: ModalController = inject(ModalController);

    constructor(
        public onlineFunctions: OnlineFunctions,
        private _alertService: AlertService,
        private _lobbySettingsCollectionService: LobbySettingsCollectionService,
        public auth: AuthService
    ) {}

    ngOnInit() {
        this.invite = this.queryInvite.data();

        this.unsubscribe = onSnapshot(this.queryInvite.ref, (docSnapshot) => {
            if (!docSnapshot.exists()) {
                this._alertService.createAlert({
                    title: $localize`:@@INVITE_IS_CANCELLED:The invite has been cancelled`,
                    icon: 'info',
                    showCloseButton: true,
                });

                this.modalController.dismiss();
                this.unsubscribe();
            }
        });

        this.lobbySub = this._lobbySettingsCollectionService.watchOwnLobbyGames().subscribe((onlineGameplays) => {
            if (onlineGameplays.length) {
                this.gameplay = _.first(onlineGameplays);
                this.gameplay.players[1] = this.invite.user;

                if (this.invite.user.room?.roomID) {
                    this.janusSubscriberService
                        .spectateRoom(this.invite.user.room, 'video', this.invite.user, this.userMedia, false, false)
                        .catch((error) => {
                            console.error('Error joining room:', error);
                        });
                }
            } else {
                this.decline();
            }
        });

        const firstTime = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog));
        if (!firstTime) localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
    }

    public decline(): void {
        this.unsubscribe();
        const response: AcceptDenyJoinGameRequestDialogResponse = {
            acceptedInvite: false,
        };
        this.modalController.dismiss(response);
    }

    public accept(): void {
        this.unsubscribe();
        const response: AcceptDenyJoinGameRequestDialogResponse = {
            acceptedInvite: true,
        };
        this.modalController.dismiss(response);
    }

    async ngOnDestroy(): Promise<void> {
        if (this.userMedia() && this.invite.user.room?.roomID) {
            await this.janusStateService
                .leaveRoomAndDetachAllHandles(this.invite.user.room.roomID)
                .catch(console.error)
                .finally(() => {
                    this.janusServerService.destroyUnused();
                });

            this.janusUserMediaService.cleanupUserMedia(this.userMedia(), true, true);
        }
    }
}
