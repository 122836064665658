import { Component, inject, OnInit } from '@angular/core';
import { App } from '@capacitor/app';
import { UserApiService } from '@dc-api/user.api.service';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { SpeechToScoreInfoDialogComponent } from 'src/dialogs/speech-to-score-info/speech-to-score-info.dialog';
import { environment } from 'src/environments/environment';
import { UpgradeService } from 'src/providers/UpgradeService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-ingame-menu',
    templateUrl: 'ingame-menu.html',
})
export class IngameMenuComponent implements OnInit {
    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    version;
    public options: {
        showSound?: boolean;
        showTacticsSize?: boolean;
        showAdvancedSound?: boolean;
    } = {};

    public ingameMode: GameMode = null;
    public ingameData: any = null;

    constructor(
        public platform: Platform,
        public modal: ModalController,
        private upgrade: UpgradeService,
        public auth: AuthService,
        public preferenceService: DartCounterPreferenceService,
        public videoRoomService: JanusVideoRoomService,
        public menu: MenuController,
        private _userApiService: UserApiService,
        private _ingameCameraService: InGameCameraService
    ) {
        this.options = {
            showSound: true,
            showAdvancedSound: true,
        };
    }

    async ngOnInit(): Promise<void> {
        try {
            const { version } = await App.getInfo();
            this.version = version;
        } catch (_) {}
    }

    onMenuOpen() {
        this.ingameMode = this.preferenceService.ingameMode;
        this.ingameData = this.preferenceService.ingameData;
    }

    SetOptions(options) {
        this.options = options;
    }

    async OpenSubscriptionDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.INGAMEMENU);
        this.modal
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }

    toggleCheckoutPercentage() {
        this.auth.user.profile.has_checkout_rate = !this.auth.user.profile.has_checkout_rate;

        this._userApiService.updateUser({
            profile: {
                has_checkout_rate: this.auth.user.profile.has_checkout_rate,
            },
        });
    }

    toggleActionReplays(): void {
        this.preferenceService.enableActionReplays = !this.preferenceService.enableActionReplays;
        this.preferenceService.savePreferences();

        if (this.preferenceService.enableActionReplays) {
            this._ingameCameraService.startRecording(
                this.auth.user.id,
                'clip',
                this.videoRoomService.ownCamera.camType
            );
        } else {
            this._ingameCameraService.stopRecording(this.auth.user.id, false).catch(console.error);
        }
    }

    showSpeechToScore(): void {
        this.modal
            .create({
                component: SpeechToScoreInfoDialogComponent,
            })
            .then((elem) => {
                elem.present();
            });
    }
}
