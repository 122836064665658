<div class="normal-keyboard-container" appWebKeyboardContainer>
    <div class="key-wrapper">
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(1, 'part')">
            1
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(2, 'part')">
            2
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(3, 'part')">
            3
        </button>
    </div>
    <div class="key-wrapper">
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(4, 'part')">
            4
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(5, 'part')">
            5
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(6, 'part')">
            6
        </button>
    </div>
    <div class="key-wrapper">
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(7, 'part')">
            7
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(8, 'part')">
            8
        </button>
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(9, 'part')">
            9
        </button>
    </div>
    <div class="key-wrapper">
        @if (undoScore) {
            <button
                type="button"
                data-twe-ripple-init
                data-twe-ripple-color="light"
                data-twe-ripple-centered="true"
                class="icon-key"
                (click)="emitUndoScore()">
                <app-icon class="h-8 w-8" icon="undo_outline" class="text-black"></app-icon>
            </button>
        } @else {
            <div class="flex-1 text-center"></div>
        }
        <button
            type="button"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            data-twe-ripple-centered="true"
            class="key"
            (click)="onClick(0, 'part')">
            0
        </button>
        <div class="flex-1 text-center"></div>
    </div>
</div>
