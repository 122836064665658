import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RatingApiService } from '@dc-api/rating.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { RatingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/ratings.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { DCFireStoreRating, DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { ModalController, NavController } from '@ionic/angular';
import { Timestamp } from 'firebase/firestore';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-rate-player-dialog',
    templateUrl: 'rate-player.dialog.html',
    styleUrls: ['rate-player.dialog.scss'],
})
export class RatePlayerDialogComponent implements OnInit {
    @Input() user: User;
    @Input() fsUser: DCFireStoreUser;

    public selectedStar = 0;

    constructor(
        public view: ModalController,
        public route: ActivatedRoute,
        public nav: NavController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        private _userApiService: UserApiService,
        private _ratingApiService: RatingApiService,
        private _usersCollectionService: UsersCollectionService,
        private _ratingsCollectionService: RatingsCollectionService
    ) {}

    ngOnInit(): void {
        this._ratingApiService
            .getRatingToUserById({ userId: this.user.id })
            .then((res) => {
                this.selectedStar = res.data?.rating > 0 ? res.data.rating : 0;
            })
            .catch(() => {
                this.selectedStar = 0;
            });
    }

    selectStar(star: number) {
        this.selectedStar = star;
    }

    send() {
        if (!this.selectedStar) {
            return false;
        }

        this._userApiService.rateUserById({ userId: this.user.id, rating: this.selectedStar }).finally(() => {
            const user = this._usersCollectionService.getDCFireStoreUser(this.auth.user);
            this._ratingsCollectionService.sendFirestoreRating({
                sender_uid: user.uid,
                receiver_uid: this.fsUser.uid,
                user,
                rating: this.selectedStar,
                sent_at: Timestamp.now(),
            } as DCFireStoreRating);
            this.dismissModal(this.selectedStar);
        });
    }

    public dismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
