<app-page-header mainNavigation="custom" color="transparent">
    <div title [translate]="'SPEECH_TO_SCORE'" i18n="@@SPEECH_TO_SCORE">Speech to score</div>
    <div right class="right-page-header">
        <app-icon icon="close" (click)="dismiss()"></app-icon>
    </div>
</app-page-header>

<ion-content scrollY="false" fullscreen class="bg-white-pattern text-black">
    <ion-row style="padding: 0.5rem; align-items: center" id="account">
        <p
            style="font-size: 16px; flex: 1 1 0%; padding: 0 1rem"
            [translate]="'SPEECH_TO_SCORE_INFO'"
            i18n="@@SPEECH_TO_SCORE_INFO">
            Use speech to score to control the app
        </p>
        <ion-toggle
            color="secondary"
            [(ngModel)]="preferenceService.enableSpeechToScore"
            (ionChange)="toggleSpeechToScore()"></ion-toggle>
    </ion-row>

    <div class="command-info-list">
        <div *ngFor="let info of speechToScoreService.commandInfo">
            <div style="padding: 0.5rem 1rem" [class.editing]="editableType && editableType !== info.type">
                <ion-label style="font-weight: semibold">{{ info.title }}</ion-label>
            </div>
            <div class="list-container" [class.editing]="editableType && editableType !== info.type">
                <div class="command-container">
                    <ng-container *ngFor="let list of info.list">
                        <div *ngIf="list.option === speechToScoreService.commandOptions[info.type]">
                            <div class="text-container">
                                <div *ngFor="let text of list.parts[0].texts">
                                    <div class="input-type" *ngIf="text.type === 'command'">"{{ text.text }}"</div>
                                    <div class="input-type" *ngIf="text.type === 'input'">{{ text.text }}</div>
                                    <div class="text-type" *ngIf="text.type === 'text'">{{ text.text }}</div>
                                    <div class="lang-type" *ngIf="text.type === 'lang'">
                                        <img
                                            style="width: 27px; height: 16px"
                                            src="assets/images/flags/assets/images/flags/rect/{{ text.flag }}" />
                                        <span>{{ text.text }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ion-icon
                    name="chevron-down-outline"
                    color="secondary"
                    style="font-size: 24px; padding: 0 0.5rem 0 1rem"
                    *ngIf="info.hasOptions && !editableType"
                    (click)="changeType(info.type)"></ion-icon>
                <ion-icon
                    name="chevron-up-outline"
                    color="secondary"
                    style="font-size: 24px; padding: 0 0.5rem 0 1rem"
                    *ngIf="info.hasOptions && editableType"
                    (click)="changeType(info.type)"></ion-icon>
            </div>
            <div class="options-container" *ngIf="editableType === info.type">
                <div class="list-container select-options">
                    <div class="command-container">
                        <ng-container *ngFor="let list of info.list">
                            <div
                                *ngIf="list.option !== speechToScoreService.commandOptions[info.type]"
                                (click)="changeOption(list.option)">
                                <div class="text-container">
                                    <div *ngFor="let text of list.parts[0].texts">
                                        <div class="input-type" *ngIf="text.type === 'command'">"{{ text.text }}"</div>
                                        <div class="input-type" *ngIf="text.type === 'input'">{{ text.text }}</div>
                                        <div class="text-type" *ngIf="text.type === 'text'">{{ text.text }}</div>
                                        <div class="lang-type" *ngIf="text.type === 'lang'">
                                            <img
                                                style="width: 27px; height: 16px"
                                                src="assets/images/flags/rect/{{ text.flag }}" />
                                            <span>{{ text.text }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>
