import { Shanghai, ShanghaiUser } from 'dc-core/dc-backend/dc-classes';
import { RangeMode, ShanghaiSettings, TrainingMode, WinningType } from 'dc-core/dc-backend/dc-enums';
import * as moment from 'moment';
import { LOCAL_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import {
    dateTimeFormat,
    RANGE_ONE_TO_SEVEN,
    RANGE_ONE_TO_TWENTY,
    THROW_LOW_TO_HIGH_SHANGHAI,
    TRAINING_MODES,
} from '../../settings.globals';
import { DartCounterGameSettings } from '../settings-classes';

export class DartCounterShanghaiSettings extends DartCounterGameSettings {
    public throwOrder: ShanghaiSettings;
    public throwOrders: { label: string; tag: string; type: ShanghaiSettings }[];

    public requireNumber: boolean;

    public requireTriple: boolean;
    public goalTriple: number;
    public minGoalTriple = 1;
    public maxGoalTriple = 20;

    public trainingMode: TrainingMode;
    public trainingModes = TRAINING_MODES;

    public range: RangeMode;
    public ranges = [RANGE_ONE_TO_SEVEN, RANGE_ONE_TO_TWENTY];

    public winningType: WinningType;

    constructor() {
        //Set the super-class settings
        super();
        this.storageKey = LOCAL_GAME_SETTINGS_STORAGE_KEYS.SHANGHAI;

        this.throwOrders = [THROW_LOW_TO_HIGH_SHANGHAI];

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        } else {
            this.setDefaults();
        }
    }

    private setDefaults() {
        this.throwOrder = 'low_to_high';
        this.playerType = 'players';
        this.trainingMode = 'training';
        this.range = 'one_to_twenty';
        this.requireNumber = false;
        this.requireTriple = false;
        this.goalTriple = 10;

        this.users = [];
        this.teams = [];
    }

    private setStoredSettings(storedSettings: DartCounterShanghaiSettings) {
        this.throwOrder = storedSettings.throwOrder;
        this.trainingMode = storedSettings.trainingMode;
        this.range = storedSettings.range;
        this.requireNumber = storedSettings.requireNumber;
        this.requireTriple = storedSettings.requireTriple;
        this.goalTriple = storedSettings.goalTriple;

        this.users = storedSettings.users;
        this.teams = storedSettings.teams;
    }

    changeRequireTriple(setting: boolean): void {
        this.requireTriple = setting;
    }

    changeTrainingMode(trainingMode: TrainingMode): void {
        this.trainingMode = trainingMode;
    }

    changeRequiredNumber(requireNumber: boolean): void {
        this.requireNumber = requireNumber;
    }

    changeRange(range: RangeMode): void {
        this.range = range;
        if (this.requireTriple && range === 'one_to_seven' && this.goalTriple > 7) {
            this.goalTriple = 7;
        }
    }

    saveGameSettings() {
        let storageObject = {
            throwOrder: this.throwOrder,
            playerType: this.playerType,
            trainingMode: this.trainingMode,
            range: this.range,
            requireNumber: this.requireNumber,
            requireTriple: this.requireTriple,
            goalTriple: this.goalTriple,
            users: this.users,
            teams: this.teams,
        };
        localStorage.setItem(this.storageKey, JSON.stringify(storageObject));
    }

    mapToShanghaiObject(): Shanghai {
        const users: ShanghaiUser[] = [];
        this.users.forEach((user, index) => {
            users.push({
                id: index + 1,
                shanghai_id: 0,
                name: user.id === null ? user.full_name : user.first_name,
                user_id: user.id,
                user,
                ghost_name: user.id === null ? user.full_name : null,
                is_verified: this.user.id === user.id ? true : false,
            });
        });

        return <Shanghai>{
            id: 1,
            settings: this.throwOrder,
            range_mode: this.range,
            training_mode: this.users.length > 1 ? this.trainingMode : 'training',
            require_number: this.requireNumber,
            goal_triple: this.requireTriple ? this.goalTriple : null,
            started_at: moment().utc().format(dateTimeFormat),
            users: users,
            turns: [],
        };
    }
}
