import { effect, inject, Injectable, signal, untracked, WritableSignal } from '@angular/core';

import { AuthService } from '@services/auth.service';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { environment } from 'src/environments/environment';
import { CameraStreamClip, ReportedUser } from '../../dc-backend/dc-classes';
import { DCFireStoreUser } from '../../dc-firestore/globals/firestore.tables';
import { UserMedia } from '../dc-janus/DartCounterUserMedia';
import { JanusVideoRoomService } from '../dc-janus/janus-video-room.service';
import { DartCounterPreferenceService } from '../preference/preference.service';
import { CAMERA_TYPE, JanusRoom } from './camera.models';
import { DCOnlineCore } from '@dc-core/dc-gamelogic/online-core.functions';

export type MediaRecorderMode = 'clip' | 'report';

export type BlobsWithEvents = { blobs: { blob: Blob; length: number }[]; events: any[]; duration: number };

export type ActionReplayPromptType = 'icon' | 'full';
export type UpcomingActionReplay = { title: string; clipFrame?: string };

@Injectable()
export class InGameCameraService {
    public streamsLoading = false;
    public hasNetworkChangeListener = false;

    public userMediaMap = new Map<number, UserMedia>();
    public currentPlayingUserId: number = null;
    public currentUserMedia: UserMedia = null;
    public playerCamMaxHeight: WritableSignal<number> = signal(200);

    public clips: CameraStreamClip[] = [];
    public hasActionReplay: WritableSignal<ActionReplayPromptType> = signal(null);
    public currentActionReplay: WritableSignal<UpcomingActionReplay> = signal(null);
    public promptActionReplay: WritableSignal<ActionReplayPromptType> = signal(null);

    private authService: AuthService = inject(AuthService);
    private videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    private preferenceService: DartCounterPreferenceService = inject(DartCounterPreferenceService);
    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);
    private onlineCore: DCOnlineCore = inject(DCOnlineCore);

    constructor() {
        effect(() => {
            const promptType = this.hasActionReplay();
            if (promptType && this.currentPlayingUserId) {
                // Show prompt and stop recording
                this.stopActionReplayAndShowPrompt(this.authService.user.id, promptType);
            }
        });
    }

    public setPlayerCamMaxHeight(maxHeight: number): void {
        this.playerCamMaxHeight.set(maxHeight);
    }

    async addUserToGame(player: DCFireStoreUser, currentPlayerId: number) {
        const roomId = player?.room?.roomID;

        if (!roomId) {
            return;
        }

        await this.joinJanusRoom(player?.room, player, currentPlayerId);
    }

    async joinJanusRoom(janusRoom: JanusRoom, player: DCFireStoreUser, currentPlayerId: number = null): Promise<void> {
        if (this.authService.user.id == player.user_id && this.videoRoomService.ownCamera.roomID) {
            this.userMediaMap.set(player.user_id, this.videoRoomService.ownUserMedia);
        } else {
            if (this.userMediaMap.has(player.user_id)) {
                const existingUserMedia = this.userMediaMap.get(player.user_id);
                try {
                    await this.leaveUserMedia(existingUserMedia, false, null);
                } catch (error) {
                    console.error(error);
                } finally {
                    existingUserMedia.joinedRoomId = janusRoom.roomID;
                }
            } else {
                const userMedia = new UserMedia();
                userMedia.joinedRoomId = janusRoom.roomID;
                this.userMediaMap.set(player.user_id, userMedia);
            }

            this.videoRoomService.addUserToRoom(janusRoom.janusServerHost, janusRoom.roomID, player);
            this.videoRoomService
                .spectateRoom(
                    janusRoom,
                    'video',
                    player,
                    this.userMediaMap.get(player.user_id),
                    false,
                    this.onlineCore.isSpectating
                )
                .catch(console.error);
        }

        if (currentPlayerId) {
            this.setCurrentUserMedia(currentPlayerId);
        }
    }

    leaveUserMedia(userMedia: UserMedia, removeFromMap: boolean, userId: number): Promise<void> {
        return new Promise(async (resolve) => {
            if (!userMedia && userId) {
                userMedia = this.userMediaMap.get(userId);
            }

            if (userMedia) {
                try {
                    await this.videoRoomService.leaveRoomAndDetachAllHandles(userMedia.joinedRoomId, true);
                } catch (error) {
                    console.error('Error leaving room and detaching handle:', error);
                } finally {
                    userMedia.cleanupUserMedia(false, true);
                    if (removeFromMap) {
                        this.userMediaMap.delete(userId);
                    }

                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    checkPlayerRooms(players: DCFireStoreUser[], currentPlayerId: number) {
        players.forEach((player) => {
            this.addUserToGame(player, currentPlayerId);
        });
    }

    setCurrentUserMedia(userId: number): void {
        if (!userId) {
            this.currentPlayingUserId = null;
            this.currentUserMedia = null;
            return;
        }

        this.currentPlayingUserId = userId;
        const currentUserMedia = this.userMediaMap.get(userId);
        if (currentUserMedia) {
            this.currentUserMedia = currentUserMedia;
        } else {
            this.currentUserMedia = null;
        }
    }

    checkReportRecording(userId: number, authenticatedUserId: number): void {
        if (!this.appFeaturesService.enabledAppFeatures().report_recording) {
            return;
        }

        if (!userId) {
            return;
        }

        try {
            if (this.currentPlayingUserId && this.currentPlayingUserId != authenticatedUserId) {
                this.stopRecording(this.currentPlayingUserId, false);
            }
        } catch (err) {
            console.error(err);
        }

        if (userId != authenticatedUserId) {
            try {
                const userMedia = this.userMediaMap.get(userId);
                if (userMedia) {
                    this.startRecording(
                        userId,
                        'report',
                        userMedia.videoStreams.dualStreams ? CAMERA_TYPE.SMART_DEVICE : CAMERA_TYPE.EXTERNAL_DEVICE
                    );
                }
            } catch (err) {
                console.error(err);
            }
        }
    }

    checkActionReplayAndStartRecording(authenticatedUserId: number, record: boolean): void {
        if (!this.appFeaturesService.enabledAppFeatures().action_replays) {
            return;
        }

        if (this.preferenceService.enableActionReplays) {
            if (this.currentPlayingUserId) {
                if (this.currentPlayingUserId == authenticatedUserId && record) {
                    this.hasActionReplay.set(null);
                    this.currentActionReplay.set(null);
                    this.promptActionReplay.set(null);

                    if (this.videoRoomService.ownUserMedia?.videoStreams.hasStreams) {
                        this.startRecording(authenticatedUserId, 'clip', this.videoRoomService.ownCamera.camType);
                    }
                } else if (this.currentPlayingUserId != authenticatedUserId && !record) {
                    this.hasActionReplay.set(null);
                    this.currentActionReplay.set(null);
                    this.promptActionReplay.set(null);
                }
            }
        }
    }

    stopActionReplayAndShowPrompt(authenticatedUserId: number, promptType: ActionReplayPromptType): void {
        this.stopRecording(authenticatedUserId, true)
            .then((res) => {
                if (res) {
                    this.hasActionReplay.set(null);
                    this.promptActionReplay.set(promptType);
                } else {
                    this.hasActionReplay.set(null);
                    this.currentActionReplay.set(null);
                    this.promptActionReplay.set(null);
                }
            })
            .catch(() => {
                this.hasActionReplay.set(null);
                this.currentActionReplay.set(null);
                this.promptActionReplay.set(null);
            });
    }

    async startRecording(userId: number, mode: MediaRecorderMode, camType: CAMERA_TYPE): Promise<void> {
        let userMedia = this.userMediaMap.get(userId);
        if (!userMedia || userMedia.videoStreams.hasStreams === false) {
            return;
        }

        if (userMedia.recorders.board?.recorder?.mediaRecorder || userMedia.recorders.player?.recorder?.mediaRecorder) {
            try {
                if (userMedia.videoStreams.dualStreams) {
                    userMedia.recorders.board.recorder.recording = null;
                    userMedia.recorders.player.recorder.recording = null;

                    const boardRecorderState = await userMedia.recorders.board?.recorder?.mediaRecorder?.getState();
                    if (boardRecorderState !== 'recording') {
                        userMedia.recorders.board?.recorder?.mediaRecorder?.startRecording();
                    }

                    const playerRecorderState = await userMedia.recorders.player?.recorder?.mediaRecorder?.getState();
                    if (playerRecorderState !== 'recording') {
                        userMedia.recorders.player?.recorder?.mediaRecorder?.startRecording();
                    }
                } else {
                    userMedia.recorders.board.recorder.recording = null;

                    const boardRecorderState = await userMedia.recorders.board?.recorder?.mediaRecorder?.getState();
                    if (boardRecorderState !== 'recording') {
                        userMedia.recorders.board?.recorder?.mediaRecorder?.startRecording();
                    }
                }
            } catch (err) {
                console.error(err);
            }

            if (mode === 'clip') {
                userMedia.startDurationTimer();
            }
        } else {
            userMedia.autoStartRecording = true;
            userMedia.setupMediaRecorder(mode, camType);
        }
    }

    stopRecording(userId: number, generateActionReplayFrame: boolean): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            let userMedia = this.userMediaMap.get(userId);
            if (!userMedia) {
                resolve(false);
                return;
            }

            if (
                userMedia.recorders.board?.recorder?.mediaRecorder ||
                userMedia.recorders.player?.recorder?.mediaRecorder
            ) {
                if (generateActionReplayFrame) {
                    this.generateClipFrame(userMedia);
                }

                userMedia.stopDurationTimer();

                try {
                    if (userMedia.videoStreams.dualStreams) {
                        const boardRecorderState = await userMedia.recorders.board?.recorder?.mediaRecorder?.getState();
                        if (boardRecorderState === 'recording') {
                            await userMedia.recorders.board?.recorder?.mediaRecorder?.stopRecording();
                        }
                        userMedia.recorders.board.recorder.recording =
                            await userMedia.recorders.board?.recorder?.mediaRecorder.getBlob();

                        const playerRecorderState =
                            await userMedia.recorders.player?.recorder?.mediaRecorder?.getState();
                        if (playerRecorderState === 'recording') {
                            await userMedia.recorders.player?.recorder?.mediaRecorder?.stopRecording();
                        }
                        userMedia.recorders.player.recorder.recording =
                            await userMedia.recorders.player?.recorder?.mediaRecorder.getBlob();
                    } else {
                        const boardRecorderState = await userMedia.recorders.board?.recorder?.mediaRecorder?.getState();
                        if (boardRecorderState === 'recording') {
                            await userMedia.recorders.board?.recorder?.mediaRecorder?.stopRecording();
                        }
                        userMedia.recorders.board.recorder.recording =
                            await userMedia.recorders.board?.recorder?.mediaRecorder.getBlob();
                    }

                    if (!userMedia.recorders.board.recorder.recording?.size) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                } catch (err) {
                    console.error(err);
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        });
    }

    saveActionReplay(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            let userMedia = this.videoRoomService.ownUserMedia;
            if (!userMedia) {
                reject();
                return;
            }

            if (
                userMedia.recorders.board?.recorder?.mediaRecorder ||
                userMedia.recorders.player?.recorder?.mediaRecorder
            ) {
                const duration =
                    userMedia.recorders.board?.recorder.duration ?? userMedia.recorders.player?.recorder.duration;
                const currentActionReplay = untracked(this.currentActionReplay);
                const thumbnail = currentActionReplay.clipFrame;

                const formData = new FormData();
                formData.append('title', currentActionReplay.title);

                if (userMedia.videoStreams.dualStreams) {
                    formData.append('boardCamClips[]', userMedia.recorders.board.recorder.recording);
                    formData.append('playerCamClips[]', userMedia.recorders.player.recorder.recording);
                } else {
                    formData.append('boardCamClips[]', userMedia.recorders.board.recorder.recording);
                }

                try {
                    const response = await fetch(environment.apiUrl + '/camera-stream-clips/clip', {
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${this.authService.accessToken}`,
                        },
                        method: 'POST',
                    });

                    if (userMedia.videoStreams.dualStreams) {
                        userMedia.recorders.board.recorder.recording = null;
                        userMedia.recorders.player.recorder.recording = null;
                    } else {
                        userMedia.recorders.board.recorder.recording = null;
                    }

                    if (response.ok) {
                        const clip = (await response.json()) as CameraStreamClip;
                        clip.duration = duration;
                        clip.thumbnail_url = thumbnail;
                        this.clips.unshift(clip);
                        resolve();
                    } else {
                        reject();
                    }
                } catch (_) {
                    reject();
                }
            } else {
                reject();
            }
        });
    }

    private generateClipFrame(userMedia: UserMedia): void {
        try {
            const videoTrack = userMedia.videoStreams.board.stream.getVideoTracks()[0];
            const { width, height } = videoTrack.getSettings();

            // Create a video element for capturing the screenshot
            const videoElementForFrame: HTMLVideoElement = document.createElement('video') as HTMLVideoElement;
            videoElementForFrame.width = width / 2;
            videoElementForFrame.height = height / 2;
            videoElementForFrame.playsInline = true;
            videoElementForFrame.muted = true;
            videoElementForFrame.srcObject = userMedia.videoStreams.board.stream;

            // Handle the event when video metadata is loaded
            videoElementForFrame.onloadedmetadata = () => {
                videoElementForFrame
                    .play()
                    .then(() => {
                        // Create a canvas element to draw the video frame
                        const canvas = document.createElement('canvas') as HTMLCanvasElement;
                        canvas.width = width / 2;
                        canvas.height = height / 2;

                        // Draw the current frame of the video onto the canvas
                        const context = canvas.getContext('2d');
                        if (context) {
                            context.drawImage(videoElementForFrame, 0, 0, canvas.width, canvas.height);
                            // Convert canvas to data URL and update the Action Replay
                            this.currentActionReplay.update((currentActionReplay) => {
                                currentActionReplay.clipFrame = canvas.toDataURL('image/png');
                                return currentActionReplay;
                            });
                            canvas.remove();
                        }
                    })
                    .finally(() => {
                        this.cleanupVideoElement(videoElementForFrame);
                    });
            };

            videoElementForFrame.onerror = () => {
                this.cleanupVideoElement(videoElementForFrame);
            };
        } catch (err) {
            console.error(err);
        }
    }

    getReportClipFormData(userId: number): FormData {
        let userMedia = this.userMediaMap.get(userId);
        if (!userMedia) {
            return null;
        }

        if (userMedia.recorders.board?.recorder?.mediaRecorder || userMedia.recorders.player?.recorder?.mediaRecorder) {
            if (!userMedia.recorders.board.recorder.recording?.size) {
                return null;
            }

            const formData = new FormData();
            if (userMedia.videoStreams.dualStreams) {
                formData.append('boardCamClips[]', userMedia.recorders.board.recorder.recording);
                formData.append('playerCamClips[]', userMedia.recorders.player.recorder.recording);
            } else {
                formData.append('boardCamClips[]', userMedia.recorders.board.recorder.recording);
            }
            return formData;
        }

        return null;
    }

    async finishReportClip(reportedUser: ReportedUser, formData: FormData): Promise<void> {
        let userMedia = this.userMediaMap.get(reportedUser.reported_id);
        if (!userMedia) {
            return;
        }

        try {
            await fetch(environment.apiUrl + '/camera-stream-clips/' + reportedUser.id + '/report', {
                body: formData,
                headers: {
                    Authorization: `Bearer ${this.authService.accessToken}`,
                },
                method: 'POST',
            });
        } finally {
            if (userMedia.videoStreams.dualStreams) {
                userMedia.recorders.board.recorder.recording = null;
                userMedia.recorders.player.recorder.recording = null;
            } else {
                userMedia.recorders.board.recorder.recording = null;
            }
        }
    }

    refreshCameras(players: DCFireStoreUser[], currentPlayerId: number): void {
        this.videoRoomService.leaveSpectatingRooms(true).finally(async () => {
            this.checkPlayerRooms(players, currentPlayerId);
        });
    }

    init(): void {
        this.userMediaMap.clear();
        this.clips = [];
        this.hasActionReplay.set(null);
        this.currentActionReplay.set(null);
        this.promptActionReplay.set(null);
    }

    cleanService() {
        this.userMediaMap.forEach((userMedia, key) => {
            userMedia.cleanupUserMedia(false, key !== this.authService.user.id, key !== this.authService.user.id);
        });
        this.userMediaMap.clear();

        // Leave rooms & destroy handles
        this.videoRoomService.leaveSpectatingRooms(true).catch(console.error);

        this.currentUserMedia = null;
        this.streamsLoading = false;
    }

    cleanAfterGame(): void {
        this.currentPlayingUserId = null;

        this.videoRoomService.ownUserMedia.cleanupUserMedia(false, false, true);

        this.hasActionReplay.set(null);
        this.currentActionReplay.set(null);
        this.promptActionReplay.set(null);
    }

    private cleanupVideoElement(videoElement: HTMLVideoElement): void {
        videoElement.onloadedmetadata = null;
        videoElement.onerror = null;

        videoElement.remove();
    }
}
