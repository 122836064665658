@if (smallLayout()) {
    <div class="small-player-container-stat">
        <app-remaining-score [team]="team()" [busted]="team().busted"></app-remaining-score>
    </div>
} @else {
    @if (bigSwiper()) {
        <app-match-big-swiper-stats
            class="w-full"
            [class.h-fit]="!fullHeight()"
            [class.h-full]="fullHeight()"
            [team]="team()"
            [isOnline]="currentGame().is_online"
            [checkoutPossible]="currentGame().checkoutPossible"
            [halfWidth]="currentGame().teams.length === 1"
            [keyboard]="keyboard()"
            [teamSwiperStats]="teamSwiperStats()"
            [currentTeam]="currentTeam()"></app-match-big-swiper-stats>
    } @else {
        <app-match-team-swiper-stats
            class="w-full"
            [class.h-fit]="!fullHeight()"
            [class.h-full]="fullHeight()"
            [team]="team()"
            [isOnline]="currentGame().is_online"
            [checkoutPossible]="currentGame().checkoutPossible"
            [halfWidth]="currentGame().teams.length === 1"
            [keyboard]="keyboard()"
            [teamSwiperStats]="teamSwiperStats()"
            [dualStreams]="dualStream()"
            [currentTeam]="currentTeam()"></app-match-team-swiper-stats>
    }
}
