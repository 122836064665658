import { Component, inject, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { DCFireStoreInvite } from '@dc-core/dc-firestore/globals/firestore.tables';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { AlertService } from '@dc-core/dc-services/alert.service';
import { FreeMatchesService } from '@dc-core/dc-services/free-matches.service';
import { ModalController } from '@ionic/angular/standalone';
import { onSnapshot, QueryDocumentSnapshot, Unsubscribe } from 'firebase/firestore';
import { GamesLeaveBusterService } from 'src/app/modules/games/services/games-leave-buster.service';
import { JanusServerService } from 'src/app/services/janus/janus-server.service';
import { JanusStateService } from 'src/app/services/janus/janus-state.service';
import { JanusSubscriberService } from 'src/app/services/janus/janus-subscriber.service';
import { JanusUserMedia, JanusUserMediaService } from 'src/app/services/janus/janus-user-media.service';
import { JoyrideService } from 'src/providers/ngx-joyride/src/lib/services/joyride.service';
import { AuthService } from 'src/services/auth.service';

export interface AcceptDenyGameInviteRequestDialogResponse {
    acceptedInvite: boolean;
}

@Component({
    selector: 'app-accept-deny-game-invite-request-dialog',
    templateUrl: 'accept-deny-game-invite-request.html',
    styleUrls: ['accept-deny-game-invite-request.scss'],
})
export class AcceptDenyGameInviteRequestDialogComponent implements OnInit, OnDestroy {
    @Input() queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>;
    invite: DCFireStoreInvite;
    gameplay: OnlineGameplay;
    unsubscribe: Unsubscribe;

    private freeMatchesService: FreeMatchesService = inject(FreeMatchesService);
    private modalController: ModalController = inject(ModalController);
    private janusStateService: JanusStateService = inject(JanusStateService);
    private janusSubscriberService: JanusSubscriberService = inject(JanusSubscriberService);
    private janusServerService: JanusServerService = inject(JanusServerService);
    private janusUserMediaService: JanusUserMediaService = inject(JanusUserMediaService);

    public userMedia: WritableSignal<JanusUserMedia> = signal(this.janusUserMediaService.getDefaultUserMedia());

    constructor(
        public onlineFunctions: OnlineFunctions,
        private readonly joyrideService: JoyrideService,
        private _alertService: AlertService,
        public auth: AuthService,
        private _lobbySettingsCollectionService: LobbySettingsCollectionService,
        private gamesLeaveBusterService: GamesLeaveBusterService
    ) {}

    ngOnInit() {
        this.invite = this.queryInvite.data();

        this.unsubscribe = onSnapshot(this.queryInvite.ref, (docSnapshot) => {
            if (!docSnapshot.exists()) {
                this._alertService.createAlert({
                    title: $localize`:@@INVITE_IS_CANCELLED:The invite has been cancelled`,
                    icon: 'info',
                    showCloseButton: true,
                });

                this.modalController.dismiss();
                this.unsubscribe();
            } else {
                if (this.invite.user.room?.roomID) {
                    this.janusSubscriberService
                        .spectateRoom(this.invite.user.room, 'video', this.invite.user, this.userMedia, false, false)
                        .catch((error) => {
                            console.error('Error joining room:', error);
                        });
                }

                this._lobbySettingsCollectionService.getDocSnapshot(this.invite.gameplay_doc_id).then((snapshot) => {
                    this.gameplay = snapshot.data();
                });
            }
        });

        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog)) ?? true;
        if (showTour) {
            this.showTour();
            localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
        }
    }

    public canPlayOnline(): boolean {
        return this.freeMatchesService.canPlay;
    }

    public showTour(index = null) {
        let steps = ['firstStep'];
        if (index !== null) {
            steps = [steps[index]];
        }

        setTimeout(() => {
            this.joyrideService.startTour({
                steps: steps,
                showPrevButton: false,
                themeColor: '#444',
            });
        }, 500);
    }

    public decline(): void {
        this.unsubscribe();
        const response: AcceptDenyGameInviteRequestDialogResponse = {
            acceptedInvite: false,
        };
        this.modalController.dismiss(response);
    }

    public accept(): void {
        if (this.canPlayOnline()) {
            this.gamesLeaveBusterService.allowedToLeave = true;

            this.unsubscribe();
            const response: AcceptDenyGameInviteRequestDialogResponse = {
                acceptedInvite: true,
            };
            this.modalController.dismiss(response);
        }
    }

    async ngOnDestroy(): Promise<void> {
        if (this.userMedia() && this.invite.user.room?.roomID) {
            await this.janusStateService
                .leaveRoomAndDetachAllHandles(this.invite.user.room.roomID)
                .catch(console.error)
                .finally(() => {
                    this.janusServerService.destroyUnused();
                });

            this.janusUserMediaService.cleanupUserMedia(this.userMedia(), true, true);
        }
    }
}
