import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GameApiService } from '@dc-api/game.api.service';
import { AlertService } from '@dc-core/dc-services/alert.service';
import { AfterGameService } from '@services/after-game.service';
import { tap } from 'rxjs';

import { TournamentLogicService } from '../../tournaments/services/tournament-logic.service';
import { GamesLeaveBusterService } from '../services/games-leave-buster.service';

export const gamesOnlineLeaveBusterGuard: CanActivateFn = () => {
    const leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);
    const alertService: AlertService = inject(AlertService);
    const afterGameService: AfterGameService = inject(AfterGameService);
    const gameApiService: GameApiService = inject(GameApiService);
    const tournamentLogicService: TournamentLogicService = inject(TournamentLogicService);

    if (leaveBusterService.isAllowedToLeaveOnlineGame()) {
        if (leaveBusterService.gameIsFinished) {
            leaveBusterService.currentTournamentGame = null;
        } else {
            afterGameService.cleanupAfterOnlineGame();
        }

        return true;
    } else {
        if (leaveBusterService.isOpponentsTurn()) {
            alertService.createAlert({
                title: $localize`:@@CANT_LEAVE_RIGHT_NOW:You can't leave right now`,
                text: $localize`:@@ONLY_LEAVE_IN_YOUR_TURN:You can only leave the match when it's your turn`,
                icon: 'warning',
            });
            return false;
        }

        return leaveBusterService.startOnlineLeaveProcess().pipe(
            tap((hasLeft) => {
                if (hasLeft) {
                    if (!leaveBusterService.gameIsFinished) {
                        afterGameService.cleanupAfterOnlineGame();

                        if (leaveBusterService.currentTournamentGame) {
                            tournamentLogicService.quitGame({ ...leaveBusterService.currentTournamentGame });
                            leaveBusterService.currentTournamentGame = null;
                        } else {
                            gameApiService
                                .leaveOnlineGame({ activeGameDocId: leaveBusterService.activeOnlineGame.doc_id })
                                .catch(console.error);
                        }
                    }
                }
            })
        );
    }
};
