import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appMaintainHeight]',
})
export class MaintainHeightDirective implements AfterViewInit {
    prevHeight = null;

    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        this.prevHeight = this.el.nativeElement.style.height;
    }

    reset(): void {
        this.el.nativeElement.style.height = this.prevHeight;
    }
}
