import { Route } from '@angular/router';

export const smartDeviceRoutes: Route[] = [
    {
        path: 'smart-devices',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('./pages/smart-devices/smart-devices.component').then((c) => c.SmartDevicesComponent),
            },
            {
                path: 'invites',
                loadComponent: () =>
                    import('./pages/smart-device-invites/smart-device-invites.component').then(
                        (c) => c.SmartDeviceInvitesComponent
                    ),
            },
            {
                path: 'omni/:id/:mode',
                loadComponent: () => import('./pages/smart-devices/omni/omni.component').then((c) => c.OmniComponent),
            },
            {
                path: 'virt/:id',
                loadComponent: () => import('./pages/smart-devices/virt/virt.component').then((c) => c.VirtComponent),
            },
        ],
    },
];
