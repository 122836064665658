<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@501_AVG">501 avg.</ng-container>
    <ng-container i18n="@@TUT_501AVERAGE">The 501 average here, is the average over the last 2 months</ng-container>
}
<!-- End of translation containers-->

<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="close()"></app-icon>
        <div class="title-container">
            <div class="title" [translate]="'INVITE_A_FRIEND'" i18n="@@INVITE_A_FRIEND">Invite a friend</div>
        </div>
        <div class="mt-8 flex w-full flex-col" *ngIf="user">
            <div class="relative flex items-center justify-between rounded-md bg-neutral-100 px-4 py-2">
                <div class="flex items-center justify-start space-x-2">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="user.is_ultimate"
                        [badge]="user.profile?.badge"
                        [url]="user.profile?.profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div class="space-y-0.5 overflow-hidden">
                        <div class="flex items-center space-x-1">
                            <div class="truncate text-xs font-bold text-black">
                                {{ user.first_name || ('NO_NAME' | translate) }}
                            </div>
                        </div>
                        <app-online-player-stats [player]="user"></app-online-player-stats>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-center rounded-md p-5 w-full mt-4">
            <div class="font-bebas-neue text-black text-2xl" [translate]="'LAST_FIVE_GAMES'" i18n="@@LAST_FIVE_GAMES">
                Last results
            </div>
            @if (user.last_results?.length > 0) {
                <div class="mt-1">
                    @for (result of user.last_results; track result) {
                        <div
                            class="w-5 h-5 rounded-full mr-1 inline-block"
                            [ngClass]="{
                                'bg-red': result === 'lost',
                                'bg-green': result === 'won',
                                'bg-blue': result === 'tie'
                            }"></div>
                    }
                </div>
            } @else {
                <div class="text-black" [translate]="'NO_ONLINE_MATCHES'" i18n="@@NO_ONLINE_MATCHES">
                    No online matches played
                </div>
            }
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="trySendInvite()"
                [translate]="'SEND_INVITE'"
                i18n="@@SEND_INVITE">
                Send invite
            </app-primary-button>
        </div>
    </div>
</modal-content>
