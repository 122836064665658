import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-dartboard-dialog',
    templateUrl: 'dartboard.html',
    styleUrls: ['dartboard.scss'],
})
export class DartboardDialogComponent {
    public values: Array<any> = [];
    public totalScore = 0;

    constructor(public view: ModalController) {}

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    AddValue(amount: number, multiplier: string, name: string = null) {
        if (this.values.length < 3) {
            if (name === null) {
                name = multiplier + ' ' + amount;
            }

            let value = amount;
            switch (multiplier) {
                case 'DOUBLE':
                    value *= 2;
                    break;
                case 'TREBLE':
                    value *= 3;
                    break;
            }

            this.values.push({
                name,
                value,
            });

            this.totalScore += value;
        }
    }

    DeleteItemFromArray(item, array: any[]) {
        if (array.indexOf(item) >= 0) {
            this.totalScore -= item.value;
            array.splice(array.indexOf(item), 1);
        }
    }
}
