import { Component, inject, OnInit } from '@angular/core';
import { GameLabelApiService } from '@dc-api/game-label.api.service';
import { GameLabel, GameLabelCategory } from '@dc-core/dc-backend/dc-classes';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { AlertService } from 'dc-core/dc-services/alert.service';
import _ from 'lodash';
import { LoadingService } from 'src/app/services/loading.service';
import { AuthService } from 'src/services/auth.service';

import { GameLabelsDialogComponent, GameLabelsDialogPayload } from '../game-labels/game-labels.dialog';

export interface PickGameLabelsDialogPayload {
    pickedGameLabelCategories: GameLabelCategory[];
}

@Component({
    selector: 'app-pick-game-labels-dialog',
    templateUrl: 'pick-game-labels.dialog.html',
    styleUrls: ['pick-game-labels.dialog.scss'],
})
export class PickGameLabelsDialogComponent implements OnInit {
    gameLabelCategories: GameLabelCategory[] = null;
    pickedGameLabelCategories: GameLabelCategory[] = null;
    availableGameLabelCategories: GameLabelCategory[] = null;

    private modalController: ModalController = inject(ModalController);

    constructor(
        public auth: AuthService,
        private loading: LoadingService,
        private _alertService: AlertService,
        private _navParams: NavParams,
        private _gameLabelApiService: GameLabelApiService
    ) {}

    ngOnInit(): void {
        this.pickedGameLabelCategories = this._navParams.get('pickedGameLabelCategories') ?? [];

        this._gameLabelApiService.getGameLabelCategories({}).then((res) => {
            this.gameLabelCategories = res.data;
            this.checkAlreadyAssigned();
        });
    }

    label(gameLabel: GameLabel, categoryIndex: number, labelIndex: number): void {
        const pickedGameLabelCategory = _.find(
            this.pickedGameLabelCategories,
            (category) => category.id == gameLabel.category_id
        );
        if (pickedGameLabelCategory) {
            pickedGameLabelCategory.labels.push(gameLabel);
        } else {
            const gameLabelCategory = _.find(
                this.gameLabelCategories,
                (category) => category.id == gameLabel.category_id
            );
            if (gameLabelCategory) {
                const category = _.clone(gameLabelCategory);
                category.labels = [gameLabel];
                this.pickedGameLabelCategories.push(category);
            }
        }

        this.availableGameLabelCategories[categoryIndex].labels.splice(labelIndex, 1);
        if (!this.availableGameLabelCategories[categoryIndex].labels.length) {
            this.availableGameLabelCategories.splice(categoryIndex, 1);
        }

        this.sortAssigned();
        this.sortAvailable();
    }

    unlabel(gameLabel: GameLabel, categoryIndex: number, labelIndex: number): void {
        const availableLabelCategory = _.find(
            this.availableGameLabelCategories,
            (category) => category.id == gameLabel.category_id
        );
        if (availableLabelCategory) {
            availableLabelCategory.labels.push(gameLabel);
        } else {
            const gameLabelCategory = _.find(
                this.gameLabelCategories,
                (category) => category.id == gameLabel.category_id
            );
            if (gameLabelCategory) {
                const category = _.clone(gameLabelCategory);
                category.labels = [gameLabel];
                this.availableGameLabelCategories.push(category);
            }
        }

        this.pickedGameLabelCategories[categoryIndex].labels.splice(labelIndex, 1);
        if (!this.pickedGameLabelCategories[categoryIndex].labels.length) {
            this.pickedGameLabelCategories.splice(categoryIndex, 1);
        }

        this.sortAssigned();
        this.sortAvailable();
    }

    isAlreadyAssigned(gameLabel: GameLabel): boolean {
        if (this.pickedGameLabelCategories !== null) {
            for (const pickedGameLabelCategory of this.pickedGameLabelCategories) {
                for (const pickedGameLabel of pickedGameLabelCategory.labels) {
                    if (pickedGameLabel.id === gameLabel.id) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    checkAlreadyAssigned(): void {
        const availableGameLabelCategories: GameLabelCategory[] = [];
        this.gameLabelCategories.forEach((gameLabelCategory) => {
            gameLabelCategory.labels.forEach((gameLabel) => {
                if (!this.isAlreadyAssigned(gameLabel)) {
                    const availableLabelCategory = _.find(
                        availableGameLabelCategories,
                        (category) => category.id == gameLabelCategory.id
                    );

                    if (availableLabelCategory) {
                        availableLabelCategory.labels.push(gameLabel);
                    } else {
                        const category = _.clone(gameLabelCategory);
                        category.labels = [gameLabel];
                        availableGameLabelCategories.push(category);
                    }
                }
            });
        });

        this.availableGameLabelCategories = availableGameLabelCategories;
        this.sortAvailable();
    }

    sortAssigned(): void {
        this.pickedGameLabelCategories.sort((a, b) => a.final_title.localeCompare(b.final_title));
        this.pickedGameLabelCategories.forEach((category) => {
            category.labels.sort((a, b) => a.final_title.localeCompare(b.final_title));
        });
    }

    sortAvailable(): void {
        this.availableGameLabelCategories.sort((a, b) => a.final_title.localeCompare(b.final_title));
        this.availableGameLabelCategories.forEach((category) => {
            category.labels.sort((a, b) => a.final_title.localeCompare(b.final_title));
        });
    }

    openGameLabels() {
        this.modalController
            .create({
                component: GameLabelsDialogComponent,
                componentProps: {
                    gameLabelCategories: this.gameLabelCategories,
                } as GameLabelsDialogPayload,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => {
                    for (const assignedGameLabelCategory of this.pickedGameLabelCategories) {
                        const category = _.find(
                            this.gameLabelCategories,
                            (gameLabelCategory) => gameLabelCategory.id === assignedGameLabelCategory.id
                        );
                        if (category) {
                            assignedGameLabelCategory.final_title = category.final_title;
                            assignedGameLabelCategory.title = category.title;
                            assignedGameLabelCategory.color = category.color;
                            assignedGameLabelCategory.text_color = category.text_color;

                            assignedGameLabelCategory.labels.forEach((assignedGameLabel) => {
                                const gameLabel = _.find(
                                    category.labels,
                                    (gameLabelCategory) => gameLabelCategory.id === assignedGameLabelCategory.id
                                );
                                if (gameLabel) {
                                    assignedGameLabel.final_title = gameLabel.final_title;
                                    assignedGameLabel.title = gameLabel.title;
                                }
                            });
                        }
                    }

                    this.checkAlreadyAssigned();
                });
            });
    }

    public close(): void {
        this.modalController.dismiss(this.pickedGameLabelCategories);
    }
}
