import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class FriendApiService extends ApiService {
    public notifications = 0;

    // GET Requests
    public getIncomingFriends = super.getFetcher().path('/friends/incoming').method('get').create();
    public getFriends = super.getFetcher().path('/friends').method('get').create();

    // POST Requests
    public addFriendByUserId = super.getFetcher().path('/friends/add/{userId}').method('post').create();
    public acceptFriendByUserId = super.getFetcher().path('/friends/accept/{userId}').method('post').create();
    public denyFriendByUserId = super.getFetcher().path('/friends/deny/{userId}').method('post').create();

    // DELETE REQUESTS
    public removeFriendByUserId = super.getFetcher().path('/friends/remove/{userId}').method('delete').create();
}
