import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IDeactivatableComponent } from '../utils/deactivatable-component.interface';

@Injectable()
export class CanDeactivateGuard  {
  canDeactivate(component: IDeactivatableComponent): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate();
  }
}
