import { Injectable, signal, WritableSignal } from '@angular/core';

import { ApiService } from './api.service';
import { UserNotification } from '@dc-core/dc-backend/dc-classes';

@Injectable()
export class UserNotificationApiService extends ApiService {
    public newNotification: WritableSignal<UserNotification> = signal(null);

    public getUserNotifications = super.getFetcher().path('/user-notifications').method('get').create();
    public getUnseenUserNotificationsCount = super
        .getFetcher()
        .path('/user-notifications/unseen-count')
        .method('get')
        .create();
    public markUserNotificationsAsSeen = super
        .getFetcher()
        .path('/user-notifications/mark-as-seen')
        .method('post')
        .create();
    public markUserNotificationsAsRead = super
        .getFetcher()
        .path('/user-notifications/mark-as-read')
        .method('post')
        .create();
}
