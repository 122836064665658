import { DocumentReference, Timestamp } from '@angular/fire/firestore';
import { DCFireStoreUser } from '../dc-firestore/globals/firestore.tables';
import { CricketTactics, Match, TournamentGame } from './dc-classes';
import { CricketTacticsSettings, FirestoreUserBackendEventType, GameMode, StatisticNumberFormat } from './dc-enums';

export interface GameLabelDataList {
    label?: string;
    data: any[];
}

export class OnlineGameplaySettings {
    doc_id?: string;
    doc_ref?: DocumentReference<any>;
    name?: string;
    type: GameMode = 'match';
    owners: string[] = [];
    players: DCFireStoreUser[] = [];
    last_updated?: Timestamp; // Unix

    // Additional lobby settings
    onlineGameVersion?: number = null;
}

export class PlayerInterim {
    legs: number = 0;
    sets: number = 0;
    remaining?: number;
}
export class PublicOnlineGameplay extends OnlineGameplaySettings {
    is_best_of?: boolean;
    has_sets?: boolean;
    goal_amount?: number;
    start_score?: number;
    settings?: CricketTacticsSettings;
    player_interims: PlayerInterim[] = [];
    isFinished: boolean = false;

    has_camera?: boolean = false;
    has_virt_cam?: boolean = false;
    has_omni?: boolean = false;
    playernames?: string[] = [];
    is_private?: boolean = false;
    tournament_game?: TournamentGame;
}

export class OnlineGameplay extends OnlineGameplaySettings {
    game?: Match | CricketTactics;

    inGameTimer?: number;
    fastScoring?: boolean;
    players: DCFireStoreUser[] = [];
    timer_ends_at?: Timestamp;

    playerOneStatus?: PLAYERSTATUS = PLAYERSTATUS.NONE;
    playerTwoStatus?: PLAYERSTATUS = PLAYERSTATUS.NONE;

    // Additional lobby settings
    cameraOnly?: boolean;
    onlineGameStatus?: ONLINEGAMESTATUS = ONLINEGAMESTATUS.INITIAL;

    tournament_game?: TournamentGame;
}

export enum PLAYERSTATUS {
    NONE = 'none',
    READY = 'ready',
    SKIPH2H = 'skiph2h',
}

export enum ONLINEGAMESTATUS {
    INITIAL = 'initial',
    INVITE_ONLY = 'invite_only',
    READY_TO_START = 'ready_to_start',
    STARTED = 'started',
    INTERRUPTED = 'interrupted',
    SAVED = 'saved',
    REMOVED = 'removed',
    FINISHED = 'finished',
}

export interface GroupedGameStatistics {
    title: string;
    format?: StatisticNumberFormat;
    values: any[];
}

export type GameStatisticsCollection = GroupedGameStatistics[][];

export interface FirestoreUserBackendEvent {
    doc_id?: string;
    doc_ref?: DocumentReference<any>;
    type: FirestoreUserBackendEventType;
    data?: any;
}
