@if (classes) {
    <div
        class="rounded-sm justify-center items-center inline-flex w-full duration-200 {{ classes }}"
        [class.opacity-60]="hidden"
        [class.cursor-pointer]="clickable"
        [class.!h-10]="type() === 'withdraw_from_tournament'"
        [ngClass]="{ 'font-bebas-neue text-2xl h-12': big, 'h-9 font-bold text-sm': !big }">
        <div
            class="text-center flex items-center space-x-1 whitespace-nowrap"
            [class.text-xs]="(type() === 'waiting_for_approval' && !big) || timeUntil">
            <div>{{ text }}</div>
            @if (hasTimer) {
                <div class="{{ 'text-' + color }}">{{ timeUntil }}</div>
            }
        </div>
    </div>
}
