import { Component, OnDestroy } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import {
    DartbotCricketTacticsLevels,
    DartbotLevel,
    DartbotMatchLevels,
} from 'dc-core/dc-gamelogic/settings/local/dartbot/dartbot.config';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-vs-dartbot-dialog',
    templateUrl: 'vsdartbot.dialog.html',
})
export class VSDartbotDialogComponent implements OnDestroy {
    public dartbotLevels = [];
    public cpuLevel: number;
    public gameMode: string;

    private backButtonSubscription: Subscription;

    constructor(
        public platform: Platform,
        public view: ModalController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public modal: ModalController,
        public _modal: ModalController,
        public navParams: NavParams,
        private translate: TranslateService
    ) {}

    ionViewWillEnter() {
        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });

        this.cpuLevel = this.navParams.get('cpuLevel');
        this.gameMode = this.navParams.get('gameMode');
        switch (this.gameMode) {
            case 'match':
                this.dartbotLevels = DartbotMatchLevels;
                break;
            case 'cricket_tactics':
                this.dartbotLevels = DartbotCricketTacticsLevels;
                break;
        }
    }

    public confirm(): void {
        this.view.dismiss(this.dartbotLevels[this.cpuLevel - 1]);
    }

    public close(): void {
        this.view.dismiss();
    }

    public GetCPUAverage(): string {
        const dbLevel: DartbotLevel = this.dartbotLevels[this.cpuLevel - 1];
        if (dbLevel) {
            let res: any = dbLevel.minAvg;
            if (dbLevel.maxAvg) {
                res += ' - ' + dbLevel.maxAvg + ' ';
            } else {
                res += '+ ';
            }
            res += this.translate.instant('AVG');
            return res;
        }
        return '';
    }

    ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }
    }
}
