import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class CameraStreamClipApiService extends ApiService {
    public getCameraStreamClips = super.getFetcher().path('/camera-stream-clips').method('get').create();
    public showCameraStreamClipById = super.getFetcher().path('/camera-stream-clips/{clipId}').method('get').create();
    public updateCameraStreamClipById = super.getFetcher().path('/camera-stream-clips/{clipId}').method('put').create();
    public deleteCameraStreamClipById = super
        .getFetcher()
        .path('/camera-stream-clips/{clipId}')
        .method('delete')
        .create();
    public checkCameraStreamClipsLimit = super
        .getFetcher()
        .path('/camera-stream-clips/check-limit')
        .method('get')
        .create();
    public clipCameraStream = super.getFetcher().path('/camera-stream-clips/clip').method('post').create();
    public downloadCameraStreamClip = super
        .getFetcher()
        .path('/camera-stream-clips/{clipId}/download')
        .method('post')
        .create();
}
