<app-page-header mainNavigation="custom" defaultBack="statistics">
    <app-icon left icon="arrow_back" (click)="back(false)"></app-icon>
    <div title>{{ title | translate }}</div>
    <app-option-button right icon="bin" (click)="TryRemove()" color="text-white"></app-option-button>
</app-page-header>

<ion-content class="ion-text-black bg-white-pattern">
    @if (item) {
        <div class="bg-white flex flex-col text-center text-black py-6">
            <div class="text-lg font-bold">{{ item.name }}</div>
            <div class="text-sm text-neutral-700">
                {{ item.started_at | date: 'dd-MMM-yyyy H:mm' : '' : translateService.currentLang }}
            </div>
        </div>

        <div class="relative" appWebContainer>
            @if (item.training_mode === 'versus') {
                <div class="flex top-0 px-12 py-3">
                    <div [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-1/4'"></div>
                    <div
                        [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @for (user of item.users; track user) {
                            <div [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-1/' + item.users.length">
                                @if (user.user) {
                                    <app-profile-photo
                                        [ultimate]="user.user.is_ultimate"
                                        [badge]="user.user.profile?.badge"
                                        [size]="32"
                                        [url]="user.user.profile?.profile_photo_url"
                                        style="height: 32px"></app-profile-photo>
                                } @else {
                                    <app-profile-photo [size]="32" style="height: 32px"></app-profile-photo>
                                }
                                <div class="mt-1 text-center truncate capitalize">{{ getUserName(user) }}</div>
                            </div>
                        }
                    </div>
                </div>
            }
            <div class="mt-3 mx-4">
                <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-1/4'"
                        [translate]="'TOTAL_SCORE'"
                        i18n="@@TOTAL_SCORE">
                        Total score
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @for (user of item.users; track user) {
                            <div [ngClass]="item.users.length > 1 ? 'w-1/' + item.users.length : null">
                                {{ user.total_score }}
                            </div>
                        }
                    </div>
                </div>
                <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-1/4'"
                        [translate]="'DARTS_HIT'"
                        i18n="@@DARTS_HIT">
                        Darts hit
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @for (user of item.users; track user) {
                            <div [ngClass]="item.users.length > 1 ? 'w-1/' + item.users.length : null">
                                {{ user.darts_hit }}/{{ user.turns_count * 3 }}
                            </div>
                        }
                    </div>
                </div>
                <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                    <div
                        [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-1/4'"
                        [translate]="'HIT_RATE'"
                        i18n="@@HIT_RATE">
                        Hit rate
                    </div>
                    <div
                        [ngClass]="item.users?.length <= 2 ? 'w-1/2' : 'w-3/4'"
                        class="flex text-center justify-center">
                        @for (user of item.users; track user) {
                            <div [ngClass]="item.users.length > 1 ? 'w-1/' + item.users.length : null">
                                {{ (user.darts_hit / (user.turns_count * 3)) * 100 | number: '1.0-2' }}%
                            </div>
                        }
                    </div>
                </div>
            </div>

            @if (item && turns) {
                @for (user of item.users; track user; let scoreIndex = $index) {
                    <div class="mt-3 mx-4">
                        <div class="bg-white px-4 py-2.5" [ngClass]="user.expanded ? 'rounded-t-md' : 'rounded-md'">
                            <div
                                class="flex justify-between items-center text-lg text-black cursor-pointer"
                                (click)="expandItem(user)">
                                <div class="flex items-center">
                                    <div [translate]="'SCORES'" i18n="@@SCORES" class="text-sm mr-4">Scores</div>
                                    @if (item.training_mode === 'versus') {
                                        <app-profile-photo
                                            [size]="22"
                                            [ultimate]="user.is_ultimate"
                                            [badge]="user.profile?.badge"
                                            [url]="user.profile?.profile_photo_url"
                                            class="mr-2"
                                            style="height: 24px"></app-profile-photo>
                                        {{ getUserName(user) }}
                                    }
                                </div>
                                <div>
                                    @if (!user.expanded) {
                                        <app-icon icon="keyboard_arrow_down"></app-icon>
                                    } @else {
                                        <app-icon icon="keyboard_arrow_up"></app-icon>
                                    }
                                </div>
                            </div>
                        </div>
                        @if (user.expanded) {
                            @for (turn of turns[user.id]; track turn) {
                                <div
                                    class="flex justify-between border-b-1 items-center border-b-white px-4 py-3 text-sm">
                                    <div class="w-1/3">
                                        <span [translate]="'NUMBER'" i18n="@@NUMBER">Number</span>:
                                        {{ turn.number_to_throw }}
                                    </div>
                                    @if (!item.is_legacy) {
                                        <div>
                                            <span [translate]="'HITS'" i18n="@@HITS">Hits</span>:
                                            <span
                                                [ngClass]="{
                                                    positive: turn.darts_hit > 0,
                                                    negative: turn.darts_hit === 0
                                                }">
                                                {{ turn.darts_hit }}
                                            </span>
                                        </div>
                                    }
                                    <div class="text-right w-1/3">
                                        <span [translate]="'TOTAL_POINTS'" i18n="@@TOTAL_POINTS">Points</span>:
                                        <span
                                            [ngClass]="{
                                                positive: turn.total_score > 0,
                                                negative: turn.total_score === 0
                                            }">
                                            {{ turn.total_score }}
                                        </span>
                                    </div>
                                </div>
                            }
                        }
                    </div>
                }
            }
        </div>
    }
</ion-content>
