<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern">
    <app-page-header mainNavigation="custom" defaultBack="dashboard" color="transparent">
        <div title>Dev</div>
    </app-page-header>

    <div>
        <app-primary-button
            class="space-y-6"
            addedClasses="w-full"
            size="normal"
            type="submit"
            (click)="startRecording()">
            Start recording
        </app-primary-button>
        <app-primary-button
            class="space-y-6"
            addedClasses="w-full"
            size="normal"
            type="submit"
            (click)="stopRecording()">
            Stop recording
        </app-primary-button>
    </div>
</div>
