import { Component, OnInit } from '@angular/core';
import { GameLabelApiService } from '@dc-api/game-label.api.service';
import { GameLabel, GameLabelCategory } from '@dc-core/dc-backend/dc-classes';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { ModalController, NavParams } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import _ from 'lodash';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

import { GameLabelsDialogComponent, GameLabelsDialogPayload } from '../game-labels/game-labels.dialog';

export interface AssignGameLabelsDialogPayload {
    gameMode: GameMode;
    gameId: number;
    assignedGameLabels: GameLabel[];
}

@Component({
    selector: 'app-assign-game-labels-dialog',
    templateUrl: 'assign-game-labels.dialog.html',
    styleUrls: ['assign-game-labels.dialog.scss'],
})
export class AssignGameLabelsDialogComponent implements OnInit {
    gameMode: GameMode;
    gameId: number;

    gameLabelCategories: GameLabelCategory[] = null;
    assignedGameLabelCategories: GameLabelCategory[] = null;
    availableGameLabelCategories: GameLabelCategory[] = null;

    constructor(
        public modal: ModalController,
        public _modal: ModalController,
        public auth: AuthService,
        private loading: DCLoadingService,
        private _alertService: DartCounterAlertService,
        private _navParams: NavParams,
        private _gameLabelApiService: GameLabelApiService
    ) {}

    ngOnInit(): void {
        this.gameMode = this._navParams.get('gameMode');
        this.gameId = this._navParams.get('gameId');
        const assignedGameLabels = this._navParams.get('assignedGameLabels') ?? [];

        this._gameLabelApiService.getGameLabelCategories({}).then((res) => {
            this.gameLabelCategories = res.data;

            const assignedGameLabelCategories: GameLabelCategory[] = [];
            if (assignedGameLabels) {
                assignedGameLabels.forEach((assignedGameLabel) => {
                    const category = _.find(
                        assignedGameLabelCategories,
                        (assignedGameLabelCategory) => assignedGameLabelCategory.id === assignedGameLabel.category_id
                    );
                    if (category) {
                        category.labels.push(assignedGameLabel);
                    } else {
                        const gameLabelCategory = _.find(
                            this.gameLabelCategories,
                            (categoryFromList) => categoryFromList.id === assignedGameLabel.category_id
                        );
                        if (gameLabelCategory) {
                            const clonedCategory = _.clone(gameLabelCategory);
                            clonedCategory.labels = [assignedGameLabel];
                            assignedGameLabelCategories.push(clonedCategory);
                        }
                    }
                });
            }
            this.assignedGameLabelCategories = assignedGameLabelCategories;
            this.sortAssigned();

            this.checkAlreadyAssigned();
        });
    }

    label(gameLabel: GameLabel, categoryIndex: number, labelIndex: number): void {
        if (this.gameMode && this.gameId) {
            this._gameLabelApiService
                .labelGame({ game_type: this.gameMode, game_id: this.gameId, label_id: gameLabel.id })
                .then((res) => {
                    gameLabel.pivot = res.data;

                    this.labelGame(gameLabel, categoryIndex, labelIndex);
                });
        } else {
            this.labelGame(gameLabel, categoryIndex, labelIndex);
        }
    }

    labelGame(gameLabel: GameLabel, categoryIndex: number, labelIndex: number): void {
        const assignedGameLabelCategory = _.find(
            this.assignedGameLabelCategories,
            (category) => category.id === gameLabel.category_id
        );
        if (assignedGameLabelCategory) {
            assignedGameLabelCategory.labels.push(gameLabel);
        } else {
            const gameLabelCategory = _.find(
                this.gameLabelCategories,
                (category) => category.id === gameLabel.category_id
            );
            if (gameLabelCategory) {
                const category = _.clone(gameLabelCategory);
                category.labels = [gameLabel];
                this.assignedGameLabelCategories.push(category);
            }
        }

        this.availableGameLabelCategories[categoryIndex].labels.splice(labelIndex, 1);
        if (!this.availableGameLabelCategories[categoryIndex].labels.length) {
            this.availableGameLabelCategories.splice(categoryIndex, 1);
        }

        this.sortAssigned();
        this.sortAvailable();
    }

    unlabel(gameLabel: GameLabel, categoryIndex: number, labelIndex: number): void {
        if (this.gameMode && this.gameId) {
            this._gameLabelApiService
                .unlabelGame({ game_type: this.gameMode, game_id: this.gameId, label_id: gameLabel.id })
                .then(() => {
                    this.unlabelGame(gameLabel, categoryIndex, labelIndex);
                });
        } else {
            this.unlabelGame(gameLabel, categoryIndex, labelIndex);
        }
    }

    unlabelGame(gameLabel: GameLabel, categoryIndex: number, labelIndex: number) {
        const availableLabelCategory = _.find(
            this.availableGameLabelCategories,
            (category) => category.id === gameLabel.category_id
        );
        if (availableLabelCategory) {
            availableLabelCategory.labels.push(gameLabel);
        } else {
            const gameLabelCategory = _.find(
                this.gameLabelCategories,
                (category) => category.id === gameLabel.category_id
            );
            if (gameLabelCategory) {
                const category = _.clone(gameLabelCategory);
                category.labels = [gameLabel];
                this.availableGameLabelCategories.push(category);
            }
        }

        this.assignedGameLabelCategories[categoryIndex].labels.splice(labelIndex, 1);
        if (!this.assignedGameLabelCategories[categoryIndex].labels.length) {
            this.assignedGameLabelCategories.splice(categoryIndex, 1);
        }

        this.sortAssigned();
        this.sortAvailable();
    }

    isAlreadyAssigned(gameLabel: GameLabel): boolean {
        if (this.assignedGameLabelCategories !== null) {
            for (const assignedGameLabelCategory of this.assignedGameLabelCategories) {
                for (const assignedGameLabel of assignedGameLabelCategory.labels) {
                    if (assignedGameLabel.id === gameLabel.id) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    checkAlreadyAssigned(): void {
        const availableGameLabelCategories: GameLabelCategory[] = [];
        this.gameLabelCategories.forEach((gameLabelCategory) => {
            gameLabelCategory.labels.forEach((gameLabel) => {
                if (!this.isAlreadyAssigned(gameLabel)) {
                    const availableLabelCategory = _.find(
                        availableGameLabelCategories,
                        (category) => category.id === gameLabelCategory.id
                    );

                    if (availableLabelCategory) {
                        availableLabelCategory.labels.push(gameLabel);
                    } else {
                        const category = _.clone(gameLabelCategory);
                        category.labels = [gameLabel];
                        availableGameLabelCategories.push(category);
                    }
                }
            });
        });

        this.availableGameLabelCategories = availableGameLabelCategories;
        this.sortAvailable();
    }

    sortAssigned(): void {
        this.assignedGameLabelCategories.sort((a, b) => a.final_title.localeCompare(b.final_title));
        this.assignedGameLabelCategories.forEach((category) => {
            category.labels.sort((a, b) => a.final_title.localeCompare(b.final_title));
        });
    }

    sortAvailable(): void {
        this.availableGameLabelCategories.sort((a, b) => a.final_title.localeCompare(b.final_title));
        this.availableGameLabelCategories.forEach((category) => {
            category.labels.sort((a, b) => a.final_title.localeCompare(b.final_title));
        });
    }

    openGameLabels() {
        this._modal
            .create({
                component: GameLabelsDialogComponent,
                componentProps: {
                    gameLabelCategories: this.gameLabelCategories,
                } as GameLabelsDialogPayload,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => {
                    for (const assignedGameLabelCategory of this.assignedGameLabelCategories) {
                        const category = _.find(
                            this.gameLabelCategories,
                            (gameLabelCategory) => gameLabelCategory.id === assignedGameLabelCategory.id
                        );
                        if (category) {
                            assignedGameLabelCategory.final_title = category.final_title;
                            assignedGameLabelCategory.title = category.title;
                            assignedGameLabelCategory.color = category.color;
                            assignedGameLabelCategory.text_color = category.text_color;

                            assignedGameLabelCategory.labels.forEach((assignedGameLabel) => {
                                const gameLabel = _.find(
                                    category.labels,
                                    (gameLabelCategory) => gameLabelCategory.id === assignedGameLabelCategory.id
                                );
                                if (gameLabel) {
                                    assignedGameLabel.final_title = gameLabel.final_title;
                                    assignedGameLabel.title = gameLabel.title;
                                }
                            });
                        }
                    }

                    this.checkAlreadyAssigned();
                });
            });
    }

    public close(): void {
        const labels = [];
        this.assignedGameLabelCategories.forEach((category) => {
            category.labels.forEach((label) => {
                labels.push(label);
            });
        });
        this.modal.dismiss(labels);
    }
}
