<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@SEARCH">Search</ng-container>
    <ng-container i18n="@@FRIEND_REQUESTS">Friend requests</ng-container>
}
<!-- End of translation containers-->

<ion-content fullscreen appDisableKeyboardResize class="bg-white-pattern ion-text-black">
    <app-page-header mainNavigation="back" defaultBack="friends">
        <div title [translate]="'SEARCH_USERS'" i18n="@@SEARCH_USERS">Search users</div>
    </app-page-header>
    <div class="p-4 space-y-4" appWebContainer>
        <div class="dialog-input with-icon">
            <app-icon icon="person_search"></app-icon>
            <input type="text" [formControl]="searchControl" placeholder="{{ 'SEARCH' | translate }}" />
        </div>

        @if (!searchString && !recentSearches.length) {
            <div>
                <p class="text-center" [translate]="'NO_RECENT_SEARCHES'" i18n="@@NO_RECENT_SEARCHES">
                    No recent searches
                </p>
            </div>
        }
        @if (!searchString && recentSearches.length) {
            <div>
                <p class="text-center text-black" [translate]="'RECENT_SEARCHES'" i18n="@@RECENT_SEARCHES">
                    Recent searches
                </p>
                @for (person of recentSearches; track person) {
                    <div
                        class="flex items-center space-x-3 p-2 text-black cursor-pointer"
                        (click)="OpenProfile(person)">
                        <app-profile-photo
                            [size]="40"
                            [ultimate]="person.is_ultimate"
                            [badge]="person.profile?.badge"
                            [url]="person.profile?.profile_photo_url"></app-profile-photo>
                        <div class="space-y-1">
                            <div class="font-semibold">{{ person.full_name }}</div>
                            <div class="text-xs">
                                {{ person.profile?.nickname || ('NO_NICKNAME' | translate) }}
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
        @if (searchString && !people.length && !loadingFriends) {
            <div>
                <p class="text-center text-black" [translate]="'NO_RESULTS'" i18n="@@NO_RESULTS">No results found</p>
            </div>
        }
        @if (searchString && people.length && !loadingFriends) {
            <div>
                @for (person of people; track person) {
                    @if (!person.is_blocked && !person.has_blocked) {
                        <div class="flex items-center justify-between p-2 text-black">
                            <div
                                class="flex items-center space-x-3 cursor-pointer overflow-hidden"
                                (click)="OpenProfile(person)">
                                <app-profile-photo
                                    [size]="40"
                                    [ultimate]="person.is_ultimate"
                                    [url]="person.profile?.profile_photo_url"
                                    [badge]="person.profile?.badge"></app-profile-photo>
                                <div class="space-y-1 overflow-hidden">
                                    <div class="font-semibold truncate">{{ person.full_name }}</div>
                                    <div class="text-xs truncate">
                                        {{ person.profile?.nickname || ('NO_NICKNAME' | translate) }}
                                    </div>
                                </div>
                            </div>

                            @if (person.friend_status === 'friends') {
                                <ion-icon style="font-size: 22px" name="checkmark" color="success"></ion-icon>
                            }
                            @if (person.friend_status === 'pending') {
                                <app-primary-button
                                    size="extrasmall"
                                    type="button"
                                    color="black"
                                    fill="outline"
                                    [translate]="'PENDING'"
                                    i18n="@@PENDING"
                                    (click)="ShowPendingOptions(person)">
                                    Pending
                                </app-primary-button>
                            }
                            @if (
                                !person.friend_status ||
                                person.friend_status === 'no_friends' ||
                                person.friend_status === 'incoming'
                            ) {
                                <app-primary-button
                                    size="extrasmall"
                                    type="button"
                                    color="blue"
                                    fill="outline"
                                    (click)="AddFriend(person)">
                                    <ion-icon name="add-outline"></ion-icon>
                                </app-primary-button>
                            }
                        </div>
                    }
                }
            </div>
        }

        @if (loadingFriends) {
            <div class="flex pt-8">
                <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-orange"></app-icon>
            </div>
        }
    </div>
</ion-content>
