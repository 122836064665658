/* eslint-disable max-len */
import { Component, inject, Input } from '@angular/core';
import { Match, MatchUser } from '@dc-core/dc-backend/dc-classes';
import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-match-card',
    templateUrl: 'match-card.component.html',
})
export class MatchCardComponent {
    @Input() game: Match;
    @Input() showArrow = true;

    public translateService: TranslateService = inject(TranslateService);

    constructor(public nav: NavController) {}

    getMatchUserName(matchUser: MatchUser): string {
        let name = '';

        if (matchUser.user) {
            name = matchUser.user.first_name;
        } else {
            name = matchUser.ghost_name;
        }

        if (matchUser.is_cpu) {
            name = name + ' (' + matchUser.cpu_level + ')';
        }

        return name;
    }

    GetPlayers(team): string {
        const playerNames = team.users.map((user) => {
            return user.user ? user.user.first_name : user.ghost_name;
        });

        return playerNames.join(', ');
    }

    GetTeamThreeDartAverage(team): number {
        let threeDartAverage = 0;
        team.users.forEach((user) => {
            threeDartAverage += user.three_dart_average;
        });

        return threeDartAverage / team.users.length;
    }

    ShowDetail(item) {
        this.nav.navigateForward('statistics/match/details', {
            queryParams: {
                item,
                gameId: item.id,
            },
        } as NavigationOptions);
    }
}
