import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserApiService } from '@dc-api/user.api.service';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { components } from 'dc-core/dc-typings/dc-backend-definitions';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { DCLoadingService } from 'src/providers/DCLoadingService';

@Component({
    selector: 'app-page-blockedplayers',
    templateUrl: 'blockedplayers.html',
})
export class BlockedPlayersComponent {
    public blockedPlayers: components['schemas']['User'][] = [];
    public loaded = false;

    constructor(
        public nav: NavController,
        public navCtrl: NavController,
        public loading: DCLoadingService,
        public modal: ModalController,
        public route: ActivatedRoute,
        private userApiService: UserApiService
    ) {
        this.userApiService
            .getBlockedUsers({})
            .then((res) => {
                this.blockedPlayers = res.data;
            })
            .catch()
            .finally(() => {
                this.loaded = true;
            });
    }

    tryUnblock(playerID, index) {
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@UNBLOCK_PLAYER:Unblock player`,
                    text: $localize`:@@SURE_TO_UNBLOCK_PLAYER:Are you sure you want to unblock this player?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unblock(playerID, index);
                    }
                });
            });
    }

    unblock(playerID, index) {
        this.loading.ShowDefaultLoader();
        this.userApiService
            .unblockUserById({ userId: playerID })
            .then(() => {
                this.blockedPlayers.splice(index, 1);
            })
            .finally(() => this.loading.DismissLoader());
    }
}
