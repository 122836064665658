import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonicModule, PopoverController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

export type EditProfilePopoverResponse = 'edit' | 'delete';

@Component({
    selector: 'app-edit-profile-photo-popover',
    templateUrl: './edit-profile-photo-popover.component.html',
    standalone: true,
    imports: [CommonModule, IonicModule, TranslateModule],
})
export class EditProfilePopoverComponent {
    private popoverController: PopoverController = inject(PopoverController);

    close(res: EditProfilePopoverResponse = null) {
        this.popoverController.dismiss(res);
    }
}
