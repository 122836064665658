import { Component, inject, input, InputSignal, OnDestroy, OnInit, output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'app-dialog-view',
    standalone: true,
    imports: [TranslateModule, IconComponent],
    templateUrl: './dialog-view.component.html',
    styleUrl: './dialog-view.component.css',
})
export class DialogViewComponent implements OnInit, OnDestroy {
    public showCloseButton: InputSignal<boolean> = input.required();
    public onDismiss = output<void>();

    private platform: Platform = inject(Platform);

    private subscription: Subscription;

    public ngOnInit(): void {
        this.subscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.dismiss();
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public dismiss(): void {
        this.onDismiss.emit();
    }
}
