import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

export interface RenameDialogPayload {
    title: string;
    renamable: string;
}

@Component({
    selector: 'app-rename-dialog',
    templateUrl: 'rename.dialog.html',
})
export class RenameDialogComponent {
    @Input() title: string;
    @Input() renamable: string;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute
    ) {}

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
