import { Injectable } from '@angular/core';
import _ from 'lodash';
import { OnlineGameplay, ONLINEGAMESTATUS } from '../dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from './collection-helpers/active_games.collection.service';
import { GameApiService } from '@dc-api/game.api.service';
import { DartCounterAlertService } from '../dc-services/alert.service';
import { FirebaseApiService } from '@dc-api/firebase.api.service';
import { UsersCollectionService } from './collection-helpers/users.collection.service';
import { FireStoreAuthService } from './firestore-auth.service';
import { User } from '../dc-backend/dc-classes';
import { Timestamp } from 'firebase/firestore';
import { INVITATIONTYPE, INVITATIONSTATUS, DCFireStoreInvite } from './globals/firestore.tables';
import { InviteCollectionService } from './collection-helpers/invite.collection.service';
import { Platform } from '../dc-backend/dc-enums';
import { GameFunctions } from '../dc-gamelogic/game.functions';
import { DartCounterCricketTacticsGame } from '../dc-gamelogic/in-game/cricket-tactics/cricket-tactics.in-game.classes';
import { DartCounterPlayingMatch } from '../dc-gamelogic/in-game/match/match.in-game.classes';

@Injectable()
export class UnfinishedGamesService {
    public unfinishedGames: OnlineGameplay[] = [];

    constructor(
        private _activeGamesCollectionService: ActiveGamesCollectionService,
        private _gameApiService: GameApiService,
        private _alertService: DartCounterAlertService,
        private _firebaseApiService: FirebaseApiService,
        private _usersCollectionService: UsersCollectionService,
        private _dcFireAuth: FireStoreAuthService,
        private _dcInvites: InviteCollectionService
    ) {}

    loadUnfinishedGames(): void {
        this._activeGamesCollectionService
            .getOwnGamesByStatuses([ONLINEGAMESTATUS.INTERRUPTED, ONLINEGAMESTATUS.SAVED, ONLINEGAMESTATUS.REMOVED])
            .then((res) => {
                this.unfinishedGames = _.map(res.docs, (queryDoc) => {
                    const unfinishedGame = queryDoc.data();
                    unfinishedGame.doc_id = queryDoc.id;
                    unfinishedGame.doc_ref = queryDoc.ref;
                    return unfinishedGame;
                });
            });
    }

    saveAll(platform: Platform): void {
        this.unfinishedGames.forEach((unfinishedGame, index) => {
            this.save(platform, unfinishedGame, index);
        });
    }

    async save(platform: Platform, unfinishedGame: OnlineGameplay, index: number): Promise<void> {
        switch (unfinishedGame.type) {
            case 'match':
                this._gameApiService.storeMatch(
                    GameFunctions.getStoreMatchRequest(platform, unfinishedGame.game as DartCounterPlayingMatch)
                );
                break;
            case 'cricket_tactics':
                this._gameApiService.storeCricketTactics(
                    GameFunctions.getStoreCricketTacticsRequest(
                        platform,
                        unfinishedGame.game as DartCounterCricketTacticsGame
                    )
                );
                break;
        }
        await this.remove(unfinishedGame, index);
    }

    removeAll(): void {
        this.unfinishedGames.forEach((unfinishedGame, index) => {
            this.remove(unfinishedGame, index);
        });
    }

    async remove(unfinishedGame: OnlineGameplay, index: number): Promise<void> {
        await this._activeGamesCollectionService.quitOnlineGame(unfinishedGame, unfinishedGame.doc_ref);
        this.unfinishedGames.splice(index, 1);
    }

    reinvite(ownUser: User, gameplay: OnlineGameplay): void {
        if (gameplay.onlineGameStatus == ONLINEGAMESTATUS.REMOVED) {
            this._alertService.createAlert({
                title: $localize`:@@REINVITE_NOT_POSSIBLE:Reinvite is not possible, opponent removed the game`,
                icon: 'error',
                timer: 2000,
                timerProgressBar: true,
                showCloseButton: true,
            });
        } else {
            const fsUser = this._usersCollectionService.getDCFireStoreUser(ownUser);

            const userIds = [];
            let inviteSent = false;
            gameplay.players.forEach((player) => {
                if (player.uid != this._dcFireAuth.getCurrentUID()) {
                    userIds.push(player.user_id);

                    const invite = {
                        sender_uid: this._dcFireAuth.getCurrentUID(),
                        receiver_uid: player.uid,
                        gameplay_doc_id: gameplay.doc_id,
                        invitation_type: INVITATIONTYPE.REINVITE,
                        sent_at: Timestamp.now(),
                        status: INVITATIONSTATUS.PENDING,
                        user: fsUser,
                    } as DCFireStoreInvite;

                    this._dcInvites.sendFirestoreInvite(invite);

                    if (!inviteSent) {
                        inviteSent = true;
                        this._activeGamesCollectionService.inviteOnlyOnlineGame(gameplay);
                    }
                }
            });

            if (userIds.length) {
                this._firebaseApiService.firebaseContinueGame({ user_ids: userIds });
            }

            this._alertService.createAlert({
                title: $localize`:@@INVITE_IS_SENT:The invite has been sent`,
                icon: 'success',
                timerProgressBar: true,
                showCloseButton: true,
            });
        }
    }

    getDaysLeft(unfinishedGame: OnlineGameplay): number {
        if (!unfinishedGame.last_updated) {
            return 0;
        }

        // Getting the time difference in milliseconds
        const timeDiff: number = new Date().getTime() - unfinishedGame.last_updated.toDate().getTime();

        // Converting the time difference to days
        const daysDiff: number = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Now you can check if the difference is less than 14
        if (daysDiff < 14) {
            return 14 - daysDiff;
        }

        return 0;
    }
}
