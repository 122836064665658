<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="dismiss()"></app-icon>
        <div class="title-container">
            <div class="title">{{ title }}</div>
        </div>
        <div class="mt-8">
            <input type="text" class="dialog-input" [(ngModel)]="renamable" />
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="dismiss(renamable)"
                [translate]="'CONFIRM'"
                i18n="@@CONFIRM">
                Confirm
            </app-primary-button>
        </div>
    </div>
</modal-content>
