import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-quit-dialog',
    templateUrl: 'quit.html',
})
export class QuitDialogComponent {
    constructor(public view: ModalController) {}

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
