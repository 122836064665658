import { inject, Injectable } from '@angular/core';
import { GameApiService } from '@dc-api/game.api.service';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { GameLabelService } from '@dc-core/dc-services/game-label.service';
import { OmniCommunicationService } from '@dc-core/dc-services/omni/omni-communication.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { ModalController } from '@ionic/angular';
import { HighlightsDialogComponent } from 'src/dialogs/highlights/highlights.dialog';
import { environment } from 'src/environments/environment';

import { LocalCameraService } from './local-camera.service';

@Injectable()
export class AfterGameService {
    private onlineGamesService: OnlineGamesService = inject(OnlineGamesService);
    private ingameCameraService: InGameCameraService = inject(InGameCameraService);
    private gameApiService: GameApiService = inject(GameApiService);
    private gameLabelService: GameLabelService = inject(GameLabelService);
    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);
    private videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    private omniCommunicationService: OmniCommunicationService = inject(OmniCommunicationService);
    private localCameraService: LocalCameraService = inject(LocalCameraService);
    private modalController: ModalController = inject(ModalController);

    public localGamePlayed(gameMode: GameMode, gameId: number, withVirtCam: boolean, withOmniScoring: boolean): void {
        this.gameLabelService.assignSelectedLabels(gameMode, gameId);

        if (withVirtCam || withOmniScoring) {
            const virtCamId = withVirtCam ? this.videoRoomService.smartDevice?.id ?? null : null;
            const omniScoringId = withOmniScoring ? this.omniCommunicationService.smartDevice?.id ?? null : null;

            if (virtCamId || omniScoringId) {
                this.smartDeviceApiService
                    .logSmartDeviceUsage({
                        game_type: gameMode,
                        game_id: gameId,
                        is_online: false,
                        virt_cam_id: virtCamId,
                        omni_scoring_id: omniScoringId,
                    })
                    .catch(console.error);
            }
        }
    }

    public onlineGamePlayed(gameMode: GameMode, gameId: number, withVirtCam: boolean, withOmniScoring: boolean): void {
        this.ingameCameraService.clips.forEach((clip) => {
            this.gameApiService.assignCameraStreamClipToGame({ clipId: clip.id, game_type: gameMode, game_id: gameId });
        });
        this.gameLabelService.assignSelectedLabels(gameMode, gameId);

        if (withVirtCam || withOmniScoring) {
            const virtCamId = withVirtCam ? this.videoRoomService.smartDevice?.id ?? null : null;
            const omniScoringId = withOmniScoring ? this.omniCommunicationService.smartDevice?.id ?? null : null;

            if (virtCamId || omniScoringId) {
                this.smartDeviceApiService
                    .logSmartDeviceUsage({
                        game_type: gameMode,
                        game_id: gameId,
                        is_online: true,
                        virt_cam_id: virtCamId,
                        omni_scoring_id: omniScoringId,
                    })
                    .catch(console.error);
            }
        }
    }

    public cleanupAfterGame(): void {
        this.onlineGamesService.controlSmartDeviceInactivity(true, true, true);
        this.ingameCameraService.cleanAfterGame();
        this.localCameraService.checkPublishingVideo();

        if (this.ingameCameraService.clips.length) {
            this.modalController
                .create({
                    component: HighlightsDialogComponent,
                    componentProps: {
                        clips: this.ingameCameraService.clips,
                    },
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                });
        }
    }
}
