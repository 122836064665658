<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="DismissModal()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'PLAYER_OPTIONS'" i18n="@@PLAYER_OPTIONS">Player options</div>
    </div>
    <div class="slide-modal-content">
        @if (user) {
            <div class="flex flex-col">
                <div class="mx-auto flex items-center justify-start space-x-2 cursor-pointer mb-5">
                    <div class="relative">
                        <app-profile-photo
                            class="h-fit"
                            [ultimate]="user.is_ultimate"
                            [badge]="user.profile?.badge"
                            [url]="user.profile?.profile_photo_url"
                            [size]="32">
                        </app-profile-photo>
                    </div>

                    @if (user.id > 0) {
                        <div class="space-y-0.5 overflow-hidden">
                            <div class="flex items-center space-x-1">
                                <div class="ucfirst truncate text-xs font-bold leading-none text-black">
                                    {{ user.first_name || ('NO_NAME' | translate) }}
                                </div>
                            </div>
                            <div class="flex items-center">
                                <app-online-player-stats [player]="user" [showRating]="false"></app-online-player-stats>
                            </div>
                        </div>
                    }
                </div>

                @if (user.is_ultimate) {
                    @if (!user.profile?.sound) {
                        <app-primary-button
                            size="normal"
                            addedClasses="w-full"
                            color="orange"
                            (click)="ShowLinkSound()">
                            <app-icon icon="volume_mute" class="mr-2"></app-icon>
                            <span [translate]="'LINK_SOUND'" i18n="@@LINK_SOUND">Link name</span>
                        </app-primary-button>
                    }
                    @if (user.profile?.sound) {
                        <app-primary-button size="normal" addedClasses="w-full" color="orange" (click)="RemoveSound()">
                            <app-icon icon="volume_high" class="mr-2"></app-icon>
                            <span [translate]="'UNLINK'" i18n="@@UNLINK">Unlink</span>
                        </app-primary-button>
                    }
                }
            </div>

            @if (!user.id) {
                <div class="flex flex-col mb-5">
                    <div class="dialog-input-label">
                        <span [translate]="'NAME_OF_PLAYER'" i18n="@@NAME_OF_PLAYER">Player name</span>
                    </div>
                    <input
                        type="text"
                        name="playerName"
                        ngDefaultControl
                        [(ngModel)]="user.first_name"
                        class="dialog-input"
                        required />
                </div>
                <app-primary-button
                    size="normal"
                    addedClasses="w-full"
                    color="black"
                    [translate]="'CONFIRM'"
                    i18n="@@CONFIRM"
                    (click)="DismissModal(user)">
                    Confirm
                </app-primary-button>
            }
        }
    </div>
</div>
