import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { AuthService } from '@services/auth.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-page-header',
    standalone: true,
    imports: [IconComponent, CommonModule],
    templateUrl: './page-header.component.html',
})
export class PageHeaderComponent implements OnInit {
    @Input() color: 'orange' | 'black' | 'white' | 'transparent' | 'orange-pattern' = 'orange';
    @Input() mainNavigation: 'menu' | 'back' | 'custom' = 'menu';
    @Input() defaultBack: string;

    public isWeb = environment.isWeb;

    constructor(
        private menuCtrl: MenuController,
        private navCtrl: NavController,
        public auth: AuthService,
        private router: Router
    ) {}

    classes = '';

    ngOnInit(): void {
        switch (this.color) {
            case 'orange':
                this.classes += 'bg-orange !text-white';
                break;
            case 'white':
                this.classes += 'bg-white text-black';
                break;
            case 'black':
                this.classes += 'bg-black text-white';
                break;
            case 'orange-pattern':
                this.classes += '!text-white';
        }
    }

    toggleMenu() {
        this.menuCtrl.toggle();
    }

    goBack() {
        const currentNavigation = this.router.getCurrentNavigation();
        if (!currentNavigation || currentNavigation.extras.state?.navigationId === 1) {
            this.navCtrl.navigateRoot(this.defaultBack);
        } else {
            this.navCtrl.pop().catch(() => {
                this.navCtrl.navigateRoot(this.defaultBack);
            });
        }
    }
}
