/* eslint-disable max-len */
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    ElementRef,
    inject,
    Input,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { RecapApiService } from '@dc-api/recap.api.service';
import { SoundPath } from '@dc-core/dc-backend/dc-enums';
import { RecapResponse } from '@dc-core/dc-backend/dc-responses';
import { DartCounterAudioService } from '@dc-core/dc-services/audio/audio.service';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from '@services/auth.service';
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { environment } from 'src/environments/environment';

import { ProfilePhotoComponent } from '../../../../../components/profile-photo/profile-photo.component';

type RecapScreen = {
    name: string;
    duration: number;
    animation: () => Promise<void>;
    exitAnimation?: () => Promise<void>;
    audio?: string;
    progress: number;
};

@Component({
    selector: 'app-year-recap-dialog',
    standalone: true,
    templateUrl: 'year-recap.dialog.html',
    imports: [CommonModule, IonicModule, TranslateModule, IconComponent, ProfilePhotoComponent, PrimaryButtonComponent],
    animations: [
        trigger('fade', [
            transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0 }))]),
        ]),
    ],
})
export class YearRecapDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() initialRecap: RecapResponse;
    @ViewChild('omniDartboardWrapper', { static: false }) public svgWrapper: ElementRef;

    public authService: AuthService = inject(AuthService);

    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private modalController: ModalController = inject(ModalController);
    private audioService: DartCounterAudioService = inject(DartCounterAudioService);
    private http: HttpClient = inject(HttpClient);
    private translateService: TranslateService = inject(TranslateService);
    private loadingService: DCLoadingService = inject(DCLoadingService);
    private recapApiService: RecapApiService = inject(RecapApiService);
    private socialSharing: SocialSharing = inject(SocialSharing);
    private platform: Platform = inject(Platform);

    public recap: WritableSignal<RecapResponse> = signal(null);
    public allowAudio = !environment.local;
    public isPaused: WritableSignal<boolean> = signal(false);

    public animateOrangeCircle = false;
    public isTextAnimating: WritableSignal<boolean> = signal(false);
    public dartsAnimating = 0;
    public isTextFading = false;
    public isCircleMoving = false;
    public isCircleExiting = false; // Circle exiting animation
    public dartboardTexts: {
        thrownLegs: string;
        wonLegs: string;
        legsPercentage: string;
        average: string;
        averagePercentage: string;
    } = { thrownLegs: '', wonLegs: '', legsPercentage: '', average: '', averagePercentage: '' };
    public slideInLastSlide: WritableSignal<boolean[]> = signal([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ]);

    public screenIndex: WritableSignal<number> = signal(0); // Current screen index
    public useHeightForOrangeCircle: WritableSignal<boolean> = signal(null);
    public hideArrows: WritableSignal<boolean> = signal(false);

    public dartboardSvg: SVGElement;
    public isSvgLoaded: boolean = false;

    private dartboardSvgUrl: string = 'assets/images/tor_board.svg'; // Path to your SVG file

    private timeouts: number[] = []; // Store all timeouts
    private intervals: number[] = []; // Store all intervals
    private backButtonSubscription: Subscription;

    public screens: WritableSignal<RecapScreen[]> = signal([
        {
            name: 'Your year',
            duration: 2600,
            animation: () => this.triggerFirstAnimation(),
            exitAnimation: () => this.exitFirstAnimation(),
            audio: 'recap_music_1.mp3',
            progress: 0,
        },
        {
            name: 'Statistics',
            duration: 2600,
            animation: () => this.triggerSecondAnimation(),
            exitAnimation: () => this.exitSecondAnimation(),
            audio: 'recap_music_5.mp3',
            progress: 0,
        },
        {
            name: 'Games thrown',
            duration: 3000,
            animation: () => this.triggerThirdAnimation(),
            exitAnimation: () => this.exitThirdAnimation(),
            audio: 'recap_music_4.mp3',
            progress: 0,
        },
        {
            name: 'Thrown legs',
            duration: 5000,
            animation: () => this.triggerFourthAnimation(),
            exitAnimation: () => this.exitFourthAnimation(),
            audio: 'recap_music_3.mp3',
            progress: 0,
        },
        {
            name: 'Your average',
            duration: 3000,
            animation: () => this.triggerFifthAnimation(),
            exitAnimation: () => this.exitFifthAnimation(),
            audio: 'recap_music_6.mp3',
            progress: 0,
        },
        {
            name: 'Recap overview',
            duration: 6000,
            animation: () => this.triggerFinalAnimation(),
            audio: 'recap_music_2.mp3',
            progress: 0,
        },
    ]);

    private zoomedViewBox = { x: 201.2, y: 88.4, width: 50, height: 70 };

    public ngOnInit(): void {
        this.recap.set(this.initialRecap);

        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.dismiss();
        });
    }

    public ngAfterViewInit(): void {
        this.startCurrentScreen();

        $localize`:@@YOU_THREW_X_AMOUNT_OF_LEGS:You threw ${this.recap().total_legs}:amount: legs`;
        $localize`:@@AND_WON_X_AMOUNT:And you won ${this.recap().total_legs_won}:amount: of them`;
        $localize`:@@YOU_WON_PERCENTAGE:You won ${this.recap().total_legs_win_percentage}:percentage:%`;
        $localize`:@@YOU_THREW_X_AVERAGE:You threw ${this.recap().three_dart_average}:average: on average`;
        $localize`:@@THIS_PUTS_YOU_IN_THE_TOP_PERCENTAGE_OF_PLAYERS:This puts you in the top ${this.recap().three_dart_average_percentage}:percentage:% of all players`;

        this.loadDartboardTexts();
    }

    public share(): void {
        this.ga.trackEvent(
            GA_EVENTCATEGORIES.YEARRECAP,
            GA_EVENTACTIONS.SHARERECAP,
            this.authService.user.is_ultimate ? 'ultimate' : 'not_ultimate'
        );

        this.loadingService.ShowDefaultLoader();

        this.recapApiService
            .shareRecap({})
            .then((res) => {
                const image = 'data:image/png;base64,' + res.data.base64;
                if (environment.isWeb) {
                    const a = document.createElement('a');
                    a.href = image;
                    a.download = this.recap().year + ' DartCounter Recap.png';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();

                    this.loadingService.DismissLoader();
                } else {
                    this.socialSharing
                        .share(null, this.recap().year + ' DartCounter Recap', image)
                        .finally(() => this.loadingService.DismissLoader());
                }
            })
            .catch(() => {
                setTimeout(() => {
                    this.loadingService.DismissLoader();
                }, 250);
            });
    }

    public refresh(): void {
        this.ga.trackEvent(
            GA_EVENTCATEGORIES.YEARRECAP,
            GA_EVENTACTIONS.REFRESHRECAP,
            this.authService.user.is_ultimate ? 'ultimate' : 'not_ultimate'
        );

        this.loadingService.ShowDefaultLoader();

        this.isTextAnimating.set(false);

        this.recapApiService
            .refreshRecap({ year: this.initialRecap.year })
            .then((res) => {
                this.recap.set({ ...res.data });
                this.startCurrentScreen();
            })
            .finally(() => {
                setTimeout(() => {
                    this.loadingService.DismissLoader();
                }, 250);
            });
    }

    public toggleAudio(): void {
        this.allowAudio = !this.allowAudio;

        const currentScreen = this.screens()[this.screenIndex()];
        if (this.allowAudio && currentScreen.audio) {
            // Play audio for the current screen
            this.audioService.playAudioFromDevice(SoundPath.RECAP, currentScreen.audio);
        } else {
            this.audioService.stopAudio();
        }
    }

    public togglePause(): void {
        this.isPaused.update((isPaused) => !isPaused);
        if (this.isPaused()) {
            this.audioService.stopAudio();
        } else {
            this.goToNextScreen();
        }
    }

    public goToNextScreen(): void {
        this.clearAnimationsAndTimers();

        const currentScreen = this.screens()[this.screenIndex()];
        currentScreen.progress = 100;

        if (this.screenIndex() < this.screens().length - 1) {
            // Trigger the current slide's exit animation
            currentScreen.exitAnimation().then(() => {
                this.screenIndex.update((screenIndex) => screenIndex + 1); // Move to the next screen
                this.startCurrentScreen(); // Start the next screen
                this.hideArrows.set(false);
            });
        } else {
            // Last animation and then close
            if (currentScreen.exitAnimation) {
                currentScreen.exitAnimation().then(() => {
                    // this.dismiss(); // End of slides
                });
            } else {
                // this.dismiss(); // End of slides
            }
        }
    }

    public goToPreviousScreen(): void {
        this.isTextAnimating.set(false);
        this.clearAnimationsAndTimers();

        if (this.screenIndex() > 0) {
            if (this.screenIndex() === this.screens().length - 1) {
                this.screens.update((screens) => {
                    screens[this.screenIndex()].progress = 0;
                    screens[this.screenIndex() - 1].progress = 0;
                    return screens;
                });
                this.screenIndex.update((screenIndex) => screenIndex - 2);
            } else {
                this.screens.update((screens) => {
                    screens[this.screenIndex()].progress = 0;
                    return screens;
                });
                this.screenIndex.update((screenIndex) => screenIndex - 1);
            }
            this.startCurrentScreen();
            this.hideArrows.set(false);
        } else {
            this.goToNextScreen();
        }
    }

    public onTap(event: MouseEvent): void {
        this.hideArrows.set(true);

        if (this.isPaused()) {
            this.isPaused.update(() => false);
        }

        const finalButtons = document.getElementById('final-buttons');
        if (finalButtons && finalButtons.contains(event.target as HTMLElement)) {
            return;
        }

        const tapContainer = document.getElementById('tap-container');
        if (tapContainer) {
            if (event.clientX < tapContainer.clientWidth / 2) this.goToPreviousScreen();
            else this.goToNextScreen();
        }
    }

    public rankMessage(rank: number | null): string {
        if (rank <= 1) {
            return $localize`:@@TOP_1_PERCENT_OF_ALL_PLAYERS:Wow! You're in the top 1% of all players this year, a true champion!`;
        } else if (rank <= 5) {
            return $localize`:@@YOU_ARE_AMONG_THE_TOP_5_PERCENT:Amazing work! You're among the top 5%, keep it up!`;
        } else if (rank <= 10) {
            return $localize`:@@YOU_ARE_AMONG_THE_TOP_10_PERCENT:Great job! You're in the top 10%, a fantastic achievement!`;
        } else if (rank <= 25) {
            return $localize`:@@YOU_ARE_AMONG_THE_TOP_25_PERCENT:Well done! You're in the top 25%, keep pushing for more!`;
        } else if (rank <= 50) {
            return $localize`:@@YOU_ARE_AMONG_THE_TOP_50_PERCENT:You're part of an awesome community, keep growing with us!`;
        } else {
            $localize`:@@EVERY_DART_COUNTS_LETS_AIM_FOR_NEW_MILETONES:Every dart counts, thank you for being part of DartCounter! Let's aim for new milestones in ${this.recap().year}:year:.`;
            return this.translateService.instant('EVERY_DART_COUNTS_LETS_AIM_FOR_NEW_MILETONES', {
                year: this.recap().year,
            });
        }
    }

    private showSlideInAnimations(index: number) {
        this.slideInLastSlide.update((slideIn) => {
            slideIn[index] = true;
            return slideIn;
        });
        if (index < this.slideInLastSlide().length - 1) {
            setTimeout(() => {
                this.showSlideInAnimations(++index);
            }, 500);
        }
    }

    private loadSvg(): void {
        this.resetSvg(); // Reset the SVG before going back

        this.http.get(this.dartboardSvgUrl, { responseType: 'text' }).subscribe((dartboardSvg) => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = dartboardSvg.trim();
            this.dartboardSvg = tempDiv.querySelector('svg');

            if (this.dartboardSvg) {
                this.attachSvgToWrapper();
            }
        });
    }

    private attachSvgToWrapper(): void {
        if (this.dartboardSvg && this.svgWrapper) {
            const wrapper = this.svgWrapper.nativeElement;
            wrapper.innerHTML = ''; // Clear existing content
            wrapper.appendChild(this.dartboardSvg);

            this.isSvgLoaded = true; // Show the SVG after it's loaded
            this.animateSvg(); // Start the animation
        }
    }

    private animateSvg(): void {
        const svg = d3.select(this.dartboardSvg);

        // Start with the full dartboard view (no rotation initially)
        const initialViewBox = { x: 0, y: 0, width: 453, height: 453 }; // Full board dimensions
        const initialRotation = 0; // Initial rotation in degrees

        // Define target viewBox and rotation
        const targetViewBox = { ...this.zoomedViewBox }; // Use your specified zoomed view
        const fullRotation = -360; // A full spin
        const finalRotation = 0; // Final rotation (back to 0)

        // Select the dartboard group for rotation
        const dartboardGroup = svg.select('#tor_dartboard');

        // Set initial viewBox and rotation
        svg.attr('viewBox', `${initialViewBox.x} ${initialViewBox.y} ${initialViewBox.width} ${initialViewBox.height}`)
            .attr('preserveAspectRatio', 'xMidYMid meet') // Maintain aspect ratio
            .attr('class', 'absolute w-full h-full') // Ensure it fills the parent container
            .style('opacity', 0); // Start invisible

        dartboardGroup.attr('transform', `rotate(${initialRotation} 226.5 226.5)`); // Initial rotation

        // Fade in the SVG
        svg.transition()
            .duration(1000) // 1-second fade-in
            .ease(d3.easeCubicInOut)
            .style('opacity', 1)
            .on('end', () => {
                // Perform the full 360-degree spin while zooming
                svg.transition()
                    .duration(3000) // Duration for the zoom and rotation
                    .ease(d3.easeCubicInOut)
                    .attr(
                        'viewBox',
                        `${targetViewBox.x} ${targetViewBox.y} ${targetViewBox.width} ${targetViewBox.height}`
                    );

                dartboardGroup
                    .transition()
                    .duration(2000) // Match duration with the zoom
                    .ease(d3.easeCubicInOut)
                    .attrTween('transform', () => {
                        const interpolate = d3.interpolateNumber(initialRotation, fullRotation + finalRotation);
                        return (t) => `rotate(${interpolate(t)} 226.5 226.5)`;
                    });
            });
    }

    private rotateSvg(totalRotations: number = 3) {
        const svg = d3.select(this.dartboardSvg);

        // Apply initial viewBox and preserve aspect ratio
        svg.attr(
            'viewBox',
            `${this.zoomedViewBox.x} ${this.zoomedViewBox.y} ${this.zoomedViewBox.width} ${this.zoomedViewBox.height}`
        )
            .attr('preserveAspectRatio', 'xMidYMid meet') // Maintain aspect ratio while centering
            .attr('class', 'absolute w-full h-full'); // Ensure it fills the parent container

        // Select all <g> elements within the SVG
        const dartboardGroup = svg.select('#tor_dartboard');

        // Define rotation logic
        const rotationStep = -18; // Degrees to rotate counter-clockwise each step
        let currentRotation = 0;

        const rotate = () => {
            if (Math.abs(currentRotation) < Math.abs(totalRotations * rotationStep)) {
                currentRotation += rotationStep;

                dartboardGroup
                    .transition()
                    .duration(300) // Duration of each rotation
                    .ease(d3.easeLinear) // Smooth linear transition
                    .attr('transform', `rotate(${currentRotation} 226 226)`) // Rotate around the center of the dartboard
                    .on('end', () => {
                        // Pause for 50ms between rotations
                        setTimeout(() => rotate(), 50);
                    });
            }
        };

        rotate();
    }

    private animateZoomOutAndRotate(): void {
        const svg = d3.select(this.dartboardSvg);

        // Define the center point of the dartboard
        const centerX = 226; // Center X coordinate
        const centerY = 226; // Center Y coordinate

        // Define the zoom-out target (small viewBox but still centered on the bullseye)
        const zoomOutViewBox = { x: centerX - 2, y: centerY - 2, width: 1, height: 1 }; // Adjust to avoid complete disappearance

        // Select the parent group to apply transformations
        const dartboardGroup = svg.select('#tor_dartboard');

        let currentRotation = 0;
        let rotationInterval: any; // To hold the interval reference

        // Start the rotation animation
        const rotate = () => {
            rotationInterval = setInterval(() => {
                currentRotation -= 2; // Rotate counterclockwise by 2 degrees each frame
                dartboardGroup.attr('transform', `rotate(${currentRotation} ${centerX} ${centerY})`); // Rotate around the center
            }, 20); // Faster updates for smoother animation
        };

        // Ensure opacity is 1 before starting
        svg.style('opacity', 1);

        // Start the zoom-out animation
        svg.transition()
            .duration(5000) // 5 seconds for the zoom-out
            .ease(d3.easeCubicInOut)
            .attr('viewBox', `${zoomOutViewBox.x} ${zoomOutViewBox.y} ${zoomOutViewBox.width} ${zoomOutViewBox.height}`)
            .on('end', () => {
                // Stop rotation after zoom-out finishes
                clearInterval(rotationInterval);

                // Allow a smooth fade-out after the zoom
                svg.transition()
                    .duration(1000) // 1-second fade-out
                    .ease(d3.easeCubicInOut)
                    .style('opacity', 0);
            });

        // Start the infinite rotation
        rotate();
    }

    private startCurrentScreen(): void {
        if (this.screenIndex() === 0) {
            const circleContainer = document.getElementById('circle-container');
            if (circleContainer) {
                this.useHeightForOrangeCircle.set(circleContainer.clientHeight <= circleContainer.clientWidth * 0.75);
            }
        }

        const currentScreen = this.screens()[this.screenIndex()];
        this.screens.update((screens) => {
            screens[this.screenIndex()].progress = 0; // Reset progress for the current screen
            return screens;
        });
        this.clearAnimationsAndTimers(); // Clear previous timeouts and intervals

        // Set previous screens' progress to 100%
        this.screens.update((screens) => [
            ...screens.map((screen, index) => ({
                ...screen,
                progress: index < this.screenIndex() ? 100 : screen.progress,
            })),
        ]);

        if (currentScreen.audio && this.allowAudio) {
            // Play audio for the current screen
            this.audioService.playAudioFromDevice(SoundPath.RECAP, currentScreen.audio);
        }

        // Start progress bar and animations simultaneously
        this.animateProgressBar(currentScreen.duration); // Start progress bar
        currentScreen.animation();

        if (this.screenIndex() < this.screens().length - 1) {
            this.timeouts.push(
                setTimeout(() => {
                    if (!this.isPaused()) {
                        this.goToNextScreen();
                    }
                }, currentScreen.duration + 3000)
            );
        }
    }

    private animateProgressBar(duration: number): void {
        const intervalId = setInterval(() => {
            this.screens.update((screens) => {
                if (this.isPaused()) {
                    screens[this.screenIndex()].progress = 100;
                } else {
                    screens[this.screenIndex()].progress++; // Update current screen's progress
                }
                return screens;
            });

            if (this.screens()[this.screenIndex()].progress >= 100) {
                clearInterval(intervalId); // Clear interval when progress reaches 100%
            }
        }, duration / 100);

        this.intervals.push(intervalId);
    }

    private resetSvg(): void {
        if (this.svgWrapper && this.svgWrapper.nativeElement) {
            this.svgWrapper.nativeElement.innerHTML = ''; // Clear existing SVG
        }
        this.isSvgLoaded = false; // Reset the flag to allow reloading
    }

    private triggerFirstAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.animateOrangeCircle = true;

            this.timeouts.push(
                setTimeout(() => {
                    this.isTextAnimating.set(true);

                    this.timeouts.push(
                        setTimeout(() => {
                            resolve(); // No fade-out logic here; handled by triggerExitAnimation
                        }, 2000) // Duration of enter animation
                    );
                }, 1200) // Circle animation duration
            );
        });
    }

    private exitFirstAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isTextAnimating.set(false); // Ensure entering animation stops
            this.isTextFading = true; // Trigger fade-out

            this.timeouts.push(
                setTimeout(() => {
                    this.animateOrangeCircle = false; // Reset circle animation
                    this.isTextFading = false; // End fade-out
                    resolve(); // Animation complete
                }, 500) // Duration of fade-out animation
            );
        });
    }

    private triggerSecondAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isCircleMoving = false;
            this.isCircleExiting = false;
            this.isTextAnimating.set(true); // Start text animations for "Statistics"
            this.dartsAnimating = 1;

            this.timeouts.push(
                setTimeout(() => {
                    resolve(); // Resolve after text animations complete
                }, 3000) // Duration of text animations
            );
        });
    }

    private exitSecondAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isTextAnimating.set(false);
            this.isTextFading = true; // Start fade-out animations for "Statistics"
            this.isCircleMoving = true; // Trigger circle movement after fade-out

            this.timeouts.push(
                setTimeout(() => {
                    resolve(); // Resolve after circle movement is complete
                }, 500) // Duration of fade-out
            );
        });
    }

    private triggerThirdAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isTextAnimating.set(true);
            this.isCircleExiting = false;

            this.dartsAnimating = 2;
            this.timeouts.push(
                setTimeout(() => {
                    this.dartsAnimating = 3;
                }, 400)
            );

            this.timeouts.push(
                setTimeout(() => {
                    resolve(); // Resolve after content fade-in
                }, 1000) // Duration for fade-in
            );
        });
    }

    private exitThirdAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isTextAnimating.set(false);

            this.isTextFading = true; // Trigger fade-out for bottom texts
            // Start the circle exit animation
            this.isCircleExiting = true;

            this.timeouts.push(
                setTimeout(() => {
                    resolve(); // Resolve after the animations are complete
                }, 500) // Duration for the circle movement
            );
        });
    }

    private triggerFourthAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isTextAnimating.set(false);

            // Load the SVG when the component initializes
            this.loadSvg();

            this.timeouts.push(
                setTimeout(() => {
                    this.isTextAnimating.set(true);
                    this.isCircleExiting = false;
                    resolve(); // Resolve after content fade-in
                }, 3500) // Duration for fade-in
            );
        });
    }

    private exitFourthAnimation(): Promise<void> {
        return new Promise((resolve) => {
            if (this.isTextAnimating()) {
                this.isTextFading = true; // Trigger fade-out for bottom texts
            }

            this.isTextAnimating.set(false);

            this.timeouts.push(
                setTimeout(() => {
                    resolve(); // Resolve after the animations are complete
                }, 500) // Duration for the circle movement
            );
        });
    }

    private triggerFifthAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isTextAnimating.set(false);

            this.rotateSvg();

            this.timeouts.push(
                setTimeout(() => {
                    this.isTextAnimating.set(true);
                    resolve(); // Resolve after content fade-in
                }, 1200)
            );
        });
    }

    private exitFifthAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isTextAnimating.set(false);

            this.isTextFading = true; // Trigger fade-out for bottom texts

            setTimeout(() => {
                this.animateZoomOutAndRotate();
            }, 800);

            this.timeouts.push(
                setTimeout(() => {
                    resolve(); // Resolve after the animations are complete
                }, 6800) // Duration for the circle movement
            );
        });
    }

    private triggerFinalAnimation(): Promise<void> {
        return new Promise((resolve) => {
            this.isTextAnimating.set(true);

            this.slideInLastSlide.update((slideIn) => [...slideIn.map(() => false)]);
            this.showSlideInAnimations(0);
            resolve();
        });
    }

    private loadDartboardTexts(): void {
        this.dartboardTexts = {
            thrownLegs: this.translateService.instant('YOU_THREW_X_AMOUNT_OF_LEGS', {
                amount: this.recap().total_legs ?? 0,
            }),
            wonLegs: this.translateService.instant('AND_WON_X_AMOUNT', { amount: this.recap().total_legs_won ?? 0 }),
            legsPercentage: this.translateService.instant('YOU_WON_PERCENTAGE', {
                percentage: Math.ceil(this.recap().total_legs_win_percentage ?? 0),
            }),
            average: this.translateService.instant('YOU_THREW_X_AVERAGE', {
                average: Number(this.recap().three_dart_average ?? 0).toFixed(2),
            }),
            averagePercentage: this.recap().three_dart_average_percentage
                ? this.translateService.instant('THIS_PUTS_YOU_IN_THE_TOP_PERCENTAGE_OF_PLAYERS', {
                      percentage: Math.ceil(this.recap().three_dart_average_percentage ?? 0),
                  })
                : null,
        };
    }

    private clearAnimationsAndTimers(): void {
        this.animateOrangeCircle = false;
        this.isTextFading = false;

        this.timeouts.forEach(clearTimeout);
        this.intervals.forEach(clearInterval);
        this.timeouts = [];
        this.intervals = [];
    }

    public dismiss(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    public ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }

        this.audioService.stopAudio();
        this.clearAnimationsAndTimers();
    }
}
