<div class="relative">
    <button (click)="toggleNotifications()" class="option-button-wrapper relative" id="notifications-icon-button">
        <app-icon icon="notifications" size="medium"></app-icon>
        @if (newNotifications() > 0) {
            <ion-badge
                mode="md"
                class="!ion-bg-yellow !ion-text-black absolute -right-1 -top-1"
                id="notifications-badge">
                {{ newNotifications() }}
            </ion-badge>
        }
    </button>
</div>
