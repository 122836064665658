<modal-content class="bg-white-pattern text-black pt-4">
    @if (possibleDartsAtDouble?.length) {
        <div class="overflow-y-auto">
            <h2 class="text-black" [translate]="'DARTS_ON_DOUBLE'" i18n="@@DARTS_ON_DOUBLE">Darts used on a double</h2>
            <ion-row class="dartrow">
                @for (dart of possibleDartsAtDouble; track $index) {
                    <ion-col
                        class="dart text-center"
                        [ngClass]="{ selected: selectedDartAtDouble === dart, focussed: isWeb && !doubleEntered }"
                        (click)="setSelectedDartAtDouble(dart)">
                        {{ dart }}
                    </ion-col>
                }
            </ion-row>
        </div>
    }

    @if (possibleDarts?.length) {
        <div>
            <h2 class="text-black" [translate]="'DARTS_FOR_FINISH'" i18n="@@DARTS_FOR_FINISH">
                Darts used for checkout
            </h2>
            <ion-row class="dartrow">
                @for (dart of possibleDarts; track $index) {
                    <ion-col
                        class="dart text-center"
                        [ngClass]="{ selected: selectedDart === dart, focussed: isWeb && doubleEntered }"
                        (click)="setSelectedDart(dart)">
                        {{ dart }}
                    </ion-col>
                }
            </ion-row>
        </div>
    }
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA orange" style="position: relative" (click)="ReturnDarts()">
            <p class="text-center" style="height: 72px" [translate]="'CONFIRM'" i18n="@@CONFIRM">Confirm</p>
        </div>
    </ion-toolbar>
</ion-footer>
