export class ScoreHelper {
    public static getAddToTimer(score: number): number {
        let addToTimer = 0;
        if (score === 180) {
            addToTimer = 2800;
        } else if (score >= 140) {
            addToTimer = 1200;
        } else if (score >= 100) {
            addToTimer = 600;
        }
        return addToTimer;
    }
}
