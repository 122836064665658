import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConverterProvider {
    arrayBufferToStr(buf: Uint8Array) {
        return String.fromCharCode.apply(null, new Uint16Array(buf));
    }

    strToArrayBuffer(dataString: string) {
        const data = new Uint8Array(dataString.length);
        for (let i = 0; i < data.length; i++) {
            data[i] = dataString.charCodeAt(i);
        }
        console.log(data);
        return data;
    }
}
