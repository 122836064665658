import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';
import { DartCounterCricketTacticsTeam } from '@dc-core/dc-gamelogic/in-game/cricket-tactics/cricket-tactics.in-game.classes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-cricket-tactics-team-score',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './cricket-tactics-team-score.component.html',
    styleUrl: './cricket-tactics-team-score.component.css',
})
export class CricketTacticsTeamScoreComponent {
    public team: InputSignal<DartCounterCricketTacticsTeam> = input.required<DartCounterCricketTacticsTeam>();
    public currentScore: InputSignal<number> = input<number>(0);
    public smallLayout: InputSignal<boolean> = input<boolean>(false);
    public omniActive: InputSignal<boolean> = input<boolean>(false);
}
