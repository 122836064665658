import { AroundTheClock, AroundTheClockUser } from 'dc-core/dc-backend/dc-classes';
import {
    AmountOfHits,
    AroundTheClockSettings,
    AroundTheClockThrowout,
    NumberSegment,
    TrainingMode,
} from 'dc-core/dc-backend/dc-enums';
import moment from 'moment';
import { GameApiService } from '../../../../dc-api/game.api.service';
import { LOCAL_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import {
    ALL,
    BullOrBullseye,
    BULLSEYEMODE_BULL,
    BULLSEYEMODE_BULLSEYE,
    dateTimeFormat,
    DOUBLE,
    ONCE,
    PLAYERMODE,
    SINGLE,
    THRICE,
    THROW_HIGH_TO_LOW,
    THROW_LOW_TO_HIGH,
    TRAINING_MODES,
    TRHOW_RANDOM,
    TRIPLE,
    TWICE,
} from '../../settings.globals';
import { DartCounterGameSettings } from '../settings-classes';

export class DartCounterAroundTheClockSettings extends DartCounterGameSettings {
    public gameApiService: GameApiService;

    public bullOrBullseyes: { label: string; tag: string; type: AroundTheClockThrowout }[];
    public bullOrBullseye: AroundTheClockThrowout;

    public numberSegments: { label: string; tag: string; type: NumberSegment }[];
    public numberSegment: NumberSegment;

    public throwOrder: AroundTheClockSettings;
    public throwOrders: { label: string; tag: string; type: AroundTheClockSettings }[];

    public hits: { label: string; tag: string; type: AmountOfHits }[];
    public hit: AmountOfHits;

    public increaseGoalByMultiplier: boolean;

    public trainingMode: TrainingMode;
    public trainingModes = TRAINING_MODES;

    constructor() {
        //Set the super-class settings
        super();
        this.storageKey = LOCAL_GAME_SETTINGS_STORAGE_KEYS.AROUND_THE_CLOCK;

        //Set the specific settings
        this.playerTypes = [PLAYERMODE];
        this.bullOrBullseyes = [BULLSEYEMODE_BULL, BULLSEYEMODE_BULLSEYE];
        this.numberSegments = [ALL, SINGLE, DOUBLE, TRIPLE];
        this.hits = [ONCE, TWICE, THRICE];

        this.throwOrders = [THROW_LOW_TO_HIGH, THROW_HIGH_TO_LOW, TRHOW_RANDOM];

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        } else {
            this.setDefaults();
        }
    }

    private setDefaults() {
        this.bullOrBullseye = 'bullseye';
        this.numberSegment = 'all';
        this.hit = 'once';
        this.increaseGoalByMultiplier = false;
        this.throwOrder = 'low_to_high';
        this.trainingMode = 'training';

        this.users = [];
    }

    private setStoredSettings(storedSettings: DartCounterAroundTheClockSettings) {
        this.bullOrBullseye = storedSettings.bullOrBullseye;
        this.throwOrder = storedSettings.throwOrder;
        this.numberSegment = storedSettings.numberSegment;
        this.hit = storedSettings.hit;
        this.increaseGoalByMultiplier = storedSettings.increaseGoalByMultiplier ?? false;
        this.users = storedSettings.users;
        this.trainingMode = storedSettings.trainingMode ?? 'training';
    }

    saveGameSettings() {
        let storageObject = {
            bullOrBullseye: this.bullOrBullseye,
            throwOrder: this.throwOrder,
            numberSegment: this.numberSegment,
            hit: this.hit,
            increaseGoalByMultiplier: this.increaseGoalByMultiplier,
            playerType: this.playerType,
            users: this.users,
        };
        localStorage.setItem(this.storageKey, JSON.stringify(storageObject));
    }

    changeBullOrBullseye(bullOrBullseye: BullOrBullseye): void {
        this.bullOrBullseye = bullOrBullseye;
    }

    changSegmentsMode(numberSegment: NumberSegment): void {
        this.numberSegment = numberSegment;
    }

    changeHits(hit: AmountOfHits): void {
        this.hit = hit;
    }

    changeThrowOrder(setting: AroundTheClockSettings): void {
        this.throwOrder = setting;
    }

    changeTrainingMode(trainingMode: TrainingMode): void {
        this.trainingMode = trainingMode;
    }

    toggleIncreaseGoalByMultiplier(): void {
        this.increaseGoalByMultiplier = !this.increaseGoalByMultiplier;
    }

    mapToAroundTheClockObject(): AroundTheClock {
        const users: AroundTheClockUser[] = [];
        this.users.forEach((user, index) => {
            users.push({
                id: index + 1,
                around_the_clock_id: 0,
                name: user.id === null ? user.full_name : user.first_name,
                user_id: user.id,
                user,
                ghost_name: user.id === null ? user.full_name : null,
                is_verified: this.user.id === user.id ? true : false,
            });
        });

        return <AroundTheClock>{
            id: 1,
            training_mode: this.users.length > 1 ? this.trainingMode : 'training',
            throwout: this.bullOrBullseye,
            settings: this.throwOrder,
            number_segment: this.numberSegment,
            hits: this.hit,
            goal_increase: this.increaseGoalByMultiplier ? 'thrown' : 'one',
            started_at: moment().utc().format(dateTimeFormat),
            users: users,
            turns: [],
        };
    }
}
