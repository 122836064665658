import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';

@Component({
    selector: 'app-accordion',
    standalone: true,
    templateUrl: './app-accordion.component.html',
    imports: [CommonModule, IonicModule, IconComponent],
})
export class AccordionComponent {
    @Input() isExpanded = false;
    @Input() data: any; // Type this based on your needs
    @Output() expandChange = new EventEmitter<void>();

    public modal: ModalController = inject(ModalController);

    constructor() {}

    toggleExpand() {
        this.expandChange.emit(); // Notify the parent to handle expansion state
    }
}
