import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, DestroyRef, inject, input, InputSignal, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AuthApiService } from '@dc-api/auth.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from '@services/auth.service';
import { CompleteAccountDialogComponent } from 'src/dialogs/complete-account/complete-account.dialog';
import { ProvidePasswordDialogComponent } from 'src/dialogs/provide-password/provide-password.dialog';
import { TermsAndNewsletterDialogComponent } from 'src/dialogs/terms-and-newsletter/terms-and-newsletter.dialog';
import { environment, socials } from 'src/environments/environment';

import { GoogleWebSigninComponent } from '../google-web-signin/google-web-signin.component';

export type SocialLoginType = 'continue' | 'sign_up';

@Component({
    selector: 'app-social-logins',
    standalone: true,
    imports: [GoogleWebSigninComponent, TranslateModule],
    templateUrl: './social-logins.component.html',
})
export class SocialLoginsComponent implements OnInit {
    public type: InputSignal<SocialLoginType> = input.required<SocialLoginType>();

    private authService: AuthService = inject(AuthService);
    private authApiService: AuthApiService = inject(AuthApiService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private loadingService: DCLoadingService = inject(DCLoadingService);
    private socialAuthService: SocialAuthService = inject(SocialAuthService);
    private platform: Platform = inject(Platform);
    private destroyRef: DestroyRef = inject(DestroyRef);
    private modalController: ModalController = inject(ModalController);
    private navController: NavController = inject(NavController);

    public isWeb: boolean = environment.isWeb;
    public appleAuth: boolean = false;

    public ngOnInit(): void {
        if (this.platform.is('capacitor') && this.platform.is('ios')) {
            this.appleAuth = true;
        }

        this.socialAuthService.authState.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
            if (user.provider === 'GOOGLE') {
                this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then((accessToken) => {
                    this.googleLogin(user, accessToken);
                });
            }
        });
    }

    public googleSignin(wrapper: any): void {
        wrapper.click();
    }

    public async facebookLogin(): Promise<void> {
        this.loadingService.ShowDefaultLoader();

        await FacebookLogin.login({ permissions: ['email', 'public_profile'] })
            .then(async (result) => {
                const facebookUser = await FacebookLogin.getProfile({ fields: ['first_name', 'last_name'] });
                this.facebookApiLogin(
                    result.accessToken.token,
                    facebookUser['first_name'] as string,
                    facebookUser['last_name'] as string
                );
            })
            .catch(() => {
                this.loadingService.DismissLoader();
                this.alertService.createAlert({
                    title: 'Facebook login failed',
                    icon: 'error',
                });
            });
    }

    public async googleLogin(user?: any, token?: string): Promise<void> {
        this.loadingService.ShowDefaultLoader();

        try {
            const googleUser = user
                ? { ...user, familyName: user.lastName, givenName: user.firstName }
                : await GoogleAuth.signIn();
            const accessToken = token ?? googleUser.authentication.accessToken;

            if (accessToken) {
                this.googleApiLogin(accessToken, googleUser.givenName, googleUser.familyName);
            } else {
                this.loadingService.DismissLoader();
                this.alertService.createAlert({
                    title: 'Google login failed',
                    icon: 'error',
                });
            }
        } catch (e) {
            this.loadingService.DismissLoader();
        }
    }

    public async appleLogin(): Promise<void> {
        this.loadingService.ShowDefaultLoader();

        const options: SignInWithAppleOptions = {
            clientId: socials.apple.clientId,
            redirectURI: socials.apple.redirectUrl,
            scopes: 'email name',
        };
        SignInWithApple.authorize(options)
            .then((result: SignInWithAppleResponse) => {
                this.appleApiLogin(
                    result.response.identityToken,
                    result.response.givenName,
                    result.response.familyName
                );
            })
            .catch(() => {
                this.loadingService.DismissLoader();
                this.alertService.createAlert({
                    title: 'Apple login failed',
                    icon: 'error',
                });
            });
    }

    private facebookApiLogin(token: string, first_name: string, last_name: string, password: string = null): void {
        this.authApiService
            .facebookLogin({
                token,
                first_name,
                last_name,
                password,
                check_opt_in: true,
            })
            .then((res) => {
                this.authService.clearStorage();
                this.authService.saveAuthentication(res.data.user, res.data.access_token, true);
                this.loadingService.DismissLoader();

                this.checkCompleteAccount(res.data.user);
            })
            .catch((err) => {
                this.loadingService.DismissLoader();

                const error = err.getActualType();
                if (error.status === 412) {
                    this.modalController
                        .create({
                            component: ProvidePasswordDialogComponent,
                            componentProps: {
                                email: error.data.email,
                            },
                            cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                if (dialogRes.data != null) {
                                    this.loadingService.ShowDefaultLoader();
                                    this.facebookApiLogin(token, first_name, last_name, dialogRes.data);
                                }
                            });
                        });
                } else {
                    this.alertService.errorFromApi(err);
                }
            });
    }

    private appleApiLogin(token: string, first_name: string, last_name: string, password: string = null): void {
        this.authApiService
            .appleLogin({
                token,
                first_name,
                last_name,
                password,
                check_opt_in: true,
            })
            .then((res) => {
                this.authService.clearStorage();
                this.authService.saveAuthentication(res.data.user, res.data.access_token, true);
                this.loadingService.DismissLoader();

                this.checkCompleteAccount(res.data.user);
            })
            .catch((err) => {
                this.loadingService.DismissLoader();

                const error = err.getActualType();
                if (error.status === 412) {
                    this.modalController
                        .create({
                            component: ProvidePasswordDialogComponent,
                            componentProps: {
                                email: error.data.email,
                            },
                            cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                if (dialogRes.data != null) {
                                    this.loadingService.ShowDefaultLoader();
                                    this.appleApiLogin(token, first_name, last_name, dialogRes.data);
                                }
                            });
                        });
                } else {
                    this.alertService.errorFromApi(err);
                }
            });
    }

    private googleApiLogin(token: string, first_name: string, last_name: string, password: string = null): void {
        this.authApiService
            .googleLogin({
                token,
                first_name,
                last_name,
                password,
                check_opt_in: true,
            })
            .then((res) => {
                this.authService.clearStorage();
                this.authService.saveAuthentication(res.data.user, res.data.access_token, true);
                this.loadingService.DismissLoader();

                this.checkCompleteAccount(res.data.user);
            })
            .catch((err) => {
                this.loadingService.DismissLoader();

                const error = err.getActualType();
                if (error.status === 412) {
                    this.modalController
                        .create({
                            component: ProvidePasswordDialogComponent,
                            componentProps: {
                                email: error.data.email,
                            },
                            cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                if (dialogRes.data != null) {
                                    this.loadingService.ShowDefaultLoader();
                                    this.googleApiLogin(token, first_name, last_name, dialogRes.data);
                                }
                            });
                        });
                } else {
                    this.alertService.errorFromApi(err);
                }
            });
    }

    private checkCompleteAccount(user: User): void {
        if (!user.first_name?.length) {
            this.completeAccount(user);
        } else {
            this.checkTermsAndNewsletter(user);
        }
    }

    private completeAccount(user: User): void {
        this.modalController
            .create({
                component: CompleteAccountDialogComponent,
                backdropDismiss: false,
                componentProps: {
                    firstName: user.first_name,
                    lastName: user.last_name,
                    username: user.username,
                },
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => {
                    this.checkTermsAndNewsletter(user);
                });
            });
    }

    private checkTermsAndNewsletter(user: User): void {
        if (user.accepted_terms_at === null || (user.email && user.newsletter_opt_in === null)) {
            this.modalController
                .create({
                    component: TermsAndNewsletterDialogComponent,
                    backdropDismiss: false,
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(() => {
                        this.navController.navigateRoot('dashboard');
                    });
                });
        } else {
            this.navController.navigateRoot('dashboard');
        }
    }
}
