/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { ProfileApiService } from '@dc-api/profile.api.service';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { TournamentApiService } from '@dc-api/tournament.api.service';
import { Tournament } from '@dc-core/dc-backend/dc-classes';
import { LastTenOnlineGamesResponse } from '@dc-core/dc-backend/dc-responses';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { IonicModule, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { CropProfilePhotoDialogComponent } from 'src/dialogs/crop-profile-photo/crop-profile-photo.dialog';
import { environment } from 'src/environments/environment';

import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-join-tournament-dialog',
    templateUrl: 'join-tournament.dialog.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        PrimaryButtonComponent,
        IconComponent,
        TranslateModule,
    ],
})
export class JoinTournamentDialogComponent implements OnInit {
    @Input() tournament: Tournament;

    public authService: AuthService = inject(AuthService);

    private modalController: ModalController = inject(ModalController);
    private translateService: TranslateService = inject(TranslateService);
    private formBuilder: FormBuilder = inject(FormBuilder);
    private tournamentApiService: TournamentApiService = inject(TournamentApiService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private profileApiService: ProfileApiService = inject(ProfileApiService);
    private platform: Platform = inject(Platform);
    private loadingService: DCLoadingService = inject(DCLoadingService);
    private statisticsApiService: StatisticsApiService = inject(StatisticsApiService);
    private navController: NavController = inject(NavController);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);

    public isLoading: boolean;
    public joinForm: FormGroup = this.formBuilder.group({
        password: [''],
    });
    public lastTenOnlineGames: LastTenOnlineGamesResponse;
    public notEnoughOnlineGames: boolean;
    public canJoin: boolean = true;
    public joinError: string;

    public isProduction = environment.production;

    public async ngOnInit(): Promise<void> {
        this.isLoading = true;

        if (this.tournament.is_private) {
            this.joinForm.get('password').addValidators([Validators.required]);
            this.joinForm.updateValueAndValidity();
        }

        await this.statisticsApiService.getLastTenOnlineGamesStatistics({}).then((res) => {
            this.notEnoughOnlineGames = environment.production ? res.data.count < 10 : false;
            this.lastTenOnlineGames = res.data;

            if (
                this.tournament.min_three_dart_avg &&
                this.lastTenOnlineGames.three_dart_average < this.tournament.min_three_dart_avg
            ) {
                $localize`:@@THREE_DART_AVG_IS_TOO_LOWER_THAN_MINIMUM:Your three dart average of ${this.lastTenOnlineGames.three_dart_average}:average: is lower than the minimum of ${this.tournament.min_three_dart_avg}:minimum:`;
                this.joinError = this.translateService.instant('THREE_DART_AVG_IS_TOO_LOWER_THAN_MINIMUM', {
                    average: this.lastTenOnlineGames.three_dart_average,
                    minimum: this.tournament.min_three_dart_avg,
                });
                this.canJoin = false;
            } else if (
                this.tournament.max_three_dart_avg &&
                this.lastTenOnlineGames.three_dart_average > this.tournament.max_three_dart_avg
            ) {
                $localize`:@@THREE_DART_AVG_IS_TOO_HIGHER_THAN_MAXMIMUM:Your three dart average of ${this.lastTenOnlineGames.three_dart_average}:average: is higher than the maximum of ${this.tournament.max_three_dart_avg}:maximum:`;
                this.joinError = this.translateService.instant('THREE_DART_AVG_IS_TOO_HIGHER_THAN_MAXMIMUM', {
                    average: this.lastTenOnlineGames.three_dart_average,
                    maximum: this.tournament.max_three_dart_avg,
                });
                this.canJoin = false;
            }
        });

        this.isLoading = false;
    }

    public join(): void {
        if (this.tournament.needs_approval && !this.authService.user.profile.online_setup_url) {
            this.alertService.createAlert({
                title: $localize`:@@YOU_NEED_TO_UPLOAD_AN_ONLINE_SETUP_TO_JOIN:You need to upload an online setup to join`,
                icon: 'error',
            });
            return;
        }

        this.tournamentApiService
            .joinTournament({
                tournamentId: this.tournament.id,
                password: this.tournament.is_private ? this.joinForm.get('password').value : null,
            })
            .then((res) => {
                this.ga.trackEvent(GA_EVENTCATEGORIES.TOURNAMENTS, GA_EVENTACTIONS.JOIN);

                this.dismiss(res.data);
            })
            .catch((err) => this.alertService.errorFromApi(err));
    }

    public async uploadOnlineSetup(): Promise<void> {
        if (this.platform.is('capacitor')) {
            try {
                const checkCamPermissions = await Camera.checkPermissions();
                if (checkCamPermissions.camera !== 'granted' || checkCamPermissions.photos !== 'granted') {
                    const requestCamResult = await Camera.requestPermissions();
                    if (requestCamResult.camera === 'granted' && requestCamResult.photos === 'granted') {
                        setTimeout(() => {
                            this.uploadOnlineSetup();
                        }, 500);
                        return;
                    } else {
                        throw 'No permission';
                    }
                }
            } catch (_) {
                this.alertService.createAlert({
                    title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                    icon: 'error',
                    timer: 2000,
                    timerProgressBar: true,
                    showCloseButton: true,
                });
                return;
            }
        }

        await Camera.getPhoto({
            height: 500,
            width: 500,
            allowEditing: false,
            correctOrientation: true,
            quality: 90,
            resultType: CameraResultType.Base64,
            webUseInput: environment.isWeb,
        }).then((imageData: Photo) => {
            this.modalController
                .create({
                    component: CropProfilePhotoDialogComponent,
                    componentProps: {
                        image: imageData.base64String,
                        format: imageData.format,
                    },
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(async (dialogRes) => {
                        if (dialogRes.data) {
                            this.loadingService.ShowDefaultLoader();

                            await this.profileApiService
                                .uploadOnlineSetup({ encoded_online_setup: dialogRes.data })
                                .then((res) => {
                                    this.authService.user.profile.online_setup_path = res.data.online_setup_path;
                                    this.authService.user.profile.online_setup_url = dialogRes.data;

                                    this.loadingService.DismissLoader();
                                })
                                .catch((err) => {
                                    this.alertService.errorFromApi(err);
                                });
                        }
                    });
                });
        });
    }

    public goToGlobalLobby(): void {
        this.dismiss();
        this.navController.navigateBack('online-games/global-lobby');
    }

    public dismiss(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }
}
