import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { KeyboardClickEvent, User } from '@dc-core/dc-backend/dc-classes';
import { KeyboardClickSource } from '@dc-core/dc-backend/dc-enums';
import { defaultHotkeys, HotkeyHelper } from '@dc-core/dc-helpers/hotkey.helper';
import { WebKeyboardContainerDirective } from 'src/directives/web-keyboard-container.directive';
import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-new-hotkeys-keyboard',
    standalone: true,
    imports: [CommonModule, WebKeyboardContainerDirective, IconComponent],
    templateUrl: 'new-hotkeys-keyboard.component.html',
})
export class NewHotkeysKeyboardComponent implements OnChanges {
    @Input() user: User;
    @Input() undoScore = false;

    @Output() keyboardClickEvent = new EventEmitter<KeyboardClickEvent>();
    @Output() undoScoreEvent = new EventEmitter();

    public hotkeys: number[] = [];

    ngOnChanges(): void {
        this.hotkeys = this.user && this.user.hotkeys ? HotkeyHelper.fromHotkeys(this.user.hotkeys) : defaultHotkeys;
    }

    onClick(key: any, source: KeyboardClickSource) {
        this.keyboardClickEvent.emit({
            key: key,
            source: source,
        });
    }

    emitUndoScore(): void {
        if (this.undoScore) {
            this.undoScoreEvent.emit();
        }
    }
}
