export interface GameLabelColor {
    color: string;
    textColor?: string;
}

export const LABEL_COLORS: GameLabelColor[] = [
    {
        color: '#baf2da',
        textColor: '#000',
    },
    {
        color: '#f8e69f',
        textColor: '#000',
    },
    {
        color: '#ffe2bc',
        textColor: '#000',
    },
    {
        color: '#ffd2cb',
        textColor: '#000',
    },
    {
        color: '#dfd8fd',
        textColor: '#000',
    },
    {
        color: '#4bce97',
        textColor: '#000',
    },
    {
        color: '#e2b204',
        textColor: '#000',
    },
    {
        color: '#faa53d',
        textColor: '#000',
    },
    {
        color: '#f87462',
        textColor: '#000',
    },
    {
        color: '#9e8fef',
        textColor: '#000',
    },
    {
        color: '#20845a',
        textColor: '#fff',
    },
    {
        color: '#936e01',
        textColor: '#fff',
    },
    {
        color: '#b55c01',
        textColor: '#fff',
    },
    {
        color: '#ca3520',
        textColor: '#fff',
    },
    {
        color: '#6d5dc6',
        textColor: '#fff',
    },
    {
        color: '#ccdfff',
        textColor: '#000',
    },
    {
        color: '#c0f0f5',
        textColor: '#000',
    },
    {
        color: '#d3f1a7',
        textColor: '#000',
    },
    {
        color: '#fdd0eb',
        textColor: '#000',
    },
    {
        color: '#dcdfe4',
        textColor: '#000',
    },
    {
        color: '#579dff',
        textColor: '#000',
    },
    {
        color: '#60c6d2',
        textColor: '#000',
    },
    {
        color: '#94c747',
        textColor: '#000',
    },
    {
        color: '#e774bb',
        textColor: '#000',
    },
    {
        color: '#8590a1',
        textColor: '#000',
    },
    {
        color: '#0b65e4',
        textColor: '#fff',
    },
    {
        color: '#1e7f8c',
        textColor: '#fff',
    },
    {
        color: '#5b7f24',
        textColor: '#fff',
    },
    {
        color: '#ae4686',
        textColor: '#fff',
    },
    {
        color: '#616f86',
        textColor: '#fff',
    },
];
