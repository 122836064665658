import { Injectable } from '@angular/core';
import { ONLINE_GAME_STATE_ACTIONS } from '../dc-gamelogic/online-game/online.ingame.globals';
import { JanusRoom } from '../dc-services/camera/camera.models';
import { DartCounterLocalizeService } from '../dc-services/localize.service';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { SweetAlertOptions } from 'sweetalert2';
import { ActionsCollectionService } from './collection-helpers/actions.collection.service';
import {
    ACTIONTYPE,
    DCFireStoreUser,
    DCGameAction,
    FSCameraAction,
    FSGameStateAction,
    FSOmniStateAction,
    FSRematchAction,
    FSSavedGameAction,
    FSVoiceCallAction,
    INVITATIONSTATUS,
} from './globals/firestore.tables';
import { GameMode } from '../dc-backend/dc-enums';
import { OnlineGameplay } from '../dc-backend/dc-interfaces';
import { dateTimeFormat } from '../dc-gamelogic/settings/settings.globals';
import { LocalStorageKey } from '../dc-localstorage';
import moment from 'moment';
import { ActiveGamesCollectionService } from './collection-helpers/active_games.collection.service';
import { VoiceCallStatus } from '@dc-core/dc-services/voice/voice-call.service';

export interface SendAlertPayload {
    sender: number;
    gameplay_doc_id: string;
    additional_vars: string[];
    options: SweetAlertOptions;
}

export interface SendCameraActionPayload {
    sender: number;
    janusRoom: JanusRoom;
}

export interface SendVoiceCallActionPayload {
    sender: number;
    janusRoom: JanusRoom;
}

export interface SendRematchActionPayload {
    sender: number;
    status: INVITATIONSTATUS;
}

export interface SavedGameActionPayload {
    sender: number;

    game_id: number;
    game_mode: GameMode;
}

export interface PushGameStateActionPayload {
    sender: number;
    gameplay_doc_id: string;

    state: ONLINE_GAME_STATE_ACTIONS;
}

@Injectable()
export class ActionsLogicService {
    public localizeService: DartCounterLocalizeService;

    public cameraActions$: Subject<FSCameraAction> = new Subject();
    public voiceCallActions$: Subject<FSVoiceCallAction> = new Subject();
    public gameStateActions$: Subject<FSGameStateAction> = new Subject();
    public omniStateActions$: Subject<FSOmniStateAction> = new Subject();
    public savedGameActions$: BehaviorSubject<FSSavedGameAction> = new BehaviorSubject(null);

    constructor(
        public actionsCollectionService: ActionsCollectionService,
        private _activeGamesCollectionService: ActiveGamesCollectionService
    ) {}

    setupLocalize(localizeService: DartCounterLocalizeService): void {
        this.localizeService = localizeService;
    }

    checkAction(action: DCGameAction) {
        // Set the right payload
        switch (action.actionType) {
            case ACTIONTYPE.CAMERA:
                this.handleCameraAction(action as FSCameraAction);
                break;
            case ACTIONTYPE.VOICE_CALL:
                this.handleVoiceCallAction(action as FSVoiceCallAction);
                break;
            case ACTIONTYPE.OMNI_STATE:
                this.handleOmniState(action as FSOmniStateAction);
                break;
            case ACTIONTYPE.GAME_STATE:
                this.handleGameState(action as FSGameStateAction);
                break;
            case ACTIONTYPE.SAVED_GAME:
                this.handleSavedGame(action as FSSavedGameAction);
                break;
        }
    }

    pushGameState(payload: PushGameStateActionPayload) {
        const gameState: FSGameStateAction = {
            actionType: ACTIONTYPE.GAME_STATE,

            sender_user_id: payload.sender,
            sent_at: Timestamp.now(),

            state: payload.state,
        };

        this.actionsCollectionService.addItem(gameState).then((res) => {});
    }

    pushOmniState(omniState: FSOmniStateAction) {
        this.actionsCollectionService.addItem(omniState).then((res) => {});
    }

    handleGameState(state: FSGameStateAction) {
        this.gameStateActions$.next(state);
    }

    handleOmniState(state: FSOmniStateAction) {
        this.omniStateActions$.next(state);
    }

    handleSavedGame(state: FSSavedGameAction) {
        this.savedGameActions$.next(state);
    }

    sendCameraAction(payload: SendCameraActionPayload) {
        const action: FSCameraAction = {
            actionType: ACTIONTYPE.CAMERA,
            toSelf: true,

            sender_user_id: payload.sender,
            sent_at: Timestamp.now(),

            camera: payload.janusRoom,
        };

        this.actionsCollectionService.addItem(action).then((res) => {});
    }

    sendVoiceCallAction(payload: SendVoiceCallActionPayload, status: VoiceCallStatus): FSVoiceCallAction {
        const action: FSVoiceCallAction = {
            actionType: ACTIONTYPE.VOICE_CALL,
            toSelf: false,

            sender_user_id: payload.sender,
            sent_at: Timestamp.now(),

            status,
            janusRoom: payload.janusRoom,
        };

        this.actionsCollectionService.addItem(action).then((res) => {});

        return action;
    }

    sendRematchAction(payload: SendRematchActionPayload) {
        const action: FSRematchAction = {
            actionType: ACTIONTYPE.REMATCH,

            sender_user_id: payload.sender,
            sent_at: Timestamp.now(),
            status: payload.status,
        };

        this.actionsCollectionService.addItem(action);
    }

    setupRematch(onlineGameplay: OnlineGameplay, currentUid: string): void {
        if (onlineGameplay.owners[0] == currentUid) {
            const onlineSavedGameplayFromStorage = localStorage.getItem(LocalStorageKey.onlineSavedGameplay);
            if (onlineSavedGameplayFromStorage !== null) {
                const onlineSavedGameplay = JSON.parse(onlineSavedGameplayFromStorage) as OnlineGameplay;
                onlineSavedGameplay.owners = onlineGameplay.owners;
                onlineSavedGameplay.players = onlineGameplay.players;
                onlineSavedGameplay.game.started_at = moment().utc().format(dateTimeFormat);

                // This automatically adds the Active Game ref to the owners as well
                this._activeGamesCollectionService.addItem(onlineSavedGameplay);
                this._activeGamesCollectionService.isJoining = true;
            }
        }
    }

    handleCameraAction(cameraAction: FSCameraAction) {
        this.cameraActions$.next(cameraAction);
    }

    handleVoiceCallAction(voiceCallAction: FSVoiceCallAction) {
        this.voiceCallActions$.next(voiceCallAction);
    }

    clearSubjects() {
        this.cameraActions$ = new Subject();
        this.gameStateActions$ = new Subject();
        this.savedGameActions$ = new BehaviorSubject(null);
    }
}
