/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseApiService } from '@dc-api/firebase.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import { UnfinishedGamesService } from '@dc-core/dc-firestore/unfinished-games.service';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

import { ReinviteDialogComponent } from '../reinviteDialog/reinviteDialog';

@Component({
    selector: 'app-page-unfinished-games',
    templateUrl: 'unfinished-games.html',
    styleUrls: ['unfinished-games.scss'],
})
export class UnfinishedGamesComponent implements OnInit {
    uid = '';

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public loading: DCLoadingService,
        public platform: Platform,
        public modal: ModalController,
        public statisticsApiService: StatisticsApiService,
        public route: ActivatedRoute,
        public onlineFunctions: OnlineFunctions,
        public unfinishedGamesService: UnfinishedGamesService,
        private _activeGamesCollectionService: ActiveGamesCollectionService,
        private _gameApiService: GameApiService,
        private _dcInvites: InviteCollectionService,
        private _dcFireAuth: FireStoreAuthService,
        private _dcUserCollection: UsersCollectionService,
        private _alertService: DartCounterAlertService,
        private _firebaseApiService: FirebaseApiService
    ) {}

    ngOnInit() {
        this.uid = this._dcFireAuth.getCurrentUID();
    }

    public removeAll() {
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_UNFINISHED_GAMES:Delete unfinished games`,
                    text: $localize`:@@DELETE_THESE_UNFINISHED_GAMES:Do you want to delete these unfinished games?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinishedGamesService.removeAll();

                        this.nav.navigateBack('/online-games/global-lobby');
                    }
                });
            });
    }

    deleteGame(unfinishedGame: OnlineGameplay, index: number) {
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_UNFINISHED_GAME:Delete unfinished game`,
                    text: $localize`:@@DELETE_THIS_UNFINISHED_GAME:Do you want to remove this unfinished game?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinishedGamesService.remove(unfinishedGame, index);

                        if (!this.unfinishedGamesService.unfinishedGames.length) {
                            this.nav.navigateBack('/online-games/global-lobby');
                        }
                    }
                });
            });
    }

    reinvite(gameplay: OnlineGameplay) {
        // Show Dialog, do you want to invite your opponent to continue this match?
        let reinviteDialog;
        this.modal
            .create({
                component: ReinviteDialogComponent,
                cssClass: 'auto-height',
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();

                reinviteDialog = elem;
                reinviteDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinishedGamesService.reinvite(this.auth.user, gameplay);
                    }
                });
            });
    }

    saveGame(unfinishedGame: OnlineGameplay, index: number) {
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@SAVE_MATCH:Save game`,
                    text: $localize`:@@SAVING_UNFINISHED_MATCH:Do you want to save this game to your statistics?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unfinishedGamesService.save(
                            this.platform.is('ios') ? 'ios' : 'android',
                            unfinishedGame,
                            index
                        );

                        if (!this.unfinishedGamesService.unfinishedGames.length) {
                            this.nav.navigateBack('/online-games/global-lobby');
                        }
                    }
                });
            });
    }
}
