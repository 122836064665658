import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { TournamentGame } from '@dc-core/dc-backend/dc-classes';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { ActionsLogicService, PushGameStateActionPayload } from '@dc-core/dc-firestore/actions.service';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { PublicGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/public_games.collection.service';
import { ONLINE_GAME_STATE_ACTIONS } from '@dc-core/dc-gamelogic/online-game/online.ingame.globals';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, ModalOptions } from '@ionic/angular';
import { AuthService } from '@services/auth.service';
import { from, Observable, of, Subject, switchMap } from 'rxjs';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { QuitDialogComponent } from 'src/dialogs/quit/quit';
import {
    SaveOnlineGameDialogComponent,
    SaveOnlineGameDialogResponse,
} from 'src/dialogs/save-online-game/save-online-game';

@Injectable({
    providedIn: 'root',
})
export class GamesLeaveBusterService {
    public saveGame$: Subject<boolean> = new Subject<boolean>();
    public destroyedGame: WritableSignal<boolean> = signal(false);
    public inGame: WritableSignal<boolean> = signal(false);

    private authService: AuthService = inject(AuthService);
    private actionsLogicService: ActionsLogicService = inject(ActionsLogicService);
    private activeGamesCollectionService: ActiveGamesCollectionService = inject(ActiveGamesCollectionService);
    private publicGamesCollectionService: PublicGamesCollectionService = inject(PublicGamesCollectionService);
    private modalController: ModalController = inject(ModalController);

    public currentUserId: number;
    public activeOnlineGame: OnlineGameplay;
    public currentTournamentGame: TournamentGame;
    public allowedToLeave: boolean = false;
    public gameIsFinished: boolean = false;
    public leaveAfterReport: boolean = false;
    public onlineEndScreenDialog: HTMLIonModalElement = null;

    private leaveDialogIsOpened = false;

    private gameMode: GameMode;

    constructor() {}

    public initOnlineLeaveBuster(
        currentUserId: number,
        activeGame: OnlineGameplay,
        allowedToLeave: boolean = false,
        gameIsFinished: boolean = false,
        leaveAfterReport: boolean = false
    ): void {
        this.inGame.set(true);

        this.currentUserId = currentUserId;
        this.activeOnlineGame = activeGame;
        this.currentTournamentGame = activeGame.tournament_game ?? null;
        this.allowedToLeave = allowedToLeave;
        this.gameIsFinished = gameIsFinished;
        this.leaveAfterReport = leaveAfterReport;

        this.leaveDialogIsOpened = false;

        this.destroyedGame.set(false);
    }

    public initLocalLeaveBuster(
        gameMode: GameMode,
        allowedToLeave: boolean = false,
        gameIsFinished: boolean = false
    ): void {
        this.inGame.set(true);

        this.gameMode = gameMode;
        this.allowedToLeave = allowedToLeave;
        this.gameIsFinished = gameIsFinished;

        this.leaveDialogIsOpened = false;
    }

    public resetOnlineLeaveBuster(): void {
        this.inGame.set(false);

        this.currentUserId = null;
        this.activeOnlineGame = null;
        this.allowedToLeave = false;
        this.gameIsFinished = false;
        this.leaveAfterReport = false;

        this.leaveDialogIsOpened = false;

        this.destroyedGame.set(true);
    }

    public resetLocalLeaveBuster(): void {
        this.inGame.set(false);

        this.allowedToLeave = false;
        this.gameIsFinished = false;

        this.leaveDialogIsOpened = false;
    }

    public isAllowedToLeaveOnlineGame(): boolean {
        if (
            this.authService.forceDeactivateRoute ||
            this.allowedToLeave ||
            !this.activeOnlineGame ||
            this.gameIsFinished
        ) {
            return true;
        }
    }

    public isAllowedToLeaveLocalGame(): boolean {
        if (this.authService.forceDeactivateRoute || this.allowedToLeave || this.gameIsFinished) {
            return true;
        }
    }

    public isOpponentsTurn(): boolean {
        return this.authService.user.id !== this.currentUserId && !this.leaveAfterReport;
    }

    public startOnlineLeaveProcess(): Observable<boolean> {
        if (this.leaveDialogIsOpened) {
            return of(false);
        }

        this.leaveDialogIsOpened = true;

        return from(
            this.modalController.create({
                component: QuitDialogComponent,
                cssClass: 'auto-height',
            })
        ).pipe(
            switchMap((elem) => {
                elem.present();
                return from(elem.onDidDismiss()).pipe(
                    switchMap((dialogRes) => {
                        if (dialogRes.data === 'quit') {
                            this.leaveDialogIsOpened = false;

                            return this.leaveOnlineGame();
                        } else if (dialogRes.data === 'save') {
                            return from(
                                this.modalController.create({
                                    component: SaveOnlineGameDialogComponent,
                                    cssClass: 'auto-height',
                                    showBackdrop: true,
                                    backdropDismiss: false,
                                } as ModalOptions)
                            ).pipe(
                                switchMap((elem) => {
                                    elem.present();

                                    return from(elem.onDidDismiss()).pipe(
                                        switchMap((dialogRes) => {
                                            this.leaveDialogIsOpened = false;

                                            const result: SaveOnlineGameDialogResponse = dialogRes.data;
                                            if (result === 'save_to_continue') {
                                                return this.saveOnlineGameToContinue();
                                            } else if (result === 'save_to_stats') {
                                                return this.saveOnlineGameToStats();
                                            }
                                            return of(false);
                                        })
                                    );
                                })
                            );
                        } else {
                            this.leaveDialogIsOpened = false;

                            return of(false);
                        }
                    })
                );
            })
        );
    }

    public startLocalLeaveProcess(): Observable<boolean> {
        if (this.leaveDialogIsOpened) {
            return of(false);
        }

        this.leaveDialogIsOpened = true;

        if (this.gameMode === 'match') {
            return from(
                this.modalController.create({
                    component: QuitDialogComponent,
                    cssClass: 'auto-height',
                })
            ).pipe(
                switchMap((elem) => {
                    elem.present();
                    return from(elem.onDidDismiss()).pipe(
                        switchMap((dialogRes) => {
                            this.leaveDialogIsOpened = false;

                            if (dialogRes.data) {
                                this.saveGame$.next(dialogRes.data === 'save');
                                return of(true);
                            }
                            return of(false);
                        })
                    );
                })
            );
        } else {
            return from(
                this.modalController.create({
                    component: PromptDialogComponent,
                    componentProps: {
                        title: $localize`:@@QUIT_GAME:Quit game`,
                        text: $localize`:@@SURE_TO_QUIT:Are you sure you want to quit this game?`,
                        cancelText: $localize`:@@CANCEL:Cancel`,
                        confirmText: $localize`:@@QUIT:Quit`,
                    } as AlertPayload,
                    cssClass: 'auto-height',
                    showBackdrop: true,
                    backdropDismiss: false,
                })
            ).pipe(
                switchMap((elem) => {
                    elem.present();
                    return from(elem.onDidDismiss()).pipe(
                        switchMap((dialogRes) => {
                            this.leaveDialogIsOpened = false;

                            if (dialogRes.data) {
                                return of(true);
                            }
                            return of(false);
                        })
                    );
                })
            );
        }
    }

    private leaveOnlineGame(): Observable<boolean> {
        this.actionsLogicService.pushGameState({
            sender: this.authService.user.id,
            gameplay_doc_id: this.activeOnlineGame.doc_id,
            state: ONLINE_GAME_STATE_ACTIONS.OPPONENT_QUIT,
        } as PushGameStateActionPayload);

        this.quitOnlineGame();

        return of(true);
    }

    private saveOnlineGameToContinue(): Observable<boolean> {
        this.actionsLogicService.pushGameState({
            sender: this.authService.user.id,
            gameplay_doc_id: this.activeOnlineGame.doc_id,
            state: ONLINE_GAME_STATE_ACTIONS.OPPONENT_SAVED_TO_CONTINUE,
        } as PushGameStateActionPayload);

        this.quitOnlineGame();

        return of(true);
    }

    private saveOnlineGameToStats(): Observable<boolean> {
        this.actionsLogicService.pushGameState({
            sender: this.authService.user.id,
            gameplay_doc_id: this.activeOnlineGame.doc_id,
            state: ONLINE_GAME_STATE_ACTIONS.OPPONENT_SAVED_TO_STATS,
        } as PushGameStateActionPayload);

        this.saveGame$.next(false);

        this.quitOnlineGame();

        return of(true);
    }

    private async quitOnlineGame(): Promise<void> {
        await this.activeGamesCollectionService.quitOnlineGame();
        this.publicGamesCollectionService.remove();
    }
}
