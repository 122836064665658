import { Component, OnInit } from '@angular/core';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AuthApiService } from '@dc-api/auth.api.service';
import { ModalController, Platform } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { socials } from 'src/environments/environment';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-link-socials-dialog',
    templateUrl: 'link-socials-dialog.html',
})
export class LinkSocialsDialogComponent implements OnInit {
    showAppleAuth = false;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        private loading: DCLoadingService,
        private _alertService: DartCounterAlertService,
        private authApiService: AuthApiService,
        private platform: Platform
    ) {}

    ngOnInit() {
        if (this.platform.is('ios')) {
            this.showAppleAuth = true;
        }
    }

    async linkFacebook() {
        this.loading.ShowDefaultLoader();

        await FacebookLogin.login({ permissions: ['email', 'public_profile'] })
            .then(async (result) => {
                const facebookUser = await FacebookLogin.getProfile({ fields: ['first_name', 'last_name'] });
                this.authApiService
                    .facebookLogin({
                        token: result.accessToken.token,
                        first_name: facebookUser['first_name'] as string,
                        last_name: facebookUser['last_name'] as string,
                        check_opt_in: true,
                    })
                    .then((res) => {
                        this.auth.saveAuthentication(res.data.user, res.data.access_token);
                        this.loading.DismissLoader();
                        this._alertService.createAlert({});
                    })
                    .catch((err) => {
                        this.loading.DismissLoader();
                        this._alertService.errorFromApi(err);
                    });
            })
            .catch(() => {
                this.loading.DismissLoader();
                this._alertService.createAlert({
                    title: 'Facebook login failed',
                    icon: 'error',
                });
            });
    }

    async unlinkFacebook() {
        this.loading.ShowDefaultLoader();

        this.authApiService
            .unlinkFacebook({})
            .then((res) => {
                this.auth.saveAuthentication(res.data.user, res.data.access_token);
                this._alertService.createAlert({});
            })
            .catch((err) => {
                this._alertService.errorFromApi(err);
            })
            .finally(() => this.loading.DismissLoader());
    }

    async linkApple() {
        this.loading.ShowDefaultLoader();

        const options: SignInWithAppleOptions = {
            clientId: socials.apple.clientId,
            redirectURI: socials.apple.redirectUrl,
            scopes: 'email name',
        };
        SignInWithApple.authorize(options)
            .then((result: SignInWithAppleResponse) => {
                this.authApiService
                    .appleLogin({
                        token: result.response.identityToken,
                        first_name: result.response.givenName,
                        last_name: result.response.familyName,
                        check_opt_in: true,
                    })
                    .then((res) => {
                        this.auth.saveAuthentication(res.data.user, res.data.access_token);
                        this.loading.DismissLoader();
                        this._alertService.createAlert({});
                    })
                    .catch((err) => {
                        this.loading.DismissLoader();
                        this._alertService.errorFromApi(err);
                    });
            })
            .catch((error) => {
                this.loading.DismissLoader();
                this._alertService.createAlert({
                    title: error,
                    icon: 'error',
                });
            });
    }

    async unlinkApple() {
        this.loading.ShowDefaultLoader();

        this.authApiService
            .unlinkApple({})
            .then((res) => {
                this.auth.saveAuthentication(res.data.user, res.data.access_token);
                this._alertService.createAlert({});
            })
            .catch((err) => {
                this._alertService.errorFromApi(err);
            })
            .finally(() => this.loading.DismissLoader());
    }

    async linkGoogle() {
        this.loading.ShowDefaultLoader();

        try {
            const googleUser = await GoogleAuth.signIn();
            const accessToken = googleUser.authentication.accessToken;
            if (accessToken) {
                this.authApiService
                    .googleLogin({
                        token: accessToken,
                        first_name: googleUser.givenName,
                        last_name: googleUser.familyName,
                        check_opt_in: true,
                    })
                    .then((res) => {
                        this.auth.saveAuthentication(res.data.user, res.data.access_token);
                        this.loading.DismissLoader();
                        this._alertService.createAlert({});
                    })
                    .catch((err) => {
                        this.loading.DismissLoader();
                        this._alertService.errorFromApi(err);
                    });
            } else {
                this.loading.DismissLoader();
                this._alertService.createAlert({
                    title: 'Google login failed',
                    icon: 'error',
                });
            }
        } catch (e) {
            this.loading.DismissLoader();
        }
    }

    async unlinkGoogle() {
        this.loading.ShowDefaultLoader();

        this.authApiService
            .unlinkGoogle({})
            .then((res) => {
                this.auth.saveAuthentication(res.data.user, res.data.access_token);
                this._alertService.createAlert({});
            })
            .catch((err) => {
                this._alertService.errorFromApi(err);
            })
            .finally(() => this.loading.DismissLoader());
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
