<div
    class="omni-heatmap-container flex items-center justify-center h-full w-full animate__animated animate__fadeIn"
    #omniHeatmapWrapper>
    <div class="heatmap-container" #heatmapContainer>
        <!-- dark overlay -->
        @if (loaded) {
            <div id="svg-overlay"></div>
        }
    </div>
</div>
