import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, InputSignal, OnInit } from '@angular/core';
import { DCOnlineCore } from '@dc-core/dc-gamelogic/online-core.functions';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { UserMedia } from '@dc-core/dc-services/dc-janus/DartCounterUserMedia';

@Component({
    selector: 'app-games-online-player-camera',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './games-online-player-camera.component.html',
})
export class GamesOnlinePlayerCameraComponent implements OnInit {
    public authenticatedUserId: InputSignal<number> = input.required<number>();
    public currentUserId: InputSignal<number> = input.required<number>();
    public isSpectator: InputSignal<boolean> = input<boolean>(false);
    public allowOwnCamView: InputSignal<boolean> = input<boolean>(false);
    public visible: InputSignal<boolean> = input<boolean>(true);

    public onlineCore: DCOnlineCore = inject(DCOnlineCore);

    private ingameCameraService: InGameCameraService = inject(InGameCameraService);

    constructor() {
        effect(() => {
            const videoIdToActivate = [...this.cameraStreams().keys()].find(
                (userId) => userId === this.currentUserId()
            );
            const videoIdsToPause = [...this.cameraStreams().keys()].filter(
                (userId) => userId !== this.currentUserId()
            );

            this.toggleVideoPlayback(videoIdToActivate, videoIdsToPause);
        });
    }

    public ngOnInit(): void {
        const videoIdToActivate = [...this.cameraStreams().keys()].find((userId) => userId === this.currentUserId());
        const videoIdsToPause = [...this.cameraStreams().keys()].filter((userId) => userId !== this.currentUserId());

        this.toggleVideoPlayback(videoIdToActivate, videoIdsToPause);
    }

    public cameraStreams(): Map<number, UserMedia> {
        return this.ingameCameraService.userMediaMap;
    }

    private toggleVideoPlayback(videoIdToActivate: number, videoIdsToPause: number[]): void {
        videoIdsToPause.forEach((videoId) => {
            const videoElement = document.getElementById(`player-cam-${videoId}`) as HTMLVideoElement;
            videoElement?.pause();
        });

        const videoElement = document.getElementById(`player-cam-${videoIdToActivate}`) as HTMLVideoElement;
        videoElement?.play();
    }
}
