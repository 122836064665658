<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@H2HSETTING_INFO">
        John McDonald announcements. Switch off doesn't apply to online games
    </ng-container>
    <ng-container i18n="@@CHECKOUT_PERCENTAGE_SWITCHED_ON">
        Checkout percentage is switched on. You need to enter ‘Amount of darts on a double’ when you have got 50 points
        or less left, so your checkout rate will be calculated.
    </ng-container>
    <ng-container i18n="@@CHECKOUT_PERCENTAGE_SWITCHED_OFF">
        Checkout percentage is switched off. You won’t enter ‘Amount of darts on a double’ when you have got 50 points
        or less left, so your checkout rate won’t be calculated.
    </ng-container>
    <ng-container i18n="@@ACTION_REPLAYS_SETTINGS_TOOLBOX"
        >When you activated your camera, the Action Replays feature will be enabled. You can clip and save your
        highlights.</ng-container
    >
}
<!-- End of translation containers-->

<app-page-header mainNavigation="back" defaultBack="settings">
    <div title [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</div>
</app-page-header>

<div class="h-full flex flex-col bg-white-pattern overflow-y-auto text-black" id="main-content">
    <div class="flex-1" appWebContainer>
        <div class="flex max-w-xl mx-auto px-8 py-4 w-full flex-col space-y-3 text-black">
            <div class="flex h-8">
                <div class="flex items-center whitespace-nowrap gap-4">
                    <app-icon icon="volume_high"></app-icon>
                    <span [translate]="'MASTERCALLER_RAY_MARTIN'" i18n="@@MASTERCALLER_RAY_MARTIN">
                        MasterCaller Ray Martin
                    </span>
                </div>
                <app-checkbox-new [reverse]="true" class="flex ml-auto mt-1">
                    <input type="checkbox" [checked]="preferenceService.allowCaller" (click)="toggleCaller()" />
                </app-checkbox-new>
            </div>
            <div class="flex h-8">
                <div class="flex items-center whitespace-nowrap gap-4">
                    <app-icon icon="three_dots"></app-icon>
                    <span [translate]="'HEAD_TO_HEAD_STATS'" i18n="@@HEAD_TO_HEAD_STATS"> Head-to-head stats </span>
                    <div
                        class="bg-blue p-0.5 !text-white rounded-full cursor-pointer"
                        (click)="showHelpModal('HEAD_TO_HEAD_STATS', 'H2HSETTING_INFO')">
                        <app-icon icon="questionmark" size="small"></app-icon>
                    </div>
                </div>
                <app-checkbox-new [reverse]="true" class="flex ml-auto mt-1">
                    <input type="checkbox" [checked]="preferenceService.showHeadToHead" (click)="toggleHeadtohead()" />
                </app-checkbox-new>
            </div>
            @if (auth.user?.profile) {
                <div class="flex h-8">
                    <div class="flex items-center whitespace-nowrap gap-4">
                        <app-icon icon="dartboard"></app-icon>
                        <span [translate]="'CHECKOUT_RATE_PERCENT'" i18n="@@CHECKOUT_RATE_PERCENT">
                            Checkout percentage
                        </span>
                        <div
                            class="bg-blue p-0.5 !text-white rounded-full cursor-pointer"
                            (click)="
                                showHelpModal(
                                    'CHECKOUT_RATE_PERCENT',
                                    auth.user.profile.has_checkout_rate
                                        ? 'CHECKOUT_PERCENTAGE_SWITCHED_ON'
                                        : 'CHECKOUT_PERCENTAGE_SWITCHED_OFF'
                                )
                            ">
                            <app-icon icon="questionmark" size="small"></app-icon>
                        </div>
                    </div>
                    <app-checkbox-new [reverse]="true" class="ml-auto mt-1">
                        <input
                            type="checkbox"
                            [checked]="auth.user.profile.has_checkout_rate"
                            (click)="toggleHasCheckoutRate()" />
                    </app-checkbox-new>
                </div>
            }
            @if (appFeaturesService.enabledAppFeatures().action_replays) {
                <div class="flex h-8">
                    <div class="flex items-center whitespace-nowrap gap-4">
                        <app-icon icon="action_replays"></app-icon>
                        <span [translate]="'ACTION_REPLAYS'" i18n="@@ACTION_REPLAYS">Action Replays</span>
                        <div
                            class="bg-blue p-0.5 !text-white rounded-full cursor-pointer"
                            (click)="showHelpModal('ACTION_REPLAYS', 'ACTION_REPLAYS_SETTINGS_TOOLBOX')">
                            <app-icon icon="questionmark" size="small"></app-icon>
                        </div>
                    </div>
                    <app-checkbox-new [reverse]="true" class="ml-auto mt-1">
                        <input
                            type="checkbox"
                            [checked]="preferenceService.enableActionReplays"
                            (click)="toggleActionReplays()" />
                    </app-checkbox-new>
                </div>
            }

            <div class="flex flex-col divide-y divide-neutral-200">
                <div class="flex items-center justify-between w-full py-3 cursor-pointer" (click)="ShowHotkeys()">
                    <span [translate]="'PERSONAL_HOTKEYS'" i18n="@@PERSONAL_HOTKEYS">Personal hotkeys</span>
                    <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
                </div>
                <div class="flex items-center justify-between w-full py-3 cursor-pointer" (click)="LinkName()">
                    <span [translate]="'YOUR_SOUND'" i18n="@@YOUR_SOUND">Your sound</span>
                    <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
                </div>
            </div>
        </div>
    </div>
</div>
