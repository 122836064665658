<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog !bg-black flex flex-col overflow-y-hidden h-[40rem]">
        <app-icon icon="close" class="text-black close-button" (click)="dismiss()"></app-icon>
        <div class="flex justify-center items-center space-x-2">
            <img class="w-24" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
            <div class="text-white font-bold text-2xl" i18n="@@HEATMAP" [translate]="'HEATMAP'">Heatmap</div>
        </div>

        <div class="flex h-full w-full py-6">
            <app-omni-heatmap [coordinates]="coordinates" class="w-full h-full"></app-omni-heatmap>
        </div>
    </div>
</modal-content>
