import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, input, InputSignal, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';

@Component({
    selector: 'app-animated-title',
    standalone: true,
    templateUrl: 'animated-title.component.html',
    imports: [CommonModule],
    animations: [
        trigger('fade', [
            transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0 }))]),
        ]),
    ],
})
export class AnimatedTitleComponent implements OnInit, OnDestroy {
    public titles: InputSignal<string[]> = input.required();
    public speed: InputSignal<number> = input(5000);

    public currentTitle: WritableSignal<string> = signal(null);

    private titleIndex: number = 0;
    private titleInterval: any;

    public ngOnInit(): void {
        this.currentTitle.set(this.titles()[this.titleIndex]);

        if (this.titles().length > 1) {
            this.titleInterval = setInterval(() => {
                this.titleIndex = (this.titleIndex + 1) % this.titles().length;
                this.currentTitle.set(null);
                setTimeout(() => {
                    this.currentTitle.set(this.titles()[this.titleIndex]);
                }, 200);
            }, this.speed());
        }
    }

    public ngOnDestroy(): void {
        if (this.titleInterval) {
            clearInterval(this.titleInterval);
        }
    }
}
