import { Injectable } from '@angular/core';
import { JanusRoom } from '../camera/camera.models';

@Injectable({
    providedIn: 'root',
})
export class OwnJanusService {
    public ownCamera: JanusRoom = {
        janusServerHost: null,
        roomID: null,
        camType: null,
        scale: 'scale(1)',
        disablePlayerCamForSpectators: false,
        disablePlayerCamForAll: false,
    };

    public ownVoiceCall: JanusRoom = {
        janusServerHost: null,
        roomID: null,
        camType: null,
    };
}
