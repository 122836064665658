<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="cancel()"></app-icon>
        <div class="title-container">
            <div class="title" [translate]="'SAVE_ONLINE_GAME'" i18n="@@SAVE_ONLINE_GAME">Save online game</div>
            <div
                class="subtitle"
                [translate]="'SAVE_TO_CONTINUE_OR_STATISTICS'"
                i18n="@@SAVE_TO_CONTINUE_OR_STATISTICS">
                Do you want to save this game to continue later or close the game and add it to your statistics?
            </div>
        </div>
        <div class="button-container flex justify-between space-x-2">
            <app-primary-button
                size="normal"
                color="blue"
                (click)="saveToContinue()"
                [translate]="'SAVE_TO_CONTINUE'"
                i18n="@@SAVE_TO_CONTINUE">
                Save to continue
            </app-primary-button>
            <app-primary-button
                size="normal"
                color="orange"
                (click)="saveToStats()"
                [translate]="'SAVE_TO_STATISTICS'"
                i18n="@@SAVE_TO_STATISTICS">
                Save to statistics
            </app-primary-button>
        </div>
    </div>
</modal-content>
