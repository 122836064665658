/* eslint-disable max-len */
import { Component, inject, Input } from '@angular/core';
import { SinglesTraining } from '@dc-core/dc-backend/dc-classes';
import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-singles-training-card',
    templateUrl: 'singles-training-card.component.html',
})
export class SinglesTrainingCardComponent {
    @Input() game: SinglesTraining;
    @Input() showArrow = true;

    public translateService: TranslateService = inject(TranslateService);

    constructor(public nav: NavController) {}

    getUserName(user: any): string {
        if (user.user) {
            return user.user.first_name;
        } else {
            return user.ghost_name;
        }
    }

    ShowDetail(item) {
        this.nav.navigateForward('statistics/singles-training/details', {
            queryParams: {
                item,
                gameId: item.id,
            },
        } as NavigationOptions);
    }
}
