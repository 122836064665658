/* eslint-disable max-len */
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthApiService } from '@dc-api/auth.api.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { SocialMediaService } from 'src/providers/SocialMediaService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-edit-email',
    templateUrl: 'edit-email.html',
})
export class EditEmailComponent {
    step = 1;
    Digits: string;
    sentEmail = false;

    public editEmailForm: FormGroup = this.formBuilder.group({
        new_email: ['', Validators.required],
        confirm_new_email: ['', Validators.required],
    });

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public navCtrl: NavController,
        public loading: DCLoadingService,
        public social: SocialMediaService,
        public modal: ModalController,
        public route: ActivatedRoute,
        public authApiService: AuthApiService,
        private _alertService: DartCounterAlertService,
        private translate: TranslateService,
        private formBuilder: FormBuilder
    ) {
        $localize`:@@NEW_EMAIL:New email`;
        $localize`:@@REPEAT_NEW_EMAIL:Repeat new email`;
    }

    SubmitNewEmail() {
        if (this.editEmailForm.invalid) {
            return;
        }

        this.loading.ShowDefaultLoader();

        this.authApiService
            .changeEmail({
                email: this.editEmailForm.get('new_email').value,
                type: 'code',
            })
            .then(() => {
                this.loading.DismissLoader();
                this.sentEmail = true;
                this.step++;

                this._alertService.createAlert({
                    title: $localize`:@@EMAIL_VERIFICATION_HAS_BEEN_SENT:The email verification has been sent!`,
                });
            })
            .catch((e) => {
                if (e instanceof this.authApiService.changeEmail.Error) {
                    this.loading.DismissLoader();
                    const error = e.getActualType();
                    this._alertService.createAlert({
                        title: error.data.message,
                        icon: 'error',
                    });
                }
            })
            .finally();
    }

    AttemptDigits() {
        this.loading.ShowDefaultLoader();

        this.authApiService
            .verifyEmailByCode({
                code: parseInt(this.Digits),
            })
            .then(() => {
                this.loading.DismissLoader();

                this._alertService.createAlert({
                    title: $localize`:@@YOU_CHANGED_YOUR_EMAIL:You changed your email!`,
                });

                this.auth.user.email = this.editEmailForm.get('new_email').value;
                this.nav.pop();
            })
            .catch((err) => {
                this.loading.DismissLoader();
                this._alertService.errorFromApi(err);
            })
            .finally();
    }

    dismiss() {
        this.modal.dismiss();
    }
}
