import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { COUNTRIES } from '@dc-core/dc-firestore/globals/countries.globals';
import { ModalController } from '@ionic/angular';
import { take } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-countries-dialog',
    templateUrl: 'countries.dialog.html',
    styleUrls: ['countries.dialog.scss'],
})
export class CountriesDialogComponent {
    selectedCountry: any;
    searchString: string;
    profile: any;
    public countries: { label: string; name: string; flag: string; alpha2: string }[] = COUNTRIES;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.profile = params.profile;
        });
    }

    selectCountry(country) {
        this.selectedCountry = country.alpha2;
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
