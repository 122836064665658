import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var CameraPreview = /** @class */function (_super) {
  __extends(CameraPreview, _super);
  function CameraPreview() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.FOCUS_MODE = {
      FIXED: 'fixed',
      AUTO: 'auto',
      CONTINUOUS: 'continuous',
      CONTINUOUS_PICTURE: 'continuous-picture',
      CONTINUOUS_VIDEO: 'continuous-video',
      EDOF: 'edof',
      INFINITY: 'infinity',
      MACRO: 'macro' // Android Only
    };
    _this.EXPOSURE_MODE = {
      LOCK: 'lock',
      AUTO: 'auto',
      CONTINUOUS: 'continuous',
      CUSTOM: 'custom'
    };
    _this.FLASH_MODE = {
      OFF: 'off',
      ON: 'on',
      AUTO: 'auto',
      RED_EYE: 'red-eye',
      TORCH: 'torch' // Android Only
    };
    _this.COLOR_EFFECT = {
      AQUA: 'aqua',
      BLACKBOARD: 'blackboard',
      MONO: 'mono',
      NEGATIVE: 'negative',
      NONE: 'none',
      POSTERIZE: 'posterize',
      SEPIA: 'sepia',
      SOLARIZE: 'solarize',
      WHITEBOARD: 'whiteboard' // Android Only
    };
    _this.CAMERA_DIRECTION = {
      BACK: 'back',
      FRONT: 'front'
    };
    return _this;
  }
  CameraPreview.prototype.startCamera = function (options) {
    return cordova(this, "startCamera", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.startRecordVideo = function (options) {
    return cordova(this, "startRecordVideo", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.stopCamera = function () {
    return cordova(this, "stopCamera", {}, arguments);
  };
  CameraPreview.prototype.stopRecordVideo = function () {
    return cordova(this, "stopRecordVideo", {}, arguments);
  };
  CameraPreview.prototype.switchCamera = function () {
    return cordova(this, "switchCamera", {}, arguments);
  };
  CameraPreview.prototype.hide = function () {
    return cordova(this, "hide", {}, arguments);
  };
  CameraPreview.prototype.show = function () {
    return cordova(this, "show", {}, arguments);
  };
  CameraPreview.prototype.takePicture = function (options) {
    return cordova(this, "takePicture", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.takeSnapshot = function (options) {
    return cordova(this, "takeSnapshot", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.setColorEffect = function (effect) {
    return cordova(this, "setColorEffect", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.setZoom = function (zoom) {
    return cordova(this, "setZoom", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.getMaxZoom = function () {
    return cordova(this, "getMaxZoom", {}, arguments);
  };
  CameraPreview.prototype.getZoom = function () {
    return cordova(this, "getZoom", {}, arguments);
  };
  CameraPreview.prototype.setPreviewSize = function (dimensions) {
    return cordova(this, "setPreviewSize", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.getFocusMode = function () {
    return cordova(this, "getFocusMode", {}, arguments);
  };
  CameraPreview.prototype.setFocusMode = function (focusMode) {
    return cordova(this, "setFocusMode", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.getSupportedFocusModes = function () {
    return cordova(this, "getSupportedFocusModes", {}, arguments);
  };
  CameraPreview.prototype.getFlashMode = function () {
    return cordova(this, "getFlashMode", {}, arguments);
  };
  CameraPreview.prototype.setFlashMode = function (flashMode) {
    return cordova(this, "setFlashMode", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.getSupportedFlashModes = function () {
    return cordova(this, "getSupportedFlashModes", {}, arguments);
  };
  CameraPreview.prototype.getSupportedPictureSizes = function () {
    return cordova(this, "getSupportedPictureSizes", {}, arguments);
  };
  CameraPreview.prototype.getExposureMode = function () {
    return cordova(this, "getExposureMode", {}, arguments);
  };
  CameraPreview.prototype.getExposureModes = function () {
    return cordova(this, "getExposureModes", {}, arguments);
  };
  CameraPreview.prototype.setExposureMode = function (lock) {
    return cordova(this, "setExposureMode", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.getExposureCompensation = function () {
    return cordova(this, "getExposureCompensation", {}, arguments);
  };
  CameraPreview.prototype.setExposureCompensation = function (exposureCompensation) {
    return cordova(this, "setExposureCompensation", {
      "successIndex": 1,
      "errorIndex": 2
    }, arguments);
  };
  CameraPreview.prototype.getExposureCompensationRange = function () {
    return cordova(this, "getExposureCompensationRange", {}, arguments);
  };
  CameraPreview.prototype.tapToFocus = function (xPoint, yPoint) {
    return cordova(this, "tapToFocus", {}, arguments);
  };
  CameraPreview.prototype.onBackButton = function () {
    return cordova(this, "onBackButton", {}, arguments);
  };
  CameraPreview.prototype.getHorizontalFOV = function () {
    return cordova(this, "getHorizontalFOV", {}, arguments);
  };
  CameraPreview.prototype.getCameraCharacteristics = function () {
    return cordova(this, "getCameraCharacteristics", {}, arguments);
  };
  CameraPreview.ɵfac = /* @__PURE__ */(() => {
    let ɵCameraPreview_BaseFactory;
    return function CameraPreview_Factory(__ngFactoryType__) {
      return (ɵCameraPreview_BaseFactory || (ɵCameraPreview_BaseFactory = i0.ɵɵgetInheritedFactory(CameraPreview)))(__ngFactoryType__ || CameraPreview);
    };
  })();
  CameraPreview.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CameraPreview,
    factory: CameraPreview.ɵfac
  });
  CameraPreview.pluginName = "CameraPreview";
  CameraPreview.plugin = "cordova-plugin-camera-preview";
  CameraPreview.pluginRef = "CameraPreview";
  CameraPreview.repo = "https://github.com/cordova-plugin-camera-preview/cordova-plugin-camera-preview";
  CameraPreview.platforms = ["Android", "iOS"];
  CameraPreview = __decorate([], CameraPreview);
  return CameraPreview;
}(AwesomeCordovaNativePlugin);
export { CameraPreview };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CameraPreview, [{
    type: Injectable
  }], null, {
    startCamera: [],
    startRecordVideo: [],
    stopCamera: [],
    stopRecordVideo: [],
    switchCamera: [],
    hide: [],
    show: [],
    takePicture: [],
    takeSnapshot: [],
    setColorEffect: [],
    setZoom: [],
    getMaxZoom: [],
    getZoom: [],
    setPreviewSize: [],
    getFocusMode: [],
    setFocusMode: [],
    getSupportedFocusModes: [],
    getFlashMode: [],
    setFlashMode: [],
    getSupportedFlashModes: [],
    getSupportedPictureSizes: [],
    getExposureMode: [],
    getExposureModes: [],
    setExposureMode: [],
    getExposureCompensation: [],
    setExposureCompensation: [],
    getExposureCompensationRange: [],
    tapToFocus: [],
    onBackButton: [],
    getHorizontalFOV: [],
    getCameraCharacteristics: []
  });
})();
