<app-page-header mainNavigation="back" defaultBack="local-games">
    <div title [translate]="'CRICKET_TACTICS'" i18n="@@CRICKET_TACTICS">Cricket / Tactics</div>
    <app-fullscreen-icon right></app-fullscreen-icon>
</app-page-header>

<div class="pb-safe-offset relative flex h-full flex-col overflow-hidden bg-white-pattern text-black" id="main-content">
    <div class="flex flex-1 flex-col overflow-auto p-4" appWebContainer>
        <div class="space-y-2">
            <!-- Player details -->
            <div class="new-game-card">
                <div class="title-container">
                    <div class="icon">
                        <app-icon icon="people_alt" size="small"></app-icon>
                    </div>
                    <div class="title" [translate]="'PLAYER_DETAILS'" i18n="@@PLAYER_DETAILS">Player details</div>
                </div>

                <app-toggle-switch
                    class="block w-full"
                    [disabled]="
                        (teams[1]?.users.length === 1 && vsDartbot && playerType === 'teams') ||
                        (playerType === 'teams' &&
                            teams[0].users.length === 2 &&
                            teams[1].users.length === 2 &&
                            !vsDartbot) ||
                        (playerType === 'players' && users.length === 4 && !vsDartbot)
                    "
                    [model]="vsDartbot"
                    (itemChange)="vsDartbotChanged($event)">
                    <div class="flex items-center space-x-1">
                        <div [translate]="'VS_DARTBOT'" i18n="@@VS_DARTBOT">vs. Dartbot</div>
                        <div
                            class="rounded-md bg-neutral-900 px-2 text-sm font-bold uppercase text-white"
                            (click)="vsDartbotChanged(true)"
                            *ngIf="dartbotAdded && dartbotLevel">
                            <span i18n="@@LEVEL">Level</span> {{ dartbotLevel.level }}
                        </div>
                    </div>
                </app-toggle-switch>

                <app-list-switch
                    class="block"
                    size="medium"
                    [item]="playerType"
                    [items]="playerTypes"
                    (itemChange)="changePlayerType($event)"></app-list-switch>

                <div class="mt-4" *ngIf="playerType === 'players'">
                    <app-games-local-add-player
                        [users]="users"
                        (updatedUsersEvent)="users = $event"
                        (addPlayerEvent)="addPlayer($event)"
                        (removePlayerEvent)="removePlayer($event)"
                        (showPlayerOptionsEvent)="showPlayerOptions($event)"></app-games-local-add-player>
                </div>

                <div class="mt-6" *ngIf="playerType === 'teams'">
                    <app-new-game-add-team
                        [(teams)]="teams"
                        (addPlayerEvent)="addPlayer($event)"
                        (removePlayerEvent)="removePlayer($event)"
                        (showPlayerOptionsEvent)="showPlayerOptions($event)"></app-new-game-add-team>
                </div>
            </div>

            <!-- Game settings -->
            <div class="new-game-card">
                <div class="title-container">
                    <div class="icon">
                        <app-icon icon="tune" size="small"></app-icon>
                    </div>
                    <div class="title" [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</div>
                </div>

                <div class="flex flex-col space-y-2">
                    <app-list-switch [item]="mode" [items]="modes" (itemChange)="changeMode($event)"></app-list-switch>
                    <div
                        class="text-left text-xs text-neutral-600"
                        *ngIf="mode === 'cricket'"
                        [translate]="'CRICKET_EXPLANATION'"
                        i18n="@@CRICKET_EXPLANATION">
                        In Cricket you need to close all the numbers from 15 to 20 as quick as you can. You will 'close'
                        the number by hitting them 3 times. Single = 1, Double = 2, Treble = 3.
                    </div>
                    <div
                        class="text-left text-xs text-neutral-600"
                        *ngIf="mode === 'tactics'"
                        [translate]="'TACTICS_EXPLANATION'"
                        i18n="@@TACTICS_EXPLANATION">
                        In Tactics you need to close all the numbers from 10 to 20 as quick as you can. You will 'close'
                        the number by hitting them 3 times. Single = 1, Double = 2, Treble = 3.
                    </div>

                    <div class="flex items-center space-x-1">
                        <app-list-switch
                            class="w-full"
                            [item]="bestOfFirstTo"
                            [items]="bestOfFirstTos"
                            (itemChange)="changeBestOfFirstTo($event)"></app-list-switch>
                        <div
                            class="rounded bg-orange px-3 pb-[0.15rem] pt-[0.25rem] font-bebas-neue text-3xl !text-white cursor-pointer"
                            (click)="showGoalAmountDialog()">
                            {{ goalAmount }}
                        </div>
                        <app-list-switch
                            class="w-full"
                            [item]="setOrLeg"
                            [items]="setsOrLegs"
                            (itemChange)="changeSetOrLeg($event)"></app-list-switch>
                    </div>
                </div>

                <div class="mt-2 flex flex-col space-y-2" *ngIf="showTwoLegsDifference || showCutThroat">
                    <app-toggle-switch
                        class="block w-full"
                        [model]="twoLegsDifference"
                        (itemChange)="twoLegsDifference = !twoLegsDifference"
                        [translate]="'TWO_LEGS_DIFFERENCE'"
                        i18n="@@TWO_LEGS_DIFFERENCE"
                        *ngIf="showTwoLegsDifference">
                        Two legs difference
                    </app-toggle-switch>

                    <app-toggle-switch
                        class="block w-full"
                        [model]="suddenDeath"
                        (itemChange)="suddenDeath = !suddenDeath"
                        [translate]="'SUDDEN_DEATH'"
                        i18n="@@SUDDEN_DEATH"
                        *ngIf="showTwoLegsDifference && twoLegsDifference === true">
                        Sudden death
                    </app-toggle-switch>

                    <app-toggle-switch
                        class="block w-full"
                        [model]="cutThroat"
                        (itemChange)="toggleCutThroat()"
                        [translate]="'CUT_THROAT'"
                        i18n="@@CUT_THROAT">
                        Cut-throat
                    </app-toggle-switch>
                    <div
                        class="text-left text-xs text-neutral-600"
                        [translate]="'CUT_THROAT_EXPLANATION'"
                        i18n="@@CUT_THROAT_EXPLANATION">
                        With cut-throat mode on, the player with the least points will win. Give your opponents penalty
                        points.
                    </div>
                </div>
            </div>
        </div>

        <app-primary-button
            class="mt-4"
            size="normal"
            addedClasses="w-full"
            [translate]="'START_GAME'"
            i18n="@@START_GAME"
            (click)="startGame()">
            Start game
        </app-primary-button>
    </div>

    <app-bottom-navigation class="w-full" active="local"></app-bottom-navigation>
</div>
