/* eslint-disable max-len */
import { inject, Injectable } from '@angular/core';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { ProfileApiService } from '@dc-api/profile.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { DCLoadingService } from '@providers/DCLoadingService';
import {
    EditProfilePopoverComponent,
    EditProfilePopoverResponse,
} from 'src/app/modules/profile/components/edit-profile-photo-popover/edit-profile-photo-popover.component';
import { UserService } from 'src/app/modules/user/services/user.service';
import { CropProfilePhotoDialogComponent } from 'src/dialogs/crop-profile-photo/crop-profile-photo.dialog';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';
import { PopoverService } from './popover.service';

@Injectable()
export class ProfilePhotoService {
    private authService: AuthService = inject(AuthService);
    private platform: Platform = inject(Platform);
    private loadingService: DCLoadingService = inject(DCLoadingService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private modalController: ModalController = inject(ModalController);
    private profileApiService: ProfileApiService = inject(ProfileApiService);
    private userService: UserService = inject(UserService);
    private popoverController: PopoverController = inject(PopoverController);
    private popoverService: PopoverService = inject(PopoverService);

    async updateProfilePhoto(user: User, event?: any): Promise<void> {
        this.userService.checkSuspension('profile_editing', true).then(async (suspension) => {
            if (!suspension.suspended) {
                if (this.platform.is('capacitor')) {
                    try {
                        const checkCamPermissions = await Camera.checkPermissions();
                        if (checkCamPermissions.camera !== 'granted' || checkCamPermissions.photos !== 'granted') {
                            const requestCamResult = await Camera.requestPermissions();
                            if (requestCamResult.camera === 'granted' && requestCamResult.photos === 'granted') {
                                setTimeout(() => {
                                    this.updateProfilePhoto(user);
                                }, 500);
                                return;
                            } else {
                                throw 'No permission';
                            }
                        }
                    } catch (_) {
                        this.alertService.createAlert({
                            title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                            icon: 'error',
                            timer: 2000,
                            timerProgressBar: true,
                            showCloseButton: true,
                        });
                        return;
                    }
                }

                if (user.profile.profile_photo_path) {
                    this.popoverController
                        .create({
                            event,
                            component: EditProfilePopoverComponent,
                        })
                        .then((popover) => {
                            popover.present();
                            this.popoverService.init(popover);
                            popover.onDidDismiss().then((res) => {
                                this.popoverService.destroy();

                                const result = res.data as EditProfilePopoverResponse;
                                switch (result) {
                                    case 'edit':
                                        this.editPhoto(user);
                                        break;
                                    case 'delete':
                                        this.profileApiService.deleteProfilePhoto({}).then(() => {
                                            user.profile.profile_photo_path = null;
                                            user.profile.profile_photo_url = null;

                                            this.authService.user.profile.profile_photo_path = null;
                                            this.authService.user.profile.profile_photo_url = null;
                                        });
                                        break;
                                }
                            });
                        });
                } else {
                    await this.editPhoto(user);
                }
            }
        });
    }

    private async editPhoto(user: User): Promise<void> {
        await Camera.getPhoto({
            height: 500,
            width: 500,
            allowEditing: false,
            correctOrientation: true,
            quality: 90,
            resultType: CameraResultType.Base64,
            webUseInput: environment.isWeb,
        }).then((imageData: Photo) => {
            this.modalController
                .create({
                    component: CropProfilePhotoDialogComponent,
                    componentProps: {
                        image: imageData.base64String,
                        format: imageData.format,
                    },
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(async (dialogRes) => {
                        if (dialogRes.data) {
                            this.loadingService.ShowDefaultLoader();

                            await this.profileApiService
                                .uploadProfilePhoto({ encoded_profile_photo: dialogRes.data })
                                .then((res) => {
                                    user.profile.profile_photo_path = res.data.profile_photo_path;
                                    user.profile.profile_photo_url = dialogRes.data;

                                    this.authService.user.profile.profile_photo_path = res.data.profile_photo_path;
                                    this.authService.user.profile.profile_photo_url = dialogRes.data;

                                    this.loadingService.DismissLoader();
                                })
                                .catch((err) => {
                                    this.alertService.errorFromApi(err);
                                });
                        }
                    });
                });
        });
    }
}
