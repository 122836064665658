<div
    class="dc-ingame-animation"
    *ngIf="isVisible"
    [ngClass]="{ 'animate__animated animate__fadeOut': isHiding }"
    [style.top.px]="animationSettings?.top"
    [style.left.px]="animationSettings?.left">
    <div class="animation-content">
        <div class="message">
            <h1
                *ngIf="animationSettings.message"
                [ngClass]="[isHiding ? animationSettings.hideAnimation : animationSettings.showAnimation]">
                {{ animationSettings.message }}
            </h1>
        </div>
    </div>
</div>
