import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { components } from 'dc-core/dc-typings/dc-backend-definitions';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-profile-amounts-dialog',
    templateUrl: 'profile-amounts.dialog.html'
})
export class ProfileAmountsDialogComponent {
    scores: components['responses']['ScoresResponse']['content']['application/json'] = null;
    public scoresList = [
        { key: 'n180', from: 180, to: 180 },
        { key: 'p160', from: 160, to: 179 },
        { key: 'p140', from: 140, to: 159 },
        { key: 'p120', from: 120, to: 139 },
        { key: 'p100', from: 100, to: 119 },
        { key: 'p80', from: 80, to: 99 },
        { key: 'p60', from: 60, to: 79 },
        { key: 'p40', from: 40, to: 59 },
    ];

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private navParams: NavParams,
        public modal: ModalController,
        public route: ActivatedRoute
    ) {}

    ionViewWillEnter() {
        this.scores = this.navParams.get('scores');
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
