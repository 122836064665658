import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';

export const bobs27GameRoutes: Route[] = [
    {
        path: 'game/bobs-27',
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/bobs-27-local-game/bobs-27-local-game.component').then((m) => m.Bobs27LocalGameComponent),
    },
];
