import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileStatisticsResponse } from '@dc-core/dc-backend/dc-responses';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-profile-stats-dialog',
    templateUrl: 'profile-stats.dialog.html',
})
export class ProfileStatsDialogComponent {
    statistics: ProfileStatisticsResponse = null;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private navParams: NavParams,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute
    ) {}

    ionViewWillEnter() {
        this.statistics = this.navParams.get('statistics');
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
