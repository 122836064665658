<modal-content fullscreen="true" class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="transparent">
        @if (gameLabel) {
            @if (gameLabel.id) {
                <div title [translate]="'EDIT_GAME_LABEL'" i18n="@@EDIT_GAME_LABEL">Edit game label</div>
            } @else {
                <div title [translate]="'CREATE_GAME_LABEL'" i18n="@@CREATE_GAME_LABEL">Create game label</div>
            }
        }
        <app-icon icon="close" (click)="close()"></app-icon>
    </app-page-header>

    <ng-container *ngIf="gameLabel">
        <form class="crud-form" style="margin-top: 10px; margin-bottom: 20px">
            <ion-row>
                <ion-col>
                    <div class="head">
                        <span [translate]="'TITLE'" i18n="@@TITLE">Title</span>
                    </div>
                    <ion-input type="text" name="name" [(ngModel)]="gameLabel.title" required></ion-input>
                </ion-col>
            </ion-row>

            <ion-button
                style="margin-top: 30px; width: 100%"
                fill="outline"
                *ngIf="gameLabel.id"
                color="danger"
                (click)="delete()"
                [translate]="'DELETE'"
                i18n="@@DELETE">
                Delete
            </ion-button>
        </form>
    </ng-container>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA" style="position: relative" (click)="createOrUpdate()">
            <p class="text-center" style="height: 72px" [translate]="'SAVE'" i18n="@@SAVE" *ngIf="gameLabel.id">Save</p>
            <p class="text-center" style="height: 72px" [translate]="'CREATE'" i18n="@@CREATE" *ngIf="!gameLabel.id">
                Create
            </p>
        </div>
    </ion-toolbar>
</ion-footer>
