import { Component, OnInit } from '@angular/core';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { NavParams, PopoverController } from '@ionic/angular';

export type CAMERA_POPOVER_RESPONSE = 'activate_camera' | 'view_camera' | 'refresh_camera' | 'clip';

@Component({
    selector: 'app-camera-options-popover',
    templateUrl: './camera-options-popover.component.html',
    styleUrls: ['camera-options-popover.scss'],
})
export class CameraOptionsPopoverComponent implements OnInit {
    private userId: number;
    private currentUserId: number;

    public ownCamera = false;
    public hasStreams = false;

    constructor(
        private navParams: NavParams,
        private popover: PopoverController,
        private _ingameCameraService: InGameCameraService
    ) {}

    ngOnInit(): void {
        this.userId = this.navParams.get('userId');
        this.currentUserId = this.navParams.get('currentUserId');

        if (this.userId === this.currentUserId) {
            this.ownCamera = true;
        }

        if (this._ingameCameraService.userMediaMap.get(this.userId)?.videoStreams.hasStreams) {
            this.hasStreams = true;
        }
    }

    ClosePopover(res: CAMERA_POPOVER_RESPONSE = null) {
        this.popover.dismiss(res);
    }
}
