import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class PaymentApiService extends ApiService {
    // GET Requests
    public getPlans = super.getFetcher().path('/plans').method('get').create();

    // POST Requests
    public redeemCoupon = super.getFetcher().path('/coupons/redeem').method('post').create();
    public applySubscription = super.getFetcher().path('/subscriptions/apply').method('post').create();
    public paymentsPayPalValidateSubscription = super
        .getFetcher()
        .path('/payments/paypal/subscription/validate/{userId}')
        .method('get')
        .create();
    public paymentsAppleValidateSubscription = super
        .getFetcher()
        .path('/payments/apple/subscription/validate')
        .method('post')
        .create();
    public generateAppleDiscount = super
        .getFetcher()
        .path('/payments/apple/subscription/generate-discount')
        .method('post')
        .create();
    public paymentsGoogleValidateSubscription = super
        .getFetcher()
        .path('/payments/google/subscription/validate')
        .method('post')
        .create();
}
