<app-page-header mainNavigation="back" defaultBack="online-games">
    <div title [translate]="'FRIENDS_MODE'" i18n="@@FRIENDS_MODE">Friends lobby</div>
    <div right class="right-page-header">
        <app-control-camera-icon [refreshGame]="true"></app-control-camera-icon>
        @if (isWeb) {
            <app-fullscreen-icon></app-fullscreen-icon>
        }
    </div>
</app-page-header>

<div class="bg-white-pattern relative flex h-full flex-col" id="main-content">
    <div class="flex flex-auto flex-col overflow-auto overscroll-none landscape:px-safe">
        <div class="flex h-full flex-col items-center px-4 py-8 justify-between text-black" appSmallWebContainer>
            @if (online.totalFriends === null) {
                <div class="flex">
                    <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-orange"></app-icon>
                </div>
            }

            @if (online.totalFriends === 0) {
                <div class="space-y-2 px-4 h-full flex flex-col justify-center">
                    <div
                        class="text-center text-lg font-bold"
                        [translate]="'CONNTECT_WITH_OTHERS'"
                        i18n="@@CONNTECT_WITH_OTHERS">
                        Connect with others
                    </div>
                    <div
                        class="text-center text-sm font-semibold"
                        [translate]="'NO_FRIENDS_FOR_ONLINE'"
                        i18n="@@NO_FRIENDS_FOR_ONLINE">
                        Add friends and invite them for an online match!
                    </div>
                </div>
                <div class="w-full">
                    <app-primary-button
                        size="normal"
                        addedClasses="w-full"
                        (click)="openFriendSearch()"
                        [translate]="'ADD_FRIENDS'"
                        i18n="@@ADD_FRIENDS">
                        Add friends
                    </app-primary-button>
                </div>
            }

            @if (online.totalFriends > 0) {
                <div class="w-full mx-4">
                    @if (online.totalFriends) {
                        @if (online.user) {
                            <div class="mb-4 w-full">
                                <div
                                    class="flex justify-between items-center bg-panel px-4 py-2.5 font-bebas-neue text-xl text-black"
                                    (click)="toggleTab('online-friends', online.onlineFriends?.length || 0)"
                                    [class]="activeTab === 'online-friends' ? 'rounded-t-md' : 'rounded-md'">
                                    <div class="flex items-center space-x-1">
                                        <div [translate]="'ONLINE_FRIENDS'" i18n="@@ONLINE_FRIENDS">Online friends</div>
                                        <div class="text-lg">({{ online.onlineFriends?.length || 0 }})</div>
                                    </div>
                                    @if (online.onlineFriends?.length || 0 > 0) {
                                        <app-icon
                                            [icon]="
                                                activeTab === 'online-friends'
                                                    ? 'keyboard_arrow_up'
                                                    : 'keyboard_arrow_down'
                                            "
                                            size="large"
                                            class="text-neutral-400"></app-icon>
                                    }
                                </div>
                                @if (activeTab === 'online-friends') {
                                    <div class="px-4 py-3">
                                        @for (friend of online.onlineFriends; track friend) {
                                            <ng-container
                                                *ngTemplateOutlet="friendContainer; context: { friend }"></ng-container>
                                        }
                                    </div>
                                }
                            </div>
                            <div class="my-4 w-full">
                                <div
                                    class="flex justify-between items-center bg-panel px-4 py-2.5 font-bebas-neue text-xl text-black"
                                    (click)="toggleTab('in-game', online.inGameFriends?.length || 0)"
                                    [class]="activeTab === 'in-game' ? 'rounded-t-md' : 'rounded-md'">
                                    <div class="flex items-center space-x-1">
                                        <div [translate]="'IN_GAME'" i18n="@@IN_GAME">In-game</div>
                                        <div class="text-lg">({{ online.inGameFriends?.length || 0 }})</div>
                                    </div>
                                    @if (online.inGameFriends?.length || 0 > 0) {
                                        <app-icon
                                            [icon]="
                                                activeTab === 'in-game' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                            "
                                            size="large"
                                            class="text-neutral-400"></app-icon>
                                    }
                                </div>
                                @if (activeTab === 'in-game') {
                                    <div class="px-4 py-3">
                                        @for (friend of online.inGameFriends; track friend) {
                                            <ng-container
                                                *ngTemplateOutlet="friendContainer; context: { friend }"></ng-container>
                                        }
                                    </div>
                                }
                            </div>

                            <div class="my-4 w-full">
                                <div
                                    class="flex justify-between items-center bg-panel px-4 py-2.5 font-bebas-neue text-xl text-black"
                                    (click)="toggleTab('offline-friends', online.offlineFriends?.length || 0)"
                                    [class]="activeTab === 'offline-friends' ? 'rounded-t-md' : 'rounded-md'">
                                    <div class="flex items-center space-x-1">
                                        <div [translate]="'OFFLINE_FRIENDS'" i18n="@@OFFLINE_FRIENDS">
                                            Offline friends
                                        </div>
                                        <div class="text-lg">({{ online.offlineFriends?.length || 0 }})</div>
                                    </div>
                                    @if (online.offlineFriends?.length || 0 > 0) {
                                        <app-icon
                                            [icon]="
                                                activeTab === 'offline-friends'
                                                    ? 'keyboard_arrow_up'
                                                    : 'keyboard_arrow_down'
                                            "
                                            size="large"
                                            class="text-neutral-400"></app-icon>
                                    }
                                </div>
                                @if (activeTab === 'offline-friends') {
                                    <div class="px-4 py-3">
                                        @for (friend of online.offlineFriends; track friend) {
                                            <ng-container
                                                *ngTemplateOutlet="friendContainer; context: { friend }"></ng-container>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    }
                </div>
            }
        </div>
    </div>
    <div class="flex-none">
        <div class="landscape:px-safe" [class.pb-safe]="!auth.user?.is_ultimate">
            <app-online-saved-gameplay
                class="block w-full"
                [disable]="!!dcInvites.currentOutgoingInvite"></app-online-saved-gameplay>
        </div>

        @if (auth.user?.is_ultimate === false) {
            <app-games-trial-banner
                [applyBottomOffset]="true"
                [trialStatus]="{ games_played: freeMatchesService.gamesPlayed }">
            </app-games-trial-banner>
        }
    </div>
</div>

<ng-template #friendContainer let-friend="friend">
    <div class="flex justify-between items-center">
        <div class="cursor-pointer flex items-center justify-start space-x-2 mb-3" (click)="showPlayerInfo(friend)">
            <div class="relative">
                <app-profile-photo
                    class="h-fit"
                    [ultimate]="friend?.is_ultimate"
                    [badge]="friend?.profile?.badge"
                    [url]="friend?.profile?.profile_photo_url"
                    [size]="32">
                </app-profile-photo>
            </div>
            <div class="space-y-0.5 overflow-hidden">
                <div class="flex items-center space-x-1">
                    <div class="ucfirst truncate text-sm font-bold leading-none text-black">
                        {{ friend?.full_name || ('NO_NAME' | translate) }}
                    </div>
                    <img
                        class="h-2"
                        *ngIf="friend?.countryFlag"
                        src="assets/images/flags/rect/{{ friend?.countryFlag }}" />
                </div>
            </div>
        </div>
        @if (friend?.status === 'online') {
            @if (dcInvites.currentOutgoingInvite?.receiver_uid !== friend.uid) {
                <app-primary-button size="extrasmall" (click)="invite(friend)" [translate]="'INVITE'" i18n="@@INVITE">
                    Invite
                </app-primary-button>
            }
            @if (dcInvites.currentOutgoingInvite?.receiver_uid === friend.uid) {
                <div class="flex items-center space-x-2 cursor-pointer" (click)="cancelInvite()">
                    <div class="h-fit items-center rounded-sm bg-white px-2 py-1.5 text-black">
                        <div class="pt-0.5 font-bebas-neue text-xl leading-none">
                            {{ dcInvites.showTimer() }}
                        </div>
                    </div>
                    <app-primary-button size="extrasmall" color="red" [translate]="'CANCEL'" i18n="@@CANCEL">
                        Cancel
                    </app-primary-button>
                </div>
            }
        }
    </div>
</ng-template>
