import { NgModule } from '@angular/core';
import { IntervalManager } from './interval.manager';
import { RXJSSubscriptionManager } from './rxjs-subscription.manager';

@NgModule({
    providers: [
        RXJSSubscriptionManager,
        IntervalManager
    ],
    imports: [],
})
export class DartCounterCoreLoggingModule {}
