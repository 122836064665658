<modal-content fullscreen="true" class="bg-white-pattern text-black">
    <div class="result-header">
        <ion-icon name="close" class="visibility-hidden" (click)="dismissModal()"></ion-icon>
        <div class="title-container">
            <span class="title" [translate]="'RATE_PLAYER'" i18n="@@RATE_PLAYER">Rate this player</span>
        </div>
        <ion-icon name="close" (click)="dismissModal()"></ion-icon>
    </div>

    @if (user) {
        <div class="result-container one">
            <div class="cpt_stats_wrapper">
                <div class="stats_top one">
                    <div class="cpt_teamsection one">
                        <div class="cpt_players">
                            <app-profile-photo
                                style="height: fit-content; width: fit-content"
                                [size]="82"
                                [big]="true"
                                [badge]="user.profile?.badge"
                                [ultimate]="user.is_ultimate"
                                [url]="user.profile?.profile_photo_url">
                            </app-profile-photo>
                            <span class="playername truncate text-black">
                                {{ user.first_name || ('NO_NAME' | translate) }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="flex justify-center items-center space-x-4">
                    @for (star of [1, 2, 3, 4, 5]; track star) {
                        <app-icon
                            icon="star"
                            size="extralarge"
                            class="cursor-pointer"
                            [class]="star <= selectedStar ? 'text-orange' : 'text-neutral-300'"
                            (click)="selectStar(star)"></app-icon>
                    }
                </div>
            </div>
        </div>
    }

    <div class="result-footer">
        <app-primary-button
            class="w-full"
            color="blue"
            addedClasses="w-full"
            size="medium"
            [translate]="'SEND'"
            i18n="@@SEND"
            (click)="send()">
            Send
        </app-primary-button>
    </div>
</modal-content>
