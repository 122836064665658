import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CookieConsent from 'vanilla-cookieconsent';

type RecordCategory = 'functional' | 'analytics';

type CookieConsentRecords = {
    functional: boolean;
    analytics: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class CookieConsentService {
    public records: CookieConsentRecords = {
        functional: environment.isWeb ? false : true,
        analytics: environment.isWeb ? false : true,
    };

    public load(): void {
        if (!environment.isWeb) {
            return;
        }

        this.checkCookies();
    }

    public getConsent(category: RecordCategory): boolean {
        if (!environment.isWeb) {
            return true;
        }

        return this.records[category];
    }

    public checkCookies(): void {
        this.records.functional = CookieConsent.acceptedCategory('functional' as RecordCategory);
        this.records.analytics = CookieConsent.acceptedCategory('analytics' as RecordCategory);
    }

    public init(): void {
        if (!environment.isWeb) {
            return;
        }

        CookieConsent.run({
            onFirstConsent: () => {
                this.checkCookies();
            },

            onConsent: () => {
                this.checkCookies();
            },

            onChange: () => {
                this.checkCookies();
            },

            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true,
                },
                functional: {
                    enabled: true,
                },
                analytics: {
                    enabled: true,
                    autoClear: {
                        cookies: [
                            {
                                name: /^_ga/,
                            },
                        ],
                    },
                },
                tracking: {
                    enabled: true,
                },
            },

            language: {
                default: 'en',
                translations: {
                    en: {
                        consentModal: {
                            title: 'We use cookies',
                            description:
                                'Cookies are simple text files that are stored on your computer or mobile device by a website’s server. Each cookie is unique to your web browser. It contains anonymous information such as a unique identifier, the website’s domain name, and some digits and numbers.',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage Individual preferences',
                            footer: `
                          <a href="https://dartcounter.net/privacy-policy" target="_blank">Privacy Policy</a>
                      `,
                        },
                        preferencesModal: {
                            title: 'Manage cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Accept current selection',
                            closeIconLabel: 'Close modal',
                            serviceCounterLabel: 'Service|Services',
                            sections: [
                                {
                                    title: 'Your Privacy Choices',
                                    description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
                                },
                                {
                                    title: 'Strictly Necessary',
                                    description:
                                        'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                                    linkedCategory: 'necessary',
                                    cookieTable: {
                                        headers: {
                                            name: 'Name',
                                            domain: 'Service',
                                            description: 'Description',
                                            expiration: 'Expiration',
                                        },
                                        body: [
                                            {
                                                name: '__cfid',
                                                domain: 'CloudFlare',
                                                description:
                                                    'This cookie by Cloudflare ensures load balance across servers.',
                                                expiration: 'Expires after 1 day',
                                            },
                                            {
                                                name: '__cf_bm',
                                                domain: 'CloudFlare Bot Management',
                                                description:
                                                    'Enables support through Cloudflare Bot Management. It distinguishes between humans and bots, which helps in making valid reports on website usage.',
                                                expiration: 'Expires when browser is closed',
                                            },
                                            {
                                                name: '_cfuvid',
                                                domain: 'CloudFlare WAF',
                                                description:
                                                    'Allows Cloudflare WAF to distinguish individual users who share the same IP address. Blocking this cookie may prevent all users on the same IP address from viewing videos simultaneously.',
                                                expiration: 'Expires when browser is closed',
                                            },
                                        ],
                                    },
                                },
                                {
                                    title: 'Functionality',
                                    description:
                                        'Functionality cookies allow us to operate the site according to the choices you make. For example, these cookies enable us to recognise your username and remember your customizations during future visits.',
                                    linkedCategory: 'functional',
                                },
                                {
                                    title: 'Performance and Analytics',
                                    description:
                                        'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                    linkedCategory: 'analytics',
                                    cookieTable: {
                                        headers: {
                                            name: 'Name',
                                            domain: 'Service',
                                            description: 'Description',
                                            expiration: 'Expiration',
                                        },
                                        body: [
                                            {
                                                name: '_ga',
                                                domain: 'Google Analytics',
                                                description:
                                                    'Used by Google Analytics to distinguish unique visitors and calculate visitor, session, and campaign data. These cookies are not used to track users across unrelated websites.',
                                                expiration: 'Expires after 1 year, 1 month, 4 days',
                                            },
                                            {
                                                name: '_ga_*',
                                                domain: 'Google Analytics',
                                                description: 'Used by Google Analytics to calculate page views.',
                                                expiration: 'Expires after 1 year, 1 month, 4 days',
                                            },
                                        ],
                                    },
                                },
                                {
                                    title: 'More information',
                                    description:
                                        'For any queries in relation to the policy on cookies and your choices, please <a href="https://app.dartcounter.net/contact">contact us</a>',
                                },
                            ],
                        },
                    },
                },
            },
        });
    }
}
