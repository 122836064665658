/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProfilePhotoComponent } from '@components/profile-photo/profile-photo.component';
import { AddPlayerPayload, EditPlayerPayload, RemovePlayerPayload } from '@dc-core/dc-gamelogic/game-interfaces';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { User } from 'firebase/auth';
import { DragulaModule, DragulaOptions, DragulaService } from 'ng2-dragula';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';

@Component({
    selector: 'app-games-local-add-player',
    standalone: true,
    imports: [CommonModule, TranslateModule, IonicModule, DragulaModule, ProfilePhotoComponent, IconComponent],
    templateUrl: 'games-local-add-player.component.html',
})
export class GamesLocalAddPlayerComponent implements OnInit, OnDestroy {
    @Input() users: any;

    @Output() updatedUsersEvent = new EventEmitter();
    @Output() showPlayerOptionsEvent = new EventEmitter<EditPlayerPayload>();
    @Output() addPlayerEvent = new EventEmitter<AddPlayerPayload>();
    @Output() removePlayerEvent = new EventEmitter<RemovePlayerPayload>();

    public authService: AuthService = inject(AuthService);
    private dragulaService: DragulaService = inject(DragulaService);

    constructor() {}

    public ngOnInit(): void {
        this.dragulaService.createGroup('PLAYERS', {
            revertOnSpill: true,
            accepts: (_el) => {
                const newIndex = [].slice.call(_el.parentElement.children).indexOf(_el);
                const allowedIndex = newIndex < this.users.length;

                const draggable = !_el.className.includes('no-drag');

                return draggable && allowedIndex;
            },
        } as DragulaOptions);

        this.dragulaService.dragend().subscribe(() => {
            this.updatedUsersEvent.emit(this.users);
        });
    }

    public ngOnDestroy(): void {
        this.dragulaService.destroy('PLAYERS');
    }

    public humanUsers(): any[] {
        return this.users.filter((user) => !user.is_cpu);
    }

    public showPlayerOptions(user: User, userIndex: number): void {
        this.showPlayerOptionsEvent.emit({ teamIndex: null, userIndex, user });
    }

    public addPlayer(user?: User, team?: { users: User[] }): void {
        this.addPlayerEvent.emit({ user, team });
    }

    public removePlayer(userIndex: number, teamIndex: number = null): void {
        this.removePlayerEvent.emit({ userIndex, teamIndex });
    }
}
