<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'EMAIL_PREFERENCES'" i18n="@@EMAIL_PREFERENCES">
            Email preferences
        </div>
    </div>
    <div class="slide-modal-content">
        @if (auth.user.email) {
            <app-checkbox
                class="block"
                [model]="newsletterOptIn"
                color="white"
                (itemChange)="newsletterOptIn = !newsletterOptIn"
                class="mb-4">
                <div class="flex text-black" [translate]="'NEWSLETTER_OPT_IN_INFO'" i18n="@@NEWSLETTER_OPT_IN_INFO">
                    Yes, I want to hear about exclusive offers, announcements and the newest products from Target
                    DartCounter, as well as those tailored to my account and activity.
                </div>
            </app-checkbox>
        }
        <app-primary-button
            size="normal"
            addedClasses="w-full"
            color="orange"
            (click)="save()"
            [translate]="'SAVE'"
            i18n="@@SAVE">
            Save
        </app-primary-button>
    </div>
</div>
