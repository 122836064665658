import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * ANDROID** - Privacy setting for how the notification should be shown on the lockscreen of Android 5+ devices.
 */
export var OSLockScreenVisibility;
(function (OSLockScreenVisibility) {
  /**
   * Fully visible (default)
   */
  OSLockScreenVisibility[OSLockScreenVisibility["Public"] = 1] = "Public";
  /**
   * Contents are hidden
   */
  OSLockScreenVisibility[OSLockScreenVisibility["Private"] = 0] = "Private";
  /**
   * Not shown
   */
  OSLockScreenVisibility[OSLockScreenVisibility["Secret"] = -1] = "Secret";
})(OSLockScreenVisibility || (OSLockScreenVisibility = {}));
/**
 * How the notification was displayed to the user. Part of OSNotification. See inFocusDisplaying for more information on how this is used.
 */
export var OSDisplayType;
(function (OSDisplayType) {
  /**
   * notification is silent, or inFocusDisplaying is disabled.
   */
  OSDisplayType[OSDisplayType["None"] = 0] = "None";
  /**
   * (**DEFAULT**) - native alert dialog display.
   */
  OSDisplayType[OSDisplayType["InAppAlert"] = 1] = "InAppAlert";
  /**
   * native notification display.
   */
  OSDisplayType[OSDisplayType["Notification"] = 2] = "Notification";
})(OSDisplayType || (OSDisplayType = {}));
export var OSActionType;
(function (OSActionType) {
  OSActionType[OSActionType["Opened"] = 0] = "Opened";
  OSActionType[OSActionType["ActionTake"] = 1] = "ActionTake";
})(OSActionType || (OSActionType = {}));
var OneSignal = /** @class */function (_super) {
  __extends(OneSignal, _super);
  function OneSignal() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /**
     * constants to use in inFocusDisplaying()
     *
     * @hidden
     */
    _this.OSInFocusDisplayOption = {
      None: 0,
      InAppAlert: 1,
      Notification: 2
    };
    return _this;
  }
  OneSignal.prototype.startInit = function (appId, googleProjectNumber) {
    return cordova(this, "startInit", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.handleNotificationReceived = function () {
    return cordova(this, "handleNotificationReceived", {
      "observable": true
    }, arguments);
  };
  OneSignal.prototype.handleNotificationOpened = function () {
    return cordova(this, "handleNotificationOpened", {
      "observable": true
    }, arguments);
  };
  OneSignal.prototype.handleInAppMessageClicked = function () {
    return cordova(this, "handleInAppMessageClicked", {
      "observable": true
    }, arguments);
  };
  OneSignal.prototype.iOSSettings = function (settings) {
    return cordova(this, "iOSSettings", {
      "sync": true,
      "platforms": ["iOS"]
    }, arguments);
  };
  OneSignal.prototype.endInit = function () {
    return cordova(this, "endInit", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.promptForPushNotificationsWithUserResponse = function () {
    return cordova(this, "promptForPushNotificationsWithUserResponse", {
      "platforms": ["iOS"]
    }, arguments);
  };
  OneSignal.prototype.getTags = function () {
    return cordova(this, "getTags", {}, arguments);
  };
  OneSignal.prototype.getIds = function () {
    return cordova(this, "getIds", {}, arguments);
  };
  OneSignal.prototype.sendTag = function (key, value) {
    return cordova(this, "sendTag", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.sendTags = function (json) {
    return cordova(this, "sendTags", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.deleteTag = function (key) {
    return cordova(this, "deleteTag", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.deleteTags = function (keys) {
    return cordova(this, "deleteTags", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.registerForPushNotifications = function () {
    return cordova(this, "registerForPushNotifications", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.enableVibrate = function (enable) {
    return cordova(this, "enableVibrate", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.enableSound = function (enable) {
    return cordova(this, "enableSound", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.inFocusDisplaying = function (displayOption) {
    return cordova(this, "inFocusDisplaying", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.setSubscription = function (enable) {
    return cordova(this, "setSubscription", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.getPermissionSubscriptionState = function () {
    return cordova(this, "getPermissionSubscriptionState", {}, arguments);
  };
  OneSignal.prototype.postNotification = function (notificationObj) {
    return cordova(this, "postNotification", {}, arguments);
  };
  OneSignal.prototype.cancelNotification = function (notificationId) {
    return cordova(this, "cancelNotification", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.promptLocation = function () {
    return cordova(this, "promptLocation", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.syncHashedEmail = function (email) {
    return cordova(this, "syncHashedEmail", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.setLogLevel = function (logLevel) {
    return cordova(this, "setLogLevel", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.setLocationShared = function (shared) {
    return cordova(this, "setLocationShared", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.addPermissionObserver = function () {
    return cordova(this, "addPermissionObserver", {
      "observable": true
    }, arguments);
  };
  OneSignal.prototype.addSubscriptionObserver = function () {
    return cordova(this, "addSubscriptionObserver", {
      "observable": true
    }, arguments);
  };
  OneSignal.prototype.setEmail = function (email, emailAuthToken) {
    return cordova(this, "setEmail", {}, arguments);
  };
  OneSignal.prototype.logoutEmail = function () {
    return cordova(this, "logoutEmail", {}, arguments);
  };
  OneSignal.prototype.addEmailSubscriptionObserver = function () {
    return cordova(this, "addEmailSubscriptionObserver", {
      "observable": true
    }, arguments);
  };
  OneSignal.prototype.clearOneSignalNotifications = function () {
    return cordova(this, "clearOneSignalNotifications", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.setRequiresUserPrivacyConsent = function (required) {
    return cordova(this, "setRequiresUserPrivacyConsent", {}, arguments);
  };
  OneSignal.prototype.provideUserConsent = function (granted) {
    return cordova(this, "provideUserConsent", {}, arguments);
  };
  OneSignal.prototype.userProvidedPrivacyConsent = function (callback) {
    return cordova(this, "userProvidedPrivacyConsent", {}, arguments);
  };
  OneSignal.prototype.setExternalUserId = function (externalId) {
    return cordova(this, "setExternalUserId", {}, arguments);
  };
  OneSignal.prototype.removeExternalUserId = function () {
    return cordova(this, "removeExternalUserId", {}, arguments);
  };
  OneSignal.prototype.addTrigger = function (key, value) {
    return cordova(this, "addTrigger", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.addTriggers = function (triggers) {
    return cordova(this, "addTriggers", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.removeTriggerForKey = function (key) {
    return cordova(this, "removeTriggerForKey", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.removeTriggersForKeys = function (keys) {
    return cordova(this, "removeTriggersForKeys", {
      "sync": true
    }, arguments);
  };
  OneSignal.prototype.getTriggerValueForKey = function (key) {
    return cordova(this, "getTriggerValueForKey", {}, arguments);
  };
  OneSignal.prototype.pauseInAppMessages = function (pause) {
    return cordova(this, "pauseInAppMessages", {
      "sync": true
    }, arguments);
  };
  OneSignal.ɵfac = /* @__PURE__ */(() => {
    let ɵOneSignal_BaseFactory;
    return function OneSignal_Factory(__ngFactoryType__) {
      return (ɵOneSignal_BaseFactory || (ɵOneSignal_BaseFactory = i0.ɵɵgetInheritedFactory(OneSignal)))(__ngFactoryType__ || OneSignal);
    };
  })();
  OneSignal.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: OneSignal,
    factory: OneSignal.ɵfac
  });
  OneSignal.pluginName = "OneSignal";
  OneSignal.plugin = "onesignal-cordova-plugin";
  OneSignal.pluginRef = "plugins.OneSignal";
  OneSignal.repo = "https://github.com/OneSignal/OneSignal-Cordova-SDK";
  OneSignal.platforms = ["Amazon Fire OS", "Android", "iOS", "Windows"];
  OneSignal = __decorate([], OneSignal);
  return OneSignal;
}(AwesomeCordovaNativePlugin);
export { OneSignal };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OneSignal, [{
    type: Injectable
  }], null, {
    startInit: [],
    handleNotificationReceived: [],
    handleNotificationOpened: [],
    handleInAppMessageClicked: [],
    iOSSettings: [],
    endInit: [],
    promptForPushNotificationsWithUserResponse: [],
    getTags: [],
    getIds: [],
    sendTag: [],
    sendTags: [],
    deleteTag: [],
    deleteTags: [],
    registerForPushNotifications: [],
    enableVibrate: [],
    enableSound: [],
    inFocusDisplaying: [],
    setSubscription: [],
    getPermissionSubscriptionState: [],
    postNotification: [],
    cancelNotification: [],
    promptLocation: [],
    syncHashedEmail: [],
    setLogLevel: [],
    setLocationShared: [],
    addPermissionObserver: [],
    addSubscriptionObserver: [],
    setEmail: [],
    logoutEmail: [],
    addEmailSubscriptionObserver: [],
    clearOneSignalNotifications: [],
    setRequiresUserPrivacyConsent: [],
    provideUserConsent: [],
    userProvidedPrivacyConsent: [],
    setExternalUserId: [],
    removeExternalUserId: [],
    addTrigger: [],
    addTriggers: [],
    removeTriggerForKey: [],
    removeTriggersForKeys: [],
    getTriggerValueForKey: [],
    pauseInAppMessages: []
  });
})();
