import { Injectable } from '@angular/core';
import {
    addDoc,
    collection,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentData,
    DocumentReference,
    Firestore,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
} from '@angular/fire/firestore';
import { OnlineGameplay } from '../../dc-backend/dc-interfaces';
import _ from 'lodash';
import { first, Observable, Subject, Subscription } from 'rxjs';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { FSScoringEvent, FIRESTORE_COLLECTION } from '../globals/firestore.tables';

@Injectable()
export class GameEventsCollectionService extends FireStoreCollectionsService {
    private defaultClass = new FSScoringEvent();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.GAME_EVENTS;
    private firestore_collection: CollectionReference<FSScoringEvent>;
    private _activeGameRef: DocumentReference<OnlineGameplay> = null;
    public onlineGameEvent$: Subject<FSScoringEvent> = new Subject<FSScoringEvent>();
    private _gameEventSubscription?: Subscription;

    constructor(private firestore: Firestore) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<FSScoringEvent>(this.collection_name);
    }

    enableGameEvents(gameDocRef: DocumentReference<OnlineGameplay>, isSpectating: boolean) {
        this.disableGameEvents();
        this._activeGameRef = gameDocRef;

        // Fetch initial events and then listen for new events
        this.listenForInitialEvents()
            .pipe(first())
            .subscribe({
                next: (total: number) => {
                    this._gameEventSubscription = this.listenForNewEvents(total).subscribe({
                        next: (gameEvent: FSScoringEvent) => {
                            if (gameEvent) {
                                this.onlineGameEvent$.next(gameEvent);
                            }
                        },
                        error: (err) => {
                            // Handle error
                            console.error('Error listening for new game events:', err);
                        },
                    });
                },
                error: (err) => {
                    // Handle error
                    console.error('Error fetching initial game events:', err);
                },
            });
    }

    listenForInitialEvents(): Observable<number> {
        const collectionRef = collection(this._activeGameRef, this.collection_name);

        return new Observable<number>((observer) => {
            try {
                const initialQuery = query(collectionRef, orderBy('sent_at'));

                getDocs(initialQuery)
                    .then((snapshot) => {
                        observer.next(snapshot.size);
                        observer.complete();
                    })
                    .catch((err) => {
                        observer.error(err);
                    });
            } catch (err) {
                observer.error(err);
            }
        });
    }

    listenForNewEvents(total: number): Observable<FSScoringEvent> {
        const collectionRef = collection(this._activeGameRef, this.collection_name);

        return new Observable<FSScoringEvent>((observer) => {
            try {
                const newEventsQuery = query(collectionRef, orderBy('sent_at'));

                const unsubscribe = onSnapshot(newEventsQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === 'added' && snapshot.size > total) {
                            const data = change.doc.data() as FSScoringEvent;
                            observer.next(data);
                        }
                    });
                });

                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    disableGameEvents() {
        // If there's an active subscription, unsubscribe from it
        if (this._gameEventSubscription) {
            this._gameEventSubscription.unsubscribe();
            this._gameEventSubscription = undefined; // Clear the reference
        }
    }

    addItem(newDoc: FSScoringEvent): Promise<DocumentReference<DocumentData>> {
        const gameEventsCollectionRef = collection(this._activeGameRef, this.collection_name);
        return addDoc(gameEventsCollectionRef, newDoc);
    }

    getDocByID(id: string): DocumentReference<DocumentData> {
        const gameEventsCollectionRef = collection(this._activeGameRef, this.collection_name);
        return doc(gameEventsCollectionRef, id);
    }

    watchDoc(id: string, observerFn) {
        let docRef = this.getDocByID(id);
        return onSnapshot(docRef, observerFn);
    }

    removeItem(docId): void {
        deleteDoc(this.getDocByID(docId));
    }

    updateItem(ref: DocumentReference<DocumentData>, updatedValues: DocumentData): Promise<void> {
        return updateDoc(ref, updatedValues);
    }

    // async updateAction(doc: DCFireStoreAction) {
    //     // Update only the players + status
    //     const fsGameplay = <DCFireStoreAction>{
    //         completed: doc.completed,
    //     };

    //     let docRef = await this.getDocByID(doc.doc_id);
    //     this.updateItem(docRef, fsGameplay);
    // }
}
