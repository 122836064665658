import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SUBSCRIPTION_KEY } from './subscription_enums';

interface ActiveSubscription {
    subscription: Subscription | null;
    observable: Observable<any> | null;
    component: string;
}

@Injectable()
export class RXJSSubscriptionManager {
    private activeSubscriptions: { [key: string]: ActiveSubscription } = {};

    addSubscription(component: string, key: SUBSCRIPTION_KEY | string, item: Subscription): void {
        if (this.activeSubscriptions[key]) {
            if (environment.debug) {
                console.log(key + ' already existed, removing old one');
            }

            // If a subscription with the same key is already active, unsubscribe before subscribing again
            this.cleanSubscription(key);
        }

        this.activeSubscriptions[key] = {
            subscription: item,
            observable: null,
            component,
        };
    }

    cleanSubscription(key: SUBSCRIPTION_KEY | string): void {
        if (this.activeSubscriptions[key]) {
            const activeSubscription = this.activeSubscriptions[key];
            activeSubscription.subscription.unsubscribe();

            delete this.activeSubscriptions[key];
        }
    }

    getActiveSubscriptions(): { key: string; component: string }[] {
        return Object.keys(this.activeSubscriptions).map((key) => {
            const activeSubscription = this.activeSubscriptions[key];
            return { key, component: activeSubscription.component };
        });
    }
}
