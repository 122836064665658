<ion-content fullscreen class="bg-white-pattern text-black">
    <div
        class="px-0 py-4 m-0 h-full flex flex-col justify-center items-center text-center"
        *ngIf="downtimesCollectionService.currentDowntime">
        <img class="maintenance-icon" src="assets/icon/maintenance_icon.svg" />
        <h1
            class="uppercase font-black font-bebas-neue"
            style="font-size: 4em; margin: 2rem 0 1rem"
            [translate]="'MAINTENANCE'"
            i18n="@@MAINTENANCE">
            Maintenance
        </h1>

        <span *ngIf="downtimesCollectionService.currentDowntime.from">
            <span [translate]="'FROM'" i18n="@@FROM">From</span>:
            {{
                downtimesCollectionService.currentDowntime.from.toDate()
                    | date: 'dd MMM yyyy HH:mm' : '' : translateService.currentLang
            }}
        </span>
        <span *ngIf="downtimesCollectionService.currentDowntime.until">
            <span [translate]="'UNTIL'" i18n="@@UNTIL">Until</span>:
            {{
                downtimesCollectionService.currentDowntime.until.toDate()
                    | date: 'dd MMM yyyy HH:mm' : '' : translateService.currentLang
            }}
        </span>
        <span
            style="font-size: 0.9em; margin-top: 0.5rem"
            *ngIf="downtimesCollectionService.currentDowntime.estimatedMinutes">
            <span [translate]="'ESTIMATED_TIME'" i18n="@@ESTIMATED_TIME">Estimated time</span>:
            {{ downtimesCollectionService.currentDowntime.estimatedMinutes }}
            <span style="text-transform: lowercase" [translate]="'MINUTES'" i18n="@@MINUTES">Minutes</span>
        </span>
    </div>
</ion-content>
