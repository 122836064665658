import { Component } from '@angular/core';
import { SpectatorsCollectionService } from '@dc-core/dc-firestore/collection-helpers/spectators.collection.service';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-spectators-dialog',
    templateUrl: 'spectators.dialog.html',
})
export class SpectatorsDialogComponent {
    constructor(
        public view: ModalController,
        public _view: ModalController,
        public auth: AuthService,
        public spectatorsCollectionService: SpectatorsCollectionService
    ) {}

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
