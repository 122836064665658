<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'FOLLOW_US'" i18n="@@FOLLOW_US">Follow us</div>
    </div>
    <div class="slide-modal-content text-black">
        <div class="flex justify-between mx-8">
            <div
                class="flex items-center justify-center rounded-md w-12 h-12 text-3xl !text-white bg-[#25d366]"
                (click)="openUrl('https://whatsapp.com/channel/0029VagW2WQ4NVihgmE93e1I')">
                <ion-icon name="logo-whatsapp"></ion-icon>
            </div>
            <div class="flex items-center justify-center rounded-md w-12 h-12 text-3xl !text-white bg-[#1877f2]">
                <ion-icon
                    name="logo-facebook"
                    (click)="openUrl('https://www.facebook.com/targetdartcounter/')"></ion-icon>
            </div>
            <div class="flex items-center justify-center rounded-md w-12 h-12 text-3xl !text-white bg-[#c32aa3]">
                <ion-icon name="logo-instagram" (click)="openUrl('https://www.instagram.com/dartcounter/')"></ion-icon>
            </div>
            <div class="flex items-center justify-center rounded-md w-12 h-12 text-3xl !text-white bg-[#ff0000]">
                <ion-icon name="logo-youtube" (click)="openUrl('https://www.youtube.com/@DartCounter')"></ion-icon>
            </div>
            <div class="flex items-center justify-center rounded-md w-12 h-12 text-3xl !text-white !bg-black-full">
                <ion-icon name="logo-tiktok" (click)="openUrl('https://www.tiktok.com/@dartcounter')"></ion-icon>
            </div>
        </div>
    </div>
</div>
