import { inject, Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { environment } from 'src/environments/environment';
import { LocalStorageKey } from '../../dc-localstorage';
import { DartCounterMatchSpeechCommands } from './commands/match.speech-commands';
import { generateDutchToNumber, generateEnglishToNumber, generateGermanToNumber } from './lang-numbers';
import { NumbersGrammarDE, NumbersGrammarEN, NumbersGrammarNL } from './numbers-grammar';
import { CommandGame, CommandOption, CommandType } from './speech-commands';

export type RecognitionLang = 'en-US' | 'nl-NL' | 'de-DE';

export interface SearchCommand {
    matchedCommand: SpeechCommand;
    commandWithLanguage: CommandWithLanguage;
    regexCommand: RegExp;
}

export interface ActionCommand {
    command: string;
    regexCommand: RegExp;
    commandWithLanguage: CommandWithLanguage;
}

export interface ProcessedSpeech {
    matchedCommand: SpeechCommand;
    attributes: any[];
    found: number;
}

export interface SpeechCommand {
    id?: number;
    type: CommandType;
    option?: CommandOption;
    game?: CommandGame;
    commands: CommandWithLanguage[];
    action: (lang: RecognitionLang, attributes: string[]) => number | null; // Returns if action is successful
}

export interface SpeechCommandInfo {
    title: string;
    type: CommandType;
    game?: CommandGame;
    hasOptions: boolean;
    list: SpeechCommandInfoList[];
}

export interface SpeechCommandInfoList {
    option?: CommandOption | RecognitionLang;
    parts: SpeechCommandInfoPart[];
}

export interface SpeechCommandInfoPart {
    languages: RecognitionLang[];
    texts: SpeechCommandInfoText[];
}

export interface SpeechCommandSubject {
    type: CommandType;
    attributes: any[];
}

export interface SpeechCommandInfoText {
    text: string;
    type: 'command' | 'input' | 'text' | 'lang';
    flag?: string;
}

export interface CommandWithLanguage {
    list: RegExp[];
    languages: RecognitionLang[];
    attributes: number[];
}

export class DartCounterSpeechAction {}

export interface CommandOptions {
    language?: RecognitionLang;
    score?: CommandOption;
}

@Injectable()
export class DartCounterSpeechToScoreService {
    public recognition: any;

    public recognizing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public playingAudio$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public game: CommandGame;
    public lang: RecognitionLang;
    public langNumbers = {};
    public commandInfo: SpeechCommandInfo[] = [];
    public commandOptions: CommandOptions = {};
    public speechCommands: SpeechCommand[] = [];
    public processedSpeech$: Subject<SpeechCommandSubject> = new Subject<SpeechCommandSubject>();

    private isStarting = false;
    private recognizing = false;
    private processing = false;
    private playingAudio = false;
    private mainSpeechCommands: SpeechCommand[] = [];
    private lastInterimCommand: ProcessedSpeech = null;
    private foundTimeout;
    private restartAfter: number = null;
    private restartInterval: ReturnType<typeof setInterval>;

    private customGrammar: boolean;

    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    constructor() {}

    loadRecognition(): void {
        this.log('EVENT', 'loading');

        let commandOptionsFromStorage = localStorage.getItem(LocalStorageKey.speechToScoreOptions);
        if (commandOptionsFromStorage) {
            this.commandOptions = JSON.parse(commandOptionsFromStorage);
        }

        this.commandOptions.language = 'en-US';
        this.commandOptions.score = this.commandOptions.score ?? CommandOption.SCORE;

        this.recognition = new ((window as any).SpeechRecognition ||
            (window as any).webkitSpeechRecognition ||
            (window as any).mozSpeechRecognition ||
            (window as any).oSpeechRecognition ||
            (window as any).msSpeechRecognition)();

        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        if (
            'SpeechGrammarList' in window ||
            'webkitSpeechGrammarList' in window ||
            'mozSpeechGrammarList' in window ||
            'oSpeechGrammarList' in window ||
            'msSpeechGrammarList' in window
        ) {
            this.customGrammar = true;
        } else {
            this.customGrammar = false;
        }

        this.recognition.onstart = () => {
            this.log('EVENT', 'onstart');

            this.isStarting = false;

            this.setRecognizing(true);
            this.setProcessing(false);
        };

        this.recognition.onaudiostart = () => {
            this.log('EVENT', 'onaudiostart');
        };

        this.recognition.onaudioend = () => {
            this.log('EVENT', 'onaudioend');
        };

        this.recognition.onsoundstart = () => {
            this.log('EVENT', 'onsoundstart');
        };

        this.recognition.onsoundend = () => {
            this.log('EVENT', 'onsoundend');
        };

        this.recognition.onspeechstart = () => {
            this.log('EVENT', 'onspeechstart');
        };

        this.recognition.onspeechend = () => {
            this.log('EVENT', 'onspeechend');
        };

        this.recognition.onend = () => {
            this.log('EVENT', 'onend');

            this.isStarting = false;

            this.setRecognizing(false);
            this.setProcessing(false);

            if (this.restartAfter !== null) {
                setTimeout(() => {
                    try {
                        this.recognition.start();
                    } catch (err) {
                        this.log(err);
                    }
                }, this.restartAfter);
            }

            if (this.restartInterval) {
                clearInterval(this.restartInterval);
            }
        };

        this.recognition.onresult = (event) => {
            if (this.restartInterval) {
                clearInterval(this.restartInterval);
            }

            this.log('ONRESULT', event, this.recognizing, this.processing, !this.playingAudio);

            if (this.recognizing && !this.processing && !this.playingAudio) {
                let finalTranscript = '';
                let interimTranscript = '';

                for (let i = event.resultIndex; i < event.results.length; i++) {
                    const result = event.results[i][0].transcript;
                    if (event.results[i].isFinal) {
                        finalTranscript += result;
                    } else {
                        interimTranscript += result;
                    }
                }

                this.log('Interim transcript:', interimTranscript);
                this.log('Final transcript:', finalTranscript);

                if (finalTranscript) {
                    const processedSpeech: ProcessedSpeech = this.processSpeech(finalTranscript);
                    if (processedSpeech) {
                        this.log('FOUND COMMAND - Final', processedSpeech);
                        this.action(processedSpeech.matchedCommand, processedSpeech.attributes);
                    }
                } else if (interimTranscript) {
                    const processedSpeech: ProcessedSpeech = this.processSpeech(interimTranscript);
                    if (processedSpeech) {
                        if (!this.lastInterimCommand) {
                            this.lastInterimCommand = processedSpeech;
                            this.setInterimTimeout();
                        } else {
                            if (
                                this.lastInterimCommand.matchedCommand.id == processedSpeech.matchedCommand.id &&
                                this.lastInterimCommand.attributes == processedSpeech.attributes
                            ) {
                                this.lastInterimCommand.found++;

                                if (this.lastInterimCommand.found >= 2) {
                                    this.clearInterimTimeout();
                                    this.log('FOUND COMMAND - Interim', processedSpeech);
                                    this.action(processedSpeech.matchedCommand, processedSpeech.attributes);
                                }
                            } else {
                                this.clearInterimTimeout();
                                this.lastInterimCommand = processedSpeech;
                                this.setInterimTimeout();
                            }
                        }
                    }
                }
            }
        };

        this.recognition.onerror = (event) => {
            this.log('Speech recognition error', event.error);

            switch (event.error) {
                case 'audio-capture':
                case 'not-allowed':
                    if (this.restartAfter === null) {
                        this.restart();
                        this.restartInterval = setInterval(() => {
                            this.restart();
                        }, 5000);
                    }
                    break;
            }
        };

        this.log('RECOGNITION', this.recognition);

        this.log('EVENT', 'loaded');
    }

    private setInterimTimeout(): void {
        this.clearInterimTimeout();

        this.foundTimeout = setTimeout(() => {
            if (this.lastInterimCommand) {
                this.log('FOUND COMMAND - Timeout', this.lastInterimCommand);
                this.action(this.lastInterimCommand.matchedCommand, this.lastInterimCommand.attributes);
            }
            this.foundTimeout = null;
        }, 750);
    }

    private clearInterimTimeout(): void {
        if (this.foundTimeout) {
            clearTimeout(this.foundTimeout);
        }
        this.foundTimeout = null;
    }

    private processSpeech(transcript: string): ProcessedSpeech {
        const command = transcript.toLowerCase();
        const searchedCommand: SearchCommand = this.searchForCommand(command);

        if (searchedCommand) {
            let attributes = this.getAttributes({
                command,
                regexCommand: searchedCommand.regexCommand,
                commandWithLanguage: searchedCommand.commandWithLanguage,
            });
            return { matchedCommand: searchedCommand.matchedCommand, attributes, found: 0 } as ProcessedSpeech;
        } else {
            this.log('ERROR', 'Command not recognized');

            return null;
        }
    }

    private searchForCommand(command: string): SearchCommand {
        for (let vc of this.speechCommands) {
            for (let c of vc.commands) {
                for (let cl of c.list) {
                    if (cl.test(command) && c.languages.includes(this.lang)) {
                        return { matchedCommand: vc, commandWithLanguage: c, regexCommand: cl };
                    }
                }
            }
        }
    }

    getAttributes(actionCommand: ActionCommand): any[] {
        const attributes = this.extractAttributes(actionCommand.command, actionCommand.regexCommand);
        const finalAttributes = [];
        actionCommand.commandWithLanguage.attributes.forEach((attr) => {
            attributes.forEach((foundAttr, index) => {
                if (attr == index) {
                    finalAttributes.push(foundAttr);
                }
            });
        });
        return finalAttributes;
    }

    action(matchedCommand: SpeechCommand, finalAttributes: any[]): void {
        this.setProcessing(true);

        if (this.foundTimeout) {
            clearTimeout(this.foundTimeout);
        }

        this.lastInterimCommand = null;
        matchedCommand.action(this.lang, finalAttributes);

        setTimeout(() => {
            this.setProcessing(false);
        }, 500);
    }

    private extractAttributes(command: string, regex: RegExp): string[] {
        const matches = command.match(regex);
        if (matches && matches.length > 1) {
            const attributes = matches.slice(1).map((attribute) => attribute.trim());
            return attributes;
        }
        return [];
    }

    init(game: CommandGame, language: string): void {
        this.loadRecognition();

        this.game = game;
        this.setLangFromLocale(language);

        this.loadCommandInfo();
    }

    loadCommandInfo(): void {
        let commandInfo: SpeechCommandInfo[] = [];
        // defaultCommandInfoList.forEach((defaultCommandInfo) => {
        //     commandInfo.push(defaultCommandInfo);
        // });

        switch (this.game) {
            case CommandGame.MATCH:
            case CommandGame.ONLINE_MATCH:
                new DartCounterMatchSpeechCommands(this.langNumbers).info().forEach((info) => {
                    commandInfo.push(info);
                });
                break;
        }

        commandInfo = commandInfo.filter((info) => !info.game || info.game === this.game);
        commandInfo.forEach((info) => {
            info.list.forEach((list) => {
                list.parts = list.parts.filter((part) => part.languages.includes(this.lang));
            });
        });

        this.commandInfo = commandInfo;
    }

    saveOptions(): void {
        localStorage.setItem(LocalStorageKey.speechToScoreOptions, JSON.stringify(this.commandOptions));
    }

    changeOption(type: CommandType, option: CommandOption): void {
        this.commandOptions[type.toString()] = option;
        this.saveOptions();

        if (type === CommandType.LANGUAGE) {
            this.setLang(this.commandOptions.language);
            this.loadCommandInfo();
        }

        this.refreshCommands();
    }

    setLangFromLocale(language: string): void {
        let lang: RecognitionLang = 'en-US';
        switch (language) {
            // case 'nl':
            //     lang = 'nl-NL';
            //     break;
            // case 'de':
            //     lang = 'de-DE';
            //     break;
            default:
                lang = 'en-US';
                break;
        }
        this.setLang(lang);
    }

    setLang(lang: RecognitionLang): void {
        if (!this.commandOptions.language) {
            this.commandOptions.language = lang;
            this.saveOptions();
        }

        this.recognition.lang = this.commandOptions.language;
        this.lang = this.commandOptions.language;

        let grammarList = null;
        if (this.customGrammar) {
            grammarList = new ((window as any).SpeechGrammarList ||
                (window as any).webkitSpeechGrammarList ||
                (window as any).mozSpeechGrammarList ||
                (window as any).oSpeechGrammarList ||
                (window as any).msSpeechGrammarList)();
        }

        switch (this.lang) {
            case 'nl-NL':
                this.langNumbers = generateDutchToNumber();
                if (grammarList) {
                    grammarList.addFromString(NumbersGrammarNL);
                    grammarList.addFromString(
                        `#JSGF V1.0; grammar generalCommandsDutch; public <command> = resterende score | score | geen score | failliet | score ongedaan maken | score bewerken | toetsenbord | nummers | enkel | sneltoetsen | check out;`
                    );
                }
                break;
            case 'de-DE':
                this.langNumbers = generateGermanToNumber();
                if (grammarList) {
                    grammarList.addFromString(NumbersGrammarDE);
                    grammarList.addFromString(
                        `#JSGF V1.0; grammar generalCommandsGerman; public <command> = verbleibende Punktzahl | Punktzahl | keine Punktzahl | pleite | Punktzahl rückgängig machen | Punktzahl bearbeiten | Tastatur | Zahlen | einzel | Hotkeys | check out;`
                    );
                }
                break;
            default:
                this.langNumbers = generateEnglishToNumber();
                if (grammarList) {
                    grammarList.addFromString(NumbersGrammarEN);
                    grammarList.addFromString(
                        `#JSGF V1.0; grammar generalCommands; public <command> = remaining score | score | no score | bust | undo score | edit score | keyboard | numbers | single | hotkeys | check out;`
                    );
                }
                break;
        }

        if (grammarList) {
            this.recognition.grammars = grammarList;
        }
    }

    loadMainCommands(speechCommands: SpeechCommand[]): void {
        this.mainSpeechCommands = speechCommands;
        this.mainSpeechCommands = this.mainSpeechCommands.filter(
            (mainSpeechCommand) => !mainSpeechCommand.game || mainSpeechCommand.game === this.game
        );
        this.mainSpeechCommands.forEach((speechCommand, index) => {
            speechCommand.id = index;
        });

        this.refreshCommands();
    }

    refreshCommands(): void {
        this.speechCommands = _.cloneDeep(this.mainSpeechCommands);
        this.speechCommands.forEach((speechCommand, index) => {
            if (
                speechCommand.type &&
                speechCommand.option &&
                speechCommand.option != this.commandOptions[speechCommand.type]
            ) {
                this.speechCommands.splice(index, 1);
            }
        });
    }

    setRecognizing(recognizing: boolean): void {
        this.recognizing = recognizing;
        this.recognizing$.next(recognizing);
    }

    setProcessing(processing: boolean): void {
        this.processing = processing;
        this.processing$.next(processing);
    }

    setPlayingAudio(playingAudio: boolean): void {
        this.playingAudio = playingAudio;
        this.playingAudio$.next(playingAudio);
    }

    start(): void {
        if (!this.appFeaturesService.enabledAppFeatures().speech_to_score) {
            return;
        }

        if (!this.recognition || this.isStarting) {
            return;
        }

        this.isStarting = true;

        this.log('START', this.recognizing);

        this.restartAfter = 0;

        if (!this.recognizing) {
            try {
                this.recognition.start();
            } catch (err) {
                this.log(err);
            }
        }
    }

    stop(restartAfter: number = null): void {
        if (!this.appFeaturesService.enabledAppFeatures().speech_to_score) {
            return;
        }

        if (!this.recognition) {
            return;
        }

        this.log('STOP', this.recognizing);

        this.restartAfter = restartAfter;
        this.clearInterimTimeout();
        this.lastInterimCommand = null;

        if (this.recognizing) {
            try {
                this.recognition.abort();
            } catch (err) {
                this.log(err);
            }
        }
    }

    restart(restartAfter: number = 0): void {
        if (this.recognizing) {
            this.stop(restartAfter);
        } else {
            this.start();
        }
    }

    log(first: any, ...optionalParams: any[]): void {
        if (environment.debug) {
            console.log('SPEECH TO SCORE - ' + first, optionalParams);
        }
    }
}
