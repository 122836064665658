<div class="w-full flex flex-row" [class]="showUserThrowsLabel() ? 'justify-between' : 'justify-end'">
    @if (isPaused()) {
        <div
            class="w-full text-center font-bebas-neue text-xl text-black mx-4 my-3 truncate"
            [translate]="'BREAK_IN_PROGRESS'"
            i18n="@@BREAK_IN_PROGRESS">
            You've got a break
        </div>
    } @else {
        @if (showUserThrowsLabel()) {
            @if (playerCount() === 1) {
                <div
                    class="font-bebas-neue text-xl !text-white mx-4 my-3 truncate"
                    [translate]="'ITS_YOUR_TURN'"
                    i18n="@@ITS_YOUR_TURN">
                    It's your turn!
                </div>
            } @else {
                <div
                    class="font-bebas-neue text-black text-xl truncate mx-4 my-3"
                    [class.!text-white]="!isOnline() || myTurn()">
                    {{ userThrowsLabel() }}
                </div>
            }
        }

        @if (omniActive() && myTurn() && !isPaused()) {
            @if (omniIngameService.currentSingleDartEvent) {
                <!-- <div class="animate__animated animate__slideInLeft font-bebas-neue text-xl mx-4 my-3">
            @if (omniIngameService.currentSingleDartEvent === 'RemoveDarts') {
                <div [translate]="'REMOVE_YOUR_DARTS'" i18n="@@REMOVE_YOUR_DARTS">Remove your darts</div>
            } @else if (omniIngameService.currentSingleDartEvent === 'BustedScore') {
                <div [translate]="'BUSTED_SCORE_REMOVE_DARTS'" i18n="@@BUSTED_SCORE_REMOVE_DARTS">
                    You have bust your score, remove your darts
                </div>
            } @else if (omniIngameService.currentSingleDartEvent === 'DoubleOutError') {
                <div [translate]="'NEED_END_WITH_DOUBLE'" i18n="@@NEED_END_WITH_DOUBLE">
                    You need to finish with a double, remove your darts
                </div>
            } @else if (omniIngameService.currentSingleDartEvent === 'MasterOutError') {
                <div [translate]="'NEED_END_WITH_DOUBLE_OR_TRIPLE'" i18n="@@NEED_END_WITH_DOUBLE_OR_TRIPLE">
                    You need to finish with a double or triple, remove your darts
                </div>
            }
        </div> -->
                <app-primary-button
                    class="block my-2 mr-2 animate__animated animate__fadeIn"
                    size="extrasmall"
                    [addedClasses]="'h-full'"
                    [addedSlotClasses]="'!font-bebas-neue !text-base'"
                    [color]="'white'"
                    [bold]="false"
                    (click)="playerChange()"
                    [translate]="'DARTS_ARE_REMOVED'"
                    i18n="@@DARTS_ARE_REMOVED">
                    Darts are removed
                </app-primary-button>
            } @else if (!omniIngameService.forcedAllThrown) {
                <app-primary-button
                    class="block my-2 mr-2"
                    size="extrasmall"
                    [addedClasses]="'h-full'"
                    [addedSlotClasses]="'!font-bebas-neue !text-base'"
                    [color]="'white'"
                    [bold]="false"
                    (click)="forceOmniTurnReady()"
                    [translate]="'ALL_THROWN'"
                    i18n="@@ALL_THROWN">
                    All thrown
                </app-primary-button>
            }
        }
    }
</div>
