import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

export type SaveOnlineGameDialogResponse = 'save_to_continue' | 'save_to_stats';

@Component({
    selector: 'app-save-online-game-dialog',
    templateUrl: 'save-online-game.html',
})
export class SaveOnlineGameDialogComponent {
    constructor(public view: ModalController) {}

    public cancel(): void {
        this.view.dismiss();
    }

    public saveToContinue(): void {
        this.view.dismiss('save_to_continue' as SaveOnlineGameDialogResponse);
    }

    public saveToStats(): void {
        this.view.dismiss('save_to_stats' as SaveOnlineGameDialogResponse);
    }
}
