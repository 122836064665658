import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';

export const aroundTheClockGameRoutes: Route[] = [
    {
        path: 'game/around-the-clock',
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/around-the-clock-local-game/around-the-clock-local-game.component').then(
                (m) => m.AroundTheClockLocalGameComponent
            ),
    },
];
