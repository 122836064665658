import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';

@Injectable()
export class ConfettiService {
    private starPositions = [
        { x: 0.5, y: 0.1 }, // Top
        { x: 0.2, y: 0.4 }, // Bottom-left
        { x: 0.8, y: 0.4 }, // Bottom-right
        { x: 0.3, y: 0.8 }, // Upper-left
        { x: 0.7, y: 0.8 }, // Upper-right
    ];

    public showStarAndBigBang(): void {
        this.triggerStarConfetti(0);
    }

    private triggerStarConfetti(index: number): void {
        if (index < this.starPositions.length) {
            const position = this.starPositions[index];
            confetti({
                particleCount: 100,
                startVelocity: 30,
                spread: 360,
                origin: position,
            });

            setTimeout(() => {
                this.triggerStarConfetti(index + 1);
            }, 450);
        } else {
            this.triggerBigBang();
        }
    }

    private triggerBigBang(): void {
        confetti({
            particleCount: 500,
            startVelocity: 50,
            spread: 360,
            gravity: 0.5,
            ticks: 200,
            origin: { x: 0.5, y: 0.5 }, // Center
        });
    }
}
