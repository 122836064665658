import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class InputFocusManagerService {
    private setInputFocus$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

    constructor() {}

    public triggerFocusInput(): void {
        if (environment.isWeb) this.setInputFocus$.next();
    }

    public focusInput(): Observable<void> {
        return this.setInputFocus$;
    }
}
