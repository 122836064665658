import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class ProfileApiService extends ApiService {
    public uploadProfilePhoto = super.getFetcher().path('/profiles/photo/upload').method('put').create();
    public deleteProfilePhoto = super.getFetcher().path('/profiles/photo').method('delete').create();
    public uploadOnlineSetup = super.getFetcher().path('/profiles/online-setup/upload').method('put').create();
    public deleteOnlineSetup = super.getFetcher().path('/profiles/online-setup').method('delete').create();
}
