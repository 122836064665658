<div class="dc-event-overlay" *ngIf="isVisible" [ngClass]="{ 'animate__animated animate__fadeOut': isHiding }">
    <div class="overlay-content">
        <div class="message" *ngFor="let text of texts">
            <h1 *ngIf="text.message" [ngClass]="[isHiding ? text.hideAnimation : text.showAnimation]">
                {{ text.message }}
            </h1>
            <img
                *ngIf="text.imagePath"
                [src]="text.imagePath"
                [ngClass]="[isHiding ? text.hideAnimation : text.showAnimation]"
                alt="Event Image" />
        </div>
    </div>
</div>
