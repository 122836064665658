import { Injectable } from '@angular/core';
import { LOCAL_GAME_UNFINISHED_KEYS } from '../dc-gamelogic/game-storage';

import { ApiService } from './api.service';

@Injectable()
export class GameApiService extends ApiService {
    public getPlayersHeadToHead = super
        .getFetcher()
        .path('/head-to-head/players/{user1}/{user2}')
        .method('get')
        .create();

    public getDartbot = super.getFetcher().path('/dartbot').method('get').create();

    public assignCameraStreamClipToGame = super
        .getFetcher()
        .path('/camera-stream-clips/{clipId}/assign')
        .method('post')
        .create();

    public createPreGame = super.getFetcher().path('/pre-games').method('post').create();
    public readyPreGame = super.getFetcher().path('/pre-games/ready').method('post').create();

    // Match
    public unverifiedMatchesCount = 0;
    public unfinishedLocalMatches: any[] = [];
    public getMatches = super.getFetcher().path('/matches').method('get').create();
    public getTournamentMatches = super.getFetcher().path('/matches/tournaments').method('get').create();
    public getMatchUsers = super.getFetcher().path('/matches/users').method('get').create();
    public storeMatch = super.getFetcher().path('/matches').method('post').create();
    public getMatchById = super.getFetcher().path('/matches/{matchId}').method('get').create();
    public verifyMatches = super.getFetcher().path('/matches/verify').method('post').create();
    public removeMatches = super.getFetcher().path('/matches/remove').method('post').create();
    public getStartedMatches = super.getFetcher().path('/matches/started').method('get').create();
    public startMatch = super.getFetcher().path('/matches/start').method('post').create();

    // Cricket Tactics
    public unverifiedCricketTacticsCount = 0;
    public getCricketTactics = super.getFetcher().path('/cricket-tactics').method('get').create();
    public getCricketTacticUsers = super.getFetcher().path('/cricket-tactics/users').method('get').create();
    public storeCricketTactics = super.getFetcher().path('/cricket-tactics').method('post').create();
    public getCricketTacticsById = super
        .getFetcher()
        .path('/cricket-tactics/{cricketTacticsId}')
        .method('get')
        .create();
    public verifyCricketTactics = super.getFetcher().path('/cricket-tactics/verify').method('post').create();
    public removeCricketTactics = super.getFetcher().path('/cricket-tactics/remove').method('post').create();

    // Around the Clock
    public unverifiedAroundTheClocksCount = 0;
    public getAroundTheClocks = super.getFetcher().path('/around-the-clocks').method('get').create();
    public getAroundTheClockUsers = super.getFetcher().path('/around-the-clocks/users').method('get').create();
    public storeAroundTheClock = super.getFetcher().path('/around-the-clocks').method('post').create();
    public getAroundTheClockById = super
        .getFetcher()
        .path('/around-the-clocks/{aroundTheClockId}')
        .method('get')
        .create();
    public verifyAroundTheClocks = super.getFetcher().path('/around-the-clocks/verify').method('post').create();
    public removeAroundTheClocks = super.getFetcher().path('/around-the-clocks/remove').method('post').create();

    // Bob's 27
    public unverifiedBobs27sCount = 0;
    public getBobs27s = super.getFetcher().path('/bobs-27s').method('get').create();
    public getBobs27Users = super.getFetcher().path('/bobs-27s/users').method('get').create();
    public storeBobs27 = super.getFetcher().path('/bobs-27s').method('post').create();
    public getBobs27ById = super.getFetcher().path('/bobs-27s/{bobs27Id}').method('get').create();
    public verifyBobs27s = super.getFetcher().path('/bobs-27s/verify').method('post').create();
    public removeBobs27s = super.getFetcher().path('/bobs-27s/remove').method('post').create();

    // Shanghai
    public unverifiedShanghaisCount = 0;
    public getShanghais = super.getFetcher().path('/shanghais').method('get').create();
    public getShanghaiUsers = super.getFetcher().path('/shanghais/users').method('get').create();
    public storeShanghai = super.getFetcher().path('/shanghais').method('post').create();
    public getShanghaiById = super.getFetcher().path('/shanghais/{shanghaiId}').method('get').create();
    public verifyShanghais = super.getFetcher().path('/shanghais/verify').method('post').create();
    public removeShanghais = super.getFetcher().path('/shanghais/remove').method('post').create();

    // 121 Checkout
    public unverifiedCheckout121sCount = 0;
    public getCheckout121s = super.getFetcher().path('/checkout121s').method('get').create();
    public getCheckout121Users = super.getFetcher().path('/checkout121s/users').method('get').create();
    public getCheckout121TrialStatus = super.getFetcher().path('/checkout121s/trial').method('get').create();
    public storeCheckout121 = super.getFetcher().path('/checkout121s').method('post').create();
    public getCheckout121ById = super.getFetcher().path('/checkout121s/{checkout121Id}').method('get').create();
    public verifyCheckout121s = super.getFetcher().path('/checkout121s/verify').method('post').create();
    public removeCheckout121s = super.getFetcher().path('/checkout121s/remove').method('post').create();

    // Singles Training
    public unverifiedSinglesTrainingsCount = 0;
    public getSinglesTrainings = super.getFetcher().path('/singles-trainings').method('get').create();
    public getSinglesTrainingUsers = super.getFetcher().path('/singles-trainings/users').method('get').create();
    public storeSinglesTraining = super.getFetcher().path('/singles-trainings').method('post').create();
    public getSinglesTrainingById = super
        .getFetcher()
        .path('/singles-trainings/{singlesTrainingId}')
        .method('get')
        .create();
    public verifySinglesTrainings = super.getFetcher().path('/singles-trainings/verify').method('post').create();
    public removeSinglesTrainings = super.getFetcher().path('/singles-trainings/remove').method('post').create();

    // Doubles Training
    public unverifiedDoublesTrainingsCount = 0;
    public getDoublesTrainings = super.getFetcher().path('/doubles-trainings').method('get').create();
    public getDoublesTrainingUsers = super.getFetcher().path('/doubles-trainings/users').method('get').create();
    public storeDoublesTraining = super.getFetcher().path('/doubles-trainings').method('post').create();
    public getDoublesTrainingById = super
        .getFetcher()
        .path('/doubles-trainings/{doublesTrainingId}')
        .method('get')
        .create();
    public verifyDoublesTrainings = super.getFetcher().path('/doubles-trainings/verify').method('post').create();
    public removeDoublesTrainings = super.getFetcher().path('/doubles-trainings/remove').method('post').create();

    // Score Training
    public unverifiedScoreTrainingsCount = 0;
    public getScoreTrainings = super.getFetcher().path('/score-trainings').method('get').create();
    public getScoreTrainingUsers = super.getFetcher().path('/score-trainings/users').method('get').create();
    public storeScoreTraining = super.getFetcher().path('/score-trainings').method('post').create();
    public getScoreTrainingById = super.getFetcher().path('/score-trainings/{scoreTrainingId}').method('get').create();
    public verifyScoreTrainings = super.getFetcher().path('/score-trainings/verify').method('post').create();
    public removeScoreTrainings = super.getFetcher().path('/score-trainings/remove').method('post').create();

    setUnverifiedGames(): void {
        this.getMatches({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedMatchesCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedMatchesCount = 0;
            });
        this.getCheckout121s({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedCheckout121sCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedCheckout121sCount = 0;
            });
        this.getCricketTactics({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedCricketTacticsCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedCricketTacticsCount = 0;
            });
        this.getAroundTheClocks({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedAroundTheClocksCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedAroundTheClocksCount = 0;
            });
        this.getBobs27s({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedBobs27sCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedBobs27sCount = 0;
            });
        this.getShanghais({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedShanghaisCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedShanghaisCount = 0;
            });
        this.getSinglesTrainings({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedSinglesTrainingsCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedSinglesTrainingsCount = 0;
            });
        this.getDoublesTrainings({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedDoublesTrainingsCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedDoublesTrainingsCount = 0;
            });
        this.getScoreTrainings({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.unverifiedScoreTrainingsCount = res.data.total;
            })
            .catch(() => {
                this.unverifiedScoreTrainingsCount = 0;
            });
    }

    totalUnverifiedGames(): number {
        return (
            this.unverifiedMatchesCount +
            this.unverifiedCricketTacticsCount +
            this.unverifiedAroundTheClocksCount +
            this.unverifiedCheckout121sCount +
            this.unverifiedBobs27sCount +
            this.unverifiedShanghaisCount +
            this.unverifiedSinglesTrainingsCount +
            this.unverifiedDoublesTrainingsCount +
            this.unverifiedScoreTrainingsCount
        );
    }

    getUnfinishedLocalGames(): void {
        this.unfinishedLocalMatches = JSON.parse(localStorage.getItem(LOCAL_GAME_UNFINISHED_KEYS.MATCH)) || [];
    }

    setUnfinishedLocalGames(): void {
        localStorage.setItem(LOCAL_GAME_UNFINISHED_KEYS.MATCH, JSON.stringify(this.unfinishedLocalMatches));
    }
}
