import { Component, inject } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-follow-modal',
    templateUrl: './follow-modal.component.html',
    standalone: true,
    imports: [IonicModule, TranslateModule],
})
export class FollowModalComponent {
    private modalController: ModalController = inject(ModalController);
    private inAppBrowser: InAppBrowser = inject(InAppBrowser);

    dismiss() {
        this.modalController.dismiss();
    }

    openUrl(url: string) {
        this.inAppBrowser.create(url, '_system', {
            location: 'yes',
        } as InAppBrowserOptions);
    }
}
