/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProfilePhotoComponent } from '@components/profile-photo/profile-photo.component';
import { AuthApiService } from '@dc-api/auth.api.service';
import { FriendApiService } from '@dc-api/friend.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { ValidationLabelComponent } from 'src/app/shared/components/validation-label/validation-label.component';

enum AddPlayerType {
    guest = 'guest',
    dartbot = 'dartbot',
    friend = 'friend',
    existing = 'existing',
    own = 'own',
}

export type AddPlayerDialogResponse = {
    type: AddPlayerType;
    user: User;
};

@Component({
    selector: 'app-games-local-add-player-dialog',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrimaryButtonComponent,
        ProfilePhotoComponent,
        TranslateModule,
        IonicModule,
        ValidationLabelComponent,
        IconComponent,
    ],
    templateUrl: 'games-local-add-player-dialog.component.html',
})
export class GamesLocalAddPlayerDialogComponent implements OnInit, OnDestroy {
    @ViewChild('form') formElement: FormGroupDirective;
    private friendApiService: FriendApiService = inject(FriendApiService);
    private authApiService: AuthApiService = inject(AuthApiService);
    private modalController: ModalController = inject(ModalController);
    private authService: AuthService = inject(AuthService);
    private formBuilder: FormBuilder = inject(FormBuilder);
    private platform: Platform = inject(Platform);

    public playerTypeToAdd: AddPlayerType;
    public addPlayerTypes = AddPlayerType;
    public title = $localize`:@@ADD_PLAYER:Add player`;
    public addPlayerForm = this.formBuilder.group({
        guestName: [],
        friend: [],
        existingUser: [],
    });
    public loginForm = this.formBuilder.group({
        login: ['', Validators.required],
        password: ['', Validators.required],
    });
    public friends: User[] = [];

    private backButtonSubscription: Subscription;

    constructor() {}

    public ngOnInit(): void {
        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });
    }

    public ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }
    }

    public back(): void {
        this.title = $localize`:@@ADD_PLAYER:Add player`;
        this.formElement.resetForm();
        this.playerTypeToAdd = null;

        for (const key in this.addPlayerForm.controls) {
            this.addPlayerForm.get(key).clearValidators();
            this.addPlayerForm.get(key).updateValueAndValidity();
        }

        this.addPlayerForm.removeValidators(Validators.required);
        this.addPlayerForm.reset();
    }

    public close(returnValue: AddPlayerDialogResponse = null): void {
        this.modalController.dismiss(returnValue);
    }

    public setPlayerTypeToAdd(type: AddPlayerType): void {
        this.playerTypeToAdd = type;

        switch (type) {
            case AddPlayerType.guest:
                this.title = $localize`:@@GUEST_DETAILS:Guest details`;
                this.addPlayerForm.get('guestName').addValidators(Validators.required);
                this.addPlayerForm.get('guestName').updateValueAndValidity();
                break;
            case AddPlayerType.friend:
                this.title = $localize`:@@FRIEND_DETAILS:Friend details`;
                this.addPlayerForm.get('friend').addValidators(Validators.required);
                this.addPlayerForm.get('friend').updateValueAndValidity();

                this.friendApiService.getFriends({ search: '', limit: 100 }).then((res) => {
                    this.friends = res.data.data;
                });
                break;
            case AddPlayerType.existing:
                this.title = $localize`:@@USER_CREDENTIALS:User credentials`;
                this.addPlayerForm.get('existingUser').addValidators(Validators.required);
                this.addPlayerForm.get('existingUser').updateValueAndValidity();
                break;
            case AddPlayerType.own:
                this.close({
                    type: type,
                    user: this.authService.user,
                });
                break;
        }
    }

    public searchFriends(event: CustomEvent): void {
        const searchString = event.detail.value;
        if (searchString) {
            this.friendApiService.getFriends({ search: searchString, limit: 9999 }).then((res) => {
                this.friends = res.data.data;

                if (!res.data.data.find((friend) => friend.id === this.addPlayerForm.get('friend').value?.id)) {
                    this.addPlayerForm.get('friend').reset();
                }
            });
        } else {
            this.friends = [];
            this.addPlayerForm.get('friend').reset();
        }
    }

    public selectFriend(friend: User): void {
        this.addPlayerForm.get('friend').patchValue(friend);
    }

    public loginExistingUser(): void {
        const user = this.loginForm.value;

        this.authApiService
            .login({ login: user.login, password: user.password })
            .then((res) => {
                this.addPlayerForm.get('existingUser').patchValue(res.data.user);
            })
            .finally(() => {
                this.loginForm.reset();
            });
    }

    public addPlayer(): void {
        switch (this.playerTypeToAdd) {
            case 'guest':
                this.addGuestPlayer();
                break;
            case 'friend':
                this.addFriendPlayer();
                break;
            case 'existing':
                this.addExistingPlayer();
                break;
        }
    }

    public resetExistingPlayer(form: FormGroupDirective): void {
        this.addPlayerForm.get('existingUser').reset();
        form.resetForm();
    }

    private addGuestPlayer(): void {
        const guestName = this.addPlayerForm.get('guestName').value;
        this.close({
            type: AddPlayerType.guest,
            user: {
                id: null,
                first_name: guestName,
                full_name: guestName,
            },
        });
    }

    private addFriendPlayer(): void {
        const friend = this.addPlayerForm.get('friend').value;
        this.close({
            type: AddPlayerType.friend,
            user: friend,
        });
    }

    private addExistingPlayer(): void {
        const existingPlayer = this.addPlayerForm.get('existingUser').value;
        this.close({
            type: AddPlayerType.existing,
            user: existingPlayer,
        });
    }
}
