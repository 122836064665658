import { inject, Injectable } from '@angular/core';
import { GameApiService } from '@dc-api/game.api.service';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { TrialStatusResponse } from '@dc-core/dc-backend/dc-responses';
import { AuthService } from '@services/auth.service';
import { ApiResponse } from 'openapi-typescript-fetch';
import { from, map, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GamesTrialService {
    private authService: AuthService = inject(AuthService);
    private gameApiService: GameApiService = inject(GameApiService);
    private storageKeys: { [key: string]: string } = { checkout_121: 'CHECKOUT_121_TRIAL_GAMES_COUNT' };
    private MAX_GAMES: number = 3;

    constructor() {}

    public hasValidTrial(gameMode: GameMode): Observable<boolean> {
        if (this.authService.user.is_ultimate) {
            return of(true);
        } else {
            switch (gameMode) {
                case 'checkout_121':
                    return from(this.getCheckout121TrialStatus()).pipe(map((status) => status.data.valid));
                default:
                    return of(false);
            }
        }
    }

    public async getCheckout121TrialStatus(): Promise<ApiResponse<TrialStatusResponse>> {
        const localGameSCount = Number(localStorage.getItem(this.storageKeys['checkout_121'])) || 0;

        const trialStatus = await this.gameApiService.getCheckout121TrialStatus({});
        const apiGameCount = trialStatus.data.games_played;
        const finalGameCount = Math.max(localGameSCount, apiGameCount);
        return {
            ...trialStatus,
            data: {
                ...trialStatus.data,
                games_played: finalGameCount,
                valid: finalGameCount < this.MAX_GAMES,
            },
        };
    }

    public increaseLocalTrialCount(gameMode: GameMode): void {
        window.localStorage.setItem(
            this.storageKeys[gameMode],
            (Number(window.localStorage.getItem(this.storageKeys[gameMode])) + 1).toString()
        );
    }
}
