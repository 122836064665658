import { Plugin } from '@capacitor/core';

export interface CapacitorSmartDeviceClientPlugin extends Plugin {
  sendTLSRequest(options: SendTLSRequestOptions): Promise<{ data: string }>;
  startUDPSocket(options: WatchUDPPortOptions): Promise<{ data: string }>;
  stopUDPSocket(): Promise<{ data: string }>;
}

export interface SendTLSRequestOptions {
  host: string;
  port: number;
  data: any;
}

export interface WatchUDPPortOptions {
  host: string;
  port: number;
}

export enum VirtCamCommands {
  ConfigureWiFiStation = 0,
  StartWiFiAP = 1,
  SystemShutdown = 2,
  SystemReboot = 3,
  StartUV4L = 4,
  StopUV4L = 5,
  ReadGUID = 6,
}

export interface VirtCamConfigureWiFiStationRequest {
  command: VirtCamCommands.ConfigureWiFiStation;
  ssid: string;
  passphrase: string;
  country: string;
}

export interface VirtCamStartWiFiAPRequest {
  command: VirtCamCommands.StartUV4L;
  tls: 0 | 1;
}

export interface VirtCamCommandRequest {
  command: VirtCamCommands;
}

export enum VirtCamResponses {
  Success = 0,
  Error = 1,
}

export interface VirtCamResponse {
  status: VirtCamResponses;
  guid?: string;
}

export class VirtCamHelper {
  public static getResponse(res: { data: string }): VirtCamResponse {
    return JSON.parse(res.data) as VirtCamResponse;
  }
}
