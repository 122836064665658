/* eslint-disable max-len */
import { Component, effect, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { App } from '@capacitor/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FollowModalComponent } from '@components/follow-modal/follow-modal.component';
import { ConfigApiService } from '@dc-api/config.api.service';
import { CTAApiService } from '@dc-api/cta.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { RecapApiService } from '@dc-api/recap.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { CTAModule, Discount, Sound } from '@dc-core/dc-backend/dc-classes';
import { RecapResponse } from '@dc-core/dc-backend/dc-responses';
import { COUNTRIES } from '@dc-core/dc-firestore/globals/countries.globals';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { AlertController, IonContent, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { PurchaseService } from '@providers/PurchaseService';
import { MediaWatcherService } from '@services/media-watcher.service';
import { ThemeService } from '@services/theme.service';
import { ConfigKey, CTAActionTarget } from 'dc-core/dc-backend/dc-enums';
import { HotkeyKeyboardOptions } from 'dc-core/dc-gamelogic/game-interfaces';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { components } from 'dc-core/dc-typings/dc-backend-definitions';
import _ from 'lodash';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { ProfilePrivacyDialogComponent } from 'src/app/modules/account-settings/components/profile-privacy/profile-privacy.dialog';
import { ThemePreferenceModalComponent } from 'src/app/modules/account-settings/components/theme-preference/theme-preference-modal.component';
import { YearRecapDialogComponent } from 'src/app/modules/year-recap/dialogs/year-recap/year-recap.dialog';
import { FeatureSlide, FeatureSlidesDialogComponent } from 'src/dialogs/feature-slides/feature-slides';
import { environment } from 'src/environments/environment';
import { SUPPORTED_ENVIRONMENTS } from 'src/environments/environment.models';

import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from '../../app/app.globals';
import { CTAModuleDialogComponent } from '../../dialogs/ctamodule/ctamodule';
import { DartboardDialogComponent } from '../../dialogs/dartboard/dartboard';
import { ForceChangePasswordDialogComponent } from '../../dialogs/force-change-password/force-change-password';
import { LinkSoundDialogComponent } from '../../dialogs/link-sound/link-sound';
import { LoginOrCreateDialogComponent } from '../../dialogs/login-or-create/login-or-create';
import { Events } from '../../providers/EventsService';
import { JoyrideService } from '../../providers/ngx-joyride/src/lib/services/joyride.service';
import { UpgradeService } from '../../providers/UpgradeService';
import { AuthService } from '../../services/auth.service';

/**
 * Generated class for the Games page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-page-dashboard',
    templateUrl: 'dashboard.html',
})
export class DashboardComponent implements OnInit {
    @ViewChild('swiper')
    swiperRef: ElementRef | undefined;

    @ViewChild(IonContent)
    content: IonContent;
    numberKeyboardOptions: HotkeyKeyboardOptions;
    signal: number = 1;

    public isLocal = environment.local;
    public isDev = environment.stage === SUPPORTED_ENVIRONMENTS.DEVELOPMENT;
    public dcConfig: any = {};
    public modules: components['schemas']['CTAModule'][] = [];
    public invitations: any = [];
    public isTablet?: boolean = undefined;
    private showedAlert = false;
    private exitAppAlert: any;
    private backbuttonSubscription: Subscription;

    public headerimg = 'assets/icon/dctarget-line-grey.png';
    countries: {
        label: string;
        name: string;
        flag: string;
        alpha2: string;
    }[];

    public locale: 'en' | 'nl' | 'de' = null;
    public images: {
        upgradeToUltimate: string;
        checkout121: string;
        virt: string;
        omni: string;
        follow_us: string;
        action_replays: string;
        blackFriday: string;
    } = {
        upgradeToUltimate: null,
        checkout121: null,
        virt: null,
        omni: null,
        follow_us: null,
        action_replays: null,
        blackFriday: null,
    };

    public canRecap: boolean;
    public recap: RecapResponse;
    public recapYear: number;
    public showingRecap = false;

    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);
    public themeService: ThemeService = inject(ThemeService);

    private recapApiService: RecapApiService = inject(RecapApiService);

    private upgradeSlideRendered: boolean = false;
    private isOpeningUpgradeDialog = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private navCtrl: NavController,
        public auth: AuthService,
        private iab: InAppBrowser,
        private ga: DartCounterAnalyticsService,
        private market: Market,
        public menu: MenuController,
        private _router: Router,
        private alertCtrl: AlertController,
        private _alertService: DartCounterAlertService,
        public platform: Platform,
        private modal: ModalController,
        public preferenceService: DartCounterPreferenceService,
        private upgrade: UpgradeService,
        private events: Events,
        public gameApiService: GameApiService,
        private ctaApiService: CTAApiService,
        private _userApiService: UserApiService,
        private readonly joyrideService: JoyrideService,
        private translate: TranslateService,
        private _configApiService: ConfigApiService,
        private _purchases: PurchaseService,
        private _mediaWatcherService: MediaWatcherService
    ) {
        effect(() => {
            const currentActiveDiscount = this.upgrade.currentActiveDiscount();
            this.setImages(currentActiveDiscount);
        });
    }

    ionViewDidEnter() {
        this.menu.enable(true, 'mainmenu');
        this.menu.enable(false, 'ingamemenu');

        this.auth.setInApp();
    }

    joyrideDone() {
        const scrollContent = document.getElementById('scroll-content');
        if (top !== null) {
            scrollContent.scrollTo({
                top: 0,
                left: 0,
            });
        }

        if (this.platform.is('capacitor')) {
            this.checkCTAModules();
        }
    }

    async checkCTAModules() {
        let userlevel: components['schemas']['CTAUserLevel'] = 'guest';
        if (this.auth.user && this.auth.user.is_admin) {
            userlevel = 'admin';
        } else if (this.auth.user && this.auth.user.is_ultimate) {
            userlevel = 'ultimate';
        } else if (this.auth.user) {
            userlevel = 'user';
        }

        let platform: components['schemas']['Platform'] = 'android';
        if (this.platform.is('ios')) {
            platform = 'ios';
        }
        let device: components['schemas']['Device'] = 'mobile';
        if (this.platform.is('tablet')) {
            device = 'tablet';
        }

        try {
            const { build } = await App.getInfo();
            const moduleData = {
                user_level: userlevel,
                platform,
                device,
                version: parseInt(build),
            };

            this.ctaApiService.getCTAModules(moduleData).then((res) => {
                this.modules = res.data;
                const viewedModules = JSON.parse(localStorage.getItem(LocalStorageKey.viewedCTAModules));

                this.CheckViewedModules(viewedModules ?? [], moduleData);
            });
        } catch (err) {
            console.warn(err);
        }
    }

    showTour() {
        setTimeout(() => {
            this.joyrideService.startTour({
                steps: ['dashb1', 'dashb2', 'dashb3', 'dashb4'],
                showPrevButton: false,
                themeColor: '#444',
            });

            localStorage.setItem(LocalStorageKey.firstTimeDashboard, JSON.stringify(false));
        }, 250);
    }

    async ngOnInit() {
        this.platform.ready().then(() => {
            this._purchases.initialize();
        });

        this.isTablet = this.platform.is('tablet');

        this._configApiService.getConfig({}).then((res) => {
            this.dcConfig = res.data;
            if (this.dcConfig['HEADERIMAGE' as ConfigKey] && this.dcConfig['HEADERIMAGE' as ConfigKey]['value']) {
                this.headerimg =
                    environment.apiUrl + '/config/file/' + this.dcConfig['HEADERIMAGE' as ConfigKey]['value'];
            }
        });

        this.setLocale();

        this.events.subscribe('navigate', (data: any) => {
            this.navCtrl.navigateForward(data.path, {
                queryParams: data.queryParams,
            });
        });

        if (this.auth.user) {
            // Pass the DartCounter UserID to Google Analytics
            await FirebaseAnalytics.setUserId({ userId: this.auth.user.id.toString() });

            this.auth.userIsUltimate.pipe(takeUntil(this._unsubscribeAll)).subscribe((isUltimate) => {
                if (!isUltimate) {
                    this.upgradeSlideRendered = true;
                } else if (isUltimate && this.upgradeSlideRendered) {
                    this.upgradeSlideRendered = false;
                    this.swiperRef?.nativeElement.swiper.removeSlide(0);
                }
            });

            if (this.auth.user.must_change_password) {
                this.ShowChangePasswordDialog();
            }
        }

        this._userApiService
            .getCurrentUser({})
            .then((res) => {
                this.auth.user = res.data;
            })
            .catch((e) => {
                this._alertService.errorFromApi(e);
            })
            .finally();
    }

    ionViewWillEnter() {
        this.ga.trackView('Dashboard');

        if (localStorage.getItem(LocalStorageKey.theme)) {
            this.afterThemeCheck();
        } else {
            this.modal
                .create({
                    component: ThemePreferenceModalComponent,
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(() => {
                        this.themeService.saveSelectedThemeToStorage();

                        if (environment.production) {
                            this.ga.trackEvent(
                                GA_EVENTCATEGORIES.DASHBOARD,
                                GA_EVENTACTIONS.CHANGETHEME,
                                this.themeService.selectedTheme()
                            );
                        }

                        this.afterThemeCheck();
                    });
                });
        }
    }

    afterThemeCheck(): void {
        if (this.auth.user.profile_privacy) {
            this.afterProfilePrivacyCheck();
        } else {
            this.modal
                .create({
                    component: ProfilePrivacyDialogComponent,
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(() => {
                        if (!this.auth.user.profile_privacy) {
                            this._userApiService.updateUser({ profile_privacy: 'public' }).then(() => {
                                this.auth.user.profile_privacy = 'public';
                            });
                        }

                        this.afterProfilePrivacyCheck();
                    });
                });
        }
    }

    afterProfilePrivacyCheck(): void {
        this.subscribeBackButton();
        this.checkRecap();
    }

    CheckViewedModules(viewedModules, moduleData) {
        let foundOne = false;
        this.modules.forEach((module: components['schemas']['CTAModule']) => {
            if (!foundOne) {
                const viewed = _.find(viewedModules, (m: components['schemas']['CTAModule']) => m.id == module.id);
                //Not viewed yet! show it and add it to the viewedModules
                if (!viewed) {
                    foundOne = true;

                    // If it's persistent, never add it to the 'viewed' list
                    // So it will always show
                    if (!module.is_persistent) {
                        //Viewed the module on this device
                        viewedModules.push({ id: module.id });

                        localStorage.setItem(LocalStorageKey.viewedCTAModules, JSON.stringify(viewedModules));
                    }

                    this.showCTAModuleDialog(moduleData, module);
                }
            }
        });
    }

    showCTAModuleDialog(moduleData, module: CTAModule): void {
        this.modal
            .create({
                component: CTAModuleDialogComponent,
                componentProps: {
                    module,
                },
                cssClass: 'auto-height',
                backdropDismiss: false,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();

                //Add the 'VIEW' event to the database
                this.ctaApiService.actionCTAModuleById({
                    moduleId: module.id,
                    version: moduleData.version,
                    action_type: 'view',
                    user_level: moduleData.user_level,
                    platform: moduleData.platform,
                    device: moduleData.device,
                });
                elem.onDidDismiss().then((dialogRes) => {
                    if (module.is_persistent) {
                        this.showCTAModuleDialog(moduleData, module);
                    }

                    const responseData = dialogRes.data;
                    if (responseData && responseData.target) {
                        // Add the 'CLICK' event to the database
                        this.ctaApiService.actionCTAModuleById({
                            moduleId: module.id,
                            version: moduleData.version,
                            action_type: 'click',
                            action_target: responseData.target,
                            user_level: moduleData.user_level,
                            platform: moduleData.platform,
                            device: moduleData.device,
                        });

                        switch (<CTAActionTarget>responseData.target) {
                            case 'upgrade':
                                this.OpenSubscriptionDialog();
                                break;
                            case 'update':
                                this.OpenStore();
                                break;
                            case 'create_account':
                                this.ShowLogin();
                                break;
                            case 'link':
                                window.location.href = responseData.addon;
                                break;
                            default:
                                break;
                        }
                    }
                });
            });
    }

    openMenu() {
        this.menu.open('mainmenu');
    }

    OpenStore() {
        if (this.platform.is('ios')) {
            this.market.open('999533915');
        } else {
            this.market.open('com.dartcounter.mobile');
        }
    }

    subscribeBackButton() {
        if (this.backbuttonSubscription) {
            this.backbuttonSubscription.unsubscribe();
        }
        // Confirm exit
        this.backbuttonSubscription = this.platform.backButton.subscribeWithPriority(1, () => {
            if (!this.showedAlert) {
                this.confirmExitApp();
            } else {
                this.showedAlert = false;
                this.exitAppAlert.dismiss();
            }
        });
    }

    unsubscribeBackButton() {
        if (this.backbuttonSubscription) {
            this.backbuttonSubscription.unsubscribe();
        }
    }

    ionViewDidLeave() {
        this.unsubscribeBackButton();
    }

    ShowChangePasswordDialog() {
        this.modal
            .create({
                component: ForceChangePasswordDialogComponent,
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: false,
                showBackdrop: true,
            })
            .then((elem) => {
                this.unsubscribeBackButton();
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    this.subscribeBackButton();
                    if (dialogRes.data === true) {
                        this._alertService.createAlert({
                            title: $localize`:@@PASSWORD_CHANGED_SUCCESSFULLY:Password changed successfully`,
                            icon: 'success',
                        });
                    }
                });
            });
    }

    ShowLogin() {
        this.unsubscribeBackButton();
        this.modal
            .create({
                component: LoginOrCreateDialogComponent,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => {
                    this.subscribeBackButton();
                });
            });
    }

    ShowProfile() {
        this.navCtrl.navigateForward('profile', {
            queryParams: {
                showBottomNav: true,
            },
        });
        this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.OPENPROFILE);
    }

    getFlag(abbr) {
        const country = _.find(COUNTRIES, (country) => country.alpha2 === abbr);
        if (!country) {
            return null;
        }
        return country.flag;
    }

    DartboardPOC() {
        //send throwout with amount of darts
        this.modal
            .create({
                component: DartboardDialogComponent,
            })
            .then((elem) => {
                elem.present();
            });
    }

    OpenStatistics() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.OPENSTATISTICS);
        this.navCtrl.navigateForward('statistics');
    }

    async OpenSubscriptionDialog() {
        if (this.isOpeningUpgradeDialog) {
            return;
        }

        this.isOpeningUpgradeDialog = true;

        this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.OPENULTIMATE);

        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.DASHBOARD);
        this.modal
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                this.unsubscribeBackButton();
                elem.present().then(() => (this.isOpeningUpgradeDialog = false));
                elem.onDidDismiss().then(() => {
                    this.subscribeBackButton();
                });
            });
    }

    LinkName() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.OPENMASTERCALLER);

        this.modal
            .create({
                component: LinkSoundDialogComponent,
                componentProps: {
                    user: this.auth.user,
                },
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                this.unsubscribeBackButton();
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    this.subscribeBackButton();

                    if (dialogRes.data === false) {
                        this.RemoveSound();
                    } else if (dialogRes.data !== null) {
                        this.AddSound(dialogRes.data);
                    }
                });
            });
    }

    openLink() {
        if (this.dcConfig.DashboardCTALink === 'DISCOUNT') {
            this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.CTAPRESS, this.dcConfig.DashboardCTA);
            this.OpenSubscriptionDialog();
        } else {
            const url = this.dcConfig.DashboardCTALink;
            this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.CTAPRESS, this.dcConfig.DashboardCTALink);

            this.iab.create(url, '_system', {
                location: 'yes',
            } as InAppBrowserOptions);
        }
    }

    openLink2(url) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.CTAMODULE, GA_EVENTACTIONS.CTAPRESS, url);

        this.iab.create(url, '_system', {
            location: 'yes',
        } as InAppBrowserOptions);
    }

    setLocale(): void {
        switch (this.translate.currentLang) {
            case 'de':
            case 'nl':
                this.locale = this.translate.currentLang;
                break;
            default:
                this.locale = 'en';
                break;
        }
    }

    setImages(discount: Discount): void {
        this._mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                const isLargeTablet =
                    matchingAliases.includes('lg') &&
                    (this.platform.is('capacitor') ? this.platform.is('tablet') : true);

                if (isLargeTablet) {
                    this.images.upgradeToUltimate =
                        'assets/images/dashboard/Slide-upgrade-carousel ' + this.locale.toUpperCase() + '.png';
                    this.images.checkout121 = 'assets/images/dashboard/checkout121.jpg';
                    this.images.virt = 'assets/images/ftr/virt-cam-setup/Virt-setup-device-dashboard.jpg';
                    this.images.omni = 'assets/images/ftr/omni-scoring-setup/Omni-setup-device.jpg';
                    this.images.follow_us = 'assets/images/dashboard/follow-us.jpg';
                    this.images.action_replays = 'assets/images/dashboard/new-action-replays.jpg';

                    switch (discount?.event) {
                        case 'black_friday':
                            this.images.blackFriday = 'assets/images/discount-events/BlackFriday33Off_web.jpg';
                            break;
                    }
                } else {
                    this.images.upgradeToUltimate =
                        'assets/images/dashboard/Slide-upgrade-carousel-sm ' + this.locale.toUpperCase() + '.png';
                    this.images.checkout121 = 'assets/images/dashboard/checkout121-sm.jpg';
                    this.images.virt = 'assets/images/ftr/virt-cam-setup/Virt-setup-device-dashboard-sm.jpg';
                    this.images.omni = 'assets/images/ftr/omni-scoring-setup/Omni-setup-device-sm.jpg';
                    this.images.follow_us = 'assets/images/dashboard/follow-us-sm.jpg';
                    this.images.action_replays = 'assets/images/dashboard/new-action-replays-sm.jpg';

                    switch (discount?.event) {
                        case 'black_friday':
                            this.images.blackFriday = 'assets/images/discount-events/BlackFriday33Off_mobile.jpg';
                            break;
                    }
                }
            });
    }

    AddSound(sound: Sound) {
        this._userApiService
            .updateUser({
                profile: {
                    sound_id: sound.id,
                },
            })
            .then(() => {
                this.auth.user.profile.sound = sound;
                this.auth.user.profile.sound_id = sound.id;
            });

        if (this.auth.user.is_ultimate) {
            this._alertService.createAlert({
                title: $localize`:@@SOUND_LINKED:Sound has been linked`,
            });
        }
    }

    RemoveSound() {
        this._userApiService
            .updateUser({
                profile: {
                    sound_id: null,
                },
            })
            .then(() => {
                this.auth.user.profile.sound = null;
                this.auth.user.profile.sound_id = null;
            });

        $localize`:@@SOUND_REMOVED:Sound has been removed`;
        this._alertService.createAlert({
            title: this.translate.instant('SOUND_REMOVED'),
        });
    }

    NewGame() {
        // let modal;
        // this.modal.create({
        //   component: HeadToHeadDialogOne,
        //   componentProps: {
        //     playerOneStatus: 88,
        //     playerTwoStatus: 101,
        //   },
        //   // cssClass: 'longDialog',
        //   backdropDismiss: true,
        //   showBackdrop: true,
        // } as ModalOptions).then(elem => {
        //   elem.present();
        //   modal = elem;
        //   modal.onDidDismiss().then(
        //     () => {

        //     });
        // });

        this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.STARTNEWGAME);
        this.navCtrl.navigateForward('local-games');
    }

    goToOnlineGames() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.OPENONLINEGAMES);
        this.navCtrl.navigateForward('online-games');
    }

    goToTournaments() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.DASHBOARD, GA_EVENTACTIONS.OPENTOURNAMENTS);
        this.navCtrl.navigateRoot('tournaments');
    }

    goToDev() {
        this.navCtrl.navigateForward('dev');
    }

    confirmExitApp() {
        this.showedAlert = true;

        $localize`:@@ARE_YOU_SURE:Are you sure?`;
        const question = this.translate.instant('ARE_YOU_SURE');
        $localize`:@@QUIT:Quit`;
        const title = this.translate.instant('QUIT');
        $localize`:@@YES:Yes`;
        const confirm = this.translate.instant('YES');
        $localize`:@@CANCEL:Cancel`;
        const cancel = this.translate.instant('CANCEL');

        this.alertCtrl
            .create({
                header: title,
                message: question,
                buttons: [
                    {
                        text: cancel,
                    },
                    {
                        text: confirm,
                        handler: () => {
                            navigator['app'].exitApp();
                        },
                    },
                ],
            })
            .then((elem) => {
                this.exitAppAlert = elem;
                this.exitAppAlert.present();
                elem.onDidDismiss().then(() => {
                    this.showedAlert = false;
                    this.subscribeBackButton();
                });
            });
    }

    checkRecap(): void {
        this.recapYear = this.appFeaturesService.enabledAppFeatures().year_recap;
        if (this.recapYear) {
            const lastRecapYear = JSON.parse(localStorage.getItem(LocalStorageKey.lastRecapYear));
            if (lastRecapYear === this.recapYear) {
                this.canRecap = true;
                this.checkFirstTimeDashboard();
            } else {
                this.recapApiService
                    .getRecap({})
                    .then((res) => {
                        this.recap = res.data;
                        this.canRecap = true;

                        if (this._router.url.includes('dashboard')) {
                            this.showRecap(true);
                        } else {
                            this.checkFirstTimeDashboard();
                        }
                    })
                    .catch(() => {
                        this.recapApiService
                            .refreshRecap({ year: this.recapYear })
                            .then((res) => {
                                this.recap = res.data;
                                this.canRecap = true;
                                localStorage.setItem(LocalStorageKey.lastRecapYear, JSON.stringify(this.recapYear));
                                this.checkFirstTimeDashboard();
                            })
                            .catch(() => {
                                this.checkFirstTimeDashboard();
                            });
                    });
            }
        }
    }

    checkFirstTimeDashboard(): void {
        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeDashboard)) ?? true;
        if (showTour) {
            this.showTour();
        } else {
            this.joyrideDone();
        }
    }

    async showRecap(checkFirstTimeDashboardAfter = false): Promise<void> {
        if (!this.recap) {
            const recapResponse = await this.recapApiService.getRecap({});
            this.recap = recapResponse.data;
        }

        if (this.platform.is('capacitor')) {
            ScreenOrientation.lock({ orientation: 'portrait' });
        }

        this.modal
            .create({
                component: YearRecapDialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                componentProps: {
                    initialRecap: this.recap,
                },
            })
            .then((elem) => {
                elem.present().then(() => {
                    localStorage.setItem(LocalStorageKey.lastRecapYear, JSON.stringify(this.recapYear));
                });
                elem.onDidDismiss().then(() => {
                    if (this.platform.is('capacitor')) {
                        ScreenOrientation.unlock();
                    }

                    if (checkFirstTimeDashboardAfter) {
                        this.checkFirstTimeDashboard();
                    }
                });
            });
    }

    showFeatureSlides(feature: 'action_replays'): void {
        let title = '';
        let slides: FeatureSlide[] = [];

        switch (feature) {
            case 'action_replays':
                const name = $localize`:@@ACTION_REPLAYS:Action Replays`;
                $localize`:@@NEW_FEATURE_NAME:New ${name}:name: feature`;
                title = this.translate.instant('NEW_FEATURE_NAME', { name: name });

                slides = [
                    {
                        title: $localize`:@@ACTION_REPLAYS:Action Replays`,
                        desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_1:Save your turn as an Action Replay`,
                        image: 'assets/images/online-features/1-action-replays.png',
                    },
                    {
                        title: $localize`:@@ACTIVATE_YOUR_CAMERA:Activate your camera`,
                        desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_2:Use your camera in-game to save it as a replay`,
                        image: 'assets/images/online-features/2-action-replays.png',
                    },
                    {
                        title: $localize`:@@SAVE_AS_REPLAY:Save as Action Replay`,
                        desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_3:Whenever your turn has passed, you can save it as an Action Replay`,
                        image: 'assets/images/online-features/3-action-replays.png',
                    },
                    {
                        title: $localize`:@@VIEW_YOUR_REPLAYS:View your replays`,
                        desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_4:View, download and favourite your replays in the gallery`,
                        image: 'assets/images/online-features/4-action-replays.png',
                    },
                    {
                        title: $localize`:@@UPGRADE_FOR_REPLAYS:More features for Ultimates`,
                        desc: $localize`:@@NEW_ONLINE_ACTION_REPLAYS_PREVIEW_DESC_5:Ultimate users can favourite and save more Action Replays`,
                        image: 'assets/images/online-features/5-action-replays.png',
                    },
                ];
                break;
        }

        this.modal
            .create({
                component: FeatureSlidesDialogComponent,
                componentProps: {
                    title,
                    slides,
                },
                cssClass: 'auto-height',
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
            });
    }

    NavigateToOnline() {
        this.navCtrl.navigateForward('/online-games');
    }

    OpenFollowUsModal() {
        this.modal
            .create({
                component: FollowModalComponent,
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
            });
    }

    ionViewWillLeave(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // targetRelease() {
    //     let url = 'https://www.target-darts.co.uk/the-big-hitters-target-launch';
    //     switch (this.translate.currentLang) {
    //         case 'de':
    //             url = 'https://mcdart.de/target2024';
    //             break;
    //         case 'nl':
    //             url = 'https://www.dartshopper.nl/launches/target-launch/';
    //             break;
    //     }

    //     this.iab.create(url, '_system', {
    //         location: 'yes',
    //     } as InAppBrowserOptions);
    // }

    preorderOmni() {
        let url = 'https://www.target-darts.co.uk/omni-auto-scoring-system';
        switch (this.translate.currentLang) {
            case 'de':
                url = 'https://www.dartshopper.de/target-omni-scoring-system.html';
                break;
            case 'nl':
                url = 'https://www.dartshopper.nl/target-omni-scoring-system.html';
                break;
        }

        this.iab.create(url, '_system', {
            location: 'yes',
        } as InAppBrowserOptions);
    }

    open121Checkout() {
        this.navCtrl.navigateForward('/local-games/checkout-121');
    }
}
