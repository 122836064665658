/* eslint-disable max-len */
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { TournamentApiService } from '@dc-api/tournament.api.service';
import { Tournament, TournamentGame } from '@dc-core/dc-backend/dc-classes';
import { TournamentFinishGameEvent } from '@dc-core/dc-backend/dc-enums';
import { PlayerInterim } from '@dc-core/dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { DCFireStoreInvite, INVITATIONSTATUS } from '@dc-core/dc-firestore/globals/firestore.tables';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { AuthService } from '@services/auth.service';
import { ConfettiService } from '@services/confetti.service';
import { getDoc } from 'firebase/firestore';
import {
    TournamentNotificationDialogComponent,
    TournamentNotificationDialogPayload,
    TournamentNotificationType,
} from 'src/app/modules/tournaments/dialogs/tournament-notification-dialog/tournament-notification.dialog';
import { EchoService } from 'src/app/services/echo.service';
import { environment } from 'src/environments/environment';

import { GamesLeaveBusterService } from '../../games/services/games-leave-buster.service';
import { TournamentRequirementsCheckDialogComponent } from '../dialogs/tournament-requirements-check/tournament-requirements-check.dialog';
import { TournamentView } from '../pages/tournaments/view/tournament-view.component';

@Injectable()
export class TournamentService {
    public participatingTournament: WritableSignal<Tournament | null> = signal(null);
    public currentGameInvite: WritableSignal<DCFireStoreInvite> = signal(null);
    public updatedTournament: WritableSignal<Tournament> = signal(null);

    private authService: AuthService = inject(AuthService);
    private tournamentApiService: TournamentApiService = inject(TournamentApiService);
    private echoService: EchoService = inject(EchoService);
    private modalController: ModalController = inject(ModalController);
    private navController: NavController = inject(NavController);
    private inviteCollectionService: InviteCollectionService = inject(InviteCollectionService);
    private activeGamesCollectionService: ActiveGamesCollectionService = inject(ActiveGamesCollectionService);
    private leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);
    private usersCollectionService: UsersCollectionService = inject(UsersCollectionService);
    private confettiService: ConfettiService = inject(ConfettiService);

    public tournamentListeners = new Map<number, boolean>();
    public tournamentModals = new Map<number, { type: TournamentNotificationType; elem: HTMLIonModalElement }>();
    public invites: WritableSignal<Tournament[]> = signal([]);

    public startAllListeners(): void {
        this.getCurrentTournaments();

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.check-in.started',
            (data: { tournamentId: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        status: 'check_in_period',
                    };
                    this.updatedTournament.set({ ...tournament });
                    this.setParticipatingTournament(tournament);

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                type: 'check_in_started',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'check_in_started', elem);

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(tournament.id);

                                if (dialogRes.data) {
                                    this.goToTournament(tournament);
                                }
                            });
                        });
                }
            }
        );

        this.echoService.privateChannel(`user.${this.authService.user.id}`, 'tournament.pre-game.started', async () => {
            const participatingTournament = await this.getCurrentTournaments();
            if (participatingTournament) {
                this.updatedTournament.set({ ...participatingTournament });
            }
        });

        this.echoService.privateChannel(`user.${this.authService.user.id}`, 'tournament.participant.invited', () => {
            this.checkInvites();
        });

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.approved',
            (data: { tournamentId: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        participation: {
                            ...this.participatingTournament().participation,
                            approved_at: new Date().toISOString(),
                        },
                    };
                    this.updatedTournament.set({ ...tournament });
                    this.setParticipatingTournament(tournament);

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                type: 'approved',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'approved', elem);

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(tournament.id);

                                if (dialogRes.data) {
                                    this.goToTournament(tournament);
                                }
                            });
                        });
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.withdrawed',
            (data: { tournamentId: number }) => {
                if (data.tournamentId) {
                    this.clearListeners(data.tournamentId);
                    this.clearParticipatingTournament(data.tournamentId);
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.reported',
            (data: { tournamentId: number; participantId: number; gameId: number }) => {
                if (!this.leaveBusterService.inGame()) {
                    if (this.participatingTournament()?.id === data.tournamentId) {
                        const tournament: Tournament = {
                            ...this.participatingTournament(),
                        };

                        if (tournament.participants) {
                            this.modalController
                                .create({
                                    component: TournamentNotificationDialogComponent,
                                    componentProps: {
                                        tournament,
                                        participant: tournament.participants.find(
                                            (participant) => participant.id == data.participantId
                                        ),
                                        game: tournament.games.find((game) => game.id == data.gameId),
                                        type: 'reported',
                                    } as TournamentNotificationDialogPayload,
                                    cssClass: 'auto-height',
                                    showBackdrop: true,
                                    backdropDismiss: true,
                                })
                                .then((elem) => {
                                    this.checkNewDialogAndClearOld(tournament.id, 'reported', elem);

                                    elem.present();
                                    elem.onDidDismiss().then((dialogRes) => {
                                        this.tournamentModals.delete(tournament.id);

                                        if (dialogRes.data) {
                                            this.goToTournament(tournament, true, 'activity');
                                        }
                                    });
                                });
                        }
                    }
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.disqualified',
            (data: { tournamentId: number }) => {
                this.leaveBusterService.allowedToLeave = true;

                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        participation: {
                            ...this.participatingTournament().participation,
                            disqualified_at: new Date().toISOString(),
                            result: 'lost',
                        },
                    };
                    this.updatedTournament.set({ ...tournament });
                    this.setParticipatingTournament(tournament);

                    this.goToTournament(tournament, false);

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                type: 'disqualified',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'disqualified', elem);

                            elem.present();
                            elem.onDidDismiss().then(() => {
                                this.tournamentModals.delete(tournament.id);
                            });
                        });

                    this.clearListeners(tournament.id);
                    this.clearParticipatingTournament(tournament.id);
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.game.won-by-event',
            (data: { tournamentId: number; finishGameEvent: TournamentFinishGameEvent }) => {
                this.leaveBusterService.allowedToLeave = true;

                if (data.tournamentId) {
                    this.tournamentApiService
                        .showTournamentByIdOrSlug({ tournamentId: data.tournamentId })
                        .then((res) => {
                            const tournament: Tournament = res.data;

                            if (data.finishGameEvent === 'disqualified') {
                                this.goToTournament(tournament, false);
                            }

                            this.modalController
                                .create({
                                    component: TournamentNotificationDialogComponent,
                                    componentProps: {
                                        tournament,
                                        type: 'game_won_by_event',
                                        finishGameEvent: data.finishGameEvent,
                                    } as TournamentNotificationDialogPayload,
                                    cssClass: 'auto-height',
                                    showBackdrop: true,
                                    backdropDismiss: true,
                                })
                                .then((elem) => {
                                    this.checkNewDialogAndClearOld(tournament.id, 'game_won_by_event', elem);

                                    elem.present();
                                    elem.onDidDismiss().then((dialogRes) => {
                                        this.tournamentModals.delete(tournament.id);

                                        if (dialogRes.data && data.finishGameEvent !== 'disqualified') {
                                            this.goToTournament(tournament);
                                        }
                                    });
                                });
                        });
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.lost',
            (data: { tournamentId: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                    };

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                participant: tournament.participation,
                                type: 'lost',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'lost', elem);

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(tournament.id);

                                if (dialogRes.data) {
                                    this.goToTournament(tournament);
                                }
                            });
                        });

                    this.clearParticipatingTournament(tournament.id);
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.won',
            (data: { tournamentId: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                    };

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                type: 'won',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'won', elem);

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(tournament.id);

                                if (dialogRes.data) {
                                    this.goToTournament(tournament);
                                }
                            });
                        });

                    this.confettiService.showStarAndBigBang();

                    this.clearParticipatingTournament(tournament.id);
                }
            }
        );
    }

    public clearAllListeners(): void {
        this.tournamentListeners.forEach((_, id) => {
            this.clearListeners(id);
        });
        this.tournamentListeners.clear();

        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.check-in.started');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.pre-game.started');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.invited');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.approved');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.withdrawed');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.reported');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.disqualified');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.game.won-by-event');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.lost');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.won');
    }

    public getCurrentTournaments(searchTournamentId: number = null): Promise<Tournament> {
        return new Promise((resolve) => {
            this.tournamentApiService
                .getParticipatingTournaments({})
                .then((res) => {
                    let participating = res.data.current;

                    const invites: Tournament[] = [];
                    res.data.tournaments.forEach((tournament) => {
                        if (searchTournamentId == tournament.id) {
                            participating = tournament;
                        }

                        if (tournament.participation.invited_for_admin_at && !tournament.participation.is_admin) {
                            invites.push(tournament);
                        }

                        if (tournament.participation.joined_at) {
                            this.startListeners(tournament);
                        }
                    });

                    this.invites.set(invites);
                    this.participatingTournament.set(res.data.current ? { ...res.data.current } : null);
                    resolve(participating);
                })
                .catch(() => resolve(null));
        });
    }

    public startListeners(tournament: Tournament): void {
        if (!this.tournamentListeners.has(tournament.id)) {
            this.tournamentListeners.set(tournament.id, true);

            this.startTournamentPrivateChannels(tournament);
        }
    }

    public clearListeners(tournamentId: number): void {
        if (this.tournamentListeners.has(tournamentId)) {
            this.echoService.leave(`tournament.${tournamentId}`);
            this.tournamentListeners.delete(tournamentId);
        }
    }

    public setNewParticipatingTournament(tournament: Tournament, force = false): void {
        this.startListeners(tournament);
        const participatingTournament = this.participatingTournament();
        if (
            force ||
            !participatingTournament ||
            participatingTournament.id === tournament.id ||
            tournament.starting_at <= participatingTournament.starting_at
        ) {
            this.participatingTournament.set({ ...tournament });
        }
    }

    public showPreGameInvite(invite: DCFireStoreInvite): void {
        this.modalController
            .create({
                component: TournamentNotificationDialogComponent,
                componentProps: {
                    tournament: invite.tournament_game.tournament,
                    game: invite.tournament_game,
                    type: 'game_is_starting',
                } as TournamentNotificationDialogPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: true,
            })
            .then((elem) => {
                this.checkNewDialogAndClearOld(invite.tournament_game.tournament_id, 'game_is_starting', elem);

                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    this.tournamentModals.delete(invite.tournament_game.tournament_id);

                    if (dialogRes.data) {
                        this.dismissOnlineEndScreenDialog();
                        this.checkRequirementsAndGoToPreGame(invite);
                    }
                });
            });
    }

    public checkRequirementsAndGoToPreGame(invite: DCFireStoreInvite): void {
        if (!invite) {
            invite = this.currentGameInvite();
        }

        this.checkTournamentRequirements(invite.tournament_game.tournament).then((checked) => {
            if (checked) {
                try {
                    const activeGameRef = this.activeGamesCollectionService.getDocByID(
                        invite.tournament_game.active_game_doc_id
                    );
                    getDoc(activeGameRef).then((docSnapshot) => {
                        const gameplay = docSnapshot.data();
                        if (gameplay) {
                            const players = gameplay.players;
                            players.forEach((player, index) => {
                                if (player.user_id === this.authService.user.id) {
                                    players[index] = this.usersCollectionService.getDCFireStoreUser(
                                        this.authService.user
                                    );
                                }
                            });
                            this.activeGamesCollectionService.updateOnlinePlayersByRef(activeGameRef, players);
                        }
                    });
                } catch (err) {
                    console.error(err);
                }

                this.currentGameInvite.set(null);

                this.activeGamesCollectionService.redirectBack = `/tournaments/${invite.tournament_game.tournament_id}`;

                this.inviteCollectionService.updateTournamentInviteStatus(invite.doc_ref, INVITATIONSTATUS.ACCEPTED);
                this.navController.navigateForward('pre-game', {
                    queryParams: {
                        doc_id: invite.tournament_game.active_game_doc_id,
                    },
                } as NavigationOptions);
            }
        });
    }

    public checkTournamentRequirements(tournament: Tournament): Promise<boolean> {
        return new Promise((resolve) => {
            this.modalController
                .create({
                    component: TournamentRequirementsCheckDialogComponent,
                    componentProps: {
                        tournament,
                    },
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                    showBackdrop: true,
                    backdropDismiss: false,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
                });
        });
    }

    public startRound(game: TournamentGame): void {
        this.tournamentApiService.startTournamentGame({
            tournamentId: game.tournament_id,
            gameId: game.id,
        });
    }

    public updateRound(game: TournamentGame, playerInterims: PlayerInterim[]): void {
        this.tournamentApiService.updateTournamentGame({
            tournamentId: game.tournament_id,
            gameId: game.id,
            player_interims: playerInterims,
        });
    }

    public finishGame(game: TournamentGame): Promise<void> {
        return new Promise<void>((resolve) => {
            this.tournamentApiService
                .finishTournamentGame({
                    tournamentId: game.tournament_id,
                    gameId: game.id,
                })
                .finally(() => resolve());
        });
    }

    public leaveGame(game: TournamentGame): Promise<void> {
        return new Promise<void>((resolve) => {
            this.tournamentApiService
                .leaveTournamentGame({
                    tournamentId: game.tournament_id,
                    gameId: game.id,
                })
                .finally(() => resolve());
        });
    }

    public quitGame(game: TournamentGame): Promise<void> {
        return new Promise<void>((resolve) => {
            this.tournamentApiService
                .quitTournamentGame({
                    tournamentId: game.tournament_id,
                    gameId: game.id,
                })
                .finally(() => resolve());
        });
    }

    public async removeNotRespondingUser(): Promise<void> {
        if (this.leaveBusterService.currentTournamentGame) {
            const tournament = { ...this.leaveBusterService.currentTournamentGame.tournament };
            await this.activeGamesCollectionService.removeNotRespondingUser();
            await this.finishGame(this.leaveBusterService.currentTournamentGame);

            this.modalController
                .create({
                    component: TournamentNotificationDialogComponent,
                    componentProps: {
                        tournament,
                        type: 'game_won_by_event',
                        finishGameEvent: 'not_responding',
                    } as TournamentNotificationDialogPayload,
                    cssClass: 'auto-height',
                    showBackdrop: true,
                    backdropDismiss: true,
                })
                .then((elem) => {
                    this.checkNewDialogAndClearOld(tournament.id, 'game_won_by_event', elem);

                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        this.tournamentModals.delete(tournament.id);

                        if (dialogRes.data) {
                            this.goToTournament(tournament);
                        }
                    });
                });
        }
    }

    public checkInvites(): void {
        this.tournamentApiService.getParticipatingTournaments({}).then((res) => {
            const invites: Tournament[] = [];
            res.data.tournaments.forEach((tournament) => {
                if (tournament.participation.invited_for_admin_at && !tournament.participation.is_admin) {
                    invites.push(tournament);
                }
            });
            this.invites.set(invites);
        });
    }

    private goToTournament(tournament: Tournament, forward = true, view: TournamentView = null): void {
        this.dismissOnlineEndScreenDialog();

        let queryParams = {};
        if (view) {
            queryParams = {
                view,
            };
        }

        if (forward) {
            this.navController.navigateForward(`tournaments/${tournament.slug}`, {
                queryParams,
            });
        } else {
            this.navController.navigateBack(`tournaments/${tournament.slug}`, {
                queryParams,
            });
        }
    }

    private dismissOnlineEndScreenDialog(): void {
        if (this.leaveBusterService.onlineEndScreenDialog) {
            this.leaveBusterService.onlineEndScreenDialog.dismiss();
            this.leaveBusterService.onlineEndScreenDialog = null;
        }
    }

    private setParticipatingTournament(tournament: Tournament, refresh = false): void {
        if (this.participatingTournament()?.id === tournament.id) {
            if (refresh) {
                this.tournamentApiService.showTournamentByIdOrSlug({ tournamentId: tournament.id }).then((res) => {
                    this.participatingTournament.set(res.data);
                });
            } else {
                this.participatingTournament.set(tournament ? { ...tournament } : null);
            }
        }
    }

    private clearParticipatingTournament(tournamentId: number): void {
        if (this.participatingTournament()?.id === tournamentId) {
            this.participatingTournament.set(null);
        }
    }

    private startTournamentPrivateChannels(tournament: Tournament): void {
        this.echoService.privateChannel(`tournament.${tournament.id}`, 'tournament.started', () => {
            if (this.participatingTournament()?.id === tournament.id) {
                const updatedTournament: Tournament = {
                    ...this.participatingTournament(),
                    started_at: new Date().toISOString(),
                    status: 'started',
                };
                this.setParticipatingTournament(updatedTournament);
            }
        });

        this.echoService.privateChannel(`tournament.${tournament.id}`, 'tournament.finished', () => {
            if (this.participatingTournament()?.id === tournament.id) {
                const updatedTournament: Tournament = {
                    ...this.participatingTournament(),
                    finished_at: new Date().toISOString(),
                    status: 'finished',
                };
                this.setParticipatingTournament(updatedTournament);
            }
        });
    }

    private checkNewDialogAndClearOld(
        tournamentId: number,
        type: TournamentNotificationType,
        elem: HTMLIonModalElement
    ): void {
        if (this.tournamentModals.has(tournamentId)) {
            this.tournamentModals.get(tournamentId).elem.dismiss();
        }

        this.tournamentModals.set(tournamentId, { type, elem });
    }
}
