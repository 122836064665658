import { ScoreTraining, ScoreTrainingUser } from 'dc-core/dc-backend/dc-classes';
import { TrainingMode } from 'dc-core/dc-backend/dc-enums';
import * as moment from 'moment';
import { LOCAL_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import { dateTimeFormat, TRAINING_MODES } from '../../settings.globals';
import { DartCounterGameSettings } from '../settings-classes';

export class DartCounterScoreTrainingSettings extends DartCounterGameSettings {
    public amountOfTurns: number = 10;
    public minAmountOfTurns = 1;
    public maxAmountOfTurns = 999;

    public trainingMode: TrainingMode;
    public trainingModes = TRAINING_MODES;

    constructor() {
        //Set the super-class settings
        super();
        this.storageKey = LOCAL_GAME_SETTINGS_STORAGE_KEYS.SCORE_TRAINING;

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        } else {
            this.setDefaults();
        }
    }

    private setDefaults() {
        this.amountOfTurns = 10;
        this.playerType = 'players';
        this.trainingMode = 'training';

        this.users = [];
        this.teams = [];
    }

    private setStoredSettings(storedSettings: DartCounterScoreTrainingSettings) {
        this.amountOfTurns = storedSettings.amountOfTurns;
        this.trainingMode = storedSettings.trainingMode ?? 'training';

        this.users = storedSettings.users;
        this.teams = storedSettings.teams;
    }

    changeTrainingMode(trainingMode: TrainingMode): void {
        this.trainingMode = trainingMode;
    }

    checkForErrors(): string[] {
        let errors = [];

        if (this.amountOfTurns < this.minAmountOfTurns) {
            this.amountOfTurns = this.minAmountOfTurns;
            errors.push($localize`:@@ERROR_MIN_AMOUNT_OF_TURNS:The minimum amount is turns is 1`);
        } else if (this.amountOfTurns > this.maxAmountOfTurns) {
            this.amountOfTurns = this.maxAmountOfTurns;
            errors.push($localize`:@@ERROR_MAX_AMOUNT_OF_TURNS:The maximum amount is turns is 999`);
        }

        return errors;
    }

    saveGameSettings() {
        let storageObject = {
            amountOfTurns: this.amountOfTurns,
            playerType: this.playerType,
            users: this.users,
            teams: this.teams,
        };
        localStorage.setItem(this.storageKey, JSON.stringify(storageObject));
    }

    mapToScoreTrainingObject(): ScoreTraining {
        const users: ScoreTrainingUser[] = [];
        this.users.forEach((user, index) => {
            users.push({
                id: index + 1,
                score_training_id: 0,
                name: user.id === null ? user.full_name : user.first_name,
                user_id: user.id,
                user,
                ghost_name: user.id === null ? user.full_name : null,
                is_verified: this.user.id === user.id ? true : false,
            });
        });

        return <ScoreTraining>{
            id: 1,
            amount_of_turns: this.amountOfTurns,
            training_mode: this.users.length > 1 ? this.trainingMode : 'training',
            started_at: moment().utc().format(dateTimeFormat),
            users: users,
            turns: [],
        };
    }
}
