import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class DashboardApiService extends ApiService {
    // Subscriptions
    public dashboardFilteredSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/filtered')
        .method('post')
        .create();
    public dashboardTotalActiveSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/active/total')
        .method('get')
        .create();
    public dashboardActiveMonthlySubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/active/monthly')
        .method('get')
        .create();
    public dashboardActiveYearlySubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/active/yearly')
        .method('get')
        .create();
    public dashboardActiveYearSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/active/year')
        .method('get')
        .create();
    public dashboardActiveYearRangeSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/active/year/range')
        .method('get')
        .create();
    public dashboardActivePaymentProvidersSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/active/payment-providers')
        .method('get')
        .create();
    public dashboardNewSubscriptions = super.getFetcher().path('/dashboard/subscriptions/new').method('get').create();
    public dashboardNewPeriodicSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/new/periodic')
        .method('post')
        .create();
    public dashboardNewPaymentProvidersSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/new/payment-providers')
        .method('get')
        .create();
    public dashboardNewYearSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/new/year')
        .method('get')
        .create();
    public dashboardNewYearRangeSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/new/year/range')
        .method('get')
        .create();
    public dashboardYearSubscriptions = super.getFetcher().path('/dashboard/subscriptions/year').method('get').create();
    public dashboardAmountPeriodicSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/amount/periodic')
        .method('get')
        .create();
    public dashboardAmountYearSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/amount/year')
        .method('get')
        .create();
    public dashboardChurnRateSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/churn-rate')
        .method('get')
        .create();
    public dashboardYearOverviewSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/year-overview')
        .method('get')
        .create();
    public dashboardYearOverviewMonthlySubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/year-overview/monthly')
        .method('get')
        .create();
    public dashboardYearOverviewYearlySubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/year-overview/yearly')
        .method('get')
        .create();
    public dashboardPaymentProviderOverviewSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/payment-provider-overview')
        .method('get')
        .create();
    public dashboardPaymentProviderDayOverviewSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/payment-provider-day-overview')
        .method('get')
        .create();
    public dashboardTopCountriesSubscriptions = super
        .getFetcher()
        .path('/dashboard/subscriptions/top-countries')
        .method('get')
        .create();
    public dashboardCancelReasons = super
        .getFetcher()
        .path('/dashboard/subscriptions/cancel-reasons')
        .method('get')
        .create();
    public dashboardCancelReasonAnswers = super
        .getFetcher()
        .path('/dashboard/subscriptions/cancel-reason-answers')
        .method('get')
        .create();
    public dashboardChartPresets = super.getFetcher().path('/dashboard/subscriptions/presets').method('get').create();
    public dashboardStoreChartPreset = super
        .getFetcher()
        .path('/dashboard/subscriptions/presets')
        .method('post')
        .create();
    public dashboardDeleteChartPreset = super
        .getFetcher()
        .path('/dashboard/subscriptions/presets/{presetId}')
        .method('delete')
        .create();

    // Forecast
    public dashboardForecast = super.getFetcher().path('/dashboard/forecast').method('get').create();

    // Global Statistics
    public getGlobalStatisticsCategories = super
        .getFetcher()
        .path('/dashboard/global-statistics/categories')
        .method('get')
        .create();
    public storeGlobalStatisticsCategory = super
        .getFetcher()
        .path('/dashboard/global-statistics/categories')
        .method('post')
        .create();
    public showGlobalStatisticsCategory = super
        .getFetcher()
        .path('/dashboard/global-statistics/categories/{categoryId}')
        .method('get')
        .create();
    public updateGlobalStatisticsCategory = super
        .getFetcher()
        .path('/dashboard/global-statistics/categories/{categoryId}')
        .method('put')
        .create();
    public deleteGlobalStatisticsCategory = super
        .getFetcher()
        .path('/dashboard/global-statistics/categories/{categoryId}')
        .method('delete')
        .create();
    public storeGlobalStatisticsCategoryField = super
        .getFetcher()
        .path('/dashboard/global-statistics/category-fields')
        .method('post')
        .create();
    public updateGlobalStatisticsCategoryField = super
        .getFetcher()
        .path('/dashboard/global-statistics/category-fields/{fieldId}')
        .method('put')
        .create();
    public deleteGlobalStatisticsCategoryField = super
        .getFetcher()
        .path('/dashboard/global-statistics/category-fields/{fieldId}')
        .method('delete')
        .create();
    public getGlobalStatisticsData = super
        .getFetcher()
        .path('/dashboard/global-statistics/data')
        .method('get')
        .create();
    public storeGlobalStatisticsFieldData = super
        .getFetcher()
        .path('/dashboard/global-statistics/data')
        .method('post')
        .create();
    public updateGlobalStatisticsFieldData = super
        .getFetcher()
        .path('/dashboard/global-statistics/data/{fieldDataId}')
        .method('put')
        .create();
    public getFinanceIncomeData = super.getFetcher().path('/dashboard/finance/income').method('get').create();
    public storeFinanceIncomeData = super.getFetcher().path('/dashboard/finance/income').method('post').create();
    public updateFinanceIncomeData = super
        .getFetcher()
        .path('/dashboard/finance/income/{dataId}')
        .method('put')
        .create();

    // Finance
    public financeIncomeRevenueByMonthYear = super
        .getFetcher()
        .path('/dashboard/finance/income/revenue-by-month-year')
        .method('get')
        .create();
    public financeIncomeForecastAndRevenue = super
        .getFetcher()
        .path('/dashboard/finance/income/forecast-and-revenue')
        .method('get')
        .create();
    public financeIncomeRevenueByYear = super
        .getFetcher()
        .path('/dashboard/finance/income/revenue-by-year')
        .method('get')
        .create();
}
