import { User } from 'dc-core/dc-backend/dc-classes';
import { GameStatistics, OverallStatDetails, StatDetailType } from '../game-statistics.classes';
import { CricketTacticsGameSetting } from '@dc-core/dc-backend/dc-enums';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';

export interface CricketTacticsOverallStatsData {
    marksPerRound: { average?: number; best?: number; worst?: number };
    totalScore: { average?: number; best?: number; worst?: number };
}

export interface CricketTacticsStatDetails {
    gameSetting: CricketTacticsGameSetting;
}

export class CricketTacticsOverallStats extends GameStatistics {
    public gameSetting: CricketTacticsGameSetting = 'all';

    public overallStats: CricketTacticsOverallStatsData = {
        marksPerRound: {},
        totalScore: {},
    } as any;

    public loadedOverallStats: boolean = false;
    public gameStatisticsApiService: GameStatisticsApiService;

    private _localStorageKey = 'cricketTacticsOverallStats';

    constructor(user: User, gameStatisticsApiService: GameStatisticsApiService) {
        super(user);
        this.gameStatisticsApiService = gameStatisticsApiService;
    }

    setFilters(): void {
        let savedFilters = JSON.parse(localStorage.getItem(this._localStorageKey));
        if (savedFilters) {
            this.gameSetting = savedFilters.gameSetting ?? this.gameSetting;
        }
        super.setFilters();
    }

    saveFilters() {
        localStorage.setItem(
            this._localStorageKey,
            JSON.stringify({
                gameSetting: this.gameSetting,
                statisticsGameType: this.statisticsGameType,
                statisticsRange: this.statisticsRange,
                year: this.year,
                month: this.month,
            })
        );
        super.saveFilters();
    }

    async loadOverallStats(): Promise<void> {
        this.loadedOverallStats = false;
        await this.initMarksPerRound();
        if (this.user.is_ultimate) {
            await this.initTotalScore();
        }
        this.loadedOverallStats = true;
        this.saveFilters();
    }

    async initMarksPerRound(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getCricketTacticsMarksPerRoundStatistics({
                        type,
                        game_setting: this.gameSetting,
                        is_online: this.getIsOnline(),
                        vs_cpu: this.getVsCPU(),
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.marksPerRound[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initTotalScore(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.gameStatisticsApiService
                    .getCricketTacticsTotalScoreStatistics({
                        type,
                        game_setting: this.gameSetting,
                        is_online: this.getIsOnline(),
                        vs_cpu: this.getVsCPU(),
                        limit: 1,
                        from_date: this.fromDate,
                        to_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.totalScore[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }
}
