import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class StatisticsApiService extends ApiService {
    // GET Requests
    public getProfileStatistics = super.getFetcher().path('/statistics/profile').method('get').create();
    public getOverallStatistics = super.getFetcher().path('/statistics/overall').method('get').create();
    public getOnlineStatistics = super.getFetcher().path('/statistics/online').method('get').create();
    public getLastTenOnlineGamesStatistics = super
        .getFetcher()
        .path('/statistics/last-ten-online-games')
        .method('get')
        .create();
    public getLegsAmountStatistics = super.getFetcher().path('/statistics/legs/amount').method('get').create();
    public getLegsAmountThrowingFirstStatistics = super
        .getFetcher()
        .path('/statistics/legs/amount/throwing-first')
        .method('get')
        .create();
    public getLegsAmountThrowingAgainstStatistics = super
        .getFetcher()
        .path('/statistics/legs/amount/throwing-against')
        .method('get')
        .create();
    public getLegsAmountUnderPressureStatistics = super
        .getFetcher()
        .path('/statistics/legs/amount/under-pressure')
        .method('get')
        .create();
    public getScoringStatistics = super.getFetcher().path('/statistics/501').method('get').create();

    // POST Requests
    public compareStatistics = super.getFetcher().path('/statistics/compare').method('post').create();
    public resetStatistics = super.getFetcher().path('/statistics/reset').method('post').create();
}
