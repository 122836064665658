import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';

export const scoreTrainingGameRoutes: Route[] = [
    {
        path: 'game/score-training',
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/score-training-local-game/score-training-local-game.component').then(
                (m) => m.ScoreTrainingLocalGameComponent
            ),
    },
];
