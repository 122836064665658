<ion-content fullscreen class="bg-white-pattern text-black">
    <div class="flex flex-col items-center justify-center h-full py-0 px-4 m-0 text-center">
        <img style="width: 50%" src="assets/icon/dctarget_stacked_orange.png" />
        <h1
            class="font-black font-bebas-neue uppercase"
            style="font-size: 4em; margin: 2rem 0 1rem"
            [translate]="'BANNED'"
            i18n="@@BANNED">
            Banned
        </h1>

        <span *ngIf="auth.user.banned_until">
            <span [translate]="'UNTIL'" i18n="@@UNTIL">Until</span>:
            {{ auth.user.banned_until | date: 'dd MMM yyyy HH:mm' : '' : translateService.currentLang }}
        </span>
        <span *ngIf="!auth.user.banned_until" [translate]="'PERMANENT_BAN'" i18n="@@PERMANENT_BAN">
            Permanent ban
        </span>

        @if (auth.user.ban_reason) {
            <span class="mt-8">
                <span [translate]="'REASON'" i18n="@@REASON">Reason</span>:
                {{ auth.user.ban_reason }}
            </span>
        }
    </div>
</ion-content>
