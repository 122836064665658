import { Injectable, signal, WritableSignal } from '@angular/core';

import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable()
export class PrivateChatApiService extends ApiService {
    public unreadCount: WritableSignal<number> = signal(0);
    public refreshChat$: Subject<void> = new Subject();

    public getPrivateChats = super.getFetcher().path('/private-chats').method('get').create();
    public createPrivateChat = super.getFetcher().path('/private-chats').method('post').create();
    public showPrivateChat = super.getFetcher().path('/private-chats/{conversationId}').method('get').create();
    public deletePrivateChat = super.getFetcher().path('/private-chats/{conversationId}').method('delete').create();
    public sendPrivateChatMessage = super
        .getFetcher()
        .path('/private-chats/{conversationId}/send-message')
        .method('post')
        .create();
    public showPrivateChatMessage = super
        .getFetcher()
        .path('/private-chats/{conversationId}/message/{messageId}')
        .method('get')
        .create();
    public getUnreadPrivateChatCount = super.getFetcher().path('/private-chats/unread-count').method('get').create();
    public markPrivateChatAsRead = super
        .getFetcher()
        .path('/private-chats/{conversationId}/mark-as-read')
        .method('post')
        .create();

    setUnreadCount(): void {
        this.getUnreadPrivateChatCount({})
            .then((res) => {
                this.unreadCount.set(res.data.unread_count);
            })
            .catch(console.error);
    }
}
