import { Injectable } from '@angular/core';
import {
    CollectionReference,
    DocumentReference,
    Firestore,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    QuerySnapshot,
    setDoc,
    Timestamp,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { User } from 'dc-core/dc-backend/dc-classes';
import { doc, DocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { BehaviorSubject, interval, Observable, switchMap, takeWhile } from 'rxjs';
import { ONLINEGAMESTATUS } from '../../dc-backend/dc-interfaces';
import { OwnJanusService } from '../../dc-services/dc-janus/own-janus.service';
import { FireStoreAuthService } from '../firestore-auth.service';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { COUNTRIES } from '../globals/countries.globals';
import {
    DCFireStoreUser,
    FIRESTORE_COLLECTION,
    FireStoreOnlineVersion,
    ONLINESTATUS,
} from '../globals/firestore.tables';

@Injectable()
export class UsersCollectionService extends FireStoreCollectionsService {
    private defaultClass = new DCFireStoreUser();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.USERS;
    private firestore_collection: CollectionReference<DCFireStoreUser>;

    get isLoggedIn$() {
        return this.authState.asObservable();
    }
    get currentStatus(): ONLINESTATUS {
        return this._currentStatus;
    }
    private authState = new BehaviorSubject<boolean>(false);

    private _currentStatus: ONLINESTATUS = ONLINESTATUS.ONLINE;
    private _isActive: boolean = false;
    public activeUserDocRef: DocumentReference<DCFireStoreUser>;

    constructor(
        firestore: Firestore,
        private _dcFireAuth: FireStoreAuthService,
        private _omniIngameService: OmniIngameService,
        private _ownJanusService: OwnJanusService
    ) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<DCFireStoreUser>(this.collection_name);
    }

    userIsOnline(): void {
        this._isActive = true;
        this.updateLastActive();

        interval(280 * 1000) // update every approx 5 minutes
            .pipe(
                takeWhile(() => this._isActive),
                switchMap(() => {
                    return this.updateLastActive();
                })
            )
            .subscribe();
    }

    // Call this when user logs out or app stops
    userIsOffline(): void {
        this._isActive = false;
    }

    getFlag(abbr: string): string {
        let flag = null;
        if (abbr) {
            const country = _.find(COUNTRIES, (country) => country.alpha2 === abbr);
            if (country) {
                flag = country.flag;
            }
        }

        return flag;
    }

    getDCFireStoreUser(user: User): DCFireStoreUser {
        return <DCFireStoreUser>{
            uid: this._dcFireAuth.getCurrentUID(),
            room: this._ownJanusService.ownCamera?.roomID ? this._ownJanusService.ownCamera : null,
            has_omni: this._omniIngameService.omniConnected(),
            user_id: user.id,
            first_name: user.first_name || null,
            full_name: user.full_name || `${user.first_name} ${user.last_name}`,
            last_name: user.last_name || null,
            nickname: user.profile?.nickname || null,
            countryFlag: this.getFlag(user.profile?.country),
            profile_photo_url: user.profile?.profile_photo_url || null,
            last_results: user.last_results || [],
            two_month_average: user.two_month_average || null,
            sound: user.is_ultimate ? user.profile?.sound?.file || null : null,
            has_checkout_rate: user.profile?.has_checkout_rate,
            badge: user.profile?.badge || null,
            rating: user.rating || null,
            times_rated: user.times_rated || 0,
            is_ultimate: user.is_ultimate || null,
            version: FireStoreOnlineVersion,
            team: user.profile?.team,
            division: user.profile?.division,
            darts: user.profile?.darts,
            league: user.profile?.league,
        };
    }

    userStartedApp(user: User) {
        let fsUser = this.getDCFireStoreUser(user);
        this.activeUserDocRef = this.getDocByID(fsUser.uid);

        getDoc(this.activeUserDocRef).then((docSnapshot: DocumentSnapshot<DCFireStoreUser>) => {
            if (!docSnapshot.exists()) {
                fsUser.last_active = Timestamp.now();
                fsUser.status = ONLINESTATUS.IN_APP;
                fsUser.version = FireStoreOnlineVersion;

                this.addItem(fsUser);
            } else {
                this.updateUserStatus(ONLINESTATUS.IN_APP);
                this.authState.next(true);
                this.userIsOnline();
            }
        });
    }

    watchOwnUserDocRef(): Observable<DCFireStoreUser> {
        return new Observable<DCFireStoreUser>((observer) => {
            try {
                let previousActiveGameRef = null;

                const unsubscribe = onSnapshot(this.activeUserDocRef, async (docSnapshot) => {
                    const newData = docSnapshot.data();

                    if (newData && newData.activeGameRef !== previousActiveGameRef) {
                        // Handle the change
                        previousActiveGameRef = newData.activeGameRef;

                        if (newData.activeGameRef) {
                            // Check if the activeGame is still a valid one
                            let data = (await getDoc(newData.activeGameRef)).data();
                            if (
                                data &&
                                data.game.legs != null &&
                                data.onlineGameStatus != ONLINEGAMESTATUS.INTERRUPTED &&
                                data.onlineGameStatus != ONLINEGAMESTATUS.REMOVED &&
                                data.onlineGameStatus != ONLINEGAMESTATUS.FINISHED &&
                                data.onlineGameStatus != ONLINEGAMESTATUS.SAVED
                            ) {
                                observer.next(newData);
                            } else {
                                updateDoc(this.activeUserDocRef, {
                                    ...(<DCFireStoreUser>{
                                        activeGameRef: null,
                                    }),
                                });
                            }
                        } else {
                            observer.next(newData);
                        }
                    }
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    // Update the status if its new
    updateUserStatus(status: ONLINESTATUS) {
        if (this._currentStatus != status && this.activeUserDocRef) {
            this._currentStatus = status;

            let fsUser = <DCFireStoreUser>{
                status: this._currentStatus,
                version: FireStoreOnlineVersion,
            };

            if (status == ONLINESTATUS.OFFLINE) {
                this.userIsOffline(); // Stop updating the 'last_active'
            }

            this.updateItem(this.activeUserDocRef, fsUser);
        }
    }

    setOffline() {
        if (this.activeUserDocRef) {
            let fsUser = <DCFireStoreUser>{
                status: ONLINESTATUS.OFFLINE,
            };

            this.updateItem(this.activeUserDocRef, fsUser);
        }
    }

    // Update the last active if the user is in the app
    updateLastActive(): Promise<void> {
        if (this.activeUserDocRef) {
            let fsUser = <DCFireStoreUser>{
                last_active: Timestamp.now(),
                status: this._currentStatus,
            };

            return this.updateItem(this.activeUserDocRef, fsUser);
        }
    }

    removeActiveGameRef(status: ONLINESTATUS) {
        this._currentStatus = status;

        updateDoc(this.activeUserDocRef, {
            ...(<DCFireStoreUser>{
                activeGameRef: null,
                status: status,
            }),
        });
    }

    getDocByID(id: string): DocumentReference<DCFireStoreUser> {
        return doc(this.firestore_collection, id);
    }

    async updateFSUser(user: User) {
        let fsUser = <DCFireStoreUser>{};

        fsUser.profile_photo_url = user.profile?.profile_photo_url;
        fsUser.badge = user.profile?.badge;
        fsUser.first_name = user.first_name;
        fsUser.last_name = user.last_name;
        fsUser.full_name = user.full_name;
        fsUser.is_ultimate = user.is_ultimate;
        fsUser.last_results = user.last_results;
        fsUser.two_month_average = user.two_month_average;
        fsUser.room = this._ownJanusService.ownCamera;
        fsUser.team = user.profile?.team;
        fsUser.division = user.profile?.division;
        fsUser.darts = user.profile?.darts;
        fsUser.league = user.profile?.league;

        this._currentStatus = ONLINESTATUS.ONLINE;
        fsUser.status = this._currentStatus;
        fsUser.last_active = Timestamp.now();

        this.updateItem(this.activeUserDocRef, fsUser);
    }

    addItem(newDoc: DCFireStoreUser) {
        setDoc(doc(this.firestore_collection, newDoc.uid), newDoc).then(() => {
            this.activeUserDocRef = this.getDocByID(newDoc.uid);
            this.authState.next(true);
        });
    }

    updateItem(ref: DocumentReference<DCFireStoreUser>, updatedValues: DCFireStoreUser): Promise<void> {
        return updateDoc(ref, { ...updatedValues });
    }

    async getOnlineUsersByStatuses(statuses?: ONLINESTATUS[]): Promise<DCFireStoreUser[]> {
        try {
            const whereClause = this.getAttributeString(this.defaultClass, (obj: DCFireStoreUser) => obj.status);
            const testQuery = query(this.firestore_collection, where(whereClause, 'in', statuses));

            try {
                const snapshot = await getDocs(testQuery);
                const users: DCFireStoreUser[] = [];
                snapshot.forEach((doc) => {
                    users.push(doc.data() as DCFireStoreUser);
                });
                return users;
            } catch (err) {
                throw err;
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // getOnlineUsersByIDsObservable(user_ids: number[]): Observable<DCFireStoreUser[]> {
    //     const whereClause = this.getAttributeString(this.defaultClass, (obj: DCFireStoreUser) => obj.user_id);
    //     return this.firestore.collection<DCFireStoreUser>('users',
    //              ref => ref.where(whereClause, 'in', user_ids))
    //              .valueChanges();
    // }

    async getOnlineUsersByIDs(user_ids?: number[]): Promise<DCFireStoreUser[]> {
        try {
            const whereClause = this.getAttributeString(this.defaultClass, (obj: DCFireStoreUser) => obj.user_id);
            const testQuery = query(this.firestore_collection, where(whereClause, 'in', user_ids));

            try {
                const snapshot = await getDocs(testQuery);
                const users: DCFireStoreUser[] = [];
                snapshot.forEach((doc) => {
                    users.push(doc.data() as DCFireStoreUser);
                });
                return users;
            } catch (err) {
                throw err;
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async getOnlineUserCountByStatuses(statuses?: ONLINESTATUS[]): Promise<number> {
        try {
            const whereClause = this.getAttributeString(this.defaultClass, (obj: DCFireStoreUser) => obj.status);
            const testQuery = query(this.firestore_collection, where(whereClause, 'in', statuses));

            try {
                const snapshot = await getDocs(testQuery);
                return snapshot.size;
            } catch (err) {
                throw err;
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    excludeOfflineUsers(): Promise<QuerySnapshot<DCFireStoreUser>> {
        try {
            const whereClause = this.getAttributeString(this.defaultClass, (obj: DCFireStoreUser) => obj.status);
            const testQuery = query(this.firestore_collection, where(whereClause, '!=', ONLINESTATUS.OFFLINE));

            return getDocs(testQuery);
        } catch (err) {
            console.error(err);
        }
    }
}
