import { Route } from '@angular/router';
import { DowntimeGuard } from 'src/guards/downtime.guard';

export const authenticationRoutes: Route[] = [
    {
        path: 'login',
        canActivate: [DowntimeGuard],
        loadComponent: () => import('./pages/login/login.component').then((c) => c.LoginComponent),
    },
    {
        path: 'register',
        canActivate: [DowntimeGuard],
        loadComponent: () => import('./pages/register/register.component').then((c) => c.RegisterComponent),
    },
    {
        path: 'forgot-password',
        canActivate: [DowntimeGuard],
        loadComponent: () =>
            import('./pages/forgot-password/forgot-password.component').then((c) => c.ForgotPasswordComponent),
    },
];
