@if (currentGame() && currentUser() && liveGameplay() && authenticatedUserUUID) {
    <app-page-header mainNavigation="custom" color="white" #header>
        <div
            left
            class="text-center font-open-sans text-sm font-bold text-black cursor-pointer"
            (click)="goToGlobalLobby()"
            [translate]="'QUIT'"
            i18n="@@QUIT">
            Quit
        </div>
        @if (titles) {
            <app-animated-title title [titles]="titles" class="text-black"></app-animated-title>
        }
        <div right class="right-page-header">
            <ng-content select="[header-options]"> </ng-content>
            @if (isWeb) {
                <app-fullscreen-icon></app-fullscreen-icon>
            }
        </div>
    </app-page-header>

    @if (isWeb && isExtraLargeDevice()) {
        <div class="flex h-full overflow-hidden bg-white-pattern">
            <div class="flex flex-col min-w-[20rem] h-full">
                @for (team of currentGame().teams; track $index) {
                    <div
                        class="relative flex flex-col overflow-hidden w-full h-full"
                        [class.opacity-90]="currentTeam() !== team"
                        [class.bg-white]="currentTeam() === team">
                        <div class="player-container-user-details">
                            <div class="details-wrapper" [class.current-turn]="currentTeam().id === team.id">
                                @for (user of team.users; track $index) {
                                    <div class="flex w-full items-center justify-center space-x-2">
                                        @if (user.id === currentStartingPlayerId()) {
                                            <div class="started-leg-indicator"></div>
                                        }

                                        <app-profile-photo
                                            class="h-fit cursor-pointer"
                                            [ultimate]="user.user?.is_ultimate"
                                            [badge]="user.user?.profile?.badge"
                                            [size]="28"
                                            [url]="user.user?.profile?.profile_photo_url"
                                            (click)="showPlayerInfo(user.user, authenticatedUserId)">
                                        </app-profile-photo>
                                        <div
                                            class="truncate font-bold text-black cursor-pointer"
                                            (click)="showPlayerInfo(user.user, authenticatedUserId)">
                                            {{ user.user?.first_name || ('NO_NAME' | translate) }}
                                        </div>

                                        @if (
                                            liveGameplay().type === 'match' || liveGameplay().type === 'cricket_tactics'
                                        ) {
                                            <div class="flex items-center font-bebas-neue">
                                                @if (currentGame().has_sets) {
                                                    <app-match-interim-score size="medium" type="sets">
                                                        {{ team.total_score_sets }}
                                                    </app-match-interim-score>
                                                }
                                                <app-match-interim-score
                                                    size="large"
                                                    type="legs"
                                                    [showLabel]="currentGame().has_sets">
                                                    {{ team.totalLegsWonThisSet }}
                                                </app-match-interim-score>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="player-container-stats landscape:overflow-auto relative">
                            @switch (liveGameplay().type) {
                                @case ('match') {
                                    <app-match-team-score
                                        class="w-full"
                                        [currentGame]="currentGame()"
                                        [team]="team"
                                        [currentTeam]="currentTeam()"
                                        [keyboard]="currentUser().user_id === authenticatedUserId ? keyboard() : null"
                                        [dualStream]="isDualStream() && currentUser().user_id !== authenticatedUserId"
                                        [teamSwiperStats]="teamSwiperStats()"
                                        [fullHeight]="false"></app-match-team-score>

                                    @if (authenticatedUserId === team.users[0].user_id) {
                                        <ng-container
                                            *ngTemplateOutlet="editScoresButton; context: { index: 1 }"></ng-container>
                                    }
                                }

                                @case ('cricket_tactics') {
                                    <app-cricket-tactics-team-score
                                        [team]="team"
                                        [currentScore]="
                                            currentTurn().addScore[team.id]
                                        "></app-cricket-tactics-team-score>
                                }
                            }
                        </div>
                    </div>
                }
                <div class="absolute z-10 flex flex-col items-center left-[20rem] top-1/2">
                    <div class="flex items-center font-bebas-neue"></div>

                    <!-- Spectators -->
                    <div
                        class="flex w-fit flex-col items-center rounded-sm bg-white text-black px-2 py-1 -ml-5 cursor-pointer"
                        (click)="showSpectators()">
                        <app-icon icon="visibility" size="medium"></app-icon>
                        <div class="text-center font-bebas-neue leading-none">
                            {{ spectators().length }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative flex-auto overflow-hidden w-full border-l-2 border-white">
                <div class="flex flex-col h-full">
                    <div
                        class="flex flex-none flex-col bg-neutral-100"
                        [class.!bg-orange]="currentUser().user_id === authenticatedUserId && !onlineCore.pause">
                        <div class="flex items-center justify-between text-black">
                            <app-ingame-turn-info
                                [omniActive]="hasOmni()"
                                [isOnline]="true"
                                [isPaused]="onlineCore.pause"
                                [myTurn]="currentUser().user_id === authenticatedUserId"
                                [playerCount]="currentGame().teams.length"
                                [playerName]="currentUser().user?.first_name"
                                class="flex items-center justify-between w-full"></app-ingame-turn-info>

                            <div class="flex justify-end space-x-2 mr-3 my-3">
                                @if (!onlineCore.pause) {
                                    <div
                                        class="w-max flex items-center space-x-0.5 rounded-sm bg-white px-2 py-1.5 pr-4 text-black"
                                        [class.border-2]="currentUser().user_id !== authenticatedUserId"
                                        [class.border-orange]="currentUser().user_id !== authenticatedUserId">
                                        <app-icon icon="timer"></app-icon>
                                        <div class="pt-0.5 font-bebas-neue text-lg leading-none">
                                            <strong class="text-xl leading-none">{{
                                                onlineCore.getTimerValue()
                                            }}</strong>
                                            s
                                        </div>
                                    </div>

                                    <!-- Pause button -->

                                    @if (currentUser().user_id === authenticatedUserId) {
                                        <div
                                            class="flex h-10 w-10 items-center justify-center rounded-sm bg-white cursor-pointer"
                                            (click)="togglePause$.next()">
                                            <app-icon icon="pause"></app-icon>
                                        </div>
                                    }
                                }
                            </div>
                        </div>

                        @if (currentUser().user_id === authenticatedUserId && !onlineCore.pause) {
                            <ng-container *ngTemplateOutlet="gameScoreInput"></ng-container>
                        }
                    </div>

                    @if (!onlineCore.pause) {
                        <div class="flex h-full w-full relative overflow-hidden">
                            @if (
                                keyboard() !== 'none' &&
                                (currentUser().user_id === authenticatedUserId ||
                                    (liveGameplay().type === 'cricket_tactics' &&
                                        currentUser().user_id !== authenticatedUserId))
                            ) {
                                <div [class]="hasBoardCameraStream() ? 'w-full border-r-2 border-white' : 'w-full'">
                                    <ng-container *ngTemplateOutlet="gameKeyboard"></ng-container>
                                </div>
                            }

                            @if (displayOmni() && currentUser().user_id !== authenticatedUserId) {
                                <div
                                    class="flex"
                                    [class]="
                                        hasBoardCameraStream() && !isDualStream()
                                            ? 'w-3/5 border-r-2 border-white'
                                            : isDualStream()
                                              ? 'w-2/5 border-r-2 border-white'
                                              : 'w-full'
                                    ">
                                    <app-omni-opponent-view
                                        [isLargeDevice]="isExtraLargeDevice()"
                                        class="h-full w-full"></app-omni-opponent-view>
                                </div>
                            }

                            <div
                                [class]="tabsVisible() ? 'flex flex-col h-full w-full overflow-hidden' : 'flex'"
                                [ngClass]="{
                                    'w-3/5':
                                        displayBoardCam() &&
                                        keyboard() !== 'none' &&
                                        currentUser().user_id === authenticatedUserId,
                                    'w-full':
                                        liveGameplay().type === 'match' &&
                                        ((currentUser().user_id === authenticatedUserId && keyboard() === 'none') ||
                                            currentUser().user_id !== authenticatedUserId),
                                    'w-2/3': displayBoardCam() && liveGameplay().type === 'cricket_tactics'
                                }">
                                @if (
                                    hasBoardCameraStream() && hasOmni() && currentUser().user_id !== authenticatedUserId
                                ) {
                                    <div class="flex h-16 min-h-16 bg-white bg-opacity-80">
                                        @if (hasBoardCameraStream()) {
                                            <button
                                                class="inline-flex basis-1/2 items-center justify-center"
                                                [class.inner-border-b-2-orange]="
                                                    activeTab() === 'auto_camera' || activeTab() === 'camera'
                                                "
                                                [class.opacity-60]="
                                                    activeTab() !== 'auto_camera' && activeTab() !== 'camera'
                                                "
                                                (click)="toggleTab('camera')">
                                                <div
                                                    class="font-bebas-neue text-xl text-black"
                                                    [translate]="'CAMERA'"
                                                    i18n="@@CAMERA">
                                                    Camera
                                                </div>
                                            </button>
                                        }
                                        <button
                                            class="inline-flex basis-1/2 items-center justify-center"
                                            [class.inner-border-b-2-orange]="
                                                activeTab() === 'auto_scores' || activeTab() === 'scores'
                                            "
                                            [class.opacity-60]="
                                                activeTab() !== 'auto_scores' && activeTab() !== 'scores'
                                            "
                                            (click)="toggleTab('scores')">
                                            <div
                                                class="font-bebas-neue text-xl text-black"
                                                [translate]="'LIVE_SCORES'"
                                                i18n="@@LIVE_SCORES">
                                                Live scores
                                            </div>
                                        </button>
                                    </div>
                                }

                                @if (displayLegScores()) {
                                    <div
                                        [class]="
                                            displayBoardCam()
                                                ? 'w-3/5 border-r-2 border-white'
                                                : 'w-full h-full border-white'
                                        "
                                        [ngClass]="{ 'border-l-2': displayOmni() }">
                                        <ng-container *ngTemplateOutlet="legScores"></ng-container>
                                    </div>
                                }

                                <div
                                    [class]="
                                        displayBoardCam() ? 'flex w-full h-full max-h-full overflow-hidden' : 'hidden'
                                    ">
                                    <app-games-online-board-camera
                                        [class]="hasBoardCameraStream() ? 'block h-full w-full' : ''"
                                        [style]="hasBoardCameraStream() ? 'max-height: 100%; display: flex;' : ''"
                                        [authenticatedUserId]="authenticatedUserId"
                                        [visible]="displayBoardCam()"
                                        [currentUserId]="currentUser().user_id"
                                        [players]="liveGameplay().players"
                                        [allowOwnCamView]="true"></app-games-online-board-camera>

                                    <app-games-online-player-camera
                                        [class]="
                                            displayPlayerCam() ? 'block overflow-hidden border-l-2 border-white' : ''
                                        "
                                        [style]="displayPlayerCam() ? 'aspect-ratio: 10 / 16' : ''"
                                        [ngStyle]="{ 'max-height.px': maxPlayerCamHeight }"
                                        [authenticatedUserId]="authenticatedUserId"
                                        [currentUserId]="currentUser().user_id"
                                        [visible]="displayPlayerCam()"
                                        [allowOwnCamView]="true">
                                    </app-games-online-player-camera>
                                </div>
                            </div>
                        </div>
                    }

                    <!-- Pause -->
                    <app-games-online-pause
                        [authenticatedUserUUID]="authenticatedUserUUID"
                        [currentUserId]="currentUser().user_id"
                        [liveGameplay]="liveGameplay()"
                        [pauseTrigger]="togglePause$"></app-games-online-pause>

                    <ng-container *ngTemplateOutlet="bottomLeft"></ng-container>

                    <!-- Own camera -->
                    <app-games-online-own-camera
                        [authenticatedUserId]="authenticatedUserId"></app-games-online-own-camera>
                </div>
            </div>
        </div>
    } @else {
        <div
            class="relative flex h-full overflow-hidden bg-white-pattern portrait:pb-safe flex-row portrait:flex-col"
            [class.landscape:px-safe]="
                !(isDualStream() && isLandscape && currentUser().user_id !== authenticatedUserId && !onlineCore.pause)
            "
            [class.bg-black-header]="
                isDualStream() && isLandscape && currentUser().user_id !== authenticatedUserId && !onlineCore.pause
            ">
            <!-- Top/left normal -->
            @if (
                !(isDualStream() && isLandscape && currentUser().user_id !== authenticatedUserId && !onlineCore.pause)
            ) {
                <div class="flex flex-col landscape:w-1/2">
                    <div class="relative flex flex-1 overflow-auto">
                        <!-- Players -->
                        <div
                            class="relative flex flex-1"
                            [ngClass]="{
                                'w-full flex-row': !(
                                    currentUser().user_id !== authenticatedUserId &&
                                    isDualStream() &&
                                    !onlineCore.pause
                                ),
                                'w-1/2 flex-col':
                                    currentUser().user_id !== authenticatedUserId && isDualStream() && !onlineCore.pause
                            }">
                            @for (team of currentGame().teams; track team.id; let teamIndex = $index) {
                                <div
                                    class="relative flex flex-col overflow-hidden"
                                    [ngClass]="{
                                        'w-full':
                                            currentUser().user_id !== authenticatedUserId &&
                                            isDualStream() &&
                                            !onlineCore.pause,
                                        'w-1/2': !(
                                            currentUser().user_id !== authenticatedUserId &&
                                            isDualStream() &&
                                            !onlineCore.pause
                                        )
                                    }"
                                    [class.opacity-80]="currentTeam() !== team"
                                    [class.bg-white]="currentTeam() === team">
                                    <div class="player-container-user-details">
                                        <div
                                            class="details-wrapper"
                                            [class.current-turn]="currentTeam().id === team.id">
                                            @for (user of team.users; track $index) {
                                                <div class="flex w-full items-center justify-center space-x-2">
                                                    @if (user.id === currentStartingPlayerId()) {
                                                        <div class="started-leg-indicator"></div>
                                                    }

                                                    <app-profile-photo
                                                        class="h-fit cursor-pointer"
                                                        [ultimate]="user.user?.is_ultimate"
                                                        [badge]="user.user?.profile?.badge"
                                                        [size]="28"
                                                        [url]="user.user?.profile?.profile_photo_url"
                                                        (click)="showPlayerInfo(user.user, authenticatedUserId)">
                                                    </app-profile-photo>
                                                    <div
                                                        class="truncate font-bold text-black cursor-pointer"
                                                        (click)="showPlayerInfo(user.user, authenticatedUserId)">
                                                        {{ user.user?.first_name || ('NO_NAME' | translate) }}
                                                    </div>

                                                    @if (
                                                        liveGameplay().type === 'match' ||
                                                        liveGameplay().type === 'cricket_tactics'
                                                    ) {
                                                        <div class="flex items-center font-bebas-neue">
                                                            @if (currentGame().has_sets) {
                                                                <app-match-interim-score size="medium" type="sets">
                                                                    {{ team.total_score_sets }}
                                                                </app-match-interim-score>
                                                            }
                                                            <app-match-interim-score
                                                                size="large"
                                                                type="legs"
                                                                [showLabel]="currentGame().has_sets">
                                                                {{ team.totalLegsWonThisSet }}
                                                            </app-match-interim-score>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="player-container-stats landscape:overflow-auto relative">
                                        @switch (liveGameplay().type) {
                                            @case ('match') {
                                                <app-match-team-score
                                                    class="w-full"
                                                    [currentGame]="currentGame()"
                                                    [team]="team"
                                                    [currentTeam]="currentTeam()"
                                                    [keyboard]="
                                                        currentUser().user_id === authenticatedUserId
                                                            ? keyboard()
                                                            : null
                                                    "
                                                    [dualStream]="
                                                        isDualStream() && currentUser().user_id !== authenticatedUserId
                                                    "
                                                    [teamSwiperStats]="teamSwiperStats()"
                                                    [fullHeight]="false"></app-match-team-score>

                                                @if (authenticatedUserId === team.users[0].user_id) {
                                                    <ng-container
                                                        *ngTemplateOutlet="
                                                            editScoresButton;
                                                            context: { index: teamIndex }
                                                        "></ng-container>
                                                }
                                            }

                                            @case ('cricket_tactics') {
                                                <app-cricket-tactics-team-score
                                                    [team]="team"
                                                    [currentScore]="
                                                        currentTurn().addScore[team.id]
                                                    "></app-cricket-tactics-team-score>
                                            }
                                        }
                                    </div>
                                </div>
                            }
                            <div
                                class="absolute z-10 flex flex-col items-center"
                                [class.player-container-virt-scores]="
                                    currentUser().user_id !== authenticatedUserId && isDualStream() && !onlineCore.pause
                                "
                                [class.player-container-normal-scores]="
                                    !(
                                        currentUser().user_id !== authenticatedUserId &&
                                        isDualStream() &&
                                        !onlineCore.pause
                                    )
                                ">
                                <div class="flex items-center font-bebas-neue"></div>

                                <!-- Spectators -->
                                <div
                                    class="mt-2 flex w-fit flex-col items-center rounded-sm bg-white px-2 py-1 cursor-pointer text-black"
                                    (click)="showSpectators()">
                                    <app-icon icon="visibility" size="medium"></app-icon>
                                    <div class="text-center font-bebas-neue leading-none">
                                        {{ spectators().length }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        @if (!onlineCore.pause) {
                            <!-- Player cam -->
                            <app-games-online-player-camera
                                [class]="
                                    currentUser().user_id !== authenticatedUserId && isDualStream() ? 'block w-1/2' : ''
                                "
                                [authenticatedUserId]="authenticatedUserId"
                                [currentUserId]="currentUser().user_id"></app-games-online-player-camera>
                        }
                    </div>
                    <div
                        class="flex flex-none flex-col bg-neutral-100"
                        [class.!bg-orange]="currentUser().user_id === authenticatedUserId && !onlineCore.pause">
                        <div class="flex items-center justify-between text-black">
                            <app-ingame-turn-info
                                [omniActive]="hasOmni()"
                                [isOnline]="true"
                                [isPaused]="onlineCore.pause"
                                [myTurn]="currentUser().user_id === authenticatedUserId"
                                [playerCount]="currentGame().teams.length"
                                [playerName]="currentUser().user?.first_name"
                                class="flex items-center justify-between w-full"></app-ingame-turn-info>

                            <div class="flex justify-end space-x-2 mr-3 my-3">
                                @if (!onlineCore.pause) {
                                    <div
                                        class="w-max flex items-center space-x-0.5 rounded-sm bg-white px-2 py-1.5 pr-4 text-black"
                                        [class.border-2]="currentUser().user_id !== authenticatedUserId"
                                        [class.border-orange]="currentUser().user_id !== authenticatedUserId">
                                        <app-icon icon="timer"></app-icon>
                                        <div class="pt-0.5 font-bebas-neue text-lg leading-none">
                                            <strong class="text-xl leading-none">{{
                                                onlineCore.getTimerValue()
                                            }}</strong>
                                            s
                                        </div>
                                    </div>

                                    <!-- Pause button -->

                                    @if (currentUser().user_id === authenticatedUserId) {
                                        <div
                                            class="flex h-10 w-10 items-center justify-center rounded-sm bg-white cursor-pointer"
                                            (click)="togglePause$.next()">
                                            <app-icon icon="pause" class="text-black"></app-icon>
                                        </div>
                                    }
                                }
                            </div>
                        </div>

                        @if (currentUser().user_id === authenticatedUserId && !onlineCore.pause) {
                            <ng-container *ngTemplateOutlet="gameScoreInput"></ng-container>
                        }
                    </div>
                </div>
            }

            <!-- Bottom/right normal -->
            @if (
                !(isDualStream() && isLandscape && currentUser().user_id !== authenticatedUserId && !onlineCore.pause)
            ) {
                <div
                    class="relative flex flex-auto flex-col overflow-hidden landscape:w-1/2 landscape:border-l-2 border-white">
                    @if (
                        (keyboard() !== 'none' && currentUser().user_id === authenticatedUserId && !onlineCore.pause) ||
                        (liveGameplay().type === 'cricket_tactics' &&
                            currentUser().user_id !== authenticatedUserId &&
                            !onlineCore.pause &&
                            !hasBoardCameraStream())
                    ) {
                        <div class="h-full">
                            <ng-container *ngTemplateOutlet="gameKeyboard"></ng-container>
                        </div>
                    } @else if (!onlineCore.pause && hasBoardCameraStream() && hasOmni()) {
                        <div class="flex h-16 min-h-16 bg-white bg-opacity-80 flex-none">
                            <button
                                class="inline-flex basis-1/2 items-center justify-center"
                                [class.inner-border-b-2-orange]="
                                    activeTab() === 'auto_camera' || activeTab() === 'camera'
                                "
                                [class.opacity-60]="activeTab() !== 'auto_camera' && activeTab() !== 'camera'"
                                (click)="toggleTab('camera')">
                                <div class="font-bebas-neue text-xl text-black" [translate]="'CAMERA'" i18n="@@CAMERA">
                                    Camera
                                </div>
                            </button>
                            <button
                                class="inline-flex basis-1/2 items-center justify-center"
                                [class.inner-border-b-2-orange]="activeTab() === 'omni_scoring'"
                                [class.opacity-60]="activeTab() !== 'omni_scoring'"
                                (click)="toggleTab('omni_scoring')">
                                <img class="h-5" [src]="deviceImages.omni_logo" alt="OMNI SCORING" />
                            </button>
                        </div>
                    }

                    @if (
                        liveGameplay().type === 'match' &&
                        currentUser().user_id !== authenticatedUserId &&
                        !onlineCore.pause &&
                        !hasBoardCameraStream() &&
                        !hasOmni()
                    ) {
                        <ng-container *ngTemplateOutlet="legScores"></ng-container>
                    }

                    @if (displayOmni() && !onlineCore.pause && currentUser().user_id !== authenticatedUserId) {
                        <div class="flex h-full overflow-hidden">
                            <app-omni-opponent-view class="h-full w-full"></app-omni-opponent-view>
                        </div>
                    }

                    <app-games-online-board-camera
                        [class]="
                            displayBoardCam() &&
                            (currentUser().user_id !== authenticatedUserId || (isWeb && keyboard() === 'none'))
                                ? 'h-full w-full flex max-h-full overflow-hidden'
                                : ''
                        "
                        [authenticatedUserId]="authenticatedUserId"
                        [visible]="activeTab() === 'camera' || !displayOmni()"
                        [currentUserId]="currentUser().user_id"
                        [players]="liveGameplay().players"
                        [allowOwnCamView]="isWeb && keyboard() === 'none'"></app-games-online-board-camera>

                    <!-- Pause -->
                    <app-games-online-pause
                        [authenticatedUserUUID]="authenticatedUserUUID"
                        [currentUserId]="currentUser().user_id"
                        [liveGameplay]="liveGameplay()"
                        [pauseTrigger]="togglePause$"></app-games-online-pause>

                    <ng-container *ngTemplateOutlet="bottomLeft"></ng-container>

                    <!-- Own camera -->
                    <app-games-online-own-camera
                        [authenticatedUserId]="authenticatedUserId"></app-games-online-own-camera>
                </div>
            }

            <!-- Landscape Virt -->
            @if (isDualStream() && isLandscape && currentUser().user_id !== authenticatedUserId && !onlineCore.pause) {
                <div class="static flex h-full w-full">
                    <div class="static flex-1 overflow-hidden">
                        <app-games-online-board-camera
                            [authenticatedUserId]="authenticatedUserId"
                            [currentUserId]="currentUser().user_id"
                            [players]="liveGameplay().players"></app-games-online-board-camera>
                    </div>
                    <div
                        class="relative flex-1 overflow-hidden border-l-2 border-white lg:flex-none"
                        style="aspect-ratio: 8 / 16">
                        <app-games-online-player-camera
                            [authenticatedUserId]="authenticatedUserId"
                            [currentUserId]="currentUser().user_id">
                        </app-games-online-player-camera>
                    </div>

                    <!-- Spectators -->
                    <div
                        class="absolute top-2 flex flex-col items-center cursor-pointer"
                        style="left: 50%; transform: translateX(-50%)"
                        (click)="showSpectators()">
                        <div class="mt-2 flex w-fit flex-col items-center rounded-sm bg-white text-black px-2 py-1">
                            <app-icon icon="visibility" size="medium"></app-icon>
                            <div
                                class="text-center font-bebas-neue leading-none"
                                [class.text-xl]="isExtraLargeDevice()">
                                {{ spectators().length }}
                            </div>
                        </div>
                    </div>

                    <!-- Players -->
                    <div
                        class="absolute bottom-2 flex flex-col items-center pb-safe"
                        style="left: 50%; transform: translateX(10%)">
                        <div class="flex flex-col bg-white-pattern text-black">
                            @for (team of currentGame().teams; track $index) {
                                <div
                                    class="relative grid grid-cols-11 items-center justify-between px-3 py-2"
                                    [class.opacity-80]="currentTeam() !== team"
                                    [class.bg-white]="currentTeam() === team">
                                    @for (user of team.users; track $index) {
                                        <div class="col-span-8 flex items-center justify-start space-x-2 pr-4">
                                            @if (user.id === currentStartingPlayerId()) {
                                                <div class="started-leg-indicator"></div>
                                            }

                                            <app-profile-photo
                                                class="h-fit cursor-pointer"
                                                [ultimate]="user.user?.is_ultimate"
                                                [badge]="user.user?.profile?.badge"
                                                [size]="isExtraLargeDevice() ? 32 : 20"
                                                [url]="user.user?.profile?.profile_photo_url"
                                                (click)="showPlayerInfo(user.user, authenticatedUserId)">
                                            </app-profile-photo>
                                            <div
                                                class="ml-1 truncate text-sm font-bold text-black sm:text-base cursor-pointer"
                                                [class.!text-lg]="isExtraLargeDevice()"
                                                [class.sm:text-sm]="isExtraLargeDevice() === false"
                                                (click)="showPlayerInfo(user.user, authenticatedUserId)">
                                                {{ user.user?.first_name || ('NO_NAME' | translate) }}
                                            </div>
                                        </div>
                                    }
                                    <div class="col-span-3 flex items-center justify-end space-x-2 font-bebas-neue">
                                        @if (
                                            liveGameplay().type === 'match' || liveGameplay().type === 'cricket_tactics'
                                        ) {
                                            <div class="flex items-center font-bebas-neue">
                                                @if (currentGame().has_sets) {
                                                    <app-match-interim-score size="mediumlg" type="sets">
                                                        {{ team.total_score_sets }}
                                                    </app-match-interim-score>
                                                }
                                                <app-match-interim-score
                                                    size="mediumxl"
                                                    type="legs"
                                                    [showLabel]="currentGame().has_sets">
                                                    {{ team.totalLegsWonThisSet }}
                                                </app-match-interim-score>
                                            </div>
                                        }

                                        <div class="flex items-center font-bebas-neue">
                                            @switch (liveGameplay().type) {
                                                @case ('match') {
                                                    <app-match-team-score
                                                        class="w-full"
                                                        [currentGame]="currentGame()"
                                                        [team]="team"
                                                        [currentTeam]="currentTeam()"
                                                        [keyboard]="
                                                            currentUser().user_id === authenticatedUserId
                                                                ? keyboard()
                                                                : null
                                                        "
                                                        [teamSwiperStats]="teamSwiperStats()"
                                                        [smallLayout]="isDualStream()"></app-match-team-score>
                                                }

                                                @case ('cricket_tactics') {
                                                    <app-cricket-tactics-team-score
                                                        [smallLayout]="isDualStream()"
                                                        [team]="team"></app-cricket-tactics-team-score>
                                                }
                                            }
                                        </div>
                                        <div
                                            class="small-player-container-stat"
                                            [class.!text-3xl]="isExtraLargeDevice()"
                                            [class.virt]="isExtraLargeDevice() === false"></div>
                                    </div>
                                    @if (currentTeam() === team) {
                                        <div
                                            class="absolute -right-1 flex translate-x-full items-center space-x-0.5 rounded-sm border-2 border-orange bg-black px-1 py-0.5 pr-3 text-white">
                                            <app-icon icon="timer" class="text-black"></app-icon>
                                            <div class="pt-0.5 font-bebas-neue leading-none">
                                                <strong
                                                    class="text-lg leading-none"
                                                    [class.!text-2xl]="isExtraLargeDevice()"
                                                    >{{ onlineCore.getTimerValue() }}</strong
                                                >s
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <ng-container *ngTemplateOutlet="bottomLeft"></ng-container>

                <!-- Own camera -->
                <app-games-online-own-camera [authenticatedUserId]="authenticatedUserId"></app-games-online-own-camera>
            }
        </div>
    }

    @if (voiceCallService.userMedia.hasAudio$ | async) {
        <audio [srcObject]="voiceCallService.userMedia.audioStream" autoplay></audio>
    }
}

<ng-template #legScores><ng-content select="[leg-scores]"></ng-content></ng-template>
<ng-template #gameScoreInput><ng-content select="[game-score-input]"></ng-content></ng-template>
<ng-template #gameKeyboard><ng-content select="[game-keyboard]"></ng-content></ng-template>
<ng-template #bottomLeft>
    <div class="absolute bottom-3 left-0 flex flex-col space-y-4">
        <!-- Action Replay -->
        <app-games-online-action-replay [authenticatedUserId]="authenticatedUserId"></app-games-online-action-replay>

        <!-- Chat -->
        <app-games-online-chat
            class="block ml-3"
            [authenticatedUserId]="authenticatedUserId"
            [authenticatedUserUUID]="authenticatedUserUUID"
            [liveGameplay]="liveGameplay()"></app-games-online-chat>
    </div>
</ng-template>

<ng-template #editScoresButton let-index="index">
    <div
        class="absolute z-10 inline-flex h-5 w-5 items-center justify-center rounded-sm bg-orange p-1 md:h-7 md:w-7 cursor-pointer"
        [class]="isWeb && isExtraLargeDevice() ? 'top-[10%]' : 'top-[20%]'"
        [class.left-4]="index === 0"
        [class.right-4]="index === 1"
        style="transform: translateY(-50%)"
        (click)="editScores.emit()">
        <app-icon icon="edit" class="!text-white" size="small"></app-icon>
    </div>
</ng-template>
