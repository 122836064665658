/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-guest-login-as-dialog',
    templateUrl: 'guest-login-as.dialog.html',
})
export class GuestLoginAsDialogComponent implements OnInit {
    user: User;
    foundOnly: boolean;
    guestText = '';

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute,
        public navParams: NavParams,
        private translate: TranslateService,
        private _alertService: DartCounterAlertService
    ) {
        this.user = this.navParams.get('user');
        this.foundOnly = this.navParams.get('foundOnly');

        if (this.foundOnly) {
            $localize`:@@LOGGED_IN_AS_FOUND_GUEST_USER:Guest account ${this.user.username}:NAME: has been found on your device. You will be logged in with the account that has been found.`;
            this.guestText = this.translate.instant('LOGGED_IN_AS_FOUND_GUEST_USER', { NAME: this.user.username });
        } else {
            $localize`:@@LOGGED_IN_AS_NEW_OR_FOUND_GUEST_USER:Guest account ${this.user.username}:NAME: has been found on your device. You can either log in with a new guest account, or continue with the account that has been found.`;
            this.guestText = this.translate.instant('LOGGED_IN_AS_NEW_OR_FOUND_GUEST_USER', {
                NAME: this.user.username,
            });
        }
    }

    ngOnInit(): void {
        this._alertService.createAlert({
            title: this.translate.instant('LOG_IN_AS_GUEST_USER'),
            text: this.guestText,
            showCloseButton: true,
            icon: this.foundOnly ? 'success' : 'info',
            timer: null,
            cancelButtonText: !this.foundOnly ? this.translate.instant('NEW_ACCOUNT') : null,
            confirmButtonText: !this.foundOnly
                ? this.translate.instant('FOUND_ACCOUNT')
                : this.translate.instant('CONTINUE'),
            onCancel: () => {
                this.view.dismiss('new');
            },
            onConfirm: () => {
                this.view.dismiss('found');
            },
        });
    }

    public dismiss(): void {
        this.view.dismiss(null);
    }
}
