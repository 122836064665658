import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class SubscriptionApiService extends ApiService {
    // GET Requests
    public getSubscriptions = super.getFetcher().path('/subscriptions').method('get').create();
    public getUserSubscriptions = super.getFetcher().path('/subscriptions/user').method('get').create();
    public getSubscriptionById = super.getFetcher().path('/subscriptions/{subscriptionId}').method('get').create();
    public storeSubscription = super.getFetcher().path('/subscriptions').method('post').create();
    public updateSubscriptionById = super.getFetcher().path('/subscriptions/{subscriptionId}').method('put').create();
    public cancelSubscriptionById = super
        .getFetcher()
        .path('/subscriptions/{subscriptionId}/cancel')
        .method('post')
        .create();
    public getSubscriptionCancelReasons = super
        .getFetcher()
        .path('/subscriptions/cancel-reasons')
        .method('get')
        .create();
    public getSubscriptionUpgradeReasons = super
        .getFetcher()
        .path('/subscriptions/upgrade-reasons')
        .method('get')
        .create();
    public sendSubscriptionUpgradeReasons = super
        .getFetcher()
        .path('/subscriptions/upgrade-reasons/send')
        .method('post')
        .create();
}
