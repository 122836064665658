import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';

export const doublesTrainingGameRoutes: Route[] = [
    {
        path: 'game/doubles-training',
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/doubles-training-local-game/doubles-training-local-game.component').then(
                (m) => m.DoublesTrainingLocalGameComponent
            ),
    },
];
