<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <div class="title-container">
            <div class="title" *ngIf="title">{{ title }}</div>
        </div>
        <div class="mt-8">
            <div class="flex h-full gap-2 portrait:flex-col portrait:sm:flex-row landscape:flex-row">
                <div
                    class="relative mx-auto basis-1/2 overflow-hidden"
                    *ngIf="ingameCameraService.userMediaMap.get(userId)?.videoStreams.board">
                    <video
                        *ngIf="ingameCameraService.userMediaMap.get(userId)?.videoStreams.board?.stream"
                        class="ingame-camera w-full"
                        [srcObject]="ingameCameraService.userMediaMap.get(userId)?.videoStreams.board.stream"
                        [style.transform]="ingameCameraService.userMediaMap.get(userId)?.videoStreams.board.scale"
                        autoplay
                        playsinline
                        webkit-playsinline
                        muted></video>
                    <div
                        class="absolute left-4 top-4 h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center">
                        <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                    </div>
                </div>
                <div
                    class="relative mx-auto basis-1/2 overflow-hidden"
                    [class.bg-gray-50]="videoRoomService.ownCamera.disablePlayerCamForAll === true"
                    *ngIf="ingameCameraService.userMediaMap.get(userId)?.videoStreams.player">
                    <img
                        class="absolute bottom-0 left-0 right-0 top-0 m-auto w-28 grayscale"
                        src="assets/icon/dctarget_stacked_white.png"
                        *ngIf="videoRoomService.ownCamera.disablePlayerCamForAll === true" />
                    <video
                        *ngIf="ingameCameraService.userMediaMap.get(userId)?.videoStreams.player?.stream"
                        class="ingame-camera w-full"
                        [class.opacity-0]="videoRoomService.ownCamera.disablePlayerCamForAll === true"
                        [srcObject]="ingameCameraService.userMediaMap.get(userId)?.videoStreams.player.stream"
                        autoplay
                        playsinline
                        webkit-playsinline
                        muted></video>
                    <div
                        class="absolute right-4 top-4 h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center">
                        <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="close()"
                [translate]="'CLOSE'"
                i18n="@@CLOSE">
                Close
            </app-primary-button>
        </div>
    </div>
</modal-content>
