<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'LINK_SOCIALS'" i18n="@@LINK_SOCIALS">Link socials</div>
    </div>
    <div class="slide-modal-content">
        @if (!auth.user.facebook_created_at) {
            @if (auth.user.facebook_linked_at) {
                <app-primary-button
                    size="normal"
                    addedClasses="w-full mb-2 !bg-[#324e8e]"
                    color="black"
                    (click)="unlinkFacebook()">
                    <ion-icon name="logo-facebook" class="mr-2"></ion-icon>
                    <span [translate]="'UNLINK_FACEBOOK'" i18n="@@UNLINK_FACEBOOK">Unlink Facebook</span>
                </app-primary-button>
            }
            @if (!auth.user.facebook_linked_at) {
                <app-primary-button
                    size="normal"
                    addedClasses="w-full mb-2 !bg-[#324e8e] !text-white"
                    color="black"
                    (click)="linkFacebook()">
                    <ion-icon name="logo-facebook" class="mr-2"></ion-icon>
                    <span [translate]="'LINK_FACEBOOK'" i18n="@@LINK_FACEBOOK">Link Facebook</span>
                </app-primary-button>
            }
        }
        @if (showAppleAuth) {
            @if (auth.user.apple_linked_at) {
                <app-primary-button size="normal" addedClasses="w-full mb-2" color="white" (click)="unlinkApple()">
                    <ion-icon name="logo-apple" class="mr-2"></ion-icon>
                    <span [translate]="'UNLINK_APPLE'" i18n="@@UNLINK_APPLE">Unlink Apple</span>
                </app-primary-button>
            }
            @if (!auth.user.apple_linked_at) {
                <app-primary-button size="normal" addedClasses="w-full mb-2" color="white" (click)="linkApple()">
                    <ion-icon name="logo-apple" class="mr-2"></ion-icon>
                    <span [translate]="'LINK_APPLE'" i18n="@@LINK_APPLE">Link Apple</span>
                </app-primary-button>
            }
        }
        @if (!auth.user.google_created_at) {
            @if (auth.user.google_linked_at) {
                <app-primary-button size="normal" addedClasses="w-full" color="black" (click)="unlinkGoogle()">
                    <ion-icon src="assets/icon/google.svg" class="mr-2"></ion-icon>
                    <span [translate]="'UNLINK_GOOGLE'" i18n="@@UNLINK_GOOGLE">Unlink Google</span>
                </app-primary-button>
            }
            @if (!auth.user.google_linked_at) {
                <app-primary-button size="normal" addedClasses="w-full" color="black" (click)="linkGoogle()">
                    <ion-icon src="assets/icon/google.svg" class="mr-2"></ion-icon>
                    <span [translate]="'LINK_GOOGLE'" i18n="@@LINK_GOOGLE">Link Google</span>
                </app-primary-button>
            }
        }
    </div>
</div>
