import { Pipe, PipeTransform } from '@angular/core';
import { TransactionStatus } from 'dc-core/dc-backend/dc-enums';

@Pipe({
    name: 'transactionStatus',
})
export class TransactionStatusPipe implements PipeTransform {
    transform(status: TransactionStatus): string {
        switch (status) {
            case 'pending': // Orange
                return $localize`:@@PENDING:Pending`;
            case 'paid': // Green
                return $localize`:@@PAID:Paid`;
            case 'cancelled': // Gray
                return $localize`:@@CANCELLED:Cancelled`;
            case 'chargeback': // Red
                return $localize`:@@CHARGEBACK:Chargeback`;
            case 'failed': // Red
                return $localize`:@@FAILED:Failed`;
            case 'expired': // Red
                return $localize`:@@EXPIRED:Expired`;
        }
    }
}
