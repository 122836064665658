import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-login-required-dialog',
  templateUrl: 'login-required.html',
  styleUrls: ['login-required.scss'],
})
export class LoginRequiredDialogComponent {
  showFB = false;

  constructor(public view: ModalController, private modal: ModalController, public navCtrl: NavController) {}

  public DismissModal(returnValue: any = null): void {
    this.modal.dismiss(returnValue);
  }

  GoToRegistration() {
    this.navCtrl.navigateForward('register');
    this.modal.dismiss();
  }
}
