/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddPlayerPayload, EditPlayerPayload, RemovePlayerPayload } from '@dc-core/dc-gamelogic/game-interfaces';
import { AuthService } from '@services/auth.service';
import { User } from 'firebase/auth';

@Component({
    selector: 'app-new-game-add-team',
    templateUrl: 'new-game-add-team.component.html',
})
export class NewGameAddTeamComponent {
    @Input() teams: any;

    @Output() showPlayerOptionsEvent = new EventEmitter<EditPlayerPayload>();
    @Output() addPlayerEvent = new EventEmitter<AddPlayerPayload>();
    @Output() removePlayerEvent = new EventEmitter<RemovePlayerPayload>();

    constructor(public auth: AuthService) {}

    showPlayerOptions(user: User, teamIndex: number, userIndex: number): void {
        this.showPlayerOptionsEvent.emit({ teamIndex, userIndex, user });
    }

    addPlayer(user?: User, team?: { users: User[] }): void {
        this.addPlayerEvent.emit({ user, team });
    }

    removePlayer(userIndex: number, teamIndex: number = null): void {
        this.removePlayerEvent.emit({ userIndex, teamIndex });
    }
}
