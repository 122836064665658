<div class="flex flex-col" appWebContainer>
    @if (!user()) {
        <div
            class="mt-8 mb-8 flex justify-center text-center text-lg text-black"
            [translate]="'LOG_IN_TO_REDEEM_COUPON'"
            i18n="@@LOG_IN_TO_REDEEM_COUPON">
            To redeem your coupon code, please log in or create an account.
        </div>
    } @else {
        @if (type() === 'default') {
            @if (!hasMandate) {
                <div
                    class="mt-8 mb-8 flex justify-center text-center text-lg text-black"
                    [translate]="'ENTER_COUPON_FOR_TRIAL'"
                    i18n="@@ENTER_COUPON_FOR_TRIAL">
                    Enter your coupon code here and receive your trial of DartCounter Ultimate.
                </div>
            } @else {
                <div
                    class="mt-8 mb-8 flex justify-center text-center text-lg text-black"
                    [translate]="'SELECT_PAYMENT_METHOD_FOR_MANDATE_COUPON_INFO'"
                    i18n="@@SELECT_PAYMENT_METHOD_FOR_MANDATE_COUPON_INFO">
                    Select a payment method to start a subscription. The payment starts after the trial period mentioned
                    on the coupon, unless cancelled in the meantime.
                </div>
            }
        } @else {
            @if (!hasMandate) {
                <div
                    class="mt-8 mb-8 flex justify-center text-center text-lg text-black"
                    [translate]="'ENTER_COUPON_THREE_MONTHS_MANDATE'"
                    i18n="@@ENTER_COUPON_THREE_MONTHS_MANDATE">
                    Enter your coupon code here and use DartCounter Ultimate free instantly for 3 months. Subscription
                    will be billed when the trial period ends. You can cancel at any time.
                </div>
            } @else {
                <div
                    class="mt-8 mb-8 flex justify-center text-center text-lg text-black"
                    [translate]="'USE_ULTIMATE_FREE_FOR_THREE_MONTHS_MANDATE'"
                    i18n="@@USE_ULTIMATE_FREE_FOR_THREE_MONTHS_MANDATE">
                    Use DartCounter Ultimate free instantly for 3 months. Subscription will be billed when the trial
                    period ends. You can cancel at any time.
                </div>
            }
        }
    }

    @if (!hasMandate) {
        @switch (type()) {
            @case ('virt') {
                <img
                    class="mx-auto max-w-lg rounded-md mb-8"
                    src="assets/images/ftr/virt-cam-setup/Virt-setup-device.jpg" />
            }

            @case ('omni') {
                <img
                    class="mx-auto max-w-lg rounded-md mb-8"
                    src="assets/images/ftr/omni-scoring-setup/Omni-setup-device.jpg" />
            }
        }
    }

    @if (!user()) {
        <app-on-page-auth [(user)]="user"></app-on-page-auth>
    } @else {
        @if (!hasMandate) {
            <div class="rounded-lg p-8 bg-white">
                <div
                    class="font-bebas-neue text-3xl text-black"
                    [translate]="'ENTER_YOUR_CODE'"
                    i18n="@@ENTER_YOUR_CODE">
                    Enter your code
                </div>
                <div class="mt-4 w-full space-y-2">
                    <div class="dialog-input-label" [translate]="'CODE'" i18n="@@CODE">Code</div>
                    <input class="dialog-input" [(ngModel)]="code" />
                </div>
                <app-primary-button
                    class="mt-4 block"
                    addedClasses="w-full"
                    size="normal"
                    (click)="redeem()"
                    [translate]="'REDEEM'"
                    i18n="@@REDEEM">
                    Redeem
                </app-primary-button>
            </div>
        } @else {
            <div
                class="flex flex-col space-y-8 md:mx-auto md:flex-row md:items-center md:justify-center md:space-y-0 md:space-x-4 text-black">
                @if (!upgradeService.plans.length) {
                    <div class="flex mt-5">
                        <app-icon icon="loading" size="large" class="mx-auto animate-spin text-orange"></app-icon>
                    </div>
                }

                <div
                    class="flex h-fit justify-center rounded-lg shadow"
                    *ngFor="let plan of upgradeService.plans"
                    [class.z-10]="plan.interval_period === 'year'">
                    <div class="flex flex-col bg-white rounded-lg">
                        <div class="p-6">
                            <div class="font-bebas-neue text-neutral-800 text-5xl text-center leading-none">
                                {{ plan.title }}
                            </div>
                            <div
                                class="mx-auto mt-4 flex w-fit items-center justify-center rounded-full bg-blue px-5 pt-1 text-center font-bebas-neue text-[26px] text-white space-x-1"
                                *ngIf="plan.interval_period === 'year' && !plan.active_discount"
                                style="padding-bottom: 0.05rem">
                                <div [translate]="'SAVE_PERCENTAGE'" i18n="@@SAVE_PERCENTAGE">Save</div>
                                <div>{{ plan.percentage }}%</div>
                            </div>
                        </div>

                        <!-- Price -->
                        <div class="flex min-w-80 flex-col items-center bg-neutral-50 p-4 rounded-b-lg">
                            <div class="flex items-center whitespace-nowrap text-black">
                                <div
                                    class="text-6xl font-extrabold tracking-tight"
                                    *ngIf="plan.interval_period === 'month'">
                                    €{{ plan.monthlyPrice }}
                                </div>
                                <div
                                    class="text-6xl font-extrabold tracking-tight"
                                    *ngIf="plan.interval_period === 'year' && !plan.active_discount">
                                    €{{ plan.price / 100 | number: '1.2-2' }}
                                </div>
                                <div
                                    class="text-6xl font-extrabold tracking-tight"
                                    *ngIf="plan.interval_period === 'year' && plan.active_discount">
                                    €{{ plan.final_first_payment_price }}
                                </div>
                                <div
                                    class="text-black-header ml-2 text-lg font-semibold"
                                    *ngIf="plan.interval_period === 'month'">
                                    / month
                                </div>
                                <div
                                    class="text-black-header ml-2 text-lg font-semibold"
                                    *ngIf="plan.interval_period === 'year'">
                                    / year
                                </div>
                            </div>
                            <div
                                class="strikethrough flex items-center whitespace-nowrap border-gray-500 font-semibold tracking-tight text-neutral-700"
                                *ngIf="plan.interval_period === 'year' && plan.active_discount">
                                <div class="text-2xl">€{{ plan.first_payment_price }}</div>
                                <div class="ml-2 text-base">/ year</div>
                            </div>

                            <app-primary-button
                                class="mt-8 block"
                                addedClasses="w-full"
                                size="normal"
                                (click)="upgrade(plan)"
                                [translate]="'UPGRADE_NOW'"
                                i18n="@@UPGRADE_NOW">
                                Upgrade now
                            </app-primary-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Description -->
            <div
                class="mt-8 flex justify-center text-center text-sm text-black"
                [translate]="'UPGRADE_TO_ULTIMATE_DESC'"
                i18n="@@UPGRADE_TO_ULTIMATE_DESC">
                Become an Ultimate-member and unlock the full potential of DartCounter. Keep track of your progress in
                extended graphs and see detailled statistics of your performance over time. Play unlimited online
                matches against over 1 million players from all over the world!
            </div>

            <!-- Features -->
            <div class="mt-12 flex flex-col items-center pb-8 text-sm">
                <div class="flex items-center">
                    <div
                        class="text-neutral-600 uppercase"
                        [translate]="'INCLUDED_FEATURES'"
                        i18n="@@INCLUDED_FEATURES">
                        Included features
                    </div>
                    <div class="ml-2 flex-auto border-b-2"></div>
                </div>
                <div class="mt-4 grid grid-cols-1 gap-y-2 text-black">
                    <div class="flex items-center" *ngFor="let feature of upgradeService.ultimateFeatures">
                        <ion-icon name="checkmark-circle-outline" class="ion-text-orange"></ion-icon>
                        <div class="ml-2">{{ feature }}</div>
                    </div>
                </div>
            </div>
        }
    }
</div>
