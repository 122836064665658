import { Route } from '@angular/router';
import { ProfileEditingRestrictionGuard } from 'src/guards/profile-editing-restriction.guard';

export const profileRoutes: Route[] = [
    {
        path: 'edit-profile',
        data: {
            hideTournamentNotification: true,
        },
        canActivate: [ProfileEditingRestrictionGuard],
        loadComponent: () => import('./pages/edit-profile/edit-profile.component').then((c) => c.EditProfileComponent),
    },
];
