import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Device_Images } from 'src/app/app.globals';

export interface OverlayText {
    message?: string;
    imagePath?: string;
    showAnimation?: string;
    hideAnimation?: string;
    duration?: number; // in milliseconds
    delay?: number; // in milliseconds
    hideDuration?: number; // in milliseconds
}

export interface OverlayStatus {
    isVisible: boolean;
    texts: OverlayText[];
    duration: number;
}

@Injectable({
    providedIn: 'root',
})
export class EventOverlayService {
    private overlayStatusSource = new Subject<OverlayStatus>();
    overlayStatus = this.overlayStatusSource.asObservable();

    private hideOverlayManuallySource = new Subject<void>();
    hideOverlayManually = this.hideOverlayManuallySource.asObservable();

    private _defaultDuration: number = 3000;

    showOverlay(texts: OverlayText[], duration: number = null) {
        this.overlayStatusSource.next({
            isVisible: true,
            texts,
            duration: duration ?? this._defaultDuration,
        });
    }

    hideOverlay() {
        this.hideOverlayManuallySource.next();
    }

    getOmniActivedOverlay(): OverlayText[] {
        return [
            {
                imagePath: Device_Images.omni_logo,
                message: 'Omni Scoring',
                showAnimation: 'animate__animated animate__backInDown',
                hideAnimation: 'animate__animated animate__fadeOut',
                hideDuration: 1000,
            },
            {
                message: 'Activated!',
                showAnimation: 'animate__delay-1s animate__animated animate__backInUp',
                hideAnimation: 'animate__animated animate__fadeOutUp',
                hideDuration: 1000,
            },
        ];
    }

    getYourTurnOverlay(): OverlayText[] {
        return [
            {
                message: "It's your turn!",
                showAnimation: 'animate__animated animate__fadeInLeftBig animate__fast',
                hideAnimation: 'animate__animated animate__fadeOutRightBig',
                hideDuration: 1000,
            },
        ];
    }
}
