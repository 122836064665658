@if (
    videoRoomService.ownCamera.roomID &&
    videoRoomService.ownUserMedia?.videoStreams?.hasStreams &&
    videoRoomService.ownUserMedia?.videoStreams?.activeStreams === true
) {
    <div
        [class.option-button-wrapper]="videoRoomService.ownCamera.camType !== 'smart_device'"
        class="relative"
        (click)="unlinkCamera()">
        @if (videoRoomService.ownCamera.camType !== 'smart_device') {
            <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
        }

        <img
            class="w-[3.25rem] py-2"
            [src]="'assets/icon/ftr/virt_cam_logo.webp'"
            *ngIf="videoRoomService.ownCamera.camType === 'smart_device'" />
        <app-icon icon="check_mark" size="medium" class="absolute -bottom-1 -right-1 text-green-accent"></app-icon>
    </div>
} @else {
    <div class="option-button-wrapper relative !bg-white !bg-opacity-30" (click)="linkCamera()">
        <app-icon icon="video_call" size="medium" class="!text-white"></app-icon>
    </div>
}
