import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

export type FeatureSlide = { title: string; desc: string; image?: string };

@Component({
    selector: 'app-feature-slides-dialog',
    templateUrl: 'feature-slides.html',
    styleUrls: ['feature-slides.scss'],
})
export class FeatureSlidesDialogComponent {
    @Input() title: string;
    @Input() slides: FeatureSlide[] = [];

    constructor(public view: ModalController) {}

    public DismissModal(): void {
        this.view.dismiss();
    }
}
