import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';

@Directive({
    selector: '[appDisableKeyboardResize]',
})
export class DisableKeyboardResizeDirective implements OnInit, OnDestroy {
    currentResizeMode: KeyboardResize;

    constructor(private el: ElementRef, private _platform: Platform) {}

    ngOnInit(): void {
        if (Capacitor.isNativePlatform()) {
            this._platform.ready().then(async () => {
                Keyboard.setScroll({ isDisabled: true });
                await Keyboard.getResizeMode().then((resizeMode) => {
                    this.currentResizeMode = resizeMode.mode;
                });
                Keyboard.setResizeMode({ mode: KeyboardResize.None });
            });
        }
    }

    ngOnDestroy(): void {
        if (Capacitor.isNativePlatform()) {
            this._platform.ready().then(() => {
                Keyboard.setScroll({ isDisabled: false });
                if (this.currentResizeMode) {
                    Keyboard.setResizeMode({ mode: this.currentResizeMode });
                }
            });
        }
    }
}
