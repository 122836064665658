const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');

/** @type {import('tailwindcss').Config} */
module.exports = {
    content: ['./src/**/*.{html,ts}', './node_modules/tw-elements/js/**/*.js'],
    darkMode: ['variant', '&:is(.dark *):not(.no-theme *)'],
    theme: {
        extend: {
            transitionProperty: {
                transform: 'transform',
            },
            transitionTimingFunction: {
                'ease-in-out': 'ease-in-out',
            },
            transitionDuration: {
                1000: '1000ms',
            },
            animation: {
                'grow-shrink': 'growShrink 1s ease-in-out',
                'fade-in': 'fadeIn 0.8s ease-in forwards',
                'move-up-full': 'moveUpFull 1s ease-in forwards',
                'slideup-in': 'slideUpIn 0.8s ease-out forwards',
                'dart1-animation': 'slideLeft1 0.4s ease-out forwards',
                'dart2-animation': 'slideLeft2 0.4s ease-out forwards',
                'dart3-animation': 'slideLeft3 0.4s ease-out forwards',
                'slidedown-in': 'slideDownIn 0.8s ease-out forwards',
                'slideup-out': 'slideUpOut 0.4s ease-in forwards',
                'slidedown-out': 'slideDownOut 0.4s ease-in forwards',
            },
            keyframes: {
                growShrink: {
                    '0%': { transform: 'scale(0)' },
                    '70%': { transform: 'scale(2)' },
                    '100%': { transform: 'scale(1)' },
                },
                fadeIn: {
                    '0%': { opacity: '0' },
                    '100%': { opacity: '1' },
                },
                moveUpFull: {
                    '0%': { transform: 'translateY(0)' },
                    '100%': { transform: 'translateY(-100%)' },
                },
                slideUpIn: {
                    '0%': { transform: 'translateY(50%)', opacity: '0' },
                    '100%': { transform: 'translateY(0)', opacity: '1' },
                },
                slideLeft1: {
                    '0%': { transform: 'translateX(100%) rotate(35deg)', opacity: '0' },
                    '100%': { transform: 'translateX(0) rotate(35deg)', opacity: '1' },
                },
                slideLeft2: {
                    '0%': { transform: 'translateX(100%) rotate(45deg)', opacity: '0' },
                    '100%': { transform: 'translateX(0) rotate(45deg)', opacity: '1' },
                },
                slideLeft3: {
                    '0%': { transform: 'translateX(100%) rotate(55deg)', opacity: '0' },
                    '100%': { transform: 'translateX(0) rotate(55deg)', opacity: '1' },
                },
                slideDownIn: {
                    '0%': { transform: 'translateY(-50%)', opacity: '0' },
                    '100%': { transform: 'translateY(0)', opacity: '1' },
                },
                slideUpOut: {
                    '0%': { transform: 'translateY(0)', opacity: '1' },
                    '100%': { transform: 'translateY(-100%)', opacity: '0' },
                },
                slideDownOut: {
                    '0%': { transform: 'translateY(0)', opacity: '1' },
                    '100%': { transform: 'translateY(100%)', opacity: '0' },
                },
            },
            screens: {
                xxs: '220px',
                xs: '380px',
                webxl: '1600px',
                '3xl': '1700px',
            },
            fontFamily: {
                'bebas-neue': `"Bebas Neue", ${defaultTheme.fontFamily.sans.join(',')}`,
                'open-sans': `"Open Sans", ${defaultTheme.fontFamily.mono.join(',')}`,
            },
            backgroundImage: {
                'gray-pattern': "url('src/assets/images/backgrounds/bg-grey.jpg')",
                'orange-pattern': "url('src/assets/images/backgrounds/bg-orange.jpg')",
                'white-pattern': "url('src/assets/images/backgrounds/bg-white.jpg')",
                'light-pattern': "url('src/assets/images/backgrounds/bg-light.jpg')",
            },
            borderRadius: {
                '4xl': '2rem',
                '5xl': '2.25rem',
                '6xl': '2.5rem',
            },
            borderWidth: {
                1: '1px',
            },
            spacing: {
                6.5: '1.625rem',
                18: '4.5rem',
                '9/20': '45%',
                '11/20': '55%',
                '9/10': '90%',
            },
            fontSize: {
                xxxs: '0.75rem' /* 10px */,
                xxs: '0.625rem' /* 8px */,
            },
            outlineWidth: {
                1: '1px',
            },
        },
        colors: {
            transparent: colors.transparent,
            white: {
                accent: '#efefef',
                DEFAULT: colors.white,
            },
            black: {
                header: '#1A1A1A',
                accent: '#2a2a2a',
                DEFAULT: '#171717',
                full: '#000',
            },
            green: {
                accent: '#09de77',
                DEFAULT: '#00AF5B',
            },
            neutral: {
                50: '#efefef',
                100: '#cdcdcd',
                200: '#b5b5b5',
                300: '#939393',
                400: '#7e7e7e',
                500: '#5e5e5e',
                600: '#565656',
                700: '#434343',
                800: '#343434',
                900: '#272727',
                950: '#1f1f1f',
            },
            'light-gray': {
                50: '#fdfdfd',
                100: '#f8f8f8',
                200: '#f4f4f4',
                300: '#efefef',
                400: '#ececec',
                500: '#e7e7e7',
                600: '#d2d2d2',
                700: '#a4a4a4',
                800: '#7f7f7f',
                900: '#616161',
            },
            gray: {
                50: '#e9e9e9',
                100: '#bbbbbb',
                200: '#9a9a9a',
                300: '#9a9a9a',
                400: '#4f4f4f',
                500: '#232323',
                600: '#202020',
                700: '#191919',
                800: '#131313',
                900: '#0f0f0f',
                950: '#0c0c0c',
            },
            orange: {
                50: '#FCEDEB',
                accent: '#f45b3f',
                DEFAULT: '#E44E32',
            },
            blue: {
                accent: '#4188f0',
                DEFAULT: '#326EE5',
            },
            red: {
                500: '#ef4444',
                600: '#dc2626',
                accent: '#ed4656',
                DEFAULT: '#CB2333',
            },
            yellow: {
                accent: '#ffca1b',
                DEFAULT: '#ffa800',
                'icon-alert': '#E9C000',
            },
        },
    },
    plugins: [
        require('tailwindcss-safe-area'),
        require('tailwind-scrollbar-hide'),
        require('@tailwindcss/aspect-ratio'),
        require('tw-elements/plugin.cjs'),
    ],
    safelist: ['animate-dart1-animation', 'animate-dart2-animation', 'animate-dart3-animation'],
};
