import { ScoreHelper } from '../../dc-helpers/score.helper';
import {
    CommandWithLanguage,
    DartCounterSpeechAction,
    RecognitionLang,
    SpeechCommand,
    SpeechCommandInfo,
} from './speech-to-score.service';

export interface DartCounterGlobalSpeechCommands {
    commands: (actions: DartCounterSpeechAction) => SpeechCommand[];
    info: () => SpeechCommandInfo[];
}

export enum CommandGame {
    MATCH = 'match',
    ONLINE_MATCH = 'online_match',
}

export enum CommandType {
    LANGUAGE = 'language',
    SCORE = 'score',
    REMAINING_SCORE = 'remaining_score',
    NO_SCORE = 'no_score',
    UNDO_SCORE = 'undo_score',
    EDIT_SCORE = 'edit_score',
    CHECKOUT = 'checkout',
    CHANGE_KEYBOARD = 'change_keyboard',
}

export enum CommandOption {
    SCORE = 'score',
    SCORE_ONLY = 'score_only',
}

export class SpeechCommandHelper {
    constructor(public langNumbers: {}) {}

    public getFinalScore(lang: RecognitionLang, number: string): string {
        let finalScore = null;
        if (/\d/.test(number)) {
            finalScore = number;
        } else {
            const score = this.langNumbers[number];
            if (score) {
                finalScore = score.toString();
            }
        }
        return finalScore;
    }

    public getAddToTimer(finalScore: string): number {
        const score = parseInt(finalScore);
        return ScoreHelper.getAddToTimer(score);
    }
}

export class DartCounterSpeechCommands {
    public static remainingScore: CommandWithLanguage[] = [
        {
            list: [/remaining (score|scored) (\w+)/],
            languages: ['en-US'],
            attributes: [1],
        },
        {
            list: [/resterende score (\w+)/],
            languages: ['nl-NL'],
            attributes: [0],
        },
        {
            list: [/verbleibende Punktzahl (\w+)/],
            languages: ['de-DE'],
            attributes: [0],
        },
    ];

    public static score: CommandWithLanguage[] = [
        {
            list: [/(score|scored) (\w+)/],
            languages: ['en-US', 'nl-NL'],
            attributes: [1],
        },
        {
            list: [/Punktzahl (\w+)/],
            languages: ['de-DE'],
            attributes: [0],
        },
    ];

    public static noScore: CommandWithLanguage[] = [
        {
            list: [/no (score|scored)/, /bust/],
            languages: ['en-US'],
            attributes: [],
        },
        {
            list: [/geen score/],
            languages: ['nl-NL'],
            attributes: [],
        },
        {
            list: [/keine Punktzahl/, /pleite/],
            languages: ['de-DE'],
            attributes: [],
        },
    ];

    public static undoScore: CommandWithLanguage[] = [
        {
            list: [/undo (score|scored)/],
            languages: ['en-US'],
            attributes: [],
        },
        {
            list: [/score ongedaan maken/],
            languages: ['nl-NL'],
            attributes: [],
        },
        {
            list: [/Punktzahl rückgängig machen/],
            languages: ['de-DE'],
            attributes: [],
        },
    ];

    public static editScore: CommandWithLanguage[] = [
        {
            list: [/edit (score|scored)/],
            languages: ['en-US'],
            attributes: [],
        },
        {
            list: [/score bewerken/],
            languages: ['nl-NL'],
            attributes: [],
        },
        {
            list: [/Punktzahl bearbeiten/],
            languages: ['de-DE'],
            attributes: [],
        },
    ];

    public static checkout: CommandWithLanguage[] = [
        {
            list: [/check out (\w+) and (\w+)/],
            languages: ['en-US', 'nl-NL', 'de-DE'],
            attributes: [0, 1],
        },
        {
            list: [/check out (\w+)/],
            languages: ['en-US', 'nl-NL', 'de-DE'],
            attributes: [0],
        },
    ];

    public static changeKeyboard: CommandWithLanguage[] = [
        {
            list: [/keyboard (numbers|number|single|hotkeys|hot keys)/],
            languages: ['en-US'],
            attributes: [0],
        },
        {
            list: [/toetsenbord (nummers|enkel|sneltoetsen)/],
            languages: ['nl-NL'],
            attributes: [0],
        },
        {
            list: [/Tastatur (Zahlen|einzel|Hotkeys)/],
            languages: ['de-DE'],
            attributes: [0],
        },
    ];

    public static scoreOnly: CommandWithLanguage[] = [
        {
            list: [/(\d+)/],
            languages: ['en-US', 'nl-NL', 'de-DE'],
            attributes: [0],
        },
    ];
}
