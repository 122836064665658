import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class PromotionApiService extends ApiService {
    public getPromotions = super.getFetcher().path('/promotions').method('get').create();
    public storePromotion = super.getFetcher().path('/promotions').method('post').create();
    public showPromotionById = super.getFetcher().path('/promotions/{promotionId}').method('get').create();
    public updatePromotionById = super.getFetcher().path('/promotions/{promotionId}').method('put').create();
    public deletePromotionById = super.getFetcher().path('/promotions/{promotionId}').method('delete').create();
}
