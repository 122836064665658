<modal-content class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="transparent">
        <div title [translate]="'SUDDEN_DEATH_MODE'" i18n="@@SUDDEN_DEATH_MODE">
            {{ 'SUDDEN_DEATH_MODE' | translate }}
        </div>
    </app-page-header>

    <ng-container>
        <ion-row>
            <ion-col class="text-center" style="font-size: 12px">
                <p
                    style="padding: 0 10px; margin: 0"
                    [translate]="'SUDDEN_DEATH_EXPLANATION'"
                    i18n="@@SUDDEN_DEATH_EXPLANATION">
                    The sudden death leg will be the 3rd (best of 5) leg above the goal. Example; first to 10 legs => 12
                    - 12 is possible, first to get 13 wins.
                </p>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-label [translate]="'SUDDEN_DEATH_MODE'" i18n="@@SUDDEN_DEATH_MODE"
                        >Sudden death mode
                    </ion-label>
                    <ion-toggle
                        name="suddenDeath"
                        ngDefaultControl
                        color="secondary"
                        [(ngModel)]="suddenDeath"
                        slot="end"></ion-toggle>
                </ion-item>
            </ion-col>
        </ion-row>
    </ng-container>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA" style="position: relative" (click)="dismiss(suddenDeath)">
            <p class="text-center" style="height: 72px" [translate]="'CONFIRM'" i18n="@@CONFIRM">Confirm</p>
        </div>
    </ion-toolbar>
</ion-footer>
