import { Injectable } from '@angular/core';
import { SmartDeviceType } from '@dc-core/dc-backend/dc-enums';
import { CapacitorSmartDeviceClient } from 'capacitor-smart-device-client';
import { Observable, Subject } from 'rxjs';

export interface UDPSmartDeviceInfo {
    type: UDPDeviceType;
    guid: string;
    mac: string;
}

export enum UDPDeviceType {
    Virt = 'virt',
    Omni = 'omni',
}

@Injectable()
export class UDPService {
    private currentTimeout = null;

    private udpStream: Subject<any> = new Subject<any>();
    private udpListener;

    public status: 'searching' | 'stopped' = null; // New property to track if search is active

    public watchUDPSocket(port: number, stopAfter?: number): Observable<any> {
        if (this.udpListener) {
            console.error('Already listening to UDP port');
            return;
        }

        this.status = 'searching'; // Set searching state
        this.startListener();

        CapacitorSmartDeviceClient.startUDPSocket({ host: '0.0.0.0', port });

        // Set timeout for auto-stop if specified
        if (stopAfter > 0) {
            this.currentTimeout = setTimeout(() => {
                this.stopUDPSocket();
            }, stopAfter);
        }

        return this.udpStream.asObservable();
    }

    private async startListener(): Promise<void> {
        try {
            this.udpListener = await CapacitorSmartDeviceClient.addListener('udpMessageReceived', (info) => {
                this.udpStream.next(info); // Emit received data to udpStream for subscribers
            });
        } catch (error) {
            console.error('Error starting UDP listener:', error);
        }
    }

    public stopUDPSocket() {
        if (this.currentTimeout) {
            clearTimeout(this.currentTimeout);
            this.currentTimeout = null;
        }
        this.status = 'stopped';

        try {
            CapacitorSmartDeviceClient.stopUDPSocket();
        } catch (error) {
            console.error('Error stopping UDP socket:', error);
        }

        if (this.udpListener) {
            this.udpListener.remove();
            this.udpListener = null;
        }

        this.udpStream.next(null);
    }

    // UDP Response Helper functions
    public parseUDPResponse(data: string): UDPSmartDeviceInfo | null {
        try {
            return JSON.parse(data) as UDPSmartDeviceInfo;
        } catch (error) {
            console.error('Failed to parse UDP response:', error);
            return null;
        }
    }

    public getSmartDeviceType(type: UDPDeviceType): SmartDeviceType | null {
        switch (type) {
            case UDPDeviceType.Virt:
                return 'virt_cam';
            case UDPDeviceType.Omni:
                return 'omni_scoring';
            default:
                return null;
        }
    }

    // Helper function for matching the device type
    public isCorrectSmartDeviceType(smartDeviceType: SmartDeviceType, responseType: UDPDeviceType): boolean {
        switch (smartDeviceType) {
            case 'virt_cam':
                return responseType === UDPDeviceType.Virt;
            case 'omni_scoring':
                return !responseType || responseType === UDPDeviceType.Omni;
            default:
                return false;
        }
    }

    public isValidRemoteAddress(remoteAddress: string | null): boolean {
        return !!remoteAddress && !remoteAddress.startsWith(':');
    }
}
