<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'THEME'" i18n="@@THEME">Theme</div>
    </div>
    <div class="slide-modal-content text-black">
        <div class="flex justify-center gap-4">
            <div class="space-y-2 basis-1/3 cursor-pointer" (click)="changeTheme('light')">
                <img class="rounded-lg" src="assets/images/themes/light.webp" />
                <div class="flex items-center mb-4 cursor-pointer">
                    <div
                        class="w-5 h-5 rounded-full border-2 border-neutral-400 flex items-center justify-center bg-white mr-2">
                        @if (themeService.selectedTheme() === 'light') {
                            <div class="w-2 h-2 bg-orange rounded-full"></div>
                        }
                    </div>
                    <label
                        class="text-sm"
                        [class.font-semibold]="themeService.selectedTheme() === 'light'"
                        [translate]="'LIGHT'"
                        i18n="@@LIGHT">
                        Light
                    </label>
                </div>
            </div>

            <div class="space-y-2 basis-1/3 cursor-pointer" (click)="changeTheme('dark')">
                <img class="rounded-lg" src="assets/images/themes/dark.webp" />
                <div class="flex items-center mb-4 cursor-pointer">
                    <div
                        class="w-5 h-5 rounded-full border-2 border-neutral-400 flex items-center justify-center bg-white mr-2">
                        @if (themeService.selectedTheme() === 'dark') {
                            <div class="w-2 h-2 bg-orange rounded-full"></div>
                        }
                    </div>
                    <label
                        class="text-sm"
                        [class.font-semibold]="themeService.selectedTheme() === 'dark'"
                        [translate]="'DARK'"
                        i18n="@@DARK">
                        Dark
                    </label>
                </div>
            </div>

            <div class="space-y-2 basis-1/3 cursor-pointer" (click)="changeTheme('system')">
                <img class="rounded-lg" src="assets/images/themes/system.webp" />
                <div class="flex items-center mb-4 cursor-pointer">
                    <div
                        class="w-5 h-5 rounded-full border-2 border-neutral-400 flex items-center justify-center bg-white mr-2">
                        @if (themeService.selectedTheme() === 'system') {
                            <div class="w-2 h-2 bg-orange rounded-full"></div>
                        }
                    </div>
                    <label
                        class="text-sm"
                        [class.font-semibold]="themeService.selectedTheme() === 'system'"
                        [translate]="'SYSTEM'"
                        i18n="@@SYSTEM">
                        System
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
