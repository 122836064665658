import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-add-break-dialog',
    templateUrl: 'addBreakDialog.html',
    styleUrls: ['addBreakDialog.scss'],
})
export class AddBreakDialogComponent {
    minuteOptions: number[] = [];
    selectedMinutes: number;

    constructor(
        public view: ModalController,
        private navParams: NavParams,
        public auth: AuthService
    ) {
        this.minuteOptions = this.navParams.get('minuteOptions');
        this.selectedMinutes = this.minuteOptions[0];
    }

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    public StartPause() {
        this.DismissModal(this.selectedMinutes);
    }

    public changeDuration(selectedMinutes: number) {
        this.selectedMinutes = selectedMinutes;
    }
}
