import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class RecapApiService extends ApiService {
    public getRecap = super.getFetcher().path('/recaps').method('get').create();
    public refreshRecap = super.getFetcher().path('/recaps').method('post').create();
    public shareRecap = super.getFetcher().path('/recaps/share').method('get').create();
}
