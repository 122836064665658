import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    effect,
    ElementRef,
    inject,
    input,
    InputSignal,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { OmniSingleDart } from '@dc-core/dc-services/omni/omni-ingame.service';
import { ModalController } from '@ionic/angular';
import * as d3 from 'd3';
import { environment } from 'src/environments/environment';

import { KeyboardDartComponent } from '../../app/keyboard-dart/keyboard-dart.component';
import { EditOmniScoreDialogComponent, EditOmniScorePayload } from './edit-omni-score/edit-omni-score.dialog';

export enum OmniThrowResponseState {
    WAITING = 'WAITING',
    THROW = 'THROW',
    MISS = 'MISS',
    BOUNCEOUT = 'BOUNCEOUT',
    IDLE = 'IDLE',
}

@Component({
    selector: 'app-zoomed-point',
    standalone: true,
    templateUrl: './zoomed-point.component.html',
    imports: [KeyboardDartComponent],
})
export class ZoomedPointComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('svgElement', { static: true }) svgContainer: ElementRef<SVGElement>;

    public point: InputSignal<OmniSingleDart> = input<OmniSingleDart>(null);
    public turnEnded: InputSignal<boolean> = input<boolean>(false);
    public editable: InputSignal<boolean> = input<boolean>(false);
    public zoomLevel: InputSignal<number> = input<number>(3);
    public dartIndex: InputSignal<number> = input<number>(null);

    public animate: boolean = false;
    public omniThrowResponseState: OmniThrowResponseState = OmniThrowResponseState.WAITING;

    public svgContent: SVGElement = null;
    private dartboardSvgUrl: string = 'assets/images/tor_board.svg'; // Path to your SVG file
    private static cachedSvg: SVGElement | null = null; // Cache static variable for the SVG

    private currentSvgElement: SVGElement | null = null; // Reference to the current SVG element
    private serializedSvgContent: string = ''; // Cache the serialized SVG content
    private d3SvgSelection: d3.Selection<SVGElement, unknown, null, undefined>;
    private isSvgLoaded: boolean = false;
    private isSvgLoadingPromise: Promise<void> | null = null;

    private modalController: ModalController = inject(ModalController);
    private _http: HttpClient = inject(HttpClient);

    constructor() {
        effect(() => {
            if (this.point()) {
                if (this.isSvgLoaded) {
                    this.checkValidPoint(); // Run only if the SVG is already loaded
                } else {
                    this.isSvgLoadingPromise.then(() => {
                        this.checkValidPoint(); // Run after the SVG is loaded
                    });
                }
            } else {
                this.omniThrowResponseState = OmniThrowResponseState.WAITING;
                if (this.currentSvgElement) {
                    this.removeCircleAndResetZoom();
                } else {
                    this.isSvgLoadingPromise.then(() => {
                        this.isSvgLoaded = true;
                    });
                }
            }
        });
    }

    ngOnInit(): void {
        // Load the initial SVG before running any checks if needed
        this.initializeSvg().then(() => {
            if (this.point()) {
                this.checkValidPoint(); // Run check after the SVG is loaded
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.currentSvgElement) {
            this.appendSvgToContainer(this.currentSvgElement);
            this.linkSvgToD3(); // Ensure D3 is linked after appending
        } else if (this.serializedSvgContent) {
            // Restore the SVG from the cached serialized content
            this.restoreSvgFromCache();
        }
    }

    private initializeSvg(): Promise<void> {
        if (!this.isSvgLoaded && !this.isSvgLoadingPromise) {
            this.isSvgLoadingPromise = this.loadInitialSvg().then(() => {
                this.isSvgLoaded = true;
            });
        }
        return this.isSvgLoadingPromise || Promise.resolve();
    }

    private loadInitialSvg(): Promise<void> {
        return new Promise((resolve) => {
            if (ZoomedPointComponent.cachedSvg) {
                this.currentSvgElement = ZoomedPointComponent.cachedSvg.cloneNode(true) as SVGElement;
                this.appendSvgToContainer(this.currentSvgElement);
                resolve(); // Resolve the promise after loading the cached SVG
            } else {
                this._http.get(this.dartboardSvgUrl, { responseType: 'text' }).subscribe((dartboardSvg) => {
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = dartboardSvg.trim();
                    const svgElement = tempDiv.querySelector('svg');

                    if (svgElement) {
                        ZoomedPointComponent.cachedSvg = svgElement.cloneNode(true) as SVGElement;
                        this.currentSvgElement = svgElement;
                        this.appendSvgToContainer(svgElement);
                        resolve(); // Resolve the promise after loading the SVG from the server
                    }
                });
            }
        });
    }

    private appendSvgToContainer(svgElement: SVGElement): void {
        console.log('appendSvgToContainer');
        if (this.svgContainer && this.svgContainer.nativeElement) {
            if (!this.svgContainer.nativeElement.contains(svgElement)) {
                d3.select(this.svgContainer.nativeElement).node().appendChild(svgElement);
                this.linkSvgToD3(); // Ensure D3 is linked after appending
            }
        } else {
            console.error('svgContainer is not defined or not available.');
        }
    }

    private renderZoomedSvg(): void {
        if (!this.currentSvgElement) {
            console.error('No current SVG element to modify.');
            return;
        }

        const dartboardSize = 453; // Set this to the actual width of your SVG
        const translatedX = this.point().coordinates.x + dartboardSize / 2;
        const translatedY = this.point().coordinates.y + dartboardSize / 2;
        const zoomFactor = 453 / this.zoomLevel();
        const viewBoxX = this.clamp(translatedX - zoomFactor / 2, 0, dartboardSize - zoomFactor);
        const viewBoxY = this.clamp(translatedY - zoomFactor / 2, 0, dartboardSize - zoomFactor);

        // Apply changes directly to the current SVG element
        d3.select(this.currentSvgElement)
            .transition()
            .duration(500)
            .attr('viewBox', `${viewBoxX} ${viewBoxY} ${zoomFactor} ${zoomFactor}`);

        // Add or update the circle for the point with a specific class
        let existingCircle = d3.select(this.currentSvgElement).select('circle.hitpoint-circle');
        if (existingCircle.empty()) {
            existingCircle = d3.select(this.currentSvgElement).append('circle').classed('hitpoint-circle', true);
        }
        existingCircle
            .attr('cx', translatedX)
            .attr('cy', translatedY)
            .attr('r', 5)
            .style('fill', '#4c8dff')
            .style('stroke', '#ffffff')
            .style('stroke-width', 1);
    }

    private removeCircleAndResetZoom(): void {
        if (this.currentSvgElement) {
            d3.select(this.currentSvgElement).selectAll('circle.hitpoint-circle').remove();

            // Reset the viewBox to the initial zoom with animation
            d3.select(this.currentSvgElement).transition().duration(500).attr('viewBox', '0 0 453 453'); // Assuming '0 0 453 453' is the initial viewBox
        }
    }

    public getScoreEvent(point: OmniSingleDart): string {
        if (!point) {
            return '';
        }

        if (point.singleDart.amount === 0 || point.singleDart.multiplier === null) {
            return $localize`:@@MISS:Miss`;
        }

        if (point.singleDart.amount === 25) {
            if (point.singleDart.multiplier === 1) {
                return 'S-BULL';
            }
            return 'BULL';
        } else {
            if (point.singleDart.multiplier === 1) {
                return 'S' + point.singleDart.amount;
            } else if (point.singleDart.multiplier === 2) {
                return 'D' + point.singleDart.amount;
            } else if (point.singleDart.multiplier === 3) {
                return 'T' + point.singleDart.amount;
            }
        }
    }

    public editZoomedPoint(): void {
        if (this.editable()) {
            this.modalController
                .create({
                    component: EditOmniScoreDialogComponent,
                    componentProps: {
                        dartIndex: this.dartIndex(),
                        point: this.point(),
                        svgContent: this.currentSvgElement ? this.currentSvgElement.outerHTML : '',
                    } as EditOmniScorePayload,
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                    showBackdrop: true,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            console.log(dialogRes.data);
                        }
                    });
                });
        }
    }

    private clamp(value: number, min: number, max: number): number {
        return Math.max(min, Math.min(max, value));
    }

    private checkValidPoint() {
        if (!this.point()) {
            this.omniThrowResponseState = OmniThrowResponseState.IDLE;
            return;
        }

        if (this.point().singleDart.isBounceout) {
            this.omniThrowResponseState = OmniThrowResponseState.BOUNCEOUT;
        }

        if (this.point().coordinates) {
            if (
                this.point().coordinates.x > 250 ||
                this.point().coordinates.x < -250 ||
                this.point().coordinates.y > 250 ||
                this.point().coordinates.y < -250 ||
                (!this.point().coordinates.x && !this.point().singleDart.amount)
            ) {
                this.omniThrowResponseState = OmniThrowResponseState.MISS;
            } else {
                this.omniThrowResponseState = OmniThrowResponseState.THROW;
                this.createZoomedView();
            }
        } else {
            this.omniThrowResponseState = OmniThrowResponseState.IDLE;
        }
    }

    private createZoomedView(): void {
        if (this.point() && this.currentSvgElement) {
            this.renderZoomedSvg();
        }
    }

    private restoreSvgFromCache(): void {
        if (this.serializedSvgContent) {
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(this.serializedSvgContent, 'image/svg+xml');
            const restoredSvg = svgDoc.querySelector('svg');
            if (restoredSvg) {
                this.currentSvgElement = restoredSvg as SVGElement;
                this.appendSvgToContainer(this.currentSvgElement);
            }
        }
    }

    private linkSvgToD3(): void {
        if (this.currentSvgElement) {
            this.d3SvgSelection = d3.select(this.currentSvgElement);
            console.log('SVG linked to D3:', this.d3SvgSelection);
        }
    }

    private saveSvgState(): void {
        if (this.currentSvgElement) {
            this.serializedSvgContent = new XMLSerializer().serializeToString(this.currentSvgElement);
        }
    }

    ngOnDestroy(): void {
        this.saveSvgState();
    }
}
