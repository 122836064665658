import { Component, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GamesLeaveBusterService } from 'src/app/modules/games/services/games-leave-buster.service';

@Component({
    selector: 'app-opponent-not-responding-dialog',
    templateUrl: 'opponent-not-responding.dialog.html',
})
export class OpponentNotRespondingDialogComponent {
    public modalController: ModalController = inject(ModalController);
    public leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);

    public DismissModal(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }
}
