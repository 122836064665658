import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserApiService } from '@dc-api/user.api.service';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { IonMenu, IonRange, ModalController } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { PreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { InGameCameraService } from 'src/app/services/ingame-camera.service';
import { JanusStateService } from 'src/app/services/janus/janus-state.service';
import { UpgradeService } from 'src/app/services/upgrade.service';
import { ToggleSwitchComponent } from 'src/app/toggle-switch/toggle-switch.component';
import { SpeechToScoreInfoDialogComponent } from 'src/dialogs/speech-to-score-info/speech-to-score-info.dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import { BetaFlagComponent } from '../../app/modules/account-settings/components/beta-flag/beta-flag.component';
import { OptionButtonComponent } from '../../app/option-button/option-button.component';
import { PrimaryButtonComponent } from '../../app/primary-button/primary-button.component';
import { EntryModePipe } from '../pipes/entry-mode.pipe';
import { FinishModePipe } from '../pipes/finish-mode.pipe';

@Component({
    selector: 'app-ingame-menu',
    templateUrl: 'ingame-menu.component.html',
    standalone: true,
    imports: [
        IonRange,
        CommonModule,
        TranslateModule,
        IonMenu,
        OptionButtonComponent,
        EntryModePipe,
        FinishModePipe,
        ToggleSwitchComponent,
        PrimaryButtonComponent,
        BetaFlagComponent,
        FormsModule,
    ],
})
export class IngameMenuComponent {
    public authService: AuthService = inject(AuthService);
    public preferenceService: PreferenceService = inject(PreferenceService);
    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);
    public janusStateService: JanusStateService = inject(JanusStateService);

    private modalController: ModalController = inject(ModalController);
    private userApiService: UserApiService = inject(UserApiService);
    private ingameCameraService: InGameCameraService = inject(InGameCameraService);
    private upgradeService: UpgradeService = inject(UpgradeService);

    public ingameMode: GameMode = null;
    public ingameData: any = null;

    public version = environment.version;
    public isWeb: boolean = environment.isWeb;

    public onMenuOpen(): void {
        this.ingameMode = this.preferenceService.ingameMode;
        this.ingameData = this.preferenceService.ingameData;
    }

    public async openUpgradeDialog(): Promise<void> {
        const dialogComponent = await this.upgradeService.getUpgradeDialog(GA_EVENTCATEGORIES.INGAMEMENU);
        this.modalController
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }

    public toggleCheckoutPercentage(): void {
        this.authService.user.profile.has_checkout_rate = !this.authService.user.profile.has_checkout_rate;

        this.userApiService.updateUser({
            profile: {
                has_checkout_rate: this.authService.user.profile.has_checkout_rate,
            },
        });
    }

    public toggleActionReplays(): void {
        this.preferenceService.enableActionReplays = !this.preferenceService.enableActionReplays;
        this.preferenceService.savePreferences();

        if (this.preferenceService.enableActionReplays) {
            this.ingameCameraService.startRecording(
                this.authService.user.id,
                'clip',
                this.janusStateService.videoRoom().camType
            );
        } else {
            this.ingameCameraService.stopRecording(this.authService.user.id, false).catch(console.error);
        }
    }

    public showSpeechToScore(): void {
        this.modalController
            .create({
                component: SpeechToScoreInfoDialogComponent,
            })
            .then((elem) => {
                elem.present();
            });
    }
}
