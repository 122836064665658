import { Route } from '@angular/router';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';
import { gamesOnlineLeaveBusterGuard } from '../guards/games-online-leave-buster.guard';

export const matchGameRoutes: Route[] = [
    {
        path: 'online-game/match/:id',
        data: {
            hideTournamentNotification: true,
        },
        canDeactivate: [gamesOnlineLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/match-online-game/match-online-game.component').then((c) => c.MatchOnlineGameComponent),
    },
    {
        path: 'game/match',
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/match-local-game/match-local-game.component').then((m) => m.MatchLocalGameComponent),
    },
];
