import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-match-interim-score',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: 'match-interim-score.component.html',
})
export class MatchInterimScoreComponent implements OnInit, OnChanges {
    @Input() size: 'large' | 'medium' | 'small' | 'lobby' | 'mediumxl' | 'mediumlg';
    @Input() type: 'sets' | 'legs' = 'legs';
    @Input() showLabel = false;
    classes = '';

    ngOnInit(): void {
        this.setComponent();
    }

    ngOnChanges(): void {
        this.setComponent();
    }

    setComponent(): void {
        this.classes = '';

        switch (this.size) {
            case 'large':
                this.classes += ' px-2 pt-1.5 pb-1 rounded-sm leading-none text-2xl !text-white';
                break;
            case 'mediumxl':
                this.classes += ' px-2 pt-1 pb-0.5 rounded-sm text-2xl leading-none !text-white';
                break;
            case 'mediumlg':
                this.classes += ' px-1.5 pt-1.5 pb-1 rounded-sm text-xl leading-none !text-white';
                break;
            case 'medium':
                this.classes += ' px-1.5 pt-1.5 pb-1 rounded-sm leading-none !text-white';
                break;
            case 'small':
                this.classes += ' px-1.5 pt-1 pb-0.5 rounded-sm leading-none text-sm !text-white';
                break;
            case 'lobby':
                this.classes += ' px-2 pt-1 pb-0.5 rounded-sm leading-none text-lg !text-white';
                break;
        }

        switch (this.type) {
            case 'sets':
                this.classes += ' bg-green';
                break;
            case 'legs':
                this.classes += ' bg-blue';
                break;
        }
    }
}
