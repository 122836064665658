@if (false) {
    <ng-container i18n="@@YOU">You</ng-container>
}

<app-page-header mainNavigation="menu">
    <div title [translate]="'CHATS'" i18n="@@CHATS">Chats</div>
    <div right class="right-page-header">
        <app-option-button icon="add" (click)="startNewChat()" color="text-white"></app-option-button>
    </div>
</app-page-header>

<div class="relative flex h-full flex-col overflow-hidden" id="main-content">
    <div class="flex flex-1 flex-col overflow-auto bg-white-pattern">
        @if (!isLoaded) {
            <div class="flex pt-8">
                <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-black"></app-icon>
            </div>
        }
        @if (!participants.length && isLoaded) {
            <div class="flex h-full flex-col items-center px-4 py-8 justify-between text-black" appSmallWebContainer>
                <div class="space-y-2 px-4 h-full flex flex-col justify-center">
                    <div class="text-center text-lg font-bold" [translate]="'BREAK_THE_ICE'" i18n="@@BREAK_THE_ICE">
                        Break the ice
                    </div>
                    <div
                        class="text-center text-sm font-semibold"
                        [translate]="'STUCK_FOR_IDEAS_CHAT_HELP'"
                        i18n="@@STUCK_FOR_IDEAS_CHAT_HELP">
                        Stuck for ideas? Schedule a game with one of your friends or ask how their week is going.
                    </div>
                </div>
                <div class="w-full">
                    <app-primary-button
                        size="normal"
                        addedClasses="w-full"
                        (click)="startNewChat()"
                        [translate]="'START_NEW_CHAT'"
                        i18n="@@START_NEW_CHAT">
                        Start new chat
                    </app-primary-button>
                </div>
            </div>
        }
        <div class="space-y-4 overflow-y-auto px-4 py-6" *ngIf="participants.length && isLoaded" appSmallWebContainer>
            <div
                class="flex w-full items-center space-x-2 rounded-md border border-neutral-400 px-4 py-3 focus-within:border-orange">
                <ion-icon name="search-outline" class="ion-text-neutral-300"></ion-icon>
                <input
                    class="w-full bg-transparent text-white placeholder:text-neutral-400"
                    [formControl]="searchControl"
                    placeholder="{{ 'SEARCH' | translate }}" />
            </div>

            <div class="divide-y divide-neutral-200 overflow-y-scroll scrollbar-hide">
                @for (participant of participants | privateChatFilter: { search: searchString }; track $index) {
                    <div
                        class="relative flex items-center justify-between w-full p-2 text-white cursor-pointer"
                        (click)="onChatClick(participant)">
                        <div class="grid grid-cols-12 w-full items-center">
                            <div class="col-span-2 relative w-12">
                                @if (participant.direct_message) {
                                    <app-profile-photo
                                        class="mt-0.5"
                                        [size]="40"
                                        [ultimate]="participant.conversation.participants[0].user.is_ultimate"
                                        [url]="participant.conversation.participants[0].user.profile?.profile_photo_url"
                                        [badge]="
                                            participant.conversation.participants[0].user.profile?.badge
                                        "></app-profile-photo>
                                } @else {
                                    @if (participant.conversation.icon_url) {
                                        <img class="w-full rounded-full" [src]="participant.conversation.icon_url" />
                                    } @else {
                                        <div
                                            class="inline-flex items-center w-12 h-12 justify-center rounded-full bg-black p-1">
                                            <app-icon icon="supervisor_account" class="text-black"></app-icon>
                                        </div>
                                    }
                                }
                            </div>

                            <div class="col-span-10 space-y-1 overflow-hidden w-full">
                                <div class="flex items-center justify-between space-x-2">
                                    <div class="font-bold leading-tight truncate text-black">
                                        {{ participant.conversation.name }}
                                    </div>

                                    <div class="text-neutral-700 text-xs mt-auto">
                                        {{ participant.conversation.updated_at | chatDate }}
                                    </div>
                                </div>

                                <div class="flex items-center justify-between space-x-4">
                                    @if (participant.conversation.last_message) {
                                        @if (
                                            participant.conversation.last_message.participant.user_id === auth.user.id
                                        ) {
                                            <div class="text-neutral-800 text-xs font-semibold truncate">
                                                {{ 'YOU' | translate }}:
                                                {{ participant.conversation.last_message.body }}
                                            </div>
                                        } @else {
                                            <div class="text-neutral-800 text-xs font-semibold truncate">
                                                {{ participant.conversation.last_message.participant.user.first_name }}:
                                                {{ participant.conversation.last_message.body }}
                                            </div>
                                        }
                                    } @else {
                                        <div
                                            class="text-neutral-800 text-xs font-semibold truncate"
                                            [translate]="'CHAT_CREATED'"
                                            i18n="@@CHAT_CREATED">
                                            Chat created
                                        </div>
                                    }

                                    @if (participant.unread_count) {
                                        <div>
                                            <ion-badge mode="md" class="ion-bg-yellow !ion-text-black">
                                                {{ participant.unread_count }}
                                            </ion-badge>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>

    <app-bottom-navigation class="w-full" active="chats"></app-bottom-navigation>
</div>
