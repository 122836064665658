import { User } from 'dc-core/dc-backend/dc-classes';
import { GameMode } from 'dc-core/dc-backend/dc-enums';
import { OnlineGameplay } from 'dc-core/dc-backend/dc-interfaces';
import { ONLINE_GAME_SETTINGS_STORAGE_KEYS } from 'dc-core/dc-gamelogic/game-storage';
import { LocalStorageKey } from 'dc-core/dc-localstorage';

export class DartCounterOnlineGameSettings {
    public user: User;
    public storageKey: ONLINE_GAME_SETTINGS_STORAGE_KEYS;

    public onlineGameModes: { title: string; type: GameMode }[] = [];
    public onlineGameMode: GameMode;
    public cameraOnly: boolean = false;

    constructor() {
        this.onlineGameModes.push({
            title: $localize`:@@MATCH:Match`,
            type: 'match',
        });
        this.onlineGameModes.push({
            title: $localize`:@@CRICKET_TACTICS:Cricket / Tactics`,
            type: 'cricket_tactics',
        });
    }

    public setUser(user: User) {
        this.user = user;
    }

    setOnlineGameMode(onlineGameMode: GameMode): void {
        this.onlineGameMode = onlineGameMode;
    }

    setCameraOnly(cameraOnly: boolean): void {
        this.cameraOnly = cameraOnly;
    }

    toggleCameraOnly(): void {
        this.cameraOnly = !this.cameraOnly;
    }
}
