<app-page-header mainNavigation="custom" color="orange">
    <app-icon left icon="arrow_back" (click)="dismiss()"></app-icon>
    <div title [translate]="'GAME_LABELS'" i18n="@@GAME_LABELS">Game labels</div>
    <app-option-button icon="add" (click)="manageCategory()" color="text-white"></app-option-button>
</app-page-header>

<ion-content scrollY="false" fullscreen class="bg-white-pattern text-black">
    <ion-row>
        <ion-col class="text-center">
            <p style="margin: 5px; font-size: 14px" [translate]="'GAME_LABELS_INFO'" i18n="@@GAME_LABELS_INFO">
                Manage your game labels and categories that you can assign to your games!
            </p>
        </ion-col>
    </ion-row>

    <div class="game-label-container" style="margin: 0 0.5rem">
        <p
            class="text-center"
            style="margin: 5px; font-size: 14px"
            [translate]="'NO_GAME_LABELS_FOUND'"
            i18n="@@NO_GAME_LABELS_FOUND"
            *ngIf="gameLabelCategories && !gameLabelCategories.length">
            No game labels found
        </p>
        <div
            style="border: 3px solid {{ category.color }}; border-radius: 0.5rem; padding: 0.5rem"
            *ngFor="let category of gameLabelCategories; let categoryIndex = index">
            <ion-row class="ion-justify-content-between ion-align-items-center">
                <span style="padding: 0 0.5rem; font-size: 18px" (click)="manageCategory(category, categoryIndex)">
                    {{ category.final_title }}
                </span>
                <div (click)="manageLabel(null, categoryIndex)" style="margin-right: 0.25rem">
                    <ion-icon class="text-black" slot="icon-only" name="add-outline" style="font-size: 24px"></ion-icon>
                </div>
            </ion-row>
            <ion-row
                class="game-label-large"
                *ngFor="let gameLabel of category.labels; let labelIndex = index"
                (click)="manageLabel(gameLabel, categoryIndex, labelIndex)">
                <ion-col
                    class="label-title"
                    style="background-color: {{ category.color }}; color: {{ category.text_color }}">
                    <ion-label>{{ gameLabel.final_title }}</ion-label>
                </ion-col>
            </ion-row>
        </div>
    </div>
</ion-content>
