import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GameMode } from '@dc-core/dc-backend/dc-enums';

import { GamesTrialService } from '../services/games-trial.service';

export const gamesTrialGuard: CanActivateFn = (route, state) => {
    const gamesTrialService: GamesTrialService = inject(GamesTrialService);
    const gameMode: GameMode = route.data.game_mode;

    return gamesTrialService.hasValidTrial(gameMode);
};
