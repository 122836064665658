import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AlertAction, AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import _ from 'lodash';

type ExtraAlertPayload = AlertPayload & { id: string };

@Component({
    selector: 'app-alerts',
    templateUrl: 'app-alerts.component.html',
    styleUrls: ['app-alerts.component.scss'],
    animations: [
        trigger('fade', [
            transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0 }))]),
        ]),
    ],
})
export class AppAlertsComponent implements OnInit {
    currentAlert: ExtraAlertPayload = null;

    constructor(private _alertService: DartCounterAlertService) {}

    ngOnInit(): void {
        this._alertService.$alerts.subscribe((alert: ExtraAlertPayload) => {
            if (alert) {
                if (alert.id) {
                    if (alert.id === this.currentAlert?.id) {
                        return;
                    }
                } else {
                    alert.id = Math.random().toString(36).substring(2, 8);
                }

                if (!alert.icon) {
                    alert.icon = null;
                }

                if (!alert.confirmColor) {
                    alert.confirmColor = 'black';
                }

                if (!alert.showCloseButton) {
                    alert.showCloseButton = false;
                }

                if (alert.timer == null) {
                    alert.timer = null;
                }

                this.currentAlert = alert;

                if (alert.timer) {
                    if (alert.timer === 'default') {
                        alert.timer = 1500;
                    }

                    setTimeout(() => {
                        this.dismissAlertById(alert.id);
                    }, alert.timer);
                }
            } else {
                this.currentAlert = null;
            }
        });

        this._alertService.$alertActions.subscribe((action: AlertAction) => {
            this.dismissAlertById(action.id);
        });
    }

    dismissAlertById(id: string) {
        if (this.currentAlert?.id === id) {
            this.currentAlert = null;
        }
    }

    dismissAlert(confirmed: boolean = null): void {
        const alert = _.cloneDeep(this.currentAlert);
        this.currentAlert = null;

        setTimeout(() => {
            if (confirmed === true && alert.onConfirm) {
                alert.onConfirm();
            } else if (confirmed === false && alert.onCancel) {
                alert.onCancel();
            } else if (alert.onClose) {
                alert.onClose();
            }
        }, 300);
    }

    checkBackdropDismiss(): void {
        if (this.currentAlert && this.currentAlert.backdropDismiss === true) {
            this.dismissAlert();
        }
    }
}
