@if (!onlineCore.pause) {
    @for (stream of cameraStreams() | keyvalue; track $index) {
        @if (stream.value.videoStreams.board) {
            <div
                class="relative w-full h-full overflow-hidden"
                [hidden]="
                    !visible() ||
                    (currentUserId() === authenticatedUserId() && !isSpectator() && !allowOwnCamView()) ||
                    stream.key !== currentUserId()
                ">
                <video
                    #onlineBoardCam
                    [id]="'board-cam-' + stream.key"
                    class="ingame-camera w-full h-auto max-h-full"
                    [ngClass]="{ 'active-board-cam': currentUserId() === stream.key }"
                    [srcObject]="stream.value.videoStreams.board.stream"
                    [style.transform]="stream.value.videoStreams.board.scale"
                    autoplay
                    playsinline
                    webkit-playsinline
                    muted></video>

                @if (!isSpectator()) {
                    <div class="absolute left-4 top-4 flex space-x-1">
                        <div
                            class="h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center"
                            (click)="showCameraOptions(authenticatedUserId(), currentUserId(), players())">
                            <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                        </div>
                    </div>
                }
            </div>
        }
    }
}
