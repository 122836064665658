import { numbersInDutch, numbersInEnglish, numbersInGerman } from './numbers-grammar';

export const generateEnglishToNumber = () => {
    const numbers = {
        zero: 0,
    };
    for (let i = 1; i <= 180; i++) {
        const words = numbersInEnglish(i);
        if (typeof words === 'string') {
            numbers[words] = i;
        } else {
            words.forEach((word) => {
                numbers[word] = i;
                if (word.includes('and')) {
                    numbers[word.replace(' and', '')] = i;
                }
            });
        }
    }
    return numbers;
};

export const generateDutchToNumber = () => {
    const numbers = {
        nul: 0,
    };
    for (let i = 1; i <= 180; i++) {
        const words = numbersInDutch(i);
        if (typeof words === 'string') {
            numbers[words] = i;
        } else {
            words.forEach((word) => {
                numbers[word] = i;
                if (word.includes(' en')) {
                    numbers[word.replace(' en', '')] = i;
                }
            });
        }
    }
    return numbers;
};

export const generateGermanToNumber = () => {
    const numbers = {
        null: 0,
    };
    for (let i = 1; i <= 180; i++) {
        const words = numbersInGerman(i);
        if (typeof words === 'string') {
            numbers[words] = i;
        } else {
            words.forEach((word) => {
                numbers[word] = i;
                if (word.includes(' und')) {
                    numbers[word.replace(' und', '')] = i;
                }
            });
        }
    }
    return numbers;
};
