import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'euroPrice',
})
export class EuroPricePipe implements PipeTransform {
    transform(cents: number): string {
        return '€ ' + cents / 100;
    }
}
