import { Route } from '@angular/router';
import { GameMode } from '@dc-core/dc-backend/dc-enums';

import { gamesLocalLeaveBusterGuard } from '../guards/games-local-leave-buster.guard';
import { gamesTrialGuard } from '../guards/games-trial.guard';

export const halfItGameRoutes: Route[] = [
    {
        path: 'local-games/half-it',
        loadComponent: () =>
            import('./pages/half-it-new-game/half-it-new-game.component').then((c) => c.HalfItNewGameComponent),
    },
    {
        path: 'game/half-it',
        data: {
            gameMode: 'half_it' as GameMode,
        },
        canActivate: [gamesTrialGuard],
        canDeactivate: [gamesLocalLeaveBusterGuard],
        loadComponent: () =>
            import('./pages/half-it-local-game/half-it-local-game.component').then((c) => c.HalfItLocalGameComponent),
    },
];
