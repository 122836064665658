import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FriendApiService } from '@dc-api/friend.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { ActionSheetController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { debounceTime, take } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-my-friends',
    templateUrl: 'my-friends.html',
})
export class MyFriendsComponent {
    public userId: number = null;
    public friends: Array<any> = null;
    public incomingFriends: Array<any> = null;

    public isUserProfilePrivate: boolean = null;

    public skip = 0;
    public take = 50;
    public total: number = null;
    public allTotal: number = null;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public sheet: ActionSheetController,
        private ga: DartCounterAnalyticsService,
        private loading: DCLoadingService,
        private route: ActivatedRoute,
        public friendApiService: FriendApiService,
        private _alertService: DartCounterAlertService,
        private userApiService: UserApiService,
        private userService: UserService,
        public modal: ModalController,
        private translate: TranslateService
    ) {}

    ionViewDidEnter(): void {
        this.ga.trackView('Friends');

        this.isUserProfilePrivate = null;
        this.friends = null;
        this.incomingFriends = null;
        this.skip = 0;
        this.take = 50;
        this.total = null;

        this.searchControl.valueChanges
            .pipe(debounceTime(600), takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                if (value.length) {
                    this.searchFriends(value);
                } else {
                    this.searchFriends(null);
                }
            });

        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            let userId = this.auth.user.id;
            if (params.userId) {
                userId = params.userId;
            }

            this.userId = userId;

            if (userId === this.auth.user.id) {
                this.isUserProfilePrivate = false;

                this.getFriends();
                this.GetIncomingFriends();
            } else {
                this.userApiService
                    .getUserById({
                        userId,
                    })
                    .then((res) => {
                        this.isUserProfilePrivate = this.userService.isUserProfilePrivate(res.data);

                        if (!this.isUserProfilePrivate) {
                            this.getFriends();
                        }
                    });
            }
        });
    }

    GetIncomingFriends() {
        this.friendApiService
            .getIncomingFriends({})
            .then((res) => {
                this.incomingFriends = res.data;
                this.friendApiService.notifications = res.data.length;
            })
            .catch((err) => {
                this._alertService.errorFromApi(err);
                this.loading.DismissLoader();
            });
    }

    OpenFriends() {
        this.nav.navigateForward('search-users');
    }

    OpenProfile(person) {
        this.nav.navigateForward('profile/' + person.id);
    }

    AddFriend(account) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .addFriendByUserId({ userId: account.id })
            .then(() => {
                account.friend_status = 'pending';
                this.loading.DismissLoader();
            })
            .catch((e) => {
                this._alertService.errorFromApi(e);
                this.loading.DismissLoader();
            });
    }

    ShowPendingOptions(account) {
        $localize`:@@CANCEL_REQUEST:Cancel`;
        const actionSheet = this.sheet.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: this.translate.instant('CANCEL_REQUEST'),
                    icon: 'close',
                    handler: () => {
                        this.RemoveFriend(account);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    RemoveFriend(account) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .denyFriendByUserId({ userId: account.id })
            .then(() => {
                account.friend_status = 'no_friends';
                this.loading.DismissLoader();
                this.GetIncomingFriends();
            })
            .catch((e) => {
                if (e instanceof this.friendApiService.denyFriendByUserId.Error) {
                    const error = e.getActualType();
                    this._alertService.createAlert({
                        title: error.data.message,
                        icon: 'error',
                    });
                }
                this.loading.DismissLoader();
            });
    }

    AcceptFriend(account) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .acceptFriendByUserId({ userId: account.id })
            .then(() => {
                account.friend_status = 'friends';
                this.loading.DismissLoader();
                this.GetIncomingFriends();
                this.getFriends(true);
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    DenyFriend(account) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .denyFriendByUserId({ userId: account.id })
            .then(() => {
                account.friend_status = 'no_friends';
                this.loading.DismissLoader();
                this.GetIncomingFriends();
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    ionViewWillLeave() {
        this.loading.DismissLoader();
    }

    getFriends(reset = false): void {
        if (reset) {
            this.skip = 0;
            this.total = null;
            this.allTotal = null;
        }

        if (this.skip <= this.total || this.total === null) {
            this.friendApiService
                .getFriends({
                    user_id: this.userId,
                    limit: this.take,
                    page: this.skip === 0 ? 1 : this.skip / this.take + 1,
                })
                .then((res) => {
                    if (this.friends === null) {
                        this.friends = [];
                    }

                    if (res.data.data.length) {
                        this.skip += this.take;
                    }

                    this.total = res.data.total;
                    this.allTotal = res.data.total;

                    this.friends.push(...res.data.data);
                });
        }
    }

    searchFriends(searchString: string = null): void {
        this.skip = 0;
        this.total = null;

        this.friendApiService
            .getFriends({
                user_id: this.userId,
                limit: this.take,
                page: this.skip === 0 ? 1 : this.skip / this.take + 1,
                search: searchString,
            })
            .then((res) => {
                if (res.data.data.length) {
                    this.skip += this.take;
                }

                this.total = res.data.total;

                this.friends = res.data.data;
            });
    }
}
