import { Route } from '@angular/router';

export const tournamentRoutes: Route[] = [
    {
        path: 'tournaments',
        loadComponent: () => import('./pages/tournaments/tournaments.component').then((c) => c.TournamentsComponent),
    },
    {
        path: 'tournaments/history',
        loadComponent: () =>
            import('./pages/tournaments/history/tournament-history.component').then(
                (c) => c.TournamentHistoryComponent
            ),
    },
    {
        path: 'tournaments/:id',
        data: {
            hideTournamentNotification: true,
        },
        loadComponent: () =>
            import('./pages/tournaments/view/tournament-view.component').then((c) => c.TournamentViewComponent),
    },
    {
        path: 'tournaments/:id/:view',
        data: {
            hideTournamentNotification: true,
        },
        loadComponent: () =>
            import('./pages/tournaments/view/tournament-view.component').then((c) => c.TournamentViewComponent),
    },
];
