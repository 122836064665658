import { inject, Injectable } from '@angular/core';
import { CricketTacticsUser, MatchUser, ReportTurn } from '@dc-core/dc-backend/dc-classes';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { DartCounterCricketTacticsGame } from '@dc-core/dc-gamelogic/in-game/cricket-tactics/cricket-tactics.in-game.classes';
import { DartCounterPlayingMatch } from '@dc-core/dc-gamelogic/in-game/match/match.in-game.classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';

@Injectable({
    providedIn: 'root',
})
export class GamesReportService {
    public lastReport: Date = null;

    private alertService: DartCounterAlertService = inject(DartCounterAlertService);

    public checkReport(showAlert: boolean): boolean {
        if (this.canReport()) {
            this.lastReport = new Date();
            return true;
        } else {
            if (showAlert) {
                this.alertService.createAlert({
                    title: $localize`:@@PLEASE_WAIT_BEFORE_REPORTING_AGAIN:Please wait before reporting again`,
                    icon: 'info',
                });
            }
            return false;
        }
    }

    public getReportTurns(onlineGameplay: OnlineGameplay): ReportTurn[] | null {
        const reportTurns: ReportTurn[] = [];
        switch (onlineGameplay.type) {
            case 'match':
                const match = onlineGameplay.game as DartCounterPlayingMatch;
                const lastMatchSet = match.sets[match.sets.length - 1];
                if (lastMatchSet) {
                    const lastMatchLeg = lastMatchSet.legs[lastMatchSet.legs.length - 1];
                    if (lastMatchLeg) {
                        lastMatchLeg.turns.forEach((turn) => {
                            let matchUser: MatchUser = null;
                            match.teams.forEach((team) => {
                                team.users.forEach((user) => {
                                    if (user.id == turn.match_user_id) {
                                        matchUser = user;
                                    }
                                });
                            });
                            reportTurns.push({
                                user_id: matchUser.user_id,
                                user_name: matchUser.user?.full_name,
                                thrown_score: turn.total_score,
                                current_score: turn.remaining_score,
                            });
                        });
                    }
                }
                break;
            case 'cricket_tactics':
                const cricketTactics = onlineGameplay.game as DartCounterCricketTacticsGame;
                const lastCricketSet = cricketTactics.sets[cricketTactics.sets.length - 1];
                if (lastCricketSet) {
                    const lastCricketLeg = lastCricketSet.legs[lastCricketSet.legs.length - 1];
                    if (lastCricketLeg) {
                        lastCricketLeg.turns.forEach((turn) => {
                            let cricketUser: CricketTacticsUser = null;
                            cricketTactics.teams.forEach((team) => {
                                team.users.forEach((user) => {
                                    if (user.id == turn.cricket_tactics_user_id) {
                                        cricketUser = user;
                                    }
                                });
                            });
                            reportTurns.push({
                                user_id: cricketUser?.user_id,
                                user_name: cricketUser?.user?.full_name,
                                thrown_score: turn.total_score,
                            });
                        });
                    }
                }
                break;
        }
        return reportTurns.length ? reportTurns : null;
    }

    private canReport(): boolean {
        if (!this.lastReport) {
            return true;
        }

        const now = new Date();
        const millis = 30 * 1000; // 30 seconds
        return now.getTime() - this.lastReport.getTime() >= millis;
    }
}
