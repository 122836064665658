import { Pipe, PipeTransform } from '@angular/core';
import { ChatParticipant } from '@dc-core/dc-backend/dc-classes';

@Pipe({
    name: 'UserFilter',
    pure: false,
})
export class UserFilterPipe implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out

        if (filter.search) {
            const searchparts = filter.search.split(' ');
            let resultarray = JSON.parse(JSON.stringify(items));
            searchparts.forEach((searchPart) => {
                resultarray = this.filterRecursive(resultarray, searchPart.toLowerCase());
            });
            return resultarray;
        }

        return items;
    }

    filterRecursive(items, search) {
        return items.filter(
            (item) =>
                (item.first_name && item.first_name.toLowerCase().indexOf(search) !== -1) ||
                (item.last_name && item.last_name.toLowerCase().indexOf(search) !== -1) ||
                (item.profile?.nickname && item.profile?.nickname.toLowerCase().indexOf(search) !== -1)
        );
    }
}

@Pipe({
    name: 'CountryFilter',
    pure: false,
})
export class CountryFilterPipe implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out

        if (filter.search) {
            const searchparts = filter.search.split(' ');
            let resultarray = JSON.parse(JSON.stringify(items));
            searchparts.forEach((searchPart) => {
                resultarray = this.filterRecursive(resultarray, searchPart.toLowerCase());
            });
            return resultarray;
        }

        return items;
    }

    filterRecursive(items, search) {
        return items.filter(
            (item) =>
                item.label.indexOf(search) !== -1 ||
                item.label.toLowerCase().indexOf(search) !== -1 ||
                (item.extra &&
                    (item.extra.indexOf(search) !== -1 || item.extra.toLowerCase().indexOf(search) !== -1)) ||
                item.name.indexOf(search) !== -1 ||
                item.name.toLowerCase().indexOf(search) !== -1
        );
    }
}

@Pipe({
    name: 'MatchPlayersFilter',
    pure: false,
})
export class MatchPlayersPipe implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out

        if (filter.search) {
            const searchparts = filter.search.split(' ');
            let resultarray = JSON.parse(JSON.stringify(items));
            searchparts.forEach((searchPart) => {
                resultarray = this.filterRecursive(resultarray, searchPart.toLowerCase());
            });
            return resultarray;
        }

        return items;
    }

    filterRecursive(items, search) {
        return items.filter(
            (item) =>
                item.teams[0].players[0].name.toLowerCase().indexOf(search) !== -1 ||
                item.teams[1].players[0].name.toLowerCase().indexOf(search) !== -1
        );
    }
}

@Pipe({
    name: 'privateChatFilter',
    pure: false,
})
export class PrivateChatFilter implements PipeTransform {
    transform(items: ChatParticipant[], filter: any): any {
        if (!items || !filter) {
            return items;
        }

        if (filter.search) {
            return items.filter(
                (item) => item.conversation.name.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
            );
        }

        return items;
    }
}
