import { NgModule } from '@angular/core';
import { InGameCameraService } from './camera/ingame-camera.service';
import { JanusEventService } from './dc-janus/janus-events.service';
import { JanusVideoRoomService } from './dc-janus/janus-video-room.service';
import { UserMediaService } from './dc-janus/usermedia.service';
import { FreeMatchesService } from './free-matches.service';
import { GameLabelService } from './game-label.service';
import { DartCounterSpeechToScoreService } from './speech-to-score/speech-to-score.service';
import { OnlineGamesService } from './online-games.service';
import { OmniCommunicationService } from './omni/omni-communication.service';
import { OmniResponseMappingService } from './omni/omni-response-mapping.service';
import { CastService } from './cast.service';
import { OwnJanusService } from './dc-janus/own-janus.service';
import { VoiceCallService } from './voice/voice-call.service';

@NgModule({
    providers: [
        InGameCameraService,
        FreeMatchesService,
        GameLabelService,
        DartCounterSpeechToScoreService,
        OnlineGamesService,
        OmniCommunicationService,
        OmniResponseMappingService,
        UserMediaService,
        JanusVideoRoomService,
        OwnJanusService,
        JanusEventService,
        CastService,
        VoiceCallService,
    ],
    imports: [],
})
export class DartCounterCoreServiceModule {}
