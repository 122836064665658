<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'SELECT_YOUR_COUNTRY'" i18n="@@SELECT_YOUR_COUNTRY">
            Select your country
        </div>
    </div>
    <div class="slide-modal-content">
        <input
            class="dialog-input mb-4"
            type="text"
            [(ngModel)]="searchString"
            placeholder="{{ 'SEARCH' | translate }}" />

        <div class="dialog-input-label" [translate]="'COUNTRIES'" i18n="@@COUNTRIES">Countries</div>
        @if (countries) {
            <div class="h-80 overflow-y-auto">
                @for (country of countries | CountryFilter: { search: searchString }; track country.alpha2) {
                    <div
                        [class.active]="country.alpha2 === selectedCountry"
                        (click)="selectCountry(country)"
                        class="border-b-1 border-neutral-200 flex py-2 items-center px-1">
                        <div class="pr-4">
                            <img
                                class="flag"
                                style="width: 27px; height: 16px"
                                src="assets/images/flags/rect/{{ country.flag }}" />
                        </div>
                        <div>{{ country.name }}</div>
                    </div>
                }
                <div class="country"></div>
            </div>
        }

        <app-primary-button
            (click)="dismiss(selectedCountry)"
            size="normal"
            addedClasses="w-full"
            class="w-full"
            [translate]="'CONFIRM'"
            i18n="@@CONFIRM">
            Confirm
        </app-primary-button>
    </div>
</div>
