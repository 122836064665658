<modal-content scrollY="false" fullscreen class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="white">
        <div title [translate]="'CONTINUE_ONLINE_MATCH'" i18n="@@CONTINUE_ONLINE_MATCH">Continue game</div>
        <app-option-button right icon="close" (click)="DismissModal()" color="text-black"></app-option-button>
    </app-page-header>

    <ion-row>
        <ion-col class="text-center">
            <h4 [translate]="'REINVITE_FRIEND_QUESTION'" i18n="@@REINVITE_FRIEND_QUESTION">
                You are about to re-invite your opponent
            </h4>
        </ion-col>
    </ion-row>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA orange" style="position: relative" (click)="DismissModal(true)">
            <p class="text-center" style="height: 72px" [translate]="'CONFIRM'" i18n="@@CONFIRM">Confirm</p>
        </div>
    </ion-toolbar>
</ion-footer>
