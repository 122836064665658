import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FriendApiService } from '@dc-api/friend.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { PrivateChatApiService } from '@dc-api/private-chat.api.service';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { environment } from 'src/environments/environment';

import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from '../../../app.globals';
import { AppFeaturesService } from '../../app-features/services/app-features.service';

@Component({
    selector: 'app-bottom-navigation',
    standalone: true,
    imports: [CommonModule, IonicModule, TranslateModule, IconComponent],
    templateUrl: 'bottom-navigation.component.html',
})
export class BottomNavigationComponent {
    @Input() active: 'dashboard' | 'local' | 'online' | 'statistics' | 'tournaments' | 'profile' | 'chats';

    public isWeb = environment.isWeb;
    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    public showTitles = false;

    constructor(
        public gameApiService: GameApiService,
        public friendApiService: FriendApiService,
        public privateChatApiService: PrivateChatApiService,
        private _ga: DartCounterAnalyticsService,
        private _nav: NavController
    ) {}

    goToDashboard() {
        this._ga.trackEvent(GA_EVENTCATEGORIES.BOTTOMNAV, GA_EVENTACTIONS.OPENDASHBOARD);
        this._nav.navigateRoot('dashboard');
    }

    goToLocalGames() {
        this._ga.trackEvent(GA_EVENTCATEGORIES.BOTTOMNAV, GA_EVENTACTIONS.OPENOFFLINEGAMES);
        this._nav.navigateRoot('local-games');
    }

    goToOnlineGames() {
        this._ga.trackEvent(GA_EVENTCATEGORIES.BOTTOMNAV, GA_EVENTACTIONS.OPENONLINEGAMES);
        this._nav.navigateRoot('online-games');
    }

    goToTournaments() {
        this._ga.trackEvent(GA_EVENTCATEGORIES.BOTTOMNAV, GA_EVENTACTIONS.OPENTOURNAMENTS);
        this._nav.navigateRoot('tournaments');
    }

    goToStatistics() {
        this._ga.trackEvent(GA_EVENTCATEGORIES.BOTTOMNAV, GA_EVENTACTIONS.OPENSTATISTICS);
        this._nav.navigateRoot('statistics');
    }

    goToProfile() {
        this._ga.trackEvent(GA_EVENTCATEGORIES.BOTTOMNAV, GA_EVENTACTIONS.OPENPROFILE);
        this._nav.navigateRoot('profile', {
            queryParams: {
                showBottomNav: true,
            },
        });
    }

    goToChats() {
        this._ga.trackEvent(GA_EVENTCATEGORIES.BOTTOMNAV, GA_EVENTACTIONS.OPENCHATS);
        this._nav.navigateRoot('chats');
    }
}
