/* eslint-disable max-len */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CameraStreamApiService } from '@dc-api/camera-stream.api.service';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { JanusRoom } from '@dc-core/dc-services/camera/camera.models';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { UserMediaErrorType, UserMediaService } from '@dc-core/dc-services/dc-janus/usermedia.service';
import { MenuController } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
    selector: 'app-connect',
    templateUrl: 'connect.component.html',
    styleUrls: ['connect.component.scss'],
})
export class ConnectComponent implements OnInit, OnDestroy {
    public janusRoom: JanusRoom = null;
    public isConfirmed = false;
    public devices: MediaDeviceInfo[] = [];

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public menuController: MenuController,
        public userService: UserService,
        private _alertService: DartCounterAlertService,
        private _cameraStreamApiService: CameraStreamApiService,
        public videoRoomService: JanusVideoRoomService,
        public userMediaService: UserMediaService,
        private _activeGamesCollectionService: ActiveGamesCollectionService
    ) {}

    public ngOnInit(): void {
        this.videoRoomService.isExternal = true;
        this._activeGamesCollectionService.disableJoin = true;

        this.menuController.enable(true, 'mainmenu');

        this.requestMediaAccess();
    }

    requestMediaAccess(): void {
        this.userMediaService
            .getUserMedia({
                audio: false,
                video: true,
            })
            .then((stream) => {
                stream.getTracks().forEach((track) => track.stop());

                setTimeout(() => {
                    this.userMediaService.getDevices(this.videoRoomService.ownUserMedia);
                }, 500);
            })
            .catch((error: DOMException) => {
                const userMediaError = this.userMediaService.mapToUserMediaErrorType(error);
                if (userMediaError === UserMediaErrorType.PermissionDenied) {
                    this._alertService.createAlert({
                        title: $localize`:@@ENABLE_CAM_BROWSER_PERMS:Please enable camera permissions in your browser settings.`,
                        icon: 'error',
                        timer: null,
                        allowOutsideClick: false,
                        confirmButtonText: $localize`:@@CONFIRM:Confirm`,
                    });
                } else {
                    setTimeout(() => {
                        this.userMediaService.getDevices(this.videoRoomService.ownUserMedia);
                    }, 500);
                }
            });
    }

    startPublishing(): void {
        if (this.isConfirmed) {
            return;
        }

        this.isConfirmed = true;

        const media = this.userMediaService.getMediaConstraints(true, false);
        this.videoRoomService.startPublishing('video', media, this.videoRoomService.ownUserMedia).then((janusRoom) => {
            this.janusRoom = janusRoom;
            this.generateCredentials();
        });
    }

    generateCredentials(): void {
        if (this.janusRoom) {
            this._cameraStreamApiService
                .storeCameraStreamByRoomNumber({
                    room: this.janusRoom.roomID,
                    janus_server_hostname: this.janusRoom.janusServerHost,
                    virt_cam_id: null,
                })
                .then((res) => {
                    this.videoRoomService.externalCode = res.data.code;
                    this.videoRoomService.externalPassword = res.data.password;
                });
        }
    }

    disableCode(): void {
        if (this.janusRoom && this.videoRoomService.externalCode && this.videoRoomService.externalPassword) {
            this._cameraStreamApiService
                .deleteCameraStream({
                    room: this.janusRoom.roomID,
                    code: this.videoRoomService.externalCode,
                    password: this.videoRoomService.externalPassword,
                })
                .then(() => {
                    this.videoRoomService.externalCode = null;
                    this.videoRoomService.externalPassword = null;
                });
        }
    }

    ngOnDestroy(): void {
        this.videoRoomService.isExternal = false;
        this.videoRoomService.externalCode = null;
        this.videoRoomService.externalPassword = null;

        this._activeGamesCollectionService.disableJoin = false;

        // Cleanup the preview / streams
        this.videoRoomService.ownUserMedia.cleanupUserMedia(true, true);
        this.disableCode();

        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
