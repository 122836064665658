import { Injectable } from '@angular/core';
import {
    addDoc,
    collection,
    CollectionReference,
    doc,
    DocumentData,
    DocumentReference,
    Firestore,
    onSnapshot,
    query,
    Timestamp,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import { OnlineGameplay } from '../../dc-backend/dc-interfaces';
import _ from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { FIRESTORE_COLLECTION, DCGameAction, FSSavedGameAction, ACTIONTYPE } from '../globals/firestore.tables';
import { SavedGameActionPayload } from '../actions.service';

@Injectable()
export class ActionsCollectionService extends FireStoreCollectionsService {
    private defaultClass = new DCGameAction();

    private _lastTimestamp: Timestamp = Timestamp.now();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.ACTIONS;
    private firestore_collection: CollectionReference<DCGameAction>;
    private _activeGameRef: DocumentReference<OnlineGameplay> = null;
    public onlineActions$: Subject<DCGameAction> = new Subject<DCGameAction>();
    private _actionsSubscription?: Subscription;

    constructor(private firestore: Firestore) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<DCGameAction>(this.collection_name);
    }

    setLastTimestamp(): void {
        this._lastTimestamp = Timestamp.now();
    }

    enableActions(gameDocRef: DocumentReference<OnlineGameplay>, isSpectating: boolean) {
        this.disableActions();

        this._activeGameRef = gameDocRef;

        if (isSpectating) {
            this.setLastTimestamp();
        }

        // Check for new incoming events
        this._actionsSubscription = this.listenForActions().subscribe((gameEvent: DCGameAction) => {
            if (gameEvent) {
                this.onlineActions$.next(gameEvent);
            }
        });
    }

    listenForActions(): Observable<DCGameAction> {
        const collectionRef = collection(this._activeGameRef, this.collection_name);
        const timestampClause = this.getAttributeString(this.defaultClass, (obj: DCGameAction) => obj.sent_at);

        return new Observable<DCGameAction>((observer) => {
            try {
                let newActionsQuery = null;
                if (this._lastTimestamp) {
                    newActionsQuery = query(collectionRef, where(timestampClause, '>', this._lastTimestamp));
                } else {
                    newActionsQuery = query(collectionRef);
                }

                const unsubscribe = onSnapshot(newActionsQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as DCGameAction;
                        if (change.type === 'added') {
                            observer.next(data);
                        }
                    });
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    disableActions() {
        // If there's an active subscription, unsubscribe from it
        if (this._actionsSubscription) {
            this._actionsSubscription.unsubscribe();
            this._actionsSubscription = undefined; // Clear the reference
        }
    }

    addItem(newDoc: DCGameAction): Promise<DocumentReference<DocumentData>> {
        const gameEventsCollectionRef = collection(this._activeGameRef, this.collection_name);
        return addDoc(gameEventsCollectionRef, newDoc);
    }

    getDocByID(id: string): DocumentReference<DocumentData> {
        const gameEventsCollectionRef = collection(this._activeGameRef, this.collection_name);
        return doc(gameEventsCollectionRef, id);
    }

    watchDoc(id: string, observerFn) {
        let docRef = this.getDocByID(id);
        return onSnapshot(docRef, observerFn);
    }

    updateItem(ref: DocumentReference<DocumentData>, updatedValues: DocumentData): Promise<void> {
        return updateDoc(ref, updatedValues);
    }

    sendSavedGameAction(payload: SavedGameActionPayload) {
        const action: FSSavedGameAction = {
            actionType: ACTIONTYPE.SAVED_GAME,

            sender_user_id: payload.sender,
            sent_at: Timestamp.now(),

            game_id: payload.game_id,
            game_mode: payload.game_mode,
        };

        this.addItem(action).then((res) => {});
    }
}
