<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title">
            {{ title }}
        </div>
    </div>
    <div class="slide-modal-content text-black">
        <div [innerHTML]="contents"></div>
        @if (button) {
            <app-primary-button
                class="mt-2"
                size="small"
                [addedClasses]="'h-full'"
                [color]="'orange'"
                (click)="visitCallToAction()">
                {{ button.text }}
            </app-primary-button>
        }
    </div>
</div>
