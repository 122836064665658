<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="dismiss()"></app-icon>
        <div class="title-container">
            <div class="title" [translate]="'PERSONAL_HOTKEYS'" i18n="@@PERSONAL_HOTKEYS">Personal hotkeys</div>
        </div>
        <div class="mt-8 space-y-2">
            @if (hotkeyKeyboardOptions) {
                <app-hotkey-keyboard
                    class="black-keyboard"
                    [(user)]="auth.user"
                    [visible]="true"
                    [options]="hotkeyKeyboardOptions"></app-hotkey-keyboard>
            }
        </div>
    </div>
</modal-content>
