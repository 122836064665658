import { Subscription } from 'dc-core/dc-backend/dc-classes';
import {
    HOW_TO_CANCEL_APPLE_SUBSCRIPTION,
    HOW_TO_CANCEL_GOOGLE_SUBSCRIPTION,
    HOW_TO_RESUME_APPLE_SUBSCRIPTION,
    HOW_TO_RESUME_GOOGLE_SUBSCRIPTION,
} from 'dc-core/dc-globals/subscription.globals';

export class SubscriptionHelper {
    public static howToCancel(subscription: Subscription): string | null {
        if (subscription?.payment_provider === 'apple') {
            return HOW_TO_CANCEL_APPLE_SUBSCRIPTION;
        } else if (subscription?.payment_provider === 'google') {
            return HOW_TO_CANCEL_GOOGLE_SUBSCRIPTION;
        } else {
            return null;
        }
    }

    public static howToResume(subscription: Subscription): string | null {
        if (subscription.payment_provider === 'apple') {
            return HOW_TO_RESUME_APPLE_SUBSCRIPTION;
        } else if (subscription.payment_provider === 'google') {
            return HOW_TO_RESUME_GOOGLE_SUBSCRIPTION;
        } else {
            return null;
        }
    }
}
