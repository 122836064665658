import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class UserApiService extends ApiService {
    // GET Requests
    public getCurrentUser = super.getFetcher().path('/user').method('get').create();
    public getUserById = super.getFetcher().path('/users/{userId}').method('get').create();
    public getAllUsers = super.getFetcher().path('/users').method('get').create();
    public getAllUsersWithExtra = super.getFetcher().path('/users/extra').method('get').create();
    public getBlockedUsers = super.getFetcher().path('/users/blocked').method('get').create();
    public getAllBlockedUsers = super.getFetcher().path('/users/blocked/all').method('get').create();
    public getHotkeysByUserId = super.getFetcher().path('/hotkeys/{userId}').method('get').create();
    public getLastActivity = super.getFetcher().path('/last-activity').method('get').create();
    public checkSuspension = super.getFetcher().path('/users/check-suspension').method('get').create();

    // POST Requests
    public blockUserById = super.getFetcher().path('/users/{userId}/block').method('post').create();
    public unblockUserById = super.getFetcher().path('/users/{userId}/unblock').method('post').create();
    public rateUserById = super.getFetcher().path('/users/{userId}/rate').method('post').create();
    public reportUserById = super.getFetcher().path('/users/{userId}/report').method('post').create();
    public banUserById = super.getFetcher().path('/users/{userId}/ban').method('post').create();
    public unbanUserById = super.getFetcher().path('/users/{userId}/unban').method('post').create();
    public downloadUsers = super.getFetcher().path('/users/download').method('post').create();

    // PUT Requests
    public updateUser = super.getFetcher().path('/users').method('put').create();
    public updateHotkeysByUserId = super.getFetcher().path('/hotkeys/{userId}').method('put').create();
    public updateLocale = super.getFetcher().path('/locale').method('put').create();
    public forceUltimateOnUserById = super.getFetcher().path('/users/{userId}/force-ultimate').method('put').create();

    // DELETE Requests
    public deleteUserById = super.getFetcher().path('/users/{userId}').method('delete').create();
}
