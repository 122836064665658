import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ucfirst',
})
export class UpperCaseFirstPipe implements PipeTransform {
    transform(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}
