/* eslint-disable max-len */
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'app-banned',
    templateUrl: 'banned.component.html',
})
export class BannedComponent implements OnInit, OnDestroy {
    public translateService: TranslateService = inject(TranslateService);

    constructor(
        public menu: MenuController,
        public nav: NavController,
        public auth: AuthService
    ) {}

    ngOnInit(): void {
        this.auth.forceDeactivateRoute = false;

        this.menu.enable(false, 'mainmenu');
    }

    ngOnDestroy(): void {
        this.menu.enable(true, 'mainmenu');
    }
}
