import { Injectable } from '@angular/core';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';

/*
  Generated class for the AuthService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class SocialMediaService {
    public products: any = [];
    public proSubscription: any = null;

    constructor() {
        //this.fb.browserInit(224780451025678);
    }

    FacebookLogin(): Promise<FacebookLoginResponse> {
        return FacebookLogin.login({ permissions: ['email'] });
    }

    // GetFriends(userID){
    //   this.fb.api(userID + '/friends', ['user_friends'])
    //   .then((res) => {
    //     console.log('Result: ', res);
    //   }).catch(e =>
    //     console.error('Failed: ', e)
    //   );
    // }

    // GetProfilePicture(userID) {
    //   this.fb
    //     .api(userID + '/picture', ['public_profile'])
    //     .then((res) => {
    //       console.log('Result: ', res);
    //     })
    //     .catch((e) => console.error('Failed: ', e));
    // }
}
