<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'DATE_OF_BIRTH'" i18n="@@DATE_OF_BIRTH">Date of birth</div>
    </div>
    <div class="slide-modal-content">
        <div class="dialog-input-label" [translate]="'DATE_OF_BIRTH'" i18n="@@DATE_OF_BIRTH">Date of birth</div>
        <div class="dialog-input" (click)="changeBirthdate()">
            @if (birthdate) {
                <span>{{ birthdate | date: 'd MMM yyyy' : '' : translateService.currentLang }}</span>
            } @else {
                <span [translate]="'NO_DATE_OF_BIRTH'" i18n="@@NO_DATE_OF_BIRTH"> No date of birth </span>
            }
        </div>
        <app-primary-button
            size="normal"
            class="mt-4"
            addedClasses="w-full"
            color="orange"
            (click)="save()"
            [translate]="'SAVE'"
            i18n="@@SAVE">
            Save
        </app-primary-button>
    </div>
</div>
