<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        @if (!leaveBusterService.currentTournamentGame) {
            <app-icon icon="close" class="text-black close-button" (click)="DismissModal()"></app-icon>
        }
        <div class="title-container">
            <div class="title" [translate]="'OPPONENT_NOT_RESPONDING'" i18n="@@OPPONENT_NOT_RESPONDING">
                Opponent not responding
            </div>
            @if (!leaveBusterService.currentTournamentGame) {
                <div class="subtitle" [translate]="'YOU_CAN_WAIT_ON_OPPONENT'" i18n="@@YOU_CAN_WAIT_ON_OPPONENT">
                    You can wait for your opponent to still take a turn, or you can close and leave the game!
                </div>
            }
        </div>
        <div class="button-container flex justify-between space-x-2">
            <app-primary-button
                size="normal"
                color="red"
                [class.w-full]="leaveBusterService.currentTournamentGame"
                [addedClasses]="leaveBusterService.currentTournamentGame ? 'w-full' : 'px-4'"
                (click)="DismissModal('quit')"
                [translate]="'QUIT'"
                i18n="@@QUIT">
                Quit
            </app-primary-button>
            @if (!leaveBusterService.currentTournamentGame) {
                <app-primary-button
                    size="normal"
                    color="blue"
                    addedClasses="px-4"
                    (click)="DismissModal('save')"
                    [translate]="'SAVE'"
                    i18n="@@SAVE">
                    Save
                </app-primary-button>
            }
        </div>
    </div>
</modal-content>
